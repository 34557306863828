import * as SagaHelpers from '../helpers';
import * as SagaEffects from 'redux-saga/effects';
import * as types from '../../constants/ActionTypes';
import TimeRegistrationStatusConnector from '../../connectors/TimeRegistrationStatus';

const getAllTimeRegistrationStatus = SagaHelpers.createApiHandler(
    types.API_TIMEREGISTRATIONSTATUS_GET_ALL_INIT, 
    SagaHelpers.generateGetAllApiCall(
        TimeRegistrationStatusConnector,
        function* (connector: TimeRegistrationStatusConnector): any {
            return yield SagaEffects.call([connector, connector.getTimeRegistrationStatus]);
        }
    )
)

export default function* timeRegistrationStatusSaga() {
    yield SagaEffects.all([
        SagaEffects.fork(getAllTimeRegistrationStatus)
    ]);
}

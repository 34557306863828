export const isSameDay = (dateInQuestion: Date, markerDate: Date) =>
  dateInQuestion.getFullYear() === markerDate.getFullYear() &&
  dateInQuestion.getMonth() === markerDate.getMonth() &&
  dateInQuestion.getDate() === markerDate.getDate();

export const isToday = (date: Date) => isSameDay(date, new Date());

export const isSameDayOrAfter = (dateInQuestion: Date, markerDate: Date) =>
  isSameDay(dateInQuestion, markerDate) || dateInQuestion > markerDate;

export const isBeforeOrSameDay = (dateInQuestion: Date, markerDate: Date) =>
  isSameDayOrAfter(markerDate, dateInQuestion);

export const isBefore = (dateInQuestion: Date, markerDate: Date) =>
  isBeforeOrSameDay(dateInQuestion, subtractDays(markerDate, 1));

export const isAfter = (dateInQuestion: Date, markerDate: Date) =>
  isSameDayOrAfter(dateInQuestion, addDays(markerDate, 1));

export const isBetween = (
  dateInQuestion: Date,
  periodStart: Date,
  periodEnd: Date
) =>
  isSameDayOrAfter(dateInQuestion, periodStart) &&
  isBeforeOrSameDay(dateInQuestion, periodEnd);

// returns true if the inner period is contained (not strict) within the outer period
export const isWithinPeriod = (
  outerPeriodeStart: Date,
  outerPeriodEnd: Date,
  innerPeriodStart: Date,
  innerPeriodEnd: Date
) =>
  isSameDayOrAfter(innerPeriodStart, outerPeriodeStart) &&
  isBeforeOrSameDay(innerPeriodEnd, outerPeriodEnd);

export const intersectsPeriod = (
  outerPeriodStart: Date,
  outerPeriodEnd: Date,
  intersectingPeriodStart: Date,
  intersectingPeriodEnd: Date
) =>
  // is contained within period
  (intersectingPeriodStart &&
    intersectingPeriodEnd &&
    isWithinPeriod(
      outerPeriodStart,
      outerPeriodEnd,
      intersectingPeriodStart,
      intersectingPeriodEnd
    )) ||
  // we have a start date and it is somewhere between locked start and end date
  (intersectingPeriodStart &&
    isWithinPeriod(
      outerPeriodStart,
      outerPeriodEnd,
      intersectingPeriodStart,
      intersectingPeriodStart
    )) ||
  // we have an end date and it is somewhere between locked start and end date
  (intersectingPeriodEnd &&
    isWithinPeriod(
      outerPeriodStart,
      outerPeriodEnd,
      intersectingPeriodEnd,
      intersectingPeriodEnd
    )) ||
  // we don't have an end date (so it's infinite +) and the start date is before the locked period ends
  (!intersectingPeriodEnd &&
    intersectingPeriodStart &&
    isBeforeOrSameDay(intersectingPeriodStart, outerPeriodEnd)) ||
  // we don't have a start date (so it's infinite -) and the end date is after the locked period starts
  (!intersectingPeriodStart &&
    intersectingPeriodEnd &&
    isSameDayOrAfter(intersectingPeriodEnd, outerPeriodStart));

export const isInSameCalendarMonth = (date1: Date, date2: Date) =>
  date1.getFullYear() === date2.getFullYear() &&
  date1.getMonth() === date2.getMonth();

export const endOfMonth = (date: Date) =>
  new Date(new Date(date.getFullYear(), date.getMonth() + 1, 1).getTime() - 1);

export const startOfMonth = (date: Date) =>
  new Date(new Date(date.getFullYear(), date.getMonth(), 1));

const copyAndModify = (date: Date, modifier: (input: Date) => void): Date => {
  const newDate = new Date(date);
  modifier(newDate);
  return newDate;
};

export const endOfDay = (date: Date): Date =>
  copyAndModify(date, (date) => {
    date.setHours(23);
    date.setMinutes(59);
    date.setSeconds(59);
    date.setMilliseconds(999);
  });

export const startOfDay = (date: Date): Date =>
  copyAndModify(date, (date) => {
    date.setHours(0);
    date.setMinutes(0);
    date.setSeconds(0);
    date.setMilliseconds(0);
  });

export const startOfWeek = (dateInWeek: Date): Date =>
  startOfDay(
    copyAndModify(dateInWeek, (date) => {
      const day = date.getDay();
      const diff = date.getDate() - day + (day === 0 ? -6 : 1); // because sunday is 0
      date.setDate(diff);
    })
  );

export const endOfWeek = (dateInWeek: Date): Date =>
  endOfDay(
    copyAndModify(dateInWeek, (date) => {
      const day = date.getDay();
      const diff = date.getDate() + (day === 0 ? 0 : 7 - day); // because sunday is 0
      date.setDate(diff);
    })
  );

export const numDaysBetweenInclusive = (startDate: Date, endDate: Date) => {
  const diffMsec = Math.abs(endDate.getTime() - startDate.getTime());
  return Math.ceil(diffMsec / (1000 * 60 * 60 * 24));
};

export const min = (date1: Date, date2: Date) =>
  date1 < date2 ? date1 : date2;

export const max = (date1: Date, date2: Date) =>
  date1 > date2 ? date1 : date2;

export const addDays = (date: Date, numberOfDays: number) => {
  const result = new Date(date.valueOf());
  result.setDate(date.getDate() + Math.max(0, numberOfDays));
  return result;
};

export const subtractDays = (date: Date, numberOfDays: number) => {
  const result = new Date(date.valueOf());
  result.setDate(date.getDate() - Math.max(0, numberOfDays));
  return result;
};

export const toYyyyMmDd = (date: Date) =>
  `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(
    2,
    "0"
  )}-${String(date.getDate()).padStart(2, "0")}`;

export const toHhMm = (date: Date) =>
  `${String(date.getHours()).padStart(2, "0")}:${String(
    date.getMinutes()
  ).padStart(2, "0")}`;

export const toHhMmSs = (date: Date) =>
  `${toHhMm(date)}:${String(date.getSeconds()).padStart(2, "0")}`;

export const toYyyyMmDdHhMmSs = (date: Date) =>
  `${toYyyyMmDd(date)} ${toHhMmSs(date)}`;

export const diffInMinutes = (date1: Date, date2: Date) =>
  (date1.getTime() - date2.getTime()) / 60000;

export const addMinutes = (date: Date, numberOfMinutes: number) => {
  const result = new Date(date.valueOf());
  result.setMinutes(date.getMinutes() + Math.max(0, numberOfMinutes));
  return result;
};

export const sameDayAt = (
  date: Date,
  hours: number,
  minutes?: number,
  seconds?: number
) => {
  const result = new Date(date);
  if (0 <= hours && hours < 24) {
    result.setHours(hours);
  }
  result.setMinutes(minutes && 0 <= minutes && minutes < 60 ? minutes : 0);
  result.setSeconds(seconds && 0 <= seconds && seconds < 60 ? seconds : 0);
  result.setMilliseconds(0);
  return result;
};

export const todayAt = (
  hours: number,
  minutes?: number,
  seconds?: number
): Date => sameDayAt(new Date(), hours, minutes, seconds);

export const toWeekday = (date: Date, locale?: string) =>
  date.toLocaleDateString(locale, { weekday: "long" });

function dateOrdinal(dom: number): string {
  if (dom == 31 || dom == 21 || dom == 1) return dom + "st";
  else if (dom == 22 || dom == 2) return dom + "nd";
  else if (dom == 23 || dom == 3) return dom + "rd";
  else return dom + "th";
}

export const toOrdinalDayOfMonth = (date: Date): string => {
  const dayOfMonth = date.getDate();
  return dateOrdinal(dayOfMonth);
};

export const toMonth = (date: Date, locale?: string) =>
  date.toLocaleDateString(locale, { month: "long" });

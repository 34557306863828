export {};

declare global {
    interface Array<T> {
      flatten(): Array<any>;
    }
}

const flatten = function(arr: any[], result: any[] = []) {
  for (let i = 0, length = arr.length; i < length; i++) {
    const value = arr[i];
    if (Array.isArray(value)) {
      flatten(value, result);
    } else {
      result.push(value);
    }
  }
  return result;
};

Array.prototype.flatten = function(){
  return flatten(this);
}

import * as SagaHelpers from '../helpers';
import * as SagaEffects from 'redux-saga/effects';
import * as types from '../../constants/ActionTypes';
import LanguageConnector from '../../connectors/Language';

const getAllLanguages = SagaHelpers.createApiHandler(
    types.API_LANGUAGE_GET_ALL_INIT, 
    SagaHelpers.generateGetAllApiCall(
        LanguageConnector,
        function* (connector: LanguageConnector): any {
            return yield SagaEffects.call([connector, connector.getLanguage]);
        }
    )
)

export default function* languageSaga() {
    yield SagaEffects.all([
        SagaEffects.fork(getAllLanguages)
    ]);
}

import ModelMergeQueue, {
  INVALID as QUEUE_INVALID,
  SCHEDULED as QUEUE_SCHEDULED,
  UNCHANGED as QUEUE_UNCHANGED,
  UNKNOWN as QUEUE_UNKNOWN,
  VALID as QUEUE_VALID,
} from "./ModelMergeQueue";
import moment from "moment";
import "./setOperations";
import * as apihelper from "../selectors/apihelper";

export const QUEUED_VALID = "queued, valid";
export const QUEUED_INVALID = "queued, invalid";
export const SCHEDULED = "scheduled, not live";
export const LIVE = "live";
export const PERSISTED = "persisted";
export const UNKNOWN = "unknown, probably persisted";

export const timeRegistrationDuration = (treg) =>
  (new Date(apihelper.getAttr(treg, "endTime")).valueOf() -
    new Date(apihelper.getAttr(treg, "startTime")).valueOf()) /
  1000 /
  60 /
  60; // in hours

const tregIsBetween = (tr, start, end) => {
  const startTimeStr = apihelper.getAttr(tr, "startTime");
  const endTimeStr = apihelper.getAttr(tr, "endTime");
  return start <= new Date(startTimeStr) && new Date(endTimeStr) <= end;
};

class Filter {
  constructor(
    filter = {
      startTime: null,
      endTime: null,
      projects: null,
      resources: null,
      contractRoles: null,
      timeRegistrations: null,
    }
  ) {
    this.filter = this.filter.bind(this);

    this.startTime =
      this.endTime =
      this.projectIds =
      this.resourceIds =
      this.contractRoleIds =
      this.timeRegistrationIds =
        null;

    if (filter instanceof Filter) {
      this.startTime = filter.startTime;
      this.endTime = filter.endTime;
      this.projectIds = filter.projectIds;
      this.resourceIds = filter.resourceIds;
      this.contractRoleIds = filter.contractRoleIds;
      this.timeRegistrationIds = filter.timeRegistrationIds;
    } else if (filter) {
      if (filter.startTime && moment(filter.startTime).isValid()) {
        this.startTime = moment(filter.startTime);
      }
      if (filter.endTime && moment(filter.endTime).isValid()) {
        this.endTime = moment(filter.endTime);
      }
      if (Array.isArray(filter.projects)) {
        this.projectIds = filter.projects.map((p) =>
          apihelper.isEntity(p) ? apihelper.getEntityId(p) : p
        );
      }
      if (Array.isArray(filter.resources)) {
        this.resourceIds = filter.resources.map((r) =>
          apihelper.isEntity(r) ? apihelper.getEntityId(r) : r
        );
      }
      if (Array.isArray(filter.contractRoles)) {
        this.contractRoleIds = filter.contractRoles.map((cr) =>
          apihelper.isEntity(cr) ? apihelper.getEntityId(cr) : cr
        );
      }
      if (Array.isArray(filter.timeRegistrations)) {
        this.timeRegistrationIds = filter.timeRegistrations.map((tr) =>
          apihelper.isEntity(tr) ? apihelper.getEntityId(tr) : tr
        );
      }
    }
  }

  hasTimeRegistrations() {
    return this.timeRegistrationIds != null;
  }

  visitTimeRegistrationIds(func) {
    this.hasTimeRegistrations() && this.timeRegistrationIds.forEach(func);
  }

  hasProjects() {
    return this.projectIds != null;
  }

  visitProjectIds(func) {
    this.hasProjects() && this.projectIds.forEach(func);
  }

  hasResources() {
    return this.resourceIds != null;
  }

  visitResourceIds(func) {
    this.hasResources() && this.resourceIds.forEach(func);
  }

  hasContractRoles() {
    return this.contractRoleIds != null;
  }

  visitContractRoles(func) {
    this.hasContractRoles() && this.contractRoleIds.forEach(func);
  }

  hasStartTime() {
    return this.startTime != null;
  }

  hasEndTime() {
    return this.endTime != null;
  }

  getStartTime() {
    return this.startTime;
  }

  getEndTime() {
    return this.endTime;
  }

  filter(timeReg) {
    let startTime = moment(apihelper.getAttr(timeReg, "startTime")),
      endTime = moment(apihelper.getAttr(timeReg, "endTime"));
    return (
      (this.startTime == null ||
        !startTime.isValid() ||
        startTime.isSameOrAfter(this.startTime)) &&
      (this.endTime == null ||
        !endTime.isValid() ||
        endTime.isSameOrBefore(this.endTime)) &&
      (!this.hasProjects() ||
        !apihelper.relHasReference(timeReg, "project") ||
        this.projectIds.includes(apihelper.getRelId(timeReg, "project"))) &&
      (!this.hasResources() ||
        !apihelper.relHasReference(timeReg, "resource") ||
        this.resourceIds.includes(apihelper.getRelId(timeReg, "resource"))) &&
      (!this.hasContractRoles() ||
        !apihelper.relHasReference(timeReg, "contractRole") ||
        this.contractRoleIds.includes(
          apihelper.getRelId(timeReg, "contractRole")
        )) &&
      (!this.hasTimeRegistrations() ||
        !apihelper.getEntityId(timeReg) ||
        this.timeRegistrationIds.includes(apihelper.getEntityId(timeReg)))
    );
  }
}

export class TimeRegistrationIndex {
  constructor(timeRegistrations) {
    this.timeRegistrations = timeRegistrations;
    this.buildIndex(timeRegistrations);
  }

  buildIndex(timeRegistrations) {
    let registrationMatrix = {
      day: {},
      resource: {},
      project: {},
      contractRole: {},
      id: {},
    };
    let summations = {
      resources: {},
      total: 0,
      projects: {},
      resourcePerDay: {},
      day: {},
    };
    let boundaries = {
      earliestStartTime: null,
      latestStartTime: null,
      earliestEndTime: null,
      latestEndTime: null,
    };
    timeRegistrations.forEach((tr) => {
      let startTime = moment.parseZone(apihelper.getAttr(tr, "startTime")),
        endTime = moment.parseZone(apihelper.getAttr(tr, "endTime")),
        day = startTime.date(),
        month = startTime.month(),
        year = startTime.year(),
        tregId = apihelper.getEntityId(tr),
        resourceId = apihelper.getRelId(tr, "resource"),
        projectId = apihelper.getRelId(tr, "project"),
        contractRoleId = apihelper.getRelId(tr, "contractRole"),
        tregDuration = timeRegistrationDuration(tr);

      // init if empty
      registrationMatrix.day[year] = registrationMatrix.day[year] || {};
      registrationMatrix.day[year][month] =
        registrationMatrix.day[year][month] ||
        new Array(32).fill(0).map(() => []);
      registrationMatrix.resource[resourceId] =
        registrationMatrix.resource[resourceId] || [];
      registrationMatrix.project[projectId] =
        registrationMatrix.project[projectId] || [];
      registrationMatrix.contractRole[contractRoleId] =
        registrationMatrix.contractRole[contractRoleId] || [];

      summations.resources[resourceId] = summations.resources[resourceId] || 0;
      summations.projects[projectId] = summations.projects[projectId] || 0;
      summations.day[year] = summations.day[year] || {};
      summations.day[year][month] =
        summations.day[year][month] || new Array(32).fill(0);
      summations.day[year][month][day] = summations.day[year][month][day] || 0;
      summations.resourcePerDay[resourceId] =
        summations.resourcePerDay[resourceId] || {};
      summations.resourcePerDay[resourceId][year] =
        summations.resourcePerDay[resourceId][year] || {};
      summations.resourcePerDay[resourceId][year][month] =
        summations.resourcePerDay[resourceId][year][month] || {};
      summations.resourcePerDay[resourceId][year][month][day] =
        summations.resourcePerDay[resourceId][year][month][day] || 0;

      // add to lookup index
      registrationMatrix.resource[resourceId].push(tr);
      registrationMatrix.project[projectId].push(tr);
      registrationMatrix.contractRole[contractRoleId].push(tr);
      registrationMatrix.day[year][month][day].push(tr);
      registrationMatrix.id[tregId] = tr;

      // add to summations
      summations.resources[resourceId] += tregDuration;
      summations.projects[projectId] += tregDuration;
      summations.day[year][month][day] += tregDuration;
      summations.total += tregDuration;
      summations.resourcePerDay[resourceId][year][month][day] += tregDuration;

      // add to boundaries
      boundaries.earliestStartTime =
        boundaries.earliestStartTime == null
          ? startTime
          : moment.min(startTime, boundaries.earliestStartTime);
      boundaries.latestStartTime =
        boundaries.latestStartTime == null
          ? startTime
          : moment.max(startTime, boundaries.latestStartTime);
      boundaries.earliestEndTime =
        boundaries.earliestEndTime == null
          ? endTime
          : moment.min(endTime, boundaries.earliestEndTime);
      boundaries.latestEndTime =
        boundaries.latestEndTime == null
          ? endTime
          : moment.max(endTime, boundaries.latestEndTime);
    }, this);

    // structure timeregs in order by day => resource => [timeregs]
    this.registrationMatrix = registrationMatrix;
    this.summations = summations;
    this.boundaries = boundaries;
  }

  // startTime:       lower boundary on date (registrations must be later or same day)
  // endTime:         upper boundary on date (registrations must be earlier or same day)
  // projects:        list of whitelisted projects or project ids
  // resources:       list of whitelisted resources or resource ids
  // contractRoles:   list of whitelisted contractRoles or contractRole ids
  query(
    filter = {
      startTime: null,
      endTime: null,
      projects: null,
      resources: null,
      contractRoles: null,
      timeRegistrations: null,
    }
  ) {
    if (this.timeRegistrations.length == 0) {
      return [];
    }

    let ft = new Filter(filter);
    let filterStartTime = ft.hasStartTime()
        ? moment.max(ft.getStartTime(), this.boundaries.earliestStartTime)
        : this.boundaries.earliestStartTime,
      filterEndTime = ft.hasEndTime()
        ? moment.min(ft.getEndTime(), this.boundaries.latestEndTime)
        : this.boundaries.latestEndTime,
      filterStartTimeDate = filterStartTime.toDate(),
      filterEndTimeDate = filterEndTime.toDate();

    let regMatrix = this.registrationMatrix;

    if (
      filterEndTimeDate &&
      filterStartTimeDate &&
      filterEndTimeDate < filterStartTimeDate
    ) {
      return [];
    }

    // gather all timeregs within period
    let regIds = [],
      startMonth = filterStartTime.month(),
      startYear = filterStartTime.year(),
      endMonth = filterEndTime.month(),
      endYear = filterEndTime.year();

    // add explicit time reg ids
    if (ft.hasTimeRegistrations()) {
      ft.visitTimeRegistrationIds((trId) => {
        if (!!regMatrix.id[trId]) {
          regIds.push(trId);
        }
      });
    } else {
      let matrix = this.registrationMatrix.day;

      for (let loopYear = startYear; loopYear <= endYear; loopYear++) {
        if (!(loopYear in matrix)) {
          continue;
        }
        let isStartYear = loopYear === startYear;
        let isEndYear = loopYear === endYear;
        for (
          let loopMonth = loopYear === startYear ? startMonth : 0;
          loopMonth <= (loopYear === endYear ? endMonth : 12);
          loopMonth++
        ) {
          if (!(loopMonth in matrix[loopYear])) {
            continue;
          }
          let isStartMonth = isStartYear && loopMonth === startMonth;
          let isEndMonth = isEndYear && loopMonth === endMonth;

          let startDate = isStartMonth ? filterStartTime.date() : 0;
          let endDate = isEndMonth ? filterEndTime.date() : 31;
          let month = matrix[loopYear][loopMonth];

          if (month) {
            for (let d = startDate; d <= endDate; d++) {
              if (!(d in month)) {
                continue;
              }
              let dayTregs = month[d];
              if (isStartMonth || isEndMonth) {
                dayTregs = dayTregs.filter((tr) =>
                  tregIsBetween(tr, filterStartTimeDate, filterEndTimeDate)
                );
              }
              dayTregs.forEach((tr) => regIds.push(apihelper.getEntityId(tr)));
            }
          }
        }
      }
    }

    // filter from the remaining properties, if any
    let others = {
      project: [],
      resources: [],
      contractRole: [],
    };

    ft.visitProjectIds((pid) => {
      if (!!regMatrix.project[pid]) {
        others.project = others.project.concat(
          regMatrix.project[pid].map((tr) => apihelper.getEntityId(tr))
        );
      }
    });

    ft.visitResourceIds((rid) => {
      if (!!regMatrix.resource[rid]) {
        others.resources = others.resources.concat(
          regMatrix.resource[rid].map((tr) => apihelper.getEntityId(tr))
        );
      }
    });

    ft.visitContractRoles((crid) => {
      if (!!regMatrix.contractRole[crid]) {
        others.contractRole = others.contractRole.concat(
          regMatrix.contractRole[crid].map((tr) => apihelper.getEntityId(tr))
        );
      }
    });

    if (Array.isArray(ft.projectIds) && ft.projectIds.length > 0) {
      regIds = regIds.intersection(others.project);
    }

    if (Array.isArray(ft.resourceIds) && ft.resourceIds.length > 0) {
      regIds = regIds.intersection(others.resources);
    }

    if (Array.isArray(ft.contractRoleIds) && ft.contractRoleIds.length > 0) {
      regIds = regIds.intersection(others.contractRole);
    }

    // scoop up the registrations from the ids
    return regIds.map((id) => this.registrationMatrix.id[id], this);
  }
}

export default class StateMerge {
  constructor(storeRegistrations, liveTimeRegistrationActions, queue) {
    this.liveTimeRegistrationActions = liveTimeRegistrationActions || [];
    this.queue = queue || new ModelMergeQueue();
    this.storeRegistrations = storeRegistrations || [];
    this.index = new TimeRegistrationIndex(this.storeRegistrations);
  }

  getRegistrations(filter) {
    let ft = new Filter(filter);
    let storeRegistrations = this.index.query(ft);
    let queueChanges = this.queue.getAllUpdatedModels().filter(ft.filter);
    let queueChangesNotInStore = [],
      queueChangesAlsoInStore = [];
    let storeRegIds = new Set(
      storeRegistrations.map((tr) => apihelper.getEntityId(tr))
    );
    queueChanges.forEach((qc) => {
      if (
        (qc.id && storeRegIds.has(qc.id)) ||
        this.queue.isMergedModelNotified(qc)
      ) {
        queueChangesAlsoInStore.push(qc);
      } else {
        queueChangesNotInStore.push(qc);
      }
    }, this);
    let queueRegIds = new Set(
      queueChangesAlsoInStore.map(
        (qc) => qc.id || this.queue.getMergedModelCreateId(qc),
        this
      )
    );
    let storeRegistrationsNotInQueue = storeRegistrations.filter(
      (sr) => !queueRegIds.has(apihelper.getEntityId(sr))
    );
    return queueChangesNotInStore
      .concat(queueChangesAlsoInStore)
      .concat(storeRegistrationsNotInQueue);
  }

  registrationsByProject(project) {
    return this.getRegistrations({ projects: [project] });
  }

  registrationsByDay(day) {
    if (!moment(day).isValid()) {
      throw "StageMerge.registrationsByDay did not receive a valid date";
    }
    return this.getRegistrations({
      startTime: moment(day).startOf("day"),
      endTime: moment(day).add(1, "day").startOf("day"),
    });
  }

  registrationsByResourceAndDay(resource, day) {
    return this.getRegistrations({
      resources: [resource],
      startTime: moment(day).startOf("day"),
      endTime: moment(day).add(1, "day").startOf("day"),
    });
  }

  registrationsByResource(resource) {
    return this.getRegistrations({ resources: [resource] });
  }

  registrationsByProjectAndDay(project, day) {
    return this.registrationsByDay(day).filter((t) =>
      apihelper.relRefersToEntity(t, "project", project)
    );
  }

  registrationsByResourceAndProjectAndDay(resource, project, day) {
    return this.registrationsByResourceAndDay(resource, day).filter((t) =>
      apihelper.relRefersToEntity(t, "project", project)
    );
  }

  registrationsInWeek(anyIsoWeekDay) {
    return this.getRegistrations({
      startTime: moment(anyIsoWeekDay).startOf("isoWeek"),
      endTime: moment(anyIsoWeekDay).endOf("isoWeek"),
    });
  }

  registrationById(id) {
    return this.index.registrationMatrix.id[id];
  }

  getTimeregDuration(treg) {
    return timeRegistrationDuration(treg);
  }

  aggrHours(tregs) {
    return tregs.reduce((acc, cur) => acc + timeRegistrationDuration(cur), 0);
  }

  getTotalDuration() {
    return this.index.summations.total;
  }

  getTotalByProject(project) {
    let projectId = apihelper.isEntity(project)
      ? apihelper.getEntityId(project)
      : project;
    return this.index.summations.projects[projectId] || 0;
  }

  getTotalByResourceAndDay(resource, date) {
    let resourceId = apihelper.isEntity(resource)
      ? apihelper.getEntityId(resource)
      : resource;
    let m = moment(date);
    let day = m.date();
    let year = m.year();
    let month = m.month();
    return (
      (this.index.summations.resourcePerDay[resourceId] &&
        this.index.summations.resourcePerDay[resourceId][year] &&
        this.index.summations.resourcePerDay[resourceId][year][month] &&
        this.index.summations.resourcePerDay[resourceId][year][month][day]) ||
      0
    );
  }

  getTotalByResourcesAndDay(resources, date) {
    let resourceIds = resources.map((resource) =>
      apihelper.isEntity(resource) ? apihelper.getEntityId(resource) : resource
    );
    let m = moment(date);
    let day = m.date();
    let year = m.year();
    let month = m.month();
    let summations = this.index.summations;
    return resourceIds.reduce(
      (acc, resourceId) =>
        acc +
        ((summations.resourcePerDay[resourceId] &&
          summations.resourcePerDay[resourceId][year] &&
          summations.resourcePerDay[resourceId][year][month] &&
          summations.resourcePerDay[resourceId][year][month][day]) ||
          0),
      0
    );
  }

  getTotalByResource(resource) {
    let resourceId = apihelper.isEntity(resource)
      ? apihelper.getEntityId(resource)
      : resource;
    return this.index.summations.resources[resourceId] || 0;
  }

  getTotalByDay(date) {
    let m = moment(date);
    let day = m.date();
    let year = m.year();
    let month = m.month();
    return (
      (this.index.summations.day[year] &&
        this.index.summations.day[year][month] &&
        this.index.summations.day[year][month][day]) ||
      0
    );
  }

  containsTimeRegistration(timeRegistration) {
    return apihelper.isPersistedEntity(timeRegistration)
      ? !!this.index.registrationMatrix.id[
          apihelper.getEntityId(timeRegistration)
        ]
      : !!this.queue.findInQueue(timeRegistration);
  }

  // true if any of the provided time registrations are pending
  isPendingUpload(tregs) {
    tregs = Array.isArray(tregs) ? tregs : [tregs].filter(Boolean);
    return tregs.some((tr) => this.queue.isModelValidOrScheduled(tr), this);
  }

  // true if any of the provided time registration are currently in transit
  isUploading(tregs) {
    tregs = Array.isArray(tregs) ? tregs : [tregs].filter(Boolean);
    if (this.liveTimeRegistrationActions.length == 0 || tregs.length == 0) {
      return false;
    }

    return !!tregs.find((treg) => this._isScheduledChangeLive(treg), this);
  }

  getFailedValidations(treg) {
    return this.queue.getFailedValidations(treg);
  }

  _isScheduledChangeLive(model) {
    let ch = this.queue.getChange(model);
    return (
      !!ch &&
      !!this.liveTimeRegistrationActions.find(
        (lta) => lta.initAction.payload.transactionId == ch.claimId
      )
    );
  }

  getFieldStatus(model, fieldPath) {
    let qStatus = this.queue.queryFieldStatus(model, fieldPath);
    switch (qStatus) {
      case QUEUE_UNCHANGED:
        return apihelper.getEntityId(model) ? PERSISTED : QUEUE_VALID;
      case QUEUE_VALID:
        return QUEUED_VALID;
      case QUEUE_INVALID:
        return QUEUED_INVALID;
      case QUEUE_SCHEDULED:
        return this._isScheduledChangeLive(model) ? LIVE : SCHEDULED;
      case QUEUE_UNKNOWN:
        return UNKNOWN;
      case undefined: {
        if (model.id) {
          let storeReg = this.registrationById(apihelper.getEntityId(model));
          return storeReg ? PERSISTED : UNKNOWN;
        } else {
          return UNKNOWN;
        }
      }
      default:
        throw (
          "Unexpected value in StateMerge.getFieldStatus (" +
          qStatus +
          ", " +
          fieldPath +
          ")"
        );
    }
  }

  getReferencedProjectIds() {
    return Object.keys(this.index.registrationMatrix.project);
  }

  getReferencedResourceIds() {
    return Object.keys(this.index.registrationMatrix.resource);
  }

  getReferencedContractRoleIds() {
    return Object.keys(this.index.registrationMatrix.contractRole);
  }

  getProjectIdsWithHours() {
    return Object.keys(this.index.summations.projects).filter(
      (pId) => this.index.summations.projects[pId] > 0,
      this
    );
  }
}

import * as types from '../../constants/ActionTypes';
import { ResourceObject } from '../../lib/models';

export function pageOpened() {
    return {
        type: types.APP_PAGE_PROJECTS_AND_ROLES_OPENED
    };
}

export function selectClient(client: ResourceObject) {
    return {
        type: types.APP_PAGE_PROJECTS_AND_ROLES_CLIENT_SELECTED,
        payload: client
    };
}

export function selectProject(project: ResourceObject | null) {
    return {
        type: types.APP_PAGE_PROJECTS_AND_ROLES_PROJECT_SELECTED,
        payload: project
    };
}

export function selectProjectResource(projectResource: ResourceObject) {
    return {
        type: types.APP_PAGE_PROJECTS_AND_ROLES_PROJECTRESOURCE_SELECTED,
        payload: projectResource
    };
}

import * as types from '../../constants/ActionTypes';

export function createPermission(permission) {
    return {
        type: types.API_PERMISSION_CREATE_INIT,
        payload: {model: permission}
    };
}

export function deletePermission(permission) {
    return {
        type: types.API_PERMISSION_DELETE_INIT,
        payload: {model: permission}
    };
}

export function getAllPermissions(account) {
    return {
        type: types.API_PERMISSION_GET_ALL_INIT,
        payload: {
            account: account
        }
    };
}

import React, { Component } from "react";
import PropTypes from "prop-types";
import { Dropdown } from "@fluentui/react/lib/Dropdown";
import { Icon } from "@fluentui/react/lib/Icon";
import { Label } from "@fluentui/react/lib/Label";
import * as apihelper from "../../selectors/apihelper";
import { DropdownMenuItemType } from "@fluentui/react/lib/Dropdown";

let toOption = (key, text, disabled, dividerId, headerName) => ({
  key,
  text,
  disabled,
  dividerId,
  headerName,
});

export default class BasePickerSimple extends Component {
  constructor(props) {
    super(props);

    this._onChange = this._onChange.bind(this);
    this._onDismiss = this._onDismiss.bind(this);
    this._onOpen = this._onOpen.bind(this);
    this.state = {};
    this._blankObject = { key: "-1", text: null };
  }

  static propTypes = {
    isSelectionOptional: PropTypes.bool,
    disabled: PropTypes.bool,
    entities: PropTypes.array,
    disabledEntityIds: PropTypes.array,
    icon: PropTypes.string,
    idFunction: PropTypes.func,
    isRequired: PropTypes.bool,
    label: PropTypes.string,
    multiSelect: PropTypes.bool,
    nameFunction: PropTypes.func,
    onEntitySelected: PropTypes.func,
    onRender: PropTypes.func,
    placeholder: PropTypes.string,
    required: PropTypes.bool,
    selectedEntity: PropTypes.object,
    selectedEntities: PropTypes.array,
    silentIfBlank: PropTypes.bool, // if silentIfBlank == false then a callback is generated when _blankObject is chosen. True as default.
    onOpen: PropTypes.func,
    onClose: PropTypes.func,
    dividerList: PropTypes.array, //array of objects with keys (entityId, headerName, dividerId) - divider and header should be added in change of dividerId
  };

  static defaultProps = {
    idFunction: (entity) =>
      apihelper.getEntityType(entity) + "." + apihelper.getEntityId(entity),
    nameFunction: (entity) =>
      apihelper.getAttr(entity, "name") || apihelper.getAttr(entity, "title"),
    multiSelect: false,
    isSelectionOptional: false,
    silentIfBlank: true,
  };

  _onChange(e, value) {
    let { onEntitySelected, entities, idFunction } = this.props;

    if (
      value === this._blankObject &&
      !this.props.silentIfBlank &&
      onEntitySelected
    ) {
      onEntitySelected(null); // Callback if silentIfBlank == false.
    }

    if (value !== this._blankObject && onEntitySelected) {
      let selectedEntity = entities.find((p) => idFunction(p) === value.key);
      onEntitySelected(selectedEntity);
    }
  }

  _onDismiss() {
    let { required } = this.props;
    if (required) {
      this.setState({
        errorMessage: "Field is required",
      });
    }
    if (this.props.onClose) {
      this.props.onClose();
    }
  }

  _onOpen() {
    if (this.props.onOpen) {
      this.props.onOpen();
    }
  }

  isEntityDisabled(entity) {
    let { disabledEntityIds } = this.props;
    return !!(
      disabledEntityIds &&
      disabledEntityIds.includes(apihelper.getEntityId(entity))
    );
  }

  addIdForDivider(entity) {
    let { dividerList } = this.props;
    let dividerId = dividerList
      ? dividerList.find((item) => apihelper.entityHasId(entity, item.entityId))
          .dividerId
      : null;
    return dividerId;
  }

  addNameForHeader(entity) {
    let { dividerList } = this.props;
    let headerName = dividerList
      ? dividerList.find((item) => apihelper.entityHasId(entity, item.entityId))
          .headerName
      : null;
    return headerName;
  }

  render() {
    let {
      selectedEntity,
      selectedEntities,
      entities,
      label,
      placeholder,
      idFunction,
      nameFunction,
      onRender,
      multiSelect,
      required,
      disabled,
      icon,
      isSelectionOptional,
    } = this.props;
    let selectedEntityId = selectedEntity
      ? idFunction(selectedEntity)
      : undefined;
    let selectedEntityIds = Array.isArray(selectedEntities)
      ? selectedEntities.map(idFunction)
      : undefined;

    let options = (entities || []).map((e) =>
      toOption(
        idFunction(e),
        nameFunction(e),
        this.isEntityDisabled(e),
        this.addIdForDivider(e),
        this.addNameForHeader(e)
      )
    );

    //we add divider and header whenever there is a change in the dividerId
    for (let i = 1; i < options.length; i++) {
      let prev = options[i - 1];
      let next = options[i];
      let dividerItem = {
        key: "divider" + i,
        text: "-",
        itemType: DropdownMenuItemType.Divider,
      };
      let headerItem = {
        key: "header" + (i + 1),
        text: next.headerName,
        itemType: DropdownMenuItemType.Header,
      };

      if (
        prev.dividerId &&
        next.dividerId &&
        next.headerName &&
        prev.dividerId !== next.dividerId
      ) {
        options.splice(i, 0, dividerItem);
        options.splice(i + 1, 0, headerItem);
        i += 2;
      }
    }

    //we add the first header
    if (options[0] && options[0].headerName) {
      options.splice(0, 0, {
        key: "header",
        text: options[0].headerName,
        itemType: DropdownMenuItemType.Header,
      });
    }

    if (isSelectionOptional) {
      options.unshift(this._blankObject);
    }

    let selectProps = {};
    if (multiSelect) {
      selectProps.selectedKeys = selectedEntityIds;
      selectProps.multiSelect = !!multiSelect;
    } else {
      selectProps.selectedKey = selectedEntityId;
    }

    return (
      <div className="novatime-base-picker-simple">
        {label ? (
          <Label className={required ? "label-required" : ""}>{label}</Label>
        ) : undefined}
        <Dropdown
          required={required}
          placeholder={placeholder}
          {...selectProps}
          options={options}
          onChange={this._onChange}
          onRenderOption={onRender}
          disabled={disabled}
          styles={{ label: { color: "black" } }}
          onDismiss={this._onDismiss}
          calloutProps={{
            onLayerMounted: this._onOpen,
          }}
          panelProps={{
            onOpen: this._onOpen,
          }}
          errorMessage={
            selectedEntityId || selectedEntityIds
              ? undefined
              : this.state.errorMessage
          }
        />
        {icon ? <Icon className="indicator" iconName={icon} /> : undefined}
      </div>
    );
  }
}

import React from "react";
import PropTypes from "prop-types";
import { TextField } from "@fluentui/react/lib/TextField";
import { Dropdown } from "@fluentui/react/lib/Dropdown";
import SingleBaseForm from "./SingleBaseForm";
import * as apihelper from "../../selectors/apihelper";
import {
  getAttr,
  getRelId,
  getEntityId,
  getEntityType,
  relRefersToEntity,
} from "../../selectors/apihelper";
import {
  createEmptyModelRelationships,
  createEmptyModelAttributes,
  createEmptyModel,
} from "../../lib/emptyModelCreation";
import * as EntityTypes from "../../constants/EntityTypes";
import DatePicker from "../uifabricextensions/DatePicker";
import moment from "moment";
import {
  filterByReferenceToRelatedEntities,
  filterByRelatedEntity,
} from "../../selectors/storehelper";
import DetailsListBasic from "../DetailsListBasic";
import BasePickerSimple from "../uifabricextensions/BasePickerSimple";
import FormCommand from "../../lib/FormCommand";

export default class AccountForm extends SingleBaseForm {
  constructor(props) {
    super(props);
    let { models } = props;
    let account = models.find((model) => getEntityType(model) == "Account");
    this.state = {
      selectedAbsencePeriodTypesKeys: [],
    };

    this.state.fields = SingleBaseForm._initializeFields(
      {
        name: {
          getVal: () => getAttr(account, "name"),
          setVal: () =>
            createEmptyModelAttributes(EntityTypes.ACCOUNT, {
              name: this.state.fields.name.value,
            }),
          isRequired: true,
        },
        azureAdTenantId: {
          getVal: () => getAttr(account, "azureAdTenantId"),
          setVal: () =>
            createEmptyModelAttributes(EntityTypes.ACCOUNT, {
              azureAdTenantId: this.state.fields.azureAdTenantId.value,
            }),
          isRequired: true,
        },
        primaryLanguageId: {
          getVal: () => getRelId(account, "primaryLanguage"),
          setVal: () =>
            createEmptyModelRelationships(EntityTypes.ACCOUNT, {
              primaryLanguage: {
                id: this.state.fields.primaryLanguageId.value,
                type: EntityTypes.LANGUAGE,
              },
            }),
          isRequired: true,
        },
        primaryCurrencyId: {
          getVal: () => getRelId(account, "primaryCurrency"),
          setVal: () =>
            createEmptyModelRelationships(EntityTypes.ACCOUNT, {
              primaryCurrency: {
                id: this.state.fields.primaryCurrencyId.value,
                type: EntityTypes.CURRENCY,
              },
            }),
          isRequired: true,
        },
        internalClientId: {
          getVal: () => getRelId(account, "internalClient"),
          setVal: () =>
            createEmptyModelRelationships(EntityTypes.ACCOUNT, {
              internalClient: {
                id: this.state.fields.internalClientId.value,
                type: EntityTypes.CLIENT,
              },
            }),
          isRequired: false,
        },
        defaultVacationHours: {
          getVal: () => getAttr(account, "defaultVacationHours"),
          setVal: () =>
            createEmptyModelAttributes(EntityTypes.ACCOUNT, {
              defaultVacationHours:
                this.state.fields.defaultVacationHours.value,
            }),
          isRequired: false,
        },
        defaultVacationResetDate: {
          getVal: () => getAttr(account, "defaultVacationResetDate"),
          setVal: () =>
            createEmptyModelAttributes(EntityTypes.ACCOUNT, {
              defaultVacationResetDate: moment(
                this.state.fields.defaultVacationResetDate.value
              ).format("YYYY-MM-DD HH:mm:ss"),
            }),
          isRequired: false,
        },

        absenceRegistrationProjects: {
          getVal: () =>
            this.props.models.filter(
              (model) => getEntityType(model) == "AbsenceRegistrationProject"
            ),
          setVal: () => this.state.fields.absenceRegistrationProjects.value,
        },
      },
      models
    );

    this._onNameChange = this._onNameChange.bind(this);
    this._onAzureAdTenantIdChange = this._onAzureAdTenantIdChange.bind(this);
    this._onPrimaryLanguageIdChange =
      this._onPrimaryLanguageIdChange.bind(this);
    this._onPrimaryCurrencyIdChange =
      this._onPrimaryCurrencyIdChange.bind(this);
    this._onInternalClientIdChange = this._onInternalClientIdChange.bind(this);
    this._onDefaultVacationHoursChange =
      this._onDefaultVacationHoursChange.bind(this);
    this._onDefaultVacationResetDateChange =
      this._onDefaultVacationResetDateChange.bind(this);
    this._onProjectChange = this._onProjectChange.bind(this);
  }

  static propTypes = {
    onSave: PropTypes.func,
    onDismiss: PropTypes.func,
    clientForAccount: PropTypes.array,
    internalClientSelect: PropTypes.func,
    projectsByInternalClient: PropTypes.array,
  };

  // Overwriting _onSave() from BaseForm
  _onSave() {
    let formCommands = [];
    let fields = this.state.fields;
    let models = this.props.models;

    // Update account
    let oldAccount = models.find(
      (model) => apihelper.getEntityType(model) == "Account"
    );

    // Copied from _getModelChanges() in BaseForm
    let accountFieldsIsChanged = Object.keys(fields).filter(
      (k) => k != "absenceRegistrationProjects" && fields[k].isChanged
    );

    // Check if changes is empty
    if (accountFieldsIsChanged.length > 0) {
      let accountChanges = accountFieldsIsChanged
        .map((k) => fields[k])
        .map((field) => field.setVal(fields))
        .reduce((acc, cur) => SingleBaseForm._mergeDeep(acc, cur), {});

      // Apply accountChanges to oldAccount
      console.log("!", accountChanges);
      formCommands.push(new FormCommand(oldAccount, false, accountChanges));
    }

    // Update AbsenceRegistrationProjects
    let oldAbsenceRegistrationProjects = models.filter(
      (model) => getEntityType(model) == "AbsenceRegistrationProject"
    );
    let newAbsenceRegistrationProjects =
      fields.absenceRegistrationProjects.value;
    let newArpsFormCommands = [];
    let changeArpsFormCommands = [];
    let doNotDeleteArps = [];

    // Check for each AbsenceRegistrationProject in fields if it is new
    newAbsenceRegistrationProjects.forEach((arp) => {
      // If the project is not under the list of projectsByInternalClient, then don't add it, and it will be removed automaticly
      if (
        !this.props.projectsByInternalClient.find(
          (proj) =>
            apihelper.getEntityId(proj) == apihelper.getRelId(arp, "project")
        )
      )
        return null;

      // Getting relationhips
      let absencePeriodTypeId = apihelper.getRelId(arp, "absencePeriodType");
      let accountId = apihelper.getRelId(arp, "account");
      let projectId = apihelper.getRelId(arp, "project");

      // Searching for existing arp for account x absencePeriodType
      let existingArp = oldAbsenceRegistrationProjects.find((oldArp) => {
        let result =
          apihelper.relHasId(
            oldArp,
            "absencePeriodType",
            absencePeriodTypeId
          ) && apihelper.relHasId(oldArp, "account", accountId);
        return result;
      });

      if (existingArp) {
        // Already exists, so update instead of delete
        doNotDeleteArps.push(existingArp);

        let hasSameProjectId = apihelper.relHasId(
          existingArp,
          "project",
          projectId
        );
        console.log(hasSameProjectId);
        // Change project.
        if (!hasSameProjectId) {
          let newProjectRelationship = createEmptyModelRelationships(
            EntityTypes.ABSENCEREGISTRATIONPROJECT,
            {
              project: {
                id: projectId,
                type: EntityTypes.PROJECT,
              },
            }
          );
          console.log("!!", newProjectRelationship);
          console.log("!!", existingArp);
          changeArpsFormCommands.push(
            new FormCommand(existingArp, false, newProjectRelationship)
          );
        }
      } else {
        // New absenceRegistrationProject
        newArpsFormCommands.push(new FormCommand(arp, false, {}));
      }
    });

    // Getting difference between old and keep.
    let removeArps = oldAbsenceRegistrationProjects.difference(doNotDeleteArps);
    let removeArpsFormCommands = removeArps.map(
      (arp) => new FormCommand(arp, true)
    );

    formCommands = formCommands
      // Change these AbsenceRegistrationProjects
      .concat(changeArpsFormCommands)
      // Delete these AbsenceRegistrationProjects
      .concat(removeArpsFormCommands)
      // Add these AbsenceRegistrationProjects
      .concat(newArpsFormCommands);
    // Deletion first is necessary.
    this.props.onSave(formCommands);
  }

  _onNameChange(e) {
    let value = e.target.value;
    this._updateFieldState(
      "name",
      value,
      !value ? "Name cannot be empty" : undefined
    );
  }

  _onAzureAdTenantIdChange(e) {
    let value = e.target.value;
    this._updateFieldState(
      "azureAdTenantId",
      value,
      !value ? "Azure AD tenant ID cannot be empty" : undefined
    );
  }

  _onPrimaryLanguageIdChange(e, item) {
    this._updateFieldState(
      "primaryLanguageId",
      item.key,
      !item.key ? "Must select primary language" : undefined
    );
  }

  _onPrimaryCurrencyIdChange(e, item) {
    this._updateFieldState(
      "primaryCurrencyId",
      item.key,
      !item.key ? "Must select primary currency" : undefined
    );
  }

  _onInternalClientIdChange(e, item) {
    this._updateFieldState("internalClientId", item.key);
    this.props.internalClientSelect &&
      this.props.internalClientSelect(item.key);
  }

  _onDefaultVacationHoursChange(e) {
    let value = e.target.valueAsNumber;
    this._updateFieldState(
      "defaultVacationHours",
      value,
      !value
        ? "Default Vacation Hours has to be zero or a positive number"
        : undefined
    );
  }

  _onDefaultVacationResetDateChange(e) {
    let value = e;
    this._updateFieldState(
      "defaultVacationResetDate",
      value,
      !value ? "Default Vacation Reset Date has to be a date" : undefined
    );
  }

  // Update AbsenceRegistrtionProjects when a project is chosen
  _onProjectChange(apt, account, project, arp) {
    let updatedList =
      this.state.fields.absenceRegistrationProjects.value.slice();
    // removing the AbsenceRegistrationProject if it already existed for the AbsencePeriodType
    if (arp) {
      updatedList = updatedList.filter((loopArp) => loopArp != arp);
    }

    // If the callback return null, then the blank project was chosen and we should not do anything else.
    // If the callback does not return null, a new AbsenceRegistrationProject has to be chosen.
    if (project != null) {
      let changes = {
        absencePeriodType: {
          id: getEntityId(apt),
          type: EntityTypes.ABSENCEPERIODTYPE,
        },
        account: { id: getEntityId(account), type: EntityTypes.ACCOUNT },
        project: { id: getEntityId(project), type: EntityTypes.PROJECT },
      };
      let newArp = createEmptyModel(
        EntityTypes.ABSENCEREGISTRATIONPROJECT,
        {},
        changes
      );
      updatedList = [...updatedList, newArp];
    }

    // Create new fieldSpec with updatedList.
    let fieldSpec = {
      absenceRegistrationProjects: {
        value: updatedList,
        validationMessage: undefined,
      },
    };

    this._updateFieldState(fieldSpec);
  }

  render() {
    let {
      models,
      clientForAccount,
      allCurrency,
      allLanguage,
      allAbsencePeriodTypes,
    } = this.props;
    let allProjects = this.props.projectsByInternalClient; // Renaming
    let nameIsChanged = this.state.fields.name.isChanged,
      azureAdTenantIdIsChanged = this.state.fields.azureAdTenantId.isChanged,
      primaryLanguageIdIsChanged =
        this.state.fields.primaryLanguageId.isChanged,
      primaryCurrencyIdIsChanged =
        this.state.fields.primaryCurrencyId.isChanged,
      defaultVacationHoursIsChanged =
        this.state.fields.defaultVacationHours.isChanged;

    let account = models.find((model) => getEntityType(model) == "Account");
    let absenceRegistrationProjects =
      this.state.fields.absenceRegistrationProjects.value;

    let detailsListItems = allAbsencePeriodTypes.map((apt) => {
      let arpByType = filterByReferenceToRelatedEntities(
        absenceRegistrationProjects,
        apt,
        "absencePeriodType"
      );
      let arpByTypeAndAcc = arpByType
        ? filterByReferenceToRelatedEntities(arpByType, account, "account")
        : null;
      let arp = arpByTypeAndAcc ? arpByTypeAndAcc[0] : null;
      let selectedProject = arp
        ? filterByRelatedEntity(allProjects, arp, "project")[0]
        : null;
      let _onChange = (project) =>
        this._onProjectChange(apt, account, project, arp);
      let _renderProjectDropDown = () => {
        return (
          <BasePickerSimple
            entities={allProjects}
            isSelectionOptional={true}
            selectedEntity={selectedProject}
            onEntitySelected={_onChange}
            silentIfBlank={false}
          />
        );
      };
      return {
        id: getEntityId(apt),
        absencePeriodType: getAttr(apt, "name"),
        project: _renderProjectDropDown(),
      };
    });

    return (
      <div>
        <h2>{account ? "Edit" : "Create"} Account</h2>
        <br />
        <TextField
          label="Name"
          autoFocus={!account}
          iconProps={this._getIconProps(nameIsChanged)}
          onChange={this._onNameChange}
          required={this.state.fields.name.isRequired}
          value={this.state.fields.name.value}
          errorMessage={this.state.fields.name.validationMessage}
        />
        <br />

        <TextField
          label="Azure AD tenant ID"
          iconProps={this._getIconProps(azureAdTenantIdIsChanged)}
          onChange={this._onAzureAdTenantIdChange}
          required={this.state.fields.azureAdTenantId.isRequired}
          value={this.state.fields.azureAdTenantId.value}
          errorMessage={this.state.fields.azureAdTenantId.validationMessage}
        />

        <div className="novatime-combo-box">
          <Dropdown
            onChange={this._onPrimaryLanguageIdChange}
            label="Primary language"
            required={this.state.fields.primaryLanguageId.isRequired}
            options={allLanguage.map((l) => {
              return { key: getEntityId(l), text: l.attributes.name };
            })}
            selectedKey={this.state.fields.primaryLanguageId.value}
            buttonIconProps={this._getIconProps(primaryLanguageIdIsChanged)}
          />
          {/*<Label className="placeholder">{this.state.fields.primaryLanguageId.value ? '' : 'Select language'}</Label> //TODO: Problem when tab'ing into the field and press a key to find a language/currency that matches*/}
        </div>
        <div className="novatime-combo-box">
          <Dropdown
            label="Primary currency"
            required={this.state.fields.primaryCurrencyId.isRequired}
            onChange={this._onPrimaryCurrencyIdChange}
            options={allCurrency.map((c) => ({
              key: getEntityId(c) + "",
              text: c.attributes.name + " (" + c.attributes.currencyCode + ")",
            }))}
            selectedKey={this.state.fields.primaryCurrencyId.value}
            buttonIconProps={this._getIconProps(primaryCurrencyIdIsChanged)}
          />
          {/*<Label className="placeholder">{this.state.fields.primaryCurrencyId.value ? '' : 'Select currency'}</Label>*/}
        </div>

        {getEntityId(account) ? (
          <div>
            <div className="novatime-combo-box">
              <Dropdown
                label="Internal Client"
                required={this.state.fields.internalClientId.isRequired}
                onChange={this._onInternalClientIdChange}
                options={Object.values(clientForAccount).map((c) => ({
                  key: getEntityId(c) + "",
                  text: getAttr(c, "name"),
                }))}
                selectedKey={this.state.fields.internalClientId.value}
              />
            </div>

            <TextField
              label="Default Vacation Hours"
              autoFocus={!account}
              type={"number"}
              inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
              iconProps={this._getIconProps(defaultVacationHoursIsChanged)}
              onChange={this._onDefaultVacationHoursChange}
              required={this.state.fields.defaultVacationHours.isRequired}
              value={this.state.fields.defaultVacationHours.value}
              errorMessage={
                this.state.fields.defaultVacationHours.validationMessage
              }
            />

            <DatePicker
              label="Default Vacation Reset Date"
              value={this.state.fields.defaultVacationResetDate.value}
              onSelectDate={this._onDefaultVacationResetDateChange}
              displayArrows={false}
              isRequired={this.state.fields.defaultVacationResetDate.isRequired}
              errorMessage={
                this.state.fields.defaultVacationResetDate.validationMessage
              }
            />

            <DetailsListBasic
              columns={[
                {
                  key: "AbsencePeriodType",
                  name: "Absence Period Type",
                  fieldName: "absencePeriodType",
                  minWidth: 100,
                  maxWidth: 200,
                },
                {
                  key: "Project",
                  name: "Project for Absence Period Type",
                  fieldName: "project",
                  minWidth: 300,
                  maxWidth: 425,
                },
              ]}
              items={detailsListItems}
              onRenderItemColumn={(item, index, column) => {
                const fieldContent = item[column.fieldName];
                switch (column.key) {
                  case "AbsencePeriodType":
                    return <span>{fieldContent}</span>;
                  case "Project":
                    return <span>{fieldContent}</span>;
                }
              }}
              selectable={false}
            />
          </div>
        ) : null}
        <br />
        {this._renderButtons()}
        <br />
        {this._renderTimestampsAndId(account)}
      </div>
    );
  }
}

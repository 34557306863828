import {put, select} from 'redux-saga/effects';
import * as types from '../../../constants/ActionTypes';
import {isLoggedIn} from '../../../selectors/reducers/auth';
import {takeLatestAfterBootstrap} from '../../helpers';
import {resourceForCurrentUser} from '../../../selectors/reducers/resource';
import {checkLockedLastWeek, resourceSelected, weekSelected} from '../../../actions/pages/timeRegistration';
import {startTimeFilter} from '../../../selectors/pages/timeRegistration';
import moment from 'moment';

function* doTimeRegistrationPageOpened() {
    let startTime = yield select(startTimeFilter);
    if(!startTime){
        yield put(weekSelected(moment().startOf('isoWeek').toDate()));
    }

    const loggedIn = yield select(isLoggedIn);
    if (loggedIn) {
        const currentResource = yield select(resourceForCurrentUser);
        yield put(resourceSelected(currentResource));

        yield put(checkLockedLastWeek());
    }
}

export default function* timeRegistrationPageOpened() {
    yield takeLatestAfterBootstrap(types.APP_PAGE_TIMEREGISTRATION_OPENED, doTimeRegistrationPageOpened);
}

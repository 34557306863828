import * as types from '../../constants/ActionTypes';
import {removeItemFromList, updateDtoList} from '../helpers';
import {
  getAllAccountList,
  getDeleteAccountId,
  getIncludedAccount,
  getUpsertAccount
} from '../../selectors/actions/account';

const initialState = {
    storeRevision: 0,
    currentUserAccount: null,
    allAccounts: []
};

export default function accountReducer(state = initialState, action) {
    switch (action.type) {
        case types.API_USER_GET_ME_SUCCESS: {
            let includedAccounts = getIncludedAccount(action);
            if(includedAccounts && includedAccounts.length > 0){
                return updateDtoList(Object.assign({}, state, {
                    currentUserAccount: includedAccounts[0]
                }), 'allAccounts', [includedAccounts]);
            }
            else {
                return state;
            }
        }

        case types.API_ACCOUNT_GET_ALL_SUCCESS: {
            return updateDtoList(state, 'allAccounts', getAllAccountList(action));
        }

        case types.API_ACCOUNT_CREATE_SUCCESS:
        case types.API_ACCOUNT_UPDATE_SUCCESS:
        {
            return updateDtoList(state, 'allAccounts', [getUpsertAccount(action)]);
        }
        case types.API_ACCOUNT_DELETE_SUCCESS:
        {
            return removeItemFromList(state, 'allAccounts', [getDeleteAccountId(action)]);
        }

        default:
            return state
    }
}


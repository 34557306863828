import * as SagaHelpers from '../helpers';
import * as SagaEffects from 'redux-saga/effects';
import * as types from '../../constants/ActionTypes';
import ContractRoleConnector from '../../connectors/ContractRole';

const createContractRole = SagaHelpers.createApiHandler(
    types.API_CONTRACTROLE_CREATE_INIT, 
    SagaHelpers.generateCreateApiCall(
        ContractRoleConnector,
        function*(connector: ContractRoleConnector, action: any): any {
            return yield SagaEffects.call([connector, connector.createContractRole], {
                data: action.payload.model
            });
        }
    )
)

const deleteContractRole = SagaHelpers.createApiHandler(
    types.API_CONTRACTROLE_DELETE_INIT, 
    SagaHelpers.generateDeleteApiCall(
        ContractRoleConnector,
        function*(connector: ContractRoleConnector, action: any): any {
            return yield SagaEffects.call([connector, connector.deleteContractRole], action.payload.model.id);
        }
    )
)

const getAllContractRoles = SagaHelpers.createApiHandler(
    types.API_CONTRACTROLE_GET_ALL_INIT, 
    SagaHelpers.generateGetAllApiCall(
        ContractRoleConnector,
        function*(connector: ContractRoleConnector, action: any): any {
            const contractId = action.payload.contract ? action.payload.contract.id : null;
            return yield SagaEffects.call([connector, connector.getContractRoles], contractId);
        }
    )
)

const updateContractRoles = SagaHelpers.createApiHandler(
    types.API_CONTRACTROLE_UPDATE_INIT, 
    SagaHelpers.generateUpdateApiCall(
        ContractRoleConnector,
        function*(connector: ContractRoleConnector, action: any, requestData: any): any {
            return yield SagaEffects.call([connector, connector.updateContractRole], action.payload.model.id, requestData);
        }
    )
)

export default function* contractRoleSaga() {
    yield SagaEffects.all([
        SagaEffects.fork(getAllContractRoles),
        SagaEffects.fork(createContractRole),
        SagaEffects.fork(updateContractRoles),
        SagaEffects.fork(deleteContractRole),
    ]);
}

import * as SagaEffects from 'redux-saga/effects';
import * as types from '../../../constants/ActionTypes';
import * as clientActions from '../../../actions/api/client';
import * as clientsAndContractsSelectors from '../../../selectors/pages/clientsAndContracts';
import * as clientsAndContractsActions from '../../../actions/pages/clientsAndContracts';
import * as appDomainSelectors from '../../../selectors/app/domain';
import * as sagahelpers from '../../../sagas/helpers'

function* doLoadClients(): any {
    // fetch clients
    const selectedAccount = yield(SagaEffects.select(appDomainSelectors.selectedAccount))
    const response = yield sagahelpers.callAndAwait(clientActions.getAllClients(selectedAccount));
    // select client if none selected
    if (response.success) {
        const clientId = yield SagaEffects.select(clientsAndContractsSelectors.selectedClientId);
        if (!clientId) {
            const clients = yield SagaEffects.select(appDomainSelectors.allClientsForSelectedAccount);
            const client = clients[0];
            if (client) {
                yield SagaEffects.put(clientsAndContractsActions.selectClient(client));
            }
        }
    }
}

export default function* loadClients() {
    yield SagaEffects.takeLatest(types.APP_PAGE_CLIENTS_AND_CONTRACTS_LOAD_CLIENTS, doLoadClients);
}

import {
  NewResourceObject,
  ResourceObject,
  ResourceObjects,
} from "../lib/models";
import { useState, useCallback } from "react";

export type FieldValueBase<ValueType> = {
  isRequired: boolean;
  isChanged: boolean;
  originalValue: ValueType;
};

export type FieldValue<ValueType> = {
  value: ValueType;
};

export type FieldValidation = {
  isValid: boolean;
  validationMessage?: string;
};

export type FieldValueFull<ValueType> = FieldValueBase<ValueType> &
  FieldValue<ValueType> &
  FieldValidation;

export type InternalFields = Record<string, FieldValueFull<any>>;

export type PublicFields = Record<string, FieldValue<any>>;

/**
 * The FieldSpec is the collection of functions that summarize how a field in the form should behave when being modified
 */
export type FieldSpec<
  ModelType extends ResourceObject | ResourceObjects,
  ValueType
> = {
  // This is called to initialize the field value from the model the form based upon
  fromModel: (model: ModelType) => ValueType;
  // If the field required validation, this method will be called when the field value has been updated
  validate?: (currentValues: PublicFields) => FieldValidation;
  // Optional custom method for changing the value of the field. For instance if the field value depends on
  // the value of other fields in the form.
  update?: (
    newValue: ValueType,
    currentValues: PublicFields
  ) => FieldValue<ValueType>;
  // Extraction method once the form should save. provided with an instance of the model the form is based upon,
  // this method should transfer exactly this field to the instance
  copyToModel: (
    model: NewResourceObject,
    currentValue: FieldValue<ValueType>
  ) => void;
  // Mark if the field is required. Can be dynamic, depending on values of other fields
  isRequired?: boolean | ((fields: PublicFields) => boolean);
  // If the model does not supply an initial value, this value will be used instead.
  defaultValue?: ValueType;
};

function calcIsRequiredField<
  ModelType extends ResourceObject | ResourceObjects
>(fieldSpec: FieldSpec<ModelType, any>, fields: InternalFields) {
  return typeof fieldSpec.isRequired === "boolean"
    ? fieldSpec.isRequired
    : typeof fieldSpec.isRequired === "function"
    ? fieldSpec.isRequired(fields)
    : false;
}

function initializeFields<ModelType extends ResourceObject | ResourceObjects>(
  spec: BaseFormFieldSpec<ModelType>,
  model: ModelType
): InternalFields {
  // first pass to get values
  const fields = Object.keys(spec).reduce((result, fieldName) => {
    const fieldSpec = spec[fieldName];
    const value = fieldSpec.fromModel(model);
    result[fieldName] = {
      isChanged: false,
      isValid: true,
      originalValue: value,
      value: value || fieldSpec.defaultValue,
    } as FieldValueFull<any>;
    return result;
  }, {} as InternalFields);
  // second pass, calculate required
  Object.keys(fields).forEach((fieldName) => {
    const fieldSpec = spec[fieldName];
    fields[fieldName].isRequired = calcIsRequiredField(fieldSpec, fields);
  });
  // third pass, calculate validation
  Object.keys(fields).forEach((fieldName) => {
    const fieldSpec = spec[fieldName];
    if (fieldSpec.validate) {
      const fieldValidation = fieldSpec.validate(fields);
      // dont transfer validation message on purpose so error texts are not shown before field values are changed by the user
      fields[fieldName].isValid = fieldValidation.isValid;
    }
  });
  return fields;
}

export type BaseFormFieldSpec<
  ModelType extends ResourceObject | ResourceObjects
> = Record<string, FieldSpec<ModelType, any>>;

const defaultUpdateMethod = (newValue: any, _: any) => ({
  value: newValue,
});

// run through every field and update the validation
function updateFieldValidation<
  ModelType extends ResourceObject | ResourceObjects
>(spec: BaseFormFieldSpec<ModelType>, fields: InternalFields) {
  for (const fieldName in fields) {
    const fieldSpec = spec[fieldName];
    const updatedValidation = fieldSpec.validate
      ? fieldSpec.validate(fields)
      : { isValid: true };

    // update the validation part
    fields[fieldName] = Object.assign(fields[fieldName], updatedValidation);
  }
}

export function useFormFields<
  ModelType extends ResourceObject | ResourceObjects
>(
  spec: BaseFormFieldSpec<ModelType>,
  model: ModelType
): [
  fields: InternalFields,
  update: (fieldName: string, value: any) => void,
  isValid: boolean,
  isChanged: boolean,
  copyToModel: (model: NewResourceObject) => void
] {
  const [fields, setFields] = useState(() => initializeFields(spec, model));
  const update = useCallback(
    (fieldName: string, newValue: any) => {
      if (fieldName in fields) {
        const curValue = fields[fieldName];
        const fieldSpec = spec[fieldName];

        const updateMethod = fieldSpec.update || defaultUpdateMethod;

        // get the field in question updated
        const updatedValue = updateMethod(newValue, fields);
        const updatedField = {
          [fieldName]: Object.assign({}, curValue, updatedValue, {
            isChanged: updatedValue.value !== curValue.originalValue,
            isRequired: calcIsRequiredField(fieldSpec, fields),
          }),
        };

        // generate the whole picture with the remaining fields
        const updatedFields = Object.assign({}, fields, updatedField);

        // run validation on complete picture and update the validation part
        updateFieldValidation(spec, updatedFields);

        setFields(updatedFields);
      }
    },
    [fields]
  );

  const isChanged = Object.keys(fields).reduce(
    (acc, fieldName) => fields[fieldName].isChanged || acc,
    false
  );
  const isValid = Object.keys(fields).reduce(
    (acc, fieldName) => fields[fieldName].isValid && acc,
    true
  );

  const copyToModel = (model: NewResourceObject) =>
    Object.keys(fields).forEach((fieldName) =>
      spec[fieldName].copyToModel(model, fields[fieldName])
    );

  return [fields, update, isValid, isChanged, copyToModel];
}

import React, { Component } from "react";
import { Provider } from "react-redux";
import configureStore from "../store/configureStore";
import { Route } from "react-router-dom";
import { ConnectedRouter } from "connected-react-router";
import { history } from "../lib/history";
import Novatime from "./Novatime";

const store = configureStore({}); // init state

export default class App extends Component {
  render() {
    return (
      <Provider store={store}>
        <ConnectedRouter history={history}>
          <Route path="/" component={Novatime} />
        </ConnectedRouter>
      </Provider>
    );
  }
}

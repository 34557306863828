import React, { Component } from "react";
import PropTypes from "prop-types";
import { Callout } from "@fluentui/react/lib/Callout";

export default class InvalidRegistrationCallout extends Component {
  static propTypes = {
    isCalloutVisible: PropTypes.bool,
    targetElement: PropTypes.object,
    onDismiss: PropTypes.func,
    validations: PropTypes.arrayOf(PropTypes.string),
  };

  static defaultProps = {
    isCalloutVisible: false,
    targetElement: null,
    validations: [],
  };

  render() {
    return (
      <Callout
        className="invalid-timeregistration-callout"
        gapSpace={0}
        hidden={!this.props.isCalloutVisible}
        target={this.props.targetElement && this.props.targetElement.current}
        onDismiss={this.props.onDismiss}
        calloutMaxWidth={500}
        setInitialFocus={true}
      >
        <div className="header">
          <p>Registration is invalid</p>
        </div>
        <div className="inner">
          <div className="content">
            <p>
              Time registration had validation errors. Some of these may cause
              that no updates are sent to the backend.
            </p>
            <ul>
              {this.props.validations.map((v, idx) => (
                <li key={idx}>{v}</li>
              ))}{" "}
            </ul>
            <p>
              You can roll back your changes on the button in the Api request
              log accessed, in the top right corner, or trigger a resend of the
              request by making an update to the time registration.
            </p>
          </div>
          <div className="actions"></div>
        </div>
      </Callout>
    );
  }
}

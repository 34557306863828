import { put, select, takeLatest } from "redux-saga/effects";
import * as types from "../../../constants/ActionTypes";
import { resourceForCurrentUser } from "../../../selectors/reducers/resource";
import { getAllLockedTimePeriods } from "../../../actions/api/lockedTimePeriod";
import moment from "moment";
import * as sagaHelpers from "../../helpers";

function* doCheckMissedLockedLastWeek() {
  let startDate = moment()
    .startOf("isoWeek")
    .add(-1, "week")
    .add(-1, "day")
    .format("YYYY-MM-DD");
  let endDate = moment()
    .startOf("isoWeek")
    .add(-1, "week")
    .endOf("isoWeek")
    .add(1, "day")
    .format("YYYY-MM-DD");
  const resource = yield select(resourceForCurrentUser);

  yield sagaHelpers.callAndAwait(
    getAllLockedTimePeriods({ resource, startDate, endDate })
  );

  yield put({
    type: types.APP_PAGE_TIMEREGISTRATION_CHECK_LOCKED_LAST_WEEK_COMPLETE,
  });
}

export default function* checkMissedLockedLastWeek() {
  yield takeLatest(
    types.APP_PAGE_TIMEREGISTRATION_CHECK_LOCKED_LAST_WEEK,
    doCheckMissedLockedLastWeek
  );
}

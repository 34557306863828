import * as actionhelper from '../actionhelper';
import * as EntityTypes from '../../constants/EntityTypes';

export const getAllResorceList = actionhelper.apiResponseData;

export const getUpsertResource = actionhelper.apiResponseData;

export const getDeleteResourceId = actionhelper.apiSentModelId;

export const getIncludedResources = (action: any) => actionhelper.apiResponseIncludedOfType(action, EntityTypes.RESOURCE);

import React, { ReactNode, useCallback, useMemo, useState } from "react";
import "../../lib/times";
import * as datehelper from "../../lib/date";
import BusinessCalendar, { Locale } from "../../lib/businessCalendar";

const calendar = new BusinessCalendar();
const locale = "dk";

export type WeekDay = {
  date: Date;
  isWeekend: boolean;
  isHoliday: boolean;
  isToday: boolean;
  names: {
    dayFull: string;
    dayShort: string;
    dayRemaining: string;
    dateAndShortMonth: string;
    yyyyMmDd: string;
    holiday?: string;
    summary: string;
  };
};

type TableRow = {
  id: string;
};

const toTableRows = (rowIds: string[]): TableRow[] =>
  rowIds.map((id) => ({ id }));

const genWeekDay = (
  date: Date,
  calendar: BusinessCalendar,
  locale: Locale
): WeekDay => {
  const nameOfWeekDay = datehelper.toWeekday(date),
    yyyyMmDd = datehelper.toYyyyMmDd(date),
    isHoliday = calendar.isHoliday(date, locale),
    holiday = calendar.getHolidayName(date, locale),
    isToday = datehelper.isToday(date);
  return {
    date: datehelper.sameDayAt(date, 0),
    isWeekend: calendar.isWeekend(date),
    isHoliday,
    isToday,
    names: {
      dayFull: nameOfWeekDay,
      dayShort: nameOfWeekDay.substring(0, 3),
      dayRemaining: nameOfWeekDay.substring(3),
      dateAndShortMonth: `${datehelper.toOrdinalDayOfMonth(
        date
      )} ${datehelper.toMonth(date)}`,
      yyyyMmDd,
      holiday,
      summary: `${yyyyMmDd}${isHoliday ? ", " + holiday : ""}${
        isToday ? ", Today" : ""
      }`,
    },
  };
};

const genWeekDays = (startOfWeek: Date) =>
  [0, 1, 2, 3, 4, 5, 6].map((addDays) =>
    genWeekDay(datehelper.addDays(startOfWeek, addDays), calendar, locale)
  );

type WeekDayHeaderProps = {
  day: WeekDay;
};

const toClassNames = (day: WeekDay): string =>
  [
    day.isWeekend ? "weekend" : "",
    day.isHoliday ? "holiday" : "",
    day.isToday ? "today" : "",
  ]
    .filter(Boolean)
    .join(" ");

function WeekDayHeader({
  day,
  children,
}: React.PropsWithChildren<WeekDayHeaderProps>) {
  const classNames = toClassNames(day);

  return (
    <th
      title={day.names.summary}
      key={"fullsize-table-header-" + day.names.yyyyMmDd}
      className={classNames}
    >
      <div>
        {day.names.dayShort}
        <span className="ms-hiddenLgDown">{day.names.dayRemaining}</span>
        <br />
        <span className="ms-font-m ms-hiddenLgDown">
          {day.names.dateAndShortMonth}
        </span>
      </div>
      {children}
    </th>
  );
}

type TableRenderProps = {
  renderRowHeader: (rowId: string, weekDays: Date[]) => ReactNode;
  renderRowCell: (rowId: string, weekDay: Date) => ReactNode;
  renderRowSummation: (rowId: string) => ReactNode;
  renderDaySummation: (weekDay: Date) => ReactNode;
  renderWeekSummation: () => ReactNode;
  renderFooterHeader: () => ReactNode;
  renderAdditionalHeader?: (weekDay: Date) => ReactNode;
};

type DesktopWeekGridProps = TableRenderProps & {
  weekDays: WeekDay[];
  rows: TableRow[];
};

function DesktopWeekGrid({
  weekDays,
  rows,
  renderDaySummation,
  renderRowHeader,
  renderRowCell,
  renderWeekSummation,
  renderRowSummation,
  renderFooterHeader,
  renderAdditionalHeader,
}: DesktopWeekGridProps) {
  const weekAsDates = useMemo(() => weekDays.map((w) => w.date), [weekDays]);
  return (
    <div className="novatime-week-registration-fullsize">
      <table className={"ms-Table novatime-week-registration-table"}>
        <thead>
          <tr>
            <th />
            {weekDays.map((d) => (
              <WeekDayHeader day={d} key={d.names.yyyyMmDd}>
                {renderAdditionalHeader ? renderAdditionalHeader(d.date) : null}
              </WeekDayHeader>
            ))}
            <th />
          </tr>
        </thead>
        <tbody>
          {rows.map((row) => (
            <tr key={"fullsize-table-data-row-" + row.id}>
              {<td>{renderRowHeader(row.id, weekAsDates)}</td>}
              {weekDays.map((d) => (
                <td
                  key={"fullsize-table-data-cell-" + row.id + d.names.yyyyMmDd}
                  className={toClassNames(d)}
                >
                  {renderRowCell(row.id, d.date)}
                </td>
              ))}
              <td>{renderRowSummation(row.id)}</td>
            </tr>
          ))}
          <tr>
            <td>{renderFooterHeader()}</td>
            {rows.length > 0 ? (
              <React.Fragment>
                {weekDays.map((d) => (
                  <td
                    key={"fullsize-table-footer-" + d.names.yyyyMmDd}
                    className={toClassNames(d)}
                  >
                    {renderDaySummation(d.date)}
                  </td>
                ))}
                <td>{renderWeekSummation()}</td>
              </React.Fragment>
            ) : null}
          </tr>
        </tbody>
      </table>
    </div>
  );
}

type MobileWeekGridProps = TableRenderProps & {
  weekDays: WeekDay[];
  rows: TableRow[];
};

function MobileWeekGrid({
  weekDays,
  rows,
  renderDaySummation,
  renderRowHeader,
  renderRowCell,
  renderWeekSummation,
  renderRowSummation,
  renderFooterHeader,
}: MobileWeekGridProps) {
  const [openDay, setOpenDay] = useState<string | null>(null);
  const onDayClick = useCallback(
    (weekDay: WeekDay) =>
      setOpenDay(
        openDay === weekDay.names.yyyyMmDd ? null : weekDay.names.yyyyMmDd
      ),
    [openDay]
  );
  const weekAsDates = useMemo(() => weekDays.map((w) => w.date), [weekDays]);

  return (
    <div className="novatime-week-registration-mobile">
      {weekDays.map((weekDay) => (
        <div
          className={
            "novatime-week-registration-accordion-item" +
            (openDay === weekDay.names.yyyyMmDd ? " selected" : "")
          }
          key={"mobile-week-day-" + weekDay.names.dayShort}
        >
          <div
            title={weekDay.names.summary}
            className={"novatime-week-registration-accordion-item-header"}
            onClick={() => onDayClick(weekDay)}
          >
            {weekDay.names.dayShort}
            {weekDay.names.dayRemaining} {weekDay.names.dateAndShortMonth}
            <span>{renderDaySummation(weekDay.date)}</span>
          </div>
          <div className="novatime-week-registration-accordion-item-body">
            <table className={" ms-Table novatime-week-registration-table"}>
              <tbody>
                {rows.map((row) => (
                  <tr
                    key={"mobile-table-data-row-" + row.id}
                    className={toClassNames(weekDay)}
                  >
                    <td>{renderRowHeader(row.id, weekAsDates)}</td>
                    <td key={"mobile-table-data-cell-" + row.id + weekDay.date}>
                      {renderRowCell(row.id, weekDay.date)}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      ))}
      <div style={{ marginTop: "1em" }}>{renderFooterHeader()}</div>
    </div>
  );
}

type WeekGridProps = TableRenderProps & {
  startOfWeek: Date;
  rowIds: string[];
};

export default function WeekGrid({
  startOfWeek,
  renderRowHeader,
  renderRowCell,
  renderRowSummation,
  renderDaySummation,
  renderWeekSummation,
  renderFooterHeader,
  renderAdditionalHeader,
  rowIds,
}: WeekGridProps) {
  const weekDays = useMemo(() => genWeekDays(startOfWeek), [startOfWeek]);
  const rows = useMemo(() => toTableRows(rowIds), [rowIds]);
  const childGridProps = {
    renderRowHeader,
    renderRowCell,
    renderRowSummation,
    renderDaySummation,
    renderWeekSummation,
    renderFooterHeader,
    rows,
    renderAdditionalHeader,
    weekDays,
  };
  return (
    <div className="novatime-week-registration">
      <DesktopWeekGrid {...childGridProps} />
      <MobileWeekGrid {...childGridProps} />
    </div>
  );
}

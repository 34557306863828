import * as ActionTypes from '../../constants/ActionTypes';

export const getApiActionNameSplit  = (action: any) => action.type.match(/^API_([^_]+)_([^_]+)(.*)_(.+)$/) || [];
export const getApiCallState        = (action: any) => getApiActionNameSplit(action)[4];
export const getApiCallType         = (action: any) => getApiActionNameSplit(action)[2];
export const getApiCallEntity       = (action: any) => getApiActionNameSplit(action)[1];
export const isInitApiAction        = (action: any) => getApiCallState(action) === 'INIT';
export const isRequestApiAction     = (action: any) => getApiCallState(action) === 'REQUEST';
export const isFailedApiAction      = (action: any) => getApiCallState(action) === 'ERROR';
export const isSuccessfulApiAction  = (action: any) => getApiCallState(action) === 'SUCCESS';
export const isCancelledApiAction   = (action: any) => action.type === ActionTypes.API_CALL_CANCELLED;
export const isCreateApiAction      = (action: any) => getApiCallType(action) === 'CREATE';
export const isUpdateApiAction      = (action: any) => getApiCallType(action) === 'UPDATE';
export const isDeleteApiAction      = (action: any) => getApiCallType(action) === 'DELETE';
export const isGetApiAction         = (action: any) => getApiCallType(action) === 'GET';
export const isApiAction            = (action: any) => action.type.match(/^API_.*(INIT|REQUEST|SUCCESS|ERROR)$/);

import  React, { Component } from 'react';
import { TagPicker } from '@fluentui/react';
import { ClientProjectPickerClass, PickerTree, getEntityKey } from './ClientAndProjectPicker';
import { Icon } from '@fluentui/react/lib/Icon'; 
import _uniqueId from 'lodash/uniqueId'
import * as apihelper from '../../selectors/apihelper';
import * as storehelper from '../../selectors/storehelper';
import * as EntityTypes from '../../constants/EntityTypes';
import * as icons from '../../constants/Icons'

export class ClientAndProjectTagPicker extends Component {
  constructor(props){
    super(props);
    this.state = {
      input : ""   // input is the search text
    }

    this._onItemRemove = this._onItemRemove.bind(this);
    this._renderItemsToSelectFrom = this._renderItemsToSelectFrom.bind(this);
    this.id = _uniqueId("tagBar-");

    this._filterSuggestions = this._filterSuggestions.bind(this);
  }

  _renderItemsToSelectFrom(){
    //If the user is searching for projects, the avaliable projects should be the ones whose name begins with the written text
    if(this.state.input){ 
      let clients           = this.props.entities.filter(entity => apihelper.getEntityType(entity) === EntityTypes.CLIENT);
      let projectsAvaliable = this.props.entities.filter(entity => apihelper.getEntityType(entity) === EntityTypes.PROJECT);                             // Removing the client from the array
      projectsAvaliable     = projectsAvaliable.filter(projects => 
                                apihelper.getAttr(projects,"name").toLowerCase().slice(0,this.state.input.length) ===  this.state.input.toLowerCase() ); // Remove projects whose name does not match the written string
           
        return(
          <ClientProjectPickerClass
            onSelection       = { this.props.onSelection}
            entities          = { clients.concat(projectsAvaliable) }
            hierarchyData     = { this.props.hierarchyData }
            getEntityParentFunction = { this.props.getEntityParentFunction }
            selectedProjects  = {this.props.selectedProjects}
            inSearch          = {true}                                          // Ensures that there is no deselection when calling the component
          />
        )
      }
    
    //When no text is written we suggest all clients and projects
    return(
      <ClientProjectPickerClass
        onSelection       = { this.props.onSelection}
        entities          = { this.props.entities }
        hierarchyData     = { this.props.hierarchyData }
        getEntityParentFunction = { this.props.getEntityParentFunction }
        selectedProjects  = {this.props.selectedProjects}
      />
    )
  }

  _onItemRemove(items){
    let remainingProjects = this.props.selectedProjects.map(e => Object.assign({ }, e));       // Copy the selectedProjects for which we need to removed the corresponding projects for selected tag
    let itemsWhichWereDisplayedInTheTagBar = this._itemsToDisplay(this.props.entities, this.props.hierarchyData, this.props.selectedProjects, this.props.getEntityParentFunction);
    let removedTag = itemsWhichWereDisplayedInTheTagBar.filter(displayed => (items.find( item => item.key === displayed.key) === undefined ) );   // The list of removed tags.
    
    removedTag.forEach(tag => {                      // Loop to remove the corresponding projects of the removed tags
      if(tag.type === EntityTypes.PROJECT){          // If the removed tag was a project, we remove that project from the selected projects.
        remainingProjects = remainingProjects.filter(project => tag.id !== apihelper.getEntityId(project));
      }
      else{                                          // If the removed tag was a client, we have to remove every projec which is owned by the removed client
        remainingProjects = remainingProjects.filter(project => !(tag.childrenIds.includes(project.id) ) );
      }
    })
    this.props.onSelection(remainingProjects.map(project => project.id));       // Callback on the remaining projects.
  }

  generateTree(entities, hierarchyData, getEntityParentFunction) {
    let tree = new PickerTree();
    
    entities.forEach(entity => tree.insert( entity, 
                                            getEntityParentFunction(entity, hierarchyData).type,
                                            getEntityParentFunction(entity, hierarchyData).value));
    return tree;
  }

  generateGroupsAndItemsOfListOfDepthOne(tree) {
    let topLevel = tree
      .getTopLevelNodes()
      .sort( storehelper.sortByValues( pickerNode => apihelper.getAttr(pickerNode.entity,"name").toLowerCase() ) );
    let itemArray = [];
    let group = [];
    let currentItemLength = 0;

    topLevel.forEach(topLevelEntity => {
      let secondLevel = tree.getChildrenOfParent(topLevelEntity).sort( storehelper.sortByValues( pickerNode => apihelper.getAttr(pickerNode.entity,"name").toLowerCase() ) );
      
      itemArray = itemArray.concat(secondLevel.map((secondLevelEntity) => ({
        name: apihelper.getAttr(secondLevelEntity.entity, "name"), 
        key: getEntityKey(secondLevelEntity.entity),
        type: apihelper.getEntityType(secondLevelEntity.entity),
        id: apihelper.getEntityId(secondLevelEntity.entity)
      }) ) );
    
      group.push({
        count: secondLevel.length,
        key: getEntityKey(topLevelEntity.entity),
        level: 0,
        name: apihelper.getAttr(topLevelEntity.entity, "name"),
        startIndex: currentItemLength,
      });
      currentItemLength += secondLevel.length;
    })
    return([group, itemArray]);
  }

  _itemsToDisplay(entities, hierarchyData, selectedProjects, getEntityParentFunction){
    let tree = this.generateTree(entities, hierarchyData, getEntityParentFunction);
    let [groups, items] = this.generateGroupsAndItemsOfListOfDepthOne(tree)
    let itemsAtOurDisposal = [];
    let indexOfItemArray = 0 ; 

    groups = groups.filter(group => group.count !== 0);          //Removes clients with no projects.

    groups.forEach(group => {            // Building an array containing the clients following by their projects.
        itemsAtOurDisposal.push( {          // Adding the client
            name:   group.name +":",   
            id:     group.key ,             // These are a text string followed by a number
            type:   EntityTypes.CLIENT,   
            key:    group.key, 
            count:  group.count, 
            childrenIds: [],               // Needed for removal of the corresponding projects, when the client is displayed in the tag bar
            title:  "" } )                 // Needed for the render class to enable the hover function of the tags.

        let currentClientIndex = (itemsAtOurDisposal.length - 1 );
        for(let projectsAssociatedToTheClient = 0 ; projectsAssociatedToTheClient < group.count ; projectsAssociatedToTheClient++){ // Add the corresponding projects
            itemsAtOurDisposal.push( items[indexOfItemArray]) 
            itemsAtOurDisposal[currentClientIndex].childrenIds.push(items[indexOfItemArray].id)                               // Adding the IDs of the projects to the client
            itemsAtOurDisposal[currentClientIndex + projectsAssociatedToTheClient + 1].title = "Project of " + group.name;    // Adding title for the hover function of the tags
            indexOfItemArray++
        }
    });

    let itemsToDisplay = itemsAtOurDisposal.filter(item =>    // Removing every projects which is not selected.
                    (item.type === EntityTypes.CLIENT) ||     // Do not remove clients 
                    (selectedProjects.find( selectedProject => apihelper.getEntityId(selectedProject) === item.id ) ) ); // Remove unselected projects

    // In the following we check weather or not every project of a given client is selected. If they are, remove the projects and display the client
    // If every project is not selected, display the projects instead.

    itemsToDisplay.forEach( isClient => {
      if(isClient.type === EntityTypes.CLIENT ) {  
        isClient.numberOfSelectedProjects = ( itemsToDisplay.filter(isProject => isClient.childrenIds.includes(isProject.id))).length;  
      }
    });

    itemsToDisplay = itemsToDisplay.filter(item => item?.numberOfSelectedProjects !== 0 );   //Remove clients which have no selected projects.

    itemsToDisplay.forEach( (isClient)  => {
       if( ( isClient.type === EntityTypes.CLIENT) && isClient.count === isClient.numberOfSelectedProjects ) {       // Every project of a given client is selected.
          let projectsOfClient = itemsToDisplay.filter( item => isClient.childrenIds.includes(item.id));             // Get all projects of the client

          itemsToDisplay = itemsToDisplay.filter( item => ! isClient.childrenIds.includes(item.id) );                // Remove all projects of the client
          
          let titleOfclient = "Client with the following selected projects: \n";                                     // Create the displayed hover text
          projectsOfClient.forEach( project => titleOfclient = titleOfclient.concat(project.name +"\n"));
          
          let indexOfElement = itemsToDisplay.indexOf(isClient);
          itemsToDisplay[indexOfElement].title = titleOfclient;                                                      // Update the title of the displayed client
       } else if ( ( isClient.type === EntityTypes.CLIENT) && isClient.count !== isClient.numberOfSelectedProjects ){
          itemsToDisplay = itemsToDisplay.filter(item => !( item.type === EntityTypes.CLIENT && item.id === isClient.id)); // Remove the clients where every project is not selected
       }
    });

    return itemsToDisplay;
  }

  
  _filterSuggestions(input){
    this.setState({input : input});         // the input is needed in _renderItemsToSelectFrom
    return input? [] : undefined;           // this neededs to return undefined, in order to keep the callout opened when the input becomes and empty string.
  }                                        // if one wants to close the callout, return [] instead

  render(){
    let {entities,
      hierarchyData,
      getEntityParentFunction,
      selectedProjects,
     } = this.props;
     
    let itemsToDisplay = this._itemsToDisplay(entities, hierarchyData, selectedProjects, getEntityParentFunction);
  
    return (
      <div id = {this.id} > { /* This div is needed to prevent the suggestions from following the input field...... */ }
      <TagPicker
        items         = {itemsToDisplay}
        selectedItems = {itemsToDisplay}
        removeButtonAriaLabel = "Remove"
        styles = {{ 
          itemsWrapper: { backgroundColor: "rgb(245,245,245)",  }
        } }
        onResolveSuggestions = {this._filterSuggestions}
        pickerSuggestionsProps = {{ 
          suggestionsHeaderText: "Hold CTRL for multiselection ",
          onRenderNoResultFound: this._renderItemsToSelectFrom,
        }}
        pickerCalloutProps = {{
            preventDismissOnScroll: true,
            target: `#${this.id}`,        // Makes the selection menu mount under the tag-bar
            }}
        inputProps    = {{ style: { margin: 0 }, placeholder: "Click here for project selection",  }}
        onChange      = {this._onItemRemove}
        onRenderItem  = {(props) => <RenderClientAndProjectTags {...props}/>}
        onEmptyResolveSuggestions = {() => <h1>dummytext, since the dropdown wont be displayed otherwise </h1> } 
      />
      </div>
    );
  }
}



class RenderClientAndProjectTags extends Component{
  render(){
    let icon = icons.ICON_NAME_PROJECT;
    let titleOfDiv = this.props.item.title;
    
    if(this.props.item.type === EntityTypes.CLIENT){
        icon = icons.ICON_NAME_CLIENT;
    }
    
    return ( 
    <div style={{
        background: "#F5F5F5", 
        border: "1px solid black", 
        margin: "1px",
        fontSize: "14px",
        fontWeight: "400",
        padding: "2px 4px",
        borderRadius: "3px",
        }}
        title = {titleOfDiv}>
        <Icon iconName={icon} style={{
            color: "#0078D4", 
            fontSize: "16px", 
            margin: "2px",
            position: "relative", 
            top: "2px"}} /> 

        <span style={{margin: "2px", position: "relative", top: "-1px"}}>
            {this.props.item.name}
        </span>
        <button type= "button" 
                aria-label= {this.props.removeButtonAriaLabel} 
                onClick = {this.props.onRemoveItem} 
                style={{
                    marginLeft: "5px", 
                    background: "#F5F5F5", 
                    border:"none", 
                    fontSize: "12px"
                  }}> 
         <span style = {{fontSize: "16px", position: "relative", top: "-1px"}}>
            ✖
          </span>
        </button >
    </div>)
  }
}

import * as types from '../../../constants/ActionTypes';
import { takeLatest, put } from 'redux-saga/effects';
import { awaitCalls } from '../../helpers'

function* doLockPeriods(action) {
    yield put({ type: types.APP_DOMAIN_LOCK_PERIODS_STARTED})

    let listOfLockedTimePeriods = action.payload && action.payload.listOfLockedTimePeriods ?
        action.payload.listOfLockedTimePeriods : []

    let listOfPuts = []
    listOfLockedTimePeriods.forEach(model => {
        listOfPuts.push({
            type: types.API_LOCKEDTIMEPERIOD_CREATE_INIT,
            payload: { model }
        })
    })

    yield awaitCalls(listOfPuts)

    yield put({
        type: types.APP_DOMAIN_LOCK_PERIODS_COMPLETED,
        payload: {
            transactionId: action.payload ? action.payload.transactionId : null,
        }
    })
}

export default function* lockPeriod() {
    yield takeLatest(types.APP_DOMAIN_LOCK_PERIODS_INIT, doLockPeriods);
}

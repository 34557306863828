import React from "react";
import PropTypes from "prop-types";
import { Dropdown } from "@fluentui/react/lib/Dropdown";
import CustomPicker from "../uifabricextensions/CustomPicker";
import { Label } from "@fluentui/react/lib/Label";
import { Icon } from "@fluentui/react/lib/Icon";
import SingleBaseForm from "./SingleBaseForm";
import UserPicker from "../uifabricextensions/UserPicker";
import * as InternalPropTypes from "../../constants/PropTypes";
import {
  isEntity,
  getRelId,
  getAttr,
  getEntityId,
  entityHasId,
} from "../../selectors/apihelper";
import "../../lib/setOperations";
import { createEmptyModel } from "../../lib/emptyModelCreation";
import * as EntityTypes from "../../constants/EntityTypes";
import { lexicalInsensitiveNameSortFunc } from "../../lib/sorting";
import FormCommand from "../../lib/FormCommand";
import * as PermissionTypes from "../../constants/PermissionTypeNames";
import * as apihelper from "../../selectors/apihelper";
import "../../lib/setOperations";

export default class PermissionForm extends SingleBaseForm {
  constructor(props) {
    super(props);
    let { permissionTypeNameToIds } = props;

    this.state = {};

    this.state.fields = SingleBaseForm._initializeFields(
      {
        userId: {
          getVal: () => getEntityId(this.props.user),
          setVal: () => this.state.fields.userId.value,
          isRequired: false,
        },
        accountWrites: {
          getVal: () =>
            this.props.models
              .filter(
                (p) =>
                  getRelId(p, "permissionType") ==
                  permissionTypeNameToIds.AccountWrite
              )
              .map((p) => getRelId(p, "account")),
          setVal: () => this.state.fields.accountWrites.value,
          isRequired: false,
        },
        accountReads: {
          getVal: () =>
            this.props.models
              .filter(
                (p) =>
                  getRelId(p, "permissionType") ==
                  permissionTypeNameToIds.AccountRead
              )
              .map((p) => getRelId(p, "account")),
          setVal: () => this.state.fields.accountReads.value,
          isRequired: false,
        },
        clientWrites: {
          getVal: () =>
            this.props.models
              .filter(
                (p) =>
                  getRelId(p, "permissionType") ==
                  permissionTypeNameToIds.ClientWrite
              )
              .map((p) => getRelId(p, "client")),
          setVal: () => this.state.fields.clientWrites.value,
          isRequired: false,
        },
        clientReads: {
          getVal: () =>
            this.props.models
              .filter(
                (p) =>
                  getRelId(p, "permissionType") ==
                  permissionTypeNameToIds.ClientRead
              )
              .map((p) => getRelId(p, "client")),
          setVal: () => this.state.fields.clientReads.value,
          isRequired: false,
        },
        projectWrites: {
          getVal: () =>
            this.props.models
              .filter(
                (p) =>
                  getRelId(p, "permissionType") ==
                  permissionTypeNameToIds.ProjectWrite
              )
              .map((p) => getRelId(p, "project")),
          setVal: () => this.state.fields.projectWrites.value,
          isRequired: false,
        },
        projectReads: {
          getVal: () =>
            this.props.models
              .filter(
                (p) =>
                  getRelId(p, "permissionType") ==
                  permissionTypeNameToIds.ProjectRead
              )
              .map((p) => getRelId(p, "project")),
          setVal: () => this.state.fields.projectReads.value,
          isRequired: false,
        },
      },
      this.props.models
    );

    this._onUserIdChange = this._onUserIdChange.bind(this);
    this._onAccountWritesChange = this._onAccountWritesChange.bind(this);
    this._onAccountReadsChange = this._onAccountReadsChange.bind(this);
    this._onClientWritesChange = this._onClientWritesChange.bind(this);
    this._onClientReadsChange = this._onClientReadsChange.bind(this);
    this._onProjectWritesChange = this._onProjectWritesChange.bind(this);
    this._onProjectReadsChange = this._onProjectReadsChange.bind(this);
    this._onProjectWritesClear = this._onProjectWritesClear.bind(this);
    this._onProjectReadsClear = this._onProjectReadsClear.bind(this);
  }

  static propTypes = {
    models: InternalPropTypes.permissionEntities,
    permissionTypes: PropTypes.array, //InternalPropTypes.permissionTypeEntities,
    user: InternalPropTypes.userEntity,
    users: InternalPropTypes.userEntities,
    onSave: PropTypes.func,
    onDismiss: PropTypes.func,
    allAccounts: InternalPropTypes.accountEntities,
    allClients: InternalPropTypes.clientEntities,
    allProjects: InternalPropTypes.projectEntities,
  };

  _removeSelection(itemId, list) {
    return list.filter((i) => i != itemId);
  }

  _toggleSelection(itemId, list) {
    return list.includes(itemId)
      ? this._removeSelection(itemId, list)
      : list.concat([itemId]);
  }

  _onAccountWritesChange(e, item) {
    let accountId = item.key;
    let fieldSpec = {
      accountWrites: {
        value: this._toggleSelection(
          accountId,
          this.state.fields.accountWrites.value
        ),
        validationMessage: undefined,
      },
      accountReads: {
        value: this._removeSelection(
          accountId,
          this.state.fields.accountReads.value
        ),
        validationMessage: undefined,
      },
    };
    this._updateFieldState(fieldSpec);
  }

  _onAccountReadsChange(e, item) {
    let accountId = item.key;
    let fieldSpec = {
      accountReads: {
        value: this._toggleSelection(
          accountId,
          this.state.fields.accountReads.value
        ),
        validationMessage: undefined,
      },
      accountWrites: {
        value: this._removeSelection(
          accountId,
          this.state.fields.accountWrites.value
        ),
        validationMessage: undefined,
      },
    };
    this._updateFieldState(fieldSpec);
  }

  _onClientWritesChange(e, item) {
    let clientId = item.key;
    let fieldSpec = {
      clientWrites: {
        value: this._toggleSelection(
          clientId,
          this.state.fields.clientWrites.value
        ),
        validationMessage: undefined,
      },
      clientReads: {
        value: this._removeSelection(
          clientId,
          this.state.fields.clientReads.value
        ),
        validationMessage: undefined,
      },
    };
    this._updateFieldState(fieldSpec);
  }

  _onClientReadsChange(e, item) {
    let clientId = item.key;
    let fieldSpec = {
      clientReads: {
        value: this._toggleSelection(
          clientId,
          this.state.fields.clientReads.value
        ),
        validationMessage: undefined,
      },
      clientWrites: {
        value: this._removeSelection(
          clientId,
          this.state.fields.clientWrites.value
        ),
        validationMessage: undefined,
      },
    };
    this._updateFieldState(fieldSpec);
  }

  _onProjectWritesChange(item) {
    let projectId = item.keyId;
    let fieldSpec = {
      projectWrites: {
        value: this._toggleSelection(
          projectId,
          this.state.fields.projectWrites.value
        ),
        validationMessage: undefined,
      },
      projectReads: {
        value: this._removeSelection(
          projectId,
          this.state.fields.projectReads.value
        ),
        validationMessage: undefined,
      },
    };
    this._updateFieldState(fieldSpec);
  }

  _onProjectReadsChange(item) {
    let projectId = item.keyId;
    let fieldSpec = {
      projectReads: {
        value: this._toggleSelection(
          projectId,
          this.state.fields.projectReads.value
        ),
        validationMessage: undefined,
      },
      projectWrites: {
        value: this._removeSelection(
          projectId,
          this.state.fields.projectWrites.value
        ),
        validationMessage: undefined,
      },
    };
    this._updateFieldState(fieldSpec);
  }

  _onProjectWritesClear() {
    let fieldSpec = {
      projectWrites: {
        value: [],
        validationMessage: undefined,
      },
    };
    this._updateFieldState(fieldSpec);
  }

  _onProjectReadsClear() {
    let fieldSpec = {
      projectReads: {
        value: [],
        validationMessage: undefined,
      },
    };
    this._updateFieldState(fieldSpec);
  }

  _onUserIdChange(value) {
    this._updateFieldState(
      "userId",
      value[0] ? value[0].id : null,
      !value[0] ? "Must select a user" : undefined
    );
  }

  _onSave() {
    let { models, permissionTypeNameToIds } = this.props;
    let userId = this.state.fields.userId.value;

    let fieldToPermissionTypeMap = {
      accountWrites: PermissionTypes.ACCOUNT_WRITE,
      accountReads: PermissionTypes.ACCOUNT_READ,
      clientWrites: PermissionTypes.CLIENT_WRITE,
      clientReads: PermissionTypes.CLIENT_READ,
      projectWrites: PermissionTypes.PROJECT_WRITE,
      projectReads: PermissionTypes.PROJECT_READ,
    };

    let permissionTypeToRelationField = {
      [PermissionTypes.ACCOUNT_WRITE]: "account",
      [PermissionTypes.ACCOUNT_READ]: "account",
      [PermissionTypes.CLIENT_WRITE]: "client",
      [PermissionTypes.CLIENT_READ]: "client",
      [PermissionTypes.PROJECT_WRITE]: "project",
      [PermissionTypes.PROJECT_READ]: "project",
    };

    let compareFieldToEntityType = {
      account: EntityTypes.ACCOUNT,
      client: EntityTypes.CLIENT,
      project: EntityTypes.PROJECT,
    };

    let newPermissions = [];
    let permanentPermissions = [];

    Object.keys(fieldToPermissionTypeMap).forEach((fieldName) => {
      let entityIds = this.state.fields[fieldName].value;
      let permissionType = fieldToPermissionTypeMap[fieldName];
      let permissionsTypeId = permissionTypeNameToIds[permissionType];
      let compareFieldName = permissionTypeToRelationField[permissionType];

      // walk through all entities in the field and figure out if it is new or already existing
      entityIds.forEach((entityId) => {
        let existingModel = models.find(
          (model) =>
            apihelper.relHasId(model, "permissionType", permissionsTypeId) &&
            apihelper.relHasId(model, "user", userId) &&
            apihelper.relHasId(model, compareFieldName, entityId)
        );

        if (existingModel) {
          // no change, this already exists
          permanentPermissions.push(existingModel);
        } else {
          // new permission
          let changes = {
            user: { id: userId, type: EntityTypes.USER },
            permissionType: {
              id: permissionsTypeId,
              type: EntityTypes.PERMISSIONTYPE,
            },
          };
          changes[compareFieldName] = {
            id: entityId,
            type: compareFieldToEntityType[compareFieldName],
          };
          newPermissions.push(
            createEmptyModel(EntityTypes.PERMISSION, {}, changes)
          );
        }
      });
    });

    // find all the existing entities that are not present in fields
    let removePermissions = this.props.models.difference(permanentPermissions);

    let formCommands =
      // add these
      newPermissions
        .map((permissionEntity) => new FormCommand(permissionEntity, false, {}))
        // remove these
        .concat(
          removePermissions.map(
            (permissionEntity) => new FormCommand(permissionEntity, true)
          )
        );

    this.props.onSave(formCommands);
  }

  _isFieldChanged(name, value) {
    let { models } = this.props;
    let oldVal = this.state.fields[name].getVal.call(this, models);
    if (Array.isArray(oldVal)) {
      return !oldVal.equals(value);
    } else {
      return super._isFieldChanged(name, value);
    }
  }

  render() {
    let {
      models,
      users,
      user,
      allAccounts,
      allClients,
      allProjects,
      clientByProjectIdMap,
    } = this.props;
    let showChangeIcons = isEntity(user);
    if (user && !users.find((u) => entityHasId(u, getEntityId(user)))) {
      users = users.concat([user]);
    }

    let selectedUser = users.find(
      (u) => entityHasId(u, this.state.fields.userId.value),
      this
    );
    let sortedAccounts = allAccounts
      .slice()
      .sort(lexicalInsensitiveNameSortFunc)
      .map((a) => ({ key: getEntityId(a), text: getAttr(a, "name") }));
    let sortedClients = allClients
      .slice()
      .sort(lexicalInsensitiveNameSortFunc)
      .map((a) => ({ key: getEntityId(a), text: getAttr(a, "name") }));

    let sortedProjectsWithClientName = allProjects
      .map((proj) => {
        const projId = getEntityId(proj);
        const projClientName = getAttr(
          clientByProjectIdMap.find((o) => o.projectId == projId).client,
          "name"
        );
        return {
          key: projId,
          text: projClientName + " - " + getAttr(proj, "name"),
        };
      })
      .sort((a, b) => a.text.toLowerCase().localeCompare(b.text.toLowerCase()));

    return (
      <div>
        <h2>{models ? "Edit" : "Create"} Permission</h2>
        <br />
        <Label>User</Label>
        <UserPicker
          users={users}
          selectedUsers={[selectedUser].filter((u) => !!u)}
          onUserSelected={this._onUserIdChange}
          disabled={isEntity(user)}
        />
        <br />
        <Dropdown
          placeholder="Select accounts"
          label="Account Write"
          id="select-account-write-dropdown"
          onChange={this._onAccountWritesChange}
          onRenderCaretDown={
            showChangeIcons
              ? () => (
                  <Icon
                    iconName={
                      this.state.fields.accountWrites.isChanged
                        ? "FieldChanged"
                        : "FieldNotChanged"
                    }
                  />
                )
              : undefined
          }
          errorMessage={this.state.fields.accountWrites.validationMessage}
          selectedKeys={this.state.fields.accountWrites.value}
          multiSelect
          options={sortedAccounts}
        />
        <br />
        <Dropdown
          placeholder="Select accounts"
          label="Account Read"
          id="select-account-read-dropdown"
          onChange={this._onAccountReadsChange}
          onRenderCaretDown={
            showChangeIcons
              ? () => (
                  <Icon
                    iconName={
                      this.state.fields.accountReads.isChanged
                        ? "FieldChanged"
                        : "FieldNotChanged"
                    }
                  />
                )
              : undefined
          }
          errorMessage={this.state.fields.accountReads.validationMessage}
          selectedKeys={this.state.fields.accountReads.value}
          multiSelect
          options={sortedAccounts}
        />
        <br />
        <Dropdown
          placeholder="Select clients"
          label="Client Write"
          id="select-client-write-dropdown"
          onChange={this._onClientWritesChange}
          onRenderCaretDown={
            showChangeIcons
              ? () => (
                  <Icon
                    iconName={
                      this.state.fields.clientWrites.isChanged
                        ? "FieldChanged"
                        : "FieldNotChanged"
                    }
                  />
                )
              : undefined
          }
          errorMessage={this.state.fields.clientWrites.validationMessage}
          selectedKeys={this.state.fields.clientWrites.value}
          multiSelect
          options={sortedClients}
        />
        <br />
        <Dropdown
          placeholder="Select clients"
          label="Client Read"
          id="select-client-read-dropdown"
          onChange={this._onClientReadsChange}
          onRenderCaretDown={
            showChangeIcons
              ? () => (
                  <Icon
                    iconName={
                      this.state.fields.clientReads.isChanged
                        ? "FieldChanged"
                        : "FieldNotChanged"
                    }
                  />
                )
              : undefined
          }
          errorMessage={this.state.fields.clientReads.validationMessage}
          selectedKeys={this.state.fields.clientReads.value}
          multiSelect
          options={sortedClients}
        />
        <br />
        <CustomPicker
          placeholder="Select project"
          label="Project Write"
          id="select-client-write-custompicker"
          allOptions={sortedProjectsWithClientName}
          selected={this.state.fields.projectWrites.value}
          onSelected={this._onProjectWritesChange}
          onClear={this._onProjectWritesClear}
        />
        <br />
        <CustomPicker
          placeholder="Select project"
          label="Project Read"
          id="select-client-read-custompicker"
          allOptions={sortedProjectsWithClientName}
          selected={this.state.fields.projectReads.value}
          onSelected={this._onProjectReadsChange}
          onClear={this._onProjectReadsClear}
        />
        <br />
        {this._renderButtons()}
      </div>
    );
  }
}

import {put, select} from 'redux-saga/effects';
import * as types from '../../../constants/ActionTypes';
import {isLoggedIn} from '../../../selectors/reducers/auth';
import {takeLatestAfterBootstrap} from '../../helpers';

function* doClientsAndContractsOpened() {
    const loggedIn = yield select(isLoggedIn);
    
    if (loggedIn) {
        yield put({ type: types.APP_PAGE_CLIENTS_AND_CONTRACTS_LOAD_CLIENTS} );
    }
}

export default function* clientsAndContractsOpened() {
    yield takeLatestAfterBootstrap(types.APP_PAGE_CLIENTS_AND_CONTRACTS_OPENED, doClientsAndContractsOpened);
}

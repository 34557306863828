import * as types from '../../constants/ActionTypes';

export function createContract(contract) {
    return {
        type: types.API_CONTRACT_CREATE_INIT,
        payload: {model: contract}
    };
}

export function deleteContract(contract) {
    return {
        type: types.API_CONTRACT_DELETE_INIT,
        payload: {model: contract}
    };
}

export function getAllContracts(client, included) {
    return {
        type: types.API_CONTRACT_GET_ALL_INIT,
        payload: {
            client: client,
            included: included
        }
    };
}

export function updateContract(contract, modelChanges) {
    return {
        type: types.API_CONTRACT_UPDATE_INIT,
        payload: {
            model: contract,
            changes: modelChanges
        }
    };
}

import {put, select} from 'redux-saga/effects';
import * as types from '../../../constants/ActionTypes';
import {isLoggedIn} from '../../../selectors/reducers/auth';
import {takeLatestAfterBootstrap} from '../../helpers';

function* doUsersAndResourcesOpened() {
    const loggedIn = yield select(isLoggedIn);

    if (loggedIn) {
        yield put({ type: types.APP_PAGE_USERSANDRESOURCES_LOAD_USERS_RESOURCES_PERMISSIONS} );
    }
}

export default function* usersAndResourcesOpened() {
    yield takeLatestAfterBootstrap(types.APP_PAGE_USERSANDRESOURCES_OPENED, doUsersAndResourcesOpened);
}
    
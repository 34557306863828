import { all, put, take, takeLatest, select } from "redux-saga/effects";
import * as types from "../../constants/ActionTypes";
import * as apihelper from "../../selectors/apihelper";
import * as absencePeriodStatusActions from "../../actions/api/absencePeriodStatus";
import * as absencePeriodTypeActions from "../../actions/api/absencePeriodType";
import * as timeRegistrationStatusActions from "../../actions/api/timeRegistrationStatus";
import * as currencyActions from "../../actions/api/currency";
import * as billabilityTypeActions from "../../actions/api/billabilityTypes";
import * as projectStatusActions from "../../actions/api/projectStatus";
import * as languageActions from "../../actions/api/language";
import * as localeActions from "../../actions/api/locale";
import * as resourceTypeActions from "../../actions/api/resourceType";
import * as permissionTypeActions from "../../actions/api/permissionTypes";
import * as accountActions from "../../actions/api/account";
import * as absenceRegistrationProjectActions from "../../actions/api/absenceRegistrationProject";
import * as absencePeriodActions from "../../actions/api/absencePeriod";
import * as absencePeriodStatusSelectors from "../../selectors/reducers/absencePeriodStatus";
import * as absenceRegistrationProjectSelectors from "../../selectors/reducers/absenceRegistrationProject";
import * as projectActions from "../../actions/api/project";
import * as sagahelpers from "../../sagas/helpers";
import * as workLocationActions from "../../actions/api/workLocation";

function* doFetchInitialData() {
  yield put({ type: types.APP_FETCHINITIALDATA_STARTED });

  yield all([
    put(billabilityTypeActions.getAllBillabilityTypes()),
    put(timeRegistrationStatusActions.getAllTimeRegistrationStatus()),
    put(currencyActions.getAllCurrency()),
    put(absencePeriodStatusActions.getAllAbsencePeriodStatus()),
    put(absencePeriodTypeActions.getAllAbsencePeriodType()),
    put(languageActions.getAllLanguage()),
    put(projectStatusActions.getAllProjectStatus()),
    put(localeActions.getAllLocale()),
    put(resourceTypeActions.getAllResourceType()),
    put(permissionTypeActions.getAllPermissionTypes()),
    put(accountActions.getAllAccounts()),
    put(absenceRegistrationProjectActions.getAllAbsenceRegistrationProjects()),
    put(workLocationActions.getAllWorkLocations()),
  ]);

  yield all([
    take(types.API_BILLABILITYTYPE_GET_ALL_SUCCESS),
    take(types.API_TIMEREGISTRATIONSTATUS_GET_ALL_SUCCESS),
    take(types.API_CURRENCY_GET_ALL_SUCCESS),
    take(types.API_ABSENCEPERIODSTATUS_GET_ALL_SUCCESS),
    take(types.API_PROJECTSTATUS_GET_ALL_SUCCESS),
    take(types.API_LANGUAGE_GET_ALL_SUCCESS),
    take(types.API_LOCALE_GET_ALL_SUCCESS),
    take(types.API_RESOURCETYPE_GET_ALL_SUCCESS),
    take(types.API_PERMISSIONTYPE_GET_ALL_SUCCESS),
    take(types.API_ACCOUNT_GET_ALL_SUCCESS),
    take(types.API_ABSENCEREGISTRATIONPROJECT_GET_ALL_SUCCESS),
    take(types.API_WORKLOCATON_GET_ALL_SUCCESS),
  ]);

  // get all pending absence periods (for approval)
  const pendingAbsenceStatus = yield select(
    absencePeriodStatusSelectors.absencePeriodStatusPending
  );
  yield sagahelpers.callAndAwait(
    absencePeriodActions.getAllAbsencePeriods(
      null,
      null,
      null,
      apihelper.getEntityId(pendingAbsenceStatus)
    )
  );

  // make sure the projects for absence registration are loaded
  const absenceRegistrationProjects = yield select(
    absenceRegistrationProjectSelectors.allAbsenceRegistrationProjects
  );
  for (const projectId of absenceRegistrationProjects.map((arp) =>
    apihelper.getRelId(arp, "project")
  )) {
    yield sagahelpers.callAndAwait(projectActions.getProject(projectId));
  }

  yield put({ type: types.APP_FETCHINITIALDATA_COMPLETED });
}

export default function* fetchInitialData() {
  yield takeLatest(types.APP_FETCHINITIALDATA_INIT, doFetchInitialData);
}

import React, { Component } from "react";
import PropTypes from "prop-types";
import { IconButton } from "@fluentui/react/lib/Button";
import * as Icons from "../../constants/Icons";
import HourField from "../HourField";
import InvalidRegistrationCallout from "./InvalidRegistrationCallout";

export default class WeekCellField extends Component {
  static propTypes = {
    showAddNewButton: PropTypes.bool,
    editable: PropTypes.bool,
    onAddNewClick: PropTypes.func,
    isHighlighted: PropTypes.bool,
    disabled: PropTypes.bool,
    additionalData: PropTypes.object,
    onFocus: PropTypes.func.isRequired,
    onFocusReject: PropTypes.func,
    value: PropTypes.number.isRequired,
    failedValidations: PropTypes.array,
    isFocusable: PropTypes.bool,
  };

  static defaultProps = {
    showAddNewButton: false,
    editable: false,
    isFocusable: false,
    disabled: false,
    value: 0,
    isHighlighted: false,
    failedValidations: [],
  };

  shouldComponentUpdate(nextProps, nextState) {
    return (
      nextProps.showAddNewButton != this.props.showAddNewButton ||
      nextProps.editable != this.props.editable ||
      nextProps.isFocusable != this.props.isFocusable ||
      nextProps.disabled != this.props.disabled ||
      nextProps.isHighlighted != this.props.isHighlighted ||
      nextProps.value != this.props.value ||
      nextProps.failedValidations.length !=
        this.props.failedValidations.length ||
      nextState.isCalloutVisible != this.state.isCalloutVisible
    );
  }

  constructor(props) {
    super(props);

    this._invalidIconButtonElement = React.createRef();
    this.state = { isCalloutVisible: false };
    this._iconPropsQueued = { iconName: Icons.ICON_NAME_CHANGE_QUEUED };
    this._iconPropsInvalid = {
      iconName: Icons.ICON_NAME_INVALID_QUEUED,
      className: "icon-color-red fixposition",
    };
    this._iconPropsNew = { iconName: Icons.ICON_NAME_NEW };

    this._onNewClick = this._onNewClick.bind(this);
    this._onFocus = this._onFocus.bind(this);
    this._setCalloutVisibility = this._setCalloutVisibility.bind(this);
  }

  _onFocus(event, additionalData) {
    if (this.props.isFocusable) {
      this.props.onFocus(additionalData);
    } else {
      event.target.blur();
      if (this.props.onFocusReject) {
        this.props.onFocusReject(this.props.additionalData);
      }
    }
  }

  _onNewClick() {
    this.props.onAddNewClick(this.props.additionalData);
  }

  _setCalloutVisibility(isVisible = true) {
    this.setState({ isCalloutVisible: !!isVisible });
  }

  render() {
    let {
      isHighlighted,
      showAddNewButton,
      editable,
      failedValidations,
      ...others
    } = this.props;
    let validations = failedValidations
      .map((tregValidations) => Object.values(tregValidations))
      .flatten()
      .filter((s) => !!s);

    return (
      <div
        className={
          "novatime-weekcellfield" +
          (isHighlighted ? " underEdit" : "") +
          (this.props.disabled ? " disabled" : "")
        }
        ref={this._invalidIconButtonElement}
      >
        {showAddNewButton && editable ? (
          <IconButton
            className="new-time-registration-icon"
            iconProps={this._iconPropsNew}
            onClick={this._onNewClick}
          />
        ) : null}
        {validations.length > 0 ? (
          <React.Fragment>
            <IconButton
              className="invalid-icon"
              iconProps={this._iconPropsInvalid}
              onClick={this._setCalloutVisibility}
            />
            <InvalidRegistrationCallout
              isCalloutVisible={this.state.isCalloutVisible}
              targetElement={this._invalidIconButtonElement}
              onDismiss={() => this._setCalloutVisibility(false)}
              validations={validations}
            />
          </React.Fragment>
        ) : null}
        <HourField
          {...others}
          disabled={false}
          onFocus={this._onFocus}
          editable={true}
        />
      </div>
    );
  }
}

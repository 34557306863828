import * as SagaHelpers from '../helpers';
import * as SagaEffects from 'redux-saga/effects';
import * as types from '../../constants/ActionTypes';
import AbsenceRegistrationProjectsConnector from '../../connectors/AbsenceRegistrationProject';

const createAbsenceRegistrationProject = SagaHelpers.createApiHandler(
    types.API_ABSENCEREGISTRATIONPROJECT_CREATE_INIT, 
    SagaHelpers.generateCreateApiCall(
        AbsenceRegistrationProjectsConnector, 
        function*(connector: AbsenceRegistrationProjectsConnector, action: any): any {
            return yield SagaEffects.call([connector, connector.createAbsenceRegistrationProject], {
                data: action.payload.model
            });
        }
    )
)

const deleteAbsenceRegistrationProject = SagaHelpers.createApiHandler(
    types.API_ABSENCEREGISTRATIONPROJECT_DELETE_INIT, 
    SagaHelpers.generateDeleteApiCall(
        AbsenceRegistrationProjectsConnector, 
        function*(connector: AbsenceRegistrationProjectsConnector, action: any): any {
            return yield SagaEffects.call([connector, connector.deleteAbsenceRegistrationProject], action.payload.model.id);
        }
    )
)

const getAllAbsenceRegistrationProject = SagaHelpers.createApiHandler(
    types.API_ABSENCEREGISTRATIONPROJECT_GET_ALL_INIT, 
    SagaHelpers.generateGetAllApiCall(
        AbsenceRegistrationProjectsConnector,
        function*(connector: AbsenceRegistrationProjectsConnector): any {
            return yield SagaEffects.call([connector, connector.getAllAbsenceRegistrationProjects]);
        }
    )
)

const updateAbsenceRegistrationProject = SagaHelpers.createApiHandler(
    types.API_ABSENCEREGISTRATIONPROJECT_UPDATE_INIT, 
    SagaHelpers.generateUpdateApiCall(
        AbsenceRegistrationProjectsConnector, 
        function*(connector: AbsenceRegistrationProjectsConnector, action: any, requestData: any): any {
            return yield SagaEffects.call([connector, connector.updateAbsenceRegistrationProject], action.payload.model.id, requestData);
        }
    )
)

export default function* absenceRegistrationProjectSaga() {
    yield SagaEffects.all([
        SagaEffects.fork(getAllAbsenceRegistrationProject),
        SagaEffects.fork(createAbsenceRegistrationProject),
        SagaEffects.fork(updateAbsenceRegistrationProject),
        SagaEffects.fork(deleteAbsenceRegistrationProject),
    ]);
}

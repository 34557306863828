import * as requesthelper from '../../selectors/app/request';

const initialState = {
    liveRequests: [],
    undismissedFailedRequests: []
};

const createRequest = (action: any) => ({
    initTime: (new Date()).toISOString(),
    initAction: action,
});

function addLiveRequest(state: any, action: any){
    return Object.assign({}, state, { 
        liveRequests: state.liveRequests.concat([createRequest(action)]) 
    })
}

function removeLiveRequest(state: any, action: any){
    return Object.assign({}, state, {
        liveRequests: state.liveRequests.filter((r: any) => r.initAction !== action.payload.initAction)
    });
}

export default function apiCallReducer(state = initialState, action: any) {
    if(requesthelper.isApiAction(action)){
        if(requesthelper.isInitApiAction(action)){
            return addLiveRequest(state, action)
        }
        else if(requesthelper.isSuccessfulApiAction(action)){
            return removeLiveRequest(state, action);
        }
        else if(requesthelper.isFailedApiAction(action)){
            return removeLiveRequest(state, action);
        }

        return state;
    }
    else if (requesthelper.isCancelledApiAction(action)){
        return removeLiveRequest(state, action);
    }
    else {
        return state;
    }
}

import 'regenerator-runtime/runtime'
import { all, fork } from 'redux-saga/effects';
import lockPeriods from './lockPeriods';
import openPeriods from './openPeriods';

export default function* appRootSaga() {
    yield all([
        fork(lockPeriods),
        fork(openPeriods)
    ]);
}
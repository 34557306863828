import { getEntityId, getRelId, isEntity, getAttr } from '../apihelper';
import { isBeforeOrSameDay, isSameDayOrAfter, isWithinPeriod } from '../../lib/date';

export const allLockedTimePeriods = state => state.api.lockedTimePeriod.allLockedTimePeriods;

// returns true of query period is contained within locked period
const confiningDateRangeFilter = (queryStartDate, queryEndDate, lockedPeriodStartDate, lockedPeriodEndDate) => 
    (!queryStartDate || isBeforeOrSameDay(lockedPeriodStartDate, queryStartDate)) &&
    (!queryEndDate || isSameDayOrAfter(lockedPeriodEndDate, queryEndDate))

// returns true if two periods overlap at all
const intersectingDateRangeFilter = (queryStartDate, queryEndDate, lockedPeriodStartDate, lockedPeriodEndDate) => 
    // the locked time period is contained within the query period
    (queryStartDate && queryEndDate && isWithinPeriod(queryStartDate, queryEndDate, lockedPeriodStartDate, lockedPeriodEndDate)) ||
    // we have a start date and it is somewhere between locked start and end date
    (queryStartDate && isWithinPeriod(lockedPeriodStartDate, lockedPeriodEndDate, queryStartDate, queryStartDate)) ||
    // we have an end date and it is somewhere between locked start and end date
    (queryEndDate && isWithinPeriod(lockedPeriodStartDate, lockedPeriodEndDate, queryEndDate, queryEndDate)) ||
    // we don't have an end date (so it's infinite +) and the start date is before the locked period ends
    (!queryEndDate && queryStartDate && isBeforeOrSameDay(queryStartDate, lockedPeriodEndDate)) ||
    // we don't have a start date (so it's infinite -) and the end date is after the locked period starts
    (!queryStartDate && queryEndDate && isSameDayOrAfter(queryEndDate, lockedPeriodStartDate));

export const filterByResources = (lockedTimePeriods, resources) => {
    resources = Array.isArray(resources) ? resources : [resources];
    let resourceIds = resources.filter(isEntity).map(getEntityId);
    return lockedTimePeriods.filter(t => resourceIds.includes(getRelId(t, 'resource')))
}

export const filterByPeriod = (lockedTimePeriods, startDate, endDate) =>
{
return lockedTimePeriods.filter(
        ltp => intersectingDateRangeFilter(startDate, endDate, new Date(getAttr(ltp, 'startDate')), new Date(getAttr(ltp, 'endDate')))
    );
}

export const filterByDay = (lockedTimePeriods, date) => filterByPeriod(lockedTimePeriods, date, date);

export const lockedTimePeriodsByResources = (state, resources) => 
    filterByResources(allLockedTimePeriods(state), resources);

export const lockedTimePeriodsByResourcesAndPeriod = (state, resources, startDate, endDate) => {
    resources = Array.isArray(resources) ? resources : [resources];
    return filterByPeriod(lockedTimePeriodsByResources(state, resources), startDate, endDate);
}

export const dayInsideLockedTimePeriodeAndByResources = (state, resources, dateObj) =>
    filterByDay(lockedTimePeriodsByResources(state, resources), dateObj)

export const lockedTimePeriodsIntersectingInterval = (lockedTimePeriods, startDate, endDate) =>
    lockedTimePeriods.filter(ltp => intersectingDateRangeFilter(startDate, endDate, new Date(getAttr(ltp, 'startDate')), new Date(getAttr(ltp, 'endDate'))));

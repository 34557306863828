import React, { Component } from "react";
import PropTypes from "prop-types";
import { Callout, DirectionalHint } from "@fluentui/react/lib/Callout";
import { DefaultButton } from "@fluentui/react/lib/Button";
import ProjectBrowser from "../projectbrowser/ProjectBrowser";

export default class ProjectCallout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isCalloutVisible: false,
    };
    this._onDismiss = this._onDismiss.bind(this);
    this._onSelectProjects = this._onSelectProjects.bind(this);
  }

  _onDismiss() {
    this.setState({
      isCalloutVisible: !this.state.isCalloutVisible,
    });
  }

  _onSelectProjects(projects) {
    this.props.onItemSelected(projects);
    this._onDismiss();
  }

  render() {
    let { projects, clients, hideButtonDisabled, contracts } = this.props;
    let { isCalloutVisible } = this.state;
    return (
      <div>
        <div ref={(menuButton) => (this._menuButtonElement = menuButton)}>
          <DefaultButton onClick={this._onDismiss} text="Add more" />
        </div>
        {isCalloutVisible ? (
          <Callout
            gapSpace={6}
            target={this._menuButtonElement}
            onDismiss={this._onDismiss}
            directionalHint={DirectionalHint.bottomLeftEdge}
            preventDismissOnLostFocus={false}
            className="novatime-project-callout"
          >
            <div className="novatime-project-callout-body">
              <ProjectBrowser
                contracts={contracts}
                projects={projects}
                clients={clients}
                onSelectProjects={this._onSelectProjects}
              />
            </div>
          </Callout>
        ) : null}
      </div>
    );
  }
}

ProjectCallout.propTypes = {
  projects: PropTypes.array,
  clients: PropTypes.array,
  onItemSelected: PropTypes.func,
};

import * as types from '../../constants/ActionTypes';

export function createProjectResource(projectResource) {
    return {
        type: types.API_PROJECTRESOURCE_CREATE_INIT,
        payload: {model: projectResource}
    };
}

export function deleteProjectResource(projectResource) {
    return {
        type: types.API_PROJECTRESOURCE_DELETE_INIT,
        payload: {model: projectResource}
    };
}

export function getAllProjectResources({ project, resource, client, includeDefault, includes } = {}, ) {
    return {
        type: types.API_PROJECTRESOURCE_GET_ALL_INIT,
        payload: {
            project,
            resource,
            client,
            includeDefault,
            includes
        }
    };
}

export function updateProjectResource(projectResource, modelChanges) {
    return {
        type: types.API_PROJECTRESOURCE_UPDATE_INIT,
        payload: {
            model: projectResource,
            changes: modelChanges
        }
    };
}

import * as types from "../../constants/ActionTypes";
import {updateDtoList} from "../helpers";
import {getAllAbsencePeriodType} from "../../selectors/actions/absencePeriodType";

const initialState = {
    storeRevision: 0,
    allAbsencePeriodType: []
};

export default function absencePeriodTypeReducer(
    state = initialState,
    action
) {
    switch (action.type) {
        case types.API_ABSENCEPERIODTYPE_GET_ALL_SUCCESS:
            return updateDtoList(state, "allAbsencePeriodType", getAllAbsencePeriodType(action));

        default:
            return state;
    }
}

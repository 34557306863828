import * as SagaHelpers from '../helpers';
import * as SagaEffects from 'redux-saga/effects';
import * as types from '../../constants/ActionTypes';
import localeConnector from "../../connectors/Locale";

const getAllLocales = SagaHelpers.createApiHandler(
    types.API_LOCALE_GET_ALL_INIT, 
    SagaHelpers.generateGetAllApiCall(
        localeConnector,
        function* (connector: localeConnector): any {
            return yield SagaEffects.call([connector, connector.getLocale]);
        }
    )
)

export default function* localeSaga() {
    yield SagaEffects.all([
        SagaEffects.fork(getAllLocales)
    ]);
}

import React, {Component} from 'react';
import PropTypes from 'prop-types';
import * as InternalPropTypes from '../../constants/PropTypes';
import SelectAllPicker from './SelectAllPicker';

export default class ClientPicker extends Component {
    render() {
        let { clientEntities,
              isSelectAllAnOption,
              isAllClientsSelected,
              selectedClient,
              onClientSelected,
              onAllClientsSelected } = this.props;

        return (
            <SelectAllPicker
                isSelectAllAnOption={isSelectAllAnOption}
                isAllEntitiesSelected={isAllClientsSelected}
                selectedEntity={selectedClient}
                onEntitySelected={onClientSelected}
                onAllEntitiesSelected={onAllClientsSelected}
                entities={clientEntities}
                label={'Client'}
                placeholder={'Select Client'}
                selectAllText={'All Clients'}
            />
        );
    }
}

ClientPicker.propTypes = {
    clientEntities:             InternalPropTypes.clientEntities,
    isSelectAllAnOption:        PropTypes.bool,
    isAllClientsSelected:       PropTypes.bool,
    selectedClient:             InternalPropTypes.clientEntity,
    onClientSelected:           PropTypes.func,
    onAllClientsSelected:       PropTypes.func,
};

ClientPicker.defaultProps = {
    isSelectAllAnOption: false
}

import { createSelector } from 'reselect';
import * as apihelper from '../apihelper';
import { sortByAttr } from '../storehelper';
import * as storehelper from '../storehelper';
import { ResourceObject, ResourceObjects } from '../../lib/models';

export const filterByClients = (accounts: ResourceObjects, clients: ResourceObjects) =>
    storehelper.filterByRelatedEntity(accounts, clients, 'account');

export const filterAccountByResource = (accounts: ResourceObjects, resource: ResourceObject) => 
    accounts.find(a => apihelper.relHasId(resource, 'account', a.id));

export const allAccounts = (state: any) => state.api.account.allAccounts as ResourceObjects;

export const currentUserAccount = (state: any) => state.api.account.currentUserAccount;
export const allAccountsSorted = createSelector(
    [allAccounts],
    (accounts) => accounts.sort(sortByAttr('name'))
);

export const accountById = createSelector(
    (state: any) => allAccounts(state),
    (_: any, accountId: string) => accountId,
    (allAccounts: ResourceObjects, accountId) => allAccounts.find(a => apihelper.entityHasId(a, accountId as string))
);

export const accountByResource = createSelector(
    (state: any) => allAccounts(state),
    (_: any, resource: ResourceObject) => resource,
    (allAccounts: ResourceObjects, resource) => filterAccountByResource(allAccounts, resource as ResourceObject)
);

export const accountByClient = createSelector(
    (state: any) => allAccounts(state),
    (_: any, client: ResourceObject) => client,
    (allAccounts: ResourceObjects, client) => allAccounts.find(a => apihelper.relHasId(client as ResourceObject, 'account', a.id))
);

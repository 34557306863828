import {currentUser} from './user';
import {
    allProjectResourcesByProject,
    allProjectResourcesByProjectAndTimestamp,
    allProjectResourcesByProjects
} from './projectResource';
import {allProjectsByClient} from "./project";
import {accountByClient, currentUserAccount} from './account';
import {contractRoleById} from './contractRole';
import {clientByContractRole} from './client';
import {entityHasId, getAttr, getEntityId, getRelId, relIsPresent, relRefersToEntity} from '../apihelper';
import {allResourceType} from './resourceType';
import '../../lib/groupBy';
import '../../lib/unique';
import * as contractRoleSelectors from '../reducers/contractRole';
import * as contractSelectors from '../reducers/contract';
import * as clientSelectors from '../reducers/client';
import * as accountSelectors from '../reducers/account';
import * as storehelper from '../storehelper';
import * as projectResourceSelectors from './projectResource';

const ResourceTypeValues = allResourceType;

export const allResources = state => state.api.resource.allResources;

export const filterResourcesByTypeIds = (resources, resourceTypeIds) => resources.filter(c => resourceTypeIds.includes(getRelId(c, 'resourceType')));

export const filterActiveResources = resources => resources.filter(resource => getAttr(resource, 'isActive'));

export const filterByAccounts = (resources, accounts) =>
    storehelper.filterByReferenceToRelatedEntities(resources, accounts, 'account');

export const filterByProjectResources = (resources, projectResources) =>
    storehelper.filterByRelatedEntity(resources, projectResources, 'resource');

// return all resources from the provided set that are assignable as either via default project resources or are directly assigned
export const filterAssignableResources = (resources, projectResources, contractRoles, contracts, clients, accounts) => {
    let defaultProjectResources = projectResources ? projectResources.filter(projectResourceSelectors.isDefaultProjectResource) : [];
    let defaultProjectResourceAccounts = accountSelectors.filterByClients(accounts,
        clientSelectors.filterByContracts(clients,
            contractSelectors.filterByContractRoles(contracts, 
                contractRoleSelectors.filterByProjectResources(contractRoles, defaultProjectResources)
            )
        )
    )

    // every resource at an account can be allocated to a default project resource
    let acctResources = filterByAccounts(resources, defaultProjectResourceAccounts);

    // now get the direct assignments
    let userSpecificProjectResources = projectResources ? projectResources.filter(pr => !projectResourceSelectors.isDefaultProjectResource(pr)) : [];
    let directlyAssignedResources = filterByProjectResources(resources, userSpecificProjectResources);

    return Array.from(new Set([...acctResources, ...directlyAssignedResources]));
}

export const filterByResourceId = (allResources, resourceId) => storehelper.findById(allResources, resourceId);

export const allResourcesByTypeIds = (state, resourceTypeIds) => filterResourcesByTypeIds(allResources(state), resourceTypeIds);

export const allResourcesByAccount = (state, account, resourceTypes = ResourceTypeValues(state)) => {
    let resources = allResources(state).filter(c => relRefersToEntity(c, 'account', account));
    return filterResourcesByTypeIds(resources, resourceTypes.map(rt => rt.id));
};

export const allResourcesForCurrentAccount = state => allResourcesByAccount(state, currentUserAccount(state));

export const resourceById = (state, resourceId) => filterByResourceId(allResources(state), resourceId);

export const resourcebyIds = (state, resourceIds) => allResources(state).filter(r => resourceIds.includes(getEntityId(r)));

export const resourceByUser = (state, user) => user != null && relIsPresent(user, 'resource') && resourceById(state, getRelId(user, 'resource'));

export const resourceForCurrentUser = state => resourceByUser(state, currentUser(state));

export const resourcesByProject = (state, project, resourceTypes = ResourceTypeValues(state)) => {
    let projectResources = allProjectResourcesByProject(state, project);
    let resources = resourcesFromProjectResources(state, projectResources);
    return filterResourcesByTypeIds(resources, resourceTypes.map(rt => rt.id));
};

export const allResourcesAllocatedToProject = (state, project, date) => 
    resourcesFromProjectResources(state, allProjectResourcesByProjectAndTimestamp(state, project, date));

const resourcesFromProjectResources = (state, projectResources) => {
    const { nonDefault: notDefault, default: isDefault } = projectResourceSelectors.splitByDefaultProjectResource(projectResources);
    
    // get resources from each account
    let accts = isDefault.map(pr => getRelId(pr, 'contractRole'))
                         .unique()
                         .map(crId => contractRoleById(state, crId))
                         .map(cr => clientByContractRole(state, cr))
                         .map(cl => accountByClient(state, cl))
                         .unique();
    let acctResources = accts.map(acct => allResourcesByAccount(state, acct)).flatten();

    // get resources for specific project resources
    let specificResources = notDefault.map(pr => getRelId(pr, 'resource')).map(rId => resourceById(state, rId));

    return Array.from(new Set([...acctResources, ...specificResources]));
};

export const resourcesByClient = (state, client, resourceTypes = ResourceTypeValues(state)) => {
    let projectResources = allProjectResourcesByProjects(state, allProjectsByClient(state, client));
    let resources = resourcesFromProjectResources(state, projectResources);
    return filterResourcesByTypeIds(resources, resourceTypes.map(getEntityId));
};

export const currentUserResource = state => state.api.resource.currentUserResource;

import * as SagaEffects from 'redux-saga/effects';
import * as types from '../../../constants/ActionTypes';
import * as clientAndContractsActions from "../../../actions/pages/clientsAndContracts"

function* doClientSelectedReload(){
    yield SagaEffects.put( clientAndContractsActions.selectContract(null) );
    yield SagaEffects.put( clientAndContractsActions.selectClient(null) );
    yield SagaEffects.put({ type: types.APP_PAGE_CLIENTS_AND_CONTRACTS_LOAD_CLIENTS} );
}

export default function* clientDeleted() {
    yield SagaEffects.takeLatest(types.API_CLIENT_DELETE_SUCCESS, doClientSelectedReload);
}

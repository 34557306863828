import * as apiHelper from "../selectors/apihelper";
import type { ResourceObject } from "../lib/models";

const FORM_COMMAND_TYPE_DELETE = "delete";
const FORM_COMMAND_TYPE_UPDATE = "update";
const FORM_COMMAND_TYPE_CREATE = "create";

export type FormCommandType = "create" | "update" | "delete";

type SerializedCommand = {
  model: ResourceObject;
  modelChanges?: object;
  type: FormCommandType;
};

export default class FormCommand {
  public type: FormCommandType;
  constructor(
    public model: ResourceObject,
    isDelete: boolean,
    public modelChanges: object | undefined
  ) {
    this.type = isDelete
      ? FORM_COMMAND_TYPE_DELETE
      : !!this.model && !apiHelper.isPersistedEntity(this.model)
      ? FORM_COMMAND_TYPE_CREATE
      : FORM_COMMAND_TYPE_UPDATE;
  }

  serialize(): SerializedCommand {
    return {
      model: this.model,
      type: this.type,
      modelChanges: this.modelChanges,
    };
  }

  static deserialize(serializedCommand: SerializedCommand) {
    return new FormCommand(
      serializedCommand.model,
      serializedCommand.type === FORM_COMMAND_TYPE_DELETE,
      serializedCommand.modelChanges
    );
  }

  isDelete() {
    return this.type === FORM_COMMAND_TYPE_DELETE;
  }

  isCreate() {
    return this.type === FORM_COMMAND_TYPE_CREATE;
  }

  getModelType() {
    return apiHelper.getEntityType(this.model);
  }
}

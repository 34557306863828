import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { DefaultButton } from "@fluentui/react/lib/Button";
import * as authSelectors from "../../selectors/reducers/auth";
import * as writeBackQueueSelectors from "../../selectors/reducers/writeBackQueue";
import * as userSelectors from "../../selectors/reducers/user";
import * as rootSelectors from "../../selectors/reducers/root";
import * as permissionSelectors from "../../selectors/reducers/permission";
import * as resourceSelectors from "../../selectors/reducers/resource";
import * as absencePeriodSelectors from "../../selectors/reducers/absencePeriod";
import * as notificationSelectors from "../../selectors/app/notification";
import { goToUrl } from "../../actions/nav";
import * as writeBackQueueActions from "../../actions/writeBackQueue";
import LoadingIndicator from "../../components/LoadingIndicator";
import Row from "../../components/grid/Row";
import Column from "../../components/grid/Column";
import Grid from "../../components/grid/Grid";
import { User } from "../../components/uifabricextensions/User";
import NavBar from "./Navbar";
import * as apihelper from "../../selectors/apihelper";
import * as Environment from "../../constants/Environment";
import * as icons from "../../constants/Icons";
import * as url_consts from "../../constants/Urls";
import * as absencePeriodStatusSelectors from "../../selectors/reducers/absencePeriodStatus";
import * as absencePeriodStatusName from "../../constants/AbsencePeriodStatusNames";
import * as accountReducerSelectors from "../../selectors/reducers/account";
import { selectAccount } from "../../actions/appAction.js";
import * as appDomainSelectors from "../../selectors/app/domain";
import * as editorActions from "../../actions/EntityEditor";
import { createEmptyModel } from "../../lib/emptyModelCreation";
import * as EntityTypes from "../../constants/EntityTypes";
import { ContextualMenuItemType } from "@fluentui/react/lib/ContextualMenu";
import AccountForm from "../../components/forms/AccountForm";
import DeleteForm from "../../components/forms/DeleteForm";
import * as notificationActions from "../../actions/app/notification";
import { NotificationLevel } from "../../reducers/app/notification";
import { withCurrentUserContext } from "../../contexts/CurrentUserContext";

class Header extends Component {
  constructor(props) {
    super(props);
    this._renderPersona = this._renderPersona.bind(this);
    this._dismissFailedRequests = this._dismissFailedRequests.bind(this);
    this._onAccountChange = this._onAccountChange.bind(this);
    this._rollbackQueuedChanges = this._rollbackQueuedChanges.bind(this);
    this._onCreateAccount = this._onCreateAccount.bind(this);
    this._rollbackInvalidQueuedChanges =
      this._rollbackInvalidQueuedChanges.bind(this);
    this._onEditAccount = this._onEditAccount.bind(this);
    this._onRemoveAccount = this._onRemoveAccount.bind(this);
  }

  _onCreateAccount() {
    this.props.actions.startEdit(
      createEmptyModel(EntityTypes.ACCOUNT),
      AccountForm
    );
  }

  _onEditAccount() {
    this.props.actions.startEdit(this.props.selectedAccount, AccountForm);
  }

  _onRemoveAccount() {
    this.props.actions.startEdit(this.props.selectedAccount, DeleteForm);
  }

  _onAccountChange(element) {
    this.props.actions.selectAccount(apihelper.getEntityId(element));
  }

  _dismissFailedRequests() {
    this.props.actions.dismissAll({ level: NotificationLevel.Error });
  }

  _rollbackQueuedChanges() {
    this.props.actions.rollbackAllChanges();
  }

  _rollbackInvalidQueuedChanges() {
    this.props.actions.rollbackAllInvalidChanges();
  }

  _onClickPersona(ev) {
    ev.stopPropagation();
  }

  _renderPersona(item) {
    const { currentUser } = this.props;

    return (
      <DefaultButton
        style={{ marginTop: "4px" }}
        onClick={this._onClickPersona}
        text=""
        menuProps={{
          className: "ms-CommandBar-menuHost",
          items: item.subMenuProps.items,
        }}
      >
        <User model={currentUser} responsive={true} />
      </DefaultButton>
    );
  }

  _linkTo(url, newWindow) {
    if (newWindow) {
      this.props.actions.goToUrl(url, newWindow);
    } else {
      this.props.history.push(url);
    }
  }

  _getNavigationItems() {
    let {
      isLoggedIn,
      currentUserResource,
      currentUserPermissionSet,
      allAbsencePeriods,
      pendingAbsencePeriodStatus,
    } = this.props;
    const currentPath = window.location.pathname;

    const currentResourceIsEligibleForAbsenceRegistration =
      currentUserResource &&
      apihelper.getAttr(currentUserResource, "eligibleForAbsenceRegistration");

    let navbarItems = [];
    if (isLoggedIn) {
      navbarItems.push({
        key: "time",
        name: "Register",
        iconProps: {
          iconName: icons.ICON_NAME_TIMEREGISTRATION,
        },
        onClick: () => this._linkTo(url_consts.TIMEREGISTRATION_RELATIVE),
        className:
          currentPath === url_consts.TIMEREGISTRATION_RELATIVE
            ? "active"
            : undefined,
      });

      if (
        currentResourceIsEligibleForAbsenceRegistration ||
        currentUserPermissionSet.hasSelectedAccountRead()
      ) {
        const totalPendingAbsencePeriods = allAbsencePeriods.filter(
          (absencePeriod) =>
            apihelper.relHasId(
              absencePeriod,
              "absencePeriodStatus",
              apihelper.getEntityId(pendingAbsencePeriodStatus)
            )
        );
        const ownPendingAbsencePeriods = totalPendingAbsencePeriods.filter(
          (absencePeriod) =>
            apihelper.relRefersToEntity(
              absencePeriod,
              "resource",
              currentUserResource
            )
        );
        const addToName = currentUserPermissionSet.hasSelectedAccountWrite()
          ? totalPendingAbsencePeriods.length > 0
            ? ` (${totalPendingAbsencePeriods.length})`
            : ""
          : ownPendingAbsencePeriods.length > 0
          ? ` (${ownPendingAbsencePeriods.length})`
          : "";
        const name = "Absence" + addToName;

        navbarItems.push({
          key: "absencePeriod",
          name: name,
          iconProps: {
            iconName: icons.ICON_NAME_ABSENCEPERIOD,
          },
          onClick: () => this._linkTo(url_consts.ABSENCEPERIODS_RELATIVE),
          className:
            currentPath === url_consts.ABSENCEPERIODS_RELATIVE
              ? "active"
              : undefined,
        });
      }

      if (currentUserPermissionSet.hasAnyPermission()) {
        navbarItems.push({
          key: "resources",
          name: "Overview",
          iconProps: {
            iconName: icons.ICON_NAME_RESOURCE_MANAGEMENT,
          },
          onClick: () => this._linkTo(url_consts.RESOURCEMANAGEMENT_RELATIVE),
          className:
            currentPath === url_consts.RESOURCEMANAGEMENT_RELATIVE
              ? "active"
              : undefined,
        });
      }

      navbarItems.push({
        key: "timeregexport",
        name: "Search",
        iconProps: {
          iconName: icons.ICON_NAME_TIMEREGEXPORT,
        },
        onClick: () => this._linkTo(url_consts.TIMEREGEXPORT_RELATIVE),
        className:
          currentPath === url_consts.TIMEREGEXPORT_RELATIVE
            ? "active"
            : undefined,
      });

      if (currentUserPermissionSet.hasSelectedAccountRead()) {
        navbarItems.push({
          key: "configuration",
          name: "Configuration",
          iconProps: {
            iconName: icons.ICON_NAME_CONFIGURATION,
          },
          className: "ms-CommandBarItem",
          subMenuProps: {
            items: [
              {
                key: "contracts",
                name: "Clients and contracts",
                iconProps: {
                  iconName: icons.ICON_NAME_CLIENT,
                },
                onClick: () =>
                  this._linkTo(url_consts.CONTRACTSANDCLIENTS_RELATIVE),
                className:
                  currentPath === url_consts.CONTRACTSANDCLIENTS_RELATIVE
                    ? "active"
                    : undefined,
              },
              {
                key: "projects",
                name: "Projects and roles",
                iconProps: {
                  iconName: icons.ICON_NAME_PROJECT,
                },
                onClick: () =>
                  this._linkTo(url_consts.PROJECTSANDROLES_RELATIVE),
                className:
                  currentPath === url_consts.PROJECTSANDROLES_RELATIVE
                    ? "active"
                    : undefined,
              },
              {
                key: "users",
                name: "Users and resources",
                iconProps: {
                  iconName: icons.ICON_NAME_RESOURCE,
                },
                onClick: () =>
                  this._linkTo(url_consts.USERSANDRESOURCES_RELATIVE),
                className:
                  currentPath === url_consts.USERSANDRESOURCES_RELATIVE
                    ? "active"
                    : undefined,
              },
            ],
          },
        });
      }
    }

    return navbarItems;
  }

  _getNavigationFarItems() {
    let {
      isLoggedIn,
      isCheckingAuth,
      urls,
      environment,
      selectedAccount,
      currentUserPermissionSet,
      allAccountsSorted,
    } = this.props;
    const selectedAccountName =
      selectedAccount && apihelper.getAttr(selectedAccount, "name");

    const farItems = [];
    if (
      (currentUserPermissionSet.hasGlobalWrite() &&
        environment !== Environment.PRODUCTION) ||
      environment === Environment.DEVELOPMENT
    ) {
      var items = [
        {
          key: "swagger",
          name: "Swagger",
          iconProps: {
            iconName: icons.ICON_NAME_SWAGGER,
          },
          onClick: () => this._linkTo(urls.swagger, true),
        },
        {
          key: "elmah",
          name: "Elmah",
          iconProps: {
            iconName: icons.ICON_NAME_ELMAH,
          },
          onClick: () => this._linkTo(urls.elmah, true),
        },
      ];

      farItems.push({
        key: "debug",
        name: "",
        iconProps: {
          iconName: icons.ICON_NAME_BUG,
        },
        className: "ms-CommandBarItem",
        subMenuProps: {
          items: items,
        },
      });
    }

    if (isCheckingAuth) {
      farItems.push({
        key: "login",
        name: "Signing in...",
      });
    } else if (isLoggedIn) {
      let allAccountsSortedFiltered = allAccountsSorted.filter(
        (account) =>
          !apihelper.entityHasId(
            account,
            apihelper.getEntityId(selectedAccount)
          )
      );
      let displayUnselectedAccounts = {
        items: allAccountsSortedFiltered.map((account) => ({
          key: "account-selector-item-" + apihelper.getEntityId(account),
          iconProps: { iconName: icons.ICON_NAME_ACCOUNT },
          name: apihelper.getAttr(account, "name"),
          onClick: () => {
            this._onAccountChange(account);
          },
        })),
      };

      const newSubMenu =
        allAccountsSortedFiltered.length > 0
          ? displayUnselectedAccounts
          : { items: [] };
      const admSubMenuItems = [];

      if (currentUserPermissionSet.hasGlobalWrite()) {
        admSubMenuItems.push({
          key: "NewAccount",
          name: "Add Account",
          iconProps: { iconName: icons.ICON_NAME_NEW },
          onClick: this._onCreateAccount,
        });
      }

      if (selectedAccount) {
        if (currentUserPermissionSet.hasSelectedAccountWrite()) {
          admSubMenuItems.push({
            key: "EditAccount",
            name: `Edit ${selectedAccountName}`,
            iconProps: { iconName: icons.ICON_NAME_EDIT },
            onClick: this._onEditAccount,
          });
        }

        if (currentUserPermissionSet.hasGlobalWrite()) {
          admSubMenuItems.push({
            key: "RemoveAccount",
            name: `Remove ${selectedAccountName}`,
            iconProps: { iconName: icons.ICON_NAME_REMOVE },
            onClick: this._onRemoveAccount,
          });
        }
      }
      if (admSubMenuItems.length > 0) {
        newSubMenu.items.push({
          key: "divider",
          name: "-",
          itemType: ContextualMenuItemType.Divider,
        });
        newSubMenu.items = newSubMenu.items.concat(admSubMenuItems);
      }

      farItems.push({
        key: "person",
        onRender: this._renderPersona,
        className: "ms-CommandBarItem",
        subMenuProps: {
          items: [
            {
              key: "CurrentAccount",
              name: selectedAccountName || "No account",
              iconProps: { iconName: icons.ICON_NAME_ACCOUNT },
              subMenuProps:
                newSubMenu.items.length > 0 ? newSubMenu : undefined,
            },
            {
              key: "divider",
              name: "-",
              itemType: ContextualMenuItemType.Divider,
            },
            {
              key: "signout",
              name: "Sign out",
              iconProps: { iconName: icons.ICON_NAME_SIGNOUT },
              onClick: () => this._linkTo(url_consts.LOGOUT_RELATIVE),
            },
            {
              key: "switchuser",
              name: "Switch user account",
              iconProps: { iconName: icons.ICON_NAME_SWITCH_USER },
              onClick: () => this._linkTo(url_consts.SWITCH_USER),
            },
          ],
        },
      });
    } else {
      farItems.push({
        key: "signin",
        name: "Sign in",
        onClick: () => this._linkTo(url_consts.LOGIN_RELATIVE),
      });
    }

    return farItems;
  }

  render() {
    let { isLoggedIn, urls, visibleNotifications, queue } = this.props;

    let logoImgSrc = urls.staticFilesUrl + "images/logo.png";
    let logoLinkUrl = isLoggedIn
      ? url_consts.TIMEREGISTRATION_RELATIVE
      : url_consts.HOME_RELATIVE;
    let queuedModels = queue.getValidChanges();
    let invalidQueuedModels = queue.getInvalidChanges();

    const navbarItems = this._getNavigationItems();
    const farItems = this._getNavigationFarItems();

    return (
      <div className="novatime-header">
        <div className="novatime-container">
          <Grid>
            <Row>
              <Column sm={8}>
                <div className="title-bar">
                  <img
                    className="novataris-logo"
                    src={logoImgSrc}
                    alt="Novataris logo"
                    onClick={() => this._linkTo(logoLinkUrl)}
                  />
                </div>
              </Column>
              <Column sm={4}>
                <LoadingIndicator
                  onDismissFailed={this._dismissFailedRequests}
                  onRollbackQueued={this._rollbackQueuedChanges}
                  onRollbackInvalidQueue={this._rollbackInvalidQueuedChanges}
                  visibleNotifications={visibleNotifications}
                  queuedModels={queuedModels}
                  invalidQueuedModels={invalidQueuedModels}
                />
              </Column>
            </Row>
          </Grid>
        </div>
        <div className="novatime-header-menu-wrapper">
          <div className="novatime-container">
            <Grid>
              <Row>
                <Column sm={12}>
                  <NavBar
                    items={navbarItems}
                    isSearchBoxVisible={false}
                    areNamesVisible={true}
                    areIconsVisible={true}
                    areItemsEnabled={true}
                    overflowItems={[]}
                    farItems={farItems}
                  />
                </Column>
              </Row>
            </Grid>
          </div>
        </div>
      </div>
    );
  }
}

function mapState(state) {
  let pendingAbsencePeriodStatus = absencePeriodStatusSelectors.filterByName(
    absencePeriodStatusSelectors.allAbsencePeriodStatus(state),
    absencePeriodStatusName.PENDING
  );
  let selectedAccount = appDomainSelectors.selectedAccount(state);
  let allAccountsSorted = accountReducerSelectors.allAccountsSorted(state);

  return {
    isLoggedIn: authSelectors.isLoggedIn(state),
    isCheckingAuth: authSelectors.isCheckingAuth(state),
    currentUser: userSelectors.currentUser(state),
    visibleNotifications: notificationSelectors.visibleNotifications(state),
    urls: rootSelectors.urls(state),
    environment: rootSelectors.environment(state),
    queue: writeBackQueueSelectors.queue(state),
    currentUserResource: resourceSelectors.currentUserResource(state),
    allAbsencePeriods: absencePeriodSelectors.allAbsencePeriods(state),
    pendingAbsencePeriodStatus,
    selectedAccount,
    allAccountsSorted,
    currentUserPermissionSet:
      permissionSelectors.currentUserPermissionSet(state),
  };
}

function mapDispatch(dispatch) {
  const actions = Object.assign(
    {
      goToUrl,
      rollbackAllChanges: writeBackQueueActions.rollbackAllChanges,
      rollbackAllInvalidChanges:
        writeBackQueueActions.rollbackAllInvalidChanges,
      selectAccount,
      dismissAll: notificationActions.dismissAll,
    },
    editorActions
  );
  return { actions: bindActionCreators(actions, dispatch) };
}

export default withRouter(
  withCurrentUserContext(connect(mapState, mapDispatch)(Header))
);

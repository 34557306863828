import React from "react";
import {
  Callout,
  DelayedRender,
  IconButton,
  DirectionalHint,
  Stack,
  Text,
  Toggle,
  Icon,
} from "@fluentui/react";
import * as Icons from "../../constants/Icons";
import { useId, useBoolean } from "@fluentui/react-hooks";

const workFromHomeTitleText = "Working from home";

type ButtonIndicatorProps = Record<never, any>;
function ButtonIndicator(props: React.PropsWithChildren<ButtonIndicatorProps>) {
  const buttonId = useId("callout-button");
  const [isCalloutVisible, { toggle: toggleIsCalloutVisible }] =
    useBoolean(false);

  return (
    <>
      <IconButton
        iconProps={{
          iconName: Icons.ICON_NAME_WORK_FROM_HOME,
          styles: { root: { fontSize: "14px", margin: 0 } },
        }}
        title={workFromHomeTitleText}
        id={buttonId}
        onClick={toggleIsCalloutVisible}
        style={{
          color: "inherit",
          width: "auto",
          height: "auto",
          padding: 0,
        }}
      />
      {isCalloutVisible && (
        <Callout
          target={`#${buttonId}`}
          onDismiss={toggleIsCalloutVisible}
          role="alert"
          style={{ padding: "10px 14px" }}
          directionalHint={DirectionalHint.bottomCenter}
        >
          {props.children}
        </Callout>
      )}
    </>
  );
}

type WorkFromHomeButtonProps = {
  isModifyable: boolean;
  showIndicator: boolean;
  isIndicatorExpandable: boolean;
  onChange: (event: React.MouseEvent<HTMLElement>, checked?: boolean) => void;
  value: boolean;
  isEditable: boolean;
};

export default function WorkFromHomeToggle({
  isModifyable,
  showIndicator,
  isIndicatorExpandable,
  onChange,
  children,
  isEditable,
  value,
}: React.PropsWithChildren<WorkFromHomeButtonProps>) {
  return (
    <Stack
      enableScopedSelectors
      horizontal
      horizontalAlign="end"
      tokens={{
        childrenGap: 10,
      }}
      style={{
        visibility: !(isModifyable || showIndicator) ? "hidden" : undefined,
      }}
    >
      <Stack.Item align="center">
        <Toggle
          style={{
            marginTop: "5px",
            visibility: isModifyable ? "visible" : "hidden",
          }}
          checked={value}
          disabled={!isEditable}
          onChange={onChange}
        />
      </Stack.Item>
      {showIndicator ? (
        <Stack.Item align="center">
          {isIndicatorExpandable ? (
            <ButtonIndicator>{children}</ButtonIndicator>
          ) : (
            <Icon
              iconName={Icons.ICON_NAME_WORK_FROM_HOME}
              title={workFromHomeTitleText}
            />
          )}
        </Stack.Item>
      ) : null}
    </Stack>
  );
}

import React, { Component } from "react";
import { DatePicker, DayOfWeek } from "@fluentui/react";
import * as Icons from "../../constants/Icons";
import { Label } from "@fluentui/react/lib/Label";
import { IconButton } from "@fluentui/react/lib/Button";
import PropTypes from "prop-types";
import moment from "moment";

const DayPickerStrings = {
  months: [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ],

  shortMonths: [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ],

  days: [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ],

  shortDays: ["S", "M", "T", "W", "T", "F", "S"],

  goToToday: "Go to today",
  prevMonthAriaLabel: "Go to previous month",
  nextMonthAriaLabel: "Go to next month",
  prevYearAriaLabel: "Go to previous year",
  nextYearAriaLabel: "Go to next year",

  isRequiredErrorMessage: "Field is required.",

  invalidInputErrorMessage: "Invalid date format.",
};

export default class DatePicker2 extends Component {
  constructor(props) {
    super(props);
    this._formatDate = this._formatDate.bind(this);
    this._onLeftClick = this._onLeftClick.bind(this);
    this._onRightClick = this._onRightClick.bind(this);
  }

  _formatDate = this.props.formatDate
    ? this.props.formatDate
    : (date) => {
        let d = moment(date);
        return d.format("Do MMMM YYYY");
      };

  _onLeftClick = this.props.onLeftClick
    ? this.props.onLeftClick
    : () => {
        if (this.props.onSelectDate && this.props.value) {
          this.props.onSelectDate(
            moment(this.props.value).subtract(1, "month").toDate()
          );
        }
      };

  _onRightClick = this.props.onRightClick
    ? this.props.onRightClick
    : () => {
        if (this.props.onSelectDate && this.props.value) {
          this.props.onSelectDate(
            moment(this.props.value).add(1, "month").toDate()
          );
        }
      };

  render() {
    var { label, validate, placeholder, value, displayArrows, ...other } =
      this.props;
    let message = validate ? validate(this.props.value) : undefined;
    let val = !!value ? moment(value).toDate() : undefined;
    displayArrows = displayArrows != undefined ? displayArrows : true;
    return (
      <div className="novatime-date-picker">
        {label ? <Label>{label}</Label> : undefined}
        {displayArrows ? (
          <div className="clearfix">
            <div className="novatime-datepicker-left">
              <IconButton
                iconProps={{ iconName: Icons.ICON_GO_LEFT }}
                onClick={this._onLeftClick}
              />
            </div>
            <div className="novatime-datepicker-right">
              <IconButton
                iconProps={{ iconName: Icons.ICON_GO_RIGHT }}
                onClick={this._onRightClick}
              />
            </div>
            <div className="novatime-datepicker-center">
              <div className="picker-and-placeholder">
                <DatePicker
                  {...other}
                  firstWeekOfYear={2}
                  firstDayOfWeek={DayOfWeek.Monday}
                  strings={DayPickerStrings}
                  value={val}
                  placeholder={placeholder}
                  formatDate={this._formatDate}
                />
              </div>
            </div>
            <p className="error-message">
              <span aria-live="assertive" data-automation-id="error-message">
                {message}
              </span>
            </p>
          </div>
        ) : (
          <div className="picker-and-placeholder">
            <DatePicker
              {...other}
              firstWeekOfYear={2}
              firstDayOfWeek={DayOfWeek.Monday}
              strings={DayPickerStrings}
              value={val}
              placeholder={placeholder}
              formatDate={this._formatDate}
            />
            <p className="error-message">
              <span aria-live="assertive" data-automation-id="error-message">
                {message}
              </span>
            </p>
          </div>
        )}
      </div>
    );
  }
}

DatePicker.propTypes = {
  onLeftClick: PropTypes.func,
  onRightClick: PropTypes.func,
  formatDate: PropTypes.func,
};

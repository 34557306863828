import {all, fork} from 'redux-saga/effects';
import usersAndResourcesPageOpened from './pageOpened';
import usersAndResourcesAccountSelectedNow from './fetchUsers'
import usersAndResourcesloadUsersResourcesAndPermissions from './loadUsers'

export default function* pageRootSaga() {
    yield all([
        fork(usersAndResourcesPageOpened),
        fork(usersAndResourcesAccountSelectedNow),
        fork(usersAndResourcesloadUsersResourcesAndPermissions),
    ]);
}

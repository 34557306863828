import {put, select} from 'redux-saga/effects';
import * as types from '../../../constants/ActionTypes';
import * as authSelectors from '../../../selectors/reducers/auth';
import {takeLatestAfterBootstrap} from '../../helpers';
import * as timeRegistrationActions from '../../../actions/api/timeRegistration';
import {projectById} from '../../../selectors/reducers/project';
import * as apihelper from '../../../selectors/apihelper';
import * as pageSelectors from '../../../selectors/pages/absencePeriods';
import * as absencePeriodActions from '../../../actions/api/absencePeriod';
import * as appDomainSelectors from '../../../selectors/app/domain';

function* doAbsencePeriodLoadCurrent() {
    const loggedIn = yield select(authSelectors.isLoggedIn);

    if (loggedIn) {
        const selectedResourceId = yield select(pageSelectors.selectedResourceId);
        const selectedResource = yield select(pageSelectors.selectedResource);
        const {startDate, endDate} = yield select(pageSelectors.vacationStartAndEndDate);
        
        yield put(absencePeriodActions.getAllAbsencePeriods(startDate, selectedResourceId, endDate));

        if (selectedResource){
            const vacationProject = yield select(appDomainSelectors.selectedAccountVacationProject);
            if(vacationProject){
                const eligibleForAbsenceRegistration = apihelper.getAttr(selectedResource, 'eligibleForAbsenceRegistration');

                if(eligibleForAbsenceRegistration && startDate) {
                    yield put(timeRegistrationActions.getAllTimeRegistrations({ 
                        project: vacationProject, 
                        resource: selectedResource, 
                        startTime: startDate, 
                        endTime: endDate 
                    }));
                }   
            }
        }
    }
}

export default function* absencePeriodLoadCurrent() {
    yield takeLatestAfterBootstrap(types.APP_PAGE_ABSENCEPERIODS_LOAD_CURRENT, doAbsencePeriodLoadCurrent);
}

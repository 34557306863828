import React from "react";
import PropTypes from "prop-types";
import SingleBaseForm from "./SingleBaseForm";
import * as EntityType from "../../constants/EntityTypes";
import { getAttr, getEntityType } from "../../selectors/apihelper";
import FormCommand from "../../lib/FormCommand";

const nameAttributeMap = {
  [EntityType.ACCOUNT]: "name",
  [EntityType.CLIENT]: "name",
  [EntityType.CONTRACT]: "title",
  [EntityType.RESOURCE]: "name",
  [EntityType.CONTRACTROLE]: "name",
  [EntityType.PROJECT]: "name",
};

function getDeleteMessage(entity) {
  let entityType = getEntityType(entity),
    hasKey = entityType in nameAttributeMap,
    entityWrittenName = entityType.replace(/([a-z])([A-Z])/g, "$1 $2"),
    result =
      "Are you sure you want to delete the selected " + entityWrittenName + "?";
  if (hasKey) {
    result =
      "Are you sure you want to delete the " +
      entityWrittenName +
      ' "' +
      getAttr(entity, nameAttributeMap[entityType]) +
      '"?';
  }
  return result;
}

const referenceMap = {
  [EntityType.ACCOUNT]: "clients",
  [EntityType.CLIENT]: "contracts",
  [EntityType.CONTRACT]: "contract roles and/or projects",
  [EntityType.RESOURCE]: "timeregistrations",
  [EntityType.CONTRACTROLE]: "projects and/or timeregistrations",
  [EntityType.PROJECT]: "project resources",
};

const getDeniedMessage = (entity) => {
  let entityType = getEntityType(entity);
  return (
    "You cannot delete the " +
    entityType +
    " because it has one or more " +
    referenceMap[entityType] +
    " tied to it."
  );
};

export default class DeleteForm extends SingleBaseForm {
  _onDelete() {
    var modelChanges = this._getModelChanges();
    this.props.onDelete([
      new FormCommand(this.props.model, true, modelChanges),
    ]);
  }

  render() {
    let { model } = this.props;
    let message = this.props.hasReferences
      ? getDeniedMessage(model)
      : getDeleteMessage(model);

    return (
      <div>
        <h2>Delete</h2>
        <br />
        <p>{message}</p>
        <br />
        <br />
        {this._renderDeleteButtons()}
        <br />
        {this._renderTimestampsAndId(model)}
      </div>
    );
  }
}

DeleteForm.propTypes = {
  model: PropTypes.object,
  onDelete: PropTypes.func,
  onDismiss: PropTypes.func,
};

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import KpiStack from '../../KpiStack';
import { Icon } from '@fluentui/react/lib/Icon';
import {BillabilityAggregate, AccountForm} from '../../../lib/reportAggregators/billability';

const InfoIcon = () => <Icon iconName="Info" />;

function WarningLine(props){
    return (
        <p className='novatime-report-warning-line'>
            {props.children} 
            {props.infoTitle ? 
                <span className='novatime-report-warning-line-spacer' title={props.infoTitle}><InfoIcon /></span>
            : null}
            
        </p>
    )
}

export default class HourTotalReport extends Component {
    static propTypes = {
        reportData: PropTypes.instanceOf(BillabilityAggregate)
    };

    render(){
        let { reportData } = this.props;

        let totalHours = reportData.getHours();
        let totalCount = reportData.getCount();
        let billableHours = reportData.getHours({accountForm: AccountForm.Billable});
        let billableCount = reportData.getCount({accountForm: AccountForm.Billable});
        let nonBillableHours = reportData.getHours({accountForm: AccountForm.NonBillable});
        let nonBillableCount = reportData.getCount({accountForm: AccountForm.NonBillable});
        let unaccountedHours = reportData.getHours({accountForm: AccountForm.Unaccounted});
        let unaccountedCount = reportData.getCount({accountForm: AccountForm.Unaccounted});

        let billabilityRatio = totalHours > 0 ? billableHours / totalHours : 0;

        let hourKpis = [
            {
                title: 'Total', 
                value: totalHours,
                description: `Total sum of hours across all ${totalCount} time registrations`
            },
            {
                title: 'Billable', 
                value: billableHours,
                description: `Sum of hours across all ${billableCount} billable time registrations`
            },
            {
                title: 'Non-billable', 
                value: nonBillableHours,
                description: `Sum of hours across all ${nonBillableCount} non-billable time registrations`
            },
            {
                title: 'Ratio', 
                value: `${(billabilityRatio * 100).toFixed(1)}%`,
                description: `The share of total hours that are billable`
            }
        ];

        let unaccountedDescription = "These hours are billable but cannot be accounted for since the " +
                                     "hour price cannot be found on the assocated contract roles. Unaccounted hours " +
                                     "are included in the billable sum duration but excluded from revenue calculations";

        return (
            <>
                <KpiStack kpis={hourKpis} />
                {
                    unaccountedCount > 0 ?
                    <WarningLine infoTitle={unaccountedDescription}>{unaccountedHours} hours were unaccounted</WarningLine> :
                    null
                }
            </>
            
        );
    }
}

import * as SagaHelpers from '../helpers';
import * as SagaEffects from 'redux-saga/effects';
import * as types from '../../constants/ActionTypes';
import AccountConnector from '../../connectors/Account';
import * as appActions from '../../actions/appAction';
import * as apihelper from '../../selectors/apihelper';
import * as accountSelectors from '../../selectors/reducers/account';
import { ResourceObjects } from '../../lib/models';

const createAccount = SagaHelpers.createApiHandler(
    types.API_ACCOUNT_CREATE_INIT, 
    SagaHelpers.generateCreateApiCall(
        AccountConnector,
        function*(connector:AccountConnector, action: any): any {
            return yield SagaEffects.call([connector, connector.createAccount], {
                data: action.payload.model
            });
        }
    )
)

const deleteAccount = SagaHelpers.createApiHandler(
    types.API_ACCOUNT_DELETE_INIT, 
    SagaHelpers.generateDeleteApiCall(
        AccountConnector, 
        function*(connector: AccountConnector, action: any): any {
            const selectedAccountId = action.payload.model.id;
            const allAccs: ResourceObjects = yield SagaEffects.select(accountSelectors.allAccounts);
            const accounts = allAccs.filter(a =>  !apihelper.entityHasId(a,  selectedAccountId));
            // Setting another account Account after delete
            yield SagaEffects.put(appActions.selectAccount(apihelper.getEntityId(accounts[0])));
            return yield SagaEffects.call([connector, connector.deleteAccount], selectedAccountId);
        }
    )
)

const getAllAccounts = SagaHelpers.createApiHandler(
    types.API_ACCOUNT_GET_ALL_INIT, 
    SagaHelpers.generateGetAllApiCall(
        AccountConnector, 
        function*(connector: AccountConnector): any {
            return yield SagaEffects.call([connector, connector.getAccounts]);
        }
    )
)

const updateAccount = SagaHelpers.createApiHandler(
    types.API_ACCOUNT_UPDATE_INIT, 
    SagaHelpers.generateUpdateApiCall(
        AccountConnector,
        function*(connector: AccountConnector, action: any, requestData: any): any {
            return yield SagaEffects.call([connector, connector.updateAccount], action.payload.model.id, requestData);
        }
    )
)

export default function* accountSaga() {
    yield SagaEffects.all([
        SagaEffects.fork(getAllAccounts),
        SagaEffects.fork(createAccount),
        SagaEffects.fork(updateAccount),
        SagaEffects.fork(deleteAccount),
    ]);
}

import React from "react";
import PropTypes from "prop-types";
import { TextField } from "@fluentui/react/lib/TextField";
import { Dropdown } from "@fluentui/react/lib/Dropdown";
import { Checkbox } from "@fluentui/react/lib/Checkbox";
import { Label } from "@fluentui/react/lib/Label";
import LocaleSelector from "../LocaleSelector";
import DatePicker from "../uifabricextensions/DatePicker";
import {
  entityHasId,
  getAttr,
  getEntityId,
  getRelId,
} from "../../selectors/apihelper";
import {
  createEmptyModelAttributes,
  createEmptyModelRelationships,
} from "../../lib/emptyModelCreation";
import * as EntityTypes from "../../constants/EntityTypes";
import UserPicker from "../uifabricextensions/UserPicker";
import SingleBaseForm from "./SingleBaseForm";
import CurrentUserContext from "../../contexts/CurrentUserContext";
import * as InternalPropTypes from "../../constants/PropTypes";
import moment from "moment";
import { Stack } from "@fluentui/react/lib/Stack";
import { lexicalInsensitiveNameSortFunc } from "../../lib/sorting";

export default class ResourceForm extends SingleBaseForm {
  constructor(props) {
    super(props);
    let { model } = props;
    this.state = {};

    this.state.fields = SingleBaseForm._initializeFields(
      {
        name: {
          getVal: () => getAttr(this.props.model, "name"),
          setVal: () =>
            createEmptyModelAttributes(EntityTypes.RESOURCE, {
              name: this.state.fields.name.value,
            }),
          isRequired: true,
        },
        description: {
          getVal: () => getAttr(this.props.model, "description"),
          setVal: () =>
            createEmptyModelAttributes(EntityTypes.RESOURCE, {
              description: this.state.fields.description.value,
            }),
          isRequired: false,
        },
        resourceTypeId: {
          getVal: () => getRelId(this.props.model, "resourceType"),
          setVal: () =>
            createEmptyModelRelationships(EntityTypes.RESOURCE, {
              resourceType: {
                id: this.state.fields.resourceTypeId.value,
                type: EntityTypes.RESOURCETYPE,
              },
            }),
          isRequired: true,
        },
        userId: {
          getVal: () => getRelId(this.props.model, "user"),
          setVal: () =>
            createEmptyModelRelationships(EntityTypes.RESOURCE, {
              user:
                this.state.fields.userId.value != null
                  ? {
                      id: this.state.fields.userId.value,
                      type: EntityTypes.USER,
                    }
                  : null,
            }),
          isRequired: false,
        },
        active: {
          getVal: () => getAttr(this.props.model, "isActive"),
          setVal: () =>
            createEmptyModelAttributes(EntityTypes.RESOURCE, {
              isActive: this.state.fields.active.value,
            }),
          isRequired: true,
        },
        employedAtLocaleId: {
          getVal: () => getRelId(this.props.model, "employedAtLocale"),
          setVal: () =>
            createEmptyModelRelationships(EntityTypes.RESOURCE, {
              employedAtLocale:
                this.state.fields.employedAtLocaleId.value != null
                  ? {
                      id: this.state.fields.employedAtLocaleId.value,
                      type: EntityTypes.LOCALE,
                    }
                  : null,
            }),
          isRequired: false,
        },
        utilizationTarget: {
          getVal: () => getAttr(this.props.model, "utilizationTarget"),
          setVal: () =>
            createEmptyModelAttributes(EntityTypes.RESOURCE, {
              utilizationTarget: this.state.fields.utilizationTarget.value - 0,
            }),
          isRequired: true,
        },
        eligibleForAbsenceRegistration: {
          getVal: () =>
            getAttr(this.props.model, "eligibleForAbsenceRegistration"),
          setVal: () =>
            createEmptyModelAttributes(EntityTypes.RESOURCE, {
              eligibleForAbsenceRegistration:
                this.state.fields.eligibleForAbsenceRegistration.value,
            }),
          isRequired: false,
        },
        vacationYearStart: {
          getVal: () => getAttr(this.props.model, "vacationYearStart"),
          setVal: () =>
            createEmptyModelAttributes(EntityTypes.RESOURCE, {
              vacationYearStart: moment(
                this.state.fields.vacationYearStart.value
              ).format("YYYY-MM-DD HH:mm:ss"),
            }),
          isRequired: false,
        },
        vacationHours: {
          getVal: () => getAttr(this.props.model, "vacationHours"),
          setVal: () =>
            createEmptyModelAttributes(EntityTypes.RESOURCE, {
              vacationHours: this.state.fields.vacationHours.value - 0,
            }),
          isRequired: false,
        },
        isPermanentlyEmployed: {
          getVal: () => getAttr(this.props.model, "isPermanentlyEmployed"),
          setVal: () =>
            createEmptyModelAttributes(EntityTypes.RESOURCE, {
              isPermanentlyEmployed:
                !!this.state.fields.isPermanentlyEmployed.value,
            }),
          isRequired: false,
        },
        isPaidByTheHour: {
          getVal: () => getAttr(this.props.model, "isPaidByTheHour"),
          setVal: () =>
            createEmptyModelAttributes(EntityTypes.RESOURCE, {
              isPaidByTheHour: !!this.state.fields.isPaidByTheHour.value,
            }),
          isRequired: false,
        },
        payslipEmployeeNumber: {
          getVal: () => getAttr(this.props.model, "payslipEmployeeNumber"),
          setVal: () =>
            createEmptyModelAttributes(EntityTypes.RESOURCE, {
              payslipEmployeeNumber:
                "" + this.state.fields.payslipEmployeeNumber.value,
            }),
          isRequired: false,
        },
        payslipEmployeeName: {
          getVal: () => getAttr(this.props.model, "payslipEmployeeName"),
          setVal: () =>
            createEmptyModelAttributes(EntityTypes.RESOURCE, {
              payslipEmployeeName:
                "" + this.state.fields.payslipEmployeeName.value,
            }),
          isRequired: false,
        },
      },
      model
    );

    this._onNameChange = this._onNameChange.bind(this);
    this._onDescriptionChange = this._onDescriptionChange.bind(this);
    this._onResourceTypeIdChange = this._onResourceTypeIdChange.bind(this);
    this._onUserIdChange = this._onUserIdChange.bind(this);
    this._onActiveChange = this._onActiveChange.bind(this);
    this._onEmployedAtLocaleIdChange =
      this._onEmployedAtLocaleIdChange.bind(this);
    this._localeLabelTextFunc = this._localeLabelTextFunc.bind(this);
    this._onUtilizationTargetChange =
      this._onUtilizationTargetChange.bind(this);
    this._onEligibleForAbsenceRegistration =
      this._onEligibleForAbsenceRegistration.bind(this);
    this._onVacationYearStartChange =
      this._onVacationYearStartChange.bind(this);
    this._onvacationHoursChange = this._onvacationHoursChange.bind(this);

    this._onPayslipEmployeeNumberChange =
      this._onPayslipEmployeeNumberChange.bind(this);
    this._onPayslipEmployeeNameChange =
      this._onPayslipEmployeeNameChange.bind(this);
    this._onPaidByTheHourChange = this._onPaidByTheHourChange.bind(this);
    this._onPermanentlyEmployedChange =
      this._onPermanentlyEmployedChange.bind(this);
  }

  static propTypes = {
    model: InternalPropTypes.resourceEntity,
    users: InternalPropTypes.userEntities,
    resources: InternalPropTypes.resourceEntities,
    onSave: PropTypes.func,
    onDismiss: PropTypes.func,
  };

  _onUtilizationTargetChange(e, value) {
    this._updateFieldState(
      "utilizationTarget",
      value,
      value < 0 || value > 100 ? "Value must be a percentage" : undefined
    );
  }

  _onEmployedAtLocaleIdChange(localeName) {
    let { locales } = this.props;

    let localeId = getEntityId(
      locales.find((l) => getAttr(l, "name") === localeName)
    );
    this._updateFieldState("employedAtLocaleId", localeId);
  }

  _localeLabelTextFunc(localeName) {
    let { locales } = this.props;

    let locale = locales.find((l) => getAttr(l, "name") === localeName);
    return getAttr(locale, "longName");
  }

  _onNameChange(e, value) {
    this._updateFieldState(
      "name",
      value,
      !value ? "Name cannot be empty" : undefined
    );
  }

  _onDescriptionChange(e, value) {
    this._updateFieldState("description", value);
  }

  _onResourceTypeIdChange(e, value) {
    this._updateFieldState(
      "resourceTypeId",
      value.key,
      !value.key ? "Must select resource type" : undefined
    );
  }

  _onUserIdChange(selectedUsers) {
    this._updateFieldState(
      "userId",
      selectedUsers[0] ? getEntityId(selectedUsers[0]) : null
    );
  }

  _onActiveChange(ev, isChecked) {
    this._updateFieldState("active", isChecked);
  }

  _onEligibleForAbsenceRegistration(ev, isChecked) {
    this._updateFieldState("eligibleForAbsenceRegistration", isChecked);
  }

  _onVacationYearStartChange(value) {
    this._updateFieldState("vacationYearStart", value);
  }

  _onvacationHoursChange(e, value) {
    this._updateFieldState("vacationHours", value);
  }

  _onPayslipEmployeeNumberChange(e, value) {
    this._updateFieldState("payslipEmployeeNumber", value);
  }

  _onPayslipEmployeeNameChange(e, value) {
    this._updateFieldState("payslipEmployeeName", value);
  }

  _onPaidByTheHourChange(e, isChecked) {
    this._updateFieldState("isPaidByTheHour", isChecked);
  }

  _onPermanentlyEmployedChange(e, isChecked) {
    this._updateFieldState("isPermanentlyEmployed", isChecked);
  }

  render() {
    let { model, resources, users, locale, allResourceType } = this.props;
    let nameIsChanged = this.state.fields.name.isChanged;
    let resourceTypeIdIsChanged = this.state.fields.resourceTypeId.isChanged;
    let descriptionIsChanged = this.state.fields.description.isChanged;
    let utilizationTargetIsChanged =
      this.state.fields.utilizationTarget.isChanged;
    let vacationHoursIsChanged = this.state.fields.vacationHours.isChanged;
    let payslipEmployeeNumberChanged =
      this.state.fields.payslipEmployeeNumber.isChanged;
    let payslipEmployeeNameChanged =
      this.state.fields.payslipEmployeeName.isChanged;
    // let isPaidByTheHourChanged = this.state.fields.isPaidByTheHour.isChanged
    // let isPermanentlyEmployedChanged = this.state.fields.isPermanentlyEmployed.isChanged

    let showChangeIcons = !!model;
    let selectedUsers = [];
    let selectedUser = users.find((u) =>
      entityHasId(u, this.state.fields.userId.value)
    );
    if (selectedUser) {
      selectedUsers.push(selectedUser);
    }

    let selectedLocale = locale && locale.attributes.name;

    let selectedVacationYear =
      (this.state.fields.vacationYearStart.value &&
        this.state.fields.vacationYearStart.value
          .toString()
          .startsWith("0001")) ||
      !this.state.fields.vacationYearStart.value
        ? ""
        : new Date(this.state.fields.vacationYearStart.value);

    const containerStackTokens = { childrenGap: 5 };
    // const checkboxRedactedState = {hasAccountOrGlobalAdminRead} ? "" : "disabled defaultIndeterminate";

    return (
      <div>
        <h2>{model ? "Edit" : "Create"} Resource</h2>
        <br />
        <Checkbox
          label="Active"
          onChange={this._onActiveChange}
          checked={this.state.fields.active.value}
        />
        <br />
        <TextField
          label="Name"
          autoFocus={!model}
          iconProps={this._getIconProps(nameIsChanged)}
          onChange={this._onNameChange}
          required={this.state.fields.name.isRequired}
          value={this.state.fields.name.value}
          errorMessage={this.state.fields.name.validationMessage}
        />
        <br />
        <TextField
          label="Description"
          iconProps={this._getIconProps(descriptionIsChanged)}
          onChange={this._onDescriptionChange}
          required={this.state.fields.description.isRequired}
          value={this.state.fields.description.value}
          errorMessage={this.state.fields.description.validationMessage}
        />
        <br />
        <Dropdown
          onChange={this._onResourceTypeIdChange}
          label="Resource type"
          required={this.state.fields.resourceTypeId.isRequired}
          buttonIconProps={this._getIconProps(resourceTypeIdIsChanged)}
          selectedKey={this.state.fields.resourceTypeId.value}
          options={allResourceType
            .slice()
            .sort(lexicalInsensitiveNameSortFunc)
            .map((n) => {
              return { key: getEntityId(n), text: n.attributes.name };
            })}
        />

        {/*<Label className="placeholder">{this.state.fields.resourceTypeId.value ? '' : 'Select resource type'}</Label>*/}
        <br />
        <Label>User</Label>
        <UserPicker
          resources={resources}
          users={users}
          selectedUsers={selectedUsers}
          onUserSelected={this._onUserIdChange}
        />
        <br />
        <Label>Employed at locale</Label>
        <LocaleSelector
          onSelectLocale={this._onEmployedAtLocaleIdChange}
          onRemoveLocale={this._onEmployedAtLocaleIdChange}
          selectedLocale={selectedLocale}
          isLocaleSelectable={true}
          labelTextFunc={this._localeLabelTextFunc}
        />
        <CurrentUserContext.Consumer>
          {({ currentUserPermissionSet }) => {
            return currentUserPermissionSet.hasSelectedAccountRead() ? (
              <TextField
                label="Utilization target"
                iconProps={this._getIconProps(utilizationTargetIsChanged)}
                onChange={this._onUtilizationTargetChange}
                required={this.state.fields.utilizationTarget.isRequired}
                value={this.state.fields.utilizationTarget.value}
                errorMessage={
                  this.state.fields.utilizationTarget.validationMessage
                }
              />
            ) : null;
          }}
        </CurrentUserContext.Consumer>

        <br />

        <p>
          By enabling Vacation and configuring the date for the vacation year,
          resources are able to request vacation.
        </p>
        <Checkbox
          label="Eligible for absence registration"
          onChange={this._onEligibleForAbsenceRegistration}
          checked={this.state.fields.eligibleForAbsenceRegistration.value}
        />

        <br />
        <fieldset>
          <legend>Vacation</legend>

          <DatePicker
            label="Vacation year start date"
            placeholder={selectedVacationYear ? "" : "Select an date"}
            isRequired={
              this.state.fields.eligibleForAbsenceRegistration.isRequired
            }
            value={selectedVacationYear}
            onSelectDate={this._onVacationYearStartChange}
            disabled={!this.state.fields.eligibleForAbsenceRegistration.value}
            displayArrows={false}
          />

          <TextField
            label="Total vacation hours in a vacation year"
            iconProps={this._getIconProps(vacationHoursIsChanged)}
            onChange={this._onvacationHoursChange}
            required={
              this.state.fields.eligibleForAbsenceRegistration.isRequired
            }
            value={this.state.fields.vacationHours.value}
            errorMessage={this.state.fields.vacationHours.validationMessage}
            disabled={!this.state.fields.eligibleForAbsenceRegistration.value}
          />
        </fieldset>

        <br />
        <CurrentUserContext.Consumer>
          {({ currentUserPermissionSet }) => {
            if (currentUserPermissionSet.hasSelectedAccountRead()) {
              return (
                <fieldset>
                  <legend>Pay</legend>
                  <Stack tokens={containerStackTokens}>
                    <Checkbox
                      label="Permanent employee"
                      onChange={this._onPermanentlyEmployedChange}
                      checked={this.state.fields.isPermanentlyEmployed.value}
                      checkboxRedactedState
                    />

                    <Checkbox
                      label="Paid by the hour"
                      onChange={this._onPaidByTheHourChange}
                      checked={this.state.fields.isPaidByTheHour.value}
                    />
                  </Stack>
                  <TextField
                    label="Name on payslip"
                    iconProps={this._getIconProps(payslipEmployeeNameChanged)}
                    onChange={this._onPayslipEmployeeNameChange}
                    value={this.state.fields.payslipEmployeeName.value}
                  />
                  <TextField
                    label="Payslip number"
                    iconProps={this._getIconProps(payslipEmployeeNumberChanged)}
                    onChange={this._onPayslipEmployeeNumberChange}
                    value={this.state.fields.payslipEmployeeNumber.value}
                  />
                </fieldset>
              );
            } else {
              return (
                <fieldset>
                  <legend>Pay</legend>
                  <span>
                    Information in this section requires Account Read permission
                    for the Resource.
                  </span>
                </fieldset>
              );
            }
          }}
        </CurrentUserContext.Consumer>

        <br />

        {this._renderButtons()}
        <br />
        {this._renderTimestampsAndId(model)}
      </div>
    );
  }
}

import {put, race, select, take, takeLatest} from 'redux-saga/effects';
import {getRelId, relHasReference} from '../../selectors/apihelper';
import * as types from '../../constants/ActionTypes';
import * as reducerUserSelectors from '../../selectors/reducers/user';
import {fetchInitialData} from '../../actions/app'
import * as appActions from '../../actions/appAction'
import * as authSelectors from '../../selectors/reducers/auth';

function* doBootstrap() {
    yield put({ type: types.APP_BOOTSTRAP_STARTED });
    yield put({ type: types.AUTH_INIT });
    yield take(types.AUTH_COMPLETE);
 
    // login succeeded
    const isLoggedIn = yield select(authSelectors.isLoggedIn);
    if (isLoggedIn) {
        let user = yield select(reducerUserSelectors.currentUser);

        // Fetch data which should be used througout the entire application / data which does not change during the life cycle of the application.
        yield put(fetchInitialData());
        yield take(types.APP_FETCHINITIALDATA_COMPLETED);

        if (relHasReference(user, "account")) {
            yield put( 
                appActions.selectAccount(getRelId(user, "account"))
            );
        }
    }

    yield put({ type: types.APP_BOOTSTRAP_COMPLETED });
}

export default function* bootstrap() {
    yield takeLatest(types.APP_BOOTSTRAP_INIT, doBootstrap);
}

import * as types from '../../constants/ActionTypes';

export function createResource(resource) {
    return {
        type: types.API_RESOURCE_CREATE_INIT,
        payload: {model: resource}
    };
}

export function deleteResource(resource) {
    return {
        type: types.API_RESOURCE_DELETE_INIT,
        payload: {model: resource}
    };
}

export function getAllResources(account) {
    return {
        type: types.API_RESOURCE_GET_ALL_INIT,
        payload: {
            account: account
        }
    };
}

export function updateResource(resource, modelChanges) {
    return {
        type: types.API_RESOURCE_UPDATE_INIT,
        payload: {
            model: resource,
            changes: modelChanges
        }
    };
}

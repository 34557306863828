import React, { Component } from "react";
import PropTypes from "prop-types";
import { TextField } from "@fluentui/react/lib/TextField";
import { List } from "@fluentui/react/lib/List";
import latinize from "latinize";
import fuzzysearch from "fuzzysearch";
import { DefaultButton } from "@fluentui/react/lib/Button";
import { FocusZone, FocusZoneDirection } from "@fluentui/react/lib/FocusZone";
import { Checkbox } from "@fluentui/react/lib/Checkbox";
import { ScrollablePane } from "@fluentui/react/lib/ScrollablePane";
import { Sticky, StickyPositionType } from "@fluentui/react/lib/Sticky";
import {
  relRefersToEntity,
  getAttr,
  getEntityId,
  entityHasId,
} from "../../selectors/apihelper";
import * as InternalPropTypes from "../../constants/PropTypes";
import ExtensionAlert from "../timeRegistration/extensionAlert";
import { Stack } from "@fluentui/react/lib/Stack";

// runs in two modes. If props are given, these take precedense. Otherwise, state runs expansion
export default class ProjectBrowser extends Component {
  static propTypes = {
    projects: InternalPropTypes.projectEntities,
    selectedProjects: InternalPropTypes.projectEntities,
    contracts: InternalPropTypes.contractEntities,
    clients: InternalPropTypes.clientEntities,
    onSelectProjects: PropTypes.func,
    onDismiss: PropTypes.func,
  };

  static defaultProps = {
    projects: [],
    selectedProjects: [],
    contracts: [],
    clients: [],
  };

  constructor(props) {
    super(props);
    this.state = {
      searchInput: "",
      selectedProjectIds: [],
    };
    this._searchFieldRef = null;

    this._onSearchFieldChange = this._onSearchFieldChange.bind(this);
    this._onRenderListItem = this._onRenderListItem.bind(this);
    this._onAddSelectedClicked = this._onAddSelectedClicked.bind(this);
    this._toggleProjectSelection = this._toggleProjectSelection.bind(this);
    this._onDismiss = this._onDismiss.bind(this);
  }

  componentDidMount() {
    this._searchFieldRef.focus();
  }

  _onSearchFieldChange(event, value) {
    this.setState({ searchInput: value });
  }

  _blandText(text) {
    return latinize(text || "")
      .toLowerCase()
      .replace(/[.,\/#!$%\^&\*;:{}=\-_`~()]/g, "");
  }

  _getFilteredItems() {
    let { searchInput, selectedProjectIds } = this.state;
    let { projects, contracts, clients, selectedProjects } = this.props;

    let items = projects.map((p) => {
      let contract = contracts.find((co) =>
        relRefersToEntity(p, "contract", co)
      );
      let client =
        contract &&
        clients.find((cl) => relRefersToEntity(contract, "client", cl));
      let isSelectable = !selectedProjects.find((sp) =>
        entityHasId(sp, getEntityId(p))
      );

      let res = {
        projectId: getEntityId(p),
        projectName: getAttr(p, "name"),
        isSelectable,
        projectDescription: getAttr(p, "description") || "",
        clientName: (client && getAttr(client, "name")) || "",
        selected: selectedProjectIds.indexOf(getEntityId(p)) >= 0,
      };
      res.searchText = this._blandText(
        res.projectName + res.projectDescription + res.clientName
      );
      return res;
    });
    items.sort((a, b) => {
      let nameA = a.clientName + a.projectName;
      let nameB = b.clientName + b.projectName;
      return nameA < nameB ? -1 : nameA > nameB ? 1 : 0;
    });
    return items.filter(
      (i) =>
        fuzzysearch(this._blandText(searchInput), i.searchText) || i.selected,
      this
    );
  }

  _toggleProjectSelection(item) {
    let { selectedProjectIds } = this.state;
    let newSelectedProjectIds;
    if (item.selected) {
      newSelectedProjectIds = selectedProjectIds.filter(
        (pid) => pid != item.projectId
      );
    } else {
      newSelectedProjectIds = selectedProjectIds.concat([item.projectId]);
    }
    this.setState({ selectedProjectIds: newSelectedProjectIds });
  }

  _onRenderListItem(item, index) {
    let toggle = () => this._toggleProjectSelection(item);
    let { hourSet, selectedResource } = this.props;

    let project = hourSet.getProjectById(item.projectId);

    return (
      <div
        className={
          "novatime-project-browser-item" + (item.selected ? " selected" : "")
        }
      >
        <Stack horizontal tokens={{ childrenGap: "5px" }}>
          <Checkbox
            label={item.clientName + " - " + item.projectName}
            className="novatime-project-browser-item-checkbox "
            checked={item.selected || !item.isSelectable}
            onChange={toggle}
            disabled={!item.isSelectable}
          />
        </Stack>

        {item.projectDescription ? (
          <p
            className="novatime-project-browser-item-description "
            onClick={toggle}
          >
            {item.projectDescription}
          </p>
        ) : undefined}
      </div>
    );
  }

  _onAddSelectedClicked() {
    if (this.props.onSelectProjects) {
      let selectedProjects = this.props.projects.filter((p) =>
        this.state.selectedProjectIds.includes(getEntityId(p))
      );
      this.props.onSelectProjects(selectedProjects);
    }
  }

  _onDismiss() {
    this.props.onDismiss && this.props.onDismiss();
  }

  render() {
    let { searchInput, selectedProjectIds } = this.state;
    let items = this._getFilteredItems();

    return (
      <div className="novatime-project-browser">
        <FocusZone direction={FocusZoneDirection.vertical}>
          <ScrollablePane>
            <Sticky stickyPosition={StickyPositionType.Header}>
              <div style={{ padding: "15px" }}>
                <TextField
                  componentRef={(input) => {
                    this._searchFieldRef = input;
                  }}
                  className="novatime-project-browser-search-field"
                  placeholder="Input keywords for search"
                  onChange={this._onSearchFieldChange}
                  value={searchInput}
                />
                <div className="novatime-project-browser-buttonbar">
                  {this.props.onSelectProjects ? (
                    <DefaultButton
                      primary={true}
                      onClick={this._onAddSelectedClicked}
                      disabled={selectedProjectIds.length == 0}
                      text={
                        "Add" +
                        (selectedProjectIds.length
                          ? " (" + selectedProjectIds.length + ")"
                          : "")
                      }
                    />
                  ) : undefined}
                  {this.props.onDismiss ? (
                    <DefaultButton onClick={this._onDismiss} text="Close" />
                  ) : undefined}
                </div>
              </div>
            </Sticky>
            <div className={"novatime-mobile-panel"}>
              {items.length > 0 ? (
                <List
                  className="novatime-project-browser-list"
                  items={items}
                  onRenderCell={this._onRenderListItem}
                />
              ) : (
                <p>No projects found</p>
              )}
            </div>
          </ScrollablePane>
        </FocusZone>
      </div>
    );
  }
}

import React, {Component} from 'react';
import PropTypes from 'prop-types';
import * as InternalPropTypes from '../../constants/PropTypes';
import SelectAllPicker from './SelectAllPicker';
import { relRefersToEntity, getAttr, getRelId, getEntityId, isEntity, getEntityType } from '../../selectors/apihelper';

let NameFunction = project => isEntity(project) ? getAttr(project, 'name') : 'All projects';

export default class ProjectPicker extends Component {
    render() {
        let { projectEntities,
              isSelectAllAnOption,
              isAllProjectsSelected,
              selectedProject,
              onProjectSelected,
              onAllProjectsSelected } = this.props;

        return (
            <SelectAllPicker
                isSelectAllAnOption={isSelectAllAnOption}
                isAllEntitiesSelected={isAllProjectsSelected}
                selectedEntity={selectedProject}
                onEntitySelected={onProjectSelected}
                onAllEntitiesSelected={onAllProjectsSelected}
                entities={projectEntities}
                label={'Project'}
                placeholder={'Select Project'}
                selectAllText={'All Projects'}
                nameFunction={NameFunction}
            />
        );
    }
}

ProjectPicker.propTypes = {
    projectEntities:            InternalPropTypes.projectEntities,
    isSelectAllAnOption:        PropTypes.bool,
    isAllProjectsSelected:      PropTypes.bool,
    selectedProject:            InternalPropTypes.projectEntity,
    onProjectSelected:          PropTypes.func,
    onAllProjectsSelected:      PropTypes.func,
};

ProjectPicker.defaultProps = {
    isSelectAllAnOption: false
}

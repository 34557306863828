import * as ApiHelper from '../selectors/apihelper';
import * as DateHelper from './date';

class DateInterval {
    constructor(startDate, endDate, lockedTimePeriod) {
        this.startDate = typeof startDate === 'string' ? new Date(startDate) : 
                         startDate instanceof Date ? startDate : undefined
        if(startDate === undefined){
            throw 'DateInterval cannot parse startDate: ' + startDate; 
        }

        this.endDate = typeof endDate === 'string' ? new Date(endDate) : 
                       endDate instanceof Date ? endDate : undefined;
        if(endDate === undefined){
            throw 'DateInterval cannot parse endDate: ' + endDate; 
        }
        this.lockedTimePeriod = lockedTimePeriod;
    }

    containsDate(date){
        return this.containsRange(date, date);
    }

    containsRange(startDate, endDate){
        return DateHelper.isWithinPeriod(this.startDate, this.endDate, startDate, endDate);
    }
    
    intersectsRange(startDate, endDate){
        return DateHelper.intersectsPeriod(this.startDate, this.endDate, startDate, endDate);
    }

    isStartDate(date){
        return DateHelper.isSameDay(date, this.startDate);
    }

    isEndDate(date){
        return DateHelper.isSameDay(date, this.endDate);
    }

    compare(otherDateInterval){
        return otherDateInterval.startDate - this.startDate;
    }
}

export default class LockedTimePeriodIndex {
    constructor(lockedTimePeriodEntities) {
        this.lockedTimePeriodEntities = lockedTimePeriodEntities || [];
        this._buildIndex();
    }

    _buildIndex(){
        this.intervalsByResource = {};
        this.allIntervals = [];

        // slice by resource id and add ranges in received order
        for(let i = 0; i < this.lockedTimePeriodEntities.length; i++){
            let currentLockedTimePeriod = this.lockedTimePeriodEntities[i];
            let interval = new DateInterval(
                ApiHelper.getAttr(currentLockedTimePeriod, 'startDate'), 
                ApiHelper.getAttr(currentLockedTimePeriod, 'endDate'), 
                currentLockedTimePeriod);

            this.allIntervals.push(interval);

            let resourceId = ApiHelper.getRelId(currentLockedTimePeriod, 'resource');
            if(!(resourceId in this.intervalsByResource)){
                this.intervalsByResource[resourceId] = [];
            }            
            this.intervalsByResource[resourceId].push(interval)
        }

        // sort all ranges by start date
        Object.keys(this.intervalsByResource).forEach(key => this.intervalsByResource[key].sort((a, b) => a.compare(b)), this)
        this.allIntervals.sort((a, b) => a.compare(b));
    }

    _filterIntervalsByOpenRange(intervals, startDate, endDate){
        return intervals !== null ?
               intervals.filter(interval => interval.intersectsRange(startDate, endDate)) :
               null;
    }

    _getIntervalsByResource(resourceId){
        return resourceId in this.intervalsByResource ?
               this.intervalsByResource[resourceId] :
               null;
    }

    _findFirstIntervalContainingDate(intervals, date){
        return intervals !== null ?
               intervals.find(interval => interval.containsDate(date)) :
               null;
    }

    _findFirstIntervalContainingDateForResource(date, resourceId){
        return this._findFirstIntervalContainingDate(this._getIntervalsByResource(resourceId), date);
    }

    isDateLockedForResource(date, resourceId){
        let interval = this._findFirstIntervalContainingDateForResource(date, resourceId);
        return !!interval;
    }

    isDateFirstDayOfLockedPeriodForResource(date, resourceId){
        let interval = this._findFirstIntervalContainingDateForResource(date, resourceId);
        return !!interval && interval.isStartDate(date);
    }

    isDateLastDayOfLockedPeriodForResource(date, resourceId){
        let interval = this._findFirstIntervalContainingDateForResource(date, resourceId);
        return !!interval && interval.isEndDate(date);
    }

    reduceByOpenDateRange(startDate, endDate){
        let ltps = this._filterIntervalsByOpenRange(this.allIntervals, startDate, endDate).map(interval => interval.lockedTimePeriod);
        return new LockedTimePeriodIndex(ltps);
    }

    isEntirePeriodLocked(startDate, endDate){
        let intervals = this._filterIntervalsByOpenRange(this.allIntervals, startDate, endDate);
        let curDate = startDate;
        while(curDate < endDate){
            let interval = this._findFirstIntervalContainingDate(intervals, curDate);
            if(!interval){
                return false;
            }
            curDate = DateHelper.addDays(DateHelper.min(interval.endDate, endDate), 1);
        }
        return true;
    }

    getLockedTimePeriods(){
        return this.allIntervals.map(interval => interval.lockedTimePeriod)
    }
}

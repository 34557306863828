import {all, fork} from 'redux-saga/effects';
import clientsAndContracts from './clientsAndContracts';
import projectsAndRoles from './projectsAndRoles';
import resourceManagement from './resourceManagement';
import timeRegistration from './timeRegistration';
import usersAndResources from './usersAndResources';
import absencePeriod from './absencePeriods';
import timeRegistrationExport from './timeRegistrationExport';
export default function* pageRootSaga() {
    yield all([
        fork(clientsAndContracts),
        fork(projectsAndRoles),
        fork(resourceManagement),
        fork(timeRegistration),
        fork(usersAndResources),
        fork(absencePeriod),
        fork(timeRegistrationExport)
    ]);
}

import React, { Component } from 'react'; 
import PropTypes from 'prop-types';
import * as InternalPropTypes from '../../constants/PropTypes';
import BasePickerSimple from '../../components/uifabricextensions/BasePickerSimple';
import { relRefersToEntity, getAttr, getRelId, getEntityId, isEntity, getEntityType } from '../../selectors/apihelper';

let defaultNameFunction = client => isEntity(client) ? getAttr(client, 'name') : 'All clients';
let defaultIdFunction = entity => isEntity(entity) ? getEntityType(entity) + '.' + getEntityId(entity) : entity.type + '-' + entity.id;

export default class SelectAllPicker extends Component {
    constructor(props) {
        super(props);
        this._onEntitySelected = this._onEntitySelected.bind(this);
        this.__blankEntity = {id: '-2132', type: 'SelectAllPicker', attributes: {name: '' }};
    }

    static propTypes = {
        isSelectAllAnOption:        PropTypes.bool,
        isAllEntitiesSelected:      PropTypes.bool,
        selectAllText:              PropTypes.string,
        placeholder:                PropTypes.string,
        selectedEntity:             PropTypes.object,
        onEntitySelected:           PropTypes.func,
        onAllEntitiesSelected:      PropTypes.func,
        entities:                   InternalPropTypes.jsonApiEntities,
        label:                      PropTypes.string,
        nameFunction:               PropTypes.func,
        idFunction:                 PropTypes.func
    };

    static defaultProps = {
        selectedEntity:         null,
        isAllEntitiesSelected:  false,
        isSelectAllAnOption:    false,
        selectAllText:          'Select all',
        placeholder:            '',
        nameFunction:           defaultNameFunction,
        idFunction:             defaultIdFunction
    };

    _onEntitySelected(entity) {
        let {onAllEntitiesSelected, onEntitySelected} = this.props;
        if(entity == this.__blankEntity){
            onAllEntitiesSelected();
        }
        else if(onEntitySelected){
            onEntitySelected(entity);
        }
    }

    _blankEntity(text){
        this.__blankEntity.attributes.name = text;
        return this.__blankEntity;
    }

    render() {
        let { 
            selectedEntity, 
            isSelectAllAnOption, 
            isAllEntitiesSelected, 
            entities, 
            label, 
            selectAllText, 
            placeholder, 
            idFunction,
            nameFunction
        } = this.props;
        let hasSelection = !!selectedEntity || isAllEntitiesSelected;
        let se = selectedEntity || isAllEntitiesSelected && this.__blankEntity || undefined;

        let seletableEntities = entities.slice();
        if(isSelectAllAnOption){
            seletableEntities.unshift(this._blankEntity(selectAllText))
        }

        return (
            <BasePickerSimple
                label={ label }
                placeholder={ placeholder }
                selectedEntity={se}
                entities={seletableEntities}
                onEntitySelected={this._onEntitySelected}
                nameFunction={nameFunction}
                idFunction={idFunction}
            />
        );
    }
     
}

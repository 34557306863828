import React, { Component } from 'react';
import { Dispatch, bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {goHome} from '../../actions/nav';
import {login} from '../../actions/auth';
import { withRouter, RouteComponentProps } from "react-router-dom";
import * as authSelectors from '../../selectors/app/auth';

interface LoginStateProps {
    isLoggedIn: boolean,
    isCheckingAuth: boolean
}
interface LoginOwnProps extends RouteComponentProps {
    selectAccount: boolean
}
interface LoginDispatchProps {
    actions: {
        goHome: (skipHistory: boolean) => void,
        login: (selectAccount: boolean) => void
    }
}
type LoginProps = LoginStateProps & LoginOwnProps & LoginDispatchProps;

class Login extends Component<LoginProps> {
    static defaultProps = {
        selectAccount: false
    };
    
    componentDidMount = () => this._doLogin()

    componentDidUpdate = () => this._doLogin()

    _doLogin() {
        const { actions, isCheckingAuth, isLoggedIn, selectAccount } = this.props;

        if (!isCheckingAuth) {
            if(!isLoggedIn || selectAccount){
                actions.login(selectAccount);
            }
            else {
                actions.goHome(true);
            }
        }
    }

    render = () => <></>
}

function mapStateToProps(state: any): LoginStateProps {
    return {
        isCheckingAuth: authSelectors.isCheckingAuth(state),
        isLoggedIn: authSelectors.isLoggedIn(state)
    };
}

function mapDispatch(dispatch: Dispatch): LoginDispatchProps {
    const actions = { goHome, login };
    return { actions: bindActionCreators(actions, dispatch) };
}

const connector = connect<LoginStateProps, LoginDispatchProps, LoginOwnProps>(mapStateToProps, mapDispatch);
export default withRouter(connector(Login));

import BaseConnector from './Base';

export default class ProjectResourceConnector extends BaseConnector {

    getAll(
        {projectId, resourceId, isDefault}: {projectId?: string, resourceId?: string, isDefault?: boolean} = {}
    ) {
        const filter = [];
        if(projectId){
            filter.push('project.id eq ' + projectId);
        }
        if(resourceId){
            filter.push('resource.id eq ' + resourceId);
        }
        if(isDefault){
            filter.push('isDefaultProjectResource eq ' + isDefault);
        }
        const includes = 'includes=project';
        return this.authFetch(this.baseApiUrl + 'ProjectResources/' + '?' + includes +
            (filter.length > 0 ? '&filter=' + filter.join(' and ') : ''));
    }

    getProjectResource(id: string){
        return this.authFetch(this.baseApiUrl + 'ProjectResources/' + id);
    }

    getByProjectAndResourceIds(projectIds: string[] | null, resourceIds: string[] | null, includeDefault: boolean, includes: string[]) {
        const filter = [];
        if (Array.isArray(projectIds) && projectIds.length > 0) {
            filter.push('(' + projectIds.map(byProjectId => 'project.id eq ' + byProjectId).join(' or ') + ')');
        }
        if (resourceIds || includeDefault) {
            const resourceFilter = [];
            if (resourceIds) {
                resourceFilter.push(resourceIds.map(andResourceId => 'resource.id eq ' + andResourceId).join(' or '));
            }
            if (includeDefault) {
                resourceFilter.push('isDefaultProjectResource eq true');
            }
            if(resourceFilter.length > 0){
                filter.push('(' + resourceFilter.join(' or ') + ')')
            }
        }
        const fs = filter.length ? 'filter=' + filter.join(' and ') : '';
        const is = includes && includes.length > 0 ? 'includes=' + includes.join(',') : '';
        const qs = [fs, is].filter(s => s.length > 0).join('&');
        return this.authFetch(this.baseApiUrl + 'ProjectResources/?' + qs);
    }

    createProjectResource(projectResourceDto: object) {
        return this.authPost(this.baseApiUrl + 'ProjectResources', projectResourceDto)
    }

    updateProjectResource(projectResourceId: string, projectResourceDto: object){
        return this.authPatch(this.baseApiUrl + 'ProjectResources/' + projectResourceId, projectResourceDto);
    }

    deleteProjectResource(id: string){
        return this.authDelete(this.baseApiUrl + 'ProjectResources/' + id);
    }
}

import * as actionhelper from '../actionhelper';
import * as EntityTypes from '../../constants/EntityTypes';

export const getAllAccountList = actionhelper.apiResponseData;

export const getUpsertAccount = actionhelper.apiResponseData;

export const getDeleteAccountId = actionhelper.apiSentModelId;

export const getIncludedAccount = (action: any) => actionhelper.apiResponseIncludedOfType(action, EntityTypes.ACCOUNT);

export {};

declare global {
    interface Array<T> {
        intersection(other: Array<T>): Array<T>;
    }
}

Array.prototype.intersection = function(...args) {
    const asSets = args.map(array => new Set(array));
    const intersection = asSets.reduce((accumSet, set) => new Set(Array.from(accumSet).filter(x => set.has(x))), new Set(this))
    return Array.from(intersection);
}

declare global {
    interface Array<T> {
        difference(other: Array<T>): Array<T>;
    }
}

Array.prototype.difference = function(otherArray) {
    return this.filter(x => !otherArray.includes(x));
}

declare global {
    interface Array<T> {
        union(other: Array<T>): Array<T>;
    }
}

Array.prototype.union = function(otherArray) {
    return Array.from(new Set([...this, ...otherArray])) 
}

declare global {
    interface Array<T> {
        equals(other: Array<T>): boolean;
    }
}

Array.prototype.equals = function(otherArray) {
    return this.length == otherArray.length && this.difference(otherArray).length == 0;
}

import React, { Component } from "react";
import PropTypes from "prop-types";
import { IconButton } from "@fluentui/react/lib/Button";
import { Callout, DirectionalHint } from "@fluentui/react/lib/Callout";
import * as Icons from "../constants/Icons";
import { ChoiceGroup } from "@fluentui/react/lib/ChoiceGroup";
import UrlContext from "../contexts/UrlContext";

const locales = ["dk", "us"];
export default class LocaleSelector extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isCalloutVisible: false,
    };
    this._mainDivElement = null;

    this._onToggleCallout = this._onToggleCallout.bind(this);
    this._setMainDivElement = this._setMainDivElement.bind(this);
    this._onDismiss = this._onDismiss.bind(this);
    this._onSelectLocale = this._onSelectLocale.bind(this);
    this._onRemoveLocale = this._onRemoveLocale.bind(this);
  }

  static propTypes = {
    selectedLocale: PropTypes.oneOf(locales),
    onSelectLocale: PropTypes.func,
    isLocaleSelectable: PropTypes.bool,
    labelTextFunc: PropTypes.func,
    onRemoveLocale: PropTypes.func,
  };

  static defaultProps = {
    isLocaleSelectable: true,
  };

  _setMainDivElement(elem) {
    this._mainDivElement = elem;
  }

  _onDismiss() {
    this.setState({
      isCalloutVisible: false,
    });
  }

  _onToggleCallout() {
    this.setState({
      isCalloutVisible: !this.state.isCalloutVisible,
    });
  }

  _onSelectLocale(event, item) {
    this.props.onSelectLocale &&
      this.props.isLocaleSelectable &&
      this.props.onSelectLocale(item.key);
  }

  _onRemoveLocale(event) {
    this.props.onRemoveLocale &&
      this.props.isLocaleSelectable &&
      this.props.onRemoveLocale();
  }

  shouldComponentUpdate(nextProps, nextState) {
    let isSame =
      this.props.selectedLocale == nextProps.selectedLocale &&
      this.props.isLocaleSelectable == nextProps.isLocaleSelectable &&
      this.props.labelTextFunc == nextProps.labelTextFunc &&
      this.state.isCalloutVisible == nextState.isCalloutVisible;
    return !isSame;
  }

  render() {
    let {
      selectedLocale,
      onSelectLocale,
      isLocaleSelectable,
      labelTextFunc,
      onRemoveLocale,
    } = this.props;
    let { isCalloutVisible } = this.state;

    let imageUrl = "";
    let iconProps = { iconName: Icons.ICON_NAME_LANGUAGE };
    return (
      <UrlContext.Consumer>
        {({ imageFilesUrl }) => {
          const localeImageUrl = (locale, variant) =>
            imageFilesUrl +
            "locale/" +
            locale +
            (variant ? "-" + variant : "") +
            ".png";

          return (
            <div
              className="novatime-locale-selector"
              ref={this._setMainDivElement}
            >
              {selectedLocale ? (
                <button
                  onClick={this._onToggleCallout}
                  className="novatime-locale-selector-show-Callout-button"
                >
                  {selectedLocale}
                </button>
              ) : (
                <IconButton
                  className="novatime-locale-selector-main-button"
                  iconProps={iconProps}
                  title={
                    selectedLocale
                      ? selectedLocale.toUpperCase() + " locale"
                      : "Select locale"
                  }
                  onClick={this._onToggleCallout}
                />
              )}

              <Callout
                className="novatime-locale-selector-callout"
                onDismiss={this._onDismiss}
                target={this._mainDivElement}
                gapSpace={0}
                hidden={!this.state.isCalloutVisible}
                directionalHint={DirectionalHint.bottomLeftEdge}
              >
                {onRemoveLocale ? (
                  <div
                    className="novatime-locale-selector-clear"
                    title="Remove"
                    onClick={this._onRemoveLocale}
                  >
                    Remove
                  </div>
                ) : undefined}
                <ChoiceGroup
                  label={(isLocaleSelectable ? "Choose" : "Chosen") + " locale"}
                  selectedKey={selectedLocale}
                  disabled={!isLocaleSelectable}
                  options={locales.map((l) => ({
                    key: l,
                    imageSrc: localeImageUrl(l, "gray"),
                    imageAlt: l,
                    selectedImageSrc: localeImageUrl(l),
                    imageSize: { width: 50, height: 32 },
                    text: labelTextFunc ? labelTextFunc(l) : l.toUpperCase(),
                  }))}
                  onChange={this._onSelectLocale}
                />
              </Callout>
            </div>
          );
        }}
      </UrlContext.Consumer>
    );
  }
}

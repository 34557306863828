import React, { Component } from "react";
import PropTypes from "prop-types";
import { DefaultButton, PrimaryButton } from "@fluentui/react/lib/Button";
import deepmerge from "deepmerge";
import * as apihelper from "../../selectors/apihelper";
import { Text } from "@fluentui/react/lib/Text";
import FormCommand from "../../lib/FormCommand";
import type { NewResourceObject, ResourceObject } from "../../lib/models";
import * as datehelper from "../../lib/date";

export type SingleBaseFormProps = {
  onDismiss?: () => void;
  onSave?: (commands: Array<FormCommand>) => void;
  onDelete?: () => void;
  model: ResourceObject;
  hasReferences?: boolean;
};

export type SingleBaseFormFieldSpec = {
  getVal: (model: ResourceObject) => any;
  setVal: (fields: SingleFormFields) => NewResourceObject;
  getPath?: () => string;
  isRequired:
    | boolean
    | ((fields: Record<string, SingleBaseFormField>) => boolean);
  defaultValue?: any;
};

export type SingleFormFieldValue = {
  validationMessage?: string;
  value: any;
};

export type SingleBaseFormField = SingleBaseFormFieldSpec &
  SingleFormFieldValue & {
    isChanged: boolean;
  };

export type SingleFormFields = Record<string, SingleBaseFormField>;

export type SingleFormFieldValues = Record<string, SingleFormFieldValue>;

export type SingleFieldSpecification = Record<string, SingleBaseFormFieldSpec>;

export type SingleBaseFormState = {
  fields: SingleFormFields;
};

export default class SingleBaseForm<
  P extends SingleBaseFormProps,
  S extends SingleBaseFormState
> extends Component<P, S> {
  static propTypes = {
    model: PropTypes.object,
    onSave: PropTypes.func,
    onDismiss: PropTypes.func,
    onDelete: PropTypes.func,
  };

  constructor(props: P) {
    super(props);
    this.state = {
      fields: {},
    } as S;

    this._onSave = this._onSave.bind(this);
    this._onDelete = this._onDelete.bind(this);
    this._onDismiss = this._onDismiss.bind(this);
    this._enableSaveButton = this._enableSaveButton.bind(this);
    this.isValid = this.isValid.bind(this);
    this.isChanged = this.isChanged.bind(this);
    this._updateFieldState = this._updateFieldState.bind(this);
    this._getIconProps = this._getIconProps.bind(this);
  }

  protected static _initializeFieldsFromModel(
    model: ResourceObject
  ): SingleFormFields {
    return {};
  }

  protected static _initializeFields(
    specFields: SingleFieldSpecification,
    model: ResourceObject
  ): SingleFormFields {
    const fields: SingleFormFields = {};

    Object.keys(specFields).forEach((fieldName) => {
      fields[fieldName] = Object.assign(
        {
          value: model
            ? specFields[fieldName].getVal(model)
            : specFields[fieldName].defaultValue || "",
          isChanged: false,
          validationMessage: "",
        },
        specFields[fieldName]
      );
    });

    return fields;
  }

  _onDismiss() {
    this.props.onDismiss && this.props.onDismiss();
  }

  _getModelChanges(exactFields?: SingleFormFields): ResourceObject {
    const fields = exactFields || this.state.fields;
    return Object.keys(fields)
      .filter((k) => fields[k].isChanged)
      .map((k) => fields[k])
      .map((field) => field.setVal(fields))
      .reduce(
        (acc, cur) => SingleBaseForm._mergeDeep(acc, cur),
        {}
      ) as ResourceObject;
  }

  static _mergeDeep(target: object, ...sources: object[]) {
    const dontMerge = (_destination: any, source: any) => source;
    return sources.reduce(
      (acc, cur) => deepmerge(acc, cur, { arrayMerge: dontMerge }),
      target
    );
  }

  static _getChangedRelation(id: string, type: string) {
    return { data: id === null ? null : { id, type } };
  }

  protected isValid() {
    const { fields } = this.state;
    const isFieldValid = (field: SingleBaseFormField) => {
      let isRequired = field.isRequired;
      if (typeof isRequired == "function") {
        isRequired = isRequired(fields);
      }
      return (
        !field.validationMessage &&
        (!isRequired ||
          field.value ||
          !isNaN(parseInt(field.value)) ||
          typeof field.value === "boolean")
      );
    };
    return Object.keys(this.state.fields)
      .map((k) => isFieldValid(this.state.fields[k]), this)
      .reduce((acc, cur) => acc && cur, true);
  }

  protected isChanged() {
    return Object.keys(this.state.fields)
      .map((k) => this.state.fields[k].isChanged, this)
      .reduce((acc, cur) => acc || cur, false);
  }

  _onSave() {
    const modelChanges = this._getModelChanges();
    this.props.onSave &&
      this.props.onSave([
        new FormCommand(this.props.model, false, modelChanges),
      ]);
  }

  _onDelete() {
    this.props.onDelete && this.props.onDelete();
  }

  _isFieldChanged(name: string, value: any) {
    const { model } = this.props;
    return model
      ? !this.objectsEquals(
          this.state.fields[name].getVal.call(this, model),
          value
        )
      : !!value || !isNaN(value);
  }

  _getUpdatedFieldStateObject(
    name: string,
    value: any,
    validationMessage: string | undefined
  ): SingleFormFields {
    const o: SingleFormFields = {};
    const isChanged = this._isFieldChanged(name, value);
    o[name] = Object.assign({}, this.state.fields[name], {
      value,
      validationMessage: validationMessage || "",
      isChanged,
    });

    return o;
  }

  _getUpdatedFields(
    name: string | SingleFormFieldValues,
    value: any,
    validationMessage?: string
  ): SingleFormFields {
    let fieldSpec: SingleFormFieldValues;
    // can handle multiple fields, if first argument is a field spec
    if (typeof name == "string") {
      fieldSpec = {
        [name]: { value, validationMessage } as SingleFormFieldValue,
      };
    } else {
      fieldSpec = name;
    }

    // get the updates for all fields
    let o = {};
    Object.keys(fieldSpec).forEach((fieldName: string) => {
      o = Object.assign(
        o,
        this._getUpdatedFieldStateObject(
          fieldName,
          fieldSpec[fieldName].value,
          fieldSpec[fieldName].validationMessage
        )
      );
    });

    return Object.assign({}, this.state.fields, o);
  }

  _updateFieldState(
    name: string | SingleFormFieldValues,
    value?: any,
    validationMessage?: string
  ): SingleFormFields {
    const newFields = this._getUpdatedFields(name, value, validationMessage);
    this.setState({
      fields: newFields,
    });
    return newFields;
  }

  objectsEquals(valueA: object | Date, valueB: object | Date) {
    if (
      valueA &&
      valueB &&
      valueA.constructor.name !== valueB.constructor.name
    ) {
      return false;
    } else if (valueA instanceof Date && valueB instanceof Date) {
      return valueA.getTime() === valueB.getTime();
    } else {
      return valueA === valueB;
    }
  }

  _renderButtons(extraDisableCondition = false) {
    const disableSaveButton =
      !this._enableSaveButton() || extraDisableCondition;
    return (
      <div>
        <PrimaryButton disabled={disableSaveButton} onClick={this._onSave}>
          Save
        </PrimaryButton>
        <DefaultButton onClick={this._onDismiss}>Cancel</DefaultButton>
        <div className="height3em ms-hiddenLgUp" />
      </div>
    );
  }

  _renderDeleteButtons(excludeDefault: boolean, isDisabled = false) {
    const { hasReferences } = this.props;
    const disableButton = hasReferences || isDisabled;
    return (
      <div>
        <DefaultButton
          className={disableButton ? "" : "delete"}
          disabled={disableButton}
          onClick={this._onDelete}
        >
          Delete
        </DefaultButton>
        {excludeDefault ? undefined : (
          <DefaultButton onClick={this._onDismiss}>Cancel</DefaultButton>
        )}
      </div>
    );
  }

  _enableSaveButton() {
    return this.isValid() && this.isChanged();
  }

  _getIconProps(isChanged: boolean) {
    return !!this.props.model
      ? { iconName: isChanged ? "FieldChanged" : "FieldNotChanged" }
      : undefined;
  }

  render() {
    return <></>;
  }

  _renderTimestampsAndId(model: ResourceObject) {
    const modifiedDate = datehelper.toYyyyMmDdHhMmSs(
      new Date(apihelper.getAttr(model, "modifiedDate") as string)
    );
    const addedDate = datehelper.toYyyyMmDdHhMmSs(
      new Date(apihelper.getAttr(model, "addedDate") as string)
    );
    const id = apihelper.getEntityId(model);
    return (
      <div className="novatime-text-bottom-formular">
        <Text block>
          {id ? (
            <>
              Modified: {modifiedDate}, Added: {addedDate}, Id: {id}
            </>
          ) : (
            <>Not saved yet</>
          )}
        </Text>
      </div>
    );
  }
}

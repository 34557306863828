import * as apihelper from '../../selectors/apihelper';
import * as BillabilityTypes from '../../constants/BillabilityTypes';
import * as currencySelectors from '../../selectors/reducers/currency';
import * as storehelper from '../../selectors/storehelper';
import '../../lib/unique';
import {timeRegistrationDuration} from '../../lib/StateMerge';

export const AccountForm = {
    Billable: 'Billable',
    NonBillable: 'NonBillable',
    Unaccounted: 'Unaccounted'
};

export class BillabilityAggregate {
    constructor(collapsedRows, billabilityTypes, currencies, account){
        this.collapsedRows = collapsedRows;
        this.billabilityTypes = billabilityTypes;
        this.currencies = currencies;
        this.account = account;
    }

    _filterAggregates({clientId = null, contractId = null, accountForm = null, currencyId = null, currencyCode = null, projectId = null, resourceId = null} = {}){
        if(currencyCode !== null){
            let currency = currencySelectors.findByCurrencyCode(this.currencies, currencyCode);
            if(!currency){
                throw `No currency with code ${currencyCode} found`;
            }
            if(currencyId !== null && !apihelper.entityHasId(currency, currencyId)){
                throw "Multiple currencies requested. There can only be one";
            }
            currencyId = apihelper.getEntityId(currency);
        }
        
        return this.collapsedRows.filter(row => 
            (clientId === null || clientId === row.clientId) &&
            (contractId === null || contractId === row.contractId) &&
            (accountForm === null || accountForm === row.accountForm) && 
            (currencyId === null || currencyId === row.currencyId) &&
            (projectId === null || projectId === row.projectId) &&
            (resourceId === null || resourceId === row.resourceId)
        );
    }

    getHours(filter){
        return this._filterAggregates(filter).reduce((acc, cur) => acc + cur.hours, 0);
    }

    _filterIsBillable(filter){
        return (filter.currencyId !== null || filter.currencyCode !== null) && filter.accountForm === AccountForm.Billable
    }

    getRevenue(filter){
        if(!this._filterIsBillable(filter)){
            throw "Cannot aggregate revenue of non-billable hours or unspecified currency";
        }
        return this._filterAggregates(filter).reduce((acc, cur) => acc + cur.revenue, 0);
    }

    getCount(filter){
        return this._filterAggregates(filter).reduce((acc, cur) => acc + cur.count, 0);
    }

    getClientIds(){
        return this.collapsedRows.map(row => row.clientId).unique();
    }

    getResourceIds(){
        return this.collapsedRows.map(row => row.resourceId).unique();
    }

    getContractIds(){
        return this.collapsedRows.map(row => row.contractId).unique();
    }
}

export default function aggregateBillability(
    hourSet, timeRegistrations, billabilityTypes, account, currencies) {

    // first collapse tregs to a simpler form of 
    // clientId, accountForm, currencyId, contractId, hours, treg count, revenue
    // using an intermediate lookup structure for fast addition
    let lookUpStructure = {};
    timeRegistrations.forEach(tr => {
        //
        // extract info
        //
        let duration = timeRegistrationDuration(tr);
        let trIsBillable = apihelper.getAttr(tr, 'isBillable');
        let trContractRole = hourSet.getContractRoleForTimeRegistration(tr);
        let trContract = hourSet.getContractForContractRole(trContractRole);
        let trContractRoleBillabilityType = storehelper.findById(billabilityTypes, apihelper.getRelId(trContractRole, 'billabilityType'));
        let trContractRoleBillabilityTypeName = apihelper.getAttr(trContractRoleBillabilityType, 'name');            
        let trContractRoleIsBillable = BillabilityTypes.ALL_BILLABLE.includes(trContractRoleBillabilityTypeName);

        // the contract role is billable and the timeregistration itself has not been "overruled" as being not billable
        let isTimeRegistrationBillable = trContractRoleIsBillable && trIsBillable;
        let hourPrice = apihelper.getAttr(trContractRole, 'hourPrice');
        let accountForm = isTimeRegistrationBillable ? hourPrice ? AccountForm.Billable : AccountForm.Unaccounted : AccountForm.NonBillable;

        let clientId = apihelper.getRelId(trContract, 'client');
        let contractId = apihelper.getRelId(trContractRole, 'contract');
        let currencyId = apihelper.getRelId(trContractRole, 'currency');
        let projectId = apihelper.getRelId(tr, 'project');
        let resourceId = apihelper.getRelId(tr, 'resource');

        //
        // init intermediate structure of
        // lookUpStructure[clientId][contractId][projectId][resourceId][AccountForm.NonBillable] = {hours, count}
        // lookUpStructure[clientId][contractId][projectId][resourceId][AccountForm.Unaccounted] = {hours, count}
        // lookUpStructure[clientId][contractId][projectId][resourceId][AccountForm.Billable][currencyId] = {hours, count, revenue}
        //
        lookUpStructure[clientId] ||= {};
        lookUpStructure[clientId][contractId] ||= {}
        lookUpStructure[clientId][contractId][projectId] ||= {};
        lookUpStructure[clientId][contractId][projectId][resourceId] ||= {
            [AccountForm.NonBillable]: { hours: 0, count: 0},
            [AccountForm.Unaccounted]: { hours: 0, count: 0},
            [AccountForm.Billable]: {}
        };
        if(accountForm === AccountForm.Billable){
            lookUpStructure[clientId][contractId][projectId][resourceId][AccountForm.Billable][currencyId] ||= { hours: 0, count: 0, revenue: 0};
        }

        //
        // count
        //
        let point = lookUpStructure[clientId][contractId][projectId][resourceId][accountForm];
        if(accountForm === AccountForm.Billable){
            point = point[currencyId];
            let fee = duration * hourPrice;
            point.revenue += fee;
        }
        point.hours += duration;
        point.count++;
    });

    // normalize lookup structure into rows
    let collapsedRows = [];
    Object.keys(lookUpStructure).forEach(clientId => {
        let clientLookup = lookUpStructure[clientId];
        Object.keys(clientLookup).forEach(contractId => {
            let contractLookup = clientLookup[contractId];
            Object.keys(contractLookup).forEach(projectId => {
                let projectLookup = contractLookup[projectId];
                Object.keys(projectLookup).forEach(resourceId => {
                    let resourceLookup = projectLookup[resourceId];
                    Object.keys(resourceLookup).forEach(accountForm => {
                        let accountFormLookup = resourceLookup[accountForm];
                        if(accountForm === AccountForm.Billable){
                            Object.keys(accountFormLookup).forEach(currencyId => {
                                let point = accountFormLookup[currencyId];
                                collapsedRows.push({
                                    clientId,
                                    accountForm,
                                    contractId, 
                                    projectId,
                                    resourceId,
                                    hours: point.hours,
                                    count: point.count,
                                    revenue: point.revenue,
                                    currencyId
                                });                        
                            });
                        }
                        else {
                            let point = accountFormLookup;
                            collapsedRows.push({
                                clientId,
                                accountForm,
                                contractId, 
                                projectId,
                                resourceId,
                                hours: point.hours,
                                count: point.count,
                                revenue: null,
                                currencyId: null
                            });
                        }
                    });
                });
            });
        });
    });

    return new BillabilityAggregate(collapsedRows, billabilityTypes, currencies, account);
}

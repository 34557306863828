import * as SagaEffects from "redux-saga/effects"
import * as types from '../../../constants/ActionTypes';
import { takeLatestAfterBootstrap } from "../../helpers";
import * as projectsAndRolesActions from "../../../actions/pages/projectsAndRoles"
import * as projectAndRolesSelectors from '../../../selectors/pages/projectsAndRoles';

function* reloadAfterProjectDelete(): any {
    const client = yield SagaEffects.select(projectAndRolesSelectors.selectedClient);
    yield SagaEffects.put(projectsAndRolesActions.selectProject(null));
    yield SagaEffects.put(projectsAndRolesActions.selectClient(client));
}

export default function* projectDeleted(){
    yield takeLatestAfterBootstrap(types.API_PROJECT_DELETE_SUCCESS, reloadAfterProjectDelete)
}

import {put, putResolve, select, takeLatest} from 'redux-saga/effects';
import * as types from '../../../constants/ActionTypes';
import * as timeRegExportSelectors  from "../../../selectors/pages/timeRegistrationExport";
import '../../../lib/setOperations';
import * as datehelper from '../../../lib/date';

function didProjectsIdsChange(action, currentFilter){
    return(
        action.payload.projectIds.length !== currentFilter.projectIds.length || 
            !action.payload.projectIds.every( (id,index) => id === currentFilter.projectIds[index] ) 
    )
}

function* doUpdateFilter(action) {
    const curFilter = yield select(timeRegExportSelectors.filter);
    const filterStartTime = yield select(timeRegExportSelectors.startTime);
    const filterEndTime = yield select(timeRegExportSelectors.endTime);

    let startTime = new Date(action.payload.startTime);
    let endTime = new Date(action.payload.endTime);
    let isDifferent = 
        ('projectIds' in action.payload && didProjectsIdsChange(action , curFilter)) || 
        ('startTime' in action.payload && (filterStartTime === null || !datehelper.isSameDay(filterStartTime, startTime))) ||
        ('endTime' in action.payload && (filterEndTime === null || !datehelper.isSameDay(filterEndTime, endTime))) ||
        ('resourceTypeIds' in action.payload && (!curFilter.resourceTypeIds || !curFilter.resourceTypeIds.equals(action.payload.resourceTypeIds))) ||
        ('resourceIds' in action.payload && (!curFilter.resourceIds || !curFilter.resourceIds.equals(action.payload.resourceIds)));

    if(isDifferent){
        let newFilter = Object.assign({}, curFilter, action.payload);
        if(action.payload.startTime){
            newFilter.startTime = startTime.toISOString();
            newFilter.endTime = endTime.toISOString();
        }
        yield putResolve({
            type: types.APP_PAGE_TIMEREGISTRATIONEXPORT_UPDATE_FILTER_CHANGE,
            payload: newFilter
        });
        yield put({ type: types.APP_PAGE_TIMEREGISTRATIONEXPORT_LOAD_REGISTRATIONS});
    }
}

export default function* updateFilter() {
    yield takeLatest(types.APP_PAGE_TIMEREGISTRATIONEXPORT_UPDATE_FILTER_INIT, doUpdateFilter);
}

import BaseConnector from './Base';

export default class ProjectConnector extends BaseConnector {

    getProjects(accountId = null, clientId = null, includes = null) {
        const filter = [];
        if(accountId){
            filter.push('account.id eq ' + accountId);
        }
        if(clientId){
            filter.push('contract.client.id eq ' + clientId);
        }
        const q = [];
        const filterString = filter.length > 0 ? 'filter=' + filter.join(' and ') : '';
        const includesString = includes ? 'includes=' + includes : '';
        filterString && q.push(filterString);
        includesString && q.push(includesString);
        return this.authFetch(this.baseApiUrl + 'Projects' + (q.length > 0 ? '?' + q.join('&') : ''));
    }

    getProjectsByClient(clientId?: string | null, includes?: string) {
        return this.authFetch(
            this.baseApiUrl + 'Contracts/' +
            (clientId != null ? '?filter=client.id eq ' + clientId : '') +
            (includes ? '&includes=projects' : '')
        );
    }

    createProject(projectDto: object){
        return this.authPost(this.baseApiUrl + 'Projects/', projectDto)
    }

    updateProject(projectId: string, projectDto: object){
        return this.authPatch(this.baseApiUrl + 'Projects/' + projectId, projectDto);
    }

    deleteProject(id: string){
        return this.authDelete(this.baseApiUrl + 'Projects/' + id);
    }

    getProject(id: string, includes = null){
        return this.authFetch(this.baseApiUrl + 'Projects/' + id + (includes ? '?includes=' + includes : ''));
	}

	getProjectsForPermission(byAccountId = null, includes = null) {
		const q = [];
        if(byAccountId != null){
            q.push('filter=account.id eq ' + byAccountId);
        }
        if(includes != null){
            q.push('includes=' + includes);
        }
        return this.authFetch(this.baseApiUrl + 'Projects' + (q.length > 0 ? '?' + q.join('&') : ''));
	}
}

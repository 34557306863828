import * as types from "../constants/ActionTypes";
import { getFormName } from "../containers/EntityEditor";
import FormCommand from "../lib/FormCommand";
import { NewResourceObject, ResourceObject } from "../lib/models";
import React from "react";

export function startEdit(
  model: ResourceObject | NewResourceObject,
  form: (new (...args: any[]) => React.Component) | React.FC
) {
  return {
    type: types.APP_ENTITY_EDITOR_EDIT_START,
    payload: {
      model,
      form: typeof form !== "string" ? getFormName(form) : form, // if being called with a class, record the classes name
    },
  };
}

export function endEdit(formCommands: FormCommand[]) {
  return {
    type: types.APP_ENTITY_EDITOR_EDIT_END,
    payload: {
      formCommands: formCommands.map((cmd) =>
        cmd instanceof FormCommand ? cmd.serialize() : cmd
      ),
    },
  };
}

export function clearEdit() {
  return {
    type: types.APP_ENTITY_EDITOR_EDIT_CLEAR,
  };
}

export function selectTempInternalClientId(clientId: string) {
  return {
    type: types.APP_ENTITY_EDITOR_TEMP_INTERNAL_CLIENT_ID_SELECTED,
    payload: { clientId },
  };
}

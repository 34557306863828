import { getAttr, getEntityId, entityHasId, getRelId, isEntity } from '../apihelper';
import * as PermissionType from '../../constants/PermissionTypeNames';
import * as apihelper from '../apihelper';
import * as reducerPermissionTypesSelectors from './permissionTypes';
import * as reducerAccountSelectors from './account';
import * as storehelper from '../storehelper';

export const filterByPeriod = (tregs, startTime, endTime) => tregs.filter(t => 
    (!startTime || new Date(getAttr(t, 'startTime')) >= startTime) &&
    (!endTime || new Date(getAttr(t, 'endTime')) <= endTime)
);

export const filterByResources = (tregs, resources) => {
    resources = (Array.isArray(resources) ? resources : [resources]);
    let resourceIds = resources.filter(r => isEntity(r)).map(getEntityId);
    return tregs.filter(t => resourceIds.includes(getRelId(t, 'resource')))
}

export const filterByProjects = (tregs, projects) =>
  storehelper.filterByReferenceToRelatedEntities(tregs, projects, 'project');

export const filterByResourcesAndPeriod = (tregs, resources, startTime, endTime) =>
    filterByPeriod(filterByResources(tregs, resources), startTime, endTime);

export const allTimeRegistrations = state => state.api.timeRegistration.allTimeRegistrations;

export const timeRegistrationById = (state, id) => allTimeRegistrations(state).find(tr => entityHasId(tr, id));

export const currentUserAccessLevelsForTimeReg = (project, client, account, currentUserPermissions, permissionTypes) => {
    let allAccessLevels = [];
    currentUserPermissions.forEach(permission => {
        let permissionTypesId = apihelper.getRelId(permission, 'permissionType')
        let permissionType    = reducerPermissionTypesSelectors.findPermissionTypeById(permissionTypes, permissionTypesId);
        const permissionTypeName = apihelper.getAttr(permissionType, 'name');
        switch (permissionTypeName) {
            case PermissionType.GLOBAL_ADMIN:
            case PermissionType.GLOBAL_ACCOUNTANT:
                allAccessLevels.push(permissionTypeName);
                break;
            case PermissionType.ACCOUNT_WRITE:
            case PermissionType.ACCOUNT_READ:
                if (account && apihelper.getEntityId(reducerAccountSelectors.currentUserAccount) === apihelper.getEntityId(account)) {
                    allAccessLevels.push(permissionTypeName);
                }
                break;
            case PermissionType.CLIENT_WRITE:
            case PermissionType.CLIENT_READ:
                if (client && apihelper.getRelId(permission, 'client') === apihelper.getEntityId(client)) {
                    allAccessLevels.push(permissionTypeName);
                }
                break;
            case PermissionType.PROJECT_WRITE:
            case PermissionType.PROJECT_READ:
                if (project && apihelper.getRelId(permission, 'project') === apihelper.getEntityId(project)) {
                    allAccessLevels.push(permissionTypeName);
                }
                break;
            default:
                throw new Error(`Unknown PermissionType ${permissionTypeName}`)
        }
    });
    return allAccessLevels;
}
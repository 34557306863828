import React, { Component } from 'react';
import PropTypes from 'prop-types';
import HourTotalReport from './hourTotalReport'
import BillabilityReport from './billabilityReport'
import InternalHoursReport from './internalHoursReport';
import * as InternalPropTypes from '../../../constants/PropTypes';
import { Stack } from '@fluentui/react';
import aggregateBillability from '../../../lib/reportAggregators/billability';
import aggregateUtilization from '../../../lib/reportAggregators/utilization';
import UtilizationReport from './utilizationReport';
import RevenueReport from './revenueReport';
import CurrentUserContext from '../../../contexts/CurrentUserContext';

function ReportHeadline(props){
    return (
        <h4 className='novatime-report-headline'>{props.children}</h4>
    );
}

function SingleReportContainer(props){
    return (
        <div className='novatime-report-container'>{props.children}</div>
    );
}

const stackStyles = {
    root: {
      height: '100%',
      
    },
};

const itemStyles = {
    fontSize: '18px'
};

export default class MonthReport extends Component {
    static propTypes = {
        stateMerge: InternalPropTypes.stateMerge,
        hourSet: InternalPropTypes.hourSet,
        startTime: PropTypes.instanceOf(Date),
        endTime: PropTypes.instanceOf(Date),
        currencies: InternalPropTypes.currencyEntities,
        locales: InternalPropTypes.localeEntities,
        account: InternalPropTypes.accountEntity,
        billabilityTypes: InternalPropTypes.billabilityEntities,
        clients: InternalPropTypes.clientEntities,
        limitToResources: InternalPropTypes.resourceEntities,
        absenceRegistrationProjects: InternalPropTypes.absenceRegistrationProjectEntities
    };

    render(){
        let { hourSet, stateMerge, startTime, endTime, billabilityTypes, account, currencies, absenceRegistrationProjects, clients, locales, limitToResources } = this.props;
        let searchFilter = {startTime, endTime};
        if(Array.isArray(limitToResources)){
            searchFilter.resources = limitToResources;
        }
        let tregs =  stateMerge.getRegistrations(searchFilter);
        let billabilityAggr = aggregateBillability(hourSet, tregs, billabilityTypes, account, currencies);
        let utilAggr = aggregateUtilization(billabilityAggr, startTime, endTime, tregs, hourSet.resources, locales);

        return (
            tregs.length > 0 ?
            <Stack>
                <ReportHeadline>Hours</ReportHeadline>
                <SingleReportContainer>
                    <HourTotalReport reportData={billabilityAggr} />
                </SingleReportContainer>
                <ReportHeadline>Internal hours</ReportHeadline>
                <SingleReportContainer>
                    <InternalHoursReport 
                        clients={clients} 
                        contracts={hourSet.contracts} 
                        reportData={billabilityAggr} 
                        account={account}
                        absenceRegistrationProjects={absenceRegistrationProjects}
                    />
                </SingleReportContainer>
                <CurrentUserContext.Consumer>
                {({ currentUserPermissionSet }) =>
                    currentUserPermissionSet.hasSelectedAccountRead() ?
                    <>
                        <ReportHeadline>Billability</ReportHeadline>
                        <SingleReportContainer>
                            <BillabilityReport clients={clients} reportData={billabilityAggr} />
                        </SingleReportContainer>
                        <ReportHeadline>Utilization</ReportHeadline>
                        <SingleReportContainer>
                            <UtilizationReport reportData={utilAggr} resources={hourSet.resources}/>
                        </SingleReportContainer>
                        <ReportHeadline>Revenue</ReportHeadline>
                        <SingleReportContainer billabilityAggregate={billabilityAggr}>
                            <RevenueReport billabilityAggregate={billabilityAggr} utilizationAggregate={utilAggr}/>
                        </SingleReportContainer>
                    </> :
                    null
                }
                </CurrentUserContext.Consumer>
            </Stack> :
            <Stack verticalAlign="center" styles={stackStyles}>
                <Stack.Item align="center" style={itemStyles}>
                    <span>No timeregistrations for current filter</span>
                </Stack.Item>
            </Stack>
            
        );
    }
}

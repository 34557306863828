import {put, select, takeLatest} from 'redux-saga/effects';
import * as types from '../../constants/ActionTypes';
import {accountByClient} from '../../selectors/reducers/account';
import {getAllProjects} from '../../actions/api/project';
import {clientById} from '../../selectors/reducers/client';

function* doTempInternalClientId(action) {
    let clientId = action.payload.clientId;
    let client = yield select(clientById, clientId);
    if(client){
        let account = yield select(accountByClient, client);
        if(account){
            yield put(getAllProjects({client, account}, "contract"));
        }
    }
}

export default function* tempInternalClientId() {
    yield takeLatest(types.APP_ENTITY_EDITOR_TEMP_INTERNAL_CLIENT_ID_SELECTED, doTempInternalClientId);
}
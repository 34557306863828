import React from "react";

export default class Grid extends React.Component {
  render() {
    return (
      <div className={"ms-Grid"} dir="ltr">
        {this.props.children}
      </div>
    );
  }
}

import * as types from '../constants/ActionTypes';

export function goHome(skipHistory) {
    return {
        type: types.NAVIGATION_TO_HOME,
        skipHistory
    };
}

export function goToUrl(url, newWindow = false, bypassRouter = false) {
    return {
        type: types.NAVIGATION_TO_URL,
        payload: {
            url,
            newWindow,
            bypassRouter    
        }
    };
}

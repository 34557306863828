import * as SagaHelpers from '../helpers';
import * as SagaEffects from 'redux-saga/effects';
import * as types from '../../constants/ActionTypes';
import AbsencePeriodTypeConnector from '../../connectors/AbsencePeriodType';

const getAllAbsencePeriodType = SagaHelpers.createApiHandler(
    types.API_ABSENCEPERIODTYPE_GET_ALL_INIT, 
    SagaHelpers.generateGetAllApiCall(
        AbsencePeriodTypeConnector, 
        function*(connector: AbsencePeriodTypeConnector): any {
            return yield SagaEffects.call([connector, connector.getAbsencePeriodType]);
        }
    )
)

export default function* absencePeriodTypeSaga() {
    yield SagaEffects.all([
        SagaEffects.fork(getAllAbsencePeriodType)
    ]);
}

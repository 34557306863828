import React, { Component } from 'react';
import PropTypes from 'prop-types';
import KpiStack from '../../KpiStack';
import {BillabilityAggregate, AccountForm} from '../../../lib/reportAggregators/billability';
import * as Currency from '../../../constants/Currency';
import SorterList from "../../../components/SorterList";
import { ActionButton } from '@fluentui/react/lib/Button';
import * as storehelper from '../../../selectors/storehelper';
import { SortingType } from '../../../selectors/storehelper';
import * as apihelper from '../../../selectors/apihelper';
import * as InternalPropTypes from '../../../constants/PropTypes';

const companyListDefaultLimit = 3;

const baseColumns = [
    {
            key:  'clientName',
            name: 'Client',
            fieldName: 'clientName',
            minWidth: 100,
            maxWidth: 150,
            isResizable: true
    },
    {
            key:  'billableHours',
            name: 'Billable',
            fieldName: 'billableHours',
            minWidth: 60,
            maxWidth: 60
    },
    {
            key:  'nonBillableHours',
            name: 'Non-billable',
            fieldName: 'nonBillableHours',
            minWidth: 80,
            maxWidth: 80
    },
    {
            key:  'meanRate',
            name: 'Mean rate',
            fieldName: 'meanRate',
            minWidth: 70,
            maxWidth: 70
    },
    {
        key:  'share',
        name: 'Share',
        fieldName: 'share',
        minWidth: 70,
        maxWidth: 70
    }
];

const baseColumnSorters = {
    "clientName": {
        "sortType": SortingType.LEXICAL,
        "extract": a => a.clientName,
    },
    "billableHours": {
        "sortType": SortingType.NUMERICAL,
        "extract": a => a.billableHours,
    },
    "nonBillableHours": {
        "sortType": SortingType.NUMERICAL,
        "extract": a => a.nonBillableHours,
    },
    "meanRate": {
        "sortType": SortingType.NUMERICAL,
        "extract": a => a.meanRate,
    },
    "share": {
        "sortType": SortingType.NUMERICAL,
        "extract": a => a.share,
    }
};

export default class BillabilityReport extends Component {
    static propTypes = {
        reportData: PropTypes.instanceOf(BillabilityAggregate),
        clients: InternalPropTypes.clientEntities
    };

    constructor(props){
        super(props);
        this.state = {
            showAllCompanies: false
        };
        this.toggleShowAllCompanies = this.toggleShowAllCompanies.bind(this);
    }

    toggleShowAllCompanies(){
        this.setState({showAllCompanies: !this.state.showAllCompanies})
    }

    render(){
        let { reportData, clients } = this.props;
        let { showAllCompanies } = this.state;

        let meanRates = Object.values(Currency).reduce((acc, currencyCode) => {
            let revenue = reportData.getRevenue({currencyCode, accountForm: AccountForm.Billable});
            let hours = reportData.getHours({currencyCode, accountForm: AccountForm.Billable});
            let meanRate = hours > 0 ? revenue / hours : 0;
            acc[currencyCode] = meanRate;
            return acc;
        }, {});
        
        let kpis = Object.keys(meanRates).filter(currencyCode => meanRates[currencyCode] > 0).map(currencyCode => ({
            title: `Mean rate for billable`, 
            value: `${meanRates[currencyCode].toFixed(0)} ${currencyCode}/h`,
            description: `All revenue divided by all hours rated with this currency`
        }));

        let totalBillableHours = reportData.getHours({accountForm: AccountForm.Billable});
        let clientBillability = reportData.getClientIds().map(clientId => {
            let client = storehelper.findById(clients, clientId);
            let billableHours = reportData.getHours({clientId, accountForm: AccountForm.Billable});
            let nonBillableHours = reportData.getHours({clientId, accountForm: AccountForm.NonBillable});
            let dkkRevenue = reportData.getRevenue({clientId, accountForm: AccountForm.Billable, currencyCode: Currency.CURRENCY_CODE_DKK});
            
            return {
                clientName: client ? apihelper.getAttr(client, 'name') : '',
                billableHours,
                nonBillableHours,
                meanRate: billableHours > 0 ? (dkkRevenue / billableHours).toFixed(0) : 0,
                dkkRevenue,
                share: totalBillableHours > 0 ? billableHours / totalBillableHours : 0,
                currencyCode: Currency.CURRENCY_CODE_DKK
            }
        })
        
        clientBillability.sort((a, b) => a.billableHours < b.billableHours);
        let shouldHideSomeClients = !showAllCompanies && clientBillability.length > companyListDefaultLimit;
        let countOfClients = clientBillability.length;
        if(shouldHideSomeClients){
            clientBillability = clientBillability.slice(0, companyListDefaultLimit)
        }

        return (
            <>
                <KpiStack kpis={kpis} />
                <SorterList
                    columns={baseColumns}
                    columnSorters={baseColumnSorters}
                    items={clientBillability}
                    onRenderItemColumn={(item, index, column) => {
                        const fieldContent = item[column.fieldName];
                        switch (column.key) {
                            case 'billableHours':
                            case 'nonBillableHours':
                                return <span>{fieldContent.toFixed(2)}</span>
                            case 'meanRate':
                                return <span title={`${item.dkkRevenue.toLocaleString()} ${item.currencyCode} divided by ${item.billableHours} hours`}>{fieldContent} {item.currencyCode}/h</span>
                            case 'share':
                                return <span>{Math.floor(fieldContent * 100)}%</span>
                            default:
                                return <span title={fieldContent}>{fieldContent}</span>;
                    }}}
                    selectable={false}
                />
                {
                    shouldHideSomeClients ?
                    <div style={{marginLeft: '1em'}}>
                        <ActionButton onClick={this.toggleShowAllCompanies}>Show all {countOfClients} clients</ActionButton>
                    </div>
                    :
                    null
                }
            </>
        );
    }
}

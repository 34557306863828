import BaseConnector from './Base';

export default class AbsenceRegistrationProjectConnector extends BaseConnector {
    getAllAbsenceRegistrationProjects(){
            return this.authFetch(this.baseApiUrl + 'AbsenceRegistrationProjects');
    }

    createAbsenceRegistrationProject(dto: object) {
        return this.authPost(this.baseApiUrl + 'AbsenceRegistrationProjects/', dto);
    }

    updateAbsenceRegistrationProject(id: string, dto: object) {
        const url = this.baseApiUrl + 'AbsenceRegistrationProjects/' + id + '?includes=absencePeriodType,account,project';
        return this.authPatch(url, dto);
    }

    deleteAbsenceRegistrationProject(id: string){
        return this.authDelete(this.baseApiUrl + 'AbsenceRegistrationProjects/' + id);
	}
}

import * as SagaEffects from 'redux-saga/effects';
import * as types from '../../../constants/ActionTypes';
import {deleteTimeRegistration} from '../../../actions/api/timeRegistration';
import {
    beginEditTimeRegistration,
    clearSelectedTimeRegistration,
    deleteWritebackComplete
} from '../../../actions/pages/timeRegistration';
import * as timeRegistrationSelectors from '../../../selectors/pages/timeRegistration';
import { ResourceObjects } from '../../../lib/models';

function* doDeleteTimeRegistration(action: any) {
    const { registration } = action.payload;

    yield SagaEffects.put(deleteTimeRegistration(registration));
    const { success, error } = yield SagaEffects.race({
        success: SagaEffects.take((a: any) => a.type === types.API_TIMEREGISTRATION_DELETE_SUCCESS && a.payload.initAction.payload.model.id === registration.id),
        error: SagaEffects.take((a: any) => a.type === types.API_TIMEREGISTRATION_DELETE_ERROR && a.payload.initAction.payload.model.id === registration.id)
    });

    const wasSuccessful = !!success;
    const payload = wasSuccessful ? success.payload : error.payload;
    
    const allSelectableRegistrations = (yield SagaEffects.select(timeRegistrationSelectors.currentCellRegistrations)) as ResourceObjects;
    const tmp = allSelectableRegistrations.filter(r => r !== registration);
    if(success){
        yield SagaEffects.put(deleteWritebackComplete(success, payload));
        if (tmp.length > 0) {
            yield SagaEffects.put(beginEditTimeRegistration(tmp[0]));
        } else {
            yield SagaEffects.put(clearSelectedTimeRegistration());
        }
    }
}

export default function* timeRegistrationWritebackDelay() {
    yield SagaEffects.takeLatest(types.APP_PAGE_TIMEREGISTRATION_DELETE_REGISTRATION, doDeleteTimeRegistration);
}

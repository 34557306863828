import * as types from '../../constants/ActionTypes';

export function createUser(user) {
    return {
        type: types.API_USER_CREATE_INIT,
        payload: {model: user}
    };
}

export function getAllUsers(account) {
    return {
        type: types.API_USER_GET_ALL_INIT,
        payload: {
            account: account
        }
    };
}

export function updateUser(user, modelChanges) {
    return {
        type: types.API_USER_UPDATE_INIT,
        payload: {
            model: user,
            changes: modelChanges
        }
    };
}

export function getMe(){
    return {
        type: types.API_USER_GET_ME_INIT
    }
}
import * as SagaHelpers from "../helpers";
import * as types from "../../constants/ActionTypes";
import WorkLocationRegistrationConnector from "../../connectors/WorkLocationRegistration";
import { GetAllWorkLocationRegistrationsAction } from "../../actions/api/workLocationRegistration";
import * as SagaEffects from "redux-saga/effects";
import * as apihelper from "../../selectors/apihelper";

const getAllWorkLocationRegistrations = SagaHelpers.createApiHandler(
  types.API_WORKLOCATIONREGISTRATION_GET_ALL_INIT,
  SagaHelpers.generateGetAllApiCall(
    WorkLocationRegistrationConnector,
    function* (
      connector: WorkLocationRegistrationConnector,
      action: GetAllWorkLocationRegistrationsAction
    ): any {
      const { resources, startTime, endTime } = action.payload.parameters;

      return yield SagaEffects.call([connector, connector.getAll], {
        startTime: new Date(startTime),
        endTime: new Date(endTime),
        ...(resources && {
          resourceIds: resources.map(apihelper.getEntityId) as string[],
        }),
      });
    }
  )
);

const deleteWorkLocationRegistration = SagaHelpers.createApiHandler(
  types.API_WORKLOCATIONREGISTRATION_DELETE_INIT,
  SagaHelpers.generateDeleteApiCall(
    WorkLocationRegistrationConnector,
    function* (connector: WorkLocationRegistrationConnector, action: any): any {
      return yield SagaEffects.call(
        [connector, connector.delete],
        action.payload.model.id
      );
    }
  )
);

const createWorkLocationRegistration = SagaHelpers.createApiHandler(
  types.API_WORKLOCATIONREGISTRATION_CREATE_INIT,
  SagaHelpers.generateCreateApiCall(
    WorkLocationRegistrationConnector,
    function* (connector: WorkLocationRegistrationConnector, action: any): any {
      return yield SagaEffects.call([connector, connector.create], {
        data: action.payload.model,
      });
    }
  )
);

export default function* workLocationRegistrationSaga() {
  yield SagaEffects.all([
    SagaEffects.fork(getAllWorkLocationRegistrations),
    SagaEffects.fork(deleteWorkLocationRegistration),
    SagaEffects.fork(createWorkLocationRegistration),
  ]);
}

import * as types from '../../constants/ActionTypes';

export function pageOpened() {
    return {
        type: types.APP_PAGE_CLIENTS_AND_CONTRACTS_OPENED
    };
}

export function loadClients(){
    return {
        type: types.APP_PAGE_CLIENTS_AND_CONTRACTS_LOAD_CLIENTS
    }
}

export function selectClient(client) {
    return {
        type: types.APP_PAGE_CLIENTS_AND_CONTRACTS_CLIENT_SELECTED,
        payload: client
    };
}

export function selectContract(contract) {
    return {
        type: types.APP_PAGE_CLIENTS_AND_CONTRACTS_CONTRACT_SELECTED,
        payload: contract
    };
}

export function selectContractRole(contractRole) {
    return {
        type: types.APP_PAGE_CLIENTS_AND_CONTRACTS_CONTRACTROLE_SELECTED,
        payload: contractRole
    };
}

import * as types from '../../constants/ActionTypes';
import {removeItemFromList, updateDtoList} from '../helpers';
import {
  getAllContractRoleList,
  getDeleteContractRoleId,
  getIncludedContractRoles,
  getUpsertContractRole
} from '../../selectors/actions/contractRole';

const initialState = {
    storeRevision: 0,
    allContractRoles: []
};

export default function contractRoleReducer(state = initialState, action) {    
    switch (action.type) {
        case types.API_CONTRACTROLE_GET_ALL_SUCCESS: {
            return updateDtoList(state, 'allContractRoles', getAllContractRoleList(action));
        }
        case types.API_PROJECTRESOURCE_GET_ALL_SUCCESS:
        {
            return updateDtoList(state, 'allContractRoles', getIncludedContractRoles(action));
        }

        case types.API_CONTRACTROLE_CREATE_SUCCESS:
        case types.API_CONTRACTROLE_UPDATE_SUCCESS: {
            return updateDtoList(state, 'allContractRoles', [getUpsertContractRole(action)]);
        }
        case types.API_CONTRACTROLE_DELETE_SUCCESS: {
            // todo: the getDeleteContractRoleId selector seems wrong, this needs to be tested
            return removeItemFromList(state, 'allContractRoles', [getDeleteContractRoleId(action)]);
        }

        default:
            return state;
    }
}

import * as types from '../../constants/ActionTypes';
import {updateDtoList} from '../helpers';
import {getAllUserList, getIncludedUsers, getMeUser, getUpsertUser} from '../../selectors/actions/user';

const initialState = {
    me: null,
    allUsers: []
};

export default function userReducer(state = initialState, action) {    
    switch (action.type) {
        case types.API_USER_GET_ME_SUCCESS: {
            let me = getMeUser(action);
            return updateDtoList(Object.assign({}, state, { me }), 'allUsers', [me]);
        }

        case types.API_RESOURCE_CREATE_SUCCESS:
        case types.API_RESOURCE_UPDATE_SUCCESS:
        case types.API_ABSENCEPERIODCOMMENT_GET_ALL_SUCCESS: 
        case types.API_ABSENCEPERIOD_GET_ALL_SUCCESS:
        case types.API_TIMEREGISTRATION_GET_ALL_SUCCESS: {
            return updateDtoList(state, 'allUsers', getIncludedUsers(action));
        }

        case types.API_USER_GET_ALL_SUCCESS: {
            return updateDtoList(state, 'allUsers', getAllUserList(action));
        }

        case types.API_USER_CREATE_SUCCESS:
        case types.API_USER_UPDATE_SUCCESS: {
            return updateDtoList(state, 'allUsers', [getUpsertUser(action), ...getIncludedUsers(action)]);
        }

        default:
            return state;
    }
}

import * as types from '../../constants/ActionTypes';

export function pageOpened() {
    return {
        type: types.APP_PAGE_USERSANDRESOURCES_OPENED,
    };
}

export function selectUser(user) {
    return {
        type: types.APP_PAGE_USERSANDRESOURCES_USER_SELECTED,
        payload: user,
    };
}

export function selectResource(user) {
    return {
        type: types.APP_PAGE_USERSANDRESOURCES_RESOURCE_SELECTED,
        payload: user,
    };
}

export function selectPermissionUser(permissionIdentifiers) {
    return {
        type: types.APP_PAGE_USERSANDRESOURCES_PERMISSION_SELECTED,
        payload: permissionIdentifiers,
    };
}

export function toggleInactives() {
    return {
        type: types.APP_PAGE_USERSANDRESOURCES_TOGGLE_INACTIVES,
    }
}
import * as EntityTypes from "../constants/EntityTypes";
import {
  Attributes,
  NewResourceObject,
  Relationship,
  Relationships,
  ResourceIdentifier,
  ResourceObject,
} from "../lib/models";
import * as modelCreation from "../lib/emptyModelCreation";

export const entityHasId = (
  entity?: ResourceObject,
  id?: string
): boolean | undefined => {
  try {
    return !!entity ? entity.id === id : undefined;
  } catch (e) {
    isEntity(entity, true);
    throw e;
  }
};

export const entityHasType = (
  entity?: ResourceObject,
  type?: string
): boolean | undefined => {
  try {
    return !!entity ? entity.type === type : undefined;
  } catch (e) {
    isEntity(entity, true);
    throw e;
  }
};

export const getRelId = (
  entity: ResourceObject,
  relationshipName: string
): string | null => {
  const rel = getRel(entity, relationshipName);
  return rel && rel.data
    ? (rel.data as ResourceIdentifier<ResourceObject>).id
    : null;
};

export const getRel = (
  entity: ResourceObject,
  relationshipName: string
): Relationship | null | undefined => {
  try {
    return !!entity && entity.relationships
      ? entity.relationships[relationshipName]
      : undefined;
  } catch (e) {
    isEntity(entity, true);
    throw e;
  }
};

export const relIsPresent = (
  entity: ResourceObject,
  relationshipName: string
) => !!getRel(entity, relationshipName);

export const relHasReference = (
  entity: ResourceObject,
  relationshipName: string
) => getRelId(entity, relationshipName) != null;

export const relHasId = (
  entity: ResourceObject,
  relationshipName: string,
  id: string | null | undefined
) =>
  parseInt(getRelId(entity, relationshipName) as string) ===
  parseInt(id as string);

export const hasSameRel = (
  entity1: ResourceObject,
  entity2: ResourceObject,
  relationshipName: string
) => relHasId(entity1, relationshipName, getRelId(entity2, relationshipName));

export const getEntityId = (entity?: ResourceObject) => {
  try {
    return entity?.id;
  } catch (e) {
    isEntity(entity, true);
    return undefined;
  }
};

export const isEntity = (entity: any, warnIfNot = false) => {
  const result =
    !!entity &&
    !!entity.type &&
    !!(EntityTypes as Record<string, string>)[entity.type.toUpperCase()];
  if (!result && warnIfNot) {
    console.warn("Object is not an entity!", entity);
  }
  return result;
};

export const isPersistedEntity = (entity: ResourceObject | NewResourceObject) =>
  isEntity(entity) && !!getEntityId(entity as ResourceObject);

export const relRefersToEntity = (
  relEntity: ResourceObject,
  relationshipName: string,
  refEntity: ResourceObject
) => {
  const rel = getRel(relEntity, relationshipName);
  return (
    rel &&
    rel.data &&
    isEntity(refEntity) &&
    (rel.data as ResourceIdentifier<ResourceObject>).type === refEntity.type &&
    (rel.data as ResourceIdentifier<ResourceObject>).id ===
      getEntityId(refEntity)
  );
};

export const getAttr = (entity: ResourceObject, attrName: string) => {
  try {
    return (entity.attributes as Attributes)[attrName];
  } catch (e) {
    isEntity(entity, true);
    return undefined;
  }
};

export const setAttr = (
  entity: NewResourceObject,
  attrName: string,
  value: any
) => {
  // should not be possible without forcing type, so just in case
  if (isPersistedEntity(entity)) {
    throw new Error("Cannot update attribute on persisted entity");
  }
  (entity.attributes as Attributes)[attrName] = value;
};

export const setRelId = (
  entity: NewResourceObject,
  relName: string,
  id: null | string
) => {
  // should not be possible without forcing type, so just in case
  if (isPersistedEntity(entity)) {
    throw new Error("Cannot update relationship on persisted entity");
  }
  (entity.relationships as Relationships)[relName] =
    typeof id === "string"
      ? ({
          data: {
            id,
            type: modelCreation.getRelationshipType(
              getEntityType(entity),
              relName
            ),
          },
        } as Relationship<ResourceObject>)
      : null;
};

export const getEntityType = (entity: ResourceObject | NewResourceObject) => {
  try {
    return entity.type;
  } catch (e) {
    isEntity(entity, true);
    throw e;
  }
};

export const hasAttr = (entity: ResourceObject, attrName: string) => {
  try {
    return attrName in (entity.attributes as Attributes);
  } catch (e) {
    isEntity(entity, true);
    throw e;
  }
};

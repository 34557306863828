import { all, fork } from "redux-saga/effects";
import account from "./account";
import billabilitytypes from "./billabilityType";
import client from "./client";
import contract from "./contract";
import contractRole from "./contractRole";
import lockedTimePeriod from "./lockedTimePeriod";
import permission from "./permission";
import permissionTypes from "./permissionType";
import project from "./project";
import projectResource from "./projectResource";
import resource from "./resource";
import timeRegistration from "./timeRegistration";
import user from "./user";
import absencePeriod from "./absencePeriod";
import absencePeriodComment from "./absencePeriodComment";
import absencePeriodStatus from "./absencePeriodStatus";
import absencePeriodType from "./absencePeriodType";
import absenceRegistrationProject from "./absenceRegistrationProject";
import timeRegistrationStatus from "./timeRegistrationStatus";
import currencySaga from "./currency";
import languageSaga from "./language";
import projectStatus from "./projectStatus";
import localeSaga from "./locale";
import resourceType from "./resourceType";
import workLocationRegistrationSaga from "./workLocationRegistration";
import workLocationSaga from "./workLocation";

export default function* apiSaga() {
  yield all([
    fork(account),
    fork(billabilitytypes),
    fork(client),
    fork(contract),
    fork(contractRole),
    fork(lockedTimePeriod),
    fork(permission),
    fork(permissionTypes),
    fork(project),
    fork(projectResource),
    fork(resource),
    fork(timeRegistration),
    fork(user),
    fork(absencePeriod),
    fork(absencePeriodComment),
    fork(absencePeriodStatus),
    fork(absencePeriodType),
    fork(absenceRegistrationProject),
    fork(timeRegistrationStatus),
    fork(currencySaga),
    fork(resourceType),
    fork(projectStatus),
    fork(languageSaga),
    fork(localeSaga),
    fork(workLocationRegistrationSaga),
    fork(workLocationSaga),
  ]);
}

import {put, select} from 'redux-saga/effects';
import * as types from '../../../constants/ActionTypes';
import {isLoggedIn} from '../../../selectors/reducers/auth';
import {takeLatestAfterBootstrap} from '../../helpers';

function* doProjectsAndRolesOpened() {
    const loggedIn = yield select(isLoggedIn);
     
    if (loggedIn) {
        yield put({ type: types.APP_PAGE_PROJECTS_AND_ROLES_LOAD_CLIENTS} );
    }
}

export default function* projectsAndRolesOpened() {
    yield takeLatestAfterBootstrap(types.APP_PAGE_PROJECTS_AND_ROLES_OPENED, doProjectsAndRolesOpened);
}

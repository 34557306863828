
export const baseApiUrl = (state: any) => state.root.urls.baseApiUrl;

export const isAppReady = (state: any) => state.root.isReady;

export const urls = (state: any) => state.root.urls;

export const imageFilesUrl = (state: any) => state.root.urls.imageFilesUrl;

export const environment = (state: any) => state.root.environment;

import React from "react";

export type ColumnProps = {
  sm?: number;
  md?: number;
  lg?: number;
  xl?: number;
  xxl?: number;
  xxxl?: number;
};

export default function Column(props: React.PropsWithChildren<ColumnProps>) {
  const classes: string[] = [];
  Object.entries(props).forEach(([key, data]) => {
    if (typeof data === "number") {
      classes.push("ms-" + key + data);
    }
  });
  classes.push("ms-Grid-col");

  return <div className={classes.join(" ")}>{props.children}</div>;
}

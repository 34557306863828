import BaseConnector from './Base';

export default class PermissionConnector extends BaseConnector {

	getPermissionsByAccountId(accountId: string) {
		return this.authFetch(this.baseApiUrl + 'Permissions?includes=user,account,client,project&filter=user.account.id eq ' + accountId); 
	}

	getPermissionsByUserAccountId(accountId: string) {
		return this.authFetch(this.baseApiUrl + 'Users?includes=permissions&filter=account.id eq ' + accountId);
	}  

	getPermissions() {
		return this.authFetch(this.baseApiUrl + 'Permissions/');
	}

	createPermission(permissionDto: object) {
		return this.authPost(this.baseApiUrl + 'Permissions/', permissionDto);
	}

	deletePermission(permissionId: string) {
		return this.authDelete(this.baseApiUrl + 'Permissions/' + permissionId);
	}	 
}

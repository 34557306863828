import { configureStore } from "@reduxjs/toolkit";
import { createLogger } from "redux-logger";
import { routerMiddleware } from "connected-react-router";
import { history } from "../lib/history";
import createSagaMiddleware from "redux-saga";
import rootSaga from "../sagas/index";
import rootReducer from "../reducers";

export default function getStore() {
  const logger = createLogger();
  const sagaMiddleware = createSagaMiddleware();

  const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({ thunk: false }).concat(
        sagaMiddleware,
        routerMiddleware(history),
        logger
      ),
  });

  sagaMiddleware.run(rootSaga);

  return store;
}

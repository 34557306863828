/**
 * Utility classes for bringing down the number of writebacks when writing in the 
 * description field. 
 * It works like an or between delay, where the call will happen after X time,
 * and debounce where the callback is invoked Y time after last event. 
 * Whatever happens first wins.
 * Finally it a waiting callback can be triggered manually or cancelled
 */
export class SimpleTimer {
    private timerId: NodeJS.Timeout | null = null;
    constructor(private callback: () => void, private delay: number){ }

    reset() {
        this.clear();
        this.timerId = setTimeout(() => {
            this.timerId = null;
            this.callback()
        }, this.delay);
    }

    clear() {
        if(this.timerId){
            clearTimeout(this.timerId);
            this.timerId = null;
        }
    }

    set() {
        if(!this.isSet()){
            this.reset();
        }
    }

    isSet() {
        return this.timerId !== null;
    }
}

export class WriteBackTimer {
    private delayTimer: SimpleTimer;
    private debounceTimer: SimpleTimer;

    constructor(private callback: () => void, debounceTime = 1500, delayTime = 10000){ 
        this.delayTimer = new SimpleTimer(() => { this.trigger() }, delayTime);
        this.debounceTimer = new SimpleTimer(() => { this.trigger() }, debounceTime);
    }

    touch(){
        this.debounceTimer.reset();
        this.delayTimer.set();
    }

    cancel(){
        this.debounceTimer.clear();
        this.delayTimer.clear();
    }

    trigger(){
        this.cancel();
        this.callback();
    }

    // trigger now if waiting for delay
    finish() {
        if(this.debounceTimer.isSet() || this.delayTimer.isSet()){
            this.trigger();
        }
    }

    isPending(){
        return this.debounceTimer.isSet() || this.delayTimer.isSet();
    }
}

import * as ActionTypes from '../../constants/ActionTypes';

export enum NotificationLevel {
    Error = 'error',
    Info = 'info',
    Warning = 'warning'
}

export enum NotificationType {
    ApiCallPost = 'apicallpost',
    ApiCallGet = 'apicallget',
    ApiCallPatch = 'apicallpatch',
    ApiCallDelete = 'apicalldelete',
}

export type NotificationLocation = {
    entityId?: string,
    queueId?: string,
    transactionId?: string
};

export type NotificationContentMessage = string | { title: string, description: string };

export type NotificationContent = {
    errorCode?: number,
    age?: string,
    message: NotificationContentMessage
}

export type Notification = {
    level: NotificationLevel,
    location?: NotificationLocation,
    content: NotificationContent,
    requireDismiss: boolean,
    type: NotificationType,
    timestamp?: string
};

export type Notifications = Notification[];

type NotificationReducerState = {
    notifications: Notifications,
    visible: Notifications
};

const initialState: NotificationReducerState = {
    notifications: [],
    visible: []
};

const replace = (list: Notification[], notification: Notification, findFn: (notification: Notification) => boolean) => {
    const currentNotification = list.find(findFn);
    let result = list;
    if(currentNotification){
        const notifIdx = list.indexOf(currentNotification);
        const newNotification = Object.assign({}, notification, { timestamp: currentNotification.timestamp });
        result = list.slice();
        result.splice(notifIdx, 1, newNotification);
    }
    else {
        console.warn("could not find notification for replacement", notification, list)
    }
    return result;
}

function ensureArray<T>(value: T | T[]): T[] {
    if (Array.isArray(value)) {
        return value
    } 
    else {
        return [value]
    }
}

export default function notificationReducer(state = initialState, action: any): NotificationReducerState {
    switch(action.type){
        case ActionTypes.APP_NOTIFICATION_ADD: {
            const newNotification = action.payload.notification;
            return Object.assign({}, state, { 
                notifications: state.notifications.concat(newNotification),
                visible: state.visible.concat(newNotification)
            });
        }

        case ActionTypes.APP_NOTIFICATION_DISMISS_ALL: {
            const { filter } = action.payload;            
            const filterFn = (notification: Notification) => {
                const filterIsEmpty = !filter.queueId && !filter.transactionId && !filter.level && !filter.type;
                return !filterIsEmpty && // no filter == unconditional remove, just clear everything
                       (!filter.queueId || notification?.location?.queueId !== filter.queueId) && // anything with the same queueId goes
                       (!filter.transactionId || notification?.location?.transactionId !== filter.transactionId) && // anything with the same transaction id;
                       (!filter.level || !ensureArray(filter.level).includes(notification.level)) && // anything with this level
                       (!filter.type || !ensureArray(filter.type).includes(notification.type)); // anything with this type
            }
            return Object.assign({}, state, { 
                visible: state.visible.filter(filterFn)
            })
        }
 
        case ActionTypes.APP_NOTIFICATION_REPLACE: {
            const { transactionId, notification } = action.payload;
            const findFn = (notification: Notification) => transactionId && notification?.location?.transactionId === transactionId;
            return Object.assign({}, state, {
                notifications: replace(state.notifications, notification, findFn),
                visible: replace(state.visible, notification, findFn)
            });
        }

        default:
            return state;
    }
}

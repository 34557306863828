import { ResourceObjects } from "../../lib/models";
import { createSelector } from "reselect";
import { TypeRegistrationStatusNames } from '../../constants/TimeRegistrationStatus';
import * as storehelper from '../../selectors/storehelper';

export const allTimeRegistrationStatus = (state: any) => state.api.timeRegistrationStatus.allTimeRegistrationStatus as ResourceObjects;

export const timeRegistrationStatusOpen = createSelector(
    [allTimeRegistrationStatus],
    (allStatuses) => storehelper.findByAttr(allStatuses, 'name', TypeRegistrationStatusNames[TypeRegistrationStatusNames.Open])
)

import BaseConnector from './Base';
import {isEntity, getEntityId} from '../selectors/apihelper';
import { ResourceObject } from '../lib/models';

export default class AccountConnector extends BaseConnector {
    getAccounts() {
        return this.authFetch(this.baseApiUrl + 'Accounts/');
    }

    getAccount(accountDtoOrId: ResourceObject | string){
        const id = isEntity(accountDtoOrId) ? getEntityId(accountDtoOrId as ResourceObject) : accountDtoOrId;
        return this.authFetch(this.baseApiUrl + 'Accounts/' + id);
    }

    createAccount(accountDto: object){
        return this.authPost(this.baseApiUrl + 'Accounts/', accountDto)
    }

    updateAccount(accountId: string, accountDto: object){
        return this.authPatch(this.baseApiUrl + 'Accounts/' + accountId, accountDto);
    }

    deleteAccount(id: string){
        return this.authDelete(this.baseApiUrl + 'Accounts/' + id);
    }
}

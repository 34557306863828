import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import Jumbotron from "../../components/Jumbotron";
import { DefaultButton } from "@fluentui/react/lib/Button";
import { goToUrl } from "../../actions/nav";
import * as urls from "../../constants/Urls";
import { withRouter } from "react-router-dom";
import { isLoggedIn } from "../../selectors/reducers/auth";
import { imageFilesUrl } from "../../selectors/reducers/root";

class Home extends Component {
  constructor(props) {
    super(props);

    this._onLoginButtonClick = this._onLoginButtonClick.bind(this);
  }

  _onLoginButtonClick() {
    this.props.history.push(urls.LOGIN_RELATIVE);
  }

  render() {
    return (
      <div className="novatime-container">
        <div className="novatime-page-home">
          <Jumbotron className="novatime-home-jumbotron">
            <h1>Novatime</h1>
            <p>
              Welcome to the most awesome timeregistration application for all
              your time registrations needs.
              <br />
              The fun stuff begins when you sign in, please do so below.
            </p>
            <DefaultButton
              primary={true}
              data-automation-id="test"
              text="Sign in"
              onClick={this._onLoginButtonClick}
            />
          </Jumbotron>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    isLoggedIn: isLoggedIn(state),
    imageFilesUrl: imageFilesUrl(state),
  };
}

function mapDispatch(dispatch) {
  const actions = {
    goToUrl,
  };
  return { actions: bindActionCreators(actions, dispatch) };
}

export default withRouter(connect(mapStateToProps, mapDispatch)(Home));

import * as types from '../../constants/ActionTypes';
import {removeItemFromList, updateDtoList} from '../helpers';
import {
  getAllTimeRegistrationList,
  getDeleteTimeRegistrationId,
  getUpsertTimeRegistration
} from '../../selectors/actions/timeRegistration';

const initialState = {
    lastFetchedTimeRegistrations: [],
    allTimeRegistrations: [],
    storeRevision: 0,
};

export default function timeRegistrationReducer(state = initialState, action) {
    switch (action.type) {
        case types.API_TIMEREGISTRATION_GET_ALL_SUCCESS:
        {
            return updateDtoList(Object.assign({}, state, {
                lastFetchedTimeRegistrations: getAllTimeRegistrationList(action)
            }), 'allTimeRegistrations', getAllTimeRegistrationList(action));
        }
        case types.API_TIMEREGISTRATION_CREATE_SUCCESS:
        case types.API_TIMEREGISTRATION_UPDATE_SUCCESS:
        {
            return updateDtoList(state, 'allTimeRegistrations', [getUpsertTimeRegistration(action)]);
        }
        case types.API_TIMEREGISTRATION_DELETE_SUCCESS:
        {
            return removeItemFromList(state, 'allTimeRegistrations', getDeleteTimeRegistrationId(action));
        }

        default:
            return state;
    }
}

import * as apihelper from '../selectors/apihelper';

const limitToSet = (ids, entities, entityName) => {
	return entities;
	return ids.map(id => {
		let entity = entities.find(e => apihelper.entityHasId(e, id));
		if(!entity){
			console.error("HourSet could not find " + entityName + " with id " + id + " referenced by time registration")
			//throw "HourSet could not find " + entityName + " with id " + id + " referenced by time registration";
		}
		return entity;
	})
};

const genIdx = (entities, keyFn = e => apihelper.getEntityId(e)) => (entities || []).reduce((acc, cur) => { acc[keyFn(cur)] = cur; return acc; }, {});
const groupBy = (entities, keyFn) => (entities || []).reduce((acc, cur) => { 
	let key = keyFn(cur);
	acc[key] = acc[key] || [];
	acc[key].push(cur);
	return acc; 
}, {});

export default class HourSet {
    constructor(stateMerge, projects, resources, projectResources, clients, contracts, contractRoles) {
		this.stateMerge = stateMerge;
		this.projects = limitToSet(stateMerge.getReferencedProjectIds(), projects, 'project');
		this.projectIdx = genIdx(this.projects);
		this.resources = limitToSet(stateMerge.getReferencedResourceIds(), resources, 'resource');
		this.contractRoles = limitToSet(stateMerge.getReferencedContractRoleIds(), contractRoles, 'contract role');

		this.projectResources = projectResources;
		this.projectResourceIdx = groupBy(this.projectResources, pr => apihelper.getRelId(pr, 'resource'));
        if(Array.isArray(this.projectResources)){
			this.defaultProjectResources = groupBy(this.projectResources.filter(pr => apihelper.getAttr(pr, 'isDefaultProjectResource')), pr => apihelper.getRelId(pr, 'project'));
		}
		this.clients = clients;
		this.clientIdx = genIdx(this.clients);
		this.contracts = contracts;
		this.contractIdx = genIdx(this.contracts);
		this.contractRoles = contractRoles;
		this.contractTocontractRoleIdx = groupBy(this.contractRoles, cr => apihelper.getRelId(cr, 'contract'));
		this.contractRoleIdx = genIdx(this.contractRoles);
    }

	equals(otherHourSet){
		return this.stateMerge === otherHourSet.stateMerge &&
        	   this.projects === otherHourSet.projects &&
        	   this.resources === otherHourSet.resources &&
        	   this.contractRoles === otherHourSet.contractRoles &&
        	   this.projectResources === otherHourSet.projectResources &&
        	   this.defaultProjectResources === otherHourSet.defaultProjectResources &&
        	   this.clients === otherHourSet.clients &&
        	   this.contracts === otherHourSet.contracts &&
        	   this.contractRoles === otherHourSet.contractRoles;
	}

    getClientForProject(project){
    	let co = this.getContractForProject(project);
    	let clId = co && apihelper.getRelId(co, 'client');
    	return this.clientIdx[clId];
    }

    getContractForProject(project){
    	let coId = apihelper.getRelId(project, 'contract');
    	return this.contractIdx[coId];
    }

    getProjectResourcesForResourceAndProject(resource, project){
    	let projectId = apihelper.getEntityId(project);
        let resourceId = apihelper.getEntityId(resource);
        let baseList = (this.defaultProjectResources[projectId] || []);
        baseList = baseList.concat(this.projectResourceIdx[resourceId] || []);
        return baseList.filter(pr => apihelper.relRefersToEntity(pr, 'project', project));
    }

    getContractRolesForContract(contract){
    	return this.contractTocontractRoleIdx[apihelper.getEntityId(contract)];
    }

	getContractRoleForTimeRegistration(timeRegistration){
    	return this.contractRoleIdx[apihelper.getRelId(timeRegistration, 'contractRole')];
	}

    getContractRolesForProject(project){
    	let co = this.getContractForProject(project);
    	return this.getContractRolesForContract(co);
    }

	getContractForContractRole(contractRole){
    	let coId = apihelper.getRelId(contractRole, 'contract');
    	return this.contractIdx[coId];
	}

    getProjectById(projectId){
    	return this.projectIdx[projectId];
    }

    getProjectForTimeRegistration(treg){
        return this.projectIdx[apihelper.getRelId(treg, 'project')];
    }

    getTimeRegFailedValidations(treg){
    	let validations = this.stateMerge.getFailedValidations(treg);
    	let project = this.getProjectForTimeRegistration(treg);
    	if(project){
    		if(apihelper.getAttr(project, 'needsDescription') && !apihelper.getAttr(treg, 'description')){
    			validations.description = validations.description || [];
    			validations.description.push('Time registrations for this project require a non-blank description');
    		}
    	}
    	return validations;
    }
}

import { put } from "redux-saga/effects"
import * as types from '../../../constants/ActionTypes';
import { takeLatestAfterBootstrap } from "../../helpers";
import * as clientAndContractsActions from "../../../actions/pages/clientsAndContracts"

function* doFetchClientsOfCurrentAccount(){
        yield put( clientAndContractsActions.selectContract(null) );
        yield put( clientAndContractsActions.selectClient(null) );
        yield put({ type: types.APP_PAGE_CLIENTS_AND_CONTRACTS_LOAD_CLIENTS} );
}

export default function* fetchClientOfCurrentAccount(){
    yield takeLatestAfterBootstrap(types.APP_SET_SELECTED_ACCOUNT, doFetchClientsOfCurrentAccount)

}
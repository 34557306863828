import {put, select, putResolve} from 'redux-saga/effects';
import * as types from '../../../constants/ActionTypes';
import {takeLatestAfterBootstrap} from '../../helpers';
import {isLoggedIn} from '../../../selectors/reducers/auth';
import * as appDomainSelectors from '../../../selectors/app/domain';
import * as reducerResourceSelectors from '../../../selectors/reducers/resource';
import * as resourceActions from "../../../actions/api/resource";
import * as clientActions from "../../../actions/api/client";
import * as projectResourceActions from '../../../actions/api/projectResource';
import * as contractRoleActions from '../../../actions/api/contractRole';
import * as reducerTimeRegistrastionExportSelectors from '../../../selectors/pages/timeRegistrationExport';
import moment from 'moment';
import * as timeRegistrationExportActions from '../../../actions/pages/timeRegistrationExport';
import * as apihelper from '../../../selectors/apihelper';

function* doTimeRegistrationExportPageOpened() {
    const loggedIn = yield select(isLoggedIn);

    if (loggedIn) {
        // ensure initial necessary initial data is loaded
        let selectedAccount = yield select(appDomainSelectors.selectedAccount);
        yield put(resourceActions.getAllResources(selectedAccount));
        yield put(clientActions.getAllClients(selectedAccount, 'contracts'));
        yield put(projectResourceActions.getAllProjectResources());
        yield put(contractRoleActions.getAllContractRoles());

        // update filter to limit initial search, if not set already
        let st = yield select(reducerTimeRegistrastionExportSelectors.startTime);
        if (st == null) {
            let resCurUser = yield select(reducerResourceSelectors.resourceForCurrentUser)
            let today = new Date();
            let startOfYear = moment(today).subtract(2, 'months').startOf('month').toDate();
            yield putResolve(timeRegistrationExportActions.updateFilter({ resourceIds: [apihelper.getEntityId(resCurUser)],  startDate: startOfYear, endDate: today }));
        }
        
        // set a default limit on resources so search does not drink the ocean
        let selectedResources = yield select(reducerTimeRegistrastionExportSelectors.selectedResources);
        if(selectedResources.length === 0){
            let currentResource = yield select(reducerResourceSelectors.currentUserResource);
            if(currentResource){
                yield putResolve(timeRegistrationExportActions.updateFilter({resourceIds: [apihelper.getEntityId(currentResource)]}));
            }
        }
    }
}

export default function* timeRegistrationExportPageOpened() {
    yield takeLatestAfterBootstrap(types.APP_PAGE_TIMEREGISTRATIONEXPORT_OPENED, doTimeRegistrationExportPageOpened);
}

import * as types from "../../constants/ActionTypes";
import {updateDtoList} from "../helpers";
import {getAllAbsencePeriodStatus} from "../../selectors/actions/absencePeriodStatus";

const initialState = {
    storeRevision: 0,
    allAbsencePeriodStatus: []
};

export default function absencePeriodStatusReducer(
    state = initialState,
    action
) {
    switch (action.type) {
        case types.API_ABSENCEPERIODSTATUS_GET_ALL_SUCCESS: {
            return updateDtoList(
                state,
                "allAbsencePeriodStatus",
                getAllAbsencePeriodStatus(action)
            );
        }

        default:
            return state;
    }
}

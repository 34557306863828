import deepmerge from "deepmerge";
import {
  isModelValid,
  validateModel,
  ValidationResult,
} from "./modelValidators";
import * as apihelper from "../selectors/apihelper";
import type { ResourceObject, ResourceObjects } from "./models";
import { v1 as uuidv1 } from "uuid";
import structuredClone from "@ungap/structured-clone";

// field has not changed from the original model
export const UNCHANGED = "queue unchanged";
// field has changed from original model and is in a valid state
export const VALID = "queue valid";
// field has changed from original model and is in an invalid state
export const INVALID = "queue invalid";
// field has been changed, was in a valid state, and is now scheduled for write-back
export const SCHEDULED = "queue scheduled";
// field is not present in original model
export const UNKNOWN = "queue unknown";

// update this to no repeat values when switching to typescript >= 5
export enum QueueChangeStatus {
  Unchanged = "queue unchanged",
  Valid = "queue valid",
  Invalid = "queue invalid",
  Scheduled = "queue scheduled",
  Unknown = "queue unknown",
}

type ModelIncrement = {
  change: object;
  status: QueueChangeStatus;
};

export type ModelChange = {
  original: ResourceObject;
  merged: ResourceObject;
  status: QueueChangeStatus;
  claimId?: string | null;
  validation: ValidationResult;
  mergedChanges: ResourceObject;
  changes: Array<ModelIncrement>;
  newlyCreatedId?: string;
  queueId: string;
};

const cloneModelChange = (change: ModelChange): ModelChange => {
  const result = structuredClone(change) as ModelChange;
  result.original = change.original;
  result.merged = change.merged;
  return result;
};

export default class ModelMergeQueue {
  private changes: Array<ModelChange> = [];

  constructor() {
    this.reset();
  }

  serialize() {
    return this.changes;
  }

  static deserialize(serializedChanges: Array<ModelChange>) {
    const q = new ModelMergeQueue();
    q.changes = serializedChanges.map((change) => cloneModelChange(change));
    return q;
  }

  static _mergeArrayOverwrite(target: object, ...sources: object[]) {
    const overwriteMerge = (
      destinationArray: any,
      sourceArray: any,
      options: any
    ) => sourceArray;
    return sources.reduce(
      (acc, cur) => deepmerge(acc, cur, { arrayMerge: overwriteMerge }),
      target
    );
  }

  _merge(target: object, ...sources: object[]) {
    const dontMerge = (destination: any, source: any) => source;
    return sources.reduce(
      (acc, cur) => deepmerge(acc, cur, { arrayMerge: dontMerge }),
      target
    );
  }

  findInQueue(model: ResourceObject): ModelChange | undefined {
    return this.changes.find(
      (ch) =>
        ch.merged === model ||
        (apihelper.isPersistedEntity(model) &&
          apihelper.entityHasId(model, apihelper.getEntityId(ch.original)))
    );
  }

  findInQueueByOriginalModel(originalModel: ResourceObject) {
    return this.changes.find((ch) => ch.original === originalModel);
  }

  findInQueueByQueueId(queueId: string): ModelChange | undefined {
    return this.changes.find((ch) => ch.queueId === queueId);
  }

  findInQueueByClaimIdentifier(claimId: string): ModelChange | undefined {
    return this.changes.find((ch) => ch.claimId === claimId);
  }

  addChange(change: ResourceObject, mergedModel: ResourceObject) {
    const updatedModel = this._merge({}, mergedModel, change) as ResourceObject;
    const validation = validateModel(updatedModel);
    const isModelValid = validation.isValid;
    const status = isModelValid
      ? QueueChangeStatus.Valid
      : QueueChangeStatus.Invalid;

    if (
      !apihelper.entityHasId(mergedModel, apihelper.getEntityId(updatedModel))
    ) {
      throw new Error("Queue cannot change id of model");
    }

    if (
      apihelper.getEntityType(mergedModel) !==
      apihelper.getEntityType(updatedModel)
    ) {
      throw new Error("Queue cannot change type of model");
    }

    const orgCh = this.findInQueue(mergedModel);

    const ch = orgCh || ({ queueId: uuidv1() } as ModelChange);
    ch.original = ch.original || mergedModel;
    ch.claimId = ch.claimId || null;
    ch.changes = ch.changes || [];
    ch.validation = validation;

    ch.merged = updatedModel;
    if (ch.status !== QueueChangeStatus.Scheduled) {
      ch.status = status;
    }
    ch.changes.push({ change, status });
    ch.mergedChanges = Object.assign({}, ...ch.changes.map((c) => c.change));

    if (!orgCh) {
      this.changes.push(ch);
    }

    return ch;
  }

  changeIsValid(updatedModel: ResourceObject) {
    return isModelValid(updatedModel);
  }

  reset() {
    this.changes = [];
  }

  resetInvalid() {
    this.changes = this.changes.filter(
      (ch) => ch.status !== QueueChangeStatus.Invalid
    );
  }

  getAllUpdatedModels() {
    return this.getMergedModels();
  }

  getMergedModels(
    args: {
      status: QueueChangeStatus | null;
      type: string | null;
      id: string | null;
    } = { status: null, type: null, id: null }
  ): ResourceObject[] {
    return this.changes
      .filter(
        (ch) =>
          (!args.status || args.status === ch.status) &&
          (!args.type || args.type === ch.merged.type) &&
          (!args.id || args.id === ch.merged.id)
      )
      .map((ch) => ch.merged);
  }

  getValidChanges(args = { type: null, id: null }): Array<ResourceObject> {
    return this.getMergedModels({
      type: args.type,
      id: args.id,
      status: QueueChangeStatus.Valid,
    });
  }

  getScheduledChanges(args = { type: null, id: null }): Array<ResourceObject> {
    return this.getMergedModels({
      type: args.type,
      id: args.id,
      status: QueueChangeStatus.Scheduled,
    });
  }

  getInvalidChanges(args = { type: null, id: null }): Array<ResourceObject> {
    return this.getMergedModels({
      type: args.type,
      id: args.id,
      status: QueueChangeStatus.Invalid,
    });
  }

  getFailedValidations(mergedModel: ResourceObject) {
    const ch = this.getChange(mergedModel);
    let res = {};
    if (ch && !ch.validation.isValid) {
      res = this._merge({}, ch.validation.validations);
    }
    return res;
  }

  getValidAndScheduled(args = { type: null, id: null }) {
    return this.getValidChanges(args).concat(this.getScheduledChanges(args));
  }

  _objectHasKey(obj: any, fieldPath: string) {
    const args = fieldPath.split(".");

    for (let i = 0; i < args.length; i++) {
      if (!obj || !obj.hasOwnProperty(args[i])) {
        return false;
      }
      obj = obj[args[i]];
    }
    return true;
  }

  queryFieldStatus(mergedModel: ResourceObject, fieldPath: string) {
    const ch = this.findInQueue(mergedModel);
    // move through the changes done to the model and return the latest status.
    // if any change to the field is scheduled, that is the end status, even if there are unscheduled changes to the field
    let result;
    if (ch) {
      const lastChange = ch.changes
        .slice()
        .reverse()
        .find((indivCh) => indivCh.status === QueueChangeStatus.Scheduled);
      const lastScheduledChangeIdx = lastChange
        ? ch.changes.indexOf(lastChange)
        : -1;
      const fieldModifiedChanges = ch.changes.filter(
        (indivCh) => this._objectHasKey(indivCh.change, fieldPath),
        this
      );
      const lastFieldModifiedChangeIdx = ch.changes.indexOf(
        fieldModifiedChanges[fieldModifiedChanges.length - 1]
      );
      const isFieldInModel = this._objectHasKey(ch.merged, fieldPath);

      if (!isFieldInModel) {
        return QueueChangeStatus.Unknown;
      } else if (fieldModifiedChanges.length === 0) {
        return QueueChangeStatus.Unchanged;
      } else if (
        lastScheduledChangeIdx >= 0 &&
        lastScheduledChangeIdx >= lastFieldModifiedChangeIdx
      ) {
        return QueueChangeStatus.Scheduled;
      } else {
        return ch.changes[ch.changes.length - 1].status;
      }
    }

    return result;
  }

  hasValidUnscheduledChanges(mergedModels: ResourceObject | ResourceObjects) {
    const mergedModelsArray = Array.isArray(mergedModels)
      ? mergedModels
      : [mergedModels];
    const changes = this.changes.filter((ch) =>
      mergedModelsArray.includes(ch.merged)
    );
    return changes.some((ch) => ch.status === QueueChangeStatus.Valid);
  }

  hasScheduledChanges(mergedModels: ResourceObject | ResourceObjects) {
    const mergedModelsArray = Array.isArray(mergedModels)
      ? mergedModels
      : [mergedModels];
    const changes = this.changes.filter((ch) =>
      mergedModelsArray.includes(ch.merged)
    );
    return changes.some((ch) =>
      ch.changes
        .slice()
        .reverse()
        .find((ic) => ic.status === QueueChangeStatus.Scheduled)
    );
  }

  isModelValidOrScheduled(mergedModels: ResourceObject | ResourceObjects) {
    const mergedModelsArray = Array.isArray(mergedModels)
      ? mergedModels
      : [mergedModels];
    const changes = this.changes.filter((ch) =>
      mergedModelsArray.includes(ch.merged)
    );
    return changes.some(
      (ch) =>
        ch.status === QueueChangeStatus.Valid ||
        ch.status === QueueChangeStatus.Scheduled
    );
  }

  hasWriteableUpdate() {
    return this.changes.some((ch) => ch.status === QueueChangeStatus.Valid);
  }

  scheduleChange(mergedModel: ResourceObject, claimId: string) {
    const ch = this.findInQueue(mergedModel);
    if (!ch) {
      throw new Error("Queue cannot schedule non existent change");
    }
    if (ch.status === QueueChangeStatus.Invalid) {
      throw new Error("Queue cannot schedule invalid changes");
    }
    if (ch.status === QueueChangeStatus.Scheduled) {
      throw new Error("Queue cannot schedule changes already scheduled");
    }
    if (
      this.changes.map((ch) => ch.claimId).filter((id) => id === claimId)
        .length > 0
    ) {
      throw new Error(
        "Queue cannot schedule changes for claimId already active"
      );
    }

    ch.changes.forEach((ic) => (ic.status = QueueChangeStatus.Scheduled));
    ch.status = QueueChangeStatus.Scheduled;
    ch.claimId = claimId;
    return ch;
  }

  getChange(mergedModel: ResourceObject): ModelChange | undefined {
    return this.findInQueue(mergedModel);
  }

  isModelClaimed(model: ResourceObject, claimId: string) {
    const ch = this.findInQueue(model);
    return !!ch && ch.claimId === claimId;
  }

  clearScheduledChange(
    claimId: string,
    newOriginal: ResourceObject,
    newMerged: ResourceObject
  ) {
    const ch = this.changes.find((ch) => ch.claimId === claimId);
    if (ch) {
      while (
        ch.changes[0] &&
        ch.changes[0].status === QueueChangeStatus.Scheduled
      ) {
        ch.changes.shift();
      }
      if (ch.changes.length === 0) {
        this.changes = this.changes.filter((ch2) => ch2 !== ch);
      } else {
        // set overall status to status of latest change
        ch.status = ch.changes[ch.changes.length - 1].status;
        ch.original = newOriginal;
        ch.merged = newMerged;
        delete ch.newlyCreatedId;
        delete ch.claimId;
      }
    }
    return ch;
  }

  // used for the slight window  between a new time registration is placed in the store and until the newly merged model is recalculated from the new original
  notifyCreateId(claimId: string, entityId: string) {
    const ch = this.changes.find((ch) => ch.claimId === claimId);
    if (ch) {
      ch.newlyCreatedId = entityId;
    }
  }

  isMergedModelNotified(mergedModel: ResourceObject) {
    return !!this.getMergedModelCreateId(mergedModel);
  }

  getMergedModelCreateId(mergedModel: ResourceObject) {
    const ch = this.getChange(mergedModel);
    return ch && (ch.newlyCreatedId || apihelper.getEntityId(ch.original));
  }

  getMergedModelByClaim(claimId: string) {
    const ch = this.changes.find((ch) => ch.claimId === claimId);
    return ch && ch.merged;
  }

  getUpdatedMergeModel(
    mergedModel: ResourceObject,
    newOriginal: ResourceObject
  ) {
    const ch = this.getChange(mergedModel);
    let res: object | null = null;
    if (ch) {
      res = this._merge(
        newOriginal,
        ...ch.changes
          .filter((ic) => ic.status !== QueueChangeStatus.Scheduled)
          .map((ic) => ic.change)
      );
    }
    return res;
  }

  rollbackScheduledChange(claimId: string) {
    const ch = this.changes.find((ch) => ch.claimId === claimId);
    if (ch) {
      // recompute the status of every change from the original model to the latest model
      let updatedModel = ch.original;
      ch.changes.forEach((ic) => {
        updatedModel = this._merge(updatedModel, ic.change) as ResourceObject;
        ic.status = this.changeIsValid(updatedModel)
          ? QueueChangeStatus.Valid
          : QueueChangeStatus.Invalid;
      });
      ch.status = ch.changes[ch.changes.length - 1].status;
      delete ch.claimId;
    }
    return ch;
  }
}

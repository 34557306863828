import * as types from '../constants/ActionTypes';
import { ResourceObject } from '../lib/models';

export function login(selectAccount = false) {
    return {
        type: types.AUTH_INIT_LOGIN,
        payload: {
            selectAccount
        }
    };
}

export function logout() {
    return {
        type: types.AUTH_INIT_LOGOUT
    };
}

export function loginComplete(user: ResourceObject, error = null){
    return {
        type: types.AUTH_COMPLETE,
        payload: {
            user,
            error
        }
    };
}

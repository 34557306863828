import {all, fork} from 'redux-saga/effects';
import addChange from './addChange';
import claimChange from './claimChange';
import writeBack from './writeBack';

export default function* writeBackQueueRootSaga() {
    yield all([
        fork(addChange),
        fork(claimChange),
        fork(writeBack),
    ]);
}

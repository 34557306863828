import * as types from '../../constants/ActionTypes';

const initialState = {
    selectedClientId: null,
    selectedProjectId: null,
    selectedProjectResourceId: null,
};

function setSelectedClient(state, id) {
    return Object.assign({}, state, {
                selectedClientId: id,
                selectedProjectId: null,
                selectedProjectResourceId: null
            }); 
}

function setSelectedProject(state, id) {
    return Object.assign({}, state, {
                selectedProjectId: id,
                selectedProjectResourceId: null
            }); 
}

function setSelectedProjectResource(state, id) {
    return Object.assign({}, state, {
                selectedProjectResourceId: id
            }); 
}

export default function auth(state = initialState, action) {
    switch (action.type) {
        case types.APP_PAGE_PROJECTS_AND_ROLES_OPENED: {
            return Object.assign({}, state, {});
        }

        case types.APP_PAGE_PROJECTS_AND_ROLES_CLIENT_SELECTED : { 
            return setSelectedClient(state, action.payload?.id ?? null);
        }

        case types.APP_PAGE_PROJECTS_AND_ROLES_PROJECT_SELECTED : {
            let id = action.payload ? action.payload.id : null;
            return setSelectedProject(state, id);
        }

        case types.API_PROJECT_CREATE_SUCCESS : {
            return setSelectedProject(state, action.payload.requestData.data.id);
        }

        case types.APP_PAGE_PROJECTS_AND_ROLES_PROJECTRESOURCE_SELECTED : {
            return setSelectedProjectResource(state, action.payload ? action.payload.id : null);
        }

        default:
            return state;
    }
}

import React, { Component } from "react";
import PropTypes from "prop-types";
import { Checkbox } from "@fluentui/react/lib/Checkbox";
import { Label } from "@fluentui/react/lib/Label";
import {
  entityHasId,
  getAttr,
  getRelId,
  getEntityId,
} from "../../selectors/apihelper";
import {
  createEmptyModelRelationships,
  createEmptyModelAttributes,
} from "../../lib/emptyModelCreation";
import * as EntityTypes from "../../constants/EntityTypes";
import ResourcePicker from "../uifabricextensions/ResourcePicker";
import SingleBaseForm from "./SingleBaseForm";

export default class UserForm extends SingleBaseForm {
  constructor(props) {
    super(props);
    let { model } = props;
    this.state = {};

    this.state.fields = SingleBaseForm._initializeFields(
      {
        active: {
          getVal: () => getAttr(this.props.model, "active"),
          setVal: () =>
            createEmptyModelAttributes(EntityTypes.USER, {
              active: this.state.fields.active.value,
            }),
          isRequired: true,
        },
        resourceId: {
          getVal: () => getRelId(this.props.model, "resource"),
          setVal: () =>
            createEmptyModelRelationships(EntityTypes.USER, {
              resource:
                this.state.fields.resourceId.value != null
                  ? {
                      id: this.state.fields.resourceId.value,
                      type: EntityTypes.RESOURCE,
                    }
                  : null,
            }),
          isRequired: false,
        },
      },
      model
    );

    this._onActiveChange = this._onActiveChange.bind(this);
    this._onResourceIdChange = this._onResourceIdChange.bind(this);
  }

  _onActiveChange(ev, isChecked) {
    this._updateFieldState("active", isChecked);
  }

  _onResourceIdChange(selectedResources) {
    this._updateFieldState(
      "resourceId",
      selectedResources[0] ? getEntityId(selectedResources[0]) : null
    );
  }

  render() {
    let { model, resources, users } = this.props;
    let showChangeIcons = !!model;
    let selectedResources = [];
    let selectedResource = resources.find((r) =>
      entityHasId(r, this.state.fields.resourceId.value)
    );
    if (selectedResource) {
      selectedResources.push(selectedResource);
    }

    return (
      <div>
        <h2>{model ? "Edit" : "Create"} User</h2>
        <br />
        <Checkbox
          label="Active"
          onChange={this._onActiveChange}
          checked={this.state.fields.active.value}
        />
        <br />
        <Label>Resource</Label>
        <ResourcePicker
          resources={resources}
          users={users}
          selectedResources={selectedResources}
          onResourceSelected={this._onResourceIdChange}
        />
        <br />
        {this._renderButtons()}
        <br />
        {this._renderTimestampsAndId(model)}
      </div>
    );
  }
}

UserForm.propTypes = {
  model: PropTypes.object,
  users: PropTypes.array,
  resources: PropTypes.array,
  onSave: PropTypes.func,
  onDismiss: PropTypes.func,
};

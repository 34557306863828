import {call, fork, put, select, take} from 'redux-saga/effects';
import * as types from '../../constants/ActionTypes';
import * as EntityTypes from '../../constants/EntityTypes';
import {queue} from '../../selectors/reducers/writeBackQueue'
import {timeRegistrationById} from '../../selectors/reducers/timeRegistration'
import { getUpsertTimeRegistrationId } from '../../selectors/actions/timeRegistration'
import {v1 as uuidv1} from 'uuid';
import * as writeBackActions from '../../actions/writeBackQueue';
import * as timeRegActions from '../../actions/api/timeRegistration';
import * as sagahelpers from '../helpers'
import ModelMergeQueue, { ModelChange } from '../../lib/ModelMergeQueue';
import * as apihelper from '../../selectors/apihelper';
import { ResourceObject } from '../../lib/models';

function* claimForWriteBack(model: ResourceObject, claimId: string) {
    yield put(writeBackActions.claimModelForWriteBack(model, claimId));
    yield take((a: any) => a.type === types.WRITEBACK_QUEUE_CLAIM_MODEL_FOR_WRITEBACK_DONE && a.payload.claimIdentifier === claimId);
    const q = (yield select(queue)) as ModelMergeQueue;
    if(!q.isModelClaimed(model, claimId)){
        throw new Error("model in writeback was not claimed as expected");
    }
}

function* doSingleWriteBack(mergedModel: ResourceObject): any {
    if(EntityTypes.TIMEREGISTRATION !== mergedModel.type){
        throw new Error("doSingleWriteBack saga can only perform writeback on time registrations. received type " + mergedModel.type);
    }

    // claim the model in the queue for this generator run
    const claimId = uuidv1();
    yield call(claimForWriteBack, mergedModel, claimId);

    // handle the change with api calls
    let q = (yield select(queue)) as ModelMergeQueue;
    yield put(writeBackActions.writebackStarted(claimId, mergedModel));
    const change = q.getChange(mergedModel) as ModelChange;

    const isUpdate = apihelper.isPersistedEntity(change.original);
    const initAction = isUpdate ? 
        timeRegActions.updateTimeRegistration(change.original, change.mergedChanges, claimId, change.queueId) :
        timeRegActions.createTimeRegistration(change.merged, claimId, change.queueId);
    const response = yield sagahelpers.callAndAwait(initAction);

    const wasSuccessful = !!response.success;
    let newModel;
    if(wasSuccessful){
        newModel = yield select(timeRegistrationById, getUpsertTimeRegistrationId(response.success));
        yield put(writeBackActions.writeBackSuccess(claimId, newModel, mergedModel));
    }
    else if(response.error){
        yield put(writeBackActions.writeBackFailed(claimId, response.error.payload));
    }

    q = yield select(queue);
    const oldMergedModel = q.getMergedModelByClaim(claimId) as ResourceObject;
    const newMergedModel = q.getUpdatedMergeModel(oldMergedModel, newModel);
    yield put(writeBackActions.writebackComplete(claimId, oldMergedModel, newMergedModel, newModel, wasSuccessful));

    return wasSuccessful;
}


function* loopQueue(): any {
    const failedRequests = new Map();
    while(true){
        const q = yield select(queue);
        const modelsWithExecutableChanges = [];
        // find which model changes can be written back
        if(q && q.hasWriteableUpdate()){
            const changedModels = q.getValidChanges();
            for(let i = 0; i < changedModels.length; i++){
                const model = changedModels[i];
                const ch = q.getChange(model);
                if(failedRequests.get(ch.original) !== model){
                    modelsWithExecutableChanges.push(model)
                }
            }
        }
        // write back the first one
        if(modelsWithExecutableChanges.length > 0){
            const model = modelsWithExecutableChanges[0];
            const ch = q.getChange(model);
            const success = yield call(doSingleWriteBack, model);
            if(!success){
                failedRequests.set(ch.original, model);
            }
            else{
                failedRequests.delete(ch.original);
            }
            yield call(delay, 1000);
        }
        else{
            yield call(delay, 3000);
        }
    }
}

function delay(delay: number) {
    return new Promise( res => setTimeout(res, delay) );
}

export default function* writeBack() {
    yield fork(loopQueue);
}

import React from "react";
import PropTypes from "prop-types";
import { TextField } from "@fluentui/react/lib/TextField";
import SingleBaseForm from "./SingleBaseForm";
import { getAttr } from "../../selectors/apihelper";
import { createEmptyModelAttributes } from "../../lib/emptyModelCreation";
import * as EntityTypes from "../../constants/EntityTypes";

export default class ContractForm extends SingleBaseForm {
  constructor(props) {
    super(props);
    let { model } = props;
    this.state = {};

    this.state.fields = SingleBaseForm._initializeFields(
      {
        title: {
          getVal: () => getAttr(this.props.model, "title"),
          setVal: () =>
            createEmptyModelAttributes(EntityTypes.CONTRACT, {
              title: this.state.fields.title.value,
            }),
          isRequired: true,
        },
        description: {
          getVal: () => getAttr(this.props.model, "description"),
          setVal: () =>
            createEmptyModelAttributes(EntityTypes.CONTRACT, {
              description: this.state.fields.description.value,
            }),
          isRequired: false,
        },
      },
      model
    );

    this._onTitleChange = this._onTitleChange.bind(this);
    this._onDescriptionChange = this._onDescriptionChange.bind(this);
  }

  _onTitleChange(e, value) {
    this._updateFieldState(
      "title",
      value,
      !value ? "Title cannot be empty" : undefined
    );
  }

  _onDescriptionChange(e, value) {
    this._updateFieldState("description", value);
  }

  render() {
    let { model } = this.props;
    let titleIsChanged = this.state.fields.title.isChanged,
      descriptionIsChanged = this.state.fields.description.isChanged;
    let showChangeIcons = !!model;

    return (
      <div>
        <h2>{model ? "Edit" : "Create"} Contract</h2>
        <br />
        <TextField
          label="Title"
          autoFocus={!model}
          iconProps={this._getIconProps(titleIsChanged)}
          onChange={this._onTitleChange}
          required={this.state.fields.title.isRequired}
          value={this.state.fields.title.value}
          errorMessage={this.state.fields.title.validationMessage}
        />
        <br />
        <TextField
          label="Description"
          iconProps={this._getIconProps(descriptionIsChanged)}
          onChange={this._onDescriptionChange}
          required={this.state.fields.description.isRequired}
          multiline
          rows={4}
          value={this.state.fields.description.value}
          errorMessage={this.state.fields.description.validationMessage}
        />
        <br />
        {this._renderButtons()}
        <br />
        {this._renderTimestampsAndId(model)}
      </div>
    );
  }
}

ContractForm.propTypes = {
  model: PropTypes.object,
  onSave: PropTypes.func,
  onDismiss: PropTypes.func,
};

export const ACCOUNT = "Account";

export const BILLABILITYTYPE = "BillabilityType";

export const CLIENT = "Client";
export const CONTRACT = "Contract";
export const CONTRACTROLE = "ContractRole";
export const CURRENCY = "Currency";

export const LANGUAGE = "Language";
export const LOCALE = "Locale";
export const LOCKEDTIMEPERIOD = "LockedTimePeriod";

export const PERMISSION = "Permission";
export const PERMISSIONTYPE = "PermissionType";

export const PROJECT = "Project";
export const PROJECTRESOURCE = "ProjectResource";
export const PROJECTSTATUS = "ProjectStatus";

export const RESOURCE = "Resource";
export const RESOURCETYPE = "ResourceType";

export const TIMEREGISTRATION = "TimeRegistration";
export const TIMEREGISTRATIONSTATUS = "TimeRegistrationStatus";

export const USER = "User";

export const ABSENCEPERIOD = "AbsencePeriod";
export const ABSENCEPERIODCOMMENT = "AbsencePeriodComment";
export const ABSENCEPERIODSTATUS = "AbsencePeriodStatus";
export const ABSENCEPERIODTYPE = "AbsencePeriodType";

export const ABSENCEREGISTRATIONPROJECT = "AbsenceRegistrationProject";

export const WORKLOCATION = "WorkLocation";
export const WORKLOCATIONREGISTRATION = "WorkLocationRegistration";

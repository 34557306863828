import { combineReducers } from "redux";
import account from "./account";
import billabilitytypes from "./billabilityTypes";
import client from "./client";
import contract from "./contract";
import contractRole from "./contractRole";
import currency from "./currency";
import timeRegistration from "./timeRegistration";
import lockedTimePeriod from "./lockedTimePeriod";
import permission from "./permission";
import permissiontypes from "./permissionTypes";
import project from "./project";
import projectResource from "./projectResource";
import resource from "./resource";
import user from "./user";
import absencePeriod from "./absencePeriod";
import absencePeriodComment from "./absencePeriodComment";
import absencePeriodType from "./absencePeriodType";
import absencePeriodStatus from "./absencePeriodStatus";
import absenceRegistrationProject from "./absenceRegistrationProject";
import timeRegistrationStatus from "./timeRegistrationStatus";
import resourceType from "./resourceType";
import language from "./language";
import projectStatus from "./projectStatus";
import locale from "./locale";
import workLocation from "./workLocation";
import workLocationRegistration from "./workLocationRegistration";

export default combineReducers({
  account,
  billabilitytypes,
  client,
  contract,
  contractRole,
  timeRegistration,
  lockedTimePeriod,
  permission,
  permissiontypes,
  project,
  projectResource,
  resource,
  user,
  absencePeriod,
  absencePeriodComment,
  absencePeriodType,
  absencePeriodStatus,
  absenceRegistrationProject,
  timeRegistrationStatus,
  currency,
  projectStatus,
  language,
  locale,
  resourceType,
  workLocation,
  workLocationRegistration,
});

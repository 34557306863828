import * as SagaHelpers from '../helpers';
import * as SagaEffects from 'redux-saga/effects';
import * as types from '../../constants/ActionTypes';
import CurrencyConnector from '../../connectors/Currency';

const getAllCurrencies = SagaHelpers.createApiHandler(
    types.API_CURRENCY_GET_ALL_INIT, 
    SagaHelpers.generateGetAllApiCall(
        CurrencyConnector, 
        function* (connector: CurrencyConnector): any {
            return yield SagaEffects.call([connector, connector.getCurrency]);
        }
    )
)

export default function* currencySaga() {
    yield SagaEffects.all([
        SagaEffects.fork(getAllCurrencies)
    ]);
}

import * as SagaHelpers from '../helpers';
import * as SagaEffects from 'redux-saga/effects';
import * as types from '../../constants/ActionTypes';
import ProjectStatusConnector from '../../connectors/ProjectStatus';

const getAllProjectStatus = SagaHelpers.createApiHandler(
    types.API_PROJECTSTATUS_GET_ALL_INIT, 
    SagaHelpers.generateGetAllApiCall(
        ProjectStatusConnector,
        function* (connector: ProjectStatusConnector): any {
            return yield SagaEffects.call([connector, connector.getProjectStatus]);
        }
    )
)

export default function* projectStatusSaga() {
    yield SagaEffects.all([
        SagaEffects.fork(getAllProjectStatus)
    ]);
}

import React, { Component } from "react";
import { Dialog, DialogFooter } from "@fluentui/react/lib/Dialog";
import { DefaultButton, PrimaryButton } from "@fluentui/react/lib/Button";
import { Checkbox } from "@fluentui/react/lib/Checkbox";
import { getAttr } from "../selectors/apihelper";

export default class ResourceCheckboxDialog extends Component {
  constructor(props) {
    super(props);
    this.state = { checkedResourcesIds: [] };
    this._onClickCheckAll = this._onClickCheckAll.bind(this);
    this._onClickCheckbox = this._onClickCheckbox.bind(this);
    this._onClickUncheckAll = this._onClickUncheckAll.bind(this);
    this._onConfirm = this._onConfirm.bind(this);
    this._onDismiss = this._onDismiss.bind(this);
  }

  _onClickCheckbox(e) {
    let { checkedResourcesIds } = this.state;
    let clickedResourceId = e.target.id;
    if (checkedResourcesIds.includes(clickedResourceId)) {
      checkedResourcesIds = checkedResourcesIds.filter(
        (id) => id !== clickedResourceId
      );
    } else {
      checkedResourcesIds.push(clickedResourceId);
    }
    this.setState({ checkedResourcesIds });
  }

  _onClickCheckAll() {
    let { resources } = this.props;
    let ids = resources.map((resource) => resource.id);
    this.setState({ checkedResourcesIds: ids });
  }

  _onClickUncheckAll() {
    this.setState({ checkedResourcesIds: [] });
  }

  _onConfirm() {
    let { resources, onConfirm } = this.props;
    let { checkedResourcesIds } = this.state;
    if (onConfirm) {
      let checkedResources = resources.filter((resource) =>
        checkedResourcesIds.includes(resource.id)
      );
      onConfirm(checkedResources);
    }
    this.setState({ checkedResourcesIds: [] });
  }

  _onDismiss() {
    let { onDismiss } = this.props;
    this.setState({ checkedResourcesIds: [] });
    if (onDismiss) {
      onDismiss();
    }
  }

  render() {
    let { hidden, title, resources } = this.props;
    let { checkedResourcesIds } = this.state;

    return (
      <Dialog
        hidden={hidden}
        onDismiss={this._onDismiss}
        dialogContentProps={{
          title,
        }}
        modalProps={{
          isBlocking: true,
        }}
      >
        <DefaultButton
          className="novatime-modal-default-button"
          onClick={this._onClickCheckAll}
          text="Check all"
        />
        <DefaultButton
          className="novatime-modal-default-button"
          onClick={this._onClickUncheckAll}
          text="Uncheck all"
        />
        {resources &&
          resources.map((resource) => (
            <Checkbox
              key={`${getAttr(resource, "name")}-${resource.id}`}
              className="novatime-modal-checkbox"
              id={resource.id}
              label={getAttr(resource, "name")}
              onChange={this._onClickCheckbox}
              checked={checkedResourcesIds.includes(resource.id)}
            />
          ))}
        <DialogFooter>
          <PrimaryButton onClick={this._onConfirm} text="OK" />
          <DefaultButton onClick={this._onDismiss} text="Cancel" />
        </DialogFooter>
      </Dialog>
    );
  }
}

import 'regenerator-runtime/runtime'
import { all, fork } from 'redux-saga/effects';
import domain from './domain'
import fetchInitialData from './fetchInitialData';
import bootstrap from './bootstrap';
import notification from './notification';

export default function* appRootSaga() {
    yield all([
        fork(domain),
        fork(bootstrap),
        fork(fetchInitialData),
        fork(notification)
    ]);
}
import { createSelector } from "reselect";
import {lexicalInsensitiveNameSortFunc} from '../../lib/sorting';
import * as apihelper from '../apihelper';
import * as storehelper from '../storehelper';
import * as appDomainSelectors from "../app/domain"
import * as reducerProjectSelectors from "../reducers/project";
import * as reducerAccountSelectors from "../reducers/account";
import * as reducerResourceTypeSelectors from "../reducers/resourceType";
import * as reducerResourceSelectors from "../reducers/resource";
import * as reducerTimeRegistrationSelectors  from "../reducers/timeRegistration";
import {TimeRegistrationIndex} from '../../lib/StateMerge';

const selectableResourceSort = (a,b ) => {
    let aIsActive = apihelper.getAttr(a, 'isActive');
    let bIsActive = apihelper.getAttr(b, 'isActive');
    if(aIsActive && !bIsActive){
        return -1;
    }
    else if(bIsActive && !aIsActive){
        return 1;
    }
    else{
        let aResourceTypeId = apihelper.getRelId(a, 'resourceType');
        let bResourceTypeId = apihelper.getRelId(b, 'resourceType');
        if(aResourceTypeId === bResourceTypeId){
            return lexicalInsensitiveNameSortFunc(a, b);
        }
        else {
            return bResourceTypeId - aResourceTypeId;
        }
    }
}

export const selectedProjectIds = state => state.page.timeRegistrationExport.filter.projectIds;

export const selectedResourceTypeIds = state => state.page.timeRegistrationExport.filter.resourceTypeIds;

export const selectedResourceIds = state => state.page.timeRegistrationExport.filter.resourceIds;

const startTimeFilter = state => state.page.timeRegistrationExport.filter.startTime;

export const startTime = createSelector(
    [
        startTimeFilter
    ], 
    st => st === null ? st : new Date(st)
);

const endTimeFilter = state => state.page.timeRegistrationExport.filter.endTime;

export const endTime = createSelector(
    [
        endTimeFilter
    ], 
    et => et === null ? et : new Date(et)
);

export const filter = state => state.page.timeRegistrationExport.filter;

export const viewDesc = state => state.page.timeRegistrationExport.viewDesc;

export const selectedResources = createSelector(
    [selectedResourceIds, reducerResourceSelectors.allResources],
    (resourceIds, resources) => resources.filter(r => resourceIds.includes(apihelper.getEntityId(r)))
)

export const selectedProjects = createSelector(
    [reducerProjectSelectors.allProjects, selectedProjectIds],
    (projects, projectIds) => storehelper.filterEntitiesByIds(projects, projectIds)
)

export const allProjectsForAccount = createSelector(
    [reducerAccountSelectors.currentUserAccount, reducerProjectSelectors.allProjects],
    (selectedAccount, allProjects) => storehelper.filterByReferenceToRelatedEntities(allProjects, selectedAccount, "account").flatten().sort(lexicalInsensitiveNameSortFunc)
)

export const selectedResourceTypes = createSelector(
    [reducerResourceTypeSelectors.allResourceType, selectedResourceTypeIds],
    (resourceType, selectedResourceTypeIds) =>
        storehelper.filterEntitiesByIds(resourceType, selectedResourceTypeIds)    
)

export const resourceTypeOptions = createSelector(
    [reducerResourceTypeSelectors.allResourceType],
    (allResourceType) => allResourceType.slice().sort(lexicalInsensitiveNameSortFunc)
)

export const projectResourcesForCurrentFilter = createSelector(
    [
        appDomainSelectors.allProjectResourcesForSelectedAccount,
        selectedProjects
    ], 
    (allProjectResourcesForSelectedAccount, selectedProjects) => {
        return selectedProjects.length === 0 ? 
            allProjectResourcesForSelectedAccount :
            storehelper.filterByReferenceToRelatedEntities(allProjectResourcesForSelectedAccount, selectedProjects, 'project')
    }
)

export const allocatableResourcesForCurrentFilter = createSelector(
    [projectResourcesForCurrentFilter, appDomainSelectors.allResourcesForSelectedAccount],
    (projectResources, allResourcesForSelectedAccount) => {
        let hasDefaultProjectResource = projectResources.some(pr => apihelper.getAttr(pr, 'isDefaultProjectResource'));
        return hasDefaultProjectResource ? 
            allResourcesForSelectedAccount :
            storehelper.filterByRelatedEntity(allResourcesForSelectedAccount, projectResources, 'resource')
    }
);

export const timeRegistrationIndex = createSelector(
    [reducerTimeRegistrationSelectors.allTimeRegistrations],
    (allTimeRegistrations) => {
        return new TimeRegistrationIndex(allTimeRegistrations)
    }
);

export const timeRegistrationsForCurrentFilter = createSelector(
    [timeRegistrationIndex, selectedProjectIds, startTime, endTime, selectedResources, allocatableResourcesForCurrentFilter, selectedResourceTypeIds],
    (timeRegistrationIndex, selectedProjectIds, startTime, endTime, selectedResources, resourcesForCurrentFilter, selectedResourceTypeIds) => {
        let resourcesForFilter = resourcesForCurrentFilter;
        if(selectedResources.length > 0){
            resourcesForFilter = storehelper.intersectEntities(selectedResources, resourcesForCurrentFilter);
        }
        let timeregs = timeRegistrationIndex.query({projects: selectedProjectIds, resources: resourcesForFilter, startTime, endTime});
        // add the filter on resource type, if any filters for that has been set
        return timeregs.filter(tr => {
            let result = true;
            if(selectedResourceTypeIds.length > 0){
                let resource = storehelper.findById(resourcesForCurrentFilter, apihelper.getRelId(tr, 'resource'));
                result = !resource || selectedResourceTypeIds.includes(apihelper.getRelId(resource, 'resourceType'));
            }
            return result;
        });
    }
);

export const resourcesInTimeregistrationsForCurrentFilter = createSelector(
    [timeRegistrationsForCurrentFilter, reducerResourceSelectors.allResources],
    (timeRegs, allResources) =>
        {
            return storehelper.filterByRelatedEntity(allResources, timeRegs, 'resource')
        }
)

export const selectableResources = createSelector(
    [selectedResources, allocatableResourcesForCurrentFilter, resourcesInTimeregistrationsForCurrentFilter],
    (selectedResources, allocatableResources, resourcesForTregs) => {
        return storehelper.unionEntities(selectedResources, allocatableResources, resourcesForTregs).sort(selectableResourceSort)
    }
)

export const selectedProjectsForTagPicker = createSelector(
    [selectedProjects],
    (projects) => projects.map(project => ({
                        name: apihelper.getAttr(project, "name"),
                        key: apihelper.getEntityType(project) + "-" + apihelper.getEntityId(project),
                        type: apihelper.getEntityType(project),
                        id: apihelper.getEntityId(project),
    }))
)
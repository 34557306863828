import moment from "moment";

function sameDay(d1: Date, d2: Date) {
  return (
    d1.getFullYear() === d2.getFullYear() &&
    d1.getMonth() === d2.getMonth() &&
    d1.getDate() === d2.getDate()
  );
}

function easterSunday(year: number) {
  const a = year % 19,
    b = Math.floor(year / 100),
    c = year % 100,
    d = Math.floor(b / 4),
    e = b % 4,
    f = Math.floor((b + 8) / 25),
    g = Math.floor((b - f + 1) / 3),
    h = (19 * a + b - d - g + 15) % 30,
    j = Math.floor(c / 4),
    k = c % 4,
    l = (32 + 2 * e + 2 * j - h - k) % 7,
    m = Math.floor((a + 11 * h + 22 * l) / 451),
    n = Math.floor((h + l - 7 * m + 114) / 31),
    p = (h + l - 7 * m + 114) % 31,
    day = p + 1,
    month = n - 1;

  const easter = new Date(year, month, day);
  return easter;
}

type Holidays = Record<string, Date>;

abstract class HolidayCalculator {
  _year: number;
  _holidays: Holidays;
  _holidayDates: Date[];
  constructor(year: number) {
    this._year = year;
    this._holidays = this._calculateHolidays(year);
    this._holidayDates = Object.values(this._holidays);
  }

  isHoliday(date: Date): boolean {
    return this._holidayDates.some((hd) => sameDay(hd, date));
  }

  getHolidays(): Holidays {
    return this._holidays;
  }

  getHolidayName(date: Date): string | undefined {
    return Object.keys(this._holidays).find((key) =>
      sameDay(this._holidays[key], date)
    );
  }

  abstract _calculateHolidays(year: number): Holidays;

  getHolidaysBetween(startDate: Date, endDate: Date): Holidays {
    const result: Holidays = {};
    const start = new Date(startDate.valueOf());
    start.setHours(0, 0, 0, 0);
    const end = new Date(endDate.valueOf());
    end.setHours(23, 59, 59, 999);
    Object.keys(this._holidays)
      .filter(
        (dayName) =>
          start <= this._holidays[dayName] && this._holidays[dayName] <= end
      )
      .forEach((dayName) => (result[dayName] = this._holidays[dayName]));
    return result;
  }
}

class HolidayCalculatorDk extends HolidayCalculator {
  constructor(year: number) {
    super(year);
  }

  _calculateHolidays(year: number): Holidays {
    const result: Holidays = {
      Nytårsdag: new Date(year, 0, 1),
      Juleaften: new Date(year, 11, 24),
      "1. juledag": new Date(year, 11, 25),
      "2. juledag": new Date(year, 11, 26),
      "Nytårsaftens dag": new Date(year, 11, 31),
    };

    const easter = easterSunday(year); //new Date(year, month, day);
    result["Påskedag"] = easter;

    result["2. påskedag"] = new Date(easter.valueOf());
    result["2. påskedag"].setDate(easter.getDate() + 1);
    result["Langfredag"] = new Date(easter.valueOf());
    result["Langfredag"].setDate(easter.getDate() - 2);
    result["Skærtorsdag"] = new Date(easter.valueOf());
    result["Skærtorsdag"].setDate(easter.getDate() - 3);
    // we hope to remove this condition sometime in the future
    if (year < 2024) {
      result["Store Bededag"] = new Date(easter.valueOf());
      result["Store Bededag"].setDate(easter.getDate() + 21 + 5); // 3rd sunday after easter plus some
    }

    result["Kristi Himmelfart"] = new Date(easter.valueOf());
    result["Kristi Himmelfart"].setDate(easter.getDate() + 35 + 4); // 5th sunday after easter plus some
    const pinsedag = new Date(easter.valueOf());
    pinsedag.setDate(easter.getDate() + 42 + 7); // 6th sunday after easter and another week
    result["Pinsedag"] = pinsedag;
    result["2. pinsedag"] = new Date(pinsedag.valueOf());
    result["2. pinsedag"].setDate(pinsedag.getDate() + 1);

    return result;
  }
}

class HolidayCalculatorUs extends HolidayCalculator {
  constructor(year: number) {
    super(year);
  }

  _calculateHolidays(year: number) {
    const result: Holidays = {
      "New Year's Day": new Date(year, 0, 1),
      "Christmas Day": new Date(year, 11, 24),
      "1st Day of Christmas": new Date(year, 11, 25),
      "2nd Day of Christmas": new Date(year, 11, 26),
      "New Year's Eve": new Date(year, 11, 31),
      "Independence Day": new Date(year, 5, 4),
    };

    const daysToFirstMonday = (x: number) => (6 - x + 2) % 7;
    const daysToLastMonday = (x: number) => -((x + 6) % 7);
    const daysToFirstThursday = (x: number) => (6 - x + 5) % 7;
    const easter = easterSunday(year);
    result["Easter Sunday"] = easter;
    result["Good Friday"] = new Date(easter.valueOf());
    result["Good Friday"].setDate(easter.getDate() - 3);
    result["Holy Saturday"] = new Date(easter.valueOf());
    result["Holy Saturday"].setDate(easter.getDate() - 2);
    result["2nd Day of Easter"] = new Date(easter.valueOf());
    result["2nd Day of Easter"].setDate(easter.getDate() + 1);
    const laborDay = new Date(year, 8, 1);
    laborDay.setDate(laborDay.getDate() + daysToFirstMonday(laborDay.getDay()));
    result["Labor Day"] = laborDay;
    const memorialDay = new Date(year, 5, 1);
    memorialDay.setDate(
      memorialDay.getDate() + daysToLastMonday(memorialDay.getDay())
    );
    result["Memorial Day"] = memorialDay;
    const thanksgivingThursday = new Date(year, 10, 1);
    thanksgivingThursday.setDate(
      thanksgivingThursday.getDate() +
        daysToFirstThursday(thanksgivingThursday.getDay()) +
        21
    );
    result["Thanksgiving Day"] = thanksgivingThursday;
    result["Black Friday"] = new Date(thanksgivingThursday.valueOf());
    result["Black Friday"].setDate(thanksgivingThursday.getDate() + 1);
    return result;
  }
}

export type Locale = "dk" | "us";

export default class BusinessCalendar {
  _defaultLocale: Locale | undefined;
  _locales: Record<number, Record<Locale, HolidayCalculator>>;
  constructor(defaultLocale?: Locale) {
    this._defaultLocale = defaultLocale;
    this._locales = {};
  }

  _getCalculatorForLocaleAndYear(
    year: number,
    locale: Locale
  ): HolidayCalculator {
    switch (locale) {
      case "dk":
        return new HolidayCalculatorDk(year);
      case "us":
        return new HolidayCalculatorUs(year);
      default:
        throw new Error("BusinessCalendar unknown locale " + locale);
    }
  }

  _getHolidayCalculator(
    date: number | Date,
    locale: Locale
  ): HolidayCalculator {
    const year = typeof date == "number" ? date : date.getFullYear();
    this._locales[year] = this._locales[year] || {};
    this._locales[year][locale] =
      this._locales[year][locale] ||
      this._getCalculatorForLocaleAndYear(year, locale);
    return this._locales[year][locale];
  }

  isHoliday(date: Date, locale: Locale) {
    return this._getHolidayCalculator(date, locale).isHoliday(date);
  }

  getHolidayName(date: Date, locale: Locale) {
    return this._getHolidayCalculator(date, locale).getHolidayName(date);
  }

  isToday(date: Date): boolean {
    return sameDay(new Date(), date);
  }

  isWeekend(date: Date): boolean {
    const day = date.getDay();
    return day === 6 || day === 0;
  }

  isBusinessDay(date: Date, locale: Locale): boolean {
    return !(this.isWeekend(date) || this.isHoliday(date, locale));
  }

  getNumberOfWeekendDaysBetween(
    startDate: Date,
    endDate: Date,
    datesAreInclusive = false
  ): number {
    const momentStartDate = moment(startDate).startOf("day");
    const momentEndDate = moment(endDate).endOf("day");
    const diff = momentEndDate.diff(momentStartDate, "days", datesAreInclusive);
    const numWholeWeeks = Math.floor(diff / 7);
    const remainder = diff % 7;
    const startWeekDay = startDate.getDay();
    let result = 0;
    for (let i = 0; i < remainder; i++) {
      const weekDay = (startWeekDay + i) % 7;
      if (weekDay === 0 || weekDay === 6) {
        result++;
      }
    }
    result += numWholeWeeks * 2;
    return result;
  }

  getNumberOfBusinessDaysBetween(
    startDate: Date,
    endDate: Date,
    locale: Locale,
    datesAreInclusive = false
  ): number {
    // get and subtract holidays (that are not on weekends)
    const startYear = startDate.getFullYear();
    const endYear = endDate.getFullYear();
    let noOfHolidays = 0;
    for (let year = startYear; year <= endYear; year++) {
      const calc = this._getHolidayCalculator(year, locale);
      const holidays = calc.getHolidaysBetween(startDate, endDate);
      noOfHolidays += Object.values(holidays).filter(
        (d) => !this.isWeekend(d),
        this
      ).length;
    }

    // remove weekends
    const momentEndDate = moment(endDate).endOf("day");
    const momentStartDate = moment(startDate).startOf("day");
    const numberOfDays = Math.ceil(
      momentEndDate.diff(momentStartDate, "days", datesAreInclusive)
    );
    const numberOfWeekendDays = this.getNumberOfWeekendDaysBetween(
      startDate,
      endDate,
      datesAreInclusive
    );
    return numberOfDays - noOfHolidays - numberOfWeekendDays;
  }
}

import BaseConnector from './Base';
import { getRelId } from '../selectors/apihelper';
import { ResourceObject } from '../lib/models';

export default class AbsencePeriodCommentConnector extends BaseConnector {
    getAbsencePeriodComments(id: string){
        return this.authFetch(this.baseApiUrl + 'AbsencePeriods/' + id + "/Comments?includes=sentByUser,absencePeriod");
    }

    createAbsencePeriodComment(dto: ResourceObject) {
        return this.authPost(this.baseApiUrl + 'AbsencePeriods/' + getRelId(dto, 'absencePeriod') + "/Comments", { data: dto });
    }
}

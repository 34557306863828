import * as SagaHelpers from '../helpers';
import * as SagaEffects from 'redux-saga/effects';
import * as types from '../../constants/ActionTypes';
import TimeRegistrationConnector from '../../connectors/TimeRegistration';
import * as apihelper from '../../selectors/apihelper';
import '../../lib/unique';
import * as projectSelectors from '../../selectors/reducers/project';
import * as contractSelectors from '../../selectors/reducers/contract';

const createTimeRegistration = SagaHelpers.createApiHandler(
    types.API_TIMEREGISTRATION_CREATE_INIT, 
    SagaHelpers.generateCreateApiCall(
        TimeRegistrationConnector,
        function*(connector: TimeRegistrationConnector, action: any): any {
            return yield SagaEffects.call([connector, connector.createTimeRegistration], {
                data: action.payload.model
            });
        }
    )
)

const deleteTimeRegistration = SagaHelpers.createApiHandler(
    types.API_TIMEREGISTRATION_DELETE_INIT, 
    SagaHelpers.generateDeleteApiCall(
        TimeRegistrationConnector,
        function*(connector: TimeRegistrationConnector, action: any): any {
            return yield SagaEffects.call([connector, connector.deleteTimeRegistration], action.payload.model.id);
        }
    )
)

const getAllTimeRegistration = SagaHelpers.createApiHandler(
    types.API_TIMEREGISTRATION_GET_ALL_INIT, 
    SagaHelpers.generateGetAllApiCall(
        TimeRegistrationConnector,
        function* (connector: TimeRegistrationConnector, action: any): any {
            const resources = action.payload.parameters.resources;
            const project = action.payload.parameters.project;
            const projects = action.payload.parameters.projects;
            const client = action.payload.parameters.client;
            const startTime = action.payload.parameters.startTime;
            const endTime = action.payload.parameters.endTime;
        
            const resourceIds = resources ? resources.map(apihelper.getEntityId) : null;
            let projectIds: string[] | null = [];
            if(project){
                projectIds.push(apihelper.getEntityId(project) as string);
            }
            if(Array.isArray(projects)){
                projectIds = projectIds.concat(projects.map(apihelper.getEntityId) as string[])
            }
            if(client){
                const allProjects = yield SagaEffects.select(projectSelectors.allProjects);
                const allContracts = yield SagaEffects.select(contractSelectors.allContracts)
                const allContractsForClient = contractSelectors.filterByClients(allContracts, client);
                const allProjectsForClient = projectSelectors.filterByContracts(allProjects, allContractsForClient);
                projectIds = projectIds.concat(allProjectsForClient.map(apihelper.getEntityId) as string[]);
            }
            projectIds = projectIds.unique();
            if(projectIds.length === 0){
                projectIds = null;
            }
        
            return yield SagaEffects.call(
                [connector, connector.getAll],
                { resourceIds, projectIds, startTime: new Date(startTime), endTime: new Date(endTime) },
                action.payload.includes);
        }
    )
)

const updateTimeRegistration = SagaHelpers.createApiHandler(
    types.API_TIMEREGISTRATION_UPDATE_INIT, 
    SagaHelpers.generateUpdateApiCall(
        TimeRegistrationConnector,
        function*(connector: TimeRegistrationConnector, action: any, requestData: any): any {
            return yield SagaEffects.call([connector, connector.updateTimeRegistration], action.payload.model.id, requestData);
        }
    )
)

export default function* timeRegistrationSaga() {
    yield SagaEffects.all([
        SagaEffects.fork(getAllTimeRegistration),
        SagaEffects.fork(createTimeRegistration),
        SagaEffects.fork(updateTimeRegistration),
        SagaEffects.fork(deleteTimeRegistration),
    ]);
}

import * as SagaEffects from 'redux-saga/effects';
import * as types from '../../../constants/ActionTypes';
import * as timeRegistrationActionSelectors from '../../../selectors/actions/timeRegistration';
import * as timeRegistrationSelectors from '../../../selectors/pages/timeRegistration';
import * as writeBackQueueActions from '../../../actions/writeBackQueue';
import { ResourceObject } from '../../../lib/models';

function* doMakeChangeToCurrentEdit(action: any) {
    const change = timeRegistrationActionSelectors.getChangeToCurrentEdit(action);
    const currentEdit = (yield SagaEffects.select(timeRegistrationSelectors.currentEdit)) as {
        timeRegistrationUnderEdit: ResourceObject
    };
    yield SagaEffects.put(writeBackQueueActions.addChangeToQueue(change, currentEdit.timeRegistrationUnderEdit));
}

export default function* makeChangeToCurrentEdit() {
    yield SagaEffects.takeLatest(types.APP_PAGE_TIMEREGISTRATION_MAKE_CHANGE_TO_CURRENT_EDIT, doMakeChangeToCurrentEdit);
}

import React, { Component } from "react";
import PropTypes from "prop-types";
import { Dialog } from "@fluentui/react/lib/Dialog";
import { Spinner, SpinnerSize } from "@fluentui/react/lib/Spinner";

export default class ProcessingDialog extends Component {
  static propTypes = {
    title: PropTypes.string,
    body: PropTypes.string,
  };

  render() {
    let { hidden } = this.props;

    return (
      <Dialog
        hidden={hidden}
        dialogContentProps={{
          title: "Please wait",
          subText: "Handling the request",
        }}
      >
        <Spinner size={SpinnerSize.large} />
      </Dialog>
    );
  }
}

import React, { Component } from "react";
import PropTypes from "prop-types";
import { TagPicker, ValidationState } from "@fluentui/react/lib/Pickers";
import { Icon } from "@fluentui/react/lib/Icon";
import { PersonaPresence } from "@fluentui/react/lib/Persona";
import * as Icons from "../../constants/Icons";
import latinize from "latinize";
import * as apihelper from "../../selectors/apihelper";
import * as storehelper from "../../selectors/storehelper";
import { getInitialsForNamedAttribute } from "../../lib/getInitialsForNamedAttribute";

export default class ResourcePicker extends Component {
  constructor(props) {
    super(props);

    this._onFilterChanged = this._onFilterChanged.bind(this);
    this._onItemSelected = this._onItemSelected.bind(this);
    this._listContainsDocument = this._listContainsDocument.bind(this);
    this._onResourceFilterChange = this._onResourceFilterChange.bind(this);
    this._removeDuplicates = this._removeDuplicates.bind(this);
    this._resourceToPersona = this._resourceToPersona.bind(this);
    this._getSuggestions = this._getSuggestions.bind(this);
    this._onValidateResourceSelection =
      this._onValidateResourceSelection.bind(this);
    this._onRemoveResourceSelection =
      this._onRemoveResourceSelection.bind(this);
    this._onResourceChange = this._onResourceChange.bind(this);
  }

  static propTypes = {
    selectedResources: PropTypes.array,
    resources: PropTypes.array,
    onResourceSelected: PropTypes.func,
    users: PropTypes.array,
  };

  _removeDuplicates(resources, currentSelection) {
    let curIds = currentSelection.map((r) => r.key);
    return resources.filter(
      (r) => !curIds.find((id) => apihelper.entityHasId(r, id))
    );
  }

  _onResourceFilterChange(filterText, currentSelection) {
    let { resources } = this.props;
    let limitResults = false;
    var res = [];

    if (filterText) {
      let resourcesThatContain = resources.filter((r) =>
        latinize(apihelper.getAttr(r, "name").toLowerCase()).includes(
          filterText.toLowerCase()
        )
      );
      let filteredPersonas = this._removeDuplicates(
        resourcesThatContain,
        currentSelection
      );
      filteredPersonas = limitResults
        ? filteredPersonas.splice(0, limitResults)
        : filteredPersonas;
      res = filteredPersonas.map((r) => this._resourceToPersona(r), this);
    }
    return res;
  }

  _onFilterChanged(filterText, tagList) {
    return filterText
      ? this.props.resources
          .filter(
            (tag) =>
              apihelper
                .getAttr(tag, "name")
                .toLowerCase()
                .indexOf(filterText.toLowerCase()) === 0
          )
          .filter((tag) => !this._listContainsDocument(tag, tagList))
      : [];
  }

  _getSuggestions(currentSelection) {
    let { resources } = this.props;
    let limitResults = 100;

    let suggestions = resources
      ? resources
          .slice(0, limitResults)
          .sort(
            storehelper.sortByValues((entity) =>
              apihelper.getAttr(entity, "name").toLowerCase()
            )
          )
      : [];

    return this._removeDuplicates(suggestions, currentSelection).map((s) =>
      this._resourceToPersona(s)
    );
  }

  _onRemoveSuggestion(item) {
    //currently not working
    const { peopleList, mostRecentlyUsed: mruState } = this.state;
    const indexPeopleList = peopleList.indexOf(item);
    const indexMostRecentlyUsed = mruState.indexOf(item);

    if (indexPeopleList >= 0) {
      const newPeople = peopleList
        .slice(0, indexPeopleList)
        .concat(peopleList.slice(indexPeopleList + 1));
      this.setState({ peopleList: newPeople });
    }

    if (indexMostRecentlyUsed >= 0) {
      const newSuggestedPeople = mruState
        .slice(0, indexMostRecentlyUsed)
        .concat(mruState.slice(indexMostRecentlyUsed + 1));
      this.setState({ mostRecentlyUsed: newSuggestedPeople });
    }
  }

  _onRemoveResourceSelection() {
    //        console.log('_onRemoveResourceSelection', arguments)
  }

  _onValidateResourceSelection(input) {
    if (input.indexOf("@") !== -1) {
      return ValidationState.valid;
    } else if (input.length > 1) {
      return ValidationState.warning;
    } else {
      return ValidationState.invalid;
    }
  }

  _onResourceInputChange(input) {
    const outlookRegEx = /<.*>/g;
    const emailAddress = outlookRegEx.exec(input);

    if (emailAddress && emailAddress[0]) {
      return emailAddress[0].substring(1, emailAddress[0].length - 1);
    }

    return input;
  }

  _onResourceChange(selectedItems) {
    let { resources } = this.props;
    let selectedIds = selectedItems.map((i) => i.key);
    let selectedResources = selectedIds.map((id) =>
      resources.find((r) => apihelper.entityHasId(r, id))
    );
    this.props.onResourceSelected(selectedResources);
  }

  _onItemSelected(item) {
    if (this._listContainsDocument(item, this._picker.items)) {
      return null;
    }
    return item;
  }

  _listContainsDocument(tag, tagList) {
    if (!tagList || !tagList.length || tagList.length === 0) {
      return false;
    }
    return (
      tagList.filter((compareTag) => compareTag.key === tag.key).length > 0
    );
  }

  _resourceToPersona(r) {
    let { users } = this.props;
    let userString = "";
    let user =
      users && users.find((u) => apihelper.relRefersToEntity(r, "user", u));
    if (user) {
      if (user) {
        userString =
          apihelper.getAttr(user, "name") +
          "( " +
          apihelper.getAttr(user, "email") +
          ")";
      } else {
        userString = " User ID " + apihelper.getRelId(r, "user");
      }
    }

    return {
      key: apihelper.getEntityId(r),
      imageInitials: getInitialsForNamedAttribute(r),
      name: apihelper.getAttr(r, "name"),
      secondaryText: userString,
      presence: PersonaPresence.none,
    };
  }

  render() {
    let { selectedResources, resources } = this.props;
    let resourcePersonas = resources.map(
      (r) => this._resourceToPersona(r),
      this
    );

    const suggestionProps = {
      suggestionsHeaderText: "Suggested People",
      mostRecentlyUsedHeaderText: "Suggested Contacts",
      noResultsFoundText: "No results found",
      loadingText: "Loading",
      showRemoveButtons: true,
      suggestionsAvailableAlertText: "People Picker Suggestions available",
      suggestionsContainerAriaLabel: "Suggested contacts",
    };

    return (
      <div className="novatime-resource-picker">
        <TagPicker
          className={"ms-PeoplePicker"}
          selectedItems={
            selectedResources
              ? selectedResources.map(this._resourceToPersona, this)
              : undefined
          }
          onResolveSuggestions={this._onResourceFilterChange}
          onEmptyInputFocus={this._getSuggestions}
          getTextFromItem={(r) => r.name}
          pickerSuggestionsProps={suggestionProps}
          onRemoveSuggestion={this._onRemoveSuggestion}
          onValidateInput={this._onValidateResourceSelection}
          itemLimit={2}
          disabled={this.props.disabled}
          onInputChange={this._onInputChange}
          onSelectedItemsUpdated={this._onSelectedItemsUpdated}
          onChange={this._onResourceChange}
        />
        <Icon
          className="indicator"
          iconName={
            this.props.icon == "queued"
              ? Icons.ICON_NAME_CHANGE_QUEUED
              : undefined
          }
        />
      </div>
    );
  }
}

import {delay, put, takeLatest} from 'redux-saga/effects';
import * as types from '../../../constants/ActionTypes';
import {clearSelectedTimeRegistration} from '../../../actions/pages/timeRegistration';

function* doClearSelection() {
    yield delay(200);
    yield put(clearSelectedTimeRegistration());
}

export default function* clearSelectionDelay() {
    yield takeLatest(types.APP_PAGE_TIMEREGISTRATION_DELAYED_CLEAR_SELECTION, doClearSelection);
}

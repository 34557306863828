import * as SagaHelpers from '../helpers';
import * as SagaEffects from 'redux-saga/effects';
import * as types from '../../constants/ActionTypes';
import ResourceTypeConnector from '../../connectors/ResourceType';

const getAllResourceTypes = SagaHelpers.createApiHandler(
    types.API_RESOURCETYPE_GET_ALL_INIT, 
    SagaHelpers.generateGetAllApiCall(
        ResourceTypeConnector,
        function* (connector: ResourceTypeConnector): any {
            return yield SagaEffects.call([connector, connector.getResourceType]);
        }
    )
)

export default function* resourceTypeSaga() {
    yield SagaEffects.all([
        SagaEffects.fork(getAllResourceTypes)
    ]);
}

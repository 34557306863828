import { takeLatest, put } from "redux-saga/effects";
import * as types from "../../constants/ActionTypes";
import * as navActions from "../../actions/nav";
import * as Urls from "../../constants/Urls";
import * as authActionSelectors from "../../selectors/actions/auth";

function* doLogin(action: any) {
  const selectAccount = authActionSelectors.getSelectAccount(action);

  const url =
    Urls.SIGN_IN_RELATIVE + (selectAccount ? "?prompt=select_account" : "");
  yield put(navActions.goToUrl(url, false, true));
}

export default function* login() {
  yield takeLatest(types.AUTH_INIT_LOGIN, doLogin);
}

import {put, select, take, takeLatest} from 'redux-saga/effects';
import { v1 as uuidv1 } from 'uuid';
import moment from 'moment';
import * as types from '../../../constants/ActionTypes';
import * as authSelectors from '../../../selectors/reducers/auth';
import * as projectSelectors from '../../../selectors/reducers/project';
import * as timeRegistrationActions from '../../../actions/api/timeRegistration';
import * as clientReducers from "../../../selectors/reducers/client";
import * as resourceManagementSelectors from '../../../selectors/pages/resourceManagement';
import * as lockedTimePeriodActions from "../../../actions/api/lockedTimePeriod";
import * as absencePeriodActions from '../../../actions/api/absencePeriod';

function* doLoadRegistrations() {
    const loggedIn = yield select(authSelectors.isLoggedIn);

    if (loggedIn) {
        const projectId = yield select(resourceManagementSelectors.selectedProjectId);
        const clientId = yield select(resourceManagementSelectors.selectedClientId);
        yield select(resourceManagementSelectors.isAllProjectsSelected);
        yield select(resourceManagementSelectors.isAllClientsSelected);
        const client = clientId ? yield select(clientReducers.clientById, clientId) : undefined;
        const st = yield select(resourceManagementSelectors.startTime);
        const et = yield select(resourceManagementSelectors.endTime);
        const project = projectId ? (yield select(projectSelectors.projectById, projectId)) : undefined;

        // We only have gt and lt (not ge and le), so we need to add and subtract a day...
        const startDate = moment(st).subtract(1, 'd').toDate();
        const endDate = moment(et).add(1, 'd').toDate();

        yield put(lockedTimePeriodActions.getAllLockedTimePeriods({startDate, endDate}));
        const transactionId = uuidv1();
        yield put(timeRegistrationActions.getAllTimeRegistrations({project, client, startTime: st, endTime: et, transactionId}, ['createdByUser']));
        yield put(absencePeriodActions.getAllAbsencePeriods(startDate, null, endDate));

        // wait for time regs to come in, then refresh state merge object
        const recognizeAction = action => 
            (action.type === types.API_TIMEREGISTRATION_GET_ALL_SUCCESS ||
             action.type === types.API_TIMEREGISTRATION_GET_ALL_ERROR ||
             action.type === types.API_CALL_CANCELLED) &&
             action.payload.initAction.payload.transactionId === transactionId;

        yield take(recognizeAction);
    }
}

export default function* loadRegistrations() {
    yield takeLatest(types.APP_PAGE_RESOURCEMANAGEMENT_LOAD_REGISTRATIONS, doLoadRegistrations);
}

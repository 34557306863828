import {all, fork} from 'redux-saga/effects';
import absencePeriodOpened from './pageOpened';
import resourceSelected from './resourceSelected';
import absencePeriodSelected from './selectAbsencePeriod';
import loadAbsencePeriodComments from './loadAbsencePeriodComments';
import loadForCurrentFilter from './loadForCurrentFilter';
import absencePeriodSetYear from './setYear';

export default function* pageRootSaga() {
    yield all([
        fork(absencePeriodOpened),
        fork(absencePeriodSelected),
        fork(resourceSelected),
        fork(loadAbsencePeriodComments),
        fork(loadForCurrentFilter),
        fork(absencePeriodSetYear)
    ]);
}

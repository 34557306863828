import {put, select} from 'redux-saga/effects';
import * as types from '../../../constants/ActionTypes';
import * as reducerAccountSelectors from '../../../selectors/reducers/account';
import * as resourceActions from '../../../actions/api/resource';
import * as authReducers from '../../../selectors/reducers/auth';
import {takeLatestAfterBootstrap} from '../../helpers';
import * as projectActions from '../../../actions/api/project'
import * as reducerResourceSelectors from '../../../selectors/reducers/resource';
import * as apihelper from '../../../selectors/apihelper';
import * as pageActions from '../../../actions/pages/absencePeriods';
import * as permissionSelectors from '../../../selectors/reducers/permission';
import * as pageSelectors from '../../../selectors/pages/absencePeriods';
import * as absencePeriodActions from '../../../actions/api/absencePeriod';

function* doAbsencePeriodOpened() {
    const loggedIn = yield select(authReducers.isLoggedIn);

    if (loggedIn) {
        const currentUserPermissionSet = yield select(permissionSelectors.currentUserPermissionSet);
        const curIsAdm = currentUserPermissionSet.hasSelectedAccountRead();
        let selectedResourceId = yield select(pageSelectors.selectedResourceId);
        let curUserAcct = yield select(reducerAccountSelectors.currentUserAccount);
        let acct = curUserAcct;

        if(!selectedResourceId && !curIsAdm){
            let selectedResource = yield select(reducerResourceSelectors.resourceById, selectedResourceId);
            let accountForSelectedResource = yield select(reducerAccountSelectors.accountByResource, selectedResource);
            let resource = yield select(reducerResourceSelectors.currentUserResource);
            yield put(pageActions.resourceSelected(resource));
            acct = accountForSelectedResource;
        }
        else if(curIsAdm){
            yield put(pageActions.resourceSelected(null));
        }

        const {startDate, endDate} = yield select(pageSelectors.vacationStartAndEndDate);
        yield put(absencePeriodActions.getAllAbsencePeriods(startDate, selectedResourceId || null, endDate));
    }
}

export default function* absencePeriodOpened() {
    yield takeLatestAfterBootstrap(types.APP_PAGE_ABSENCEPERIODS_OPENED, doAbsencePeriodOpened);
}

import React, { FunctionComponent, PropsWithChildren } from "react";
import { Text } from "@fluentui/react";

export type ErrorTextProps = Record<string, string>;

export default function ErrorText({ children }: ErrorTextProps) {
  return (
    <Text variant="medium" style={{ color: "red" }} block>
      {children}
    </Text>
  );
}

import React from "react";
import { PrimaryButton, DefaultButton, Stack } from "@fluentui/react";

type FormButtonBarProps = {
  isSaveDisabled: boolean;
  onSave: () => void;
  onDismiss?: () => void;
};

export function FormButtonBar({
  isSaveDisabled,
  onSave,
  onDismiss,
}: FormButtonBarProps) {
  return (
    <div className="novatime-form-buttonbar">
      <Stack
        enableScopedSelectors
        horizontal
        disableShrink
        tokens={{
          childrenGap: 10,
        }}
      >
        <PrimaryButton disabled={isSaveDisabled} onClick={onSave}>
          Save
        </PrimaryButton>
        <DefaultButton onClick={onDismiss}>Cancel</DefaultButton>
      </Stack>
      <div className="height3em ms-hiddenLgUp" />
    </div>
  );
}

import * as SagaHelpers from '../helpers';
import * as SagaEffects from 'redux-saga/effects';
import * as types from '../../constants/ActionTypes';
import ProjectConnector from '../../connectors/Project';

const createProject = SagaHelpers.createApiHandler(
    types.API_PROJECT_CREATE_INIT, 
    SagaHelpers.generateCreateApiCall(
        ProjectConnector,
        function*(connector: ProjectConnector, action: any): any {
            return yield SagaEffects.call([connector, connector.createProject], {
                data: action.payload.model
            });
        }
    )
)

const deleteProject = SagaHelpers.createApiHandler(
    types.API_PROJECT_DELETE_INIT, 
    SagaHelpers.generateDeleteApiCall(
        ProjectConnector,
        function* (connector: ProjectConnector, action: any): any {
            const selectedProject = action.payload.model.id;
            return yield SagaEffects.call([connector, connector.deleteProject], selectedProject);
        }
    )
)

const getProject = SagaHelpers.createApiHandler(
    types.API_PROJECT_GET_INIT, 
    SagaHelpers.generateGetAllApiCall(
        ProjectConnector,
        function*(connector: ProjectConnector, action: any): any {
            const { projectId } = action.payload;
            const includes = action.payload.includes ? action.payload.includes : null;
            return yield SagaEffects.call([connector, connector.getProject], projectId, includes);
        }
    )
)

const getAllProjects = SagaHelpers.createApiHandler(
    types.API_PROJECT_GET_ALL_INIT, 
    SagaHelpers.generateGetAllApiCall(
        ProjectConnector,
        function*(connector: ProjectConnector, action: any): any {
            const byAccountId = action.payload.account ? action.payload.account.id : null;
            const andClientId = action.payload.client ? action.payload.client.id : null;
            const includes = action.payload.includes ? action.payload.includes : null;
            return yield SagaEffects.call([connector, connector.getProjects], byAccountId, andClientId, includes);
        }
    )
)

const getAllProjectsForPermission = SagaHelpers.createApiHandler(
    types.API_PROJECT_GET_ALL_FOR_PERMISSION_INIT, 
    SagaHelpers.generateGetAllApiCall(
        ProjectConnector,
        function* (connector: ProjectConnector, action: any): any {
            const account = action.payload.account ? action.payload.account.id : null;
            const includes = action.payload.includes ? action.payload.includes : null;
            return yield SagaEffects.call([connector, connector.getProjectsForPermission], account, includes);
        }
    )
)

const updateProject = SagaHelpers.createApiHandler(
    types.API_PROJECT_UPDATE_INIT, 
    SagaHelpers.generateUpdateApiCall(
        ProjectConnector,
        function*(connector: ProjectConnector, action: any, requestData: any): any {
            return yield SagaEffects.call([connector, connector.updateProject], action.payload.model.id, requestData);
        }
    )
)

export default function* projectSaga() {
    yield SagaEffects.all([
        SagaEffects.fork(getAllProjectsForPermission),
        SagaEffects.fork(getAllProjects),
        SagaEffects.fork(updateProject),
        SagaEffects.fork(createProject),
        SagaEffects.fork(deleteProject),
        SagaEffects.fork(getProject),
    ]);
}

import * as types from "../constants/ActionTypes";

export function fetchInitialData() {
    return {
        type: types.APP_FETCHINITIALDATA_INIT
    };
}

export function bootstrap() {
    return {
        type: types.APP_BOOTSTRAP_INIT
    };
}

import 'regenerator-runtime/runtime'
import {all, fork} from 'redux-saga/effects';
import navigate from './nav/navigate';
import api from './api';
import pages from './pages';
import auth from './auth';
import writeBackQueue from './writeBackQueue';
import tracking from "./tracking";
import app from "./app";
import editor from './EntityEditor';

export default function* rootSaga() {
    yield all([
        fork(navigate),
        fork(api),
        fork(pages),
        fork(auth),
        fork(writeBackQueue),
        fork(tracking),
        fork(app),
        fork(editor)
    ]);
}

import {createSelector} from "reselect";
import * as AbsencePeriodStatusName from '../../constants/AbsencePeriodStatusNames';
import { ResourceObject, ResourceObjects } from "../../lib/models";
import * as storehelper from '../storehelper';

export const allAbsencePeriodStatus = (state: any) => state.api.absencePeriodStatus.allAbsencePeriodStatus;

export const filterByName = (aps: ResourceObjects, name: string) =>
    storehelper.findByAttr(aps, 'name', name) as ResourceObject | undefined;

export const absencePeriodStatusPending = createSelector(
    [ allAbsencePeriodStatus ],
    (allAbsencePeriodStatus) => filterByName(allAbsencePeriodStatus, AbsencePeriodStatusName.PENDING)
)

export const absencePeriodStatusApproved = createSelector(
    [ allAbsencePeriodStatus ],
    (allAbsencePeriodStatus) => filterByName(allAbsencePeriodStatus, AbsencePeriodStatusName.APPROVED)
)

export const absencePeriodStatusDenied = createSelector(
    [ allAbsencePeriodStatus ],
    (allAbsencePeriodStatus) => filterByName(allAbsencePeriodStatus, AbsencePeriodStatusName.DENIED)
)

import {all, fork} from 'redux-saga/effects';
import clientsAndContractsOpened from './pageOpened';
import clientsAndContractsLoadClients from './loadClients';
import clientsAndContractsClientSelected from './clientSelected';
import clientsAndContractsContractSelected from './contractSelected';
import fetchClientsOnAccountSelect from './fetchClients'
import clientDeleted from './clientDeleted';
import contractDeleted from './contractDeleted';

export default function* pageClientsAndContractsRootSaga() {
    yield all([
        fork(clientsAndContractsOpened),
        fork(clientsAndContractsLoadClients),
        fork(clientsAndContractsClientSelected),
        fork(clientsAndContractsContractSelected),
        fork(fetchClientsOnAccountSelect),
        fork(clientDeleted),
        fork(contractDeleted)
    ]);
}

import {put, select, takeLatest} from 'redux-saga/effects';
import * as types from '../../../constants/ActionTypes';
import {allProjectsByClient} from '../../../selectors/reducers/project';
import {getAllContracts} from '../../../actions/api/contract';
//import {selectedProjectId} from '../../../selectors/pages/projectsAndRoles';
import * as projectsAndRolesSelectors from '../../../selectors/pages/projectsAndRoles';
import {selectProject} from '../../../actions/pages/projectsAndRoles';
import * as sagahelpers from '../../helpers';

function* doClientSelected(action: any): any {
    if(action.payload !== null){
        yield sagahelpers.callAndAwait(getAllContracts(action.payload, 'projects'));
    }
    // select project if none selected
    const projectId = yield select(projectsAndRolesSelectors.selectedProjectId);
    if (!projectId) {
        // dont take client from payload, take from store
        const clientId = action.payload;
        const projectsByClient = yield select(allProjectsByClient, clientId);
        if (projectsByClient[0]) {
            yield put(selectProject(projectsByClient[0]));
        }
    }
}

export default function* clientSelected() {
    yield takeLatest(types.APP_PAGE_PROJECTS_AND_ROLES_CLIENT_SELECTED, doClientSelected);
}
import { ResourceObject } from "../../lib/models";
import * as datehelper from "../../lib/date";
import * as apihelper from "../../selectors/apihelper";
import React from "react";
import { Text } from "@fluentui/react";

type ModelTimestampsProps = {
  model: ResourceObject;
};

export function ModelTimestamps({ model }: ModelTimestampsProps) {
  const modifiedDate = datehelper.toYyyyMmDdHhMmSs(
    new Date(apihelper.getAttr(model, "modifiedDate") as string)
  );
  const addedDate = datehelper.toYyyyMmDdHhMmSs(
    new Date(apihelper.getAttr(model, "addedDate") as string)
  );
  const id = apihelper.getEntityId(model);
  return (
    <div className="novatime-form-modeltimestamps">
      <Text block>
        {id ? (
          <>
            Modified: {modifiedDate}, Added: {addedDate}, Id: {id}
          </>
        ) : (
          <>Not saved yet</>
        )}
      </Text>
    </div>
  );
}

import * as types from "../../constants/ActionTypes";
import {removeItemFromList, updateDtoList} from "../helpers";
import {getAllAbsenceRegistrationProjects, getDeleteAbsenceRegistrationProjectId, getUpsertAbsenceRegistrationProject} from "../../selectors/actions/absenceRegistrationProject";

const initialState = {
    storeRevision: 0,
    allAbsenceRegistrationProjects: []
};

export default function absenceRegistrationProjectReducer(
    state = initialState,
    action
    ) {

    switch (action.type) {
        case types.API_ABSENCEREGISTRATIONPROJECT_GET_ALL_SUCCESS:
            return updateDtoList(state, "allAbsenceRegistrationProjects", getAllAbsenceRegistrationProjects(action));

        case types.API_ABSENCEREGISTRATIONPROJECT_CREATE_SUCCESS:
            return updateDtoList(state, 'allAbsenceRegistrationProjects', [getUpsertAbsenceRegistrationProject(action)]);

        case types.API_ABSENCEREGISTRATIONPROJECT_UPDATE_SUCCESS:
            return updateDtoList(state, 'allAbsenceRegistrationProjects', [getUpsertAbsenceRegistrationProject(action)]);

        case types.API_ABSENCEREGISTRATIONPROJECT_DELETE_SUCCESS: 
            return removeItemFromList(state, 'allAbsenceRegistrationProjects', [getDeleteAbsenceRegistrationProjectId(action)]);

        default:
            return state;
    }
}

import * as types from '../../constants/ActionTypes';
import { ResourceObject } from '../../lib/models';

export function pageOpened() {
    return {
        type: types.APP_PAGE_ABSENCEPERIODS_OPENED
    };
}

export function selectAbsencePeriod(absencePeriodId: string) {
    return {
        type: types.APP_PAGE_ABSENCEPERIODS_SELECTED,
        payload: {
        	id: absencePeriodId
        }
    };
}

export function resourceSelected(resource: ResourceObject) {
    return {
        type: types.APP_PAGE_ABSENCEPERIODS_RESOURCE_SELECTED,
        payload: resource
    };
}

export function loadComments(absencePeriodId: string){
    return {
        type: types.APP_PAGE_ABSENCEPERIODS_LOAD_COMMENTS,
        payload: { id: absencePeriodId }
    }
}

export function setSelectedYear(year: number){
    return {
        type: types.APP_PAGE_ABSENCEPERIODS_SET_YEAR,
        payload: { year }
    };
}

export function loadForCurrentFilter(){
    return {
        type: types.APP_PAGE_ABSENCEPERIODS_LOAD_CURRENT
    }
}

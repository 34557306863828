import React, { Component } from 'react';

type HorizontalRuleProps = {
    className?: string,
    text: string,
    alignment: string
}

const HorizontalRule = ({className, text, alignment}: HorizontalRuleProps) => {
    className = (className || '') + ' novatime-horizontal-rule';
    if(alignment){
        className += ' text-align-' + alignment;
    }

    return (
        <hr className={className} data-content={text} />
    );
}

export default HorizontalRule;

import * as SagaHelpers from '../helpers';
import * as SagaEffects from 'redux-saga/effects';
import * as types from '../../constants/ActionTypes';
import AbsencePeriodStatusConnector from '../../connectors/AbsencePeriodStatus';

const getAllAbsencePeriodStatus = SagaHelpers.createApiHandler(
    types.API_ABSENCEPERIODSTATUS_GET_ALL_INIT, 
    SagaHelpers.generateGetAllApiCall(
        AbsencePeriodStatusConnector, 
        function*(connector: AbsencePeriodStatusConnector): any {
            return yield SagaEffects.call([connector, connector.getAbsencePeriodStatus]);
        }
    )
)

export default function* absencePeriodStatusSaga() {
    yield SagaEffects.all([
        SagaEffects.fork(getAllAbsencePeriodStatus)
    ]);
}

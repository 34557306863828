import React, { Component } from "react";
import {
  Persona,
  PersonaSize,
  PersonaPresence,
} from "@fluentui/react/lib/Persona";
import {
  TooltipHost,
  TooltipOverflowMode,
  DirectionalHint,
} from "@fluentui/react";
import { getAttr } from "../../selectors/apihelper";
import * as InternalPropTypes from "../../constants/PropTypes";
import { getInitialsForNamedAttribute } from "../../lib/getInitialsForNamedAttribute";

export class User extends Component {
  static propTypes = {
    model: InternalPropTypes.userEntity,
  };

  render() {
    let { responsive, model, ...other } = this.props;

    let imageInitials = undefined;
    let name = model ? getAttr(model, "name") : "";
    if (model) {
      imageInitials = getInitialsForNamedAttribute(model);
    }

    const base = {
      //            imageUrl: TestImages.personaFemale,
      imageInitials,
      text: model ? name : undefined,
      secondaryText: "secondary text",
      tertiaryText: "teriary text",
      optionalText: "optional text",
      presence:
        !model || getAttr(model, "active")
          ? PersonaPresence.none
          : PersonaPresence.blocked,
    };

    return model ? (
      <Persona
        {...base}
        {...other}
        onRenderPrimaryText={
          responsive
            ? (props) => {
                return (
                  <TooltipHost
                    content={props.text}
                    overflowMode={TooltipOverflowMode.Parent}
                    directionalHint={DirectionalHint.topLeftEdge}
                  >
                    <span className="ms-hiddenLgDown">{props.text}</span>
                  </TooltipHost>
                );
              }
            : undefined
        }
        className={responsive ? "novatime-responsive-persona" : undefined}
        size={PersonaSize.size24}
        hidePersonaDetails={false}
      />
    ) : null;
  }
}

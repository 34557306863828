import * as SagaHelpers from '../helpers';
import * as SagaEffects from 'redux-saga/effects';
import * as types from '../../constants/ActionTypes';
import BillabilityTypesConnector from '../../connectors/BillabilityTypes';

const getAllBillabilityType = SagaHelpers.createApiHandler(
    types.API_BILLABILITYTYPE_GET_ALL_INIT, 
    SagaHelpers.generateGetAllApiCall(
        BillabilityTypesConnector, 
        function*(connector: BillabilityTypesConnector): any {
            return yield SagaEffects.call([connector, connector.getBillabilityTypes]);
        }
    )
)

export default function* billabilityTypesSaga() {
    yield SagaEffects.all([
        SagaEffects.fork(getAllBillabilityType)
    ]);
}

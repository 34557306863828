import * as SagaHelpers from '../helpers';
import * as SagaEffects from 'redux-saga/effects';
import * as types from '../../constants/ActionTypes';
import ResourceConnector from '../../connectors/Resource';

const createResource = SagaHelpers.createApiHandler(
    types.API_RESOURCE_CREATE_INIT, 
    SagaHelpers.generateCreateApiCall(
        ResourceConnector,
        function*(connector: ResourceConnector, action: any): any {
            return yield SagaEffects.call([connector, connector.createResource], {
                data: action.payload.model
            });
        }
    )
)

const deleteResource = SagaHelpers.createApiHandler(
    types.API_RESOURCE_DELETE_INIT, 
    SagaHelpers.generateDeleteApiCall(
        ResourceConnector,
        function*(connector: ResourceConnector, action: any): any {
            return yield SagaEffects.call([connector, connector.deleteResource], action.payload.model.id);
        }
    )
)

const getAllResources = SagaHelpers.createApiHandler(
    types.API_RESOURCE_GET_ALL_INIT, 
    SagaHelpers.generateGetAllApiCall(
        ResourceConnector,
        function*(connector: ResourceConnector, action: any): any {
            const accountId = action.payload.account ? action.payload.account.id : null;
            return yield SagaEffects.call([connector, connector.getResourcesByAccountId], accountId);
        }
    )
)

const updateResources = SagaHelpers.createApiHandler(
    types.API_RESOURCE_UPDATE_INIT, 
    SagaHelpers.generateUpdateApiCall(
        ResourceConnector,
        function*(connector: ResourceConnector, action: any, requestData: any): any {
            return yield SagaEffects.call([connector, connector.updateResource], action.payload.model.id, requestData);
        }
    )
)

export default function* resourceSaga() {
    yield SagaEffects.all([
        SagaEffects.fork(getAllResources),
        SagaEffects.fork(createResource),
        SagaEffects.fork(deleteResource),
        SagaEffects.fork(updateResources),
    ]);
}

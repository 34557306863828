import { createSelector } from "reselect";
import * as reducerClientSelectors from "../reducers/client";
import * as reducerContractSelectors from "../reducers/contract";
import * as reducerContractRoleSelectors from "../reducers/contractRole";
import * as reducerProjectSelectors from "../reducers/project";
import * as appDomainSelectors from '../app/domain';
import * as apihelper from '../apihelper';
import * as storehelper from '../storehelper';
import * as reducerProjectResourceSelectors from "../reducers/projectResource";
import '../../lib/flatten';

export const selectedClientId: (state: any) => null | string = (state: any) =>
    state.page.projectsAndRoles.selectedClientId;

export const selectedProjectResourceId: (state: any) => null | string = (state: any) =>
    state.page.projectsAndRoles.selectedProjectResourceId;

export const selectedProjectId: (state: any) => null | string = (state: any) =>
    state.page.projectsAndRoles.selectedProjectId;

export const allClientsForSelectedAccount = createSelector(
    [reducerClientSelectors.allClients, appDomainSelectors.selectedAccount],
    (allClients, selectedAccount) => 
        allClients.filter(c => selectedAccount && apihelper.relRefersToEntity(c, 'account', selectedAccount))
                  .sort(storehelper.sortByAttr('name'))
)

export const selectedClient = createSelector(
    [selectedClientId, reducerClientSelectors.allClients],
    (clientId, allClients) => allClients.find(c => clientId && apihelper.entityHasId(c, clientId))
)

export const allContractsBySelectedClient = createSelector(
    [reducerContractSelectors.allContracts, selectedClient],
    (allContracts, selectedClient) => 
        storehelper.filterByReferenceToRelatedEntities(allContracts, selectedClient, 'client').flatten()
)

export const allContractsForSelectedClient = createSelector(
    [reducerContractSelectors.allContracts, selectedClient],
    (allContracts, selectedClient) =>  storehelper.filterByReferenceToRelatedEntities(allContracts, selectedClient, 'client').flatten()
)

export const allContractRolesForSelectedClient = createSelector(
    [allContractsBySelectedClient, reducerContractRoleSelectors.allContractRoles],
    (contratsByClient, allContractRoles) => storehelper.filterByReferenceToRelatedEntities(allContractRoles, contratsByClient, 'contract').flatten()
)

export const allProjectsBySelectedClient = createSelector(
    [allContractsForSelectedClient, reducerProjectSelectors.allProjects],
    (contratsByClient, allProjects) => storehelper.filterByReferenceToRelatedEntities(allProjects, contratsByClient, "contract").flatten().sort(storehelper.sortByAttr('name'))
)

export const allProjectsForSelectedClient = createSelector(
    [allContractsForSelectedClient, reducerProjectSelectors.allProjects],
    (contratsByClient, allProjects) => storehelper.filterByReferenceToRelatedEntities(allProjects, contratsByClient, "contract").flatten().sort(storehelper.sortByAttr('name'))
)

export const selectedProject = createSelector(
    [selectedProjectId,  reducerProjectSelectors.allProjects],
    (projectId, allProjects) => allProjects.find(project => apihelper.entityHasId(project, projectId as string))
)

export const allProjectResourcesForSelectedProject = createSelector(
    [reducerProjectResourceSelectors.allProjectResources, selectedProject],
    (projectResources, project) => storehelper.filterByReferenceToRelatedEntities(projectResources, project, "project").flatten()
)

export const allContractRoleIdsForAllProjectResources = createSelector(
    [allProjectResourcesForSelectedProject],
    (projectResources) => 
        projectResources.map(pr => apihelper.getRelId(pr, 'contractRole')).filter(id => !!id).unique() as string[]
)

export const allContractRolesForAllProjectResources = createSelector(
    [allContractRoleIdsForAllProjectResources, reducerContractRoleSelectors.allContractRoles],
    (contractRoleIds, allContractRoles) =>
        storehelper.filterEntitiesByIds(allContractRoles, contractRoleIds)    
)

export const projectResourceIdToContractRoleMap = createSelector(
    [allProjectResourcesForSelectedProject, allContractRolesForAllProjectResources],
    (projectResources, contractRoles) => projectResources
    .reduce((map, projectResource) => {
        map[apihelper.getEntityId(projectResource) as string] = contractRoles.find(cr => apihelper.relRefersToEntity(projectResource, 'contractRole', cr));
        return map;
    }, {})
)

export const selectedProjectResource = createSelector(
    [reducerProjectResourceSelectors.allProjectResources, selectedProjectResourceId],
    (projectResources, selectedProjectResourceId) =>
        projectResources.find(pr => apihelper.entityHasId(pr, selectedProjectResourceId as string))
)

import {put, takeLatest} from 'redux-saga/effects';
import * as types from '../../../constants/ActionTypes';
import * as pageActions from '../../../actions/pages/absencePeriods';

function* doAbsencePeriodSelected(action) {
    yield put(pageActions.loadComments(action.payload.id));
}

export default function* absencePeriodSelected() {
    yield takeLatest(types.APP_PAGE_ABSENCEPERIODS_SELECTED, doAbsencePeriodSelected);
}

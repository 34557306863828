import { ResourceObjects } from "../../lib/models";
import * as apihelper from "../apihelper";

export const filterByPeriod = (
  wlRegs: ResourceObjects,
  startTime: Date,
  endTime: Date
): ResourceObjects =>
  wlRegs.filter(
    (wlr) =>
      (!startTime ||
        new Date(apihelper.getAttr(wlr, "startTime") as string) >= startTime) &&
      (!endTime ||
        new Date(apihelper.getAttr(wlr, "endTime") as string) <= endTime)
  );

export const allWorkLocationRegistrations = (state: any) =>
  state.api.workLocationRegistration
    .allWorkLocationRegistrations as ResourceObjects;

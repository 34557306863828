import * as types from '../../constants/ActionTypes';
import {updateDtoList} from '../helpers';
import {
    getAllAbsencePeriodComment,
    getIncludedAbsencePeriodComments,
    getUpsertAbsencePeriodComment
} from '../../selectors/actions/absencePeriodComment';

const initialState = {
    storeRevision: 0,
    allAbsencePeriodComments: []
};

export default function absencePeriodCommentReducer(state = initialState, action) {
    switch (action.type) {
        case types.API_ABSENCEPERIODCOMMENT_GET_ALL_SUCCESS: {
            return updateDtoList(state, 'allAbsencePeriodComments', getAllAbsencePeriodComment(action));
        }

        case types.API_ABSENCEPERIODCOMMENT_CREATE_SUCCESS: {
            return updateDtoList(state, 'allAbsencePeriodComments', [getUpsertAbsencePeriodComment(action)]);
        }

        case types.API_ABSENCEPERIOD_CREATE_SUCCESS:
        case types.API_ABSENCEPERIOD_UPDATE_SUCCESS:
            let comments = getIncludedAbsencePeriodComments(action);
            return updateDtoList(state, 'allAbsencePeriodComments', comments);

        default:
            return state;
    }
}

import {call, put, select, takeLatest} from 'redux-saga/effects';
import * as types from '../../constants/ActionTypes';
import * as userActions from '../../actions/api/user';
import * as sagaHelpers from '../helpers';
import * as userActionSelectors from '../../selectors/actions/user';
import * as authActions from '../../actions/auth';
import * as notificationActions from '../../actions/app/notification';

function* doInitAuth() {

    // start the check to clarify login status
    yield put({ type: types.AUTH_CHECK });

    // see if we can fetch the users profile 
    const meCall = yield sagaHelpers.callAndAwait(userActions.getMe());
    let user = null;
    let error = null;
    if(meCall.success){
        user = userActionSelectors.getMeUser(meCall.success);
    }
    else {
        // this was an error, check up if this is an 401 - i.e. a rejection because of missing login
        const unauthorized = meCall.error.payload.response.status === 401;
        if(unauthorized){
            // we conclude that the user is not logged in and just remove the api notification
            yield put(notificationActions.dismissAll({transactionId: meCall.error.payload.transactionId}));
        }
        else {
            // some other error happened, be sure to pass it, if present
            error = meCall.error.payload.error;
        }
    }

    // finish the check
    yield put(authActions.loginComplete(user));
}

export default function* initauth() {
    yield takeLatest(types.AUTH_INIT, doInitAuth);
}

import * as types from "../../constants/ActionTypes";
import moment from "moment";
import * as localStorage from "../../constants/LocalStorageNames";
import ls from "local-storage";
import { entityHasId, getEntityId } from "../../selectors/apihelper";

const initialState = {
  filter: {
    selectedResource: null,
    startTime: null,
    endTime: null,
  },
  currentEdit: {
    timeRegistrationUnderEdit: null,
  },
  liveTimeRegistrationRequests: [],
  savedProjectIds: [],
  dialogHeader: null,
  dialogMessage: null,
  dialogCallback: undefined,
  lastWeekLockedCheckComplete: false,
};

function updateTimeRegistrationUnderEdit(
  state,
  timeRegistrationUnderEdit,
  isUnderDelete = false
) {
  return Object.assign({}, state, {
    currentEdit: {
      timeRegistrationUnderEdit,
      isUnderDelete,
    },
  });
}

function resetCurrentEdit(state) {
  return Object.assign({}, state, {
    currentEdit: {
      timeRegistrationUnderEdit: null,
    },
  });
}

export default function timeRegistration(state = initialState, action) {
  switch (action.type) {
    case types.WRITEBACK_QUEUE_ADD_CHANGE_TO_QUEUE_DONE: {
      let { original, mergedBefore, mergedAfter, change } = action.payload;
      return mergedBefore == state.currentEdit.timeRegistrationUnderEdit
        ? updateTimeRegistrationUnderEdit(state, mergedAfter)
        : state;
    }

    case types.WRITEBACK_QUEUE_WRITEBACK_COMPLETE: {
      let { success, newMergedModel, oldMergedModel } = action.payload;
      return success &&
        oldMergedModel == state.currentEdit.timeRegistrationUnderEdit
        ? updateTimeRegistrationUnderEdit(state, newMergedModel)
        : state;
    }

    case types.API_TIMEREGISTRATION_DELETE_INIT: {
      return action.payload.model == state.currentEdit.timeRegistrationUnderEdit
        ? updateTimeRegistrationUnderEdit(
            state,
            state.currentEdit.timeRegistrationUnderEdit,
            true
          )
        : state;
    }

    case types.APP_BOOTSTRAP_STARTED: {
      let savedIds = ls(localStorage.SAVED_PROJECTS);
      let listIds = savedIds ? savedIds.split(",") : [];
      return Object.assign({}, state, { savedProjectIds: listIds });
    }

    case types.APP_PAGE_TIMEREGISTRATION_ADD_SAVED_PROJECT: {
      let newIds = (state.savedProjectIds || []).concat(
        action.payload.projects.map(getEntityId)
      );
      ls(localStorage.SAVED_PROJECTS, newIds.join(","));
      return Object.assign({}, state, { savedProjectIds: newIds });
    }

    case types.APP_PAGE_TIMEREGISTRATION_REMOVE_SAVED_PROJECT: {
      let projectIds = action.payload.projects.map(getEntityId);
      let newIds = state.savedProjectIds.filter(
        (pId) => !projectIds.includes(pId)
      );
      ls(localStorage.SAVED_PROJECTS, newIds.join(","));
      return Object.assign({}, state, { savedProjectIds: newIds });
    }

    case types.APP_PAGE_TIMEREGISTRATION_RESOURCE_SELECTED: {
      return Object.assign(resetCurrentEdit(state), {
        filter: Object.assign({}, state.filter, {
          selectedResource: action.payload ? action.payload : null,
        }),
      });
    }

    case types.APP_PAGE_TIMEREGISTRATION_CLEAR_SELECTED_TIME_REGISTRATION: {
      return resetCurrentEdit(state);
    }

    case types.APP_PAGE_TIMEREGISTRATION_WEEK_SELECTED: {
      return Object.assign({}, state, {
        filter: Object.assign({}, state.filter, {
          startTime: action.payload.firstDayOfWeek
            ? moment(action.payload.firstDayOfWeek)
                .startOf("isoWeek")
                .toDate()
                .toISOString()
            : null,
          endTime: action.payload.firstDayOfWeek
            ? moment(action.payload.firstDayOfWeek)
                .endOf("isoWeek")
                .toDate()
                .toISOString()
            : null,
        }),
      });
    }

    case types.APP_PAGE_TIMEREGISTRATION_OPEN_DIALOG: {
      return Object.assign({}, state, {
        dialogHeader: action.payload.header || null,
        dialogMessage: action.payload.message || null,
        dialogCallback: action.payload.callback || undefined,
      });
    }

    case types.API_LOCKEDTIMEPERIOD_CREATE_SUCCESS:
    case types.API_LOCKEDTIMEPERIOD_CREATE_ERROR:
    case types.APP_PAGE_TIMEREGISTRATION_CLOSE_DIALOG: {
      return Object.assign({}, state, {
        dialogHeader: null,
        dialogMessage: null,
        dialogCallback: undefined,
      });
    }

    case types.APP_PAGE_TIMEREGISTRATION_LOCK_PERIOD: {
      return Object.assign({}, state, {
        dialogHeader: "Locking time period",
        dialogMessage: "Please wait...",
        dialogCallback: undefined,
      });
    }

    case types.API_TIMEREGISTRATION_DELETE_SUCCESS: {
      let deletedTimeregIsUnderEdit =
        state.currentEdit.timeRegistrationUnderEdit &&
        entityHasId(
          state.currentEdit.timeRegistrationUnderEdit,
          action.payload.initAction.payload.model.id
        );
      return deletedTimeregIsUnderEdit
        ? updateTimeRegistrationUnderEdit(state, null)
        : state;
    }

    case types.APP_PAGE_TIMEREGISTRATION_BEGIN_EDIT_TIME_REGISTRATION: {
      return updateTimeRegistrationUnderEdit(
        state,
        action.payload.registration
      );
    }

    case types.APP_PAGE_TIMEREGISTRATION_CHECK_LOCKED_LAST_WEEK_COMPLETE: {
      return Object.assign({}, state, {
        lastWeekLockedCheckComplete: true,
      });
    }

    default:
      return state;
  }
}

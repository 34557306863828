import * as types from '../../constants/ActionTypes';
import {updateDtoList} from '../helpers';
import {getAllResourceType} from '../../selectors/actions/resourceType';

const initialState = {
    storeRevision: 0,
    allResourceType: []
};

export default function resourceTypeReducer(state = initialState, action) {
    switch (action.type) {

        case types.API_RESOURCETYPE_GET_ALL_SUCCESS: {
            return updateDtoList(state, 'allResourceType', getAllResourceType(action));
        }

        default:
            return state;
    }
}

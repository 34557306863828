import React, { Component } from "react";
import PropTypes from "prop-types";
import {
  NormalPeoplePicker,
  ValidationState,
} from "@fluentui/react/lib/Pickers";
import { Icon } from "@fluentui/react/lib/Icon";
import { Label } from "@fluentui/react/lib/Label";
import { PersonaPresence } from "@fluentui/react/lib/Persona";
import * as Icons from "../../constants/Icons";
import latinize from "latinize";
import {
  relRefersToEntity,
  getAttr,
  getRelId,
  getEntityId,
  entityHasId,
} from "../../selectors/apihelper";
import * as InternalPropTypes from "../../constants/PropTypes";
import { getInitialsForNamedAttribute } from "../../lib/getInitialsForNamedAttribute";

export default class ResourcePicker extends Component {
  constructor(props) {
    super(props);

    this._getMostRecentlyUsedResources =
      this._getMostRecentlyUsedResources.bind(this);
    this._getTextFromResourceItem = this._getTextFromResourceItem.bind(this);
    this._onRemoveResourceSelection =
      this._onRemoveResourceSelection.bind(this);
    this._onValidateResourceSelection =
      this._onValidateResourceSelection.bind(this);
    this._onResourceInputChange = this._onResourceInputChange.bind(this);
    this._onResourceFilterChange = this._onResourceFilterChange.bind(this);
    this._resourceToPersona = this._resourceToPersona.bind(this);
    this._onResourceChange = this._onResourceChange.bind(this);
    this._getSuggestions = this._getSuggestions.bind(this);
  }

  static propTypes = {
    selectedResources: InternalPropTypes.singleOrArrayOfResourceEntities,
    resources: InternalPropTypes.resourceEntities,
    onResourceSelected: PropTypes.func,
    users: InternalPropTypes.userEntities,
  };

  static defaultProps = {
    users: [],
  };

  _removeDuplicates(resources, currentSelection) {
    let curIds = currentSelection.map((r) => r.key);
    return resources.filter((r) => !curIds.find((id) => entityHasId(r, id)));
  }

  _onResourceFilterChange(filterText, currentSelection) {
    let { resources, users } = this.props;
    let limitResults = false;
    let selectedResources = Array.isArray(resources) ? resources : [resources];
    filterText = latinize(filterText).toLowerCase();

    if (filterText) {
      selectedResources = resources.filter((r) => {
        let initials = getInitialsForNamedAttribute(r).join("").toLowerCase();
        let splitName = latinize(getAttr(r, "name")).toLowerCase().split(" ");
        return (
          splitName.some((ss) => ss.startsWith(filterText)) ||
          initials.startsWith(filterText)
        );
      });
    } else {
      // Looks like this doesn't cause the suggestions to show, despite being given the correct data
      return resources.map((r) => this._resourceToPersona(r), this);
    }

    let filteredPersonas = this._removeDuplicates(
      selectedResources,
      currentSelection
    );
    filteredPersonas = limitResults
      ? filteredPersonas.splice(0, limitResults)
      : filteredPersonas;
    filteredPersonas.reverse();
    return filteredPersonas.map((r) => this._resourceToPersona(r), this);
  }

  _getMostRecentlyUsedResources() {
    return [];
    //TODO: Figure out if this function is needed
    /*let { mostRecentlyUsed } = this.state;
        mostRecentlyUsed = this._removeDuplicates(mostRecentlyUsed, currentPersonas);
        return this._filterPromise(mostRecentlyUsed);*/
  }

  _getSuggestions() {
    //Set current user's resource and other randomly selected resources to show as suggestions
    let { resources } = this.props;
    let suggestions = [];
    while (
      suggestions.length < resources.length - 1 &&
      suggestions.length !== resources.length
    ) {
      let resourceToAdd =
        resources[Math.floor(Math.random() * (resources.length - 1)) + 1];
      if (!suggestions.map(getEntityId).includes(getEntityId(resourceToAdd))) {
        suggestions.push(resourceToAdd);
      }
    }
    return suggestions.map((s) => this._resourceToPersona(s));
  }

  _getTextFromResourceItem() {
    console.log("_getTextFromResourceItem");
  }

  _onRemoveSuggestion(item) {
    const { peopleList, mostRecentlyUsed: mruState } = this.state;
    const indexPeopleList = peopleList.indexOf(item);
    const indexMostRecentlyUsed = mruState.indexOf(item);

    if (indexPeopleList >= 0) {
      const newPeople = peopleList
        .slice(0, indexPeopleList)
        .concat(peopleList.slice(indexPeopleList + 1));
      this.setState({ peopleList: newPeople });
    }

    if (indexMostRecentlyUsed >= 0) {
      const newSuggestedPeople = mruState
        .slice(0, indexMostRecentlyUsed)
        .concat(mruState.slice(indexMostRecentlyUsed + 1));
      this.setState({ mostRecentlyUsed: newSuggestedPeople });
    }
  }

  _onRemoveResourceSelection() {
    console.log("_onRemoveResourceSelection", arguments);
  }

  _onValidateResourceSelection(input) {
    console.log("_onValidateResourceSelection");
    if (input.indexOf("@") !== -1) {
      return ValidationState.valid;
    } else if (input.length > 1) {
      return ValidationState.warning;
    } else {
      return ValidationState.invalid;
    }
  }

  _onResourceInputChange(input) {
    const outlookRegEx = /<.*>/g;
    const emailAddress = outlookRegEx.exec(input);

    if (emailAddress && emailAddress[0]) {
      return emailAddress[0].substring(1, emailAddress[0].length - 1);
    }

    return input;
  }

  _onResourceChange(selectedItems) {
    let { resources } = this.props;
    let selectedIds = selectedItems.map((i) => i.key);
    this.props.onResourceSelected(
      resources.filter((r) => selectedIds.find((id) => entityHasId(r, id)))
    );
  }

  _resourceToPersona(r) {
    let { users } = this.props;
    let userString = "";
    let user = users.find((u) => relRefersToEntity(r, "user", u));
    if (user) {
      if (user) {
        userString =
          getAttr(user, "name") + "( " + getAttr(user, "email") + ")";
      } else {
        userString = " User ID " + getRelId(r, "user");
      }
    }

    return {
      key: getEntityId(r),
      imageInitials: getInitialsForNamedAttribute(r),
      text: getAttr(r, "name"),
      secondaryText: userString,
      presence: PersonaPresence.none,
    };
  }

  render() {
    let { selectedResources, resources, label, disabled, ...theRest } =
      this.props;
    let resourcePersonas = resources.map(
      (r) => this._resourceToPersona(r),
      this
    );
    let limit = theRest.itemLimit || (theRest.itemLimit === 0 ? 0 : 1);

    const suggestionProps = {
      suggestionsHeaderText: "Suggested resources",
      mostRecentlyUsedHeaderText: "Suggested resources",
      noResultsFoundText: "No results found",
      loadingText: "Loading",
      showRemoveButtons: true,
      suggestionsAvailableAlertText: "People Picker Suggestions available",
      suggestionsContainerAriaLabel: "Suggested resources",
    };

    return (
      <div className="novatime-resource-picker">
        {label ? (
          <Label className={theRest.required ? "label-required" : ""}>
            {label}
          </Label>
        ) : undefined}
        <NormalPeoplePicker
          className={"ms-PeoplePicker"}
          key={"resource-picker"}
          placeholder="placeholder text"
          itemLimit={limit}
          selectedItems={
            selectedResources
              ? selectedResources.map(this._resourceToPersona, this)
              : undefined
          }
          onResolveSuggestions={this._onResourceFilterChange}
          onEmptyInputFocus={this._getSuggestions}
          getTextFromItem={(r) => r.text}
          pickerSuggestionsProps={suggestionProps}
          onRemoveSuggestion={this._onRemoveResourceSelection}
          onValidateInput={this._onValidateResourceSelection}
          removeButtonAriaLabel={"Remove"}
          onChange={this._onResourceChange}
          onInputChange={this._onInputChange}
          onSelectedItemsUpdated={this._onSelectedItemsUpdated}
          resolveDelay={0}
          disabled={disabled}
        />
        <Icon
          className="indicator"
          iconName={
            this.props.icon == "queued"
              ? Icons.ICON_NAME_CHANGE_QUEUED
              : undefined
          }
        />
      </div>
    );
  }
}

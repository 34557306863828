import {takeEvery} from "redux-saga/effects";
import appInsights from "../../lib/applicationInsights";
import {LOCATION_CHANGE} from "connected-react-router";

function* doTrackLocationChanged(action) {
  if (appInsights.isInitialized()) {
    appInsights.trackPageView({ name: action.relativeUrl})
  }
}

export default function* trackLocationChanged() {
  yield takeEvery(LOCATION_CHANGE, doTrackLocationChanged);
}
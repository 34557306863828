import BaseConnector from './Base';

export default class UsersConnector extends BaseConnector {
    
    getMe(includes: string[]){
        let url = this.baseApiUrl + 'Users/me';
        if (includes && includes.length) {
            url += '?includes=' + includes.join(',');
        }
        return this.authFetch(url);
    }

    getUsers(accountId: string) {
        return this.authFetch(this.baseApiUrl + 'Users?filter=account.id eq ' + accountId);
    }

    updateUser(userId: string, userDto: object){
        return this.authPatch(this.baseApiUrl + 'Users/' + userId + '?includes=resource', userDto);
    }

    createUser(userDto: object){
        return this.authPost(this.baseApiUrl + 'Users/', userDto)
    }
}

import { createSelector } from "reselect";
import { allAccounts } from "../reducers/account";
import { allClients } from "../reducers/client";
import { allContracts } from "../reducers/contract";
import { allContractRoles } from "../reducers/contractRole";
import {entityHasId, getEntityId, getRelId, relHasId, relRefersToEntity} from '../apihelper';
import * as appDomainSelectors from "../app/domain"
import { sortByAttr } from "../storehelper";

export const selectedClientId = state => state.page.clientsAndContracts.selectedClientId;

export const selectedContractRoleId = state => state.page.clientsAndContracts.selectedContractRoleId;

export const selectedContractId = state => state.page.clientsAndContracts.selectedContractId;

export const selectedClientById = createSelector(
    [allClients, selectedClientId],
    (clients, clientId) => clients.find(c => entityHasId(c, clientId))
)

export const selectedContractById = createSelector(
    [allContracts, selectedContractId],
    (contracts, contractId) => contracts.find(c => entityHasId(c, contractId))
)

export const selectedContractRoleById = createSelector(
    [allContractRoles, selectedContractRoleId],
    (contractRoles, contractRoleId) => contractRoles.find(cr => entityHasId(cr, contractRoleId))
)

export const allContractsBySelectedClientSorted = createSelector(
    [allContracts, selectedClientById],
    (contracts, client) => contracts.filter(contract => entityHasId(client, getRelId(contract, 'client'))).sort(sortByAttr('title'))
)

export const allContractRolesBySelectedContract = createSelector(
    [allContractRoles, selectedContractById],
    (contractRoles, contract) => contractRoles.filter(cr => relHasId(cr, 'contract', getEntityId(contract)))
)

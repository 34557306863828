import * as types from '../../constants/ActionTypes';

export function createProject(project) {
    return {
        type: types.API_PROJECT_CREATE_INIT,
        payload: {model: project}
    };
}

export function deleteProject(project) {
    return {
        type: types.API_PROJECT_DELETE_INIT,
        payload: {model: project}
    };
}

export function getAllProjects({ client, account } = {}, includes) {
    return {
        type: types.API_PROJECT_GET_ALL_INIT,
        payload: {
            client: client,
            account: account,
            includes: includes
        }
    };
}

export function getProject(projectId, includes) {
    return {
        type: types.API_PROJECT_GET_INIT,
        payload: {
            projectId,
            includes
        }
    }
}

export function getAllProjectsForPermission(account, includes) {
    return {
        type: types.API_PROJECT_GET_ALL_FOR_PERMISSION_INIT,
        payload: {
            account: account,
            includes: includes
        }
    };
}

export function updateProject(project, modelChanges) {
    return {
        type: types.API_PROJECT_UPDATE_INIT,
        payload: {
            model: project,
            changes: modelChanges
        }
    };
}

export const ACCOUNT_READ      = "AccountRead";
export const ACCOUNT_WRITE     = "AccountWrite";

export const CLIENT_READ       = "ClientRead";
export const CLIENT_WRITE      = "ClientWrite";

export const GLOBAL_ACCOUNTANT = "GlobalAccountant";
export const GLOBAL_ADMIN      = "GlobalAdmin";

export const PROJECT_WRITE     = "ProjectWrite";
export const PROJECT_READ      = "ProjectRead";
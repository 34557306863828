import * as actionhelper from '../actionhelper';
import * as EntityTypes from "../../constants/EntityTypes";

export const getAllUserList = actionhelper.apiResponseData;

export const getUpsertUser = actionhelper.apiResponseData;

export const getMeUser = actionhelper.apiResponseData;

export const getIncludedUsers = (action: any) => actionhelper.apiResponseIncludedOfType(action, EntityTypes.USER);

import * as actionhelper from '../actionhelper';
import * as EntityTypes from "../../constants/EntityTypes";

export const getAllContractRoleList = actionhelper.apiResponseData;

export const getUpsertContractRole = actionhelper.apiResponseData;

export const getDeleteContractRoleId = actionhelper.apiSentModelId;

export const getIncludedContractRoles = (action: any) => actionhelper.apiResponseIncludedOfType(action, EntityTypes.CONTRACTROLE);

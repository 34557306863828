import * as types from '../../constants/ActionTypes';
import {removeItemFromList, updateDtoList} from '../helpers';
import {
  getAllResorceList,
  getDeleteResourceId,
  getIncludedResources,
  getUpsertResource
} from '../../selectors/actions/resource';

const initialState = {
    storeRevision: 0,
    currentUserResource: null,
    allResources: []
};

export default function resourceReducer(state = initialState, action) {

    switch (action.type) {
        case types.API_USER_GET_ME_SUCCESS: {
            let userRes = getIncludedResources(action)[0];
            return updateDtoList(Object.assign({}, state, {
                currentUserResource: userRes
            }), 'allResources', userRes ? [userRes] : []);
        }
        case types.API_USER_GET_ALL_SUCCESS:
        case types.API_USER_CREATE_SUCCESS:
        case types.API_USER_UPDATE_SUCCESS:
        case types.API_PROJECTRESOURCE_GET_ALL_SUCCESS: {
            return updateDtoList(state, 'allResources', getIncludedResources(action));
        }
        case types.API_RESOURCE_GET_ALL_SUCCESS: {
            return updateDtoList(state, 'allResources', getAllResorceList(action));
        }

        case types.API_RESOURCE_CREATE_SUCCESS:
        case types.API_RESOURCE_UPDATE_SUCCESS: {
            return updateDtoList(state, 'allResources', [getUpsertResource(action), ...getIncludedResources(action)]);
        }
        case types.API_RESOURCE_DELETE_SUCCESS: {
            return removeItemFromList(state, 'allResources', [getDeleteResourceId(action)]);
        }
        
        default:
            return state;
    }
}


import React, { Component } from 'react';
import PropTypes from 'prop-types';
import KpiStack from '../../KpiStack';
import { BillabilityAggregate } from '../../../lib/reportAggregators/billability';
import * as InternalPropTypes from '../../../constants/PropTypes';
import * as apihelper from '../../../selectors/apihelper';
import { Icon } from '@fluentui/react/lib/Icon';
import SorterList from "../../../components/SorterList";
import * as storehelper from '../../../selectors/storehelper';
import { SortingType } from '../../../selectors/storehelper';
import { ActionButton } from '@fluentui/react/lib/Button';
import * as AbsencePeriodTypes from '../../../constants/AbsencePeriodTypeNames';

const InfoIcon = () => <Icon iconName="Info" />;

function WarningLine(props){
    return (
        <p className='novatime-report-warning-line'>
            {props.children} 
            {props.infoTitle ? 
                <span className='novatime-report-warning-line-spacer' title={props.infoTitle}><InfoIcon /></span>
            : null}
            
        </p>
    )
}

const vacationHoursPerDay = 8;

const baseColumns = [
    {
            key:  'contractName',
            name: 'Contract',
            fieldName: 'contractName',
            minWidth: 200,
            maxWidth: 250,
            isResizable: true
    },
    {
            key:  'share',
            name: 'Share',
            fieldName: 'share',
            minWidth: 70,
            maxWidth: 70
    },
    {
            key:  'hours',
            name: 'Hours',
            fieldName: 'hours',
            minWidth: 60,
            maxWidth: 60
    }
];

const baseColumnSorters = {
    "contractName": {
        "sortType": SortingType.LEXICAL,
        "extract": a => a.contractName,
    },
    "share": {
        "sortType": SortingType.NUMERICAL,
        "extract": a => a.share,
    },
    "hours": {
        "sortType": SortingType.NUMERICAL,
        "extract": a => a.hours,
    }
};

const hourByContractListDefaultLimit = 3;

export default class InternalHoursReport extends Component {
    static propTypes = {
        reportData: PropTypes.instanceOf(BillabilityAggregate),
        account: InternalPropTypes.accountEntity,
        contracts: InternalPropTypes.contractEntities,
        clients: InternalPropTypes.clientEntities,
        absenceRegistrationProjects: InternalPropTypes.absenceRegistrationProjectEntities
    };

    constructor(props){
        super(props);
        this.state = { showAllHoursByContracts: false }
        this.toggleShowAllHoursByContracts = this.toggleShowAllHoursByContracts.bind(this);
    }

    toggleShowAllHoursByContracts(){
        this.setState({ showAllHoursByContracts: !this.state.showAllHoursByContracts })
    }

    render(){
        let { reportData, account, contracts, clients, absenceRegistrationProjects } = this.props;
        let { showAllHoursByContracts } = this.state;

        let accountName = apihelper.getAttr(account, 'name');
        let internalClientId = apihelper.getRelId(account, 'internalClient');
        let vacationRegProject = absenceRegistrationProjects.find(arp => apihelper.relHasId(arp, 'absencePeriodType', AbsencePeriodTypes.VACATION.id));
        let vacationProjectId;
        if(vacationRegProject){
            vacationProjectId = apihelper.getRelId(vacationRegProject, 'project');
        }        

        let kpis = [];
        if(vacationProjectId){
            let vacationHours = reportData.getHours({projectId: vacationProjectId});
            let resourceIds = reportData.getResourceIds();
            let vacationDays = vacationHours/vacationHoursPerDay;
            let vacationDaysPerResource = resourceIds.length > 0 ? vacationDays / resourceIds.length : 0;
            kpis = [
                {
                    title: 'Vacation hours', 
                    value: vacationHours,
                    description: `Total sum of hours registered on the vacation project. ${(vacationDays).toFixed(1)} in days.`
                },
                {
                    title: 'Vacation days per resource', 
                    value: vacationDaysPerResource.toFixed(1),
                    description: `Assuming ${vacationHoursPerDay}h vacation per day and counting across ${resourceIds.length} registering resources`
                }
            ];
        }

        let hoursByContracRows = [];
        if(internalClientId){
            let internalClient = storehelper.findById(clients, internalClientId);
            let totalInternalHours = reportData.getHours({clientId: internalClientId});
            kpis.unshift({
                title: 'Total internal hours',
                value: totalInternalHours,
                description: `All hours registered on projects under the ${apihelper.getAttr(internalClient, 'name')} client on the ${apihelper.getAttr(account, 'name')} account`
            });

            let contractIds = reportData.getContractIds();
            contractIds.forEach(contractId => {
                let contractHours = reportData.getHours({clientId: internalClientId, contractId})
                if(contractHours > 0){
                    let contract = storehelper.findById(contracts, contractId);
                    hoursByContracRows.push({
                        contractName: apihelper.getAttr(contract, 'title'),
                        hours: contractHours,
                        share: totalInternalHours > 0 ? contractHours / totalInternalHours : 0
                    })
                }
            });
        }
        hoursByContracRows.sort((a, b) => a.hours < b.hours);
        let shouldHideSomeContracts = !showAllHoursByContracts && hoursByContracRows.length > hourByContractListDefaultLimit;
        let countOfContractsWithHours = hoursByContracRows.length;
        if(shouldHideSomeContracts){
            hoursByContracRows = hoursByContracRows.slice(0, hourByContractListDefaultLimit);
        }

        let showList = hoursByContracRows.length > 0;

        return (
            <>
                {
                    vacationProjectId ?
                    <KpiStack kpis={kpis} /> :
                    <WarningLine infoTitle="Edit the account and set an absence registration project for vacation to get reporting">
                        No vacation project configured for account {accountName}
                    </WarningLine>
                }
                {
                    internalClientId ?
                    <>
                        {
                            showList ?
                            <SorterList
                                columns={baseColumns}
                                columnSorters={baseColumnSorters}
                                items={hoursByContracRows}
                                onRenderItemColumn={(item, index, column) => {
                                    const fieldContent = item[column.fieldName];
                                    switch (column.key) {
                                        case 'hours':
                                            return <span>{fieldContent.toFixed(2)}</span>
                                        case 'share':
                                            return <span>{(fieldContent * 100).toFixed(0)}%</span>
                                        default:
                                            return <span title={fieldContent}>{fieldContent}</span>;
                                }}}
                                selectable={false}
                            /> :
                            null
                        }
                        {
                            shouldHideSomeContracts ?
                            <div style={{marginLeft: '1em'}}>
                                <ActionButton onClick={this.toggleShowAllHoursByContracts}>Show all {countOfContractsWithHours} contracts</ActionButton>
                            </div> :
                            null
                        }
                    </> :
                    <WarningLine infoTitle="Edit the account and set a client as the internal client to get reporting">
                        No Internal client configured for account {accountName}
                    </WarningLine>
                }
            </>
        );
    }
}

import BaseConnector from "./Base";

export default class AbsencePeriodsConnector extends BaseConnector {
  getAllAbsencePeriods({
    earliestStart,
    latestStart,
    resourceId,
    periodEnd,
    absencePeriodStatusId,
  }: {
    earliestStart?: Date;
    latestStart?: Date;
    resourceId?: string;
    periodEnd?: Date;
    absencePeriodStatusId?: string;
  } = {}) {
    const q = [];
    if (earliestStart) {
      q.push("periodStart gt " + earliestStart.toJSON());
    }
    if (latestStart) {
      q.push("periodStart lt " + latestStart.toJSON());
    }
    if (periodEnd) {
      q.push("periodEnd lt " + periodEnd.toJSON());
    }
    if (resourceId) {
      q.push("resource.id eq " + resourceId);
    }
    if (absencePeriodStatusId) {
      q.push("absencePeriodStatus.id eq " + absencePeriodStatusId);
    }

    let filter = "";
    if (q.length) {
      filter = "&filter=" + q.join(" and ");
    }

    return this.authFetch(
      this.baseApiUrl +
        "AbsencePeriods?includes=absencePeriodStatus,absencePeriodType,approvedByUser,resource,requestedByUser" +
        filter
    );
  }

  createAbsencePeriod(dto: object) {
    return this.authPost(this.baseApiUrl + "AbsencePeriods/", dto);
  }

  updateAbsencePeriod(id: string, dto: object) {
    return this.authPatch(
      this.baseApiUrl +
        "AbsencePeriods/" +
        id +
        "?includes=absencePeriodStatus,absencePeriodType,approvedByUser,resource,absencePeriodComments",
      dto
    );
  }

  deleteAbsencePeriod(id: string) {
    return this.authDelete(this.baseApiUrl + "AbsencePeriods/" + id);
  }
}

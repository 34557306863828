import { takeLatest, put } from 'redux-saga/effects';
import * as types from '../../constants/ActionTypes';
import * as navActions from '../../actions/nav';
import * as Urls from '../../constants/Urls';

function* doLogout() {
    yield put(navActions.goToUrl(Urls.SIGN_OUT_RELATIVE, false, true));
}

export default function* logout() {
    yield takeLatest(types.AUTH_INIT_LOGOUT, doLogout);
}

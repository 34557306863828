import {put, select, takeLatest} from 'redux-saga/effects';
import * as types from '../../../constants/ActionTypes';
import {allContractsByClient} from '../../../selectors/reducers/contract';
import {getAllContracts} from '../../../actions/api/contract';
import {selectedContractId} from '../../../selectors/pages/clientsAndContracts';
import {selectContract} from '../../../actions/pages/clientsAndContracts';
import * as sagahelpers from '../../helpers';

function* doClientSelected(action) {
    if(action.payload !== null){
        yield sagahelpers.callAndAwait(getAllContracts(action.payload));
    }

    // select contract if none selected
    let contractId = yield select(selectedContractId);
    if (!contractId) {
        let contractsByClient = yield select(allContractsByClient, action.payload);
        if (contractsByClient[0]) {
            yield put(selectContract(contractsByClient[0]));
        }
    }
}

export default function* clientSelected() {
    yield takeLatest(types.APP_PAGE_CLIENTS_AND_CONTRACTS_CLIENT_SELECTED, doClientSelected);
}

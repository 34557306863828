import * as types from '../../constants/ActionTypes';
import {removeItemFromList, updateDtoList} from '../helpers';
import {getAllClientList, getDeleteClientId, getUpsertClient} from '../../selectors/actions/client';

const initialState = {
    storeRevision: 0,
    allClients: []
};

export default function clientReducer(state = initialState, action) {
    switch (action.type) {
        case types.API_CLIENT_GET_ALL_SUCCESS:{
            return updateDtoList(state, 'allClients', getAllClientList(action));
        }
        
        case types.API_CLIENT_CREATE_SUCCESS:
        case types.API_CLIENT_UPDATE_SUCCESS: {
            return updateDtoList(state, 'allClients', [getUpsertClient(action)]);
        }
        case types.API_CLIENT_DELETE_SUCCESS: {
            return removeItemFromList(state, 'allClients', [getDeleteClientId(action)]);
        }

        default:
            return state;
    }
}

import { createSelector } from "reselect";
import * as apihelper from '../apihelper';
import * as PermissionType from "../../constants/PermissionTypeNames";
import * as storehelper from '../../selectors/storehelper';
import { ResourceObjects } from "../../lib/models";

export const findPermissionTypeById = (permissionTypes: ResourceObjects, id: string) => 
  storehelper.findById(permissionTypes, id);

export const findPermissionTypeByName = (permissionTypes: ResourceObjects, name: string) =>
  permissionTypes.find(pt => apihelper.getAttr(pt, 'name') === name);

export const allPermissionTypes = (state: any) => state.api.permissiontypes.allPermissionTypes as ResourceObjects;

export const globalAdminType = createSelector(
  [allPermissionTypes],
  permissionTypes => findPermissionTypeByName(permissionTypes, PermissionType.GLOBAL_ADMIN)
);

export const accountWriteType = createSelector(
  [allPermissionTypes],
  permissionTypes => findPermissionTypeByName(permissionTypes, PermissionType.ACCOUNT_WRITE)
);

import * as types from '../../constants/ActionTypes';
import { getEntityId } from '../../selectors/apihelper';
import { getIncludedAccount } from '../../selectors/actions/account';

const initialState = {
    selectedAccountId: null,
};

function setSelectedAccount(state: any, id: string) {
    return Object.assign({}, state, {
        selectedAccountId: id
    }); 
}

export default function settingsReducer(state = initialState, action: any){
    switch(action.type){
        case types.APP_SET_SELECTED_ACCOUNT: {
            const id = action.payload ? action.payload.id : null;
            return setSelectedAccount(state, id);
        }

        case types.API_USER_GET_ME_SUCCESS:{
            const includedAccts = getIncludedAccount(action);
            if(includedAccts.length > 0){
                return setSelectedAccount(state, getEntityId(includedAccts[0]) as string)
            }
            return state;
        }

        default: 
            return state
    }
}

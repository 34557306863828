import * as types from '../../constants/ActionTypes';
import {removeItemFromList, updateDtoList} from '../helpers';
import {
  getAllLockedTimePeriodsList,
  getDeletedLockedTimePeriodId,
  getUpsertLockedTimePeriod
} from "../../selectors/actions/lockedTimePeriod";

const initialState = {
    storeRevision: 0,
    allLockedTimePeriods: []
};

export default function lockedTimePeriodReducer(state = initialState, action) {
    switch (action.type) {
        case types.API_LOCKEDTIMEPERIOD_GET_ALL_SUCCESS: {
            return updateDtoList(state, 'allLockedTimePeriods', getAllLockedTimePeriodsList(action));
        }

        case types.API_LOCKEDTIMEPERIOD_CREATE_SUCCESS: {
            return updateDtoList(state, 'allLockedTimePeriods', [getUpsertLockedTimePeriod(action)]);
        }
        case types.API_LOCKEDTIMEPERIOD_DELETE_SUCCESS: {
            return removeItemFromList(state, 'allLockedTimePeriods', getDeletedLockedTimePeriodId(action));
        }

        default:
            return state;
    }
}

import {call, cancel, cancelled, put, race, select, take, takeEvery, takeLatest, all} from 'redux-saga/effects';
import * as types from '../constants/ActionTypes';
import {baseApiUrl, isAppReady} from '../selectors/reducers/root';
import {getEntityId, getEntityType} from '../selectors/apihelper';
import { serializeError } from 'serialize-error';
import { v1 as uuidv1 } from 'uuid';
import * as actionhelper from '../selectors/actionhelper';
import BaseConnector from '../connectors/Base';

function runWhenBootstrapped(fn: any) {
    return function*() {
        const isReady = (yield select(isAppReady)) as boolean;
        if (!isReady) {
            yield take(types.APP_BOOTSTRAP_COMPLETED);
        }
        yield call(fn);
    }
}

export function* takeLatestAfterBootstrap(actionType: string, fn: any){
    yield takeLatest(actionType, runWhenBootstrapped(fn));
}

export function* takeEveryAfterBootstrap(actionType: string, fn: any) {
    yield takeEvery(actionType, runWhenBootstrapped(fn));
}

interface ApiRequestPayload {
    initAction: object,
    transactionId: string,
    startTime: number,
    requestData?: object
}

interface ConnectorConstructable {
    new(url: string): BaseConnector;
}

const getActionType = (typeName: string) => (types as Record<string, string>)[typeName]

export const generateApiCall = (
    ConnectorClass: ConnectorConstructable, 
    genRequestFunc: any, 
    prepareRequestData: boolean | ((o: object) => object) = false
) => function*(initAction: any): any {
    const actionPrefix = initAction.type.replace(/INIT$/, '');
    const transactionId = actionhelper.getTransactionId(initAction) || uuidv1()
    let simpleResponse: object | null = null;
    try {
        const payload: ApiRequestPayload = { 
            initAction,
            transactionId,
            startTime: new Date().getTime(),
            requestData: undefined
        };
        if(typeof prepareRequestData === 'function'){
            payload.requestData = prepareRequestData(initAction);
        }

        yield put({ 
            type: getActionType(actionPrefix + 'REQUEST'), 
            payload
        });

        const url = (yield select(baseApiUrl)) as string;
        const connector = new ConnectorClass(url);
        const response = (yield call(genRequestFunc, connector, initAction, payload.requestData)) as Response;
        // this must be serializable for redux, so we cannot just post everything in the response
        simpleResponse = {
            status: response.status,
            statusText: response.statusText
        };

        let jsonData;
        if (response.status !== 204){
           jsonData = yield call([response, response.json]);
        }


        yield put({
            type: getActionType(response.ok ? actionPrefix + 'SUCCESS' : actionPrefix + 'ERROR'),
            payload: {
                requestData: jsonData,
                initAction: initAction,
                transactionId,
                response: simpleResponse
            }
        });

    } catch (error) {
        yield put({
            type: getActionType(actionPrefix + 'ERROR'),
            payload: {
                error: error instanceof Error ? serializeError(error) : error,
                initAction,
                response: simpleResponse,
                transactionId
            }
        });
    } finally {
        if (yield cancelled()){
            yield put({ type: types.API_CALL_CANCELLED, payload: { initAction: initAction, transactionId }})
        }
    }
}

export const generateGetAllApiCall = generateApiCall;

export const generateCreateApiCall = generateApiCall;

export const generateUpdateApiCall = (
    ConnectorClass: ConnectorConstructable,
    genRequestFunc: any
) => generateApiCall(ConnectorClass, genRequestFunc, (action: any) => {
    const { model, changes } = action.payload;
    let requestData = null;
    if (model) {
        requestData = {
            data: Object.assign({}, changes, { id: getEntityId(model), type: getEntityType(model) })
        };
    } else {
        requestData = {
            data: changes
        };
    }
    return requestData;
});

export const generateDeleteApiCall = (
    ConnectorClass: ConnectorConstructable,
    genRequestFunc: any
) => 
    generateApiCall(ConnectorClass, genRequestFunc, (action: any) => ({ data: action.payload }));

export function awaitCall(initAction: any) {
    const actionPrefix = initAction.type.replace('INIT', '')
    const success_response = actionPrefix + 'SUCCESS';
    const error_response = actionPrefix + 'ERROR';
    return race({
        success: take((action: any) => action.type === success_response && action.payload.initAction === initAction),
        error: take((action: any) => action.type === error_response && action.payload.initAction === initAction),
    })
}

export function* callAndAwait(initAction: any): any {
    const action = yield put(initAction);
    return yield awaitCall(action);
}

export function* awaitCalls(initActions: any): any {
    const allResponses = yield all(initActions.map((initAction: any) => callAndAwait(initAction)));
    return {
        allReponses: allResponses,
        isSuccess: function () {
            return this.allReponses.every((r: any) => !!r.success)
        },
        getReponse: function (initAction: any) {
            const response = this.allReponses.find((r: any) => 
                (r.success && r.success.payload.initAction === initAction) ||
                (r.error && r.error.payload.initAction)
            )
            return response.success || response.error;
        }
    };
}

export const createApiHandler = (actionType: string, fn: (...args: any[]) => any) => {
    return function*(){
        yield takeEvery(actionType, fn);
    }
}

import * as types from '../../constants/ActionTypes';
import {updateDtoList} from '../helpers';
import {getAllCurrency} from '../../selectors/actions/currency';

const initialState = {
    storeRevision: 0,
    allCurrency: []
};

export default function currencyReducer(state = initialState, action) {
    switch (action.type) {
        
        case types.API_CURRENCY_GET_ALL_SUCCESS: {
            return updateDtoList(state, 'allCurrency', getAllCurrency(action));
        }

        default:
            return state;
    }
}

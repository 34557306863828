import PropTypes from "prop-types";
import { React, Component } from "react";
import { GroupedList } from "@fluentui/react/lib/GroupedList";
import { DetailsRow } from "@fluentui/react/lib/DetailsList";
import {Selection, SelectionMode, SelectionZone} from "@fluentui/react/lib/Selection";
import * as apihelper from "../../selectors/apihelper";
import * as storehelper from "../../selectors/storehelper";
import * as InternalPropTypes from "../../constants/PropTypes";
import * as EntityTypes from '../../constants/EntityTypes';

/*
One of the inputs of the component "ClientAndProjectPicker" is something called a "group"
Which is an object, who instructs the "ClientAndProjectPicker" of how it should visualise the given items.
This has similarities to a tree and its branches. That is, something on the form:

            0
         /  |  \ 
        0   0   0 
      / | \.. / | \
(Note that there will most likely be more than one root in the generated tree!)
These 0 will then become a sub-dropdown in the representation of the items. (This is similarly to the headlines in word, which may have more than one sub-headline) 
The pickerTree gives us a way to generate such a tree. In the above illustration the pickerNode class will become the 0's 
*/

export const ParentOptions = {
  PARENT: "Parent", // has another entity as parent
  ORPHAN: "Orphan", // is valid but does not have a parent (mount under root)
  EXCLUDE: "Exclude", // disregard this entity in the picker tree
};

class PickerNode {
  constructor(entity, parentOption) {
    this.entity = entity;
    this.parentOption = parentOption;
  }

  setParent(parentNode) {
    this.parentNode = parentNode;
  }
}

export const getEntityKey = (entity) => 
  apihelper.getEntityType(entity) + "-" + apihelper.getEntityId(entity);

export class PickerTree {
  constructor() {
    this.nodes = {};      // key -> PickerNode
    this.parentMap = {};  // parent key -> [child keys]
  }

  insert(entity, parentOption, parentEntity) {
    if (parentOption === ParentOptions.EXCLUDE) {           // Makes sure we do not add excluded notes to the tree
      return;
    }

    let n = new PickerNode(entity, parentOption);
    let nKey = getEntityKey(entity);
    this.nodes[nKey] = n;

    if (parentOption === ParentOptions.PARENT) {            //If the pickerNode is a child of another node:
      let pKey = getEntityKey(parentEntity);                //Get the key of the parent
      this.parentMap[pKey] = this.parentMap[pKey] || [];    //Makes sure that we have an array to push the child-key into
      this.parentMap[pKey].push(nKey);                      //add the child to the parent


      if (this.nodes[pKey]) {                               //If the parent is already added to the tree, inform the child of the relation (note that this has no effect if the parents are inserted to the tree first)
        n.setParent(this.nodes[pKey]);
      }
    }

    if ( Array.isArray(this.parentMap[nKey]) && this.parentMap[nKey].length > 0 ) {
      this.parentMap[nKey].forEach( (childKey) =>           // If the entity n has children, it informs the children that n is the parent
         this.nodes[childKey].setParent(n), this  );
    }
  }

  getTopLevelNodes() {
    return Object.values(this.nodes).filter(
      (node) => node.parentOption === ParentOptions.ORPHAN
    );
  }

  getChildrenOfParent(parentNode) {
    let pKey = getEntityKey(parentNode.entity);
    let childKeys = this.parentMap[pKey] || [];               // Get childrenKeys of the parentNode, if there are any.
    return childKeys.map((cKey) => this.nodes[cKey], this);   // return these pickerNodes
  }
}

/*

// Description of how one may call the following component, given the following information:

projects,
clients,
contracts


<ClientConstractProjectPickerClass 
  entities={projects.concat(clients)}
  hierarchyData={{
    projects,
    clients,
    contracts
  }}
  getEntityParentFunction={(entity, hierarchyData) => {
    let eType = apihelper.getEntityType(entity);
    if(eType === EntityType.PROJECT){
      let contract = hierarchyData.contracts.find(contract => apihelper.relRefersToEntity(entity, 'contract', contract));
      if(contract){
        let client = hierarchyData.clients.find(client => apihelper.relRefersToEntity(contract, 'client', client));
        if(client){
          return {
            type: ParentOption.PARENT,
            value: client
          }
        }
      }
    }
    return {
      type: ParentOption.ORPHAN
    }
}}

/>

*/
const SELECTED_OR_DESELECT_ALL_PROJECTS = "SelectOrDeselectAllProjects"
export class ClientProjectPickerClass extends Component {
  static propTypes = {                                      // An example is of these are currently given in the comment above this class.
    entities: InternalPropTypes.jsonApiEntities.isRequired, // The entities we want to visualise in the ClientProjectPicker
    hierarchyData: PropTypes.object,                        // The hierarchyData of the entities above.
    getEntityParentFunction: PropTypes.func.isRequired  // Given an entity and the hierarchyData, the function returns the parent of the entity in the hierarchyData, if it exists.
                                                        // If there does not exist a parent, the function returns "ParentOption.ORPHAN" or "parentOption.EXCLUDE". 
                                                        // Hence the entity has to be a root in the pickerTree class defined above of excluded.

                                                        // input => output: 
                                                        // (entity, hierarchyData) ===> {type: ParentOption, value: any}
  };

  static defaultProps = {
    entities: [],
    hierarchyData: [],
    getEntityParentFunction: null
  }

  constructor(props) {
    super(props);

    this._onRenderCell = this._onRenderCell.bind(this);
    this._onSelectionChanged = this._onSelectionChanged.bind(this);
    this._onGroupToggle = this._onGroupToggle.bind(this);
    this.state = {
      selection: new Selection({
        onSelectionChanged: this._onSelectionChanged
      }),
      collapsedGroups: [ {key: SELECTED_OR_DESELECT_ALL_PROJECTS, isCollapsed: false} ],
      useCallback: false,
    };
  }

  componentDidMount(){
    this.props.selectedProjects.forEach(projects => this.state.selection.setKeySelected(this.getProjectKey(projects) , true) )
    this.setState( { useCallback: true } )
  }

  componentDidUpdate(prevProps) {
    if(prevProps.entities.length !== this.props.entities.length ||          // there is a difference in the length of the input. Hence we update the selected entities
      ! prevProps.entities.every( (entities,index) =>                       // the length is the same, but there is a change of element in the entities
            apihelper.getEntityId(entities)   === apihelper.getEntityId(this.props.entities[index]) &&
            apihelper.getEntityType(entities) === apihelper.getEntityType(this.props.entities[index]) &&
            apihelper.getAttr(entities, "name") === apihelper.getAttr(this.props.entities[index], "name") ) ) {

      this.state.selection.setAllSelected(false);
      this.props.selectedProjects.forEach(projects => this.state.selection.setKeySelected(this.getProjectKey(projects) , true) ) ;
    }

    if(prevProps.selectedProjects.length > this.props.selectedProjects.length){
      let removedProjects = prevProps.selectedProjects.filter(prevProj => 
            !this.props.selectedProjects.find(proj => 
                  this.getProjectKey(prevProj) === this.getProjectKey(proj) ));

      removedProjects.forEach(project =>this.state.selection.setKeySelected(this.getProjectKey(project) , false));
      }
  }

  _onSelectionChanged() {
    if(this.props.inSearch && this.props.onSelection && this.state.useCallback ){        // They have written something in the search field.

      let currentlyNotDisplayedSelectedProject = this.props.selectedProjects.filter(project => 
            !this.props.entities.find(item => 
                  apihelper.getEntityType(item) === EntityTypes.PROJECT && apihelper.getEntityId(item)=== apihelper.getEntityId(project)));

      this.props.onSelection( this.state.selection.getSelection().concat(currentlyNotDisplayedSelectedProject)
                                .map(project => project.id));
    }
    if ( (!this.props.inSearch) && this.props.onSelection && this.state.useCallback) {  // They have not written something in the search field.
      this.props.onSelection( this.state.selection.getSelection().map(project => project.id));
    }
  }

  _onRenderCell(nestingDepth, item, itemIndex, group) {
    let columns = [
      {
        key: "name",
        name: "name",
        fieldName: "name",
        minWidth: 500,
        maxWidth: 500,
      },
    ];

    return item && typeof itemIndex === "number" && itemIndex > -1 ? (
      <DetailsRow
        columns   = {columns}
        groupNestingDepth = {nestingDepth}
        item      = {item}
        itemIndex = {itemIndex}
        selection = {this.state.selection}
        selectionMode = {SelectionMode.multiple}
        compact   = {true}
        group     = {group}
      />
    ) : null;
  }

  getProjectKey(entity){
    return (apihelper.getEntityType(entity) + "-" + apihelper.getEntityId(entity) );
  }

  _onGroupToggle(group){
      let updatedCollapsedGroups = this.state.collapsedGroups.slice();
      let index = updatedCollapsedGroups.indexOf(updatedCollapsedGroups.find( e => e.key === group.key) );

      if(index === -1){     // the group with the corresponding key has not yet been toggled
        updatedCollapsedGroups.push({key: group.key, isCollapsed: group.isCollapsed});      // Add the group and the toggle state
      }else{
        updatedCollapsedGroups[index].isCollapsed = !updatedCollapsedGroups[index].isCollapsed;
      }

      this.setState({collapsedGroups: updatedCollapsedGroups});
  }

  render() {
    let { onSelection, entities, hierarchyData, getEntityParentFunction, selectedProjects} =
      this.props;

    let tree = this.generateTree(
      entities,
      hierarchyData,
      getEntityParentFunction
    );

    let [groupToUse, itemsToUse] = this.generateGroupsAndItemsOfListOfDepthTwo(tree);
    this.state.selection.setItems(itemsToUse, false);

    return (
      <div>
        <SelectionZone
          selection     = {this.state.selection}
          selectionMode = {SelectionMode.multiple}
          selectionPreservedOnEmptyClick = {true}
          isSelectedOnFocus = {true}
        >
          <GroupedList
            items         = {itemsToUse}
            onRenderCell  = {this._onRenderCell}
            selection     = {this.state.selection}
            selectionMode = {SelectionMode.multiple}
            groupProps = {{ 
              showEmptyGroups: true, 
              headerProps : {onToggleCollapse: group => this._onGroupToggle(group) }
            }}
            groups        = {groupToUse}
            compact       = {true}
          />
        </SelectionZone>
      </div>
    );
  }

  generateTree(entities, hierarchyData, getEntityParentFunction) {
    let tree = new PickerTree();
    
    entities.forEach(entity => tree.insert( entity, 
                                            getEntityParentFunction(entity, hierarchyData).type,
                                            getEntityParentFunction(entity, hierarchyData).value))
    return tree;
  }

  generateGroupsAndItemsOfListOfDepthTwo(tree) {
    let topLevel = tree.getTopLevelNodes().sort( storehelper.sortByValues( pickerNode => apihelper.getAttr(pickerNode.entity,"name").toLowerCase() ) );
    let itemArray = [];
    let group = [];
    let currentItemLength = 0;

    topLevel.forEach( (topLevelEntity) => {
      let secondLevel = tree.getChildrenOfParent(topLevelEntity).sort( storehelper.sortByValues( pickerNode => apihelper.getAttr(pickerNode.entity,"name").toLowerCase() ) );

      itemArray = itemArray.concat(secondLevel.map((secondLevelEntity) => ({
          name: apihelper.getAttr(secondLevelEntity.entity, "name"), 
          key:  getEntityKey(secondLevelEntity.entity),
          type: apihelper.getEntityType(secondLevelEntity.entity),
          id:   apihelper.getEntityId(secondLevelEntity.entity)
        }) ) )
        
        group.push({
          count: secondLevel.length,
          isCollapsed: (this.state.collapsedGroups.find( e => e.key === getEntityKey(topLevelEntity.entity) ))?.isCollapsed ?? this.props.inSearch? false : true,
          key: getEntityKey(topLevelEntity.entity),
          level: 1,
          name: apihelper.getAttr(topLevelEntity.entity, "name"),
          startIndex: currentItemLength,
        });
        currentItemLength += secondLevel.length;
    })
    let groupWithAllSelection = [{children: group, isCollapsed: this.state.collapsedGroups.find(e => e.key === SELECTED_OR_DESELECT_ALL_PROJECTS).isCollapsed, 
                                  count: currentItemLength, key: SELECTED_OR_DESELECT_ALL_PROJECTS, level: 0, name: "Select/Deselect All", startIndex: 0}];

    return [groupWithAllSelection, itemArray];
  }
}

import * as types from '../constants/ActionTypes';

export function selectAccount(accountId) {
    let payload = {
        id: accountId
    } 
    return {
        type: types.APP_SET_SELECTED_ACCOUNT,
        payload
    };
}
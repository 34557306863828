import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { getAttr, getRelId, getEntityId } from "../../selectors/apihelper";
import {
  createEmptyModelRelationships,
  createEmptyModelAttributes,
} from "../../lib/emptyModelCreation";
import * as EntityTypes from "../../constants/EntityTypes";
import { TextField } from "@fluentui/react/lib/TextField";
import { Dropdown } from "@fluentui/react/lib/Dropdown";
import DatePicker from "../uifabricextensions/DatePicker";
import SingleBaseForm from "./SingleBaseForm";
import Row from "../grid/Row";
import Column from "../grid/Column";
import Grid from "../grid/Grid";

export default class ContractRoleForm extends SingleBaseForm {
  constructor(props) {
    super(props);
    let { model, billableBillabilityTypes, subcontracterBillabilityTypes } =
      props;
    this.state = {};

    this.state.fields = SingleBaseForm._initializeFields(
      {
        name: {
          getVal: () => getAttr(model, "name"),
          setVal: () =>
            createEmptyModelAttributes(EntityTypes.CONTRACTROLE, {
              name: this.state.fields.name.value,
            }),
          isRequired: true,
        },
        hourPrice: {
          getVal: () => getAttr(model, "hourPrice"),
          setVal: () =>
            createEmptyModelAttributes(EntityTypes.CONTRACTROLE, {
              hourPrice: this.state.fields.hourPrice.value,
            }),
          isRequired: (fields) =>
            billableBillabilityTypes
              .map((n) => getEntityId(n))
              .includes(fields.billable.value),
        },
        effectiveDate: {
          getVal: () => getAttr(model, "effectiveDate"),
          setVal: () =>
            createEmptyModelAttributes(EntityTypes.CONTRACTROLE, {
              effectiveDate: moment(
                this.state.fields.effectiveDate.value
              ).format("YYYY-MM-DD HH:mm:ss"),
            }),
          isRequired: true,
        },
        expiryDate: {
          getVal: () => getAttr(model, "expiryDate"),
          setVal: () =>
            createEmptyModelAttributes(EntityTypes.CONTRACTROLE, {
              expiryDate: moment(this.state.fields.expiryDate.value).format(
                "YYYY-MM-DD HH:mm:ss"
              ),
            }),
          isRequired: true,
        },
        description: {
          getVal: () => getAttr(model, "description"),
          setVal: () =>
            createEmptyModelAttributes(EntityTypes.CONTRACTROLE, {
              description: this.state.fields.description.value,
            }),
          isRequired: false,
        },
        billable: {
          getVal: () => getRelId(model, "billabilityType"),
          setVal: () =>
            createEmptyModelRelationships(EntityTypes.CONTRACTROLE, {
              billabilityType: {
                id: this.state.fields.billable.value,
                type: EntityTypes.BILLABILITYTYPE,
              },
            }),
          isRequired: true,
        },
        currencyId: {
          getVal: () => getRelId(model, "currency"),
          setVal: () =>
            createEmptyModelRelationships(EntityTypes.CONTRACTROLE, {
              currency: {
                id: this.state.fields.currencyId.value,
                type: EntityTypes.CURRENCY,
              },
            }),
          isRequired: (fields) =>
            billableBillabilityTypes
              .map((n) => getEntityId(n))
              .includes(fields.billable.value),
        },
        budget: {
          getVal: () => getAttr(model, "budget"),
          setVal: () =>
            createEmptyModelAttributes(EntityTypes.CONTRACTROLE, {
              budget: this.state.fields.budget.value,
            }),
          isRequired: false,
        },
        subcontractorRate: {
          getVal: () => getAttr(model, "subContractorRate"),
          setVal: () =>
            createEmptyModelAttributes(EntityTypes.CONTRACTROLE, {
              subContractorRate: this.state.fields.subcontractorRate.value,
            }),
          isRequired: (fields) =>
            subcontracterBillabilityTypes
              .map((n) => getEntityId(n))
              .includes(fields.billable.value),
        },
      },
      model
    );

    this._onNameChange = this._onNameChange.bind(this);
    this._onDescriptionChange = this._onDescriptionChange.bind(this);
    this._onEffectiveDateChange = this._onEffectiveDateChange.bind(this);
    this._onExpirationDateChange = this._onExpirationDateChange.bind(this);
    this._onBillableChange = this._onBillableChange.bind(this);
    this._onCurrencyIdChange = this._onCurrencyIdChange.bind(this);
    this._validateDate = this._validateDate.bind(this);
    this._onHourPriceChange = this._onHourPriceChange.bind(this);
    this._onBudgetChange = this._onBudgetChange.bind(this);
    this._onSubcontractorRateChange =
      this._onSubcontractorRateChange.bind(this);
  }

  _onNameChange(event, value) {
    this._updateFieldState(
      "name",
      value,
      !value ? "Name cannot be empty" : undefined
    );
  }

  _onDescriptionChange(event, value) {
    this._updateFieldState("description", value);
  }

  _validateDate() {
    let { effectiveDate, expiryDate } = this.state.fields;
    let validation = moment(expiryDate.value).diff(moment(effectiveDate.value));
    if (effectiveDate && validation < 0)
      return "Effective date has to be before expiry date.";
  }

  _onEffectiveDateChange(value) {
    this._updateFieldState("effectiveDate", value);
  }

  _onExpirationDateChange(value) {
    this._updateFieldState("expiryDate", value);
  }

  _onBillableChange(value) {
    let { billableSet } = this.props;
    let fieldSpec = {
      billable: {
        value: value.key,
        validationMessage: !value.key ? "Must select billability" : undefined,
      },
    };

    if (value.key === billableSet.getIdForNotBillable()) {
      fieldSpec.hourPrice = { value: null };
      fieldSpec.budget = { value: null };
      fieldSpec.subcontractorRate = { value: null };
      fieldSpec.currencyId = { value: null };
    } else if (value.key === billableSet.getIdForHourlyRate) {
      fieldSpec.subcontractorRate = { value: null };
    }
    this._updateFieldState(fieldSpec);
  }

  _onCurrencyIdChange(value) {
    this._updateFieldState(
      "currencyId",
      value.key,
      !value.key ? "Must select currency" : undefined
    );
  }

  _onHourPriceChange(event, value) {
    this._updateFieldState(
      "hourPrice",
      value,
      value !== 0 && !(value || "").match(/^\s*\d+\s*$/)
        ? "Hourly rate must be integer"
        : undefined
    );
  }

  _onBudgetChange(event, value) {
    this._updateFieldState(
      "budget",
      value,
      value && value !== 0 && !value.match(/^\s*\d+\s*$/)
        ? "Budget must be integer"
        : undefined
    );
  }

  _onSubcontractorRateChange(event, value) {
    this._updateFieldState(
      "subcontractorRate",
      value,
      value !== 0 && !(value || "").match(/^\s*\d+\s*$/)
        ? "Subcontractor rate must be integer"
        : undefined
    );
  }

  render() {
    let { model, billableSet, billabilityTypesByName, allCurrency } =
      this.props;
    let { fields } = this.state;

    let effDate =
      !fields.effectiveDate.value ||
      fields.effectiveDate.value.toString().startsWith("0001")
        ? ""
        : new Date(fields.effectiveDate.value);
    let expDate =
      !fields.expiryDate.value ||
      fields.expiryDate.value.toString().startsWith("0001")
        ? ""
        : new Date(fields.expiryDate.value);

    let disableSave =
      moment(expDate).diff(moment(effDate)) < 0 ||
      isNaN(fields.hourPrice.value);
    return (
      <div>
        <h2>{model ? "Edit" : "Create"} Contract role</h2>
        <br />
        <TextField
          label="Name"
          autoFocus={!model}
          iconProps={this._getIconProps(fields.name.isChanged)}
          onChange={this._onNameChange}
          required={fields.name.isRequired}
          value={fields.name.value}
          errorMessage={fields.name.validationMessage}
        />
        <br />
        <TextField
          label="Description"
          iconProps={this._getIconProps(fields.description.isChanged)}
          onChange={this._onDescriptionChange}
          required={fields.description.isRequired}
          multiline
          rows={4}
          value={fields.description.value}
          errorMessage={fields.description.validationMessage}
        />
        <br />
        <Grid>
          <Row>
            <Column sm={12} md={12} lg={6} xl={4}>
              <div className="novatime-combo-box">
                <Dropdown
                  label="Billable"
                  placeHolder="Select Billability"
                  buttonIconProps={this._getIconProps(
                    fields.billable.isChanged
                  )}
                  onChanged={this._onBillableChange}
                  required={fields.billable.isRequired}
                  options={billableSet.getAllBillableTypeEntities().map((n) => {
                    return { key: getEntityId(n), text: n.attributes.name };
                  })}
                  selectedKey={fields.billable.value}
                />
              </div>
            </Column>
            <Column sm={12} md={12} lg={6} xl={4}>
              <div className="novatime-combo-box">
                <Dropdown
                  label="Currency"
                  placeHolder="Select currency"
                  buttonIconProps={this._getIconProps(
                    fields.currencyId.isChanged
                  )}
                  onChanged={this._onCurrencyIdChange}
                  required={fields.currencyId.isRequired(fields)}
                  disabled={
                    fields.currencyId.isRequired(fields) ? undefined : true
                  }
                  options={allCurrency.map((n) => {
                    return { key: getEntityId(n), text: n.attributes.name };
                  })}
                  selectedKey={fields.currencyId.value}
                />
              </div>
            </Column>
            <Column sm={12} md={12} lg={6} xl={4}>
              <TextField
                label="Hourly rate"
                iconProps={this._getIconProps(fields.hourPrice.isChanged)}
                onChange={this._onHourPriceChange}
                required={fields.hourPrice.isRequired(fields)}
                value={
                  fields.hourPrice.value != null ? fields.hourPrice.value : ""
                }
                disabled={
                  fields.hourPrice.isRequired(fields) ? undefined : true
                }
                errorMessage={fields.hourPrice.validationMessage}
              />
            </Column>
            <Column sm={12} md={12} lg={6} xl={4}>
              <TextField
                label="Subcontractor Rate"
                iconProps={this._getIconProps(
                  fields.subcontractorRate.isChanged
                )}
                onChange={this._onSubcontractorRateChange}
                required={fields.subcontractorRate.isRequired(fields)}
                value={
                  fields.subcontractorRate.value != null
                    ? fields.subcontractorRate.value
                    : ""
                }
                disabled={
                  fields.subcontractorRate.isRequired(fields) ? undefined : true
                }
                errorMessage={fields.subcontractorRate.validationMessage}
              />
            </Column>
            <Column sm={12} md={12} lg={6} xl={4}>
              <TextField
                label="Budget"
                iconProps={this._getIconProps(fields.budget.isChanged)}
                onChange={this._onBudgetChange}
                required={fields.budget.isRequired}
                value={fields.budget.value != null ? fields.budget.value : ""}
                disabled={
                  fields.billable.value == null ||
                  fields.billable.value == billableSet.getIdForNotBillable()
                    ? true
                    : undefined
                }
                errorMessage={fields.budget.validationMessage}
              />
            </Column>
          </Row>
        </Grid>
        <br />
        <br />
        <DatePicker
          label="Effective date (inclusive)"
          placeholder={effDate ? "" : "Select an effective date"}
          isRequired={fields.effectiveDate.isRequired}
          value={effDate}
          onSelectDate={this._onEffectiveDateChange}
          validate={this._validateDate}
          displayArrows={false}
        />
        <br />
        <DatePicker
          label="Expiration date (inclusive)"
          placeholder={expDate ? "" : "Select an expiry date"}
          isRequired={fields.expiryDate.isRequired}
          value={expDate}
          onSelectDate={this._onExpirationDateChange}
          validate={this._validateDate}
          displayArrows={false}
        />
        <br />
        {this._renderButtons(disableSave)}
        <br />
        {this._renderTimestampsAndId(model)}
      </div>
    );
  }
}

ContractRoleForm.propTypes = {
  model: PropTypes.object,
  onSave: PropTypes.func,
  onDismiss: PropTypes.func,
};

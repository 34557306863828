import React from 'react';
import { ResourceObject } from '../lib/models';
import { UserPermissionSet } from '../lib/PermissionSet';
import { withContext } from './withContext';

export type CurrentUserConfig = {
    isLoggedIn: boolean,
    user: ResourceObject | null,
    currentUserPermissionSet: UserPermissionSet | null
};

const userConfig: CurrentUserConfig = {
    isLoggedIn: false,
    user: null,
    currentUserPermissionSet: null
};

const CurrentUserContext = React.createContext(userConfig);
export default CurrentUserContext;

export const withCurrentUserContext = withContext(CurrentUserContext, 'currentUserConfig');

export type WithCurrentUserContextProps = {
  currentUserConfig: CurrentUserConfig;
};

import React, {Component} from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import ProjectCallout from '../../components/uifabricextensions/ProjectCallout'
import * as InternalPropTypes from '../../constants/PropTypes';
import '../../lib/times';
import BusinessCalendar from '../../lib/businessCalendar';
import { relRefersToEntity, getAttr, getRelId, getEntityId } from '../../selectors/apihelper';

export default class Month extends Component {
    static propTypes = {
        dayInMonth: PropTypes.object,
        rows: PropTypes.array,
        isCellUnderEdit: PropTypes.func,
        renderRowHeader: PropTypes.func.isRequired,
        renderCell: PropTypes.func.isRequired,
        renderRowSummation: PropTypes.func.isRequired,
        renderColumnSummation: PropTypes.func.isRequired,
        renderTotalSummation: PropTypes.func.isRequired,
        clients: InternalPropTypes.clientEntities,
        projects: InternalPropTypes.projectEntities,
        contracts: InternalPropTypes.contractEntities,
        withCallout: PropTypes.bool,
        isLocked: PropTypes.func,
        isFirstDayOfLockedPeriod: PropTypes.func,
        isLastDayOfLockedPeriod: PropTypes.func,
        absencePeriod: PropTypes.func
    };

    static defaultProps = {
        withCallout: true,
        clients: [],
        projects: [],
        contracts: []
    };

    constructor(props) {
        super(props);
        this.state = {
            selectedMobileDate: new Date(),
            isCalloutVisible: false
        };
        this._onSelectedMobileDateChange = this._onSelectedMobileDateChange.bind(this);
        this._onDismiss = this._onDismiss.bind(this);
    }

    _onSelectedMobileDateChange(day) {
        this.setState({selectedMobileDate: moment(this.state.selectedMobileDate).isSame(day, 'day') ? null : day})
    }

    _onDismiss() {
        this.setState({
            isCalloutVisible: !this.state.isCalloutVisible
        });
    }

    render() {
        let {
            dayInMonth,
            rows,
            clients,
            contracts,
            projects,
            isCellUnderEdit,
            withCallout,
            className,
            renderRowHeader,
            renderCell,
            renderRowSummation,
            renderColumnSummation,
            renderTotalSummation,
            isLocked,
            isFirstDayOfLockedPeriod,
            isLastDayOfLockedPeriod,
            absencePeriod
        } = this.props;
        let {selectedMobileDate} = this.state;
        let firstDayOfMonth = moment(dayInMonth).startOf('month');
        let calendar = new BusinessCalendar();
        let monthDays = [];
        if(firstDayOfMonth){
            (0).upto(moment(firstDayOfMonth).daysInMonth() - 1, i => {
                let date = moment(firstDayOfMonth).add(i, 'day');
                let dateObj = date.toDate()
                let dayName = date.format('Do');
                monthDays.push({
                    weekdayName: date.format('dddd'),
                    dayNameShort: dayName.slice(0, -2),
                    dayNameRest: dayName.substr(-2),
                    date: date.format('Do MMM'),
                    isoDate: date.format('YYYY-MM-DD'),
                    dateObj,
                    isToday: calendar.isToday(dateObj),
                    isWeekend: calendar.isWeekend(dateObj),
                    isHoliday: calendar.isHoliday(dateObj, 'dk'),
                    holidayName: calendar.getHolidayName(dateObj, 'dk')
                });
            })
        }
        let projectIdsInTable = rows.map(r => r.project ? getEntityId(r.project) : null).filter(id => !!id);
        let recentProjectsInTable = rows.filter(r => !r.isLocalStorage).map(r => r.project ? getEntityId(r.project) : null).filter(id => !!id);
        let projectsForCallout = projects.filter(p => !projectIdsInTable.includes(getEntityId(p)));

        let clientHasAllProjectsInView = client => {
            let contractIds = contracts.filter(c => relRefersToEntity(c, 'client', client)).map(getEntityId);
            let projectIdsFromClient = projects.filter(p => contractIds.includes(getRelId(p, 'contract'))).map(getEntityId);
            let projectsIntersected = projectIdsFromClient.filter(pId => projectIdsInTable.includes(pId));
            return projectsIntersected.sort().join(',') === projectIdsFromClient.sort().join(',');
        };
        let clientsForCallout = clients.filter(c => !clientHasAllProjectsInView(c));
        let hideButtonDisabled = projectsForCallout.length === (projects.length - recentProjectsInTable.length);

        var res = (
            <div className="novatime-month-registration">
                <div className="novatime-month-registration-fullsize">
                    <table className={(className || "") + " ms-Table novatime-month-registration-table"}>
                        <thead>
                        <tr>
                            <th/>
                            {
                                monthDays.map(d =>
                                    <th title={d.weekdayName + ' ' + d.isoDate + (d.isHoliday ? ', ' + d.holidayName : '')}
                                        key={'fullsize-table-header-' + d.dayNameShort}
                                        className={ [d.isToday ? 'today' : '', 
                                                     d.isWeekend ? 'weekend' : '',
                                                     d.isHoliday ? 'holiday' : ''].filter(Boolean).join(' ') }>
                                        {d.dayNameShort}<span className="ms-hiddenLgDown">{d.dayNameRest}</span>
                                    </th>
                                )
                            }
                            <th/>
                        </tr>
                        </thead>
                        <tbody>
                        {
                            rows.map(r =>
                                <tr key={'fullsize-table-data-row-' + r.id}>
                                    <td>{renderRowHeader(r)}</td>
                                    {
                                        monthDays.map(d =>
                                            <td title={d.isoDate} 
                                                key={'fullsize-table-data-cell-' + r.id + d.date}
                                                className={ [d.isToday ? 'today' : '', 
                                                             d.isWeekend ? 'weekend' : '',
                                                             d.isHoliday ? 'holiday' : '',
                                                             isCellUnderEdit(r, d) ? 'underEdit' : '',
                                                             isLocked(getEntityId(r.resource), d.dateObj) ? 'locked' : '',
                                                             isFirstDayOfLockedPeriod(getEntityId(r.resource), d.dateObj) ? 'first-day-locked' : '',
                                                             isLastDayOfLockedPeriod(getEntityId(r.resource), d.dateObj) ? 'last-day-locked' : '',
                                                             absencePeriod(getEntityId(r.resource), d.dateObj) ? 'absencePeriod-' + absencePeriod(getEntityId(r.resource), d.dateObj).toLowerCase() : ''
                                                             ].join(' ') }>
                                                {renderCell(r, d)}
                                            </td>
                                        )
                                    }
                                    <td className={ isCellUnderEdit(r) ? 'underEdit' : '' }>{renderRowSummation(r)}</td>
                                </tr>
                            )
                        }
                        {rows.length > 0 || projectsForCallout.length > 0 ?
                            <tr>
                                <td>
                                    {withCallout ?
                                        <div className='callout-td'>
                                            <ProjectCallout
                                                projects={projectsForCallout}
                                                clients={clientsForCallout}
                                                onItemSelected={this.props.onItemSelected}
                                                onShowAll={undefined}
                                                hideButtonDisabled={hideButtonDisabled}
                                            />
                                        </div>
                                        : undefined}
                                </td>
                                {
                                    monthDays.map(d => 
                                        <td className={ [d.isToday ? 'today' : '', d.isWeekend ? 'weekend' : '',
                                                         d.isHoliday ? 'holiday' : '', isCellUnderEdit(null, d) ? 'underEdit' : ''].join(' ') } 
                                            key={'fullsize-table-footer-' + d.date}>{renderColumnSummation(d)}</td>)
                                }
                                <td className={ isCellUnderEdit() ? 'underEdit' : '' }>{renderTotalSummation()}</td>
                            </tr>
                            : undefined}
                        </tbody>
                    </table>
                </div>


                
                <div className="novatime-month-registration-mobile">
                    {
                        monthDays.map(d =>
                            <div
                                className={"novatime-month-registration-accordion-item" + (moment(selectedMobileDate).isSame(d, 'day') ? ' selected' : '')}
                                key={"mobile-week-day-" + d.dayNameShort}>
                                <div className="novatime-month-registration-accordion-item-header"
                                     onClick={() => this._onSelectedMobileDateChange(d)}>
                                    {d.dayNameShort}{d.dayNameRest}<span>{renderColumnSummation(d)}</span>
                                </div>
                                <div className="novatime-month-registration-accordion-item-body">
                                    <table className="ms-Table novatime-month-registration-table">
                                        <tbody>
                                        {
                                            rows.map(r => {
                                                let isUnderEdit = isCellUnderEdit(r, d);
                                                return (
                                                    <tr key={'mobile-table-data-row-' + r.id}>
                                                        <td title={(!getAttr(r.resource, 'isActive') ? "Inactive - " : "") + r.shortName} className={!getAttr(r.resource, 'isActive') ? " inactive" : ""}>
                                                            {renderRowHeader(r)}
                                                        </td>
                                                        {
                                                            <td key={'mobile-table-data-cell-' + r.id + d.date}
                                                                className={isUnderEdit ? 'underEdit' : undefined}>
                                                                {renderCell(r, d)}
                                                            </td>
                                                        }
                                                    </tr>
                                                )

                                            })
                                        }
                                        {projectsForCallout.length > 0 ?
                                            <tr>
                                                <td>
                                                    {withCallout ?
                                                        <div className='callout-td'>
                                                            <ProjectCallout
                                                                projects={projectsForCallout}
                                                                clients={clientsForCallout}
                                                                onItemSelected={this.props.onItemSelected}
                                                                onShowAll={undefined}
                                                                hideButtonDisabled={hideButtonDisabled}
                                                            />
                                                        </div>
                                                        : undefined}
                                                </td>
                                            </tr>
                                            : undefined}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        )
                    }
                </div>
            </div>
        );

        return res;
    }
}

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import KpiStack from '../../KpiStack';
import {BillabilityAggregate, AccountForm} from '../../../lib/reportAggregators/billability';
import { UtilizationAggregate } from '../../../lib/reportAggregators/utilization';
import * as Currency from '../../../constants/Currency';
import SorterList, {SortingType} from "../../../components/SorterList";
import { ActionButton } from '@fluentui/react/lib/Button';
import * as storehelper from '../../../selectors/storehelper';
import * as apihelper from '../../../selectors/apihelper';
import * as InternalPropTypes from '../../../constants/PropTypes';

const shortenToThousands = number => number > 1000 ? (number/1000).toFixed(2).toLocaleString() + 'k' : number.toLocaleString();

export default class RevenueReport extends Component {
    static propTypes = {
        billabilityAggregate: PropTypes.instanceOf(BillabilityAggregate),
        utilizationAggregate: PropTypes.instanceOf(UtilizationAggregate),
    };

    render(){
        let { billabilityAggregate, utilizationAggregate } = this.props;

        let totalBillableHours = billabilityAggregate.getHours({accountForm: AccountForm.Billable});
        let passedPeriodHours = utilizationAggregate.getPeriodWorkHoursPassed();
        let actualUtilization = passedPeriodHours > 0 ? totalBillableHours / passedPeriodHours : 0;
        let totalRemainingWorkHours = utilizationAggregate.getPeriodWorkHoursRemaining();
        let projectedBillableHoursRemaining = totalRemainingWorkHours * actualUtilization;

        //let revenue = billabilityAggregate.getRevenue();
        let revenueByCurrency = Object.values(Currency).reduce((acc, currencyCode) => {
            let revenue = billabilityAggregate.getRevenue({currencyCode, accountForm: AccountForm.Billable});
            if(revenue > 0 || currencyCode === Currency.CURRENCY_CODE_DKK){
                let billableHours = billabilityAggregate.getHours({currencyCode, accountForm: AccountForm.Billable});
                let billableShare = totalBillableHours > 0 ? billableHours / totalBillableHours : 0;
                let shareOfRemainingBillableHours = projectedBillableHoursRemaining * billableShare;
                let hours = billabilityAggregate.getHours({currencyCode, accountForm: AccountForm.Billable});
                let meanRate = hours > 0 ? revenue / hours : 0;
                acc[currencyCode] = {
                    realized: revenue || 0,
                    projected: revenue + shareOfRemainingBillableHours * meanRate
                };
            }
            return acc;
        }, {});

        return (
            <>
                {
                    Object.keys(revenueByCurrency).map(currencyCode => 
                        <KpiStack key={`kpistack-revenue-${currencyCode}`} kpis={[
                            {
                                title: 'Realized',
                                value: `${shortenToThousands(revenueByCurrency[currencyCode].realized)} ${currencyCode}`,
                                description: 'Realized value of registered hours with actual rates'
                            },
                            {
                                title: 'Projected',
                                value: `${shortenToThousands(revenueByCurrency[currencyCode].projected)} ${currencyCode}`,
                                description: `Linear regression of the ${projectedBillableHoursRemaining.toFixed(2)} billable hours remaining with the current mean rate and billability`
                            }
                        ]} />    
                    )
                }
            </>
        );
    }
}

import {ApplicationInsights} from "@microsoft/applicationinsights-web";

const instrumentationKey = process.env.REACT_APP_APPLICATION_INSIGHTS_INSTRUMENTATION_KEY;
const applicationInsights = new ApplicationInsights({
  config: {
    instrumentationKey: instrumentationKey,
  }
});
if (instrumentationKey) {
  applicationInsights.loadAppInsights();
}

export default applicationInsights.appInsights;
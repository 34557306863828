import moment from "moment";
import * as apihelper from "../selectors/apihelper";
import type { ResourceObject } from "./models";

const validateAttribute =
  (attributeName: string, required = true, cls: any = null) =>
  (model: ResourceObject) => {
    if (!apihelper.hasAttr(model, attributeName) && required) {
      return "Mandatory attribute " + attributeName + " is missing\n";
    }
    const val = apihelper.getAttr(model, attributeName);
    if (
      cls &&
      apihelper.hasAttr(model, attributeName) &&
      !(typeof cls === "string" ? typeof val === cls : val instanceof cls)
    ) {
      const className =
        typeof val === "undefined"
          ? "undefined"
          : (val as object).constructor.name;
      return (
        "Attribute " + attributeName + " was class " + className + " not " + cls
      );
    }
    return "";
  };

// can either be Date or date string
const validateDateAttribute =
  (attributeName: string, required = true) =>
  (model: ResourceObject) => {
    const isDate = validateAttribute(attributeName, required, "string")(model);
    const isThereIfRequired = validateAttribute(attributeName, required)(model);
    const isNotRequiredOrValidDateStr = moment(
      apihelper.getAttr(model, attributeName) as string
    ).isValid();

    return isDate || (isThereIfRequired && isNotRequiredOrValidDateStr);
  };

const validateRelationship =
  (relationshipName: string) => (model: ResourceObject) =>
    !apihelper.relHasReference(model, relationshipName)
      ? "Mandatory relationship " + relationshipName + " is null\n"
      : "";

export type ValidationCollection = Record<string, (string | boolean)[]>;

export interface ValidationResult {
  validations: ValidationCollection;
  asString: string;
  isValid: boolean;
}

class Validation {
  private validations: Record<
    string,
    Array<(model: ResourceObject) => string | boolean>
  >;

  constructor() {
    this.validations = {};
  }

  add(
    fieldName: string,
    validation: (model: ResourceObject) => boolean | string
  ) {
    this.validations[fieldName] = this.validations[fieldName] || [];
    this.validations[fieldName].push(validation);
    return this;
  }

  addRelationship(fieldName: string) {
    return this.add(fieldName, validateRelationship(fieldName));
  }

  addAttribute(attributeName: string, required = true, cls: any = null) {
    return this.add(
      attributeName,
      validateAttribute(attributeName, required, cls)
    );
  }

  addDateAttribute(attributeName: string, required = true) {
    return this.add(
      attributeName,
      validateDateAttribute(attributeName, required)
    );
  }

  validate(model: ResourceObject): ValidationResult {
    const validationResults: Record<string, (string | boolean)[]> = {};
    Object.keys(this.validations).forEach((k) => {
      const vs = this.validations[k].map((v) => v(model)).filter((m) => !!m);
      if (vs.length > 0) {
        validationResults[k] = vs;
      }
    }, this);
    return {
      validations: validationResults,
      isValid: Object.keys(validationResults).length == 0,
      asString: Object.entries(validationResults)
        .map((pair) => pair[0] + " " + pair[1])
        .join("\n"),
    };
  }
}

interface EntityValidator {
  isValid(model: ResourceObject): boolean;
  validate(model: ResourceObject): ValidationResult;
}

class TimeRegistrationValidator implements EntityValidator {
  private validation: Validation;

  constructor() {
    this.validation = new Validation()
      .addRelationship("resource")
      .addRelationship("project")
      .addRelationship("timeRegistrationStatus")
      .addAttribute("startTime", false, "string")
      .addDateAttribute("endTime", false)
      .addAttribute("description", false, "string")
      .add("startTime", (model) =>
        new Date(apihelper.getAttr(model, "startTime") as string) <
        new Date(apihelper.getAttr(model, "endTime") as string)
          ? ""
          : "endTime is earlier than startTime"
      );
  }

  isValid(model: ResourceObject) {
    return this.validate(model).isValid;
  }

  validate(model: ResourceObject) {
    return this.validation.validate(model);
  }
}

const validationMap: Record<string, EntityValidator> = {
  TimeRegistration: new TimeRegistrationValidator(),
};

export const isModelValid = (model: ResourceObject) => {
  return validateModel(model).isValid;
};

export const validateModel = (model: ResourceObject) => {
  if (typeof model.type != "string") {
    throw "Cannot validate model. Model has no type (" + model.type + ")";
  }
  if (!validationMap[model.type]) {
    throw "Cannot validate model. No validator for type " + model.type;
  }
  return validationMap[model.type].validate(model);
};

import * as types from '../../constants/ActionTypes';
import {updateDtoList} from '../helpers';
import {getAllTimeRegistrationStatus} from '../../selectors/actions/timeRegistrationStatus';

const initialState = {
    storeRevision: 0,
    allTimeRegistrationStatus: []
};

export default function timeRegistrationStatusReducer(state = initialState, action) {
    switch (action.type) {
        case types.API_TIMEREGISTRATIONSTATUS_GET_ALL_SUCCESS: {
            return updateDtoList(state, 'allTimeRegistrationStatus', getAllTimeRegistrationStatus(action));
        }

        default:
            return state;
    }
}
import * as SagaEffects from 'redux-saga/effects';
import * as types from '../../../constants/ActionTypes';
import * as clientAndContractsActions from "../../../actions/pages/clientsAndContracts"

function* doContractSelectedReload(){
    yield SagaEffects.put( clientAndContractsActions.selectContract(null) );
    yield SagaEffects.put({ type: types.APP_PAGE_CLIENTS_AND_CONTRACTS_LOAD_CLIENTS} );
}

export default function* contractDeleted() {
    yield SagaEffects.takeLatest(types.API_CONTRACT_DELETE_SUCCESS, doContractSelectedReload);
}

import { put, takeLatest } from "redux-saga/effects";
import * as types from "../../constants/ActionTypes";
import * as EntityTypes from "../../constants/EntityTypes";
import { clearEdit } from "../../actions/EntityEditor";
import { mergeModels } from "../../lib/emptyModelCreation";
import * as accountActions from "../../actions/api/account";
import * as clientActions from "../../actions/api/client";
import * as contractActions from "../../actions/api/contract";
import * as contractRoleActions from "../../actions/api/contractRole";
import * as projectActions from "../../actions/api/project";
import * as projectResourceActions from "../../actions/api/projectResource";
import * as resourceActions from "../../actions/api/resource";
import * as userActions from "../../actions/api/user";
import * as permissionActions from "../../actions/api/permission";
import * as absencePeriodActions from "../../actions/api/absencePeriod";
import * as absencePeriodCommentsActions from "../../actions/api/absencePeriodcomments";
import * as absenceRegistrationProjectActions from "../../actions/api/absenceRegistrationProject";
import * as workLocationRegistrationActions from "../../actions/api/workLocationRegistration";
import FormCommand, { FormCommandType } from "../../lib/FormCommand";

const actionMap: Record<FormCommandType, Record<string, any>> = {
  create: {
    [EntityTypes.ACCOUNT]: accountActions.createAccount,
    [EntityTypes.CLIENT]: clientActions.createClient,
    [EntityTypes.CONTRACT]: contractActions.createContract,
    [EntityTypes.CONTRACTROLE]: contractRoleActions.createContractRole,
    [EntityTypes.PROJECT]: projectActions.createProject,
    [EntityTypes.PROJECTRESOURCE]: projectResourceActions.createProjectResource,
    [EntityTypes.RESOURCE]: resourceActions.createResource,
    [EntityTypes.PERMISSION]: permissionActions.createPermission,
    [EntityTypes.ABSENCEPERIOD]: absencePeriodActions.createAbsencePeriod,
    [EntityTypes.ABSENCEPERIODCOMMENT]:
      absencePeriodCommentsActions.createAbsencePeriodComment,
    [EntityTypes.ABSENCEREGISTRATIONPROJECT]:
      absenceRegistrationProjectActions.createAbsenceRegistrationProject,
    [EntityTypes.WORKLOCATIONREGISTRATION]:
      workLocationRegistrationActions.createWorkLocationRegistration,
  },
  update: {
    [EntityTypes.ACCOUNT]: accountActions.updateAccount,
    [EntityTypes.CLIENT]: clientActions.updateClient,
    [EntityTypes.CONTRACT]: contractActions.updateContract,
    [EntityTypes.CONTRACTROLE]: contractRoleActions.updateContractRole,
    [EntityTypes.PROJECT]: projectActions.updateProject,
    [EntityTypes.PROJECTRESOURCE]: projectResourceActions.updateProjectResource,
    [EntityTypes.USER]: userActions.updateUser,
    [EntityTypes.RESOURCE]: resourceActions.updateResource,
    [EntityTypes.ABSENCEPERIOD]: absencePeriodActions.updateAbsencePeriod,
    [EntityTypes.ABSENCEREGISTRATIONPROJECT]:
      absenceRegistrationProjectActions.updateAbsenceRegistrationProject,
  },
  delete: {
    [EntityTypes.ACCOUNT]: accountActions.deleteAccount,
    [EntityTypes.CLIENT]: clientActions.deleteClient,
    [EntityTypes.CONTRACT]: contractActions.deleteContract,
    [EntityTypes.CONTRACTROLE]: contractRoleActions.deleteContractRole,
    [EntityTypes.PROJECT]: projectActions.deleteProject,
    [EntityTypes.PROJECTRESOURCE]: projectResourceActions.deleteProjectResource,
    [EntityTypes.RESOURCE]: resourceActions.deleteResource,
    [EntityTypes.PERMISSION]: permissionActions.deletePermission,
    [EntityTypes.ABSENCEPERIOD]: absencePeriodActions.deleteAbsencePeriod,
    [EntityTypes.ABSENCEREGISTRATIONPROJECT]:
      absenceRegistrationProjectActions.deleteAbsenceRegistrationProject,
    [EntityTypes.WORKLOCATIONREGISTRATION]:
      workLocationRegistrationActions.deleteWorkLocationRegistration,
  },
};

function* doEndEdit(action: any) {
  const { formCommands } = action.payload;

  for (let i = 0; i < formCommands.length; i++) {
    const command = FormCommand.deserialize(formCommands[i]);
    const modelType = command.getModelType();

    let action: any = null;
    if (command.isCreate()) {
      if (command.modelChanges) {
        const createFunction = actionMap.create[modelType];
        if (!createFunction) {
          throw new Error(
            "EntityEditor is missing a create mapping for form command"
          );
        }
        action = createFunction(
          mergeModels(command.model, command.modelChanges)
        );
      }
    } else if (command.isDelete()) {
      const deleteFunction = actionMap.delete[modelType];
      if (!deleteFunction) {
        throw new Error(
          "EntityEditor is missing a delete mapping for form command"
        );
      }
      action = deleteFunction(command.model);
    } else {
      const updateFunction = actionMap.update[modelType];
      if (!updateFunction) {
        throw new Error(
          "EntityEditor is missing an update mapping for form command"
        );
      }
      action = updateFunction(command.model, command.modelChanges);
    }
    if (action === null) {
      throw new Error(
        "EntityEditor unable to determine what to do for form command"
      );
    }

    yield put(action);
  }
  yield put(clearEdit());
}

export default function* endEdit() {
  yield takeLatest(types.APP_ENTITY_EDITOR_EDIT_END, doEndEdit);
}

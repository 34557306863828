import * as SagaHelpers from '../helpers';
import * as SagaEffects from 'redux-saga/effects';
import * as types from '../../constants/ActionTypes';
import AbsencePeriodCommentConnector from '../../connectors/AbsencePeriodComment';

const createAbsencePeriodComment = SagaHelpers.createApiHandler(
    types.API_ABSENCEPERIODCOMMENT_CREATE_INIT, 
    SagaHelpers.generateCreateApiCall(
        AbsencePeriodCommentConnector, 
        function*(connector: AbsencePeriodCommentConnector, action: any): any {
            return yield SagaEffects.call([connector, connector.createAbsencePeriodComment], action.payload.model);
        }
    )
)

const getAllAbsencePeriodComments = SagaHelpers.createApiHandler(
    types.API_ABSENCEPERIODCOMMENT_GET_ALL_INIT, 
    SagaHelpers.generateGetAllApiCall(
        AbsencePeriodCommentConnector, function*(connector: AbsencePeriodCommentConnector, action: any): any {
            const id = action.payload.model ? action.payload.model.id : null;
            return yield SagaEffects.call([connector, connector.getAbsencePeriodComments], id);
        }
    )
)

export default function* absencePeriodCommentSaga() {
    yield SagaEffects.all([
        SagaEffects.fork(getAllAbsencePeriodComments),
        SagaEffects.fork(createAbsencePeriodComment)
    ]);
}

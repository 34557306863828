import BaseConnector from './Base';

export default class ClientConnector extends BaseConnector {
    getClients(byAccountId = null, includes: string) {
        return this.authFetch(
            this.baseApiUrl + 'Clients/' +
            (byAccountId != null ? '?filter=account.id eq ' + byAccountId : '') +
            (includes ? '&includes=' + includes : '')
        );
    }

    createClient(clientDto: object){
        return this.authPost(this.baseApiUrl + 'Clients/', clientDto)
    }

    updateClient(clientId: string, clientDto: object){
        return this.authPatch(this.baseApiUrl + 'Clients/' + clientId, clientDto);
    }
    
    deleteClient(id: string){
        return this.authDelete(this.baseApiUrl + 'Clients/' + id);
    }
}

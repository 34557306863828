import BaseConnector from "./Base";

export default class WorkLocationRegistrationConnector extends BaseConnector {
  getAll({
    resourceIds,
    startTime,
    endTime,
  }: {
    resourceIds?: string[];
    startTime?: Date;
    endTime?: Date;
  } = {}) {
    const qs: string[] = [];

    if (resourceIds && resourceIds.length > 0) {
      qs.push(
        `(${resourceIds.map((rId) => `resource.id eq ${rId}`).join(" or ")})`
      );
    }
    if (startTime) {
      qs.push(`startTime gt ${startTime.toISOString()}`);
    }
    if (endTime) {
      qs.push(`endTime lt ${endTime.toISOString()}`);
    }

    return this.authFetch(
      this.baseApiUrl +
        "WorkLocationRegistrations/?includes=workLocation" +
        (qs.length > 0 ? `&filter=${qs.join(" and ")}` : "")
    );
  }

  delete(id: string) {
    return this.authDelete(this.baseApiUrl + "WorkLocationRegistrations/" + id);
  }

  create(workLocationRegistrationDto: object) {
    return this.authPost(
      this.baseApiUrl + "WorkLocationRegistrations/",
      workLocationRegistrationDto
    );
  }
}

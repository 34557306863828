import React, { Component } from "react";
import PropTypes from "prop-types";
import { Dropdown } from "@fluentui/react/lib/Dropdown";
import {
  createEmptyModelRelationships,
  createEmptyModelAttributes,
} from "../../lib/emptyModelCreation";
import { getAttr, getRelId, getEntityId } from "../../selectors/apihelper";
import SingleBaseForm from "./SingleBaseForm";
import DatePicker from "../uifabricextensions/DatePicker";
import Column from "../grid/Column";
import Row from "../grid/Row";
import CurrentUserContext from "../../contexts/CurrentUserContext";
import * as EntityTypes from "../../constants/EntityTypes";
import * as InternalPropTypes from "../../constants/PropTypes";
import Grid from "../grid/Grid";

export default class AbsencePeriodForm extends SingleBaseForm {
  constructor(props) {
    super(props);
    let { model } = props;
    this.state = {};

    this.state.fields = SingleBaseForm._initializeFields(
      {
        periodStart: {
          getVal: () => getAttr(this.props.model, "periodStart"),
          setVal: () =>
            createEmptyModelAttributes(EntityTypes.ABSENCEPERIOD, {
              periodStart: this.state.fields.periodStart.value,
            }),
          isRequired: true,
        },
        periodEnd: {
          getVal: () => getAttr(this.props.model, "periodEnd"),
          setVal: () =>
            createEmptyModelAttributes(EntityTypes.ABSENCEPERIOD, {
              periodEnd: this.state.fields.periodEnd.value,
            }),
          isRequired: false,
        },
        absencePeriodStatusId: {
          getVal: () => getRelId(this.props.model, "absencePeriodStatus"),
          setVal: () =>
            createEmptyModelRelationships(EntityTypes.ABSENCEPERIOD, {
              absencePeriodStatus: {
                id: this.state.fields.absencePeriodStatusId.value,
                type: EntityTypes.ABSENCEPERIODSTATUS,
              },
            }),
          isRequired: false,
        },
        absencePeriodTypeId: {
          getVal: () => getRelId(this.props.model, "absencePeriodType"),
          setVal: () =>
            createEmptyModelRelationships(EntityTypes.ABSENCEPERIOD, {
              absencePeriodType: {
                id: this.state.fields.absencePeriodTypeId.value,
                type: EntityTypes.ABSENCEPERIODTYPE,
              },
            }),
          isRequired: true,
        },
      },
      model
    );

    this._onStartChange = this._onStartChange.bind(this);
    this._onEndChange = this._onEndChange.bind(this);
    this._onAbsencePeriodStatusChange =
      this._onAbsencePeriodStatusChange.bind(this);
    this._onAbsencePeriodTypeChange =
      this._onAbsencePeriodTypeChange.bind(this);
  }

  static propTypes = {
    model: InternalPropTypes.absencePeriodEntity,
    onSave: PropTypes.func,
    onDismiss: PropTypes.func,
  };

  _onStartChange(value) {
    let fieldSpec = {
      periodStart: { value },
      periodEnd: {
        // take max
        value: [new Date(this.state.fields.periodEnd.value), value].reduce(
          function (a, b) {
            return a > b ? a : b;
          }
        ),
      },
    };

    this._updateFieldState(fieldSpec);
  }

  _onEndChange(value) {
    let fieldSpec = {
      periodEnd: { value },
      periodStart: {
        // take min
        value: [new Date(this.state.fields.periodStart.value), value].reduce(
          function (a, b) {
            return a < b ? a : b;
          }
        ),
      },
    };

    this._updateFieldState(fieldSpec);
  }

  _onAbsencePeriodStatusChange(value) {
    this._updateFieldState("absencePeriodStatusId", value.key);
  }

  _onAbsencePeriodTypeChange(value) {
    this._updateFieldState("absencePeriodTypeId", value.key);
  }

  render() {
    let { model, allAbsencePeriodStatus, allAbsencePeriodTypes } = this.props;
    let { fields } = this.state;

    let selectedStartDate =
      !fields.periodStart.value ||
      fields.periodStart.value.toString().startsWith("0001")
        ? ""
        : new Date(fields.periodStart.value);
    let selectedEndDate =
      !fields.periodEnd.value ||
      fields.periodEnd.value.toString().startsWith("0001")
        ? ""
        : new Date(fields.periodEnd.value);

    let isPeriodChanged =
      this.state.fields.periodEnd.isChanged ||
      this.state.fields.periodStart.isChanged;
    let minDate = isPeriodChanged ? selectedStartDate : undefined;
    let maxDate = isPeriodChanged ? selectedEndDate : undefined;

    let typeOptions = allAbsencePeriodTypes.map((apt) => ({
      key: getEntityId(apt),
      text: getAttr(apt, "name"),
    }));
    let statusOptions = allAbsencePeriodStatus.map((aps) => ({
      key: getEntityId(aps),
      text: getAttr(aps, "name"),
    }));
    return (
      <div>
        <h2>{model.id ? "Edit" : "Create"} Absence Request</h2>
        <br />
        <Grid>
          <Row>
            <Column sm={12} md={12} lg={6}>
              <DatePicker
                label="Start date (inclusive)"
                placeholder={selectedStartDate ? "" : "Select start date"}
                isRequired={fields.periodStart.isRequired}
                value={selectedStartDate}
                onSelectDate={this._onStartChange}
                maxDate={maxDate}
                displayArrows={false}
              />
            </Column>

            <Column sm={12} md={12} lg={6}>
              <DatePicker
                label="End date (inclusive)"
                placeholder={selectedEndDate ? "" : "Select end date"}
                isRequired={fields.periodEnd.isRequired}
                value={selectedEndDate}
                onSelectDate={this._onEndChange}
                minDate={minDate}
                displayArrows={false}
              />
            </Column>
          </Row>
        </Grid>
        <Dropdown
          onChange={(ev, option) => this._onAbsencePeriodTypeChange(option)}
          label="Type"
          required={this.state.fields.absencePeriodTypeId.isRequired}
          selectedKey={this.state.fields.absencePeriodTypeId.value}
          options={typeOptions}
        />
        {model.id ? (
          <CurrentUserContext.Consumer>
            {({ currentUserPermissionSet }) => {
              return currentUserPermissionSet.hasSelectedAccountRead() ? (
                <Dropdown
                  onChange={(ev, option) =>
                    this._onAbsencePeriodStatusChange(option)
                  }
                  label="Status"
                  required={this.state.fields.absencePeriodStatusId.isRequired}
                  selectedKey={this.state.fields.absencePeriodStatusId.value}
                  options={statusOptions}
                />
              ) : null;
            }}
          </CurrentUserContext.Consumer>
        ) : undefined}

        <br />

        {this._renderButtons()}
        <br />
        {this._renderTimestampsAndId(model)}
      </div>
    );
  }
}

import React from 'react';
import { withContext } from './withContext';

export type UrlConfig = {
  baseApiUrl: string,
  baseUrl: string,
  staticFilesUrl: string,
  imageFilesUrl: string
}

const urlConfig : UrlConfig = {
    baseApiUrl: '',
    baseUrl: '',
    staticFilesUrl: '',
    imageFilesUrl: '',
};

const UrlContext = React.createContext(urlConfig);
export default UrlContext;

export const withUrlContext = withContext(UrlContext, 'urlConfig');

export type WithUrlContextProps = {
  urlConfig: UrlConfig;
};

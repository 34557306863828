import { ResourceObject, ResourceObjects } from "./models";
import * as permissionTypeSelectors from '../selectors/reducers/permissionTypes';
import * as PermissionTypeNames from '../constants/PermissionTypeNames';
import * as apihelper from '../selectors/apihelper';
import * as contractSelectors from '../selectors/reducers/contract';
import * as EntityTypes from '../constants/EntityTypes';
import * as storehelper from '../selectors/storehelper';

export class PermissionSet {
    constructor(
        protected permissions: ResourceObjects, 
        protected contracts: ResourceObjects, 
        protected clients: ResourceObjects, 
        protected projects: ResourceObjects, 
        protected permissionTypes: ResourceObjects
    ){}

    private hasPermission({user, client, project, typeName, account}: { 
        user: string | ResourceObject, 
        project?: string | ResourceObject, 
        client?: string | ResourceObject, 
        account?: string | ResourceObject, 
        typeName: string
    }): boolean {
        const type = permissionTypeSelectors.findPermissionTypeByName(this.permissionTypes, typeName);
        const userId = typeof user === 'string' ? user : apihelper.getEntityId(user);
        const hasClient = !!client;
        const clientId = hasClient ? typeof client === 'string' ? client : apihelper.getEntityId(client) : null;
        const hasProject = !!project;
        const projectId = hasProject ? typeof project === 'string' ? project : apihelper.getEntityId(project) : null;
        const hasAccount = !!account;
        const accountId = hasAccount ? typeof account === 'string' ? account : apihelper.getEntityId(account) : null;
        return !!this.permissions.find(perm => 
            type && apihelper.relRefersToEntity(perm, 'permissionType', type) && 
            apihelper.relHasId(perm, 'user', userId) &&
            (!hasClient || apihelper.relHasId(perm, 'client', clientId)) &&
            (!hasProject || apihelper.relHasId(perm, 'project', projectId)) &&
            (!hasAccount || apihelper.relHasId(perm, 'account', accountId))
        );
    }

    userHasProjectRead(user: string | ResourceObject, project: string | ResourceObject): boolean {        
        const projectObject: ResourceObject | undefined = typeof project === 'string' ?
            storehelper.findById(this.projects, project) : project;
        const contractsForProject = contractSelectors.filterByProjects(this.contracts, projectObject || []);
        const clientId = contractsForProject.length > 0 ? apihelper.getRelId(contractsForProject[0], 'client') : null;
        return this.hasPermission({ user, typeName: PermissionTypeNames.PROJECT_READ, project: projectObject }) ||
               this.userHasProjectWrite(user, project) || 
               (!!clientId && this.userHasClientRead(user, clientId))
    }

    userHasProjectWrite(user: string | ResourceObject, project: string | ResourceObject): boolean {
        const projectObject: ResourceObject | undefined = typeof project === 'string' ?
            storehelper.findById(this.projects, project) : project;
        const contractsForProject = contractSelectors.filterByProjects(this.contracts, projectObject || []);
        const clientId = contractsForProject.length > 0 ? apihelper.getRelId(contractsForProject[0], 'client') : null;
        return this.hasPermission({ user, typeName: PermissionTypeNames.PROJECT_WRITE, project: projectObject }) ||
               (!!clientId && this.userHasClientWrite(user, clientId))
    }

    userHasClientRead(user: string | ResourceObject, client: string | ResourceObject): boolean {
        const clientObject: ResourceObject | undefined = typeof client === 'string' ? 
            storehelper.findById(this.clients, client) : client;
        return clientObject !== undefined &&
            (       
                this.hasPermission({ user, typeName: PermissionTypeNames.CLIENT_READ, client: clientObject }) ||
                this.userHasClientWrite(user, client) ||
                this.userHasAccountRead(user, apihelper.getRelId(clientObject, 'account') as string)
            );
    }

    userHasClientWrite(user: string | ResourceObject, client: string | ResourceObject): boolean {
        const clientObject: ResourceObject | undefined = typeof client === 'string' ? 
            storehelper.findById(this.clients, client) : client;
        return clientObject !== undefined &&
            (
                this.hasPermission({ user, typeName: PermissionTypeNames.CLIENT_WRITE, client: clientObject }) ||
                this.userHasAccountWrite(user, apihelper.getRelId(clientObject, 'account') as string)
            );
    }

    userHasAccountRead(user: string | ResourceObject, account: string | ResourceObject): boolean {
        return this.hasPermission({ user, typeName: PermissionTypeNames.ACCOUNT_READ, account}) ||
               this.userHasAccountWrite(user, account) ||
               this.userHasGlobalRead(user);
    }

    userHasAccountWrite(user: string | ResourceObject, account: string | ResourceObject): boolean {
        return this.hasPermission({ user, typeName: PermissionTypeNames.ACCOUNT_WRITE, account}) ||
               this.userHasGlobalWrite(user);
    }

    userHasGlobalRead(user: string | ResourceObject): boolean {
        return this.hasPermission({ user, typeName: PermissionTypeNames.GLOBAL_ACCOUNTANT}) ||
               this.userHasGlobalWrite(user);
    }

    userHasGlobalWrite(user: string | ResourceObject): boolean {
        return this.hasPermission({ user, typeName: PermissionTypeNames.GLOBAL_ADMIN});
    }
}

export class UserPermissionSet extends PermissionSet {
    constructor(
        protected permissions: ResourceObjects,
        protected contracts: ResourceObjects,
        protected clients: ResourceObjects,
        protected projects: ResourceObjects, 
        protected permissionTypes: ResourceObjects,
        protected user: ResourceObject,
        protected selectedAccount: ResourceObject){
        super(permissions, contracts, clients, projects, permissionTypes);
    }

    hasProjectRead(project: string | ResourceObject): boolean {
        return super.userHasProjectRead(this.user, project)
    }

    hasProjectWrite(project: string | ResourceObject): boolean {
        return super.userHasProjectWrite(this.user, project)
    }

    hasClientRead(client: string | ResourceObject): boolean {
        return super.userHasClientRead(this.user, client);
    }

    hasClientWrite(client: string | ResourceObject): boolean {
        return super.userHasClientWrite(this.user, client);
    }

    hasAccountRead(account: string | ResourceObject): boolean {
        return super.userHasAccountRead(this.user, account);
    }

    hasAccountWrite(account: string | ResourceObject): boolean {
        return super.userHasAccountWrite(this.user, account);
    }

    hasSelectedAccountRead(){
        return this.userHasAccountRead(this.user, this.selectedAccount);
    }

    hasSelectedAccountWrite(){
        return this.userHasAccountWrite(this.user, this.selectedAccount);
    }

    hasGlobalRead(): boolean {
        return super.userHasGlobalRead(this.user);
    }

    hasGlobalWrite(): boolean {
        return super.userHasGlobalWrite(this.user);
    }

    hasAnyPermission(): boolean {
        return this.permissions.some(perm => apihelper.relRefersToEntity(perm, 'user', this.user))
    }

    isCurrentUser(resourceOrUser: ResourceObject): boolean {
        const isResource = apihelper.entityHasType(resourceOrUser, EntityTypes.RESOURCE) && apihelper.relRefersToEntity(resourceOrUser, 'user', this.user);
        const isUser = apihelper.entityHasType(resourceOrUser, EntityTypes.USER) && apihelper.entityHasId(resourceOrUser, apihelper.getEntityId(this.user));
        return !!isResource || !!isUser;        
    }
}

import React, { Component } from 'react';
import PropTypes from 'prop-types';

/**
 * Component that alerts if you click outside of it
 */
export default class OutsideAlerter extends Component {
  constructor(props) {
    super(props);

    this.setWrapperRef = this.setWrapperRef.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  /**
   * Set the wrapper ref
   */
  setWrapperRef(node) {
    this.wrapperRef = node;
  }

  /**
   * Alert if clicked on outside of element
   */
  handleClickOutside(event) {
    // it has an inner element, the click is NOT in the inner element, but the click is on the page body (NOT on the scroll bar)
    if (this.wrapperRef && !this.wrapperRef.contains(event.target) && window.document.body.contains(event.target)) {
      if(this.props.onOutsideClick){
        this.props.onOutsideClick(event);
      }
    }
  }

  render() {
    return <div ref={this.setWrapperRef}>{this.props.children}</div>;
  }
}

//OutsideAlerter.propTypes = {
//  children: PropTypes.element.isRequired,
//};

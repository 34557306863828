import 'regenerator-runtime/runtime'
import { all, fork } from 'redux-saga/effects';
import editEnd from './editEnd'
import tempInternalClientId from './tempInternalClientId'

export default function* editorRootSaga() {
    yield all([
        fork(editEnd),
        fork(tempInternalClientId)
    ]);
}
import {put, takeEvery} from 'redux-saga/effects';
import * as types from '../../constants/ActionTypes';

function* doClaimChange(action) {
    yield put({ type: types.WRITEBACK_QUEUE_CLAIM_MODEL_FOR_WRITEBACK_CHANGE, payload: action.payload });
    yield put({ type: types.WRITEBACK_QUEUE_CLAIM_MODEL_FOR_WRITEBACK_DONE, payload: action.payload });
}

export default function* claimChange() {
    yield takeEvery(types.WRITEBACK_QUEUE_CLAIM_MODEL_FOR_WRITEBACK_INIT, doClaimChange);
}

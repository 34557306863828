import * as EntityTypes from "../constants/EntityTypes";
import deepmerge from "deepmerge";
import moment from "moment";
import {
  Attributes,
  NewResourceObject,
  Relationship,
  Relationships,
  ResourceIdentifier,
  ResourceObject,
} from "./models";

const defaultAttrs: Record<string, Record<string, any>> = {
  [EntityTypes.TIMEREGISTRATION]: {
    description: "",
    endTime: null,
    startTime: null,
    isBillable: true,
  },
  [EntityTypes.ACCOUNT]: {
    name: "",
    azureAdTenantId: "",
    licensesTotal: 0,
    licensesUsed: 0,
    defaultVacationHours: 0,
    defaultVacationResetDate: moment(
      new Date(new Date().getFullYear(), 5, 1)
    ).format("YYYY-MM-DD HH:mm:ss"),
  },
  [EntityTypes.CLIENT]: {
    name: "",
    description: "",
  },
  [EntityTypes.CONTRACT]: {
    title: "",
    description: "",
  },
  [EntityTypes.CONTRACTROLE]: {
    name: "",
    description: "",
    effectiveDate: new Date(),
    expiryDate: new Date(),
    hourPrice: null,
    budget: null,
    subContractorRate: null,
  },
  [EntityTypes.PROJECT]: {
    description: "",
    name: "",
    needsDescription: true,
  },
  [EntityTypes.PROJECTRESOURCE]: {
    effectiveDate: new Date(),
    expiryDate: new Date(),
    isDefaultProjectResource: false,
  },
  [EntityTypes.RESOURCE]: {
    eligibleForAbsenceRegistration: true,
    isActive: true,
    name: "",
    utilizationTarget: 0,
    vacationHours: 0,
    description: "",
    vacationYearStart: moment("20190501", "YYYYMMDD").format("YYYY-MM-DD"),
    payslipEmployeeName: null,
    payslipEmployeeNumber: null,
    isPaidByTheHour: null,
    isPermanentlyEmployed: null,
  },
  [EntityTypes.PERMISSION]: {},
  [EntityTypes.USER]: {
    active: true,
    email: "",
    name: "",
    uniqueIdentifier: "",
  },
  [EntityTypes.ABSENCEPERIOD]: {
    periodEnd: new Date(),
    periodStart: new Date(),
  },
  [EntityTypes.ABSENCEPERIODCOMMENT]: {
    addedDate: new Date(),
    comment: "",
  },
  [EntityTypes.ABSENCEREGISTRATIONPROJECT]: {},
  [EntityTypes.LOCKEDTIMEPERIOD]: {
    startDate: null,
    endDate: null,
  },
  [EntityTypes.WORKLOCATIONREGISTRATION]: {
    startTime: null,
    endTime: null,
    description: "",
  },
  [EntityTypes.WORKLOCATION]: {
    name: "",
    description: "",
  },
};

const relationships: Record<string, Record<string, any>> = {
  [EntityTypes.TIMEREGISTRATION]: {
    account: EntityTypes.ACCOUNT,
    contractRole: EntityTypes.CONTRACTROLE,
    createdByUser: EntityTypes.USER,
    project: EntityTypes.PROJECT,
    resource: EntityTypes.RESOURCE,
    timeRegistrationStatus: EntityTypes.TIMEREGISTRATIONSTATUS,
  },
  [EntityTypes.ABSENCEREGISTRATIONPROJECT]: {
    absencePeriodType: EntityTypes.ABSENCEPERIODTYPE,
    account: EntityTypes.ACCOUNT,
    project: EntityTypes.PROJECT,
  },
  [EntityTypes.ACCOUNT]: {
    internalClient: EntityTypes.CLIENT,
    primaryCurrency: EntityTypes.CURRENCY,
    primaryLanguage: EntityTypes.LANGUAGE,
    vacationProject: EntityTypes.PROJECT,
  },
  [EntityTypes.CLIENT]: {
    account: EntityTypes.ACCOUNT,
  },
  [EntityTypes.CONTRACT]: {
    client: EntityTypes.CLIENT,
  },
  [EntityTypes.CONTRACTROLE]: {
    currency: EntityTypes.CURRENCY,
    contract: EntityTypes.CONTRACT,
    billabilityType: EntityTypes.BILLABILITYTYPE,
  },
  [EntityTypes.PROJECT]: {
    contract: EntityTypes.CONTRACT,
    account: EntityTypes.ACCOUNT,
    projectStatus: EntityTypes.PROJECTSTATUS,
  },
  [EntityTypes.PROJECTRESOURCE]: {
    contractRole: EntityTypes.CONTRACTROLE,
    project: EntityTypes.PROJECT,
    resource: EntityTypes.RESOURCE,
  },
  [EntityTypes.RESOURCE]: {
    account: EntityTypes.ACCOUNT,
    employedAtLocale: EntityTypes.LOCALE,
    resourceType: EntityTypes.RESOURCETYPE,
    user: EntityTypes.USER,
  },
  [EntityTypes.PERMISSION]: {
    account: EntityTypes.ACCOUNT,
    client: EntityTypes.CLIENT,
    permissionType: EntityTypes.PERMISSIONTYPE,
    project: EntityTypes.PROJECT,
    user: EntityTypes.USER,
  },
  [EntityTypes.USER]: {
    account: EntityTypes.ACCOUNT,
    resource: EntityTypes.RESOURCE,
  },
  [EntityTypes.ABSENCEPERIOD]: {
    approvedByUser: EntityTypes.USER,
    requestedByUser: EntityTypes.USER,
    resource: EntityTypes.RESOURCE,
    absencePeriodStatus: EntityTypes.ABSENCEPERIODSTATUS,
    absencePeriodType: EntityTypes.ABSENCEPERIODTYPE,
  },
  [EntityTypes.ABSENCEPERIODCOMMENT]: {
    sentByUser: EntityTypes.USER,
    absencePeriod: EntityTypes.ABSENCEPERIOD,
  },
  [EntityTypes.LOCKEDTIMEPERIOD]: {
    resource: EntityTypes.RESOURCE,
  },
  [EntityTypes.WORKLOCATIONREGISTRATION]: {
    account: EntityTypes.ACCOUNT,
    resource: EntityTypes.RESOURCE,
    createdByUser: EntityTypes.USER,
    workLocation: EntityTypes.WORKLOCATION,
  },
  [EntityTypes.WORKLOCATION]: {
    account: EntityTypes.ACCOUNT,
  },
};

const toRelationshipType = (dto: ResourceObject) => ({
  data: dto == null ? null : { id: dto.id, type: dto.type },
});

type FlexibleRelationships = {
  [index: string]: Relationship | ResourceObject | ResourceIdentifier | null;
};

const isEntityType = (typeName: string) =>
  typeName &&
  (EntityTypes as { [key: string]: any })[typeName.toUpperCase()] === typeName;

export const getRelationshipType = (
  entityType: string,
  relationshipName: string
): string => {
  if (
    entityType in relationships &&
    relationshipName in relationships[entityType]
  ) {
    return relationships[entityType][relationshipName];
  }
  throw new Error(
    `No such entity type ${entityType} or relationship ${relationshipName} in entity type`
  );
};

export const createEmptyModelRelationships = (
  entityType: string,
  flexibleRelas: FlexibleRelationships = {}
): NewResourceObject => {
  const resultRelas: Relationships = {};

  Object.keys(flexibleRelas).forEach((key) => {
    if (!(key in relationships[entityType])) {
      throw new Error(
        "Unknown relationship key " + key + " for model type " + entityType
      );
    }

    if (
      flexibleRelas[key] === null ||
      isEntityType((flexibleRelas[key] as ResourceObject).type)
    ) {
      resultRelas[key] = toRelationshipType(
        flexibleRelas[key] as ResourceObject
      ) as any;
    } else if ((flexibleRelas[key] as Relationship).data) {
      resultRelas[key] = flexibleRelas[key] as Relationship;
    } else {
      throw new Error(
        "cannot create relationship type from " +
          JSON.stringify(flexibleRelas[key])
      );
    }

    const resultingType = (resultRelas[key]?.data as any)?.type;
    if (
      resultingType &&
      resultingType !== getRelationshipType(entityType, key)
    ) {
      throw new Error(
        "Provided type for relationship " +
          key +
          " of type " +
          entityType +
          " does not match expected type " +
          getRelationshipType(entityType, key)
      );
    }
  });

  return { relationships: resultRelas, type: entityType };
};

const toSerializable = (attrObject: Attributes) =>
  Object.keys(attrObject).reduce((newAttrObj, key) => {
    const val = attrObject[key];
    newAttrObj[key] = val instanceof Date ? val.toISOString() : val;
    return newAttrObj;
  }, {} as Attributes);

export const createEmptyModelAttributes = (
  type: string,
  attrs?: Attributes,
  includeDefaultAttrs = false
): NewResourceObject => {
  const defaultAttrNames = new Set(Object.keys(defaultAttrs[type]));
  const givenAttrNames = new Set(Object.keys(attrs || {}));
  const diffAttrNames = new Set(
    [...Array.from(givenAttrNames)].filter((x) => !defaultAttrNames.has(x))
  );
  if (diffAttrNames.size > 0) {
    throw new Error(
      "Cannot have attribute(s) with name(s) '" +
        Array.from(diffAttrNames).join(", ") +
        "' for type '" +
        type +
        "'"
    );
  }
  const attributes = toSerializable(
    Object.assign({}, includeDefaultAttrs ? defaultAttrs[type] : {}, attrs)
  );
  return { attributes, type };
};

export const createEmptyModel = (
  type: string,
  attrs?: Attributes,
  relas?: Relationships,
  includeDefaultAttrs = false
): NewResourceObject => {
  if (!(type in defaultAttrs && type in relationships)) {
    throw new Error(
      "Cannot create empty model for unknown type. Elaborate the model to cover the type"
    );
  }

  return Object.assign(
    { type },
    createEmptyModelRelationships(type, relas),
    createEmptyModelAttributes(type, attrs, includeDefaultAttrs)
  ) as NewResourceObject;
};

export const mergeModels = (target: object, ...sources: object[]) => {
  const dontMerge = (destination: any, source: any) => source;
  return sources.reduce(
    (acc, cur) => deepmerge(acc, cur, { arrayMerge: dontMerge }),
    target
  );
};

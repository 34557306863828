import * as types from '../../constants/ActionTypes';

export function createClient(client) {
    return {
        type: types.API_CLIENT_CREATE_INIT,
        payload: {
            model: client
        }
    };
}

export function deleteClient(client) {
    return {
        type: types.API_CLIENT_DELETE_INIT,
        payload: {model: client}
    };
}

export function getAllClients(account, included) {
    return {
        type: types.API_CLIENT_GET_ALL_INIT,
        payload: {
            account: account,
            included: included
        }
    };
}

export function updateClient(client, modelChanges) {
    return {
        type: types.API_CLIENT_UPDATE_INIT,
        payload: {
            model: client,
            changes: modelChanges
        }
    };
}

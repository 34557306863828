import * as SagaHelpers from '../helpers';
import * as SagaEffects from 'redux-saga/effects';
import * as types from '../../constants/ActionTypes';
import ClientConnector from '../../connectors/Client';

const createClient = SagaHelpers.createApiHandler(
    types.API_CLIENT_CREATE_INIT, 
    SagaHelpers.generateCreateApiCall(
        ClientConnector, 
        function*(connector: ClientConnector, action: any): any {
            return yield SagaEffects.call([connector, connector.createClient], {
                data: action.payload.model
            })
        }
    )
)

const deleteClient = SagaHelpers.createApiHandler(
    types.API_CLIENT_DELETE_INIT, 
    SagaHelpers.generateDeleteApiCall(
        ClientConnector,
        function*(connector: ClientConnector, action: any): any {
            const selectedClientId = action.payload.model.id;
            return yield SagaEffects.call([connector, connector.deleteClient], selectedClientId);
        }
    )
)

const getAllClients = SagaHelpers.createApiHandler(
    types.API_CLIENT_GET_ALL_INIT, 
    SagaHelpers.generateGetAllApiCall(
        ClientConnector, 
        function*(connector: ClientConnector, action: any): any {
            const accountId = action.payload.account ? action.payload.account.id : null;
            const included = action.payload.included === 'contracts' ? action.payload.included : null;
            return yield SagaEffects.call([connector, connector.getClients], accountId, included);
        }
    )
)

const updateClient = SagaHelpers.createApiHandler(
    types.API_CLIENT_UPDATE_INIT, 
    SagaHelpers.generateUpdateApiCall(
        ClientConnector, 
        function*(connector: ClientConnector, action: any, requestData: any): any {
            return yield SagaEffects.call([connector, connector.updateClient], action.payload.model.id, requestData);
        }
    )
)

export default function* clientSaga() {
    yield SagaEffects.all([
        SagaEffects.fork(getAllClients),
        SagaEffects.fork(updateClient),
        SagaEffects.fork(createClient),
        SagaEffects.fork(deleteClient),
    ]);
}

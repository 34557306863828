import {getAttr} from "../selectors/apihelper";
import { ResourceObject } from "./models";

export const isProjectResourceActiveAtDate = (date: Date) => (pr: ResourceObject) => {
    const effectiveDate = new Date(getAttr(pr, 'effectiveDate') as string);
    effectiveDate.setHours(0, 0, 0, 0);
    const expiryDate = new Date(getAttr(pr, 'expiryDate') as string);
    expiryDate.setHours(23, 59, 59, 999);
    return effectiveDate <= date && date <= expiryDate;
}

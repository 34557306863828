import * as types from '../../constants/ActionTypes';
import {removeItemFromList, updateDtoList} from '../helpers';
import {
  getAllProjectResourceList,
  getDeleteProjectResourceId,
  getUpsertProjectResource
} from '../../selectors/actions/projectResource';

const initialState = {
    storeRevision: 0,
    allProjectResources: []
};

export default function projectResourceReducer(state = initialState, action) {
    switch (action.type) {
        case types.API_PROJECTRESOURCE_GET_ALL_SUCCESS:
        {
            return updateDtoList(state, 'allProjectResources', getAllProjectResourceList(action));
        }

        case types.API_PROJECTRESOURCE_CREATE_SUCCESS:
        case types.API_PROJECTRESOURCE_UPDATE_SUCCESS:
        {
            return updateDtoList(state, 'allProjectResources', [getUpsertProjectResource(action)]);
        }
        case types.API_PROJECTRESOURCE_DELETE_SUCCESS: {
            return removeItemFromList(state, 'allProjectResources', getDeleteProjectResourceId(action));
        }

        default:
            return state;
    }
}

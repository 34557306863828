import React, { Component } from "react";
import PropTypes from "prop-types";
import {
  IBasePickerSuggestionsProps,
  NormalPeoplePicker,
  ValidationState,
} from "@fluentui/react/lib/Pickers";
import { PersonaPresence } from "@fluentui/react/lib/Persona";
import latinize from "latinize";
import * as InternalPropTypes from "../../constants/PropTypes";
import {
  getAttr,
  getEntityId,
  isEntity,
  entityHasId,
} from "../../selectors/apihelper";
import { getInitialsForNamedAttribute } from "../../lib/getInitialsForNamedAttribute";
import { ResourceObject, ResourceObjects } from "../../lib/models";
import { IPersonaProps } from "@fluentui/react";

type UserPickerProps = {
  users: ResourceObjects;
  selectedUsers: ResourceObjects;
  resources: ResourceObjects;
  disabled: boolean;
  onUserSelected: (user: ResourceObjects) => void;
};

function toPersona(user: ResourceObject): IPersonaProps {
  return {
    key: getEntityId(user) as string,
    imageInitials: getInitialsForNamedAttribute(user),
    text: getAttr(user, "name") as string,
    presence: PersonaPresence.none,
  };
}

function removeDuplicates(
  resources: ResourceObjects,
  currentSelection?: IPersonaProps[]
) {
  const curIds =
    (currentSelection && currentSelection.map((r) => r.key as string)) || [];
  return resources.filter((r) => !curIds.find((id) => entityHasId(r, id)));
}

export default class UserPicker extends Component<UserPickerProps> {
  constructor(props: UserPickerProps) {
    super(props);

    this._getMostRecentlyUsedUsers = this._getMostRecentlyUsedUsers.bind(this);
    this._onRemoveUserSelection = this._onRemoveUserSelection.bind(this);
    this._onValidateUserSelection = this._onValidateUserSelection.bind(this);
    this._onUserFilterChange = this._onUserFilterChange.bind(this);
    this._onUserChange = this._onUserChange.bind(this);
    this._getSuggestions = this._getSuggestions.bind(this);
  }

  static propTypes = {
    users: InternalPropTypes.userEntities,
    selectedUsers: InternalPropTypes.userEntities,
    disabled: PropTypes.bool,
    resources: InternalPropTypes.resourceTypeEntities,
    onUserSelected: PropTypes.func,
  };

  static defaultProps = {};

  _onUserFilterChange(filterText: string, currentSelection?: IPersonaProps[]) {
    const { users } = this.props;
    const limitResults = false;
    let res: IPersonaProps[] = [];

    if (filterText) {
      const usersThatMatches = users.filter((r) =>
        latinize(getAttr(r, "name") as string)
          .toLowerCase()
          .includes(latinize(filterText).toLowerCase())
      );
      let filteredPersonas = removeDuplicates(
        usersThatMatches,
        currentSelection
      );
      filteredPersonas = limitResults
        ? filteredPersonas.splice(0, limitResults)
        : filteredPersonas;
      res = filteredPersonas.map((r) => toPersona(r), this);
    }
    return res;
  }

  _getMostRecentlyUsedUsers() {
    return [];
  }

  _getSuggestions() {
    //Set current user and other randomly selected users to show as suggestions
    const { users } = this.props;
    const suggestions = [users[0]].filter((u) => isEntity(u));
    while (suggestions.length < 3 && suggestions.length !== users.length) {
      const userToAdd =
        users[Math.floor(Math.random() * (users.length - 1)) + 1];
      if (!suggestions.map(getEntityId).includes(getEntityId(userToAdd))) {
        suggestions.push(userToAdd);
      }
    }
    return suggestions.map(toPersona);
  }

  _onRemoveUserSelection(...args: any[]) {
    console.log("_onRemoveUserSelection", args);
  }

  _onValidateUserSelection(input: string): ValidationState {
    throw "not yet implemented";
  }

  _onUserChange(selectedItems?: IPersonaProps[]) {
    const { users, disabled } = this.props;
    if (!disabled) {
      const selectedIds =
        (selectedItems && selectedItems.map((i) => i.key as string)) || [];
      this.props.onUserSelected(
        users.filter((r) => selectedIds.find((id) => entityHasId(r, id)))
      );
    }
  }

  render() {
    const { selectedUsers, users, disabled } = this.props;

    const suggestionProps: IBasePickerSuggestionsProps = {
      suggestionsHeaderText: "Suggested People",
      mostRecentlyUsedHeaderText: "Suggested Contacts",
      noResultsFoundText: "No results found",
      loadingText: "Loading",
      showRemoveButtons: true,
      suggestionsAvailableAlertText: "People Picker Suggestions available",
      suggestionsContainerAriaLabel: "Suggested contacts",
    };

    return (
      <NormalPeoplePicker
        className={"ms-PeoplePicker"}
        key={"resource-picker"}
        itemLimit={1}
        selectedItems={selectedUsers ? selectedUsers.map(toPersona) : undefined}
        onResolveSuggestions={this._onUserFilterChange}
        onEmptyInputFocus={this._getSuggestions}
        getTextFromItem={(r, curVal) => r.text || ""}
        pickerSuggestionsProps={suggestionProps}
        onRemoveSuggestion={this._onRemoveUserSelection}
        onValidateInput={this._onValidateUserSelection}
        removeButtonAriaLabel={"Remove"}
        onChange={this._onUserChange}
        resolveDelay={0}
        disabled={disabled}
      />
    );
  }
}

import * as SagaHelpers from '../helpers';
import * as SagaEffects from 'redux-saga/effects';
import * as types from '../../constants/ActionTypes';
import ContractConnector from '../../connectors/Contract';
import * as apihelper from '../../selectors/apihelper';

const createContract = SagaHelpers.createApiHandler(
    types.API_CONTRACT_CREATE_INIT, 
    SagaHelpers.generateCreateApiCall(
        ContractConnector, 
        function*(connector: ContractConnector, action: any): any {
            return yield SagaEffects.call([connector, connector.createContract], {
                data: action.payload.model
            });
        }
    )
)

const deleteContract = SagaHelpers.createApiHandler(
    types.API_CONTRACT_DELETE_INIT, 
    SagaHelpers.generateDeleteApiCall(
        ContractConnector, 
        function*(connector: ContractConnector, action: any): any {
            const selectedContract = action.payload.model.id;
            return yield SagaEffects.call([connector, connector.deleteContract], selectedContract);
        }
    )
)

const getAllContract = SagaHelpers.createApiHandler(
    types.API_CONTRACT_GET_ALL_INIT, 
    SagaHelpers.generateGetAllApiCall(
        ContractConnector, 
        function*(connector: ContractConnector, action: any): any {
            const { client } = action.payload;
            let clientId = null;
            if(apihelper.isEntity(client)){
                clientId = apihelper.getEntityId(client);
            }
            else if (typeof client === 'string'){
                clientId = client;
            }
            const included = action.payload.included === 'projects' ? action.payload.included : null;
            return yield SagaEffects.call([connector, connector.getContracts], clientId, included);
        }
    )
)

const updateContract = SagaHelpers.createApiHandler(
    types.API_CONTRACT_UPDATE_INIT, 
    SagaHelpers.generateUpdateApiCall(
        ContractConnector,
        function*(connector: ContractConnector, action: any, requestData: any): any {
            return yield SagaEffects.call([connector, connector.updateContract], action.payload.model.id, requestData);
        }
    )
)

export default function* contractSaga() {
    yield SagaEffects.all([
        SagaEffects.fork(getAllContract),
        SagaEffects.fork(updateContract),
        SagaEffects.fork(createContract),
        SagaEffects.fork(deleteContract),
    ]);
}

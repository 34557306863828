import * as actionhelper from '../actionhelper';
import * as EntityTypes from '../../constants/EntityTypes';

export const getAllPermissionList = actionhelper.apiResponseData;

export const getUpsertPermission = actionhelper.apiResponseData;

export const getDeletePermissionId = actionhelper.apiSentModelId;

export const getIncludedPermissions = (action: any) => actionhelper.apiResponseIncludedOfType(action, EntityTypes.PERMISSION);

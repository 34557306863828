import { ResourceObject, ResourceObjects } from "../../lib/models";
import * as storehelper from "../../selectors/storehelper";
import * as WorkLocationNames from "../../constants/WorkLocationNames";

export const findWorkLocationByName = (
  workLocations: ResourceObjects,
  name: string
): ResourceObject | undefined =>
  storehelper.findByAttr(workLocations, "name", name);

export const allWorkLocations = (state: any) =>
  state.api.workLocation.allWorkLocations;

export const workLocationHome = (state: any) =>
  findWorkLocationByName(allWorkLocations(state), WorkLocationNames.HOME);

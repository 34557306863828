import * as types from '../../constants/ActionTypes';
import { ResourceObject, ResourceObjects } from '../../lib/models';

export function createTimeRegistration(timeRegistration: ResourceObject, transactionId: string, queueId: string) {
    return {
        type: types.API_TIMEREGISTRATION_CREATE_INIT,
        payload: {
            model: timeRegistration,
            transactionId,
            queueId
        }
    };
}

export function deleteTimeRegistration(timeRegistration: ResourceObject) {
    return {
        type: types.API_TIMEREGISTRATION_DELETE_INIT,
        payload: {model: timeRegistration}
    };
}

export function getAllTimeRegistrations({ 
    project = undefined, 
    projects = undefined, 
    resource = undefined, 
    resources = undefined, 
    client = undefined, 
    startTime = undefined, 
    endTime = undefined, 
    transactionId = undefined }: {
        project?: ResourceObject,
        projects?: ResourceObjects,
        resource?: ResourceObject,
        resources?: ResourceObjects,
        client?: ResourceObject,
        startTime?: Date | string,
        endTime?: Date | string,
        transactionId?: string
    } = {}, includes: string[] = []
) {
    if (!Array.isArray(resources)) {
        resources = [];
    }   
    if (resource) {
        resources = resources.slice();
        resources.push(resource);
    }

    return {
        type: types.API_TIMEREGISTRATION_GET_ALL_INIT,
        payload: {
            parameters: {
                project,
                projects,
                resources,
                client,
                startTime: startTime instanceof Date ? startTime.toISOString() : startTime,
                endTime: endTime instanceof Date ? endTime.toISOString() : endTime
            },
            includes,
            transactionId
        }
    };
}

export function updateTimeRegistration(
    timeRegistration: ResourceObject,
    modelChanges: ResourceObject,
    transactionId: string,
    queueId: string
) {
    return {
        type: types.API_TIMEREGISTRATION_UPDATE_INIT,
        payload: {
            model: timeRegistration,
            changes: modelChanges,
            transactionId,
            queueId
        }
    };
}

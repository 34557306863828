import * as SagaHelpers from '../helpers';
import * as SagaEffects from 'redux-saga/effects';
import * as types from '../../constants/ActionTypes';
import UserConnector from '../../connectors/Users';

const createUser = SagaHelpers.createApiHandler(
    types.API_USER_CREATE_INIT, 
    SagaHelpers.generateCreateApiCall(
        UserConnector,
        function*(connector: UserConnector, action: any): any {
            return yield SagaEffects.call([connector, connector.createUser], {
                data: action.payload.model
            });
        }
    )
)

const getAllUsers = SagaHelpers.createApiHandler(
    types.API_USER_GET_ALL_INIT, 
    SagaHelpers.generateGetAllApiCall(
        UserConnector,
        function*(connector: UserConnector, action: any): any {
            const accountId = action.payload.account ? action.payload.account.id : null;
            return yield SagaEffects.call([connector, connector.getUsers], accountId);
        }
    )
)

const getMe = SagaHelpers.createApiHandler(
    types.API_USER_GET_ME_INIT, 
    SagaHelpers.generateGetAllApiCall(
        UserConnector,
        function*(connector: UserConnector): any {
            return yield SagaEffects.call([connector, connector.getMe], ['account', 'resource']);
        }
    )
)

const updateUser = SagaHelpers.createApiHandler(
    types.API_USER_UPDATE_INIT, 
    SagaHelpers.generateUpdateApiCall(
        UserConnector, 
        function*(connector: UserConnector, action: any, requestData: any): any {
            return yield SagaEffects.call([connector, connector.updateUser], action.payload.model.id, requestData);
        }
    )
)

export default function* userSaga() {
    yield SagaEffects.all([
        SagaEffects.fork(getMe),
        SagaEffects.fork(getAllUsers),
        SagaEffects.fork(updateUser),
        SagaEffects.fork(createUser),
    ]);
}

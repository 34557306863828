import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Label } from "@fluentui/react/lib/Label";
import { ActionButton, DefaultButton } from "@fluentui/react/lib/Button";
import moment from "moment";
import DatePicker from "../../components/uifabricextensions/DatePicker";
import { Resource } from "../../components/uifabricextensions/Resource";
import BasePickerSimple from "../../components/uifabricextensions/BasePickerSimple";
import HorizontalRule from "../../components/HorizontalRule";
import TimeRegistrationList from "../../components/TimeRegistrationList";
import Grid from "../../components/grid/Grid";
import Row from "../../components/grid/Row";
import Column from "../../components/grid/Column";
import BillableSet from "../../lib/BillableSet";
import { ClientAndProjectTagPicker } from "../../components/uifabricextensions/ClientAndProjectTagPicker";
import { ParentOptions } from "../../components/uifabricextensions/ClientAndProjectPicker";
import * as billabilityTypes from "../../constants/BillabilityTypes";
import * as timeRegistrationExportActions from "../../actions/pages/timeRegistrationExport";
import * as contractRoleSelectors from "../../selectors/reducers/contractRole";
import * as billabilityTypeSelectors from "../../selectors/reducers/billabilityTypes";
import * as timeRegistrationExportSelectors from "../../selectors/pages/timeRegistrationExport";
import * as appDomainSelectors from "../../selectors/app/domain";
import * as apihelper from "../../selectors/apihelper";
import * as icons from "../../constants/Icons";
import * as ExcelJs from "exceljs";
import * as EntityTypes from "../../constants/EntityTypes";
import * as contractSelectors from "../../selectors/reducers/contract";
import * as resourceTypeSelectors from "../../selectors/reducers/resourceType";

class TimeRegistrationExport extends Component {
  constructor(props) {
    super(props);
    this._onSelectStartDate = this._onSelectStartDate.bind(this);
    this._onSelectEndDate = this._onSelectEndDate.bind(this);
    this._onSelectionProjects = this._onSelectionProjects.bind(this);
    this._onChangeResourceType = this._onChangeResourceType.bind(this);
    this._onChangeResource = this._onChangeResource.bind(this);
    this._onSelectRemoveAllResources =
      this._onSelectRemoveAllResources.bind(this);
    this._registrationMapper = this._registrationMapper.bind(this);
    this._onExcelExport = this._onExcelExport.bind(this);
    this._renderDetailsListColumn = this._renderDetailsListColumn.bind(this);
    this._onViewDescChange = this._onViewDescChange.bind(this);
  }

  componentDidMount() {
    this.props.actions.pageOpened();
  }

  _onSelectStartDate(date) {
    let { endTime } = this.props;
    this.props.actions.selectDates(
      moment(date).startOf("day").toDate(),
      endTime
    );
  }

  _onSelectEndDate(date) {
    let { startTime } = this.props;
    this.props.actions.selectDates(
      startTime,
      moment(date).endOf("day").toDate()
    );
  }

  _onSelectionProjects(selectedProjectIds) {
    this.props.actions.selectProjectIds(selectedProjectIds);
  }

  _onChangeResourceType(selectedEntity) {
    let { selectedResourceTypeIds } = this.props;

    let isRemove = selectedResourceTypeIds.includes(
      apihelper.getEntityId(selectedEntity)
    );
    let updatedSelectedResourceTypeIds = isRemove
      ? selectedResourceTypeIds.filter(
          (id) => !apihelper.entityHasId(selectedEntity, id)
        )
      : [...selectedResourceTypeIds, apihelper.getEntityId(selectedEntity)];

    this.props.actions.selectResourceTypeIds(updatedSelectedResourceTypeIds);
  }

  _onChangeResource(selectedEntity) {
    let { selectedResourceIds } = this.props;

    if (selectedResourceIds.includes(apihelper.getEntityId(selectedEntity))) {
      //If we press a already selected resource
      selectedResourceIds = selectedResourceIds.filter(
        (id) => !apihelper.entityHasId(selectedEntity, id)
      ); //Filter selectedresourceID based on the ID of the chosen resource. That is; retain only that ID
    } else {
      selectedResourceIds = selectedResourceIds.slice();
      selectedResourceIds.push(apihelper.getEntityId(selectedEntity)); //Else add the chosen Id to selectedresourceID
    }

    this.props.actions.selectResourceIds(selectedResourceIds);
  }

  _onSelectRemoveAllResources() {
    this.props.actions.selectResourceIds([]);
  }

  _onViewDescChange(viewDesc) {
    this.props.actions.updateViewDesc(viewDesc);
  }

  _registrationMapper(tr) {
    let {
      allContracts,
      allProjects,
      allClients,
      allContractRoles,
      selectableResources,
      billableSet,
    } = this.props;

    let project = allProjects.find((p) =>
      apihelper.relRefersToEntity(tr, "project", p)
    );
    let resource = selectableResources.find((r) =>
      apihelper.relRefersToEntity(tr, "resource", r)
    );
    let contractRole = allContractRoles.find((cr) =>
      apihelper.relRefersToEntity(tr, "contractRole", cr)
    );
    let contract =
      apihelper.isEntity(project) &&
      allContracts.find((co) =>
        apihelper.relRefersToEntity(project, "contract", co)
      );
    let client =
      apihelper.isEntity(contract) &&
      allClients.find((c) =>
        apihelper.relRefersToEntity(contract, "client", c)
      );
    let clientString = client ? apihelper.getAttr(client, "name") : "";
    let clientId = client ? apihelper.getEntityId(client) : "";
    let projectString = project ? apihelper.getAttr(project, "name") : "";
    let projectId = project ? apihelper.getEntityId(project) : "";
    let contractString = contract ? apihelper.getAttr(contract, "title") : "";
    let contractId = contract ? apihelper.getEntityId(contract) : "";
    let contractRoleString = contractRole
      ? apihelper.getAttr(contractRole, "name")
      : "";
    let contractRoleId = contractRole
      ? apihelper.getEntityId(contractRole)
      : "";
    let resourceString = resource ? apihelper.getAttr(resource, "name") : "";
    let resourceId = resource ? apihelper.getEntityId(resource) : "";
    let timeregId = tr ? apihelper.getEntityId(tr) : "";
    let timeregAccountId = tr ? apihelper.getRelId(tr, "account") : "";
    let timeregCreatedByUserId = tr
      ? apihelper.getRelId(tr, "createdByUser")
      : "";
    let timeregStatusId = tr
      ? apihelper.getRelId(tr, "timeRegistrationStatus")
      : "";
    let timeRegDuration =
      moment(apihelper.getAttr(tr, "endTime")).diff(
        moment(apihelper.getAttr(tr, "startTime")),
        "minute"
      ) / 60;
    let timeregStartTime = tr
      ? moment(apihelper.getAttr(tr, "startTime")).format("YYYY-MM-DD HH:mm:ss")
      : "";
    let timeregEndTime = tr
      ? moment(apihelper.getAttr(tr, "endTime")).format("YYYY-MM-DD HH:mm:ss")
      : "";
    let timeregAddedAt = tr
      ? moment(apihelper.getAttr(tr, "addedDate")).format("YYYY-MM-DD HH:mm:ss")
      : "";
    let timeregModifiedAt = tr
      ? moment(apihelper.getAttr(tr, "modifiedDate")).format(
          "YYYY-MM-DD HH:mm:ss"
        )
      : "";
    let description = apihelper.getAttr(tr, "description");
    let isoWeekNumber = tr
      ? moment(apihelper.getAttr(tr, "startTime")).isoWeek()
      : "";

    let timeRegIsBillable =
      apihelper.getAttr(tr, "isBillable") != null
        ? apihelper.getAttr(tr, "isBillable")
        : "";
    let contractRoleBillabilityType = billableSet.getBillableTypeNameFromId(
      apihelper.getRelId(contractRole, "billabilityType")
    );
    let isBillable =
      timeRegIsBillable &&
      contractRoleBillabilityType &&
      contractRoleBillabilityType !== billabilityTypes.NOT_BILLABLE;

    let resultArray = [
      clientString,
      clientId,
      projectString,
      projectId,
      contractString,
      contractId,
      contractRoleString,
      contractRoleId,
      resourceString,
      resourceId,
      timeregId,
      timeregAccountId,
      timeregCreatedByUserId,
      timeregStatusId,
      timeRegDuration,
      timeregStartTime,
      timeregEndTime,
      timeregAddedAt,
      timeregModifiedAt,
      description,
      isoWeekNumber,
    ];

    const hasTimeregBillabilityAccess = typeof timeRegIsBillable === "boolean";
    const hasContractRoleAccess = !!contractRoleBillabilityType;
    if (hasTimeregBillabilityAccess || hasContractRoleAccess) {
      resultArray.push(
        hasContractRoleAccess ? contractRoleBillabilityType : ""
      );
      resultArray.push(hasTimeregBillabilityAccess ? timeRegIsBillable : "");
      resultArray.push(
        typeof contractRoleBillabilityType !== "undefined" ? isBillable : ""
      );
    }

    return resultArray;
  }

  _onExcelExport() {
    let { timeRegs } = this.props;

    // prepare export data
    let headers = [
      "Client",
      "ClientId",
      "Project",
      "ProjectId",
      "Contract",
      "ContractId",
      "ContractRole",
      "ContractRoleId",
      "Resource",
      "ResourceId",
      "TimeRegId",
      "TimeRegAccountId",
      "TimeRegCreatedByUserId",
      "TimeRegStatusId",
      "TimeRegDuration",
      "TimeRegStartTime",
      "TimeRegEndTime",
      "TimeRegAddedAt",
      "TimeRegModifiedAt",
      "Description",
      "IsoWeekNumber",
    ];

    let rows = timeRegs.map((tr) => this._registrationMapper(tr), this);
    // deduce from data returned from api, if user has access to billability data
    let maxLengthRow = rows.reduce((acc, cur) => Math.max(acc, cur.length), 0);
    if (maxLengthRow > headers.length) {
      headers.push.apply(headers, [
        "contractRoleBillabilityType",
        "timeRegIsBillable",
        "isBillable",
      ]);
    }

    // setup excel file
    const workbook = new ExcelJs.Workbook();
    workbook.creator = "NovaTime";
    workbook.lastModifiedBy = "NovaTime";
    workbook.created = new Date();
    workbook.modified = new Date();

    const timeRegSheet = workbook.addWorksheet("TimeRegistrations");
    timeRegSheet.addTable({
      name: "TimeRegistrations",
      ref: "A1",
      headerRow: true,
      columns: headers.map((headerName) => ({
        name: headerName,
        filterButton: true,
      })),
      rows,
    });

    // send file via browser link
    workbook.xlsx
      .writeBuffer()
      .then((buffer) => {
        let blob = new Blob([buffer], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        const element = document.createElement("a");
        element.href = URL.createObjectURL(blob);
        element.download =
          "timeRegistrations-" +
          moment().format("YYYY-MM-DDTHH:mm:ss") +
          ".xlsx";
        document.body.appendChild(element);
        element.click();
        document.body.removeChild(element);
      })
      .catch((e) => {
        console.warn("failed to get buffer for exporting xlsx", e);
      });
  }

  _renderDetailsListColumn(item, index, column) {
    const fieldContent = item[column.fieldName];
    switch (column.key) {
      case "tr-resource":
        return <Resource model={fieldContent} />;
      default:
        return <span>{fieldContent}</span>;
    }
  }

  render() {
    let {
      startTime,
      endTime,
      allClients,
      allProjects,
      selectedResourceTypes,
      resourceTypeOptions,
      selectableResources,
      timeRegs,
      allContracts,
      viewDesc,
      selectedResources,
      selectedProjectsForTagPicker,
      addDividerByChangeInResourceTypeId,
    } = this.props;

    let disabled = timeRegs.length === 0;

    return (
      <div className="novatime-page-timeregistrationexport">
        <div className="novatime-container">
          <Grid>
            <Row>
              <Column sm={12} lg={4} xl={4}>
                <Label>Start date</Label>
                <DatePicker
                  value={startTime}
                  onSelectDate={this._onSelectStartDate}
                />
                <div className="height2em ms-hiddenXlUp" />
              </Column>
              <Column sm={12} lg={4} xl={4}>
                <Label>End date</Label>
                <DatePicker
                  value={endTime}
                  onSelectDate={this._onSelectEndDate}
                />
                <div className="height2em ms-hiddenXlUp" />
              </Column>
            </Row>
            <br />
            <Row>
              <Column sm={12} lg={5} xl={5}>
                <Label>Projects</Label>
                <ClientAndProjectTagPicker
                  onSelection={this._onSelectionProjects}
                  entities={allProjects.concat(allClients)}
                  hierarchyData={{
                    allProjects,
                    allClients,
                    allContracts,
                  }}
                  getEntityParentFunction={(entity, hierarchyData) => {
                    let eType = apihelper.getEntityType(entity);
                    if (eType === EntityTypes.PROJECT) {
                      let contract = hierarchyData.allContracts.find(
                        (contract) =>
                          apihelper.relRefersToEntity(
                            entity,
                            "contract",
                            contract
                          )
                      );
                      if (contract) {
                        let client = hierarchyData.allClients.find((client) =>
                          apihelper.relRefersToEntity(
                            contract,
                            "client",
                            client
                          )
                        );
                        if (client) {
                          return {
                            type: ParentOptions.PARENT, //projects have parents, clients are orphans
                            value: client,
                          };
                        }
                      }
                    }
                    return {
                      type: ParentOptions.ORPHAN,
                    };
                  }}
                  selectedProjects={selectedProjectsForTagPicker}
                />
              </Column>
              <Column sm={12} lg={5} xl={5}>
                <Label>Resources</Label>
                <div className="novatime-timeregexportresourcepicker-container">
                  <div className="novatime-timeregexportresourcepicker-dropdown">
                    <BasePickerSimple
                      placeholder={"All resources"}
                      selectedEntities={selectedResources}
                      entities={selectableResources}
                      multiSelect={true}
                      onEntitySelected={this._onChangeResource}
                      nameFunction={(e) => e.attributes.name}
                      idFunction={(e) => e.id}
                      dividerList={addDividerByChangeInResourceTypeId} //array of objects with keys (entityId, headerName, dividerId) - divider and header should be added in change of dividerId
                    />
                  </div>
                  <div className="novatime-timeregexportresourcepicker-removeall">
                    <DefaultButton
                      allowDisabledFocus={true}
                      disabled={false}
                      checked={true}
                      text="Clear"
                      onClick={this._onSelectRemoveAllResources}
                    />
                  </div>
                </div>
              </Column>
              <Column sm={12} lg={2} xl={2}>
                <BasePickerSimple
                  label={"Resource Type"}
                  placeholder={"Select resource type"}
                  selectedEntities={selectedResourceTypes}
                  entities={resourceTypeOptions}
                  multiSelect={true}
                  onEntitySelected={this._onChangeResourceType}
                  nameFunction={(e) => e.attributes.name}
                  idFunction={(e) => e.id}
                />
                <div className="height2em" />
              </Column>
            </Row>
          </Grid>
        </div>
        <div className="novatime-container">
          <div className="height4em hiddenLgUp" />
          {timeRegs.length > 0 && (
            <HorizontalRule
              text={"Time Registrations - " + timeRegs.length + " found"}
              alignment="left"
            />
          )}
          <div
            className="novatime-excelexport-button"
            style={{ marginTop: "25px" }}
          >
            <ActionButton
              iconProps={{
                iconName: icons.ICON_NAME_EXCELEXPORT,
                style: {
                  backgroundColor: "white",
                  color: disabled ? "grey" : "green",
                },
              }}
              onClick={this._onExcelExport}
              title={"Export time registrations to Excel"}
              disabled={disabled}
            >
              Export
            </ActionButton>
          </div>
          <TimeRegistrationList
            projects={allProjects}
            clients={allClients}
            columnNames={[
              "project",
              "resource",
              "date",
              "duration",
              "description",
            ]}
            resources={selectableResources}
            contracts={allContracts}
            timeRegistrations={timeRegs}
            onRenderColumn={this._renderDetailsListColumn}
            selectedTimeRegistration={null}
            onSelect={null}
            showIfNoTimeRegistrations={true}
            viewDesc={viewDesc}
            onViewDescChange={this._onViewDescChange}
          />
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  let localStartTime = timeRegistrationExportSelectors.startTime(state), //Chosen start date
    localEndTime = timeRegistrationExportSelectors.endTime(state), //Chosen end date
    localSelectedResourceIds =
      timeRegistrationExportSelectors.selectedResourceIds(state),
    localAllContractRoles = contractRoleSelectors.allContractRoles(state),
    localViewDesc = timeRegistrationExportSelectors.viewDesc(state),
    billableSet = new BillableSet(
      billabilityTypeSelectors.allBillabilityTypes(state)
    );

  let allContracts = contractSelectors.allContracts(state);
  let allClients = appDomainSelectors.allClientsForSelectedAccount(state);

  let allProjects =
    timeRegistrationExportSelectors.allProjectsForAccount(state);
  let localSelectedResourceTypeIds =
    timeRegistrationExportSelectors.selectedResourceTypeIds(state);
  let selectedResourceTypes =
    timeRegistrationExportSelectors.selectedResourceTypes(state);
  let resourceTypeOptions =
    timeRegistrationExportSelectors.resourceTypeOptions(state);
  let selectableResources =
    timeRegistrationExportSelectors.selectableResources(state);
  let selectedResources =
    timeRegistrationExportSelectors.selectedResources(state);
  let timeRegs =
    timeRegistrationExportSelectors.timeRegistrationsForCurrentFilter(state);
  let selectedProjectsForTagPicker =
    timeRegistrationExportSelectors.selectedProjectsForTagPicker(state);

  let addDividerByChangeInResourceTypeId = selectableResources
    .slice()
    .map((resource) => {
      if (!apihelper.getAttr(resource, "isActive")) {
        return {
          entityId: apihelper.getEntityId(resource),
          headerName: "Inactive Resources",
          dividerId: resourceTypeOptions.length,
        };
      }
      return {
        entityId: apihelper.getEntityId(resource),
        headerName: resourceTypeSelectors.resourceTypeNameById(
          state,
          apihelper.getRelId(resource, "resourceType")
        ),
        dividerId: apihelper.getRelId(resource, "resourceType"),
      };
    });

  return {
    startTime: localStartTime,
    endTime: localEndTime,
    allClients: allClients,
    allProjects: allProjects,
    selectedResourceTypeIds: localSelectedResourceTypeIds,
    selectedResourceTypes: selectedResourceTypes,
    resourceTypeOptions: resourceTypeOptions,
    selectableResources: selectableResources,
    selectedResourceIds: localSelectedResourceIds,
    allContractRoles: localAllContractRoles,
    billableSet,
    viewDesc: localViewDesc,
    allContracts: allContracts,
    timeRegs: timeRegs,
    selectedResources,
    selectedProjectsForTagPicker: selectedProjectsForTagPicker,
    addDividerByChangeInResourceTypeId,
  };
}

function mapDispatch(dispatch) {
  const actions = Object.assign({}, timeRegistrationExportActions);
  return { actions: bindActionCreators(actions, dispatch) };
}

export default connect(mapStateToProps, mapDispatch)(TimeRegistrationExport);

import * as actionhelper from '../actionhelper';
import * as EntityTypes from '../../constants/EntityTypes';

export const getAllContractList = actionhelper.apiResponseData;

export const getUpsertContract = actionhelper.apiResponseData;

export const getDeleteContractId = actionhelper.apiSentModelId;

export const getIncludedContracts = (action: any) => actionhelper.apiResponseIncludedOfType(action, EntityTypes.CONTRACT);

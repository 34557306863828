import {combineReducers} from "redux";
import clientsAndContracts from "./clientsAndContracts";
import usersAndResources from './usersAndResources';
import projectsAndRoles from './projectsAndRoles';
import timeRegistration from './timeRegistration';
import timeRegistrationExport from './timeRegistrationExport';
import resourceManagement from './resourceManagement';
import absencePeriod from './absencePeriod';

export default combineReducers({
    clientsAndContracts,
    projectsAndRoles,
    usersAndResources,
    timeRegistration,
    timeRegistrationExport,
    resourceManagement: resourceManagement,
    absencePeriod
});

import * as SagaHelpers from '../helpers';
import * as SagaEffects from 'redux-saga/effects';
import * as types from '../../constants/ActionTypes';
import ProjectResourceConnector from '../../connectors/ProjectResource';
import * as apihelper from '../../selectors/apihelper';
import * as projectSelectors from '../../selectors/reducers/project';
import * as contractSelectors from '../../selectors/reducers/contract';

const createProjectResource = SagaHelpers.createApiHandler(
    types.API_PROJECTRESOURCE_CREATE_INIT, 
    SagaHelpers.generateCreateApiCall(
        ProjectResourceConnector,
        function*(connector: ProjectResourceConnector, action: any): any {
            return yield SagaEffects.call([connector, connector.createProjectResource], {
                data: action.payload.model
            });
        }
    )
)

const deleteProjectResource = SagaHelpers.createApiHandler(
    types.API_PROJECTRESOURCE_DELETE_INIT, 
    SagaHelpers.generateDeleteApiCall(
        ProjectResourceConnector,
        function*(connector: ProjectResourceConnector, action: any): any {
            return yield SagaEffects.call([connector, connector.deleteProjectResource], action.payload.model.id);
        }
    )
)

const getAllProjectResource = SagaHelpers.createApiHandler(
    types.API_PROJECTRESOURCE_GET_ALL_INIT, 
    SagaHelpers.generateGetAllApiCall(
        ProjectResourceConnector,
        function*(connector: ProjectResourceConnector, action: any): any {
            const { project, client } = action.payload; 
            let projectIds: string[] | null = null;
            if(project){
                projectIds = [apihelper.getEntityId(project) as string];
            }
            else if(client){
                const allProjects = yield SagaEffects.select(projectSelectors.allProjects);
                const allContracts = yield SagaEffects.select(contractSelectors.allContracts)
                const allContractsForClient = contractSelectors.filterByClients(allContracts, client);
                const allProjectsForClient = projectSelectors.filterByContracts(allProjects, allContractsForClient);
                projectIds = allProjectsForClient.map(apihelper.getEntityId) as string[];
            }
            const resourceIds: string[] | null = action.payload.resource ? [action.payload.resource.id] : null;
            const includeDefault: boolean = action.payload.includeDefault;
            const includes = action.payload.includes;
            return yield SagaEffects.call([connector, connector.getByProjectAndResourceIds], projectIds, resourceIds, includeDefault, includes);
        }
    )
)

const updateProjectResource = SagaHelpers.createApiHandler(
    types.API_PROJECTRESOURCE_UPDATE_INIT, 
    SagaHelpers.generateUpdateApiCall(
        ProjectResourceConnector,
        function*(connector: ProjectResourceConnector, action: any, requestData: any): any {
            return yield SagaEffects.call([connector, connector.updateProjectResource], action.payload.model.id, requestData);
        }
    )
)

export default function* projectResourceSaga() {
    yield SagaEffects.all([
        SagaEffects.fork(getAllProjectResource),
        SagaEffects.fork(updateProjectResource),
        SagaEffects.fork(createProjectResource),
        SagaEffects.fork(deleteProjectResource),
    ]);
}

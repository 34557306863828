import {put, select, takeLatest} from 'redux-saga/effects';
import * as types from '../../../constants/ActionTypes';
import {getAllProjectResources} from '../../../actions/api/projectResource';
import {selectedResource} from '../../../selectors/pages/timeRegistration';
import {getAllResources} from '../../../actions/api/resource';
import {getAllUsers} from '../../../actions/api/user';
import {getAllClients} from '../../../actions/api/client';
import {getAllContractRoles} from '../../../actions/api/contractRole';
import {currentUserAccount} from '../../../selectors/reducers/account';

function* doResourceSelected(action) {
    let resource = yield select(selectedResource);
    if(resource){
        yield put(getAllProjectResources({resource, includeDefault: true, includes: ['project']}));
        let curAcct = yield select(currentUserAccount);
        yield put(getAllResources(curAcct));
        yield put(getAllUsers(curAcct));
        yield put(getAllClients(curAcct, 'contracts'));
        yield put(getAllContractRoles());
        yield put({ type: types.APP_PAGE_TIMEREGISTRATION_FETCH_WEEK_REGISTRATIONS });
    }
}

export default function* resourceSelected() {
    yield takeLatest(types.APP_PAGE_TIMEREGISTRATION_RESOURCE_SELECTED, doResourceSelected);
}
import React, { Component } from "react";
import * as Icons from "../../constants/Icons";
import { Label } from "@fluentui/react/lib/Label";
import PropTypes from "prop-types";
import moment from "moment";
import { IconButton } from "@fluentui/react/lib/Button";
import { Calendar } from "@fluentui/react/lib/Calendar";
import { DefaultButton } from "@fluentui/react/lib/Button";
import { Callout } from "@fluentui/react/lib/Callout";

const DayPickerStrings = {
  months: [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ],

  shortMonths: [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ],

  goToToday: "This month",
  prevYearAriaLabel: "Go to previous year",
  nextYearAriaLabel: "Go to next year",
};

export default class MonthPicker extends Component {
  constructor(props) {
    super(props);
    this._onSelectDate = this._onSelectDate.bind(this);
    this._formatDate = this._formatDate.bind(this);
    this._onLeftClick = this._onLeftClick.bind(this);
    this._onRightClick = this._onRightClick.bind(this);
    this._toggleShowCalendar = this._toggleShowCalendar.bind(this);

    this.state = { showCalendar: false };
    this.buttonRef = React.createRef();
  }

  _toggleShowCalendar() {
    this.setState({ showCalendar: !this.state.showCalendar });
  }

  _onSelectDate(date) {
    if (this.state.showCalendar) {
      this.setState({ showCalendar: false });
    }
    if (this.props.onSelectMonth) {
      this.props.onSelectMonth(moment(date).startOf("month").toDate());
    }
  }

  _formatDate(date) {
    let d = moment(date);
    return d.format("MMMM YYYY");
  }

  _onLeftClick() {
    if (this.props.onSelectMonth && this.props.value) {
      this.props.onSelectMonth(
        moment(this.props.value).subtract(1, "month").toDate()
      );
    }
  }

  _onRightClick() {
    if (this.props.onSelectMonth && this.props.value) {
      this.props.onSelectMonth(
        moment(this.props.value).add(1, "month").toDate()
      );
    }
  }

  render() {
    let { label, value } = this.props;

    return (
      <div className="novatime-date-picker">
        {label ? <Label>{label}</Label> : undefined}
        <div className="clearfix">
          <div className="novatime-datepicker-left">
            <IconButton
              iconProps={{ iconName: Icons.ICON_GO_LEFT }}
              onClick={this._onLeftClick}
            />
          </div>
          <div className="novatime-datepicker-right">
            <IconButton
              iconProps={{ iconName: Icons.ICON_GO_RIGHT }}
              onClick={this._onRightClick}
            />
          </div>
          <div className="novatime-datepicker-center" ref={this.buttonRef}>
            <DefaultButton
              onClick={this._toggleShowCalendar}
              text={value ? this._formatDate(value) : ""}
              style={{ width: "100%" }}
            />
            {this.state.showCalendar && (
              <Callout
                isBeakVisible={false}
                onDismiss={this._toggleShowCalendar}
                target={this.buttonRef}
                setInitialFocus
              >
                <Calendar
                  highlightSelectedMonth={true}
                  value={value != null ? moment(value).toDate() : null}
                  onSelectDate={this._onSelectDate}
                  isMonthPickerVisible={true}
                  isDayPickerVisible={false}
                  strings={DayPickerStrings}
                  formatDate={this._formatDate}
                />
              </Callout>
            )}
          </div>
        </div>
      </div>
    );
  }
}

MonthPicker.propTypes = {
  onSelectMonth: PropTypes.func,
};

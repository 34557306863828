import {all, fork} from 'redux-saga/effects';
import login from './login';
import logout from './logout';
import initauth from './initauth';

export default function* accountSaga() {
    yield all([
        fork(initauth),
        fork(login),
        fork(logout),
    ]);
}

import {allContracts, allContractsByClient} from './contract';
import {allClientsByAccount} from './client';
import {entityHasId, getEntityId, getRelId, relHasId} from '../apihelper';
import * as storehelper from '../storehelper';
import * as apihelper from '../apihelper';
import * as datehelper from '../../lib/date';
import { ResourceObject, ResourceObjects } from '../../lib/models';

export const allContractRoles = (state: any) => state.api.contractRole.allContractRoles as ResourceObjects;

export const allContractRolesByContract =
    (state: any, contract: ResourceObject) => allContractRolesByContractId(state, getEntityId(contract) as string);

export const allContractRolesByContractId =
    (state: any, contractId: string) => allContractRoles(state).filter(cr => relHasId(cr, 'contract', contractId));

export const contractRoleById =
    (state: any, contractRoleId: string) => allContractRoles(state).find(cr => entityHasId(cr, contractRoleId));

export const allcontractRolesByClient = (state: any, client: ResourceObject) => {
	const contractIds = allContractsByClient(state, client).map(getEntityId);
	return allContractRoles(state).filter(cr => contractIds.includes(getRelId(cr, 'contract') as string));
};

export const filterByActiveInPeriod = (
    contractRoles: ResourceObjects,
    startDate: Date,
    endDate: Date
) => 
    contractRoles.filter(cr => datehelper.isWithinPeriod(
        new Date(apihelper.getAttr(cr, 'effectiveDate') as string), new Date(apihelper.getAttr(cr, 'expiryDate') as string), // outer period
        startDate, endDate // inner period
    ));

const contractRolesByClients = (state: any, clients: ResourceObjects) => {
// TODO: these should probably be a new selector under contract.js
    const clientIds = clients.map(getEntityId);
    const contracts = allContracts(state).filter(co => clientIds.includes(getRelId(co, 'client') as string));

    const contractIds = contracts.map(getEntityId);
    return allContractRoles(state).filter(cr => contractIds.includes(getRelId(cr, 'contract') as string));
}

export const contractRolesByAccount =
    (state: any, account: ResourceObject) =>
        contractRolesByClients(state, allClientsByAccount(state, account));

export const filterByProjectResources = (contractRoles: ResourceObjects, projectResources: ResourceObjects) =>
    storehelper.filterByRelatedEntity(contractRoles, projectResources, 'contractRole');

import * as types from "../../constants/ActionTypes";
import { updateDtoList, removeItemFromList } from "../helpers";
import * as workLocationRegistrationActionSelectors from "../../selectors/actions/workLocationRegistration";

const initialState = {
  allWorkLocationRegistrations: [],
};

export default function workLocationRegistrationReducer(
  state = initialState,
  action: any
) {
  switch (action.type) {
    case types.API_WORKLOCATIONREGISTRATION_GET_ALL_SUCCESS: {
      return updateDtoList(
        state,
        "allWorkLocationRegistrations",
        workLocationRegistrationActionSelectors.getAllWorkLocationRegistrations(
          action
        )
      );
    }

    case types.API_WORKLOCATIONREGISTRATION_CREATE_SUCCESS: {
      return updateDtoList(state, "allWorkLocationRegistrations", [
        workLocationRegistrationActionSelectors.getUpsertWorkLocationRegistration(
          action
        ),
      ]);
    }

    case types.API_WORKLOCATIONREGISTRATION_DELETE_SUCCESS: {
      return removeItemFromList(
        state,
        "allWorkLocationRegistrations",
        workLocationRegistrationActionSelectors.getDeleteWorkLocationRegistration(
          action
        )
      );
    }

    default:
      return state;
  }
}

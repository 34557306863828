import React, { Component } from "react";
import { Label } from "@fluentui/react/lib/Label";
import { DefaultButton } from "@fluentui/react/lib/Button";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import moment from "moment";
import MonthPicker from "../../components/uifabricextensions/MonthPicker";
import { Resource } from "../../components/uifabricextensions/Resource";
import TimeRegistrationForm from "../../components/forms/TimeRegistrationForm";
import Row from "../../components/grid/Row";
import Grid from "../../components/grid/Grid";
import Column from "../../components/grid/Column";
import HorizontalRule from "../../components/HorizontalRule";
import OutsideAlerter from "../../components/OutsideAlerter";
import ClientPicker from "../../components/uifabricextensions/ClientPicker";
import ProjectPicker from "../../components/uifabricextensions/ProjectPicker";
import BasePickerSimple from "../../components/uifabricextensions/BasePickerSimple";
import TimeRegistrationList from "../../components/TimeRegistrationList";
import ByResourceTable from "../../components/timeRegistration/ByResourceTable";
import * as accountReducerSelectors from "../../selectors/reducers/account";
import { isLoggedIn } from "../../selectors/reducers/auth";
import * as resourceManagementSelectors from "../../selectors/pages/resourceManagement";
import * as clientSelectors from "../../selectors/reducers/client";
import {
  relRefersToEntity,
  getAttr,
  getEntityId,
  entityHasId,
} from "../../selectors/apihelper";
import * as resourceManagementActions from "../../actions/pages/resourceManagement";
import * as billabilitySelectors from "../../selectors/reducers/billabilityTypes";
import * as currencySelectors from "../../selectors/reducers/currency";
import * as localeSelectors from "../../selectors/reducers/locale";
import * as absencePeriodStatusSelectors from "../../selectors/reducers/absencePeriodStatus";
import * as timeRegistrationStatusSelectors from "../../selectors/reducers/timeRegistrationStatus";
import ProcessingDialog from "../../components/ProcessingDialog";
import ResourceCheckboxDialog from "../../components/ResourceCheckboxDialog";
import * as permissionSelectors from "../../selectors/reducers/permission";
import * as appDomainSelectors from "../../selectors/app/domain";
import * as datehelper from "../../lib/date";

const lexicalInsensitiveNameSortFunc = function (a, b) {
  return getAttr(a, "name")
    .toLowerCase()
    .localeCompare(getAttr(b, "name").toLowerCase());
};

const timeRegDateSortFunc = function (a, b) {
  return moment(getAttr(a, "startTime")).diff(moment(getAttr(b, "startTime")));
};

const SHOW_INACTIVE_RESOURCE_ID = "inactiveresources";

class ResourceManagement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cellUnderEdit: undefined,
      closeMonthWasClicked: false,
      openMonthWasClicked: false,
    };
    this._detailListElement = null;
    this._detailFormElement = null;

    this._onSelectMonth = this._onSelectMonth.bind(this);
    this._onSelectProject = this._onSelectProject.bind(this);
    this._onSelectAllProjects = this._onSelectAllProjects.bind(this);
    this._onSelectClient = this._onSelectClient.bind(this);
    this._onSelectAllClients = this._onSelectAllClients.bind(this);
    this._onChangeResourceType = this._onChangeResourceType.bind(this);
    this._onClickCell = this._onClickCell.bind(this);
    this._selectTimeRegistrationFromList =
      this._selectTimeRegistrationFromList.bind(this);
    this._clearSelectedCell = this._clearSelectedCell.bind(this);
    this._onOutsideClick = this._onOutsideClick.bind(this);
    this._renderDetailsListColumn = this._renderDetailsListColumn.bind(this);
    this._onViewDescChange = this._onViewDescChange.bind(this);
    this._closeMonth = this._closeMonth.bind(this);
    this._clickCloseMonth = this._clickCloseMonth.bind(this);
    this._openMonth = this._openMonth.bind(this);
    this._clickOpenMonth = this._clickOpenMonth.bind(this);
    this._closeDialog = this._closeDialog.bind(this);
    this._confirmSelection = this._confirmSelection.bind(this);
  }

  componentDidMount() {
    this.props.actions.pageOpened();
  }

  _onSelectMonth(firstDayOfWeek) {
    this.props.actions.selectMonth(firstDayOfWeek);
  }

  _onSelectClient(selectedClient) {
    this.props.actions.selectClientId(getEntityId(selectedClient));
  }

  _onSelectAllClients() {
    this.props.actions.selectAllClients();
  }

  _onSelectAllProjects() {
    this.props.actions.selectAllProjects();
  }

  _onSelectProject(selectedProject) {
    this.props.actions.selectProjectId(getEntityId(selectedProject));
  }

  _onChangeResourceType(selectedEntity) {
    let { selectedResourceTypeIds, showInactiveResources } = this.props;
    let updatedSelectedResourceTypeIds = [];
    if (selectedEntity.id == SHOW_INACTIVE_RESOURCE_ID) {
      // SHOW_INACTIVE_RESOURCE_ID = "inactiveresources"
      showInactiveResources = !showInactiveResources;
      updatedSelectedResourceTypeIds = selectedResourceTypeIds;
    } else if (selectedResourceTypeIds.includes(getEntityId(selectedEntity))) {
      //If we press a already selected resourcetype
      updatedSelectedResourceTypeIds = selectedResourceTypeIds.filter(
        (id) => !entityHasId(selectedEntity, id)
      ); //Filter selectedresourcetypesID based on the ID of the chosen resourcetype. That is; retain only that ID
    } else {
      updatedSelectedResourceTypeIds = [
        ...selectedResourceTypeIds,
        getEntityId(selectedEntity),
      ]; //Else add the chosen Id to selectedresourcetypeID
    }
    this.props.actions.selectResourceTypeIds(
      updatedSelectedResourceTypeIds,
      showInactiveResources
    ); //write the new SelectedResourceTypesToThe state filter
  }

  _onClickCell(cellData) {
    this.props.actions.selectCell(cellData);
  }

  _clearSelectedCell() {
    this.props.selectedCell != null && this.props.actions.clearSelectedCell();
  }

  _selectTimeRegistrationFromList(timeRegistrationData) {
    let timeRegistration = timeRegistrationData[0]
      ? timeRegistrationData[0].data
      : null;
    this.props.actions.selectTimeRegistration(timeRegistration);
  }

  _onOutsideClick(event) {
    function hasSomeParentTheClass(element, classname) {
      if ((element.className || "").split(" ").indexOf(classname) >= 0)
        return true;
      return (
        element.parentNode &&
        hasSomeParentTheClass(element.parentNode, classname)
      );
    }

    // check it's not the panels being opened
    let isMsCallout = hasSomeParentTheClass(event.target, "ms-Layer");
    if (!isMsCallout) {
      this._clearSelectedCell();
    }
  }

  _clickCloseMonth() {
    this.setState({ closeMonthWasClicked: true });
  }

  _clickOpenMonth() {
    this.setState({ openMonthWasClicked: true });
  }

  _closeDialog() {
    this.setState({ closeMonthWasClicked: false, openMonthWasClicked: false });
  }

  _confirmSelection(resources) {
    let { closeMonthWasClicked, openMonthWasClicked } = this.state;
    if (closeMonthWasClicked) {
      this._closeMonth(resources);
    }
    if (openMonthWasClicked) {
      this._openMonth(resources);
    }
  }

  _closeMonth(resources) {
    this.props.actions.lockPeriods(resources);
    this.setState({ closeMonthWasClicked: false });
  }

  _openMonth(resources) {
    this.props.actions.openPeriods(resources);
    this.setState({ openMonthWasClicked: false });
  }

  _onViewDescChange(viewDesc) {
    this.props.actions.updateViewDesc(viewDesc);
  }

  _renderDetailsListColumn(item, index, column) {
    const fieldContent = item[column.fieldName];
    switch (column.key) {
      case "tr-resource":
        return <Resource model={fieldContent} />;
      default:
        return <span>{fieldContent}</span>;
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    return (
      this.props.stateMerge !== nextProps.stateMerge ||
      this.props.selectedCell !== nextProps.selectedCell ||
      this.props.selectedTimeRegistration !==
        nextProps.selectedTimeRegistration ||
      this.props.contractRoles !== nextProps.contractRoles ||
      this.props.projectResources !== nextProps.projectResources ||
      this.props.showProcessingDialog !== nextProps.showProcessingDialog ||
      this.state.closeMonthWasClicked !== nextState.closeMonthWasClicked ||
      this.state.openMonthWasClicked !== nextState.openMonthWasClicked
    );
  }

  render() {
    let {
      selectedTimeRegistration,
      allClients,
      currentUserAccount,
      stateMerge,
      startTime,
      isAllClientsSelected,
      isAllProjectsSelected,
      lockedTimePeriodIndex,
      selectedCell,
      selectedProject,
      selectedClient,
      projectResources,
      contractRoles,
      idForNotBillableType,
      allCurrency,
      absencePeriodStatuses,
      absenceRegistrationProjects,
      allTimeRegistrationStatus,
      viewDesc,
      showProcessingDialog,
      isSelectedTimeRegistrationBillable,
      contracts,
      projects,
      locales,
      selectedResourceTypes,
      usersForCurrentAccount,
      absencePeriods,
      resourcesForCurrentFilter,
      resourceTypeOptions,
      currentUserPermissionSet,
      contractsForSelectedTimeRegistration,
      contractsRoleForSelectedTimeRegistration,
      notificationsForSelectedTimeRegistration,
    } = this.props;
    let { closeMonthWasClicked, openMonthWasClicked } = this.state;

    let hasSelection = !!selectedCell;

    let isUnderEdit = function (row, date) {
      return (
        hasSelection &&
        ((!row && !selectedCell.resource) ||
          (row &&
            selectedCell.resource &&
            entityHasId(selectedCell.resource, row.id))) &&
        ((!selectedCell.date && !date) ||
          (date &&
            selectedCell.date &&
            datehelper.isSameDay(date.dateObj, selectedCell.date)))
      );
    };

    let allProjects = projects.slice().sort(lexicalInsensitiveNameSortFunc);

    let users = usersForCurrentAccount;

    let detailsListTregs = selectedCell ? selectedCell.timeRegistrations : [];

    let tregHeaderText = hasSelection
      ? (selectedCell.date
          ? moment(selectedCell.date).format("MMMM")
          : selectedCell.resource
          ? getAttr(selectedCell.resource, "name")
          : startTime
          ? moment(startTime).format("MMMM")
          : "") +
        ", " +
        detailsListTregs.length +
        " registration" +
        (detailsListTregs.length !== 1 ? "s" : "")
      : "";

    let titleText = "";
    if (selectedTimeRegistration) {
      let proj = allProjects.find((p) =>
        relRefersToEntity(selectedTimeRegistration, "project", p)
      );
      titleText =
        (proj ? getAttr(proj, "name") + ", " : "") +
        moment(getAttr(selectedTimeRegistration, "startTime")).format(
          "dddd, MMMM Do"
        );
    }

    return (
      <div className="novatime-page-resourcemanagement">
        <div className="novatime-container">
          <ProcessingDialog hidden={!showProcessingDialog} />

          <ResourceCheckboxDialog
            title={`Confirm resources to ${
              openMonthWasClicked ? "open" : "close"
            }`}
            resources={resourcesForCurrentFilter}
            hidden={!closeMonthWasClicked && !openMonthWasClicked}
            onDismiss={this._closeDialog}
            onChange={this._onCheckboxChange}
            onConfirm={this._confirmSelection}
          />

          <HorizontalRule text="Select" alignment="left" />

          <Grid>
            <Row>
              <Column sm={12} lg={6} xl={3}>
                <Label>Month</Label>
                <MonthPicker
                  value={startTime}
                  onSelectMonth={this._onSelectMonth}
                />
                <div className="height2em hiddenLgUp" />
              </Column>
              <Column sm={12} lg={6} xl={3}>
                <ClientPicker
                  clientEntities={allClients}
                  isSelectAllAnOption={true}
                  isAllClientsSelected={isAllClientsSelected}
                  selectedClient={selectedClient}
                  onClientSelected={this._onSelectClient}
                  onAllClientsSelected={this._onSelectAllClients}
                />
                <div className="height2em hiddenLgUp" />
              </Column>
              <Column sm={12} lg={6} xl={3}>
                <ProjectPicker
                  projectEntities={allProjects}
                  isSelectAllAnOption={true}
                  isAllProjectsSelected={isAllProjectsSelected}
                  selectedProject={selectedProject}
                  onProjectSelected={this._onSelectProject}
                  onAllProjectsSelected={this._onSelectAllProjects}
                />
                <div className="height2em hiddenLgUp" />
              </Column>
              <Column sm={12} lg={6} xl={3}>
                <BasePickerSimple
                  label={"Resources"}
                  placeholder={"Select resource type"}
                  selectedEntities={selectedResourceTypes}
                  entities={resourceTypeOptions}
                  multiSelect={true}
                  onEntitySelected={this._onChangeResourceType}
                  nameFunction={(e) => e.attributes.name}
                  idFunction={(e) => e.id}
                />
              </Column>
            </Row>
          </Grid>
          <HorizontalRule text="Calendar" alignment="left" />
          {currentUserPermissionSet.hasSelectedAccountWrite() && (
            <div className="novatime-resourcemanagement-buttons">
              <div className="novatime-resourcemanagement-close-button">
                <DefaultButton
                  onClick={this._clickCloseMonth}
                  text={"Close month"}
                  disabled={false}
                />
              </div>
              <div className="novatime-resourcemanagement-open-button">
                <DefaultButton
                  onClick={this._clickOpenMonth}
                  text={"Open month"}
                  disabled={false}
                />
              </div>
            </div>
          )}
        </div>
        <OutsideAlerter onOutsideClick={this._onOutsideClick}>
          <div className="novatime-centered">
            <ByResourceTable
              isCellUnderEdit={isUnderEdit}
              firstDayOfMonth={startTime}
              onCellClick={this._onClickCell}
              clients={allClients}
              projects={projects}
              contracts={contracts}
              resources={resourcesForCurrentFilter}
              contractRoles={contractRoles}
              projectResources={projectResources}
              stateMerge={stateMerge}
              lockedTimePeriodIndex={lockedTimePeriodIndex}
              absencePeriods={absencePeriods}
              idForNotBillableType={idForNotBillableType}
              currencies={allCurrency}
              absencePeriodStatuses={absencePeriodStatuses}
              hourSet={this.props.hourSet}
              locales={locales}
              account={this.props.currentAccount}
              absenceRegistrationProjects={absenceRegistrationProjects}
              billabilityTypes={this.props.billabilityTypes}
            />
          </div>
          <div className="novatime-container">
            <div className="height4em hiddenLgUp" />
            {detailsListTregs.length > 0 && (
              <HorizontalRule text={tregHeaderText} alignment="left" />
            )}
            <TimeRegistrationList
              projects={allProjects}
              clients={allClients}
              columnNames={[
                "project",
                "resource",
                "date",
                "duration",
                "description",
              ]}
              resources={resourcesForCurrentFilter}
              contracts={contracts}
              timeRegistrations={detailsListTregs}
              onRenderColumn={this._renderDetailsListColumn}
              selectedTimeRegistration={selectedTimeRegistration}
              onSelect={this._selectTimeRegistrationFromList}
              showIfLessThanTwoRegistrations={true}
              viewDesc={viewDesc}
              onViewDescChange={this._onViewDescChange}
            />
            <div className="height4em hiddenLgUp" />
            {selectedTimeRegistration && (
              <HorizontalRule text={titleText} alignment="left" />
            )}
            {selectedTimeRegistration ? (
              <TimeRegistrationForm
                model={selectedTimeRegistration}
                projects={allProjects}
                resources={resourcesForCurrentFilter}
                contractRoles={contractsRoleForSelectedTimeRegistration}
                contracts={contractsForSelectedTimeRegistration}
                projectResources={projectResources}
                account={currentUserAccount}
                users={users}
                hasPermission={true}
                notifications={notificationsForSelectedTimeRegistration}
                currentUserPermissionSet={currentUserPermissionSet}
                autoShowDetails={true}
                canDelete={false}
                canEdit={false}
                isSelectedTimeRegistrationBillable={
                  isSelectedTimeRegistrationBillable
                }
                allTimeRegistrationStatus={allTimeRegistrationStatus}
              />
            ) : undefined}
          </div>
        </OutsideAlerter>
      </div>
    );
  }
}

function mapStateToProps(state) {
  let selectedProjectId = resourceManagementSelectors.selectedProjectId(state);
  let selectedProject = resourceManagementSelectors.selectedProject(state);
  let selectedClientId = resourceManagementSelectors.selectedClientId(state);
  let selectedClient = resourceManagementSelectors.selectedClient(state);
  let isAllClientsSelected =
    resourceManagementSelectors.isAllClientsSelected(state);
  let projects = resourceManagementSelectors.selectedProjects(state);
  let contracts =
    resourceManagementSelectors.contractsBySelectedProjects(state);
  let selectedResourceTypeIds =
    resourceManagementSelectors.selectedResourceTypeIdsEmployees(state);
  let selectedResourceTypes =
    resourceManagementSelectors.selectedResourceTypes(state);
  let resourceTypeOptions =
    resourceManagementSelectors.resourceTypeOptions(state);
  let startDate = resourceManagementSelectors.startTime(state);
  let endDate = resourceManagementSelectors.endTime(state);
  let viewDesc = resourceManagementSelectors.viewDesc(state);
  let showProcessingDialog =
    resourceManagementSelectors.showProcessingDialog(state);
  let selectedTimeRegistration =
    resourceManagementSelectors.selectedTimeRegistration(state);
  let projectResources =
    resourceManagementSelectors.selectedProjectResources(state);
  let contractRoles =
    resourceManagementSelectors.contractRolesBySelectedProjectResources(state);
  let lockedTimePeriodIndex =
    resourceManagementSelectors.lockedTimePeriodIndex(state);
  let resourcesForCurrentFilter =
    resourceManagementSelectors.resourcesForCurrentFilter(state); // Ugly and slow.
  let hourSet =
    resourceManagementSelectors.hourSetForSelectedTimeRegistrations(state);
  let idForNotBillableType = billabilitySelectors.idForNotBillableType(state);
  let isSelectedTimeRegistrationBillable =
    resourceManagementSelectors.isSelectedTimeRegistrationBillable(state);
  let allClients =
    clientSelectors.allClientsByCurrentUserAccountSortedByName(state);
  let locales = localeSelectors.allLocale(state);
  let currentAccount = appDomainSelectors.selectedAccount(state);
  let usersForCurrentAccount = appDomainSelectors.usersForCurrentAccount(state);
  let absencePeriodStatuses =
    absencePeriodStatusSelectors.allAbsencePeriodStatus(state);
  let billabilityTypes = billabilitySelectors.allBillabilityTypes(state);
  let currentUserAccount = accountReducerSelectors.currentUserAccount(state);
  let absencePeriods =
    resourceManagementSelectors.absencePeriodsInPeriod(state);
  let absenceRegistrationProjects =
    appDomainSelectors.allAbsenceRegistrationProjectsForSelectedAccount(state);
  const contractsForSelectedTimeRegistration =
    resourceManagementSelectors.contractForSelectedTimeRegistration(state);
  const contractsRoleForSelectedTimeRegistration =
    resourceManagementSelectors.contractRoleForSelectedTimeRegistration(state);
  const notificationsForSelectedTimeRegistration =
    resourceManagementSelectors.notificationsForSelectedTimeRegistration(state);

  return {
    resourcesForCurrentFilter,
    isLoggedIn: isLoggedIn(state),
    currentUserAccount,
    usersForCurrentAccount,
    selectedClientId,
    isAllClientsSelected,
    absenceRegistrationProjects,
    selectedProjectId,
    isAllProjectsSelected:
      resourceManagementSelectors.isAllProjectsSelected(state),
    selectedResourceTypeIds,
    startTime: startDate,
    endTime: endDate,
    selectedTimeRegistration,
    lockedTimePeriodIndex,
    selectedCell: resourceManagementSelectors.selectedCell(state),
    stateMerge: resourceManagementSelectors.stateMerge(state),
    showInactiveResources:
      resourceManagementSelectors.showInactiveResources(state),
    absencePeriods,
    selectedProject,
    selectedClient,
    projectResources,
    contractRoles,
    idForNotBillableType,
    allCurrency: currencySelectors.allCurrency(state),
    absencePeriodStatuses,
    allTimeRegistrationStatus:
      timeRegistrationStatusSelectors.allTimeRegistrationStatus(state),
    viewDesc,
    showProcessingDialog,
    currentUserPermissionSet:
      permissionSelectors.currentUserPermissionSet(state),
    isSelectedTimeRegistrationBillable,
    allClients,
    contracts,
    projects,
    locales,
    selectedResourceTypes,
    resourceTypeOptions,
    hourSet,
    billabilityTypes,
    currentAccount,
    contractsForSelectedTimeRegistration,
    contractsRoleForSelectedTimeRegistration,
    notificationsForSelectedTimeRegistration,
  };
}

function mapDispatch(dispatch) {
  const actions = Object.assign({}, resourceManagementActions);
  return { actions: bindActionCreators(actions, dispatch) };
}

export default connect(mapStateToProps, mapDispatch)(ResourceManagement);

import {takeLatest} from 'redux-saga/effects';
import * as types from '../../../constants/ActionTypes';
import {getAllContractRoles} from '../../../actions/api/contractRole';
import * as sagahelpers from '../../helpers';

function* doContractSelected(action) {
    if(action.payload !== null){
        yield sagahelpers.callAndAwait(getAllContractRoles(action.payload));
    }
}

export default function* contractSelected() {
    yield takeLatest(types.APP_PAGE_CLIENTS_AND_CONTRACTS_CONTRACT_SELECTED, doContractSelected);
}

import React, { Component } from 'react';
import PropTypes from 'prop-types';

export default class MonthHourField extends Component {
    constructor(props){
        super(props);

        this._onClick = this._onClick.bind(this);
    }

    static propTypes = {
        onClick: PropTypes.func,
        cellData: PropTypes.shape({
            value: PropTypes.number,
            registrationGoals: PropTypes.shape({goal: PropTypes.number, deviation: PropTypes.number}),
        }).isRequired
    };

    shouldComponentUpdate(nextProps, _){
        return !this.props.cellData || 
               !nextProps.cellData ||
               this.props.cellData.value !== nextProps.cellData.value
    }

    _onClick(){
        this.props.onClick && this.props.onClick(this.props.cellData);
    }

    _getFormattedValue(val){
        return val > 0 ? 
                   val % 1 === 0 ? 
                       val + '' : 
                       val.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : 
                   '';
    }

    render() {
        let {registrationGoals, value} = this.props.cellData;
        let className = ['novatime-month-hour-field'];
        let formattedVal = this._getFormattedValue(value) || '\u00A0';
        if(registrationGoals){
            let ext = Math.min(100, Math.round(Math.abs(value - registrationGoals.goal) / registrationGoals.deviation * 50));
            className.push('valid-' + ext);
        }

        return (
            <div className={className.join(' ')} onClick={this._onClick}>
                {formattedVal}
            </div>
        );
    }
}

import type { Notification, NotificationLevel, NotificationType } from '../../reducers/app/notification'
import * as ActionTypes from '../../constants/ActionTypes';

export const createNotification = (notification: Notification) => ({
    type: ActionTypes.APP_NOTIFICATION_ADD,
    payload: { notification }
})

export const replaceNotificationByTransactionId = (notification: Notification) => ({
    type: ActionTypes.APP_NOTIFICATION_REPLACE,
    payload: { 
        notification,
        transactionId: notification.location?.transactionId
    }
})

type DismissNotificationInput = {
    queueId?: string,
    transactionId?: string,
    level?: NotificationLevel | Array<NotificationLevel>,
    type?: NotificationType | Array<NotificationType>
};

export const dismissAll = ({queueId = undefined, transactionId = undefined, level = undefined, type = undefined}: DismissNotificationInput = {}) => ({
    type: ActionTypes.APP_NOTIFICATION_DISMISS_ALL,
    payload: { 
        filter: { queueId, transactionId } 
    }
});

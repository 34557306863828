import * as apihelper from '../apihelper';
import * as storehelper from '../storehelper';
import * as datehelper from '../../lib/date';
import { ResourceObject, ResourceObjects } from '../../lib/models';

export const allAbsencePeriods = (state: any) => state.api.absencePeriod.allAbsencePeriods;

export const absencePeriodById = (state: any, absencePeriodId: string) => 
    storehelper.findById(allAbsencePeriods(state), absencePeriodId);

export const filterByPeriod = (allAbsencePeriods: ResourceObjects, startDate: Date, endDate: Date) =>
    allAbsencePeriods.filter(absencePeriod => 
        datehelper.intersectsPeriod(
            startDate, 
            endDate, 
            new Date(apihelper.getAttr(absencePeriod, 'periodStart') as string), 
            new Date(apihelper.getAttr(absencePeriod, 'periodEnd') as string)
        )
    );

export const filterByResource = (absencePeriods: ResourceObjects, resource: ResourceObject) => 
        storehelper.filterByReferenceToRelatedEntities(absencePeriods, resource, 'resource');

import * as types from '../../constants/ActionTypes';
import {updateDtoList,} from '../helpers';
import {getAllPermissionTypes} from '../../selectors/actions/permissionTypes';

const initialState = {
    storeRevision: 0,
    allPermissionTypes: []
};

export default function contractRoleReducer(state = initialState, action) {
    switch (action.type) {
        case types.API_PERMISSIONTYPE_GET_ALL_SUCCESS: {
            return updateDtoList(state, 'allPermissionTypes', getAllPermissionTypes(action));
        }

        default:
            return state;
    }
}
import * as types from "../../constants/ActionTypes";
import {
  NewResourceObject,
  ResourceObject,
  ResourceObjects,
} from "../../lib/models";
import * as apihelper from "../../selectors/apihelper";

export type GetAllWorkLocationRegistrationsAction = {
  type: string;
  payload: {
    parameters: {
      resources: ResourceObjects;
      startTime: string;
      endTime: string;
    };
  };
};

export function getAllWorkLocationRegistrations({
  resources = undefined,
  startTime,
  endTime,
}: {
  resources?: ResourceObjects;
  startTime: Date;
  endTime: Date;
}): GetAllWorkLocationRegistrationsAction {
  return {
    type: types.API_WORKLOCATIONREGISTRATION_GET_ALL_INIT,
    payload: {
      parameters: {
        resources: resources || [],
        startTime: startTime.toISOString(),
        endTime: endTime.toISOString(),
      },
    },
  };
}

export type DeleteWorkLocationRegistrationAction = {
  type: string;
  payload: {
    model: ResourceObject;
  };
};

export function deleteWorkLocationRegistration(
  workLocationRegistration: ResourceObject
): DeleteWorkLocationRegistrationAction {
  return {
    type: types.API_WORKLOCATIONREGISTRATION_DELETE_INIT,
    payload: {
      model: workLocationRegistration,
    },
  };
}

export type CreateWorkLocationRegistrationAction = {
  type: string;
  payload: { model: NewResourceObject };
};

export function createWorkLocationRegistration(
  workLocationRegistration: NewResourceObject
) {
  return {
    type: types.API_WORKLOCATIONREGISTRATION_CREATE_INIT,
    payload: { model: workLocationRegistration },
  };
}

import * as types from '../../constants/ActionTypes';


export function getAllAbsencePeriodComments(model) {
    return {
        type: types.API_ABSENCEPERIODCOMMENT_GET_ALL_INIT,
        payload: {
            model
        }
    };
}

export function createAbsencePeriodComment(dto) {
    return {
        type: types.API_ABSENCEPERIODCOMMENT_CREATE_INIT,
        payload: {
            model: dto
        }
    };
}


import BaseConnector from './Base';

export default class ContractRoleConnector extends BaseConnector {
    getContractRoles(contractId: string){
        return this.authFetch(
            this.baseApiUrl + 'ContractRoles/' +
            (contractId != null ? '?filter=contract.id eq ' + contractId : '')
        );
    }

    createContractRole(contractRoleDto: object) {
        return this.authPost(this.baseApiUrl + 'ContractRoles/', contractRoleDto);
    }

    updateContractRole(contractRoleId: string, contractRoleDto: object) {
        return this.authPatch(this.baseApiUrl + 'ContractRoles/' + contractRoleId, contractRoleDto);
    }

    deleteContractRole(id: string){
        return this.authDelete(this.baseApiUrl + 'ContractRoles/' + id);
    }
}

import * as types from '../../constants/ActionTypes';
import {updateDtoList} from '../helpers';
import {getAllLocale} from '../../selectors/actions/locale';

const initialState = {
    storeRevision: 0,
    allLocale: []
};

export default function localeReducer(state = initialState, action) {
    switch (action.type) {

        case types.API_LOCALE_GET_ALL_SUCCESS: {
            return updateDtoList(state, 'allLocale', getAllLocale(action));
        }

        default:
            return state;
    }
}

import * as types from '../../constants/ActionTypes';

const initialState = {
    selectedAbsencePeriodId: null,
    selectedResourceId: null,
    selectedYear: new Date().getFullYear()
};

export default function auth(state = initialState, action) {
    switch (action.type) {
        case types.APP_PAGE_ABSENCEPERIODS_OPENED: {
            return Object.assign({}, state, {});
        }
        case types.API_ACCOUNT_CREATE_SUCCESS : {
            return Object.assign({}, state, {
                selectedAbsencePeriodId: action.payload.requestData.data.id,
            });
        }
        case types.APP_PAGE_ABSENCEPERIODS_SELECTED: {
            return Object.assign({}, state, {
                selectedAbsencePeriodId: action.payload ? action.payload.id : null,
            });
        }

        case types.APP_PAGE_ABSENCEPERIODS_RESOURCE_SELECTED: {
            return Object.assign({}, state, {
                selectedResourceId: action.payload ? action.payload.id : null
            });
        }

        case types.APP_PAGE_ABSENCEPERIODS_SET_YEAR: {
            return Object.assign({}, state, {
                selectedYear: action.payload.year
            });
        }

        default:
            return state;
    }
}

import * as SagaEffects from "redux-saga/effects";
import * as notificationActions from '../../actions/app/notification';
import { NotificationLevel, NotificationType } from "../../reducers/app/notification";
import * as actionhelper from '../../selectors/actionhelper';
import type { Notification } from '../../reducers/app/notification';
import * as requesthelper from '../../selectors/app/request';
import * as EntityTypes from '../../constants/EntityTypes';

const toDescription = (action: any): string | undefined => {
    const errors = actionhelper.apiResponseErrors(action);
    return errors && Array.isArray(errors) ? 
           errors.map(error => `${error.title}: ${error.detail} (code: ${error.code})`).join("\n") :
           undefined;
}

const capitalizeText = (text: string) => text.toLowerCase().charAt(0).toUpperCase()+(text.slice(1).toLowerCase());

const toTitle = (action: any) => {
    const entityType = requesthelper.getApiCallEntity(action);
    const entityTypeKey = Object.keys(EntityTypes).find(entityName => entityType.toLowerCase() === entityName.toLowerCase());
    const entityTypeName = entityTypeKey ? (EntityTypes as Record<string, string>)[entityTypeKey].replace(/([A-Z])/g, ' $1').trim().toLowerCase() : entityType;
    const apiCallType = requesthelper.getApiCallType(action);
    const id = actionhelper.apiSentModelId(action);
    return `${capitalizeText(apiCallType)} ${entityTypeName} ${id ? '(id: ' + id + ')' : ''}`
}

const toErrorLevel = (action: any) => requesthelper.isFailedApiAction(action) ? NotificationLevel.Error : NotificationLevel.Info;
const toNotificationType = (action: any) => 
    requesthelper.isGetApiAction(action) ? NotificationType.ApiCallGet :
    requesthelper.isCreateApiAction(action) ? NotificationType.ApiCallPost :
    requesthelper.isUpdateApiAction(action) ? NotificationType.ApiCallPatch :
    requesthelper.isDeleteApiAction(action) ? NotificationType.ApiCallDelete : null;

const toNotification = (action: any): Notification => {
    const initAction = actionhelper.apiInitAction(action);
    const queueId = initAction?.payload?.queueId;
    const entityId = actionhelper.apiSentModelId(action);
    const transactionId = actionhelper.getTransactionId(action);
    const startTime = actionhelper.getStartTime(action);

    return {
        level: toErrorLevel(action),
        location: { queueId, entityId, transactionId },
        type: toNotificationType(action) as NotificationType,
        content: {
            message: { 
                title: toTitle(action),
                description: toDescription(action) as string
            }
        },
        requireDismiss: true,
        timestamp: startTime ? `${(((new Date).getTime() - startTime) / 1000).toFixed(2)} secs` : undefined
    }
}

function* createRequestApiCallNotification(action: any){
    yield(SagaEffects.put(notificationActions.createNotification(toNotification(action))))
}

function* updateFailedApiCallNotification(action: any) {
    yield(SagaEffects.put(notificationActions.replaceNotificationByTransactionId(toNotification(action))))
}

function* cleanupSuccessfulApiCall(action: any){
    const transactionId = actionhelper.getTransactionId(action);
    const queueId = actionhelper.apiInitAction(action)?.payload?.queueId;
    yield SagaEffects.put(notificationActions.dismissAll({queueId, transactionId}));
}

function* cleanupCancelledApiCall(){
    console.warn("cleanupCancelledApiCall DETECTED A CANCELLED API CALL. THIS SHOULD CLEAN THE NOTIFICATION")
}

export default function* notificationRoot() {
    yield SagaEffects.all([
        SagaEffects.takeEvery(requesthelper.isRequestApiAction, createRequestApiCallNotification),
        SagaEffects.takeEvery(requesthelper.isFailedApiAction, updateFailedApiCallNotification),
        SagaEffects.takeEvery(requesthelper.isSuccessfulApiAction, cleanupSuccessfulApiCall),
        SagaEffects.takeEvery(requesthelper.isCancelledApiAction, cleanupCancelledApiCall)
    ])
}

import * as types from '../../constants/ActionTypes';
import * as authActionSelectors from '../../selectors/actions/auth';

const initialState = {
    isCheckingAuth: false,
    isLoggedIn: false,
    user: null,
    loginError: null,
};

export default function authReducer(state = initialState, action: any) {
    switch (action.type) {
        case types.AUTH_CHECK:
            return Object.assign({}, state, {
                isCheckingAuth: true,
            });
        
        case types.AUTH_LOGIN_EXPIRED: 
            return Object.assign({}, state, {
                isLoggedIn: true,
                user: null
            });

        case types.AUTH_COMPLETE: 
            return Object.assign({}, state, {
                isCheckingAuth: false,
                isLoggedIn: !!action.payload.user,
                user: authActionSelectors.getLoggedInUser(action),
                loginError: authActionSelectors.getLoginError(action),
            });

        default:
            return state;
    }
}

import {race, take} from 'redux-saga/effects';
import { history } from '../../lib/history'
import * as types from '../../constants/ActionTypes';

export default function* navigate() {
    while (true) {
        let { goHome, toUrl } = yield race({
            goHome: take(types.NAVIGATION_TO_HOME),
            toUrl: take(types.NAVIGATION_TO_URL)
        });
        
        if (goHome) {
            history.push('/');
        }
        if (toUrl) {
            const { url, newWindow, bypassRouter} = toUrl.payload;
            if (newWindow) {
                const win = window.open(url, '_blank');
                win.focus();
            }
            else if (bypassRouter){
                window.open(url, "_self");
            }
            else {
                history.push(url);
            }
        }
    }
}

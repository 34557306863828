import * as apihelper from '../apihelper';
import '../../lib/unique'
import { createSelector } from 'reselect';
import * as accountSelectors from './account';
import * as storehelper from '../storehelper';
import { ResourceObject, ResourceObjects } from '../../lib/models';
import * as contractSelectors from './contract';

export const filterByProjectResources = (
  projects: ResourceObjects, 
  projectResources: ResourceObjects
): ResourceObjects => 
  storehelper.filterByRelatedEntity(projects, projectResources, 'project');

export const filterByContracts = (
  projects: ResourceObjects, 
  contracts: ResourceObjects
): ResourceObjects =>
  storehelper.filterByReferenceToRelatedEntities(projects, contracts, 'contract');

export const filterByAccountIds = (
  projects: ResourceObjects, 
  accountIds: string | string[]
): ResourceObjects =>
  storehelper.filterByReferencedIds(projects, 'account', accountIds);

export const filterByAccounts = (
  projects: ResourceObjects, 
  accounts: ResourceObjects | ResourceObject | undefined | null
): ResourceObjects =>
  storehelper.filterByReferenceToRelatedEntities(projects, accounts, 'account');

export const filterByContractIds = (
  projects: ResourceObjects, 
  contractIds: string[]
): ResourceObjects =>
  storehelper.filterByReferencedIds(projects, 'contract', contractIds);

export const filterByClientIds = (
  projects: ResourceObjects, 
  clientIds: string | string[], 
  contracts: ResourceObjects): ResourceObjects => 
  filterByContracts(projects, contractSelectors.filterByClientIds(contracts, clientIds))
  
export const filterByClients = (
  projects: ResourceObjects, 
  clients: ResourceObjects | ResourceObject, 
  contracts: ResourceObjects): ResourceObjects => 
  filterByClientIds(projects, (Array.isArray(clients) ? clients : [clients]).map(apihelper.getEntityId) as string[], contracts)

export const allProjects = (state: any): ResourceObjects => state.api.project.allProjects;

export const allProjectsByClient = (state: any, client: ResourceObject) => 
  filterByClients(allProjects(state), client, contractSelectors.allContracts(state))

export const allProjectsByClientIds = (state: any, clientIds: string[]) => 
  filterByClientIds(allProjects(state), clientIds, contractSelectors.allContracts(state))

export const allProjectsByAccount = (state: any, account: ResourceObject) => 
  filterByAccounts(allProjects(state), account)

export const projectById = (state: any, projectId: string) => 
  storehelper.findById(allProjects(state), projectId);

export const projectsByIds = (state: any, projectIds: string[]) => 
  storehelper.filterEntitiesByIds(allProjects(state), projectIds);

export const allProjectsByProjectResources = (state: any, projectResources: ResourceObjects) => 
  filterByProjectResources(allProjects(state), projectResources);

export const allProjectsByCurrentUserAccount = createSelector(
  [allProjects, accountSelectors.currentUserAccount],
  (projects, account) => filterByAccounts(projects, account)
)

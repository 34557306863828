import {put, putResolve, select, takeLatest} from 'redux-saga/effects';
import * as types from '../../../constants/ActionTypes';
import * as resourceManagementSelectors from "../../../selectors/pages/resourceManagement";
import '../../../lib/setOperations';
import * as datehelper from '../../../lib/date';

function* doUpdateFilter(action) {
    const curFilter = yield select(resourceManagementSelectors.filter);
    const startTime = yield select(resourceManagementSelectors.startTime);

    const startOfMonth = datehelper.startOfMonth(new Date(action.payload.startTime));

    let isDifferent = 
        ('projectId' in action.payload && action.payload.projectId !== curFilter.projectId) ||
        ('clientId' in action.payload && action.payload.clientId !== curFilter.clientId) ||
        ('startTime' in action.payload && (startTime === null || !datehelper.isSameDay(startTime, startOfMonth))) ||
        ('showInactiveResources' in action.payload && action.payload.showInactiveResources !== curFilter.showInactiveResources) ||
        ('resourceTypeIds' in action.payload && !curFilter.resourceTypeIds.equals(action.payload.resourceTypeIds));

    if(isDifferent){
        let newFilter = Object.assign({}, curFilter, action.payload);
        if(action.payload.startTime){
            newFilter.startTime = startOfMonth.toISOString();
            newFilter.endTime = datehelper.endOfMonth(startOfMonth).toISOString();
        }
        yield putResolve({
            type: types.APP_PAGE_RESOURCEMANAGEMENT_UPDATE_FILTER_CHANGE, 
            payload: newFilter
        })
        yield put({type: types.APP_PAGE_RESOURCEMANAGEMENT_LOAD_REGISTRATIONS});
    }
}

export default function* updateFilter() {
    yield takeLatest(types.APP_PAGE_RESOURCEMANAGEMENT_UPDATE_FILTER_INIT, doUpdateFilter);
}
import React, { Component } from "react";
import PropTypes from "prop-types";
import { Spinner, SpinnerSize } from "@fluentui/react/lib/Spinner";
import * as icons from "../constants/Icons";
import { Icon } from "@fluentui/react/lib/Icon";
import { Callout, DirectionalHint } from "@fluentui/react";
import { DefaultButton } from "@fluentui/react/lib/Button";
import { IContextualMenuItem } from "@fluentui/react/lib/ContextualMenu";
import HorizontalRule from "./HorizontalRule";
import { NotificationActivityItem } from "./uifabricextensions/Notification";
import {
  Notification,
  NotificationLevel,
  NotificationType,
} from "../reducers/app/notification";

type ActivityIconProps = {
  isUploading: boolean;
  hasQueuedModels: boolean;
  hasInvalidQueuedModels: boolean;
  onShowMenuClicked: () => void;
};

const ActivityIcon = ({
  isUploading,
  hasQueuedModels,
  hasInvalidQueuedModels,
  onShowMenuClicked,
}: ActivityIconProps) => {
  const isVisible = isUploading || hasQueuedModels || hasInvalidQueuedModels;
  const clickHandler =
    hasQueuedModels || hasInvalidQueuedModels ? onShowMenuClicked : undefined;
  const title = isUploading
    ? "Changes are being uploaded"
    : hasQueuedModels
    ? "Changes have been queued, awaiting upload"
    : hasInvalidQueuedModels
    ? "Invalid changes have been queued, cannot upload"
    : undefined;
  const iconName = isUploading
    ? icons.ICON_NAME_UPLOAD
    : hasQueuedModels
    ? icons.ICON_NAME_CHANGE_QUEUED
    : hasInvalidQueuedModels
    ? icons.ICON_NAME_INVALID_QUEUED
    : undefined;
  const iconClassName = hasInvalidQueuedModels
    ? "icon-color-red fixposition"
    : "";

  return !isVisible ? null : (
    <div className="novatime-loading-icon" onClick={clickHandler}>
      <Icon iconName={iconName} title={title} className={iconClassName} />
    </div>
  );
};

type LoadingMenuProps = {
  notifications: Array<Notification>;
  hasQueuedModels: boolean;
  hasInvalidQueuedModels: boolean;
  onShowMenu: () => void;
  onDismissFailedRequests: () => void;
  onRollBackQueued: () => void;
  onRollBackInvalidQueued: () => void;
};

const isFailedApiCallNotification = (notification: Notification): boolean =>
  [
    NotificationType.ApiCallDelete,
    NotificationType.ApiCallGet,
    NotificationType.ApiCallPatch,
    NotificationType.ApiCallPost,
  ].includes(notification.type) &&
  notification.level === NotificationLevel.Error;

const LoadingMenu = ({
  notifications,
  hasQueuedModels,
  hasInvalidQueuedModels,
  onShowMenu,
  onDismissFailedRequests,
  onRollBackQueued,
  onRollBackInvalidQueued,
}: LoadingMenuProps) => {
  const hasFailedRequests = notifications.some(isFailedApiCallNotification);

  const footerButtonItems: Array<IContextualMenuItem> = [];
  if (hasFailedRequests) {
    footerButtonItems.push({
      key: "calendarEvent",
      name: "Dismiss notifications",
      onClick: onDismissFailedRequests,
    });
  }

  if (hasQueuedModels) {
    footerButtonItems.push({
      key: "rollbackqueue",
      name: "Rollback all queued changes",
      onClick: onRollBackQueued,
    });
  }

  if (hasInvalidQueuedModels) {
    footerButtonItems.push({
      key: "rollbackinvalidqueue",
      name: "Rollback all invalid queued changes",
      onClick: onRollBackInvalidQueued,
    });
  }

  return (
    <>
      <div className="novatime-loading-indicator-callout-header">
        <p className="novatime-loading-indicator-callout-title">Api requests</p>
      </div>
      <div className="novatime-loading-indicator-callout-inner">
        <div className="novatime-loading-indicator-callout-content">
          <HorizontalRule text="Requests" alignment="left" />
          {notifications.map((notification) => (
            <NotificationActivityItem notification={notification} />
          ))}
        </div>
        <div className="novatime-loading-indicator-callout-footer">
          <DefaultButton
            onClick={onShowMenu}
            split={true}
            text="Close"
            menuProps={
              footerButtonItems.length > 0
                ? { items: footerButtonItems }
                : undefined
            }
          />
        </div>
      </div>
    </>
  );
};

type LoadingIndicatorProps = {
  onDismissFailed: () => void;
  queuedModels: Array<object>;
  onRollbackQueued: () => void;
  onRollbackInvalidQueue: () => void;
  visibleNotifications: Array<Notification>;
  invalidQueuedModels: Array<object>;
};

type LoadingIndicatorState = {
  isCalloutVisible: boolean;
};

export default class LoadingIndicator extends Component<
  LoadingIndicatorProps,
  LoadingIndicatorState
> {
  static propTypes = {
    onDismissFailed: PropTypes.func,
    queuedModels: PropTypes.array,
    onRollbackQueued: PropTypes.func,
    visibleNotifications: PropTypes.array,
  };

  private _loadingIndicatorElement: null | HTMLElement;
  constructor(props: LoadingIndicatorProps) {
    super(props);
    this.state = {
      isCalloutVisible: false,
    };

    this._onDismiss = this._onDismiss.bind(this);
    this._onShowMenuClicked = this._onShowMenuClicked.bind(this);
    this._onDismissFailedRequests = this._onDismissFailedRequests.bind(this);
    this._onRollbackQueued = this._onRollbackQueued.bind(this);
    this._onRollbackInvalidQueue = this._onRollbackInvalidQueue.bind(this);
    this._loadingIndicatorElement = null;
  }

  _setLoadingIndicatorElement(elem: HTMLDivElement | null) {
    this._loadingIndicatorElement = elem;
  }

  _onShowMenuClicked() {
    this.setState({
      isCalloutVisible: !this.state.isCalloutVisible,
    });
  }

  _onDismissFailedRequests() {
    this.props.onDismissFailed && this.props.onDismissFailed();
  }

  _onRollbackQueued() {
    this.props.onRollbackQueued && this.props.onRollbackQueued();
  }

  _onRollbackInvalidQueue() {
    this.props.onRollbackInvalidQueue && this.props.onRollbackInvalidQueue();
  }

  _onDismiss() {
    this.setState({
      isCalloutVisible: false,
    });
  }

  render() {
    const { queuedModels, invalidQueuedModels, visibleNotifications } =
      this.props;
    const { isCalloutVisible } = this.state;

    const liveRequests = visibleNotifications.filter(
      (notif) => notif.level !== NotificationLevel.Error
    );
    const showLoadingIndicator = !!liveRequests.length;
    const hasUndismissedErrors = !!visibleNotifications.some(
      (notif) => notif.level === NotificationLevel.Error
    );
    const hasQueuedModels = queuedModels.length > 0;
    const hasInvalidQueuedModels = invalidQueuedModels.length > 0;
    const isUploading = liveRequests.some(
      (notif) =>
        notif.type === NotificationType.ApiCallPatch ||
        notif.type === NotificationType.ApiCallPost
    );

    return (
      <div className="novatime-loading-indicator">
        <div
          className="novatime-loading-button"
          ref={(menuButton) => this._setLoadingIndicatorElement(menuButton)}
          onClick={this._onShowMenuClicked}
        >
          {showLoadingIndicator ? (
            <Spinner size={SpinnerSize.medium} />
          ) : hasUndismissedErrors ? (
            <Icon
              iconName={icons.ICON_NAME_LOADING_ERROR}
              className="icon-color-red"
            />
          ) : undefined}
        </div>
        <ActivityIcon
          isUploading={isUploading}
          hasQueuedModels={hasQueuedModels}
          hasInvalidQueuedModels={hasInvalidQueuedModels}
          onShowMenuClicked={this._onShowMenuClicked}
        />
        {isCalloutVisible ? (
          <Callout
            className="novatime-loading-indicator-callout"
            onDismiss={this._onDismiss}
            target={this._loadingIndicatorElement}
            directionalHint={DirectionalHint.topRightEdge}
            coverTarget={true}
            isBeakVisible={false}
            gapSpace={0}
          >
            <LoadingMenu
              notifications={visibleNotifications}
              onShowMenu={this._onShowMenuClicked}
              onDismissFailedRequests={this._onDismissFailedRequests}
              onRollBackQueued={this._onRollbackQueued}
              hasQueuedModels={hasQueuedModels}
              hasInvalidQueuedModels={hasInvalidQueuedModels}
              onRollBackInvalidQueued={this._onRollbackInvalidQueue}
            />
          </Callout>
        ) : undefined}
      </div>
    );
  }
}

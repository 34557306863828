import * as types from '../../constants/ActionTypes';

const initialState = {
    selectedClientId: null,
    selectedContractId: null,
    selectedContractRoleId: null,
};

function setSelectedAccount(state, id) {       
    return Object.assign({}, state, {
                selectedAccountId: id,
                selectedClientId: null,
                selectedContractId: null,
                selectedContractRoleId: null
            }); 
}

function setSelectedClient(state, id) {
    return Object.assign({}, state, {
                selectedClientId: id,
                selectedContractId: null,
                selectedContractRoleId: null
            }); 
}

function setSelectedContract(state, id) {
    return Object.assign({}, state, {
                selectedContractId: id,
                selectedContractRoleId: null
            }); 
}

function setSelectedContractRole(state, id) {
    return Object.assign({}, state, {
                selectedContractRoleId: id 
            }); 
}

export default function auth(state = initialState, action) {
    switch (action.type) {
        case types.APP_PAGE_CLIENTS_AND_CONTRACTS_OPENED: {
            return Object.assign({}, state, {});
        }

        case types.API_ACCOUNT_CREATE_SUCCESS : {
            return setSelectedAccount(state, action.payload.requestData.data.id);
        }

        case types.APP_PAGE_CLIENTS_AND_CONTRACTS_CLIENT_SELECTED : {
            let id = action.payload ? action.payload.id : null;
            return setSelectedClient(state, id);
        }

        case types.API_CLIENT_CREATE_SUCCESS : {
            return setSelectedClient(state, action.payload.requestData.data.id);
        }

        case types.APP_PAGE_CLIENTS_AND_CONTRACTS_CONTRACT_SELECTED : {
            let id = action.payload ? action.payload.id : null;
            return setSelectedContract(state, id);
        }

        case types.API_CONTRACT_CREATE_SUCCESS : {
            return setSelectedContract(state, action.payload.requestData.data.id);
        }

        case types.APP_PAGE_CLIENTS_AND_CONTRACTS_CONTRACTROLE_SELECTED : {
            return setSelectedContractRole(state, action.payload ? action.payload.id : null); 
        }

         default:
            return state;
    }
}

import * as SagaHelpers from '../helpers';
import * as SagaEffects from 'redux-saga/effects';
import * as types from '../../constants/ActionTypes';
import PermissionTypesConnector from '../../connectors/PermissionTypes';

const getAllPermissionTypes = SagaHelpers.createApiHandler(
    types.API_PERMISSIONTYPE_GET_ALL_INIT, 
    SagaHelpers.generateGetAllApiCall(
        PermissionTypesConnector,
        function* (connector: PermissionTypesConnector): any {
            return yield SagaEffects.call([connector, connector.getPermissionTypes]);
        }
    )
)

export default function* permissionTypesSaga() {
    yield SagaEffects.all([
        SagaEffects.fork(getAllPermissionTypes)
    ]);
}

import * as types from '../../constants/ActionTypes';

const initialState = {
    selectedAccountId: null,
    modelUnderEdit: null,
    tempInternalClientId: null,
    form: null
};

export default function entityReducer(state = initialState, action: any){
    switch(action.type){

        case types.APP_ENTITY_EDITOR_EDIT_START: {
            return Object.assign({}, state, {
                modelUnderEdit: action.payload.model,
                form : action.payload.form,
            });
        }

        case types.APP_ENTITY_EDITOR_EDIT_CLEAR: {
            return Object.assign({}, state, {
                modelUnderEdit: null,
            });
        }

        case types.APP_ENTITY_EDITOR_TEMP_INTERNAL_CLIENT_ID_SELECTED: {
            return Object.assign({}, state, {
                tempInternalClientId: action.payload.clientId
            });
        }
 
        default: 
            return state
    }
}

import moment from "moment";

export const getWeekIntervalsFromMonth = (
  startDate: Date | null,
  endDate: Date | null
) => {
  const weeks: Array<any> = [],
    firstDate = moment(startDate).toDate(),
    lastDate = moment(endDate).toDate(),
    numOfDays = lastDate.getDate();

  let end = firstDate.getDay() === 0 ? 1 : 8 - firstDate.getDay();

  let start = 1;
  while (start <= numOfDays) {
    weeks.push({
      start: moment(startDate).add(start - 1, "days"),
      end: moment(startDate).add(end - 1, "days"),
    });
    start = end + 1;
    end = end + 7;
    end = start === 1 && end === 8 ? 1 : end;
    if (end > numOfDays) {
      end = numOfDays;
    }
  }
  return weeks;
};

/* tslint:disable:no-unused-variable */
import React, { Component } from "react";
import PropTypes from "prop-types";
import { v1 as uuidv1 } from "uuid";
import {
  DetailsList,
  DetailsListLayoutMode,
  Selection,
  SelectionMode,
} from "@fluentui/react/lib/DetailsList";
import "../lib/setOperations";

export default class DetailsListBasic extends Component {
  constructor(props) {
    super(props);
    const { multiselect, selectable } = this.props;

    this._onSelectionChanged = this._onSelectionChanged.bind(this);
    let selectionMode = selectable
      ? multiselect
        ? SelectionMode.multiple
        : SelectionMode.single
      : SelectionMode.none;
    this.state = {
      selection: new Selection({
        onSelectionChanged: this._onSelectionChanged,
        selectionMode: selectionMode,
      }),
      selectedItems: [],
    };
  }

  static propTypes = {
    defaultItems: PropTypes.array,
    columns: PropTypes.array,
    items: PropTypes.array,
    onSelect: PropTypes.func,
    multiselect: PropTypes.bool,
    isItemSelected: PropTypes.func,
    setKey: PropTypes.string,
    className: PropTypes.string,
    selectable: PropTypes.bool,
  };

  static defaultProps = {
    selectable: true,
    multiselect: false,
  };

  _onSelectionChanged() {
    if (this.props.onSelect) {
      if (
        !this._hasControlledSelection() ||
        !this.state.selectedItems.equals(this.state.selection.getSelection())
      ) {
        this.props.onSelect(this.state.selection.getSelection());
      }
    }
  }

  _hasControlledSelection() {
    return !!this.props.isItemSelected;
  }

  // because the selection object in UI fabric is kind of crap and we use a function to mark which records are
  // selected, we need all this stuff at exactly this hook.
  static getDerivedStateFromProps(props, state) {
    let { items, isItemSelected } = props;
    let newState = { selection: state.selection, selectedItems: [] };
    let hasControlledSelection = !!isItemSelected;

    if (items) {
      let didChange = false;
      if (items !== state.selection.getItems()) {
        state.selection.setChangeEvents(false);
        state.selection.setItems(items, false);
        state.selection.setChangeEvents(true, true);
        didChange = true;
      }

      if ((didChange || items.length > 0) && hasControlledSelection) {
        let selectedItems = items.filter(isItemSelected);
        let selectedItemIndices = selectedItems.map((item) =>
          items.indexOf(item)
        );

        // if the UI fabric list's current state for selection doesn't match (i.g. selection wasn't changed
        // through a click), change it manually
        if (
          !selectedItemIndices.every(
            state.selection.isIndexSelected,
            state.selection
          ) ||
          state.selection.getSelectedCount() !== selectedItemIndices.length
        ) {
          state.selection.setChangeEvents(false);
          state.selection.setAllSelected(false);
          selectedItemIndices.forEach((idx) =>
            state.selection.setIndexSelected(idx, true)
          );
          state.selection.setChangeEvents(true, true);
        }

        newState.selectedItems = selectedItems;
        didChange = true;
      }
      if (didChange) {
        return newState;
      }
    }

    return null;
  }

  render() {
    const { items, columns, multiselect, setKey, ...other } = this.props;

    return (
      <DetailsList
        {...other}
        items={items}
        columns={columns}
        multiselect={multiselect}
        layoutMode={DetailsListLayoutMode.fixedColumns}
        selection={this.state.selection}
        selectionPreservedOnEmptyClick={true}
        ariaLabelForSelectionColumn="Toggle selection"
        ariaLabelForSelectAllCheckbox="Toggle selection for all items"
        setKey={"static" || setKey || uuidv1()}
      />
    );
  }
}

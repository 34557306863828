/* Application */

export const APP_BOOTSTRAP_INIT = "APP_BOOTSTRAP_INIT";
export const APP_BOOTSTRAP_STARTED = "APP_BOOTSTRAP_STARTED";
export const APP_BOOTSTRAP_COMPLETED = "APP_BOOTSTRAP_COMPLETED";

export const APP_FETCHINITIALDATA_INIT = "APP_FETCHINITIALDATA_INIT";
export const APP_FETCHINITIALDATA_STARTED = "APP_FETCHINITIALDATA_STARTED";
export const APP_FETCHINITIALDATA_COMPLETED = "APP_FETCHINITIALDATA_COMPLETED";

export const APP_DOMAIN_LOCK_PERIODS_INIT = "APP_DOMAIN_LOCK_PERIODS_INIT";
export const APP_DOMAIN_LOCK_PERIODS_STARTED =
  "APP_DOMAIN_LOCK_PERIODS_STARTED";
export const APP_DOMAIN_LOCK_PERIODS_COMPLETED =
  "APP_DOMAIN_LOCK_PERIODS_COMPLETED";

export const APP_DOMAIN_OPEN_PERIODS_INIT = "APP_DOMAIN_OPEN_PERIODS_INIT";
export const APP_DOMAIN_OPEN_PERIODS_STARTED =
  "APP_DOMAIN_OPEN_PERIODS_STARTED";
export const APP_DOMAIN_OPEN_PERIODS_COMPLETED =
  "APP_DOMAIN_OPEN_PERIODS_COMPLETED";

export const APP_SET_SELECTED_ACCOUNT = "APP_SET_SELECTED_ACCOUNT";

/* Entity Editor */
export const APP_ENTITY_EDITOR_EDIT_START = "APP_ENTITY_EDITOR_EDIT_START";
export const APP_ENTITY_EDITOR_EDIT_END = "APP_ENTITY_EDITOR_EDIT_END";
export const APP_ENTITY_EDITOR_EDIT_CLEAR = "APP_ENTITY_EDITOR_EDIT_CLEAR";

export const APP_ENTITY_EDITOR_TEMP_INTERNAL_CLIENT_ID_SELECTED =
  "APP_ENTITY_EDITOR_TEMP_INTERNAL_CLIENT_ID_SELECTED  ";

/* Notification */
export const APP_NOTIFICATION_ADD = "APP_NOTIFICATION_ADD";
export const APP_NOTIFICATION_REPLACE = "APP_NOTIFICATION_REPLACE";
export const APP_NOTIFICATION_DISMISS_ALL = "APP_NOTIFICATION_DISMISS_ALL";

/* Application pages */

export const APP_PAGE_CLIENTS_AND_CONTRACTS_OPENED =
  "APP_PAGE_CLIENTS_AND_CONTRACTS_OPENED";
export const APP_PAGE_CLIENTS_AND_CONTRACTS_LOAD_CLIENTS =
  "APP_PAGE_CLIENTS_AND_CONTRACTS_LOAD_CLIENTS";
export const APP_PAGE_CLIENTS_AND_CONTRACTS_CLIENT_SELECTED =
  "APP_PAGE_CLIENTS_AND_CONTRACTS_CLIENT_SELECTED";
export const APP_PAGE_CLIENTS_AND_CONTRACTS_CONTRACT_SELECTED =
  "APP_PAGE_CLIENTS_AND_CONTRACTS_CONTRACT_SELECTED";
export const APP_PAGE_CLIENTS_AND_CONTRACTS_CONTRACTROLE_SELECTED =
  "APP_PAGE_CLIENTS_AND_CONTRACTS_CONTRACTROLE_SELECTED";

export const APP_PAGE_PROJECTS_AND_ROLES_OPENED =
  "APP_PAGE_PROJECTS_AND_ROLES_OPENED";
export const APP_PAGE_PROJECTS_AND_ROLES_LOAD_CLIENTS =
  "APP_PAGE_PROJECTS_AND_ROLES_LOAD_CLIENTS";
export const APP_PAGE_PROJECTS_AND_ROLES_CLIENT_SELECTED =
  "APP_PAGE_PROJECTS_AND_ROLES_CLIENT_SELECTED";
export const APP_PAGE_PROJECTS_AND_ROLES_PROJECT_SELECTED =
  "APP_PAGE_PROJECTS_AND_ROLES_PROJECT_SELECTED";
export const APP_PAGE_PROJECTS_AND_ROLES_PROJECTRESOURCE_SELECTED =
  "APP_PAGE_PROJECTS_AND_ROLES_PROJECTRESOURCE_SELECTED";
export const APP_PAGE_PROJECTS_AND_ROLES_ALL_PROJECTS =
  "APP_PAGE_PROJECTS_AND_ROLES_ALL_PROJECTS";

export const APP_PAGE_USERSANDRESOURCES_OPENED =
  "APP_PAGE_USERSANDRESOURCES_OPENED";
export const APP_PAGE_USERSANDRESOURCES_LOAD_USERS_RESOURCES_PERMISSIONS =
  "APP_PAGE_USERSANDRESOURCES_LOAD_USERS_RESOURCES_PERMISSIONS";
export const APP_PAGE_USERSANDRESOURCES_USER_SELECTED =
  "APP_PAGE_USERSANDRESOURCES_USER_SELECTED";
export const APP_PAGE_USERSANDRESOURCES_RESOURCE_SELECTED =
  "APP_PAGE_USERSANDRESOURCES_RESOURCE_SELECTED";
export const APP_PAGE_USERSANDRESOURCES_PERMISSION_SELECTED =
  "APP_PAGE_USERSANDRESOURCES_PERMISSION_SELECTED";
export const APP_PAGE_USERSANDRESOURCES_EDIT_START =
  "APP_PAGE_USERSANDRESOURCES_EDIT_START";
export const APP_PAGE_USERSANDRESOURCES_EDIT_END =
  "APP_PAGE_USERSANDRESOURCES_EDIT_END";
export const APP_PAGE_USERSANDRESOURCES_EDIT_CLEAR =
  "APP_PAGE_USERSANDRESOURCES_EDIT_CLEAR";
export const APP_PAGE_USERSANDRESOURCES_TOGGLE_INACTIVES =
  "APP_PAGE_USERSANDRESOURCES_TOGGLE_INACTIVES";

export const APP_PAGE_TIMEREGISTRATION_OPENED =
  "APP_PAGE_TIMEREGISTRATION_OPENED";
export const APP_PAGE_TIMEREGISTRATION_RESOURCE_SELECTED =
  "APP_PAGE_TIMEREGISTRATION_RESOURCE_SELECTED";
export const APP_PAGE_TIMEREGISTRATION_WEEK_SELECTED =
  "APP_PAGE_TIMEREGISTRATION_WEEK_SELECTED";
export const APP_PAGE_TIMEREGISTRATION_FETCH_WEEK_REGISTRATIONS =
  "APP_PAGE_TIMEREGISTRATION_FETCH_WEEK_REGISTRATIONS";
export const APP_PAGE_TIMEREGISTRATION_BEGIN_EDIT_TIME_REGISTRATION =
  "APP_PAGE_TIMEREGISTRATION_BEGIN_EDIT_TIME_REGISTRATION";
export const APP_PAGE_TIMEREGISTRATION_CLEAR_SELECTED_TIME_REGISTRATION =
  "APP_PAGE_TIMEREGISTRATION_CLEAR_SELECTED_TIME_REGISTRATION";
export const APP_PAGE_TIMEREGISTRATION_DELAYED_CLEAR_SELECTION =
  "APP_PAGE_TIMEREGISTRATION_DELAYED_CLEAR_SELECTION";
export const APP_PAGE_TIMEREGISTRATION_DELETE_REGISTRATION =
  "APP_PAGE_TIMEREGISTRATION_DELETE_REGISTRATION";
export const APP_PAGE_TIMEREGISTRATION_ADD_SAVED_PROJECT =
  "APP_PAGE_TIMEREGISTRATION_ADD_SAVED_PROJECT";
export const APP_PAGE_TIMEREGISTRATION_REMOVE_SAVED_PROJECT =
  "APP_PAGE_TIMEREGISTRATION_REMOVE_SAVED_PROJECT";
export const APP_PAGE_TIMEREGISTRATION_OPEN_DIALOG =
  "APP_PAGE_TIMEREGISTRATION_OPEN_DIALOG";
export const APP_PAGE_TIMEREGISTRATION_CLOSE_DIALOG =
  "APP_PAGE_TIMEREGISTRATION_CLOSE_DIALOG";
export const APP_PAGE_TIMEREGISTRATION_LOCK_PERIOD =
  "APP_PAGE_TIMEREGISTRATION_LOCK_PERIOD";
export const APP_PAGE_TIMEREGISTRATION_CHECK_LOCKED_LAST_WEEK =
  "APP_PAGE_TIMEREGISTRATION_CHECK_LOCKED_LAST_WEEK";
export const APP_PAGE_TIMEREGISTRATION_CHECK_LOCKED_LAST_WEEK_COMPLETE =
  "APP_PAGE_TIMEREGISTRATION_CHECK_LOCKED_LAST_WEEK_COMPLETE";
export const APP_PAGE_TIMEREGISTRATION_DELETE_WRITEBACK_COMPLETE =
  "APP_PAGE_TIMEREGISTRATION_DELETE_WRITEBACK_COMPLETE";
export const APP_PAGE_TIMEREGISTRATION_MAKE_CHANGE_TO_CURRENT_EDIT =
  "APP_PAGE_TIMEREGISTRATION_MAKE_CHANGE_TO_CURRENT_EDIT";

export const APP_PAGE_RESOURCEMANAGEMENT_OPENED =
  "APP_PAGE_RESOURCEMANAGEMENT_OPENED";
export const APP_PAGE_RESOURCEMANAGEMENT_UPDATE_FILTER_INIT =
  "APP_PAGE_RESOURCEMANAGEMENT_UPDATE_FILTER_INIT";
export const APP_PAGE_RESOURCEMANAGEMENT_UPDATE_FILTER_CHANGE =
  "APP_PAGE_RESOURCEMANAGEMENT_UPDATE_FILTER_CHANGE";
export const APP_PAGE_RESOURCEMANAGEMENT_LOAD_REGISTRATIONS =
  "APP_PAGE_RESOURCEMANAGEMENT_LOAD_REGISTRATIONS";
export const APP_PAGE_RESOURCEMANAGEMENT_CELL_SELECTED =
  "APP_PAGE_RESOURCEMANAGEMENT_CELL_SELECTED";
export const APP_PAGE_RESOURCEMANAGEMENT_TR_SELECTED =
  "APP_PAGE_RESOURCEMANAGEMENT_TR_SELECTED";
export const APP_PAGE_RESOURCEMANAGEMENT_CLEAR_SELECTED_CELL =
  "APP_PAGE_RESOURCEMANAGEMENT_CLEAR_SELECTED_CELL";
export const APP_PAGE_RESOURCEMANAGEMENT_UPDATE_VIEW_DESC =
  "APP_PAGE_RESOURCEMANAGEMENT_UPDATE_VIEW_DESC";
export const APP_PAGE_RESOURCEMANAGEMENT_LOCK_PERIODS =
  "APP_PAGE_RESOURCEMANAGEMENT_LOCK_PERIODS";
export const APP_PAGE_RESOURCEMANAGEMENT_LOCK_PERIODS_INIT =
  "APP_PAGE_RESOURCEMANAGEMENT_LOCK_PERIODS_INIT";
export const APP_PAGE_RESOURCEMANAGEMENT_LOCK_PERIODS_COMPLETED =
  "APP_PAGE_RESOURCEMANAGEMENT_LOCK_PERIODS_COMPLETED";
export const APP_PAGE_RESOURCEMANAGEMENT_OPEN_PERIODS =
  "APP_PAGE_RESOURCEMANAGEMENT_OPEN_PERIODS";
export const APP_PAGE_RESOURCEMANAGEMENT_OPEN_PERIODS_INIT =
  "APP_PAGE_RESOURCEMANAGEMENT_OPEN_PERIODS_INIT";
export const APP_PAGE_RESOURCEMANAGEMENT_OPEN_PERIODS_COMPLETED =
  "APP_PAGE_RESOURCEMANAGEMENT_OPEN_PERIODS_COMPLETED";
export const APP_PAGE_RESOURCEMANAGEMENT_TOGGLE_PROCESSING_DIALOG =
  "APP_PAGE_RESOURCEMANAGEMENT_TOGGLE_PROCESSING_DIALOG";

export const APP_PAGE_ABSENCEPERIODS_OPENED = "APP_PAGE_ABSENCEPERIODS_OPENED";
export const APP_PAGE_ABSENCEPERIODS_SELECTED =
  "APP_PAGE_ABSENCEPERIODS_SELECTED";
export const APP_PAGE_ABSENCEPERIODS_LOAD_COMMENTS =
  "APP_PAGE_ABSENCEPERIODS_LOAD_COMMENTS";
export const APP_PAGE_ABSENCEPERIODS_SET_YEAR =
  "APP_PAGE_ABSENCEPERIODS_SET_YEAR";
export const APP_PAGE_ABSENCEPERIODS_RESOURCE_SELECTED =
  "APP_PAGE_ABSENCEPERIODS_RESOURCE_SELECTED";
export const APP_PAGE_ABSENCEPERIODS_LOAD_CURRENT =
  "APP_PAGE_ABSENCEPERIODS_LOAD_CURRENT";
export const APP_PAGE_ABSENCEPERIODS_EDIT_START =
  "APP_PAGE_ABSENCEPERIODS_EDIT_START";
export const APP_PAGE_ABSENCEPERIODS_EDIT_END =
  "APP_PAGE_ABSENCEPERIODS_EDIT_END";
export const APP_PAGE_ABSENCEPERIODS_EDIT_CLEAR =
  "APP_PAGE_ABSENCEPERIODS_EDIT_CLEAR";
export const APP_PAGE_ABSENCEPERIODS_COMMENT_START =
  "APP_PAGE_ABSENCEPERIODS_COMMENT_START";
export const APP_PAGE_ABSENCEPERIODS_COMMENT_END =
  "APP_PAGE_ABSENCEPERIODS_COMMENT_END";

export const APP_PAGE_TIMEREGISTRATIONEXPORT_OPENED =
  "APP_PAGE_TIMEREGISTRATIONEXPORT_OPENED";
export const APP_PAGE_TIMEREGISTRATIONEXPORT_UPDATE_FILTER_INIT =
  "APP_PAGE_TIMEREGISTRATIONEXPORT_UPDATE_FILTER_INIT";
export const APP_PAGE_TIMEREGISTRATIONEXPORT_UPDATE_FILTER_CHANGE =
  "APP_PAGE_TIMEREGISTRATIONEXPORT_UPDATE_FILTER_CHANGE";
export const APP_PAGE_TIMEREGISTRATIONEXPORT_LOAD_REGISTRATIONS =
  "APP_PAGE_TIMEREGISTRATIONEXPORT_LOAD_REGISTRATIONS";
export const APP_PAGE_TIMEREGISTRATIONEXPORT_UPDATE_VIEW_DESC =
  "APP_PAGE_TIMEREGISTRATIONEXPORT_UPDATE_VIEW_DESC";

/* Authentication */
export const AUTH_INIT = "AUTH_INIT";
export const AUTH_CHECK = "AUTH_CHECK";
export const AUTH_COMPLETE = "AUTH_COMPLETE";
export const AUTH_LOGIN_EXPIRED = "AUTH_LOGIN_EXPIRED";
export const AUTH_INIT_LOGIN = "AUTH_INIT_LOGIN";
export const AUTH_INIT_LOGOUT = "AUTH_INIT_LOGOUT";

/* Navigation */
export const NAVIGATION_TO_HOME = "NAVIGATION_TO_HOME";
export const NAVIGATION_TO_URL = "NAVIGATION_TO_URL";

/* Application Insights */

export const AI_LOCATION_CHANGED = "AI_LOCATION_CHANGED";

/* Api */

export const API_CALL_CANCELLED = "API_CALL_CANCELLED";

/* write back queue */

export const WRITEBACK_QUEUE_ADD_CHANGE_TO_QUEUE_INIT =
  "WRITEBACK_QUEUE_ADD_CHANGE_TO_QUEUE_INIT";
export const WRITEBACK_QUEUE_ADD_CHANGE_TO_QUEUE_CHANGE =
  "WRITEBACK_QUEUE_ADD_CHANGE_TO_QUEUE_CHANGE";
export const WRITEBACK_QUEUE_ADD_CHANGE_TO_QUEUE_DONE =
  "WRITEBACK_QUEUE_ADD_CHANGE_TO_QUEUE_DONE";
export const WRITEBACK_QUEUE_CLAIM_MODEL_FOR_WRITEBACK_INIT =
  "WRITEBACK_QUEUE_CLAIM_MODEL_FOR_WRITEBACK_INIT";
export const WRITEBACK_QUEUE_CLAIM_MODEL_FOR_WRITEBACK_CHANGE =
  "WRITEBACK_QUEUE_CLAIM_MODEL_FOR_WRITEBACK_CHANGE";
export const WRITEBACK_QUEUE_CLAIM_MODEL_FOR_WRITEBACK_DONE =
  "WRITEBACK_QUEUE_CLAIM_MODEL_FOR_WRITEBACK_DONE";
export const WRITEBACK_QUEUE_WRITEBACK_STARTED =
  "WRITEBACK_QUEUE_WRITEBACK_STARTED";
export const WRITEBACK_QUEUE_WRITEBACK_SUCCESS =
  "WRITEBACK_QUEUE_WRITEBACK_SUCCESS";
export const WRITEBACK_QUEUE_WRITEBACK_ERROR =
  "WRITEBACK_QUEUE_WRITEBACK_ERROR";
export const WRITEBACK_QUEUE_WRITEBACK_COMPLETE =
  "WRITEBACK_QUEUE_WRITEBACK_COMPLETE";
export const WRITEBACK_QUEUE_WRITEBACK_INIT = "WRITEBACK_QUEUE_WRITEBACK_INIT";
export const WRITEBACK_QUEUE_ROLLBACK_ALL_QUEUED_CHANGES =
  "WRITEBACK_QUEUE_ROLLBACK_ALL_QUEUED_CHANGES";
export const WRITEBACK_QUEUE_ROLLBACK_ALL_QUEUED_INVALID_CHANGES =
  "WRITEBACK_QUEUE_ROLLBACK_ALL_QUEUED_INVALID_CHANGES";

/* Accounts */

export const API_ACCOUNT_GET_ALL_INIT = "API_ACCOUNT_GET_ALL_INIT";
export const API_ACCOUNT_GET_ALL_REQUEST = "API_ACCOUNT_GET_ALL_REQUEST";
export const API_ACCOUNT_GET_ALL_SUCCESS = "API_ACCOUNT_GET_ALL_SUCCESS";
export const API_ACCOUNT_GET_ALL_ERROR = "API_ACCOUNT_GET_ALL_ERROR";

export const API_ACCOUNT_UPDATE_INIT = "API_ACCOUNT_UPDATE_INIT";
export const API_ACCOUNT_UPDATE_REQUEST = "API_ACCOUNT_UPDATE_REQUEST";
export const API_ACCOUNT_UPDATE_SUCCESS = "API_ACCOUNT_UPDATE_SUCCESS";
export const API_ACCOUNT_UPDATE_ERROR = "API_ACCOUNT_UPDATE_ERROR";

export const API_ACCOUNT_CREATE_INIT = "API_ACCOUNT_CREATE_INIT";
export const API_ACCOUNT_CREATE_REQUEST = "API_ACCOUNT_CREATE_REQUEST";
export const API_ACCOUNT_CREATE_SUCCESS = "API_ACCOUNT_CREATE_SUCCESS";
export const API_ACCOUNT_CREATE_ERROR = "API_ACCOUNT_CREATE_ERROR";

export const API_ACCOUNT_DELETE_INIT = "API_ACCOUNT_DELETE_INIT";
export const API_ACCOUNT_DELETE_REQUEST = "API_ACCOUNT_DELETE_REQUEST";
export const API_ACCOUNT_DELETE_SUCCESS = "API_ACCOUNT_DELETE_SUCCESS";
export const API_ACCOUNT_DELETE_ERROR = "API_ACCOUNT_DELETE_ERROR";

/* Clients */

export const API_CLIENT_GET_ALL_INIT = "API_CLIENT_GET_ALL_INIT";
export const API_CLIENT_GET_ALL_REQUEST = "API_CLIENT_GET_ALL_REQUEST";
export const API_CLIENT_GET_ALL_SUCCESS = "API_CLIENT_GET_ALL_SUCCESS";
export const API_CLIENT_GET_ALL_ERROR = "API_CLIENT_GET_ALL_ERROR";

export const API_CLIENT_CREATE_INIT = "API_CLIENT_CREATE_INIT";
export const API_CLIENT_CREATE_REQUEST = "API_CLIENT_CREATE_REQUEST";
export const API_CLIENT_CREATE_SUCCESS = "API_CLIENT_CREATE_SUCCESS";
export const API_CLIENT_CREATE_ERROR = "API_CLIENT_CREATE_ERROR";

export const API_CLIENT_UPDATE_INIT = "API_CLIENT_UPDATE_INIT";
export const API_CLIENT_UPDATE_REQUEST = "API_CLIENT_UPDATE_REQUEST";
export const API_CLIENT_UPDATE_SUCCESS = "API_CLIENT_UPDATE_SUCCESS";
export const API_CLIENT_UPDATE_ERROR = "API_CLIENT_UPDATE_ERROR";

export const API_CLIENT_DELETE_INIT = "API_CLIENT_DELETE_INIT";
export const API_CLIENT_DELETE_REQUEST = "API_CLIENT_DELETE_REQUEST";
export const API_CLIENT_DELETE_SUCCESS = "API_CLIENT_DELETE_SUCCESS";
export const API_CLIENT_DELETE_ERROR = "API_CLIENT_DELETE_ERROR";

/* Users */

export const API_USER_GET_ALL_INIT = "API_USER_GET_ALL_INIT";
export const API_USER_GET_ALL_REQUEST = "API_USER_GET_ALL_REQUEST";
export const API_USER_GET_ALL_SUCCESS = "API_USER_GET_ALL_SUCCESS";
export const API_USER_GET_ALL_ERROR = "API_USER_GET_ALL_ERROR";

export const API_USER_GET_ME_INIT = "API_USER_GET_ME_INIT";
export const API_USER_GET_ME_REQUEST = "API_USER_GET_ME_REQUEST";
export const API_USER_GET_ME_SUCCESS = "API_USER_GET_ME_SUCCESS";
export const API_USER_GET_ME_ERROR = "API_USER_GET_ME_ERROR";

export const API_USER_UPDATE_INIT = "API_USER_UPDATE_INIT";
export const API_USER_UPDATE_REQUEST = "API_USER_UPDATE_REQUEST";
export const API_USER_UPDATE_SUCCESS = "API_USER_UPDATE_SUCCESS";
export const API_USER_UPDATE_ERROR = "API_USER_UPDATE_ERROR";

export const API_USER_CREATE_INIT = "API_USER_CREATE_INIT";
export const API_USER_CREATE_REQUEST = "API_USER_CREATE_REQUEST";
export const API_USER_CREATE_SUCCESS = "API_USER_CREATE_SUCCESS";
export const API_USER_CREATE_ERROR = "API_USER_CREATE_ERROR";

/* Contracts */

export const API_CONTRACT_GET_ALL_INIT = "API_CONTRACT_GET_ALL_INIT";
export const API_CONTRACT_GET_ALL_REQUEST = "API_CONTRACT_GET_ALL_REQUEST";
export const API_CONTRACT_GET_ALL_SUCCESS = "API_CONTRACT_GET_ALL_SUCCESS";
export const API_CONTRACT_GET_ALL_ERROR = "API_CONTRACT_GET_ALL_ERROR";

export const API_CONTRACT_CREATE_INIT = "API_CONTRACT_CREATE_INIT";
export const API_CONTRACT_CREATE_REQUEST = "API_CONTRACT_CREATE_REQUEST";
export const API_CONTRACT_CREATE_SUCCESS = "API_CONTRACT_CREATE_SUCCESS";
export const API_CONTRACT_CREATE_ERROR = "API_CONTRACT_CREATE_ERROR";

export const API_CONTRACT_UPDATE_INIT = "API_CONTRACT_UPDATE_INIT";
export const API_CONTRACT_UPDATE_REQUEST = "API_CONTRACT_UPDATE_REQUEST";
export const API_CONTRACT_UPDATE_SUCCESS = "API_CONTRACT_UPDATE_SUCCESS";
export const API_CONTRACT_UPDATE_ERROR = "API_CONTRACT_UPDATE_ERROR";

export const API_CONTRACT_DELETE_INIT = "API_CONTRACT_DELETE_INIT";
export const API_CONTRACT_DELETE_REQUEST = "API_CONTRACT_DELETE_REQUEST";
export const API_CONTRACT_DELETE_SUCCESS = "API_CONTRACT_DELETE_SUCCESS";
export const API_CONTRACT_DELETE_ERROR = "API_CONTRACT_DELETE_ERROR";

/* Contract roles */

export const API_CONTRACTROLE_GET_ALL_INIT = "API_CONTRACTROLE_GET_ALL_INIT";
export const API_CONTRACTROLE_GET_ALL_REQUEST =
  "API_CONTRACTROLE_GET_ALL_REQUEST";
export const API_CONTRACTROLE_GET_ALL_SUCCESS =
  "API_CONTRACTROLE_GET_ALL_SUCCESS";
export const API_CONTRACTROLE_GET_ALL_ERROR = "API_CONTRACTROLE_GET_ALL_ERROR";

export const API_CONTRACTROLE_CREATE_INIT = "API_CONTRACTROLE_CREATE_INIT";
export const API_CONTRACTROLE_CREATE_REQUEST =
  "API_CONTRACTROLE_CREATE_REQUEST";
export const API_CONTRACTROLE_CREATE_SUCCESS =
  "API_CONTRACTROLE_CREATE_SUCCESS";
export const API_CONTRACTROLE_CREATE_ERROR = "API_CONTRACTROLE_CREATE_ERROR";

export const API_CONTRACTROLE_UPDATE_INIT = "API_CONTRACTROLE_UPDATE_INIT";
export const API_CONTRACTROLE_UPDATE_REQUEST =
  "API_CONTRACTROLE_UPDATE_REQUEST";
export const API_CONTRACTROLE_UPDATE_SUCCESS =
  "API_CONTRACTROLE_UPDATE_SUCCESS";
export const API_CONTRACTROLE_UPDATE_ERROR = "API_CONTRACTROLE_UPDATE_ERROR";

export const API_CONTRACTROLE_DELETE_INIT = "API_CONTRACTROLE_DELETE_INIT";
export const API_CONTRACTROLE_DELETE_REQUEST =
  "API_CONTRACTROLE_DELETE_REQUEST";
export const API_CONTRACTROLE_DELETE_SUCCESS =
  "API_CONTRACTROLE_DELETE_SUCCESS";
export const API_CONTRACTROLE_DELETE_ERROR = "API_CONTRACTROLE_DELETE_ERROR";

/* Resource */

export const API_RESOURCE_GET_ALL_INIT = "API_RESOURCE_GET_ALL_INIT";
export const API_RESOURCE_GET_ALL_REQUEST = "API_RESOURCE_GET_ALL_REQUEST";
export const API_RESOURCE_GET_ALL_SUCCESS = "API_RESOURCE_GET_ALL_SUCCESS";
export const API_RESOURCE_GET_ALL_ERROR = "API_RESOURCE_GET_ALL_ERROR";

export const API_RESOURCE_UPDATE_INIT = "API_RESOURCE_UPDATE_INIT";
export const API_RESOURCE_UPDATE_REQUEST = "API_RESOURCE_UPDATE_REQUEST";
export const API_RESOURCE_UPDATE_SUCCESS = "API_RESOURCE_UPDATE_SUCCESS";
export const API_RESOURCE_UPDATE_ERROR = "API_RESOURCE_UPDATE_ERROR";

export const API_RESOURCE_CREATE_INIT = "API_RESOURCE_CREATE_INIT";
export const API_RESOURCE_CREATE_REQUEST = "API_RESOURCE_CREATE_REQUEST";
export const API_RESOURCE_CREATE_SUCCESS = "API_RESOURCE_CREATE_SUCCESS";
export const API_RESOURCE_CREATE_ERROR = "API_RESOURCE_CREATE_ERROR";

export const API_RESOURCE_DELETE_INIT = "API_RESOURCE_DELETE_INIT";
export const API_RESOURCE_DELETE_REQUEST = "API_RESOURCE_DELETE_REQUEST";
export const API_RESOURCE_DELETE_SUCCESS = "API_RESOURCE_DELETE_SUCCESS";
export const API_RESOURCE_DELETE_ERROR = "API_RESOURCE_DELETE_ERROR";

/* Projects */

export const API_PROJECT_GET_ALL_INIT = "API_PROJECT_GET_ALL_INIT";
export const API_PROJECT_GET_ALL_REQUEST = "API_PROJECT_GET_ALL_REQUEST";
export const API_PROJECT_GET_ALL_SUCCESS = "API_PROJECT_GET_ALL_SUCCESS";
export const API_PROJECT_GET_ALL_ERROR = "API_PROJECT_GET_ALL_ERROR";

export const API_PROJECT_GET_INIT = "API_PROJECT_GET_INIT";
export const API_PROJECT_GET_REQUEST = "API_PROJECT_GET_REQUEST";
export const API_PROJECT_GET_SUCCESS = "API_PROJECT_GET_SUCCESS";
export const API_PROJECT_GET_ERROR = "API_PROJECT_GET_ERROR";

export const API_PROJECT_UPDATE_INIT = "API_PROJECT_UPDATE_INIT";
export const API_PROJECT_UPDATE_REQUEST = "API_PROJECT_UPDATE_REQUEST";
export const API_PROJECT_UPDATE_SUCCESS = "API_PROJECT_UPDATE_SUCCESS";
export const API_PROJECT_UPDATE_ERROR = "API_PROJECT_UPDATE_ERROR";

export const API_PROJECT_CREATE_INIT = "API_PROJECT_CREATE_INIT";
export const API_PROJECT_CREATE_REQUEST = "API_PROJECT_CREATE_REQUEST";
export const API_PROJECT_CREATE_SUCCESS = "API_PROJECT_CREATE_SUCCESS";
export const API_PROJECT_CREATE_ERROR = "API_PROJECT_CREATE_ERROR";

export const API_PROJECT_DELETE_INIT = "API_PROJECT_DELETE_INIT";
export const API_PROJECT_DELETE_REQUEST = "API_PROJECT_DELETE_REQUEST";
export const API_PROJECT_DELETE_SUCCESS = "API_PROJECT_DELETE_SUCCESS";
export const API_PROJECT_DELETE_ERROR = "API_PROJECT_DELETE_ERROR";

export const API_PROJECT_GET_ALL_FOR_PERMISSION_INIT =
  "API_PROJECT_GET_ALL_FOR_PERMISSION_INIT";
export const API_PROJECT_GET_ALL_FOR_PERMISSION_REQUEST =
  "API_PROJECT_GET_ALL_FOR_PERMISSION_REQUEST";
export const API_PROJECT_GET_ALL_FOR_PERMISSION_SUCCESS =
  "API_PROJECT_GET_ALL_FOR_PERMISSION_SUCCESS";
export const API_PROJECT_GET_ALL_FOR_PERMISSION_ERROR =
  "API_PROJECT_GET_ALL_FOR_PERMISSION_ERROR";

/* TimeRegistration */

export const API_TIMEREGISTRATION_GET_ALL_INIT =
  "API_TIMEREGISTRATION_GET_ALL_INIT";
export const API_TIMEREGISTRATION_GET_ALL_REQUEST =
  "API_TIMEREGISTRATION_GET_ALL_REQUEST";
export const API_TIMEREGISTRATION_GET_ALL_SUCCESS =
  "API_TIMEREGISTRATION_GET_ALL_SUCCESS";
export const API_TIMEREGISTRATION_GET_ALL_ERROR =
  "API_TIMEREGISTRATION_GET_ALL_ERROR";

export const API_TIMEREGISTRATION_CREATE_INIT =
  "API_TIMEREGISTRATION_CREATE_INIT";
export const API_TIMEREGISTRATION_CREATE_REQUEST =
  "API_TIMEREGISTRATION_CREATE_REQUEST";
export const API_TIMEREGISTRATION_CREATE_SUCCESS =
  "API_TIMEREGISTRATION_CREATE_SUCCESS";
export const API_TIMEREGISTRATION_CREATE_ERROR =
  "API_TIMEREGISTRATION_CREATE_ERROR";

export const API_TIMEREGISTRATION_UPDATE_INIT =
  "API_TIMEREGISTRATION_UPDATE_INIT";
export const API_TIMEREGISTRATION_UPDATE_REQUEST =
  "API_TIMEREGISTRATION_UPDATE_REQUEST";
export const API_TIMEREGISTRATION_UPDATE_SUCCESS =
  "API_TIMEREGISTRATION_UPDATE_SUCCESS";
export const API_TIMEREGISTRATION_UPDATE_ERROR =
  "API_TIMEREGISTRATION_UPDATE_ERROR";

export const API_TIMEREGISTRATION_DELETE_INIT =
  "API_TIMEREGISTRATION_DELETE_INIT";
export const API_TIMEREGISTRATION_DELETE_REQUEST =
  "API_TIMEREGISTRATION_DELETE_REQUEST";
export const API_TIMEREGISTRATION_DELETE_SUCCESS =
  "API_TIMEREGISTRATION_DELETE_SUCCESS";
export const API_TIMEREGISTRATION_DELETE_ERROR =
  "API_TIMEREGISTRATION_DELETE_ERROR";

/* LockedTimePeriod */
export const API_LOCKEDTIMEPERIOD_GET_ALL_INIT =
  "API_LOCKEDTIMEPERIOD_GET_ALL_INIT";
export const API_LOCKEDTIMEPERIOD_GET_ALL_REQUEST =
  "API_LOCKEDTIMEPERIOD_GET_ALL_REQUEST";
export const API_LOCKEDTIMEPERIOD_GET_ALL_SUCCESS =
  "API_LOCKEDTIMEPERIOD_GET_ALL_SUCCESS";
export const API_LOCKEDTIMEPERIOD_GET_ALL_ERROR =
  "API_LOCKEDTIMEPERIOD_GET_ALL_ERROR";

export const API_LOCKEDTIMEPERIOD_CREATE_INIT =
  "API_LOCKEDTIMEPERIOD_CREATE_INIT";
export const API_LOCKEDTIMEPERIOD_CREATE_REQUEST =
  "API_LOCKEDTIMEPERIOD_CREATE_REQUEST";
export const API_LOCKEDTIMEPERIOD_CREATE_SUCCESS =
  "API_LOCKEDTIMEPERIOD_CREATE_SUCCESS";
export const API_LOCKEDTIMEPERIOD_CREATE_ERROR =
  "API_LOCKEDTIMEPERIOD_CREATE_ERROR";

export const API_LOCKEDTIMEPERIOD_DELETE_INIT =
  "API_LOCKEDTIMEPERIOD_DELETE_INIT";
export const API_LOCKEDTIMEPERIOD_DELETE_REQUEST =
  "API_LOCKEDTIMEPERIOD_DELETE_REQUEST";
export const API_LOCKEDTIMEPERIOD_DELETE_SUCCESS =
  "API_LOCKEDTIMEPERIOD_DELETE_SUCCESS";
export const API_LOCKEDTIMEPERIOD_DELETE_ERROR =
  "API_LOCKEDTIMEPERIOD_DELETE_ERROR";

/* Project resource */

export const API_PROJECTRESOURCE_GET_ALL_INIT =
  "API_PROJECTRESOURCE_GET_ALL_INIT";
export const API_PROJECTRESOURCE_GET_ALL_REQUEST =
  "API_PROJECTRESOURCE_GET_ALL_REQUEST";
export const API_PROJECTRESOURCE_GET_ALL_SUCCESS =
  "API_PROJECTRESOURCE_GET_ALL_SUCCESS";
export const API_PROJECTRESOURCE_GET_ALL_ERROR =
  "API_PROJECTRESOURCE_GET_ALL_ERROR";

export const API_PROJECTRESOURCE_CREATE_INIT =
  "API_PROJECTRESOURCE_CREATE_INIT";
export const API_PROJECTRESOURCE_CREATE_REQUEST =
  "API_PROJECTRESOURCE_CREATE_REQUEST";
export const API_PROJECTRESOURCE_CREATE_SUCCESS =
  "API_PROJECTRESOURCE_CREATE_SUCCESS";
export const API_PROJECTRESOURCE_CREATE_ERROR =
  "API_PROJECTRESOURCE_CREATE_ERROR";

export const API_PROJECTRESOURCE_UPDATE_INIT =
  "API_PROJECTRESOURCE_UPDATE_INIT";
export const API_PROJECTRESOURCE_UPDATE_REQUEST =
  "API_PROJECTRESOURCE_UPDATE_REQUEST";
export const API_PROJECTRESOURCE_UPDATE_SUCCESS =
  "API_PROJECTRESOURCE_UPDATE_SUCCESS";
export const API_PROJECTRESOURCE_UPDATE_ERROR =
  "API_PROJECTRESOURCE_UPDATE_ERROR";

export const API_PROJECTRESOURCE_DELETE_INIT =
  "API_PROJECTRESOURCE_DELETE_INIT";
export const API_PROJECTRESOURCE_DELETE_REQUEST =
  "API_PROJECTRESOURCE_DELETE_REQUEST";
export const API_PROJECTRESOURCE_DELETE_SUCCESS =
  "API_PROJECTRESOURCE_DELETE_SUCCESS";
export const API_PROJECTRESOURCE_DELETE_ERROR =
  "API_PROJECTRESOURCE_DELETE_ERROR";

/* PERMISSION */
export const API_PERMISSION_GET_ALL_INIT = "API_PERMISSION_GET_ALL_INIT";
export const API_PERMISSION_GET_ALL_REQUEST = "API_PERMISSION_GET_ALL_REQUEST";
export const API_PERMISSION_GET_ALL_SUCCESS = "API_PERMISSION_GET_ALL_SUCCESS";
export const API_PERMISSION_GET_ALL_ERROR = "API_PERMISSION_GET_ALL_ERROR";

export const API_PERMISSION_UPDATE_INIT = "API_PERMISSION_UPDATE_INIT";
export const API_PERMISSION_UPDATE_REQUEST = "API_PERMISSION_UPDATE_REQUEST";
export const API_PERMISSION_UPDATE_SUCCESS = "API_PERMISSION_UPDATE_SUCCESS";
export const API_PERMISSION_UPDATE_ERROR = "API_PERMISSION_UPDATE_ERROR";

export const API_PERMISSION_CREATE_INIT = "API_PERMISSION_CREATE_INIT";
export const API_PERMISSION_CREATE_REQUEST = "API_PERMISSION_CREATE_REQUEST";
export const API_PERMISSION_CREATE_SUCCESS = "API_PERMISSION_CREATE_SUCCESS";
export const API_PERMISSION_CREATE_ERROR = "API_PERMISSION_CREATE_ERROR";

export const API_PERMISSION_DELETE_INIT = "API_PERMISSION_DELETE_INIT";
export const API_PERMISSION_DELETE_REQUEST = "API_PERMISSION_DELETE_REQUEST";
export const API_PERMISSION_DELETE_SUCCESS = "API_PERMISSION_DELETE_SUCCESS";
export const API_PERMISSION_DELETE_ERROR = "API_PERMISSION_DELETE_ERROR";

/* AbsencePeriod */

export const API_ABSENCEPERIOD_GET_ALL_INIT = "API_ABSENCEPERIOD_GET_ALL_INIT";
export const API_ABSENCEPERIOD_GET_ALL_REQUEST =
  "API_ABSENCEPERIOD_GET_ALL_REQUEST";
export const API_ABSENCEPERIOD_GET_ALL_SUCCESS =
  "API_ABSENCEPERIOD_GET_ALL_SUCCESS";
export const API_ABSENCEPERIOD_GET_ALL_ERROR =
  "API_ABSENCEPERIOD_GET_ALL_ERROR";

export const API_ABSENCEPERIOD_CREATE_INIT = "API_ABSENCEPERIOD_CREATE_INIT";
export const API_ABSENCEPERIOD_CREATE_REQUEST =
  "API_ABSENCEPERIOD_CREATE_REQUEST";
export const API_ABSENCEPERIOD_CREATE_SUCCESS =
  "API_ABSENCEPERIOD_CREATE_SUCCESS";
export const API_ABSENCEPERIOD_CREATE_ERROR = "API_ABSENCEPERIOD_CREATE_ERROR";

export const API_ABSENCEPERIOD_UPDATE_INIT = "API_ABSENCEPERIOD_UPDATE_INIT";
export const API_ABSENCEPERIOD_UPDATE_REQUEST =
  "API_ABSENCEPERIOD_UPDATE_REQUEST";
export const API_ABSENCEPERIOD_UPDATE_SUCCESS =
  "API_ABSENCEPERIOD_UPDATE_SUCCESS";
export const API_ABSENCEPERIOD_UPDATE_ERROR = "API_ABSENCEPERIOD_UPDATE_ERROR";

export const API_ABSENCEPERIOD_DELETE_INIT = "API_ABSENCEPERIOD_DELETE_INIT";
export const API_ABSENCEPERIOD_DELETE_REQUEST =
  "API_ABSENCEPERIOD_DELETE_REQUEST";
export const API_ABSENCEPERIOD_DELETE_SUCCESS =
  "API_ABSENCEPERIOD_DELETE_SUCCESS";
export const API_ABSENCEPERIOD_DELETE_ERROR = "API_ABSENCEPERIOD_DELETE_ERROR";

/* AbsencePeriod Comments */

export const API_ABSENCEPERIODCOMMENT_GET_ALL_INIT =
  "API_ABSENCEPERIODCOMMENT_GET_ALL_INIT";
export const API_ABSENCEPERIODCOMMENT_GET_ALL_REQUEST =
  "API_ABSENCEPERIODCOMMENT_GET_ALL_REQUEST";
export const API_ABSENCEPERIODCOMMENT_GET_ALL_SUCCESS =
  "API_ABSENCEPERIODCOMMENT_GET_ALL_SUCCESS";
export const API_ABSENCEPERIODCOMMENT_GET_ALL_ERROR =
  "API_ABSENCEPERIODCOMMENT_GET_ALL_ERROR";

export const API_ABSENCEPERIODCOMMENT_CREATE_INIT =
  "API_ABSENCEPERIODCOMMENT_CREATE_INIT";
export const API_ABSENCEPERIODCOMMENT_CREATE_REQUEST =
  "API_ABSENCEPERIODCOMMENT_CREATE_REQUEST";
export const API_ABSENCEPERIODCOMMENT_CREATE_SUCCESS =
  "API_ABSENCEPERIODCOMMENT_CREATE_SUCCESS";
export const API_ABSENCEPERIODCOMMENT_CREATE_ERROR =
  "API_ABSENCEPERIODCOMMENT_CREATE_ERROR";

/* AbsencePeriod Status */

export const API_ABSENCEPERIODSTATUS_GET_ALL_INIT =
  "API_ABSENCEPERIODSTATUS_GET_ALL_INIT";
export const API_ABSENCEPERIODSTATUS_GET_ALL_REQUEST =
  "API_ABSENCEPERIODSTATUS_GET_ALL_REQUEST";
export const API_ABSENCEPERIODSTATUS_GET_ALL_SUCCESS =
  "API_ABSENCEPERIODSTATUS_GET_ALL_SUCCESS";
export const API_ABSENCEPERIODSTATUS_GET_ALL_ERROR =
  "API_ABSENCEPERIODTYPE_GET_ALL_ERROR";

/* AbsencePeriodType */

export const API_ABSENCEPERIODTYPE_GET_ALL_INIT =
  "API_ABSENCEPERIODTYPE_GET_ALL_INIT";
export const API_ABSENCEPERIODTYPE_GET_ALL_REQUEST =
  "API_ABSENCEPERIODTYPE_GET_ALL_REQUEST";
export const API_ABSENCEPERIODTYPE_GET_ALL_SUCCESS =
  "API_ABSENCEPERIODTYPE_GET_ALL_SUCCESS";
export const API_ABSENCEPERIODTYPE_GET_ALL_ERROR =
  "API_ABSENCEPERIODSTATUS_GET_ALL_ERROR";

/* AbsenceRegistrationProject */

export const API_ABSENCEREGISTRATIONPROJECT_GET_ALL_INIT =
  "API_ABSENCEREGISTRATIONPROJECT_GET_ALL_INIT";
export const API_ABSENCEREGISTRATIONPROJECT_GET_ALL_REQUEST =
  "API_ABSENCEREGISTRATIONPROJECT_GET_ALL_REQUEST";
export const API_ABSENCEREGISTRATIONPROJECT_GET_ALL_SUCCESS =
  "API_ABSENCEREGISTRATIONPROJECT_GET_ALL_SUCCESS";
export const API_ABSENCEREGISTRATIONPROJECT_GET_ALL_ERROR =
  "API_ABSENCEREGISTRATIONPROJECT_GET_ALL_ERROR";

export const API_ABSENCEREGISTRATIONPROJECT_CREATE_INIT =
  "API_ABSENCEREGISTRATIONPROJECT_CREATE_INIT";
export const API_ABSENCEREGISTRATIONPROJECT_CREATE_REQUEST =
  "API_ABSENCEREGISTRATIONPROJECT_CREATE_REQUEST";
export const API_ABSENCEREGISTRATIONPROJECT_CREATE_SUCCESS =
  "API_ABSENCEREGISTRATIONPROJECT_CREATE_SUCCESS";
export const API_ABSENCEREGISTRATIONPROJECT_CREATE_ERROR =
  "API_ABSENCEREGISTRATIONPROJECT_CREATE_ERROR";

export const API_ABSENCEREGISTRATIONPROJECT_UPDATE_INIT =
  "API_ABSENCEREGISTRATIONPROJECT_UPDATE_INIT";
export const API_ABSENCEREGISTRATIONPROJECT_UPDATE_REQUEST =
  "API_ABSENCEREGISTRATIONPROJECT_UPDATE_REQUEST";
export const API_ABSENCEREGISTRATIONPROJECT_UPDATE_SUCCESS =
  "API_ABSENCEREGISTRATIONPROJECT_UPDATE_SUCCESS";
export const API_ABSENCEREGISTRATIONPROJECT_UPDATE_ERROR =
  "API_ABSENCEREGISTRATIONPROJECT_UPDATE_ERROR";

export const API_ABSENCEREGISTRATIONPROJECT_DELETE_INIT =
  "API_ABSENCEREGISTRATIONPROJECT_DELETE_INIT";
export const API_ABSENCEREGISTRATIONPROJECT_DELETE_REQUEST =
  "API_ABSENCEREGISTRATIONPROJECT_DELETE_REQUEST";
export const API_ABSENCEREGISTRATIONPROJECT_DELETE_SUCCESS =
  "API_ABSENCEREGISTRATIONPROJECT_DELETE_SUCCESS";
export const API_ABSENCEREGISTRATIONPROJECT_DELETE_ERROR =
  "API_ABSENCEREGISTRATIONPROJECT_DELETE_ERROR";

/* Time Registration Status */
export const API_TIMEREGISTRATIONSTATUS_GET_ALL_INIT =
  "API_TIMEREGISTRATIONSTATUS_GET_ALL_INIT";
export const API_TIMEREGISTRATIONSTATUS_GET_ALL_REQUEST =
  "API_TIMEREGISTRATIONSTATUS_GET_ALL_REQUEST";
export const API_TIMEREGISTRATIONSTATUS_GET_ALL_SUCCESS =
  "API_TIMEREGISTRATIONSTATUS_GET_ALL_SUCCESS";
export const API_TIMEREGISTRATIONSTATUS_GET_ALL_ERROR =
  "API_TIMEREGISTRATIONSTATUS_GET_ALL_ERROR";

/* Currency */
export const API_CURRENCY_GET_ALL_INIT = "API_CURRENCY_GET_ALL_INIT";
export const API_CURRENCY_GET_ALL_REQUEST = "API_CURRENCY_GET_ALL_REQUEST";
export const API_CURRENCY_GET_ALL_SUCCESS = "API_CURRENCY_GET_ALL_SUCCESS";
export const API_CURRENCY_GET_ALL_ERROR = "API_CURRENCY_GET_ALL_ERROR";

/* Billability Types */

export const API_BILLABILITYTYPE_GET_ALL_INIT =
  "API_BILLABILITYTYPE_GET_ALL_INIT";
export const API_BILLABILITYTYPE_GET_ALL_REQUEST =
  "API_BILLABILITYTYPE_GET_ALL_REQUEST";
export const API_BILLABILITYTYPE_GET_ALL_SUCCESS =
  "API_BILLABILITYTYPE_GET_ALL_SUCCESS";
export const API_BILLABILITYTYPE_GET_ALL_ERROR =
  "API_BILLABILITYTYPE_GET_ALL_ERROR";

/* Permission Types */

export const API_PERMISSIONTYPE_GET_ALL_INIT =
  "API_PERMISSIONTYPE_GET_ALL_INIT";
export const API_PERMISSIONTYPE_GET_ALL_REQUEST =
  "API_PERMISSIONTYPE_GET_ALL_REQUEST";
export const API_PERMISSIONTYPE_GET_ALL_SUCCESS =
  "API_PERMISSIONTYPE_GET_ALL_SUCCESS";
export const API_PERMISSIONTYPE_GET_ALL_ERROR =
  "API_PERMISSIONTYPE_GET_ALL_ERROR";

/* Vacation Status */

export const API_VACATIONSTATUS_GET_ALL_INIT =
  "API_VACATIONSTATUS_GET_ALL_INIT";
export const API_VACATIONSTATUS_GET_ALL_REQUEST =
  "API_VACATIONSTATUS_GET_ALL_REQUEST";
export const API_VACATIONSTATUS_GET_ALL_SUCCESS =
  "API_VACATIONSTATUS_GET_ALL_SUCCESS";
export const API_VACATIONSTATUS_GET_ALL_ERROR =
  "API_VACATIONSTATUS_GET_ALL_ERROR";

/* Resource Type */
export const API_RESOURCETYPE_GET_ALL_INIT = "API_RESOURCETYPE_GET_ALL_INIT";
export const API_RESOURCETYPE_GET_ALL_REQUEST =
  "API_RESOURCETYPE_GET_ALL_REQUEST";
export const API_RESOURCETYPE_GET_ALL_SUCCESS =
  "API_RESOURCETYPE_GET_ALL_SUCCESS";
export const API_RESOURCETYPE_GET_ALL_ERROR = "API_RESOURCETYPE_GET_ALL_ERROR";

/* Language */
export const API_LANGUAGE_GET_ALL_INIT = "API_LANGUAGE_GET_ALL_INIT";
export const API_LANGUAGE_GET_ALL_REQUEST = "API_LANGUAGE_GET_ALL_REQUEST";
export const API_LANGUAGE_GET_ALL_SUCCESS = "API_LANGUAGE_GET_ALL_SUCCESS";
export const API_LANGUAGE_GET_ALL_ERROR = "API_LANGUAGE_GET_ALL_ERROR";

/* Project Status */
export const API_PROJECTSTATUS_GET_ALL_INIT = "API_PROJECTSTATUS_GET_ALL_INIT";
export const API_PROJECTSTATUS_GET_ALL_REQUEST =
  "API_PROJECTSTATUS_GET_ALL_REQUEST";
export const API_PROJECTSTATUS_GET_ALL_SUCCESS =
  "API_PROJECTSTATUS_GET_ALL_SUCCESS";
export const API_PROJECTSTATUS_GET_ALL_ERROR =
  "API_PROJECTSTATUS_GET_ALL_ERROR";

/* Locale */
export const API_LOCALE_GET_ALL_INIT = "API_LOCALE_GET_ALL_INIT";
export const API_LOCALE_GET_ALL_REQUEST = "API_LOCALE_GET_ALL_REQUEST";
export const API_LOCALE_GET_ALL_SUCCESS = "API_LOCALE_GET_ALL_SUCCESS";
export const API_LOCALE_GET_ALL_ERROR = "API_LOCALE_GET_ALL_ERROR";

/* Work Location */
export const API_WORKLOCATON_GET_ALL_INIT = "API_WORKLOCATON_GET_ALL_INIT";
export const API_WORKLOCATON_GET_ALL_REQUEST =
  "API_WORKLOCATON_GET_ALL_REQUEST";
export const API_WORKLOCATON_GET_ALL_SUCCESS =
  "API_WORKLOCATON_GET_ALL_SUCCESS";
export const API_WORKLOCATON_GET_ALL_ERROR = "API_WORKLOCATON_GET_ALL_ERROR";

/* Work Location Registration */
export const API_WORKLOCATIONREGISTRATION_GET_ALL_INIT =
  "API_WORKLOCATIONREGISTRATION_GET_ALL_INIT";
export const API_WORKLOCATIONREGISTRATION_GET_ALL_REQUEST =
  "API_WORKLOCATIONREGISTRATION_GET_ALL_REQUEST";
export const API_WORKLOCATIONREGISTRATION_GET_ALL_SUCCESS =
  "API_WORKLOCATIONREGISTRATION_GET_ALL_SUCCESS";
export const API_WORKLOCATIONREGISTRATION_GET_ALL_ERROR =
  "API_WORKLOCATIONREGISTRATION_GET_ALL_ERROR";

export const API_WORKLOCATIONREGISTRATION_DELETE_INIT =
  "API_WORKLOCATIONREGISTRATION_DELETE_INIT";
export const API_WORKLOCATIONREGISTRATION_DELETE_REQUEST =
  "API_WORKLOCATIONREGISTRATION_DELETE_REQUEST";
export const API_WORKLOCATIONREGISTRATION_DELETE_SUCCESS =
  "API_WORKLOCATIONREGISTRATION_DELETE_SUCCESS";
export const API_WORKLOCATIONREGISTRATION_DELETE_ERROR =
  "API_WORKLOCATIONREGISTRATION_DELETE_ERROR";

export const API_WORKLOCATIONREGISTRATION_CREATE_INIT =
  "API_WORKLOCATIONREGISTRATION_CREATE_INIT";
export const API_WORKLOCATIONREGISTRATION_CREATE_REQUEST =
  "API_WORKLOCATIONREGISTRATION_CREATE_REQUEST";
export const API_WORKLOCATIONREGISTRATION_CREATE_SUCCESS =
  "API_WORKLOCATIONREGISTRATION_CREATE_SUCCESS";
export const API_WORKLOCATIONREGISTRATION_CREATE_ERROR =
  "API_WORKLOCATIONREGISTRATION_CREATE_ERROR";

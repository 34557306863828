import * as types from "../../constants/ActionTypes";
import { updateDtoList } from "../helpers";
import * as workLocationActionSelectors from "../../selectors/actions/workLocation";

const initialState = {
  allWorkLocations: [],
};

export default function workLocationReducer(state = initialState, action: any) {
  switch (action.type) {
    case types.API_WORKLOCATON_GET_ALL_SUCCESS: {
      return updateDtoList(
        state,
        "allWorkLocations",
        workLocationActionSelectors.getAllWorkLocation(action)
      );
    }

    default:
      return state;
  }
}

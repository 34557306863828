import {put} from 'redux-saga/effects';
import * as types from '../../../constants/ActionTypes';
import {takeLatestAfterBootstrap} from '../../helpers';
import * as pageActions from '../../../actions/pages/absencePeriods';

function* doAbsencePeriodResourceSelected() {
    yield put(pageActions.loadForCurrentFilter());
}

export default function* absencePeriodResourceSelected() {
    yield takeLatestAfterBootstrap(types.APP_PAGE_ABSENCEPERIODS_RESOURCE_SELECTED, doAbsencePeriodResourceSelected);
}

export const ICON_NAME_ACCOUNT = "AnalyticsView";

export const ICON_NAME_CONFIGURATION = "Settings";

export const ICON_NAME_CLIENT = "AccountManagement";

export const ICON_NAME_CONTRACT = "InsertSignatureLine";

export const ICON_NAME_CONTRACTROLE = "PeopleRepeat";

export const ICON_NAME_TIMEREGISTRATION = "Table";

export const ICON_NAME_ABSENCEPERIOD = "OutOfOffice";

export const ICON_NAME_RESOURCE_MANAGEMENT = "TimelineDelivery";

export const ICON_NAME_PROJECT = "ProjectCollection";

export const ICON_NAME_PROJECTRESOURCE = "CompanyDirectory";

export const ICON_NAME_RESOURCE = "WorkforceManagement";

export const ICON_NAME_USER = "People";

export const ICON_NAME_PERMISSION = "Permissions";

export const ICON_NAME_NOVATIME = "Clock";

export const ICON_NAME_LOADING_ERROR = "Warning";

export const ICON_NAME_MORE = "MoreVertical";

export const ICON_NAME_VALIDATION_ERROR = "FileBug";

export const ICON_NAME_UPLOAD = "Upload";

export const ICON_NAME_DOWNLOAD = "Download";

export const ICON_NAME_CHANGE_QUEUED = "BuildQueue";

export const ICON_NAME_INVALID_QUEUED = "Error";

export const ICON_NAME_NEW = "Add";

export const ICON_GO_RIGHT = "DoubleChevronRight12";

export const ICON_GO_LEFT = "DoubleChevronLeft12";

export const ICON_NAME_REMOVE = "Cancel";

export const ICON_NAME_DELETE = "Delete";

export const ICON_NAME_LANGUAGE = "LocaleLanguage";

export const ICON_NAME_BUG = "Bug";

export const ICON_NAME_SWAGGER = "AzureAPIManagement";

export const ICON_NAME_ELMAH = "Error";

export const ICON_NAME_STORYBOOK = "Storyboard";

export const ICON_NAME_EDIT = "Edit";

export const ICON_NAME_TIMEREGEXPORT = "Search";

export const ICON_NAME_EXTENDALERT = "MegaphoneSolid";

export const ICON_NAME_EXCELEXPORT = "ExcelDocument";

export const ICON_NAME_SAVED = "CheckMark";

export const ICON_NAME_SIGNOUT = "SignOut";

export const ICON_NAME_SWITCH_USER = "UserSync";

export const ICON_NAME_WORK_FROM_HOME = "Home";

export const ICON_NAME_WRITE_ACCESS = "Edit";

export const ICON_NAME_READ_ACCESS = "Uneditable";

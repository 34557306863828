import {put, select, takeLatest} from 'redux-saga/effects';
import {v1 as uuidv1} from 'uuid';
import * as types from '../../../constants/ActionTypes';
import {isLoggedIn} from '../../../selectors/reducers/auth';
import {getAllTimeRegistrations} from '../../../actions/api/timeRegistration';
import * as timeRegistrationExportSelectors from '../../../selectors/pages/timeRegistrationExport';

function* doLoadRegistrations() {
    const loggedIn = yield select(isLoggedIn);

    if (loggedIn) {
        const resources = yield select(timeRegistrationExportSelectors.selectedResources);
        const st = yield select(timeRegistrationExportSelectors.startTime);
        const et = yield select(timeRegistrationExportSelectors.endTime);
        const projects = yield select(timeRegistrationExportSelectors.selectedProjects);
     
        const transactionId = uuidv1();

        yield put(getAllTimeRegistrations({ 
            startTime: st, 
            endTime: et, 
            resources: resources,
            transactionId,
            projects 
        }, ['createdByUser']));
    }
}


export default function* loadRegistrations() {
    yield takeLatest(types.APP_PAGE_TIMEREGISTRATIONEXPORT_LOAD_REGISTRATIONS, doLoadRegistrations);
}

import { ResourceObject, ResourceObjects } from '../../lib/models';
import {entityHasId, getEntityId, getRelId} from '../apihelper';
import * as storehelper from '../storehelper';

export const filterByContractRoles = (
    contracts: ResourceObjects,
    contractRoles: ResourceObjects
) => 
    storehelper.filterByRelatedEntity(contracts, contractRoles, 'contract');

export const filterByProjects = (
    contracts: ResourceObjects,
    projects: ResourceObject | ResourceObjects
) =>
    storehelper.filterByRelatedEntity(contracts, projects, 'contract');

export const filterByClients = (
    contracts: ResourceObjects,
    clients: ResourceObject | ResourceObjects
) =>
    storehelper.filterByReferenceToRelatedEntities(contracts, clients, 'client');

export const filterByClientIds = (
    contracts: ResourceObjects, 
    clientIds: string[] | string
): ResourceObjects =>
    storehelper.filterByReferencedIds(contracts, 'client', clientIds);

export const allContracts = (state: any) => state.api.contract.allContracts as ResourceObjects;

export const allContractsByClient = (
    state: any,
    client: ResourceObject
) => allContracts(state).filter(contract => entityHasId(client, getRelId(contract, 'client') as string));

export const contractById = (state: any, contractId: string) => allContracts(state).find(c => entityHasId(c, contractId));

export const contractByProject = (state: any, project: ResourceObject) =>
    allContracts(state).find(c => entityHasId(c, getRelId(project, 'contract') as string));

export const contractsByProjects = (state: any, projects: ResourceObjects): ResourceObjects => {
    const contractIdsFromProjects = projects.map(p => getRelId(p, 'contract'));
    return allContracts(state).filter(c => contractIdsFromProjects.includes(getEntityId(c) as string))
}

export const contractByContractRole = (state: any, contractRole: ResourceObject) =>
    contractById(state, getRelId(contractRole, 'contract') as string);


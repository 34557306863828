import {all, fork} from 'redux-saga/effects';

import pageOpened from './pageOpened';
import updateFilter from './updateFilter';
import loadRegistrations from './loadRegistrations';

export default function* pageTimeRegistrationExportRootSaga() {
    yield all([
        fork(pageOpened),
        fork(updateFilter),
        fork(loadRegistrations),
    ]);
}
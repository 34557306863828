import * as types from '../../constants/ActionTypes';
import {removeItemFromList, updateDtoList} from '../helpers';
import {
  getAllContractList,
  getDeleteContractId,
  getIncludedContracts,
  getUpsertContract
} from '../../selectors/actions/contract';

const initialState = {
    storeRevision: 0,
    allContracts: [],
};

export default function contractReducer(state = initialState, action) {
    
    switch (action.type) {
        case types.API_CONTRACT_GET_ALL_SUCCESS: {
            return updateDtoList(state, 'allContracts', getAllContractList(action));
        }

        case types.API_PROJECT_GET_ALL_SUCCESS:
        case types.API_CLIENT_GET_ALL_SUCCESS: {
            return updateDtoList(state, 'allContracts', getIncludedContracts(action));
        }

        case types.API_CONTRACT_CREATE_SUCCESS:
        case types.API_CONTRACT_UPDATE_SUCCESS: {
            return updateDtoList(state, 'allContracts', [getUpsertContract(action)]);
        }

        case types.API_CONTRACT_DELETE_SUCCESS: {
            return removeItemFromList(state, 'allContracts', [getDeleteContractId(action)]);
        }

        default:
            return state;
    }
}


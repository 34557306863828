export {};

declare global {
    interface Array<T> {
        unique(): Array<T>;
    }
}

Array.prototype.unique = function() {
    return this.filter(function (value, index, self) { 
        return self.indexOf(value) === index;
    });
}

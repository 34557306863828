import React, { Component } from "react";
import PropTypes from "prop-types";
import { TextField } from "@fluentui/react/lib/TextField";

export default class HourField extends Component {
  static propTypes = {
    onChange: PropTypes.func,
    value: PropTypes.number,
    editable: PropTypes.bool,
    disabled: PropTypes.bool,
    additionalData: PropTypes.object,
    onFocus: PropTypes.func,
    suffix: PropTypes.string,
  };

  constructor(props) {
    super(props);
    this.state = {
      isUnderEdit: false,
    };

    this.inputRef = null;
    this.decimalSeparator = (1.1).toLocaleString().substring(1, 2);

    this._onFocus = this._onFocus.bind(this);
    this._onBlur = this._onBlur.bind(this);
    this._onKeyUp = this._onKeyUp.bind(this);
    this._onKeyDown = this._onKeyDown.bind(this);
    this._setInputRef = this._setInputRef.bind(this);
    this._setInputTextSelectHour = this._setInputTextSelectHour.bind(this);
    this._setInputTextSelectMinute = this._setInputTextSelectMinute.bind(this);
    this._setInputTextHour = this._setInputTextHour.bind(this);
    this._exitEditModeReject = this._exitEditModeReject.bind(this);
    this._onChange = this._onChange.bind(this);
  }

  _setInputRef(elem) {
    this.inputRef =
      elem && elem._textElement && elem._textElement.current
        ? elem._textElement.current
        : elem;
  }

  _setInputTextSelectHour() {
    let val = this.inputRef.value;
    let idx = val.indexOf(this.decimalSeparator);

    if (idx > 0) {
      this._setInputTextSelection(0, idx);
    }
  }

  _setInputTextHour() {
    let val = this.inputRef.value;
    let idx = val.indexOf(this.decimalSeparator);

    if (idx > 0) {
      this._setInputTextSelection(idx, idx);
    }
  }

  _setInputTextSelectMinute() {
    let val = this.inputRef.value;
    let idx = val.indexOf(this.decimalSeparator);

    if (idx > 0) {
      this._setInputTextSelection(idx + 1, val.length);
    }
  }

  _setInputTextSelection(startPos, endPos) {
    let input = this.inputRef;
    if (typeof input.selectionStart !== "undefined") {
      input.selectionStart = startPos;
      input.selectionEnd = endPos;
    } else if (document.selection && document.selection.createRange) {
      // IE branch
      input.select();
      let range = document.selection.createRange();
      range.collapse(true);
      range.moveEnd("character", endPos);
      range.moveStart("character", startPos);
      range.select();
    }
  }

  _setTimeFromValue(value) {
    value = isNaN(value) ? this.props.value : value;
    let rounded = Math.round(value / 0.25) * 0.25; // round to quarter
    this.setState({
      hour: Math.floor(rounded),
      minutes: Math.floor((rounded % 1) * 100),
    });
  }

  _getTimeFromField() {
    let value = this.inputRef.value;
    return this._getTimeFromValue(value);
  }

  _getTimeFromValue(value) {
    let res = { hour: null, minutes: null, val: 0 };
    if (/^[\d\s,.:]*$/.test(value)) {
      let hm = value.split(/[,.:]/);
      hm = [hm[0], ...hm.slice(1).filter((s) => s.trim().length > 0)];
      let hours = parseInt(hm[0] || 0);
      let minutes = parseInt((hm[1] || "").substring(0, 2)) || 0;

      minutes = minutes < 10 ? minutes * 10 : minutes;
      let fhours = Math.max(Math.min(hours, 24), 0);
      let fminutes = Math.round(Math.max(Math.min(minutes, 75), 0) / 25) * 25;
      if (fhours === 24) {
        fhours = 23;
        fminutes = 75;
      }
      res = { hour: fhours, minutes: fminutes, val: fhours + fminutes / 100 };
    }
    return res;
  }

  _exitEditModeAccept() {
    let t = this._getTimeFromField();
    if (this.state.isUnderEdit && t && t.val !== this.props.value) {
      if (this.props.onChange) {
        this.props.onChange(t.val, this.props.additionalData);
      }
    }

    this.setState({
      isUnderEdit: false,
      hour: null,
      minute: null,
    });
  }

  _exitEditModeReject() {
    if (this.state.isUnderEdit) {
      this.setState({
        isUnderEdit: false,
        hour: null,
        minute: null,
      });
      setTimeout(() => this.inputRef.blur(), 0);
    }
  }

  _onFocus(e) {
    if (this.props.editable && !this.state.isUnderEdit) {
      this._setTimeFromValue();
      this.setState({
        isUnderEdit: true,
      });

      setTimeout(this._setInputTextSelectHour, 0);
      if (this.props.onFocus) {
        this.props.onFocus(e, this.props.additionalData);
      }
    }
  }

  _onBlur(e) {
    if (this.props.editable) {
      this._exitEditModeAccept();
    }
  }

  _isAllowedKeyCode(keyCode) {
    let isNumber =
      /*numbers*/ (48 <= keyCode && keyCode <= 57) ||
      /*numpad*/ (96 <= keyCode && keyCode <= 105);
    return (
      isNumber ||
      [
        /*left, right*/ 37, 39, /*comma, point*/ 110, 188, 190,
        /*del, backspace*/ 46, 8, /*ctrl, shift*/ 16, 17, /*home, end*/ 35, 36,
        /*tab*/ 9,
      ].includes(keyCode)
    );
  }

  _onKeyDown(e, v) {
    if (this.props.disabled) {
      return e.preventDefault();
    }
    let keyCode = e.keyCode || e.which;
    if (keyCode === 13) {
      this._onKeyEnter(e);
    } else if (keyCode === 27) {
      this._onKeyEsc(e);
    } else if (keyCode === 38) {
      this._onKeyUpArrow(e);
    } else if (keyCode === 40) {
      this._onKeyDownArrow(e);
    } else if (!this._isAllowedKeyCode(keyCode)) {
      e.preventDefault();
    }
  }

  _onKeyUp(e, v) {
    let keyCode = e.keyCode || e.which;
    let value = e.target.value;
    let cursorPos = e.target.selectionStart;
    let separatorIdx = value.indexOf(this.decimalSeparator);
    let inputHour =
      separatorIdx > 0 ? parseInt(value.split(this.decimalSeparator)[0]) : 0;
    if (this._isAllowedKeyCode(keyCode)) {
      let arrowLeft = keyCode === 37;
      let arrowRight = keyCode === 39;
      let arrowUp = keyCode === 38;
      let arrowDown = keyCode === 40;
      let decimalSeperator =
        keyCode === 110 || keyCode === 188 || keyCode === 190;
      let { hour, minutes, val } = this._getTimeFromField();

      if (arrowDown || arrowUp) {
        // leave, is handled in _onKeyDown
      } else if (decimalSeperator) {
        this._setTimeFromValue(val);
        setTimeout(this._setInputTextSelectMinute, 0);
      } else if (
        arrowLeft &&
        (cursorPos === separatorIdx + 1 || cursorPos === separatorIdx) &&
        separatorIdx > 0
      ) {
        setTimeout(this._setInputTextSelectHour, 0);
      } else if (
        arrowRight &&
        (cursorPos === separatorIdx + 1 || cursorPos === separatorIdx) &&
        separatorIdx > 0
      ) {
        setTimeout(this._setInputTextSelectMinute, 0);
      }

      // cursor in front of comma and hours doesn't make sense to write more of, jump to minutes
      else if (
        cursorPos === separatorIdx &&
        (separatorIdx >= 2 || inputHour > 1)
      ) {
        this._setTimeFromValue(val);
        setTimeout(this._setInputTextSelectMinute, 0);
      } else if (hour !== this.state.hour || minutes !== this.state.minutes) {
        this._setTimeFromValue(val);
        if (separatorIdx === -1) {
          if (hour > 1) {
            setTimeout(this._setInputTextSelectMinute, 0);
          } else {
            setTimeout(this._setInputTextHour, 0);
          }
        }
      }
    }
  }

  _incrementHour() {
    let t = this._getTimeFromField();
    let newHour = Math.max(Math.min(t.hour + 1, 24), 0);
    this.setState({
      hour: newHour,
      minutes: newHour === 24 ? 0 : t.minutes,
    });
    setTimeout(this._setInputTextSelectHour, 0);
  }

  _decrementHour() {
    let t = this._getTimeFromField();
    this.setState({
      hour: Math.max(Math.min(t.hour - 1, 24), 0),
      minutes: t.minutes,
    });
    setTimeout(this._setInputTextSelectHour, 0);
  }

  _incrementMinute() {
    let t = this._getTimeFromField();
    this.setState({
      minutes: t.hour === 24 ? 0 : Math.max(Math.min(t.minutes + 25, 75), 0),
      hour: t.hour,
    });
    setTimeout(this._setInputTextSelectMinute, 0);
  }

  _decrementMinute() {
    let t = this._getTimeFromField();
    this.setState({
      minutes: Math.max(Math.min(t.minutes - 25, 75), 0),
      hour: t.hour,
    });
    setTimeout(this._setInputTextSelectMinute, 0);
  }

  _onKeyUpArrow(e) {
    let value = e.target.value;
    let cursorPos = e.target.selectionStart;
    let separatorIdx = value.indexOf(this.decimalSeparator);
    if (separatorIdx > 0) {
      let editHour = cursorPos <= separatorIdx;
      if (editHour) {
        this._incrementHour();
      } else {
        this._incrementMinute();
      }
    }
  }

  _onKeyDownArrow(e) {
    e.preventDefault();
    let value = e.target.value;
    let cursorPos = e.target.selectionStart;
    let separatorIdx = value.indexOf(this.decimalSeparator);
    if (separatorIdx > 0) {
      let editHour = cursorPos <= separatorIdx;
      if (editHour) {
        this._decrementHour();
      } else {
        this._decrementMinute();
      }
    }
  }

  _onKeyEnter(e) {
    e.target.blur();
  }

  _onKeyEsc(e) {
    this._exitEditModeReject();
  }

  _getTmpValue(state = this.state) {
    return state.hour + state.minutes / 100;
  }

  _getFormattedValue(val, strict) {
    return strict
      ? val.toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })
      : val > 0
      ? val % 1 === 0
        ? val + ""
        : val.toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })
      : val + "";
  }

  _onChange(e, val) {
    this.setState(this._getTimeFromValue(val));
  }

  shouldComponentUpdate(nextProps, nextState) {
    let curVal = this.state.isUnderEdit
      ? this._getTmpValue()
      : this.props.value || 0;
    let nextVal = nextState.isUnderEdit
      ? this._getTmpValue(nextState)
      : nextProps.value || 0;
    let shouldUpdate =
      this.state.isUnderEdit != nextState.isUnderEdit ||
      this.props.editable != nextProps.editable ||
      this.props.disabled != nextProps.disabled ||
      this.props.className != nextProps.className ||
      this.props.suffix != nextProps.suffix ||
      curVal != nextVal;
    return shouldUpdate;
  }

  render() {
    let { isUnderEdit, hour, minutes } = this.state;
    let { value, editable, disabled, className, suffix } = this.props;

    let val = isUnderEdit ? this._getTmpValue() : value || 0;
    let formattedVal = this._getFormattedValue(val, isUnderEdit);

    return (
      <div className={(className || "") + " novatime-hourfield"}>
        <TextField
          className="cell-text-field"
          type="tel"
          readOnly={!this.props.editable}
          onFocus={this._onFocus}
          disabled={disabled ? true : undefined} // existance of property does the trick. undefined means non existing
          onBlur={this._onBlur}
          onKeyUp={this._onKeyUp}
          onKeyDown={this._onKeyDown}
          onChange={this._onChange}
          suffix={suffix}
          borderless={true}
          size="4"
          componentRef={this._setInputRef}
          value={formattedVal}
        />
      </div>
    );
  }
}


export const HOME_RELATIVE = '/';

export const TIMEREGISTRATION_RELATIVE = '/timereg';

export const TIMEREGEXPORT_RELATIVE = '/timeregexport';

export const ABSENCEPERIODS_RELATIVE = '/absence';

export const RESOURCEMANAGEMENT_RELATIVE = '/resourcemanagement';

export const CONTRACTSANDCLIENTS_RELATIVE = '/clientsandcontracts';

export const PROJECTSANDROLES_RELATIVE = '/projectsandroles';

export const USERSANDRESOURCES_RELATIVE = '/usersandresources';

export const LOGIN_RELATIVE = '/login';

export const LOGOUT_RELATIVE = '/logout';

export const SWITCH_USER = '/switchuser'

export const SIGN_IN_RELATIVE = '/auth/sign-in'

export const SIGN_OUT_RELATIVE = '/auth/sign-out'

import * as types from '../../constants/ActionTypes';
import {removeItemFromList, updateDtoList} from '../helpers';
import {
  getAllPermissionList,
  getDeletePermissionId,
  getIncludedPermissions,
  getUpsertPermission,
} from '../../selectors/actions/permission';

const initialState = {
    storeRevision: 0,
    allPermissions: []
};

export default function permissionReducer(state = initialState, action) {

    switch (action.type) {
        case types.API_USER_GET_ME_SUCCESS: {
            return updateDtoList(state, 'allPermissions', getIncludedPermissions(action));
        }

        case types.API_PERMISSION_GET_ALL_SUCCESS: {
            return updateDtoList(state, 'allPermissions', getAllPermissionList(action));
        }

        case types.API_PERMISSION_CREATE_SUCCESS: {
            return updateDtoList(state, 'allPermissions', [getUpsertPermission(action)]);
        }

        case types.API_PERMISSION_DELETE_SUCCESS: {
            return removeItemFromList(state, 'allPermissions', [getDeletePermissionId(action)]);
        }

        default:
            return state;
    }
}
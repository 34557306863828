import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from "react-router-dom";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

class Footer extends Component {
    render() {
        return (
            <div className="footer" ></div>
        );
    }
}

function mapState(state) {
    return {
    };
}

function mapDispatch(dispatch) {
    const actions = { };
    return { actions: bindActionCreators(actions, dispatch) };
}

export default withRouter(connect(mapState, mapDispatch)(Footer));

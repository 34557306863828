import * as types from '../../constants/ActionTypes';

export function createLockedTimePeriod(lockedTimePeriod) {
    return {
        type: types.API_LOCKEDTIMEPERIOD_CREATE_INIT,
        payload: {
            model: lockedTimePeriod
        }
    };
}

export function deleteLockedTimePeriod(lockedTimePeriod) {
    return {
        type: types.API_LOCKEDTIMEPERIOD_DELETE_INIT,
        payload: {model: lockedTimePeriod}
    };
}

export function getAllLockedTimePeriods({ resource, startDate, endDate } = {}, includes) {
    return {
        type: types.API_LOCKEDTIMEPERIOD_GET_ALL_INIT,
        payload: {
            parameters: {
                resource,
                startDate: startDate instanceof Date ? startDate.toISOString() : startDate,
                endDate: endDate instanceof Date ? endDate.toISOString() : endDate
            },
            includes
        }
    };
}

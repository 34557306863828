import * as types from '../constants/ActionTypes';

const baseUrl = process.env.HOST_URL || window.location.origin + '/';
const initialState = {
    urls: {
        baseApiUrl: baseUrl + 'api/',
        baseUrl: baseUrl,
        staticFilesUrl: baseUrl + 'Static/',
        imageFilesUrl: baseUrl + 'Static/images/',
    },
    isReady: false,
    environment: process.env.ENV
};

export default function root(state = initialState, action: any) {
    switch(action.type) {
        case types.APP_BOOTSTRAP_INIT: {
            return Object.assign({}, state, {
                urls: Object.assign({}, state.urls, {
                    swagger: state.urls.baseUrl + 'swagger',
                    elmah: state.urls.baseUrl + 'elmah.axd'
                })
            });
        }

        case types.APP_BOOTSTRAP_COMPLETED: {
            return Object.assign({}, state, {
                isReady: true
            });
        }

        default:
            return state;
    }
}

import { createSelector } from "reselect";
import {entityHasId, relRefersToEntity} from '../apihelper';
import * as accountSelectors from '../reducers/account';
import { ResourceObject, ResourceObjects } from "../../lib/models";
import * as storehelper from '../../selectors/storehelper';

export const currentUser = (state: any) => state.api.user.me as ResourceObject | null;

export const allUsers = (state: any) => state.api.user.allUsers as ResourceObjects;

export const filterByAccount = (users: ResourceObjects, account?: ResourceObject) =>
    storehelper.filterByReferenceToRelatedEntities(users, account, 'account');

export const allUsersByAccount = (state: any, account: ResourceObject) => filterByAccount(allUsers(state), account);

export const userById = (state: any, userId: string) => allUsers(state).find(u => entityHasId(u, userId));

export const userByResource = (state: any, resource: ResourceObject) =>
    allUsers(state).find(u => relRefersToEntity(resource, 'user', u));

export const allUsersInCurrentUserAccount = createSelector(
    [allUsers, accountSelectors.currentUserAccount],
    (users, account) => 
        account !== null ?
        filterByAccount(users, account) :
        null
)

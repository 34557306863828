import { Promise } from 'es6-promise';

export default class BaseConnector {

    constructor(protected baseApiUrl: string){
        if(!baseApiUrl){
            throw new Error('Cannot instantiate constructor without base api url');
        }
    }

    // this method used to be decorated with the addition of a bearer token, but after switching to cookie based
    // auth, this is no longer necessary.
    async authFetch(url: string, options: RequestInit = {}): Promise<Response>{
        options.headers = options.headers || new Headers({
            'Content-Type': 'application/json; charset=utf-8',
        });
        options.mode = 'cors';
        return await fetch(url, options);
    }

    authPost(url: string, object: object){
        return this.authFetch(url,  {
            method: 'POST',
            body: JSON.stringify(object)
        });
    }

    authPut(url: string, object: object){
        return this.authFetch(url,  {
            method: 'PUT',
            body: JSON.stringify(object)
        });
    }

    authPatch(url: string, object: object) {
        return this.authFetch(url, {
            body: JSON.stringify(object),
            method: 'PATCH',
        });
    }

    authDelete(url: string){
        return this.authFetch(url,  { method: 'DELETE' });
    }
}


import {put, takeLatest} from 'redux-saga/effects';
import * as types from '../../../constants/ActionTypes';
import {createLockedTimePeriod} from "../../../actions/api/lockedTimePeriod";

function* doLockTimePeriod(action) {
    yield put(createLockedTimePeriod(action.payload.lockedTimePeriod));
}

export default function* lockTimePeriod() {
    yield takeLatest(types.APP_PAGE_TIMEREGISTRATION_LOCK_PERIOD, doLockTimePeriod);
}

import React, { Component } from "react";
import PropTypes from "prop-types";
import * as InternalPropTypes from "../constants/PropTypes";
import { v1 as uuidv1 } from "uuid";
import DetailsListBasic from "./DetailsListBasic";
import moment from "moment";
import {
  getEntityId,
  isEntity,
  relRefersToEntity,
  getAttr,
} from "../selectors/apihelper";
import HourSet from "../lib/HourSet";
import * as EntityTypes from "../constants/EntityTypes";
import { Toggle } from "@fluentui/react/lib/Toggle";
import { TooltipHost } from "@fluentui/react/lib/Tooltip";

let TimeRegistrationDetailListColumns = [
  {
    key: "tr-validation",
    name: "Status",
    fieldName: "validation",
    minWidth: 50,
    maxWidth: 50,
    isResizable: true,
  },
  {
    key: "tr-project",
    name: "Customer - Project",
    fieldName: "project",
    minWidth: 100,
    maxWidth: 200,
    isResizable: true,
    isSortedAscending: false,
  },
  {
    key: "tr-resource",
    name: "Person",
    fieldName: "resource",
    minWidth: 100,
    maxWidth: 200,
    isResizable: true,
    isSortedAscending: false,
  },
  {
    key: "pr-date",
    name: "Date",
    fieldName: "date",
    minWidth: 100,
    maxWidth: 200,
    isResizable: true,
    isSortedAscending: true,
  },
  {
    key: "tr-duration",
    name: "Duration",
    fieldName: "duration",
    minWidth: 100,
    maxWidth: 200,
    isResizable: true,
    isSortedAscending: false,
  },
  {
    key: "tr-description",
    name: "Description",
    fieldName: "description",
    minWidth: 200,
    maxWidth: 300,
    isResizable: true,
    isSortedAscending: false,
  },
];

const mapRegistration = (
  tr,
  contracts,
  projects,
  clients,
  resources,
  hourSet
) => {
  let project = projects.find((p) => relRefersToEntity(tr, "project", p));
  let resource = resources.find((r) => relRefersToEntity(tr, "resource", r));

  let contract =
    isEntity(project) &&
    contracts.find((co) => relRefersToEntity(project, "contract", co));
  let client =
    isEntity(contract) &&
    clients.find((c) => relRefersToEntity(contract, "client", c));

  let hours =
    moment(getAttr(tr, "endTime")).diff(
      moment(getAttr(tr, "startTime")),
      "minute"
    ) / 60;
  let projectString =
    (client ? getAttr(client, "name") + " - " : "") +
    (project ? getAttr(project, "name") : "");

  let validation = hourSet && hourSet.getTimeRegFailedValidations(tr);

  let result = {
    project: projectString,
    resource: resource,
    description: getAttr(tr, "description"),
    startTime: moment(getAttr(tr, "startTime")),
    endTime: moment(getAttr(tr, "endTime")),
    duration: hours,
    date: moment(getAttr(tr, "startTime")).format("DD-MM-YYYY"),
    dateRaw: new Date(getAttr(tr, "startTime")),
    data: tr,
    personName: getAttr(resource, "name"),
    clientName: getAttr(client, "name"),
    projectName: getAttr(project, "name"),
    validation,
    isPendingUpload: hourSet && hourSet.stateMerge.isPendingUpload(tr),
    isUploading: hourSet && hourSet.stateMerge.isUploading(tr),
  };
  return result;
};

const getAvailableOptions = (items) => {
  let availableOptions = new Set();
  if (items) {
    items.forEach((item) => {
      if (items[0].projectName !== item.projectName) {
        availableOptions.add(EntityTypes.PROJECT);
      }
      if (items[0].clientName !== item.clientName) {
        availableOptions.add(EntityTypes.CLIENT);
      }
      if (items[0].personName !== item.personName) {
        availableOptions.add(EntityTypes.RESOURCE);
      }
      if (items[0].date !== item.date) {
        availableOptions.add("date");
      }
      if (availableOptions.size === 4) {
        return;
      }
    });
  }
  return availableOptions;
};

const _sortArray = (array, key, ascending) => {
  if (
    ![
      "resource",
      "client",
      "project",
      "date",
      "duration",
      "description",
    ].includes(key)
  ) {
    console.error("Cannot sort by key: " + key);
    return array;
  }
  let multiplier = ascending ? 1 : -1;
  let newArray = array.sort((a, b) => {
    let result = 0;
    let valueA = a[key];
    let valueB = b[key];
    if (key === EntityTypes.RESOURCE.toLowerCase()) {
      valueA = a.personName;
      valueB = b.personName;
    }
    if (key === "date") {
      valueA = a.dateRaw;
      valueB = b.dateRaw;
    }
    result = valueA < valueB ? -1 : 1;
    return result * multiplier;
  });
  return newArray;
};

const _createGroups = (array, keys, expandedGroups) => {
  if (keys.length === 0) {
    return { info: null, data: array };
  }
  keys.map((key) => {
    if (
      ![
        EntityTypes.RESOURCE,
        EntityTypes.CLIENT,
        EntityTypes.PROJECT,
        "date",
        "duration",
        "description",
      ].includes(key)
    ) {
      throw new Error("Cannot group by key: " + key);
    }
  });
  let sortedArray = array.sort((a, b) => {
    let result = 0;
    keys.some((key) => {
      let valueA;
      let valueB;
      if (key === EntityTypes.RESOURCE) {
        valueA = a.personName;
        valueB = b.personName;
      }
      if (key === EntityTypes.PROJECT) {
        valueA = a.projectName;
        valueB = b.projectName;
      }
      if (key === EntityTypes.CLIENT) {
        valueA = a.clientName;
        valueB = b.clientName;
      }
      if (key === "date") {
        valueA = a.dateRaw;
        valueB = b.dateRaw;
      }
      if (valueA < valueB) {
        result = -1;
        return true;
      }
      if (valueA > valueB) {
        result = 1;
        return true;
      }
    });
    return result;
  });

  const resolvePath = (object, path, defaultValue) =>
    path.split(".").reduce((o, p) => (o ? o[p] : defaultValue), object);

  let groups = {
    info: [],
    data: sortedArray,
  };
  sortedArray.forEach((entry, index) => {
    let currentGroup = "info";
    let listOfGroupsOnThisLevel;
    let groupKey;
    let headers = keys.map((key) => {
      if (key === EntityTypes.RESOURCE) {
        groupKey = entry.personName;
      }
      if (key === EntityTypes.CLIENT || key === EntityTypes.PROJECT) {
        groupKey =
          key === EntityTypes.CLIENT ? entry.clientName : entry.projectName;
      }
      if (key === "date") {
        groupKey = entry[key];
      }
      return groupKey;
    });

    let groupPath;
    headers.forEach((header, idx) => {
      groupPath = idx === 0 ? header : groupPath + "." + header;
      listOfGroupsOnThisLevel = resolvePath(groups, currentGroup, []);
      let idxForTargetGroup = listOfGroupsOnThisLevel.findIndex(
        (group) => group.key === header
      );
      if (idxForTargetGroup === -1) {
        let isCollapsed = expandedGroups
          ? !expandedGroups.includes(groupPath)
          : true;
        listOfGroupsOnThisLevel.push({
          key: header,
          name: `${header} - hours: (${entry.duration}), entries:`,
          startIndex: index,
          count: 1,
          level: idx,
          children: [],
          isCollapsed: isCollapsed,
          durationAggregation: entry.duration,
          groupPath: groupPath,
        });
        idxForTargetGroup = listOfGroupsOnThisLevel.length - 1;
      } else {
        listOfGroupsOnThisLevel[idxForTargetGroup].count += 1;
        listOfGroupsOnThisLevel[idxForTargetGroup].durationAggregation +=
          entry.duration;
        listOfGroupsOnThisLevel[
          idxForTargetGroup
        ].name = `${header} - hours: (${listOfGroupsOnThisLevel[idxForTargetGroup].durationAggregation}), entries:`;
      }
      currentGroup = currentGroup + "." + idxForTargetGroup + ".children";
    });
  });
  return groups;
};

const isSameItem = (item1, item2) => {
  return (
    item1 === item2 ||
    (item1 != null &&
      item2 != null &&
      item1.project === item2.project &&
      item1.resource === item2.resource &&
      item1.description === item2.description &&
      item1.startTime.isSame(item2.startTime) &&
      item1.endTime.isSame(item2.endTime) &&
      item1.duration === item2.duration &&
      item1.date === item2.date &&
      item1.validation === item2.validation &&
      item1.isPendingUpload === item2.isPendingUpload &&
      item1.isUploading === item2.isUploading)
  );
};

export default class TimeRegistrationList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      items: null,
      viewDesc: null,
      groups: null,
      availableOptions: null,
    };
    this._onSelect = this._onSelect.bind(this);
    this._isItemSelected = this._isItemSelected.bind(this);
    this._onColumnClick = this._onColumnClick.bind(this);
    this._toggleGroupByClient = this._toggleGroupByClient.bind(this);
    this._toggleGroupByProject = this._toggleGroupByProject.bind(this);
    this._toggleGroupByResource = this._toggleGroupByResource.bind(this);
    this._toggleGroupByDate = this._toggleGroupByDate.bind(this);
    this._onToggleCollapse = this._onToggleCollapse.bind(this);
  }

  static propTypes = {
    columnNames: PropTypes.arrayOf(PropTypes.string),
    projects: InternalPropTypes.projectEntities,
    clients: InternalPropTypes.clientEntities,
    contracts: InternalPropTypes.contractEntities,
    resources: InternalPropTypes.resourceTypeEntities,
    timeRegistrations: InternalPropTypes.timeRegistrationEntities,
    onRenderColumn: PropTypes.func,
    onRenderItem: PropTypes.func,
    selectedTimeRegistration: InternalPropTypes.timeRegistrationEntity,
    showIfLessThanTwoRegistrations: PropTypes.bool,
    showIfNoTimeRegistrations: PropTypes.bool,
    onSelect: PropTypes.func,
    hourSet: PropTypes.instanceOf(HourSet),
    viewDesc: PropTypes.shape({
      sortedBy: PropTypes.oneOf([
        "date",
        "resource",
        "project",
        "duration",
        "description",
      ]),
      groupBy: PropTypes.arrayOf(PropTypes.string),
    }),
    onViewDescChange: PropTypes.func,
    canBeGrouped: PropTypes.bool,
    expandedGroups: PropTypes.arrayOf(PropTypes.string),
  };

  static defaultProps = {
    columnNames: ["project", "resource", "date", "duration"],
    showIfLessThanTwoRegistrations: false,
    showIfNoTimeRegistrations: false,
    viewDesc: null,
    onViewDescChange: null,
    canBeGrouped: true,
    expandedGroups: [],
  };

  _onSelect(selection) {
    this.props.onSelect && this.props.onSelect(selection);
  }

  _onColumnClick = (e) => {
    let columnToSortName = e.target.firstElementChild
      ? e.target.firstElementChild.innerHTML
      : e.target.innerHTML;
    let column = TimeRegistrationDetailListColumns.find(
      (entry) => entry.name === columnToSortName
    );
    column.isSortedAscending = !column.isSortedAscending;
    this._onViewDescChange({ sortedBy: column.fieldName });
  };

  _onViewDescChange(change) {
    const { viewDesc } = this.state;
    const viewDescChanges = {};

    if (change.sortedBy) {
      viewDescChanges.sortedBy = change.sortedBy;
      viewDescChanges.ascending = !viewDesc.ascending;
    }

    if (change.groupByOption && viewDesc.groupBy) {
      let indexForOption = viewDesc.groupBy.indexOf(change.groupByOption);
      if (indexForOption === -1) {
        viewDescChanges.groupBy = viewDesc.groupBy.concat(change.groupByOption);
      } else {
        viewDescChanges.groupBy = viewDesc.groupBy.toSpliced(indexForOption, 1);
      }
    } else if (change.groupByOption) {
      viewDescChanges.groupBy = [change.groupByOption];
    }

    if (change.groupPath) {
      if (!viewDesc.expandedGroups) {
        viewDescChanges.expandedGroups = [];
      }
      if (change.wasCollapsed) {
        viewDescChanges.expandedGroups = (viewDesc.expandedGroups || []).concat(
          change.groupPath
        );
      } else {
        viewDescChanges.expandedGroups = (viewDesc.expandedGroups || []).filter(
          (str) => str !== change.groupPath
        );
      }
    }

    const newViewDesc = Object.assign({}, viewDesc, viewDescChanges);
    this.props.onViewDescChange
      ? this.props.onViewDescChange(newViewDesc)
      : this.setState({ viewDesc: newViewDesc });
  }

  _genSetKey() {
    let { timeRegistrations } = this.props;
    return timeRegistrations
      .map((tr) => getEntityId(tr) || uuidv1())
      .sort()
      .join(",");
  }

  _isItemSelected(item) {
    let { selectedTimeRegistration } = this.props;
    return selectedTimeRegistration && selectedTimeRegistration === item.data;
  }

  _toggleGroupByClient() {
    this._onViewDescChange({ groupByOption: EntityTypes.CLIENT });
  }

  _toggleGroupByProject() {
    this._onViewDescChange({ groupByOption: EntityTypes.PROJECT });
  }

  _toggleGroupByResource() {
    this._onViewDescChange({ groupByOption: EntityTypes.RESOURCE });
  }

  _toggleGroupByDate() {
    this._onViewDescChange({ groupByOption: "date" });
  }

  _onToggleCollapse(groupInfo) {
    this._onViewDescChange({
      wasCollapsed: groupInfo.isCollapsed,
      groupPath: groupInfo.groupPath,
    });
  }

  static getDerivedStateFromProps(props, state) {
    let {
      timeRegistrations,
      contracts,
      projects,
      clients,
      resources,
      hourSet,
      viewDesc,
      onViewDescChange,
    } = props;
    let items = timeRegistrations.map((tr) =>
      mapRegistration(tr, contracts, projects, clients, resources, hourSet)
    );

    let result;
    viewDesc = onViewDescChange && viewDesc ? viewDesc : state.viewDesc;
    if (!viewDesc) {
      viewDesc = {};
    }

    let availableOptions = getAvailableOptions(items);
    let groupBy = viewDesc.groupBy;

    if (groupBy) {
      groupBy = groupBy.filter((option) => availableOptions.has(option));
    }

    items = viewDesc.sortedBy
      ? _sortArray(items, viewDesc.sortedBy, viewDesc.ascending)
      : items;
    let groups = groupBy
      ? _createGroups(items, groupBy, viewDesc.expandedGroups)
      : null;
    result = {
      items: items,
      viewDesc: viewDesc,
      groups: groups,
      availableOptions: availableOptions,
    };
    return result;
  }

  shouldComponentUpdate(nextProps, nextState) {
    let {
      timeRegistrations,
      contracts,
      projects,
      clients,
      resources,
      hourSet,
      selectedTimeRegistration,
    } = nextProps;
    let nextItems = timeRegistrations.map((tr) =>
      mapRegistration(tr, contracts, projects, clients, resources, hourSet)
    );
    let isSame =
      this.state.items.length === nextItems.length &&
      this.state.items.reduce(
        (acc, cur, idx) => acc && isSameItem(nextItems[idx], cur),
        true
      ) &&
      selectedTimeRegistration === this.props.selectedTimeRegistration;
    return !isSame || this.state !== this.nextState;
  }

  render() {
    let {
      showIfNoTimeRegistrations,
      columnNames,
      timeRegistrations,
      onRenderColumn,
      onRenderItem,
      showIfLessThanTwoRegistrations,
      canBeGrouped,
    } = this.props;
    let { items, viewDesc, groups, availableOptions } = this.state;

    let filteredColumns = TimeRegistrationDetailListColumns.filter((c) =>
      columnNames.includes(c.fieldName)
    ).map((c) => Object.assign({}, c, { onColumnClick: this._onColumnClick }));

    let show =
      showIfNoTimeRegistrations ||
      (showIfLessThanTwoRegistrations && timeRegistrations.length > 0) ||
      timeRegistrations.length > 1;

    const viewDescGroupBy = viewDesc.groupBy || [];
    let onShouldVirtualize;
    if (viewDesc.groupBy) {
      onShouldVirtualize = () => viewDesc.groupBy.length <= 0;
    }

    let groupProps = {
      headerProps: {
        onToggleCollapse: this._onToggleCollapse,
      },
    };

    return (
      <div className="novatime-time-registration-list-wrapper">
        {show && canBeGrouped && (
          <div className="novatime-time-registration-list-toggle-buttons-container">
            <TooltipHost
              content={
                !availableOptions.has(EntityTypes.CLIENT)
                  ? "Selected data contains less than two unique clients"
                  : null
              }
            >
              <div className="novatime-time-registration-list-toggle-button">
                <Toggle
                  label={EntityTypes.CLIENT}
                  disabled={!availableOptions.has(EntityTypes.CLIENT)}
                  checked={
                    viewDescGroupBy.includes(EntityTypes.CLIENT) ||
                    !availableOptions.has(EntityTypes.CLIENT)
                  }
                  onChange={this._toggleGroupByClient}
                />
              </div>
            </TooltipHost>

            <TooltipHost
              content={
                !availableOptions.has(EntityTypes.PROJECT)
                  ? "Selected data contains less than two unique projects"
                  : null
              }
            >
              <div className="novatime-time-registration-list-toggle-button">
                <Toggle
                  label={EntityTypes.PROJECT}
                  disabled={!availableOptions.has(EntityTypes.PROJECT)}
                  checked={
                    viewDescGroupBy.includes(EntityTypes.PROJECT) ||
                    !availableOptions.has(EntityTypes.PROJECT)
                  }
                  onChange={this._toggleGroupByProject}
                />
              </div>
            </TooltipHost>

            <TooltipHost
              content={
                !availableOptions.has(EntityTypes.RESOURCE)
                  ? "Selected data contains less than two unique persons"
                  : null
              }
            >
              <div className="novatime-time-registration-list-toggle-button">
                <Toggle
                  label={EntityTypes.RESOURCE}
                  disabled={!availableOptions.has(EntityTypes.RESOURCE)}
                  checked={
                    viewDescGroupBy.includes(EntityTypes.RESOURCE) ||
                    !availableOptions.has(EntityTypes.RESOURCE)
                  }
                  onChange={this._toggleGroupByResource}
                />
              </div>
            </TooltipHost>

            <TooltipHost
              content={
                !availableOptions.has("date")
                  ? "Selected data contains less than two unique dates"
                  : null
              }
            >
              <div className="novatime-time-registration-list-toggle-button">
                <Toggle
                  label={"Date"}
                  disabled={!availableOptions.has("date")}
                  checked={
                    viewDescGroupBy.includes("date") ||
                    !availableOptions.has("date")
                  }
                  onChange={this._toggleGroupByDate}
                />
              </div>
            </TooltipHost>
          </div>
        )}
        {show && (
          <DetailsListBasic
            className="novatime-time-registration-list"
            columns={filteredColumns}
            items={groups ? groups.data : items}
            onRenderItemColumn={onRenderColumn}
            onRenderRow={onRenderItem}
            onSelect={this._onSelect}
            selectable={true}
            multiselect={false}
            setKey={this._genSetKey()}
            isItemSelected={this._isItemSelected}
            groups={groups ? groups.info : null}
            groupProps={groupProps}
            onShouldVirtualize={onShouldVirtualize}
          />
        )}
      </div>
    );
  }
}

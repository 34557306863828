import { useContext } from "react";
import CurrentUserContext, {
  CurrentUserConfig,
} from "../contexts/CurrentUserContext";
import UrlContext, { UrlConfig } from "../contexts/UrlContext";

export const useCurrentUserContext = () =>
  useContext(CurrentUserContext) as CurrentUserConfig;

export const useUrlContext = () => useContext(UrlContext) as UrlConfig;

import * as actionhelper from '../actionhelper';

export const getAllTimeRegistrationList = actionhelper.apiResponseData;

export const getUpsertTimeRegistration = actionhelper.apiResponseData;

export const getUpsertTimeRegistrationId = (action: any) => actionhelper.apiResponseData(action).id;                //action.payload.requestData.data is an object with an .id key

export const getDeleteTimeRegistrationId = actionhelper.apiSentModelId;

export const getChangeToCurrentEdit = (action: any) => action.payload.change;

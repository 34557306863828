import {getEntityId} from '../selectors/apihelper';

function idCompare(record1, record2){
    return (record1.id - 0) - (record2.id - 0);
}

export const updateDtoList = (state, entryName, updatedDtos) => { 
    var list = state[entryName].slice().sort(idCompare);
    var newList = updatedDtos.slice().filter(dto => !!dto).sort(idCompare);
    var i = 0, li = 0, ni = 0;
    var result = new Array(list.length + newList.length);
    var item, newItem;
    while(li < list.length || ni < newList.length){
        item = list[li];
        newItem = newList[ni];
        if(newItem && item){
            // find the record with the smallest id
            let cmp = idCompare(newItem, item);
            // newItem has smallest id
            if(cmp < 0){
                result[i] = newItem;
                ni++;
            }
            // item has smallest id
            else if(cmp > 0){
                result[i] = item;
                li++;
            }
            // these are the same, take the updated version
            else {
                result[i] = newItem;
                ni++;
                li++;                
            }
        }
        // no items left in list
        else if(newItem){
            result[i] = newItem;
            ni++;
        }
        // no items left in newList
        else {
            result[i] = item;
            li++;
        }
        i++;
    }
    result.length = i; // truncate array to proper size
    var o = {};
    o[entryName] = result;
    if('storeRevision' in state){
        o.storeRevision = state.storeRevision + 1;
    }
    return Object.assign({}, state, o);
}

export const removeItemFromList = (state, entryName, deleteKey) => {
    if(!(deleteKey instanceof Array)){
        deleteKey = [deleteKey];
    }
    var list = state[entryName].slice();
    var o = {};
    o[entryName] = list.filter(a => !deleteKey.includes(getEntityId(a)));
    if('storeRevision' in state){
        o.storeRevision = state.storeRevision + 1;
    }
    return Object.assign({}, state, o);
}

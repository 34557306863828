import * as SagaHelpers from "../helpers";
import * as SagaEffects from "redux-saga/effects";
import * as types from "../../constants/ActionTypes";
import AbsencePeriodsConnector from "../../connectors/AbsencePeriod";

const createAbsencePeriod = SagaHelpers.createApiHandler(
  types.API_ABSENCEPERIOD_CREATE_INIT,
  SagaHelpers.generateCreateApiCall(
    AbsencePeriodsConnector,
    function* (connector: AbsencePeriodsConnector, action: any): any {
      return yield SagaEffects.call(
        [connector, connector.createAbsencePeriod],
        {
          data: action.payload.model,
        }
      );
    }
  )
);

const deleteAbsencePeriod = SagaHelpers.createApiHandler(
  types.API_ABSENCEPERIOD_DELETE_INIT,
  SagaHelpers.generateDeleteApiCall(
    AbsencePeriodsConnector,
    function* (connector: AbsencePeriodsConnector, action: any): any {
      return yield SagaEffects.call(
        [connector, connector.deleteAbsencePeriod],
        action.payload.model.id
      );
    }
  )
);

const getAllAbsencePeriods = SagaHelpers.createApiHandler(
  types.API_ABSENCEPERIOD_GET_ALL_INIT,
  SagaHelpers.generateGetAllApiCall(
    AbsencePeriodsConnector,
    function* (connector: AbsencePeriodsConnector, action: any): any {
      const { earliestStart, resourceId, latestStart, absencePeriodStatusId } =
        action.payload;
      return yield SagaEffects.call(
        [connector, connector.getAllAbsencePeriods],
        {
          earliestStart:
            earliestStart !== null ? new Date(earliestStart) : earliestStart,
          latestStart:
            latestStart !== null ? new Date(latestStart) : latestStart,
          resourceId,
          absencePeriodStatusId,
        }
      );
    }
  )
);

const updateAbsencePeriod = SagaHelpers.createApiHandler(
  types.API_ABSENCEPERIOD_UPDATE_INIT,
  SagaHelpers.generateUpdateApiCall(
    AbsencePeriodsConnector,
    function* (
      connector: AbsencePeriodsConnector,
      action: any,
      requestData: any
    ): any {
      return yield SagaEffects.call(
        [connector, connector.updateAbsencePeriod],
        action.payload.model.id,
        requestData
      );
    }
  )
);

export default function* absencePeriodSaga() {
  yield SagaEffects.all([
    SagaEffects.fork(getAllAbsencePeriods),
    SagaEffects.fork(createAbsencePeriod),
    SagaEffects.fork(updateAbsencePeriod),
    SagaEffects.fork(deleteAbsencePeriod),
  ]);
}

import * as types from '../../constants/ActionTypes';
import {removeItemFromList, updateDtoList} from '../helpers';
import {
  getAllProjectList,
  getDeleteProjectId,
  getIncludedProjects,
  getUpsertProject
} from '../../selectors/actions/project';

const initialState = {
    storeRevision: 0,
    allProjects: []
};

export default function projectReducer(state = initialState, action) {
    let record, nextState, error, recordId, recordInStore;
    switch (action.type) {
        case types.API_PROJECT_GET_ALL_SUCCESS: {
            return updateDtoList(state, 'allProjects', getAllProjectList(action));
        }

        case types.API_PROJECT_GET_SUCCESS: {
            return updateDtoList(state, 'allProjects', [getUpsertProject(action)]);
        }
        case types.API_CONTRACT_GET_ALL_SUCCESS: {
            return updateDtoList(state, 'allProjects', getIncludedProjects(action));
        }

        case types.API_PROJECT_CREATE_SUCCESS:
        case types.API_PROJECT_UPDATE_SUCCESS: {
            return updateDtoList(state, 'allProjects', [getUpsertProject(action)]);
        }

        case types.API_TIMEREGISTRATION_GET_ALL_SUCCESS:
        case types.API_PROJECTRESOURCE_GET_ALL_SUCCESS: {
            let projects = getIncludedProjects(action);
            return updateDtoList(state, 'allProjects', projects);
		}

		case types.API_PROJECT_GET_ALL_FOR_PERMISSION_SUCCESS : {
			return updateDtoList(state, 'allProjects', getAllProjectList(action));
		}

        case types.API_PROJECT_DELETE_SUCCESS: {
            return removeItemFromList(state, 'allProjects', [getDeleteProjectId(action)]);
        }

        default:
            return state;
    }
}


import * as SagaEffects from "redux-saga/effects";
import * as SagaHelpers from "../helpers";
import * as types from "../../constants/ActionTypes";
import WorkLocationConnector from "../../connectors/WorkLocation";
import { GetAllWorkLocationsAction } from "../../actions/api/workLocation";

const getAllWorkLocations = SagaHelpers.createApiHandler(
  types.API_WORKLOCATON_GET_ALL_INIT,
  SagaHelpers.generateGetAllApiCall(
    WorkLocationConnector,
    function* (
      connector: WorkLocationConnector,
      action: GetAllWorkLocationsAction
    ): any {
      return yield SagaEffects.call([connector, connector.getWorkLocations]);
    }
  )
);

export default function* workLocationSaga() {
  yield SagaEffects.all([SagaEffects.fork(getAllWorkLocations)]);
}

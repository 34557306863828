import { createSelector } from "reselect";
import * as appDomainSelectors from './app/domain';
import { allClients } from "./reducers/client";
import { allContracts } from "./reducers/contract";
import { allProjects } from "./reducers/project";
import * as apihelper from './apihelper';
import * as storehelper from "./storehelper";
import { ResourceObject } from "../lib/models";

export const modelUnderEdit: (state: any) => null | ResourceObject = (state: any) => state.app.editor.modelUnderEdit;

export const form = (state: any) => state.app.editor.form;

export const tempInternalClientId = (state: any) => state.app.editor.tempInternalClientId;

export const modelsForAccountForm = createSelector(
    [modelUnderEdit, appDomainSelectors.allAbsenceRegistrationProjectsForSelectedAccount],
    (accountModel, absenceRegistrationProject) => [...absenceRegistrationProject, accountModel]
)

export const allClientsBySelectedAccountSorted = createSelector(
    [allClients, appDomainSelectors.selectedAccount],
    (clients, account) => storehelper.filterByReferenceToRelatedEntities(clients, account, 'account')
                                     .sort(storehelper.sortByAttr('name'))
)

export const internalClientIdforSelectedAccount = createSelector(
    [appDomainSelectors.selectedAccount, tempInternalClientId],
    (account, tempInternalClientId) =>  tempInternalClientId ? tempInternalClientId : account ? apihelper.getRelId(account, 'internalClient') : undefined
)

export const internalClientforSelectedAccount = createSelector(
    [allClients, internalClientIdforSelectedAccount],
    (clients, clientId) => clientId ? clients.find(c => apihelper.entityHasId(c, clientId)) : undefined
)

export const contractIdsForInternalClientForSelectedAccount = createSelector(
    [allContracts, internalClientforSelectedAccount],
    (contracts, client) => client ? contracts.filter(c => apihelper.entityHasId(client, apihelper.getRelId(c, 'client') as string)).map(c => apihelper.getEntityId(c)) : []
)

export const projectsForInternalClientForSelectedAccount = createSelector(
    [allProjects, contractIdsForInternalClientForSelectedAccount],
    (projects, contractIds) => contractIds.map(id => projects.filter(p => apihelper.relHasId(p, 'contract', id))).flatten()
)

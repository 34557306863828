import * as types from '../../constants/ActionTypes';
import {updateDtoList} from '../helpers';
import {getAllLanguage} from '../../selectors/actions/language';

const initialState = {
    storeRevision: 0,
    allLanguage: []
}

export default function languageReducer(state = initialState, action) {
    switch (action.type) {

        case types.API_LANGUAGE_GET_ALL_SUCCESS: {
            return updateDtoList(state, 'allLanguage', getAllLanguage(action));
        }

        default:
            return state;
    }
}
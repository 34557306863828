import React, { Component } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import * as Icons from "../../constants/Icons";
import { Icon } from "@fluentui/react/lib/Icon";
import { getAttr, getEntityId, entityHasId } from "../../selectors/apihelper";
import * as InternalPropTypes from "../../constants/PropTypes";
import * as datehelper from "../../lib/date";
import CurrentUserContext from "../../contexts/CurrentUserContext";

const datesNotEqual = (date1, date2) => {
  return (
    (!date1 && date2) ||
    (date1 && !date2) ||
    (date1 && date2 && date1.getTime() != date2.getTime())
  );
};

const entitesEqual = (list1, list2) =>
  list1.length == list2.length &&
  list1.reduce(
    (acc, cur, idx) => acc && entityHasId(cur, getEntityId(list2[idx])),
    true
  );

const capitalizeText = (text) => {
  return (
    text.toLowerCase().charAt(0).toUpperCase() + text.slice(1).toLowerCase()
  );
};

const generateExpirationMessages = (
  entities,
  weekDays,
  daysWithNoEntities,
  entityName
) => {
  let entitiesBeforeWeek = entities
    .filter((e) =>
      datehelper.isBeforeOrSameDay(
        new Date(getAttr(e, "expiryDate")),
        datehelper.subtractDays(weekDays[0], 1)
      )
    )
    .sort(
      (a, b) =>
        new Date(getAttr(b, "expiryDate")) - new Date(getAttr(a, "expiryDate"))
    );
  let entitiesActiveInWeek = entities.filter((e) =>
    datehelper.intersectsPeriod(
      weekDays[0],
      weekDays[6],
      new Date(getAttr(e, "effectiveDate")),
      new Date(getAttr(e, "expiryDate"))
    )
  );
  let entitiesAfterWeek = entities
    .filter((e) =>
      datehelper.isSameDayOrAfter(
        new Date(getAttr(e, "effectiveDate")),
        datehelper.addDays(weekDays[6], 1)
      )
    )
    .sort(
      (a, b) =>
        new Date(getAttr(b, "effectiveDate")) -
        new Date(getAttr(a, "effectiveDate"))
    );

  let titles = [];
  if (entities.length > 0) {
    if (daysWithNoEntities.includes(weekDays[0])) {
      let lastEffectiveBeforeWeek =
        entitiesBeforeWeek[entitiesBeforeWeek.length - 1];
      if (lastEffectiveBeforeWeek) {
        titles.push(
          "Latest " +
            entityName +
            " before week expired on " +
            moment(getAttr(lastEffectiveBeforeWeek, "expiryDate")).format(
              "MMMM Do YYYY"
            )
        );
      } else {
        titles.push(
          "No " + entityName + " fetched expired before this this week"
        );
      }
    }
    if (daysWithNoEntities.includes(weekDays[6])) {
      let earliestEffectiveAfterWeek = entitiesAfterWeek[0];
      if (earliestEffectiveAfterWeek) {
        titles.push(
          "Earliest " +
            entityName +
            " after week goes into effect " +
            moment(getAttr(earliestEffectiveAfterWeek, "effectiveDate")).format(
              "MMMM Do YYYY"
            )
        );
      } else {
        titles.push(
          "No " + entityName + " fetched becomes active after this week"
        );
      }
    }
    if (
      daysWithNoEntities.filter((d) => d > weekDays[0] && d < weekDays[6])
        .length > 0
    ) {
      entitiesActiveInWeek.forEach((cr) =>
        titles.push(
          capitalizeText(entityName) +
            " becomes active on " +
            moment(getAttr(cr, "effectiveDate")).format("MMMM Do YYYY") +
            " and expires on " +
            moment(getAttr(cr, "expiryDate")).format("MMMM Do YYYY")
        )
      );
    }
  } else {
    titles.push(
      "No " + entityName + " exists meaning no allocation has been made"
    );
  }
  if (titles.length > 0) {
    titles.push(
      "Have " +
        entityName +
        "(s) modified or created so they cover the dates you need"
    );
  }

  return titles;
};

export default class ExtensionAlert extends Component {
  static propTypes = {
    projectResources: InternalPropTypes.projectResourceEntities,
    contractRoles: InternalPropTypes.contractRoleEntities,
    weekDays: PropTypes.arrayOf(PropTypes.instanceOf(Date)),
  };

  static defaultProps = {
    projectResources: [],
    contractRoles: [],
    weekDays: [],
  };

  constructor(props) {
    super(props);
  }

  shouldComponentUpdate(nextProps, nextState) {
    let curLowestDate = this.props.weekDays[0];
    let nextLowestDate = nextProps.weekDays[0];
    let prAreSame = entitesEqual(
      nextProps.projectResources,
      this.props.projectResources
    );
    let coAreSame = entitesEqual(
      nextProps.contractRoles,
      this.props.contractRoles
    );
    return (
      !prAreSame || !coAreSame || datesNotEqual(curLowestDate, nextLowestDate)
    );
  }

  render() {
    let { projectResources, contractRoles, weekDays } = this.props;

    let daysWithNoProjectResources = weekDays.filter(
      (d) =>
        !projectResources.some((pr) =>
          moment(d).isBetween(
            getAttr(pr, "effectiveDate"),
            getAttr(pr, "expiryDate"),
            "day",
            "[)"
          )
        )
    );
    let daysWithNoContractRole = weekDays.filter(
      (d) =>
        !contractRoles.some((cr) =>
          moment(d).isBetween(
            getAttr(cr, "effectiveDate"),
            getAttr(cr, "expiryDate"),
            "day",
            "[)"
          )
        )
    );

    let hasAnyData =
      daysWithNoProjectResources.length > 0 ||
      (contractRoles.length > 0 && daysWithNoContractRole.length > 0);

    let contractRolesTitles = generateExpirationMessages(
      contractRoles,
      weekDays,
      daysWithNoContractRole,
      "contract role"
    );
    let projectResourceTitles = generateExpirationMessages(
      projectResources,
      weekDays,
      daysWithNoProjectResources,
      "project resource"
    );
    let contractRolesTitle = contractRolesTitles.join("\n");
    let projectResourcesTitle = projectResourceTitles.join("\n");

    let iconTitlePrs =
      (projectResourcesTitle ? "Project resources\n" : "") +
      projectResourcesTitle;
    let iconTitleFull =
      (contractRolesTitle ? "Contract roles\n" : "") +
      contractRolesTitle +
      (contractRolesTitle && projectResourcesTitle ? "\n\n" : "") +
      iconTitlePrs;

    return (
      <CurrentUserContext.Consumer>
        {({ currentUserPermissionSet }) =>
          hasAnyData &&
          (currentUserPermissionSet.hasSelectedAccountRead() ||
            projectResourceTitles.length > 0) ? (
            <Icon
              iconName={Icons.ICON_NAME_EXTENDALERT}
              className="extend-period-alert-icon"
              title={
                currentUserPermissionSet.hasSelectedAccountRead()
                  ? iconTitleFull
                  : iconTitlePrs
              }
            />
          ) : null
        }
      </CurrentUserContext.Consumer>
    );
  }
}

import { select, takeLatest, put } from 'redux-saga/effects';
import * as types from '../../../constants/ActionTypes'
import * as appDomainSelectors from '../../../selectors/app/domain';
import {getAllUsers} from '../../../actions/api/user';
import {getAllResources} from '../../../actions/api/resource';
import {getAllPermissions} from '../../../actions/api/permission';
import {getAllClients} from '../../../actions/api/client';
import {getAllProjectsForPermission} from '../../../actions/api/project';
import { getAllContracts } from '../../../actions/api/contract';
import { getAllAccounts } from '../../../actions/api/account';

function* doLoadInformation(){
    let selectedAccount = yield select(appDomainSelectors.selectedAccount);
    // fetch users
    yield put(getAllUsers(selectedAccount));

    // fetch resources
	yield put(getAllResources(selectedAccount));
	 
	// fetch permissions
	yield put(getAllPermissions(selectedAccount));
	yield put(getAllClients());
	yield put(getAllProjectsForPermission());
    yield put(getAllContracts());
    yield put(getAllAccounts());
}


export default function* loadInformation(){
    yield takeLatest(types.APP_PAGE_USERSANDRESOURCES_LOAD_USERS_RESOURCES_PERMISSIONS,doLoadInformation);
}
import React from "react";
import { NewResourceObject, ResourceObject } from "../../lib/models";
import * as apihelper from "../../selectors/apihelper";
import { SingleBaseFormProps } from "./SingleBaseForm";
import TimeInputField from "../TimeInputField";
import {
  FieldValue,
  PublicFields,
  useFormFields,
} from "../../hooks/formfields";
import Grid from "../grid/Grid";
import Row from "../grid/Row";
import Column from "../grid/Column";
import { ModelTimestamps } from "./ModelTimestamps";
import { FormButtonBar } from "./FormButtonBar";
import { createEmptyModel } from "../../lib/emptyModelCreation";
import FormCommand from "../../lib/FormCommand";
import { TextField } from "@fluentui/react";

type WorkLocationRegistrationFormProps = SingleBaseFormProps & {
  model: ResourceObject;
};

export default function WorkLocationRegistrationForm({
  model,
  onSave,
  onDismiss,
}: WorkLocationRegistrationFormProps) {
  const [fields, update, isValid, isChanged, copyToModel] = useFormFields(
    {
      startTime: {
        fromModel: (model: ResourceObject) =>
          new Date(apihelper.getAttr(model, "startTime") as string),
        isRequired: true,
        copyToModel: (
          model: NewResourceObject,
          currentValue: FieldValue<Date>
        ) =>
          apihelper.setAttr(
            model,
            "startTime",
            currentValue.value.toISOString()
          ),
        defaultValue: new Date(),
        validate: (fields: PublicFields) => {
          const isValid = fields["startTime"].value < fields["endTime"].value;
          return {
            isValid,
            validationMessage: !isValid
              ? "Start time must be before end time"
              : undefined,
          };
        },
      },
      endTime: {
        fromModel: (model: ResourceObject) =>
          new Date(apihelper.getAttr(model, "endTime") as string),
        isRequired: true,
        copyToModel: (
          model: NewResourceObject,
          currentValue: FieldValue<Date>
        ) =>
          apihelper.setAttr(model, "endTime", currentValue.value.toISOString()),
        defaultValue: new Date(),
        validate: (fields: PublicFields) => {
          const isValid = fields["startTime"].value < fields["endTime"].value;
          return {
            isValid,
            validationMessage: !isValid
              ? "End time must be after start time"
              : undefined,
          };
        },
      },
      description: {
        fromModel: (model: ResourceObject) =>
          apihelper.getAttr(model, "description"),
        copyToModel: (
          model: NewResourceObject,
          currentValue: FieldValue<string>
        ) => apihelper.setAttr(model, "description", currentValue.value),
      },
      accountId: {
        fromModel: (model: ResourceObject) =>
          apihelper.getRelId(model, "account"),
        isRequired: true,
        copyToModel: (
          model: NewResourceObject,
          currentValue: FieldValue<string>
        ) => apihelper.setRelId(model, "account", currentValue.value),
      },
      resourceId: {
        fromModel: (model: ResourceObject) =>
          apihelper.getRelId(model, "resource"),
        isRequired: true,
        copyToModel: (
          model: NewResourceObject,
          currentValue: FieldValue<string>
        ) => apihelper.setRelId(model, "resource", currentValue.value),
      },
      workLocationId: {
        fromModel: (model: ResourceObject) =>
          apihelper.getRelId(model, "workLocation"),
        isRequired: true,
        copyToModel: (
          model: NewResourceObject,
          currentValue: FieldValue<string>
        ) => apihelper.setRelId(model, "workLocation", currentValue.value),
      },
    },
    model
  );

  const produceFormCommands = () => {
    const changes = createEmptyModel(apihelper.getEntityType(model));
    copyToModel(changes);
    return [new FormCommand(model, false, changes)];
  };

  return (
    <div className="novatime-worklocationregistration-form">
      <Grid>
        <Row>
          <Column sm={6} md={3} lg={2}>
            <TimeInputField
              label={"From"}
              showSeconds={false}
              value={fields.startTime.value}
              onChange={(startTime: Date) => update("startTime", startTime)}
              required={fields.startTime.isRequired}
              minuteStep={15}
              errorMessage={fields.startTime.validationMessage}
            />
          </Column>
          <Column sm={6} md={3} lg={2}>
            <TimeInputField
              label={"To"}
              showSeconds={false}
              value={fields.endTime.value}
              onChange={(endTime: Date) => update("endTime", endTime)}
              required={fields.endTime.isRequired}
              minuteStep={15}
              errorMessage={fields.endTime.validationMessage}
            />
          </Column>
        </Row>
        <Row>
          <Column sm={12} md={12} lg={12}>
            <TextField
              onChange={(
                _: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
                newValue?: string
              ) => update("description", newValue)}
              required={fields.description.isRequired}
              value={fields.description.value}
              errorMessage={fields.description.validationMessage}
              label="Description"
            />
          </Column>
        </Row>
        <Row>
          <Column>
            <FormButtonBar
              isSaveDisabled={!isValid || !isChanged}
              onSave={() => onSave && onSave(produceFormCommands())}
              onDismiss={onDismiss}
            />
            <ModelTimestamps model={model} />
          </Column>
        </Row>
      </Grid>
    </div>
  );
}

import React, { Component } from "react";
import PropTypes from "prop-types";
import {
  Persona,
  PersonaSize,
  PersonaPresence,
} from "@fluentui/react/lib/Persona";
import * as apihelper from "../../selectors/apihelper";
import { getInitialsForNamedAttribute } from "../../lib/getInitialsForNamedAttribute";

export class Resource extends Component {
  static propTypes = {
    model: PropTypes.object,
  };

  shouldComponentUpdate(nextProps) {
    return (
      !this.props.model ||
      !nextProps.model ||
      apihelper.getAttr(this.props.model, "name") !=
        apihelper.getAttr(nextProps.model, "name") ||
      apihelper.getAttr(this.props.model, "isActive") !=
        apihelper.getAttr(nextProps.model, "isActive") ||
      this.props.hidePersonaDetails != nextProps.hidePersonaDetails
    );
  }

  render() {
    let { model, hidePersonaDetails } = this.props;

    let name = model ? apihelper.getAttr(model, "name") : "";
    const base = {
      imageInitials: model ? getInitialsForNamedAttribute(model) : undefined,
      text: model ? name : undefined,
      secondaryText: "secondary text",
      tertiaryText: "teriary text",
      optionalText: "optional text",
    };

    let className = "novatime-resource-persona";
    let title;

    if (model && !apihelper.getAttr(model, "isActive")) {
      className += " inactive";
      title = "Inactive";
    }

    return model ? (
      <Persona
        {...base}
        className={className}
        title={title}
        size={PersonaSize.size24}
        presence={PersonaPresence.none}
        hidePersonaDetails={hidePersonaDetails}
      />
    ) : null;
  }
}

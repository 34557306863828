import * as types from '../../constants/ActionTypes';
import {removeItemFromList, updateDtoList} from '../helpers';
import {
    allAbsencePeriods,
    getDeleteAbsencePeriodId,
    getUpsertAbsencePeriod
} from '../../selectors/actions/absencePeriod';

const initialState = {
    storeRevision: 0,
    allAbsencePeriods: []
};

export default function absencePeriodReducer(state = initialState, action) {    
    switch (action.type) {
        case types.API_ABSENCEPERIOD_GET_ALL_SUCCESS: {
            return updateDtoList(state, 'allAbsencePeriods', allAbsencePeriods(action));
        }
        case types.API_ABSENCEPERIOD_CREATE_SUCCESS:
        case types.API_ABSENCEPERIOD_UPDATE_SUCCESS: {
            return updateDtoList(state, 'allAbsencePeriods', [getUpsertAbsencePeriod(action)]);
        }
        case types.API_ABSENCEPERIOD_DELETE_SUCCESS: {
            return removeItemFromList(state, 'allAbsencePeriods', [getDeleteAbsencePeriodId(action)]);
        }
        default:
            return state;
    }
}

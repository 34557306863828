import TimeRegistrationMonth from '../../components/timeRegistration/month';
import React, { Component, PureComponent } from 'react';
import PropTypes from 'prop-types';
import * as InternalPropTypes from '../../constants/PropTypes';
import {Resource} from '../../components/uifabricextensions/Resource';
import TimeRegistrationHoverCard from './TimeRegistrationHoverCard';
import MonthHourField from './MonthHourField';
import moment from 'moment';
import latinize from 'latinize';
import '../../lib/unique';
import {v1 as uuidv1} from 'uuid';
import * as apihelper from '../../selectors/apihelper';
import MonthReport from './reports/monthReport';

function resourceSort(a, b){ 
    if (apihelper.getAttr(a, 'isActive') - apihelper.getAttr(b, 'isActive')) {
        return apihelper.getAttr(b, 'isActive') - apihelper.getAttr(a, 'isActive;')
    }
    let nameA = latinize(apihelper.getAttr(a, 'name')).toLowerCase(); // ignore upper and lowercase
    let nameB = latinize(apihelper.getAttr(b, 'name')).toLowerCase(); // ignore upper and lowercase
    if (nameA < nameB) {
        return -1;
    }
    if (nameA > nameB) {
        return 1;
    }
    // names must be equal
    return 0;
}

const regGoal = {goal: 8, deviation: 2};

class NormalCell extends Component {
    static propTypes = {
        stateMerge: InternalPropTypes.stateMerge,
        date: PropTypes.instanceOf(Date),
        resource: InternalPropTypes.resourceEntity,
        onClick: PropTypes.func
    };

    shouldComponentUpdate(nextProps){
        var stateMergeIsSame = nextProps.stateMerge === this.props.stateMerge;
        var dateIsSame = !!nextProps.date && !!this.props.date && this.props.date.getTime() === nextProps.date.getTime() || this.props.date === nextProps.date;
        var resourceIsSame = nextProps.resource === this.props.resource;
        var onClickIsSame = nextProps.onClick === this.props.onClick;
        var noChange = stateMergeIsSame && dateIsSame && resourceIsSame && onClickIsSame;
        return !noChange;
    }

    render(){
        let {stateMerge, resource, date, onClick} = this.props;
        let tregs = stateMerge.registrationsByResourceAndDay(resource, date);
        let totalHours = stateMerge.getTotalByResourceAndDay(resource, date);
        let key = 'month-cell' + tregs.map(tr => apihelper.getEntityId(tr) || uuidv1()).join('-');

        return (
            <MonthHourField 
                cellData={{
                    value: totalHours,
                    registrationGoals: regGoal,
                    date,
                    resource,
                    timeRegistrations: tregs
                }}
                onClick={onClick}
                key={key + '-monthhourfield'}
            />
        );
    }
}

class RowSummationCell extends PureComponent {
    static propTypes = {
        stateMerge: InternalPropTypes.stateMerge,
        resource: InternalPropTypes.resourceEntity,
        onClick: PropTypes.func,
        onLongHover: PropTypes.func,
        onHover: PropTypes.func,
        startTime: PropTypes.instanceOf(Date),
        endTime: PropTypes.instanceOf(Date),
    };

    render(){
        let {stateMerge, resource, onClick, onLongHover, onHover, startTime, endTime} = this.props;
        let timeRegistrations = stateMerge.registrationsByResource(resource);
        let total = stateMerge.getTotalByResource(resource);

        return (
            <TimeRegistrationHoverCard 
                renderData={{
                    timeRegistrations, 
                    resources: [resource],
                    startTime, 
                    endTime
                }}
                onHover={onHover}
                compactCardHeight={600}
                onLongHover={onLongHover}>
                <MonthHourField 
                    cellData={{
                        value: total,
                        resource,
                        timeRegistrations
                    }}
                    onClick={onClick}
                />
            </TimeRegistrationHoverCard>
        );
    }
}

class ColumnSummationCell extends Component {
    static propTypes = {
        stateMerge: InternalPropTypes.stateMerge,
        resources: InternalPropTypes.resourceEntities,
        startTime: PropTypes.instanceOf(Date),
        endTime: PropTypes.instanceOf(Date),
        onClick: PropTypes.func,
        onHover: PropTypes.func,
    };

    shouldComponentUpdate(nextProps){
        let stateMergeIsSame = this.props.stateMerge === nextProps.stateMerge;
        let resourcesIsSame = this.props.resources === nextProps.resources;
        let startTimeIsSame = this.props.startTime === nextProps.startTime ||
            !!this.props.startTime && !!nextProps.startTime && this.props.startTime.getTime() === nextProps.startTime.getTime();
        let endTimeIsSame = this.props.endTime === nextProps.endTime ||
            !!this.props.endTime && !!nextProps.endTime && this.props.endTime.getTime() === nextProps.endTime.getTime();
        let onClickIsSame = this.props.onClick === nextProps.onClick;

        return !(stateMergeIsSame && resourcesIsSame && startTimeIsSame && endTimeIsSame && onClickIsSame);
    }

    render(){
        let {stateMerge, resources, startTime, endTime, onClick, onHover} = this.props;
        let timeRegistrations = stateMerge.getRegistrations({ startTime, endTime, resources })
        let total = stateMerge.getTotalByResourcesAndDay(resources, startTime);
        
        return (
            <TimeRegistrationHoverCard 
                onHover={onHover}
                compactCardHeight={600}
                renderData={{ timeRegistrations, resources, startTime, endTime }} >
                <MonthHourField 
                    cellData={{
                        value: total,
                        date: startTime,
                        timeRegistrations
                    }}
                    onClick={onClick}
                />
            </TimeRegistrationHoverCard>
        );
    }
}

class TotalSummationCell extends PureComponent {
    static propTypes = {
        stateMerge: InternalPropTypes.stateMerge,
        resources: InternalPropTypes.resourceEntities,
        onClick: PropTypes.func,
        onLongHover: PropTypes.func,
        onHover: PropTypes.func,
    };

    render(){
        let {stateMerge, resources, onClick, onLongHover, onHover, startTime, endTime} = this.props;
        let timeRegistrations = stateMerge.getRegistrations({ resources });
        let total = stateMerge.aggrHours(timeRegistrations)

        return (
            <TimeRegistrationHoverCard 
                renderData={{
                    timeRegistrations, 
                    resources,
                    startTime, 
                    endTime
                }}
                onHover={onHover}
                compactCardHeight={600}
                onLongHover={onLongHover}>
                <MonthHourField 
                    cellData={{
                        value: total,
                        timeRegistrations
                    }}
                    onClick={onClick}
                />
            </TimeRegistrationHoverCard>
        );
    }
}

export default class ByResourceTable extends Component {
    constructor(props){
        super(props);

        this._getRows = this._getRows.bind(this);
        this._renderRowHeader = this._renderRowHeader.bind(this);
        this._renderCell = this._renderCell.bind(this);
        this._renderColumnSummation = this._renderColumnSummation.bind(this);
        this._renderRowSummation = this._renderRowSummation.bind(this);
        this._renderTotalSummation = this._renderTotalSummation.bind(this);
        this._onCellClick = this._onCellClick.bind(this);
        this._onRowSummationLongHover = this._onRowSummationLongHover.bind(this);
        this._isLocked = this._isLocked.bind(this);
        this._getAbsencePeriod = this._getAbsencePeriod.bind(this);
        this._isFirstDayOfLockedPeriod = this._isFirstDayOfLockedPeriod.bind(this);
        this._isLastDayOfLockedPeriod = this._isLastDayOfLockedPeriod.bind(this);
    }

    static propTypes = {
        isCellUnderEdit: PropTypes.func,
        firstDayOfMonth: PropTypes.object,
        onCellClick: PropTypes.func,
        clients: InternalPropTypes.clientEntities,
        projects: InternalPropTypes.projectEntities,
        contracts: InternalPropTypes.contractEntities,
        resources: InternalPropTypes.resourceEntities.isRequired,
        contractRoles: InternalPropTypes.contractRoleEntities,
        projectResources: InternalPropTypes.projectResourceEntities,
        stateMerge: InternalPropTypes.stateMerge.isRequired,
        lockedTimePeriodIndex: InternalPropTypes.lockedTimePeriodIndex,
        absencePeriods: InternalPropTypes.absencePeriodEntities,
        absencePeriodStatuses: InternalPropTypes.absencePeriodStatusEntities,
        absenceRegistrationProjects: InternalPropTypes.absenceRegistrationProjectEntities,
        hourSet: InternalPropTypes.hourSet,
        currencies: InternalPropTypes.currencyEntities,
        locales: InternalPropTypes.localeEntities,
        account: InternalPropTypes.accountEntity,
        billabilityTypes: InternalPropTypes.billabilityEntities,
        account: InternalPropTypes.accountEntity
    };

    _onCellClick(cellData){
        this.props.onCellClick && this.props.onCellClick(cellData)
    }

    _isLocked(resourceId, date){
        let {lockedTimePeriodIndex} = this.props;
        return lockedTimePeriodIndex.isDateLockedForResource(date, resourceId);
    }

    _isFirstDayOfLockedPeriod(resourceId, date) {
        let {lockedTimePeriodIndex} = this.props;
        return lockedTimePeriodIndex.isDateFirstDayOfLockedPeriodForResource(date, resourceId);
    }

    _isLastDayOfLockedPeriod(resourceId, date) {
        let {lockedTimePeriodIndex} = this.props;
        return lockedTimePeriodIndex.isDateLastDayOfLockedPeriodForResource(date, resourceId);
    }

    _getAbsencePeriod(resourceId, date) {
        let { absencePeriods, absencePeriodStatuses} = this.props;
        let absencePeriod = absencePeriods.find(absencePeriod => 
            new Date(apihelper.getAttr(absencePeriod, 'periodStart')) <= date &&
            new Date(apihelper.getAttr(absencePeriod, 'periodEnd')) >= date && 
            apihelper.relHasId(absencePeriod, 'resource', resourceId)
        );
        let res;
        if(absencePeriod) {
            let statusId = apihelper.getRelId(absencePeriod, 'absencePeriodStatus');
            let vs = absencePeriodStatuses.find(vs => apihelper.entityHasId(vs, statusId));
            res = apihelper.getAttr(vs, 'name');
        }
        return res;
    }

    _getRows(){
        let { resources } = this.props;
        return resources.slice().sort(resourceSort).map(res => ({
            id: apihelper.getEntityId(res),
            resource: res
        }));
    }

    _renderRowHeader(row){
        return <Resource model={row.resource}/>;
    }

    _renderCell(row, monthDate){
        return (
            <NormalCell 
                stateMerge={this.props.stateMerge}
                date={monthDate.dateObj}
                resource={row.resource}
                onClick={this._onCellClick}    
            />
        );
    }

    _renderColumnSummation(monthDate){
        let m = moment(monthDate.dateObj);
        return (
            <ColumnSummationCell 
                stateMerge={this.props.stateMerge}
                resources={this.props.resources}
                startTime={m.startOf('day').toDate()}
                endTime={m.endOf('day').toDate()}
                onHover={this._onRowSummationLongHover}
                onClick={this._onCellClick}    
            />
        )
    }

    _renderRowSummation(row){
        let {stateMerge, firstDayOfMonth} = this.props;

        return (
            <RowSummationCell 
                stateMerge={stateMerge}
                resource={row.resource}
                startTime={moment(firstDayOfMonth).startOf('month').toDate()}
                endTime={moment(firstDayOfMonth).endOf('month').toDate()}
                onClick={this._onCellClick}
                onHover={this._onRowSummationLongHover}
            />
        );
    }

    _renderTotalSummation(){
        let {stateMerge, firstDayOfMonth} = this.props;

        return (
            <TotalSummationCell 
                stateMerge={stateMerge}
                resources={this.props.resources}
                startTime={moment(firstDayOfMonth).startOf('month').toDate()}
                endTime={moment(firstDayOfMonth).endOf('month').toDate()}
                onClick={this._onCellClick}
                onHover={this._onRowSummationLongHover}
            />
        );
    }

    _onRowSummationLongHover(renderData){
        return (
            <MonthReport 
                stateMerge={this.props.stateMerge}
                hourSet={this.props.hourSet}
                startTime={renderData.startTime}
                endTime={renderData.endTime}
                limitToResources={renderData.resources}
                currencies={this.props.currencies}
                locales={this.props.locales}
                absenceRegistrationProjects={this.props.absenceRegistrationProjects}
                account={this.props.account}
                billabilityTypes={this.props.billabilityTypes}
                clients={this.props.clients}
            />
        );
    }

    render() {
        let {firstDayOfMonth} = this.props;
        return (
            firstDayOfMonth != null ? 
                <TimeRegistrationMonth
                    rows={this._getRows()}
                    isCellUnderEdit={this.props.isCellUnderEdit}
                    renderRowHeader={this._renderRowHeader}
                    renderCell={this._renderCell}
                    renderRowSummation={this._renderRowSummation}
                    renderColumnSummation={this._renderColumnSummation}
                    renderTotalSummation={this._renderTotalSummation}
                    dayInMonth={this.props.firstDayOfMonth}
                    clients={this.props.clients}
                    projects={this.props.projects}
                    contracts={this.props.contracts}
                    withCallout={false}
                    isLocked={this._isLocked}
                    isFirstDayOfLockedPeriod={this._isFirstDayOfLockedPeriod}
                    isLastDayOfLockedPeriod={this._isLastDayOfLockedPeriod}
                    absencePeriod={this._getAbsencePeriod}
                />
                : null
        );
    }
}
import * as types from '../../constants/ActionTypes';

export function createAccount(account) {
    return {
        type: types.API_ACCOUNT_CREATE_INIT,
        payload: {
            model: account
        }
    };
}

export function deleteAccount(account) {
    return {
        type: types.API_ACCOUNT_DELETE_INIT,
        payload: {
            model: account
        }
    };
}

export function getAllAccounts() {
    return {
        type: types.API_ACCOUNT_GET_ALL_INIT
    };
}

export function updateAccount(model, modelChanges) {
    return {
        type: types.API_ACCOUNT_UPDATE_INIT,
        payload: {
            model: model,
            changes: modelChanges
        }
    };
}


import React from "react";
import PropTypes from "prop-types";
import { TextField } from "@fluentui/react/lib/TextField";
import { Checkbox } from "@fluentui/react/lib/Checkbox";
import BasePickerSimple from "../uifabricextensions/BasePickerSimple";
import SingleBaseForm from "./SingleBaseForm";
import { entityHasId, getAttr, getRelId } from "../../selectors/apihelper";
import {
  createEmptyModelRelationships,
  createEmptyModelAttributes,
} from "../../lib/emptyModelCreation";
import * as EntityTypes from "../../constants/EntityTypes";
import * as InternalPropTypes from "../../constants/PropTypes";

export default class ProjectForm extends SingleBaseForm {
  constructor(props) {
    super(props);
    let { model } = props;
    this.state = {};

    this.state.fields = SingleBaseForm._initializeFields(
      {
        name: {
          getVal: () => getAttr(this.props.model, "name"),
          setVal: () =>
            createEmptyModelAttributes(EntityTypes.PROJECT, {
              name: this.state.fields.name.value,
            }),
          isRequired: true,
        },
        description: {
          getVal: () => getAttr(this.props.model, "description"),
          setVal: () =>
            createEmptyModelAttributes(EntityTypes.PROJECT, {
              description: this.state.fields.description.value,
            }),
          isRequired: false,
        },
        statusId: {
          getVal: () => getRelId(this.props.model, "projectStatus"),
          setVal: () =>
            createEmptyModelRelationships(EntityTypes.PROJECT, {
              projectStatus: {
                id: this.state.fields.statusId.value,
                type: EntityTypes.PROJECTSTATUS,
              },
            }),
          isRequired: true,
        },
        contractId: {
          getVal: () => getRelId(this.props.model, "contract"),
          setVal: () =>
            createEmptyModelRelationships(EntityTypes.PROJECT, {
              contract: {
                id: this.state.fields.contractId.value,
                type: EntityTypes.CONTRACT,
              },
            }),
          isRequired: true,
        },
        requiresDescriptions: {
          getVal: () => getAttr(this.props.model, "needsDescription"),
          setVal: () =>
            createEmptyModelAttributes(EntityTypes.PROJECT, {
              needsDescription: this.state.fields.requiresDescriptions.value,
            }),
          isRequired: true,
        },
      },
      model
    );

    this._onNameChange = this._onNameChange.bind(this);
    this._onDescriptionChange = this._onDescriptionChange.bind(this);
    this._onStatusIdChange = this._onStatusIdChange.bind(this);
    this._onContractChange = this._onContractChange.bind(this);
    this._onRequiresDescriptionsChange =
      this._onRequiresDescriptionsChange.bind(this);
  }

  static propTypes = {
    model: InternalPropTypes.projectEntity,
    onSave: PropTypes.func,
    onDismiss: PropTypes.func,
  };

  _onNameChange(event, value) {
    this._updateFieldState(
      "name",
      value,
      !value ? "Name cannot be empty" : undefined
    );
  }

  _onDescriptionChange(event, value) {
    this._updateFieldState("description", value);
  }

  _onStatusIdChange(value) {
    this._updateFieldState(
      "statusId",
      value.id,
      !value.id ? "Must select status" : undefined
    );
  }

  _onContractChange(value) {
    this._updateFieldState(
      "contractId",
      value.id,
      !value.id ? "Must select contract" : undefined
    );
  }

  _onRequiresDescriptionsChange(ev, isChecked) {
    this._updateFieldState("requiresDescriptions", isChecked);
  }

  render() {
    let { model, contracts, allProjectStatus } = this.props;
    let { fields } = this.state;
    let nameIsChanged = fields.name.isChanged,
      descriptionIsChanged = fields.description.isChanged,
      statusIdIsChanged = fields.statusId.isChanged,
      contractIsChanged = fields.contractId.isChanged;
    let selectedContract = contracts.find((c) =>
      entityHasId(c, fields.contractId.value)
    );
    let selectedStatus = allProjectStatus.find((s) =>
      entityHasId(s, fields.statusId.value)
    );

    return (
      <div>
        <h2>{model ? "Edit" : "Create"} Project</h2>
        <br />
        <TextField
          label="Name"
          autoFocus={!model}
          iconProps={this._getIconProps(nameIsChanged)}
          onChange={this._onNameChange}
          required={fields.name.isRequired}
          value={fields.name.value}
          errorMessage={fields.name.validationMessage}
        />
        <br />
        <TextField
          label="Description"
          iconProps={this._getIconProps(descriptionIsChanged)}
          onChange={this._onDescriptionChange}
          required={fields.description.isRequired}
          multiline
          rows={4}
          value={fields.description.value}
          errorMessage={fields.description.validationMessage}
        />
        <br />
        <BasePickerSimple
          label="Contract:"
          required={fields.contractId.isRequired}
          selectedEntity={selectedContract}
          entities={contracts}
          onEntitySelected={this._onContractChange}
          icon={
            this._getIconProps(contractIsChanged)
              ? this._getIconProps(contractIsChanged).iconName
              : undefined
          }
        />
        <br />
        <BasePickerSimple
          label="Status"
          required={fields.statusId.isRequired}
          icon={
            this._getIconProps(statusIdIsChanged)
              ? this._getIconProps(statusIdIsChanged).iconName
              : undefined
          }
          selectedEntity={selectedStatus}
          entities={allProjectStatus}
          onEntitySelected={this._onStatusIdChange}
        />
        <br />
        <Checkbox
          label="Time registrations require description?"
          onChange={this._onRequiresDescriptionsChange}
          checked={this.state.fields.requiresDescriptions.value}
        />
        <br />
        {this._renderButtons()}
        <br />
        {this._renderTimestampsAndId(model)}
      </div>
    );
  }
}

import * as storehelper from '../../selectors/storehelper';
import * as apihelper from '../../selectors/apihelper';
import BusinessCalendar from '../../lib/businessCalendar';
import {AccountForm} from './billability';
import {timeRegistrationDuration} from '../../lib/StateMerge';
import * as datehelper from '../../lib/date';
import '../../lib/unique';

const WorkHoursPerBusinessDayForFullTimeEmployees = 8;
// assuming 16d per week, so 16/5 
const WorkHoursPerBusinessDayForPartTimeEmployees = 3.2;
const WorkHoursPerBusinessDayForContractors = 0;

/*
    collapsedRows definition
        dimentions
            resourceId
            isPaidByTheHour
            isPermanentEmployee

        aggregates
            periodWorkDaysTotal
            periodWorkDaysPassed
            periodWorkDaysRemaining
            periodWorkHoursTotal
            periodWorkHoursPassed
            periodWorkHoursRemaining
            registeredHoursTotal
            registeredHoursBillable
            registeredHoursNonBillable
            workToRegisteredFactor
            periodUtilization
            projectedRegisteredHoursTotal
            targetUtilizationHoursTotal
            registeredHoursInWeekends
*/

export class UtilizationAggregate {
    constructor(collapsedRows, startTime, endTime){
        this.collapsedRows = collapsedRows;
        this.startTime = startTime;
        this.endTime = endTime;
    }

    _filterAggregates({resourceId = null, isPaidByTheHour = null, isPermanentEmployee = null} = {}){
        return this.collapsedRows.filter(row => 
            (isPaidByTheHour === null || isPaidByTheHour === row.isPaidByTheHour) &&
            (isPermanentEmployee === null || isPermanentEmployee === row.isPermanentEmployee) &&
            (resourceId === null || resourceId === row.resourceId)
        );
    }

    _aggregateAndReduce(filter, fieldname){
        return this._filterAggregates(filter).reduce((acc, cur) => acc + cur[fieldname], 0);
    }

    getPeriodWorkDaysTotal(filter){
        return this._aggregateAndReduce(filter, 'periodWorkDaysTotal');
    }

    getPeriodWorkDaysPassed(filter){
        return this._aggregateAndReduce(filter, 'periodWorkDaysPassed');
    }

    getPeriodWorkDaysRemaining(filter){
        return this._aggregateAndReduce(filter, 'periodWorkDaysRemaining');
    }

    getPeriodWorkHoursTotal(filter){
        return this._aggregateAndReduce(filter, 'periodWorkHoursTotal');
    }

    getPeriodWorkHoursPassed(filter){
        return this._aggregateAndReduce(filter, 'periodWorkHoursPassed');
    }
    
    getPeriodWorkHoursRemaining(filter){
        return this._aggregateAndReduce(filter, 'periodWorkHoursRemaining');
    }

    getRegisteredHoursTotal(filter){
        return this._aggregateAndReduce(filter, 'registeredHoursTotal');
    }
    
    getRegisteredHoursBillable(filter){
        return this._aggregateAndReduce(filter, 'registeredHoursBillable');
    }

    getRegisteredHoursNonBillable(filter){
        return this._aggregateAndReduce(filter, 'registeredHoursNonBillable');
    }

    getTargetUtilizationHoursTotal(filter){
        return this._aggregateAndReduce(filter, 'targetUtilizationHoursTotal');
    }

    getTargetUtilizationHoursPassed(filter){
        return this._aggregateAndReduce(filter, 'targetUtilizationHoursPassed');
    }

    getTargetUtilizationHoursRemaining(filter){
        return this._aggregateAndReduce(filter, 'targetUtilizationHoursRemaining');
    }

    getUtilizationTarget(filter){
        return this._aggregateAndReduce(filter, 'utilizationTarget');
    }

    getRegisteredHoursInWeekends(filter){
        return this._aggregateAndReduce(filter, 'registeredHoursInWeekends');
    }

    getResourceIds(){
        return this.collapsedRows.map(row => row.resourceId).unique();
    }

    getStartTime(){
        return this.startTime;
    }

    getEndTime(){
        return this.endTime;
    }
}

export default function aggregateUtilization(billabilityAggregate, startTime, endTime, timeRegistrations, resources, locales){
    // find number of business days in period
    let calendar = new BusinessCalendar();
    let today = new Date();
    let tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);

    let lookupStructure = {};
    billabilityAggregate.getResourceIds().forEach(resourceId => {
        let resource = storehelper.findById(resources, resourceId);
        let isPaidByTheHour = apihelper.getAttr(resource, 'isPaidByTheHour');
        let isPermanentlyEmployed = apihelper.getAttr(resource, 'isPermanentlyEmployed');

        // initialize lookup structure
        lookupStructure[isPermanentlyEmployed] ||= {};
        lookupStructure[isPermanentlyEmployed][isPaidByTheHour] ||= {};
        lookupStructure[isPermanentlyEmployed][isPaidByTheHour][resourceId] ||= {};
        
        // eventually write data for this resource at point
        let point = lookupStructure[isPermanentlyEmployed][isPaidByTheHour][resourceId];
        
        let localeName = 'dk';
        if(apihelper.relHasReference(resource, 'employedAtLocale')){
            let locale = storehelper.findById(locales, apihelper.getRelId(resource, 'employedAtLocale'));
            localeName = apihelper.getAttr(locale, 'name');
        }

        let periodWorkDaysTotal = Math.max(calendar.getNumberOfBusinessDaysBetween(startTime, endTime, localeName), 0);
        let periodWorkDaysPassed = Math.max(calendar.getNumberOfBusinessDaysBetween(startTime, datehelper.min(today, endTime), localeName), 0);
        let periodWorkDaysRemaining = Math.max(calendar.getNumberOfBusinessDaysBetween(datehelper.min(tomorrow, endTime), endTime, localeName), 0);
    
        const daysToHours = numDays => numDays * (isPermanentlyEmployed ? isPaidByTheHour ? WorkHoursPerBusinessDayForPartTimeEmployees : WorkHoursPerBusinessDayForFullTimeEmployees : WorkHoursPerBusinessDayForContractors )

        point.periodWorkDaysTotal = periodWorkDaysTotal;
        point.periodWorkDaysPassed = periodWorkDaysPassed;
        point.periodWorkDaysRemaining = periodWorkDaysRemaining;
        point.periodWorkHoursTotal = daysToHours(periodWorkDaysTotal);
        point.periodWorkHoursPassed = daysToHours(periodWorkDaysPassed);
        point.periodWorkHoursRemaining = daysToHours(periodWorkDaysRemaining);
        point.registeredHoursTotal = billabilityAggregate.getHours({resourceId});
        point.registeredHoursBillable = billabilityAggregate.getHours({resourceId, accountForm: AccountForm.Billable});
        point.registeredHoursNonBillable = billabilityAggregate.getHours({resourceId, accountForm: AccountForm.NonBillable});
        point.workToRegisteredFactor = point.registeredHoursTotal > 0 ? point.periodWorkHoursPassed / point.registeredHoursTotal : 0;
        point.periodUtilization = point.registeredHoursTotal > 0 ? point.registeredHoursBillable / point.registeredHoursTotal : 0;
        point.projectedRegisteredHoursTotal = point.registeredHoursTotal + point.periodWorkHoursRemaining * point.workToRegisteredFactor;
        let utilizationTarget = (apihelper.getAttr(resource, 'utilizationTarget') || 0) / 100;
        point.targetUtilizationHoursTotal = point.periodWorkHoursTotal * utilizationTarget;
        point.targetUtilizationHoursPassed = point.periodWorkHoursPassed * utilizationTarget;
        point.targetUtilizationHoursRemaining = point.periodWorkHoursRemaining * utilizationTarget;
        point.utilizationTarget = utilizationTarget;
        point.registeredHoursInWeekends = 0;
    });

    let weekendRegistrations = timeRegistrations.filter(tr => {
        let weekday = (new Date(apihelper.getAttr(tr, 'startTime'))).getDay();
        return weekday === 0 || weekday === 6;
    });

    weekendRegistrations.forEach(tr => {
        let resourceId = apihelper.getRelId(tr, 'resource');
        let resource = storehelper.findById(resources, resourceId);
        let isPaidByTheHour = apihelper.getAttr(resource, 'isPaidByTheHour');
        let isPermanentlyEmployed = apihelper.getAttr(resource, 'isPermanentlyEmployed');

        let duration = timeRegistrationDuration(tr);
        lookupStructure[isPermanentlyEmployed][isPaidByTheHour][resourceId].registeredHoursInWeekends += duration;
    });

    // flatten structure to rows and return aggregate
    let collapsedRows = [];
    Object.keys(lookupStructure).forEach(isPermanentlyEmployed => {
        Object.keys(lookupStructure[isPermanentlyEmployed]).forEach(isPaidByTheHour => {
            Object.keys(lookupStructure[isPermanentlyEmployed][isPaidByTheHour]).forEach(resourceId => {
                let point = lookupStructure[isPermanentlyEmployed][isPaidByTheHour][resourceId];
                collapsedRows.push(Object.assign({}, point, { isPermanentlyEmployed, isPaidByTheHour, resourceId }));
            });
        });
    });

    return new UtilizationAggregate(collapsedRows, startTime, endTime);
}

import * as types from '../../constants/ActionTypes';
import {updateDtoList} from '../helpers';
import {getAllProjectStatus} from '../../selectors/actions/projectStatus';

const initialState = {
    storeRevision: 0,
    allProjectStatus: []
};

export default function projectStatusReducer(state = initialState, action) {
    switch (action.type) {

        case types.API_PROJECTSTATUS_GET_ALL_SUCCESS: {
            return updateDtoList(state, 'allProjectStatus', getAllProjectStatus(action));
        }

        default:
            return state;
    }
}

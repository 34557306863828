import BaseConnector from "./Base";
import * as datehelper from "../lib/date";

export default class LockedTimePeriodConnector extends BaseConnector {
  getAll(
    {
      resourceId,
      startDate,
      endDate,
    }: { resourceId?: string; startDate?: string; endDate?: string } = {},
    includes: string[]
  ) {
    const q: string[] = [];
    if (resourceId) {
      q.push("resource.id eq " + resourceId);
    }

    if (startDate) {
      q.push("startDate ge " + datehelper.toYyyyMmDd(new Date(startDate)));
    }
    if (endDate) {
      q.push("endDate le " + datehelper.toYyyyMmDd(new Date(endDate)));
    }
    const qs = q.join(" and ");

    includes = includes || [];
    const is = includes.join(",");

    const qp: string[] = [];
    is && qp.push("includes=" + is);
    qs && qp.push("filter=" + qs);

    return this.authFetch(
      this.baseApiUrl + "LockedTimePeriods/?" + qp.join("&")
    );
  }

  createLockedTimePeriod(timeRegistrationDto: object) {
    return this.authPost(
      this.baseApiUrl + "LockedTimePeriods",
      timeRegistrationDto
    );
  }

  deleteLockedTimePeriod(id: string) {
    return this.authDelete(this.baseApiUrl + "LockedTimePeriods/" + id);
  }
}

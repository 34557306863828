import {combineReducers} from 'redux';
import page from './page';
import api from './api';
import { history } from '../lib/history'
import writeBackQueue from './writeBackQueue';
import {connectRouter} from 'connected-react-router';
import app from './app';
import root from './root';

export default combineReducers({
    root,
    page,
    api,
    app,  
    writeBackQueue,
    router: connectRouter(history)
});

import * as apihelper from './apihelper';
import '../lib/unique';
import '../lib/groupBy';
import '../lib/setOperations';
import { ResourceObjects, ResourceObject } from '../lib/models';

export const findById = (entities: ResourceObjects, id: string): ResourceObject | undefined => 
    entities.find(entity => apihelper.entityHasId(entity, id));

export const findByAttr = (entities: ResourceObjects, attr: string, value: any): ResourceObject | undefined =>
    entities.find(entity => apihelper.getAttr(entity, attr) === value);

// Filters entitites by entityIds. entityIds is allowed to be a single value.
export const filterEntitiesByIds = (entities: ResourceObjects, entityIds: string | string[]): ResourceObjects => {
    if (Array.isArray(entityIds)) {
        return entities.filter(entity => entityIds.some(id => apihelper.entityHasId(entity, id)))
    } else {
        return entities.filter(entity =>  apihelper.entityHasId(entity, entityIds))
    }
};

// Find all mainEntities that are pointed to by related entities (ref -> main)
// mainEntities are the entities that are filtered as result
// relatedEntities are the entities that reference to the main entity
// relatedToMainRelationshipName is the name of the relationship that a related entity refers to a main entity
export const filterByRelatedEntity = (
    mainEntities: ResourceObjects, 
    relatedEntities: ResourceObject | ResourceObjects, 
    relatedToMainRelationshipName: string
): ResourceObjects => {
    const relEntitiesArray = (Array.isArray(relatedEntities) ? relatedEntities : [relatedEntities]);
    const mainEntityIds = relEntitiesArray.map(re => apihelper.getRelId(re, relatedToMainRelationshipName)).unique() as string[];
    const result = filterEntitiesByIds(mainEntities, mainEntityIds).filter(me => !!me);
    return result;
}

// find all entities that point to an entity with any of the provided ids (main -> id)
export const filterByReferencedIds = (
    entities: ResourceObjects,
    relationshipName: string,
    referenceIds: string[] | string
) => {
    const idsAsArray = Array.isArray(referenceIds) ? referenceIds : [referenceIds];
    const idLookup = idsAsArray.reduce((acc, cur) => { acc[cur] = true; return acc}, {} as Record<string, boolean>);
    return entities.filter(entity => idLookup[apihelper.getRelId(entity, relationshipName) as string]);
}

// Find all mainEntities that points to related entities (main -> ref)
export const filterByReferenceToRelatedEntities = (
    mainEntities: ResourceObjects, 
    relatedEntities: ResourceObjects | ResourceObject | undefined | null,
    mainToRelatedRelationshipName: string
): ResourceObjects => {
    const allowableReferenceIds = 
        (Array.isArray(relatedEntities) ? relatedEntities : [relatedEntities])
        .filter(x => !!x)
        .map(re => apihelper.getEntityId(re as ResourceObject)) as string[];
    return filterByReferencedIds(mainEntities, mainToRelatedRelationshipName, allowableReferenceIds);
}

export const filterIntersectingEntities = (
    entityList1: ResourceObjects, 
    entityList2: ResourceObjects
): ResourceObjects => {
    const byType1 = entityList1.groupBy(apihelper.getEntityType);
    const byType2 = entityList2.groupBy(apihelper.getEntityType);
    const allTypes = Array.from(byType1.keys()).concat(Array.from(byType2.keys())).unique();
    let result: ResourceObjects = [];
    allTypes.forEach(type => {
        if(byType1.has(type) && byType2.has(type)){
            const list1 = byType1.get(type) || [];
            const list2 = byType2.get(type) || [];
            const ids1 = list1.map(apihelper.getEntityId).unique();
            const ids2 = list2.map(apihelper.getEntityId);
            const intersectingIds = ids1.filter(id => ids2.includes(id));
            const intersectingEntities = list1.concat(list2).unique().filter(entity => intersectingIds.includes(apihelper.getEntityId(entity)))
            result = result.concat(intersectingEntities)
        }
    })
    return result;
}

// use this function to join two lists of the same kind of resources. doubles will be resolves by picking from the first list
export const unionEntities = (...lists: ResourceObjects[]): ResourceObjects => {
    const ids: Record<string, boolean> = {};
    return lists.reduce((accList, curList) => {
        const notInResult = curList.filter(entity => !ids[apihelper.getEntityId(entity) as string]);
        notInResult.forEach(entity => ids[apihelper.getEntityId(entity) as string] = true);
        return accList.concat(notInResult);
    }, []);
}

// use this function to get a new list of the entities that are shared between two lists of the same type of entities
export const intersectEntities = (
    listOfEntities1: ResourceObjects, 
    listOfEntities2: ResourceObjects
): ResourceObjects => {
    const ids1 = listOfEntities1.map(apihelper.getEntityId) as string[];
    const ids2 = listOfEntities2.map(apihelper.getEntityId) as string[];
    const shared = ids1.intersection(ids2); // ts(2339) is unavoidable, just ignore
    const everything = listOfEntities1.concat(listOfEntities2);
    return shared.map((id: string) => everything.find(e => apihelper.entityHasId(e, id))) as ResourceObjects;
}

export const sortByValues = <T>(
    func: (obj: T) => any
) => (entityA: T, entityB: T): number => {
    const entityNameA = entityA ? func(entityA) : '';
    const entityNameB = entityB ? func(entityB) : '';
    if (entityNameA > entityNameB) {
        return 1;
    } else if (entityNameA < entityNameB) {
        return -1;
    } else {
        return 0;
    }
};

export const sortByLocaleCompare = <T>(
    extractFunc: (obj: T) => any
) => (entityA: T, entityB: T): number => {
    const valA = entityA ? extractFunc(entityA) : '';
    const valB = entityB ? extractFunc(entityB) : '';
    return valA.localeCompare(valB);
}

type SortType = {
    value: number,
    name: string,
    code: string
}

export const SortingType: Record<string, SortType> = {
    LEXICAL: {value: 1, name: "Lexicographic", code: "L"},
    NUMERICAL: {value: 2, name: "Numerical", code: "N"},
    BOOLEAN: {value: 3, name: "Boolean", code: "B"},
};

type SortInfo<T, S> = {
    extract: (obj: T) => S,
    sortType: SortType | ((a: S, b: S) => number)
}

export const sortItemsAscending = <T, S>(items: Array<T>, sortInfo: SortInfo<T, S>) => {
    const {sortType, extract} = sortInfo;
    
    if(typeof sortType === 'function'){
        return items.slice(0).sort((a, b) => sortType(extract(a), extract(b)));
    }
    else {
        switch (sortType) {
            case SortingType.LEXICAL: 
                return items.slice(0).sort(sortByLocaleCompare(extract));
            case SortingType.NUMERICAL:
                return items.slice(0).sort(sortByValues(extract));
            case SortingType.BOOLEAN:
                return items.slice(0).sort((a, b) => {
                    const ea = extract(a);
                    const eb = extract(b);
                    return (typeof ea === 'number' ? ea : 0) - (typeof eb === 'number' ? eb : 0)
                });
            default:
                throw new Error(`Unknown SortingType ${sortType}`);
        }
    }
};

const getAttribute = (attrName: string) => (entity: ResourceObject) => {
    return (apihelper.getAttr(entity, attrName) as string).toLowerCase() 
};

export const sortByAttr = (attrName: string) => sortByValues( getAttribute(attrName) )

export const enumSort = (enumList: Array<any>) => (a: any, b: any) => enumList.indexOf(a) - enumList.indexOf(b);

import * as actionhelper from '../actionhelper';
import * as EntityTypes from '../../constants/EntityTypes';

export const getAllProjectList = actionhelper.apiResponseData;

export const getUpsertProject = actionhelper.apiResponseData;

export const getDeleteProjectId = actionhelper.apiSentModelId;

export const getIncludedProjects = (action: any) => actionhelper.apiResponseIncludedOfType(action, EntityTypes.PROJECT)

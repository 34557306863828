import React, { useMemo } from "react";
import { TextField } from "@fluentui/react/lib/TextField";
import { SingleBaseFormProps } from "./SingleBaseForm";
import { createEmptyModel } from "../../lib/emptyModelCreation";
import { NewResourceObject, ResourceObject } from "../../lib/models";
import {
  FieldValue,
  PublicFields,
  useFormFields,
} from "../../hooks/formfields";
import * as apihelper from "../../selectors/apihelper";
import Grid from "../grid/Grid";
import Row from "../grid/Row";
import { FormButtonBar } from "./FormButtonBar";
import { ModelTimestamps } from "./ModelTimestamps";
import FormCommand from "../../lib/FormCommand";

type ClientFormProps = SingleBaseFormProps & {
  model: ResourceObject;
};

export default function ClientForm({
  model,
  onSave,
  onDismiss,
}: ClientFormProps) {
  const [fields, update, isValid, isChanged, copyToModel] = useFormFields(
    {
      name: {
        fromModel: (model: ResourceObject) =>
          apihelper.getAttr(model, "name") as string,
        isRequired: true,
        copyToModel: (
          model: NewResourceObject,
          currentValue: FieldValue<string>
        ) => apihelper.setAttr(model, "name", currentValue.value),
        validate: (fields: PublicFields) => {
          const isValid =
            typeof fields["name"].value === "string" &&
            fields["name"].value.trim().length > 0;
          return {
            isValid,
            validationMessage: !isValid ? "Name cannot be empty" : undefined,
          };
        },
      },
      description: {
        fromModel: (model: ResourceObject) =>
          apihelper.getAttr(model, "description") as string,
        copyToModel: (
          model: NewResourceObject,
          currentValue: FieldValue<string>
        ) => apihelper.setAttr(model, "description", currentValue.value),
      },
      billingClientName: {
        fromModel: (model: ResourceObject) =>
          apihelper.getAttr(model, "billingClientName") as string,
        copyToModel: (
          model: NewResourceObject,
          currentValue: FieldValue<string>
        ) => apihelper.setAttr(model, "billingClientName", currentValue.value),
      },
      billingClientNumber: {
        fromModel: (model: ResourceObject) =>
          apihelper.getAttr(model, "billingClientNumber") as string,
        copyToModel: (
          model: NewResourceObject,
          currentValue: FieldValue<string>
        ) =>
          apihelper.setAttr(model, "billingClientNumber", currentValue.value),
      },
    },
    model
  );

  const iconPropsFieldChanged = { iconName: "FieldChanged" };
  const iconPropsFieldUnchanged = { iconName: "FieldNotChanged" };
  const isUpdate = useMemo(() => apihelper.isPersistedEntity(model), [model]);

  const produceFormCommands = () => {
    const changes = createEmptyModel(apihelper.getEntityType(model));
    copyToModel(changes);
    return [new FormCommand(model, false, changes)];
  };

  return (
    <div className="novatime-client-form">
      <h2>{isUpdate ? "Edit" : "Create"} Client</h2>
      <Grid>
        <Row>
          <TextField
            label="Name"
            iconProps={
              fields.name.isChanged
                ? iconPropsFieldChanged
                : iconPropsFieldUnchanged
            }
            onChange={(
              _: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
              newValue?: string
            ) => update("name", newValue)}
            required={fields.name.isRequired}
            value={fields.name.value}
            errorMessage={fields.name.validationMessage}
          />
        </Row>
        <Row>
          <TextField
            label="Description"
            iconProps={
              fields.description.isChanged
                ? iconPropsFieldChanged
                : iconPropsFieldUnchanged
            }
            onChange={(
              _: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
              newValue?: string
            ) => update("description", newValue)}
            required={fields.description.isRequired}
            multiline
            rows={4}
            value={fields.description.value}
            errorMessage={fields.description.validationMessage}
          />
        </Row>
        <Row>
          <TextField
            label="Client Billing Name"
            iconProps={
              fields.billingClientName.isChanged
                ? iconPropsFieldChanged
                : iconPropsFieldUnchanged
            }
            onChange={(
              _: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
              newValue?: string
            ) => update("billingClientName", newValue)}
            required={fields.billingClientName.isRequired}
            value={fields.billingClientName.value}
            errorMessage={fields.billingClientName.validationMessage}
          />
        </Row>
        <Row>
          <TextField
            label="Client Billing Number"
            iconProps={
              fields.billingClientNumber.isChanged
                ? iconPropsFieldChanged
                : iconPropsFieldUnchanged
            }
            onChange={(
              _: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
              newValue?: string
            ) => update("billingClientNumber", newValue)}
            required={fields.billingClientNumber.isRequired}
            value={fields.billingClientNumber.value}
            errorMessage={fields.billingClientNumber.validationMessage}
          />
        </Row>
        <Row>
          <FormButtonBar
            isSaveDisabled={!isValid || !isChanged}
            onSave={() => onSave && onSave(produceFormCommands())}
            onDismiss={onDismiss}
          />
          <ModelTimestamps model={model} />
        </Row>
      </Grid>
    </div>
  );
}

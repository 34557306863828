import * as types from '../constants/ActionTypes';
import ModelMergeQueue from '../lib/ModelMergeQueue';
import { getUpsertTimeRegistration } from '../selectors/actions/timeRegistration';
import { getEntityId } from '../selectors/apihelper';
import { getTransactionId } from '../selectors/actionhelper';

const WRITEBACK_INACTIVE    = 'inactive';
const WRITEBACK_INIT        = 'initiated';
const WRITEBACK_RUNNING     = 'running';

type State = {
    serializedQueue: Array<any>,
    writeBackState: 'inactive' | 'initiated' | 'running'
}

const initialState: State = {
    serializedQueue: [],
    writeBackState: WRITEBACK_INACTIVE
}

const getQueue = (state: State) => ModelMergeQueue.deserialize(state.serializedQueue);

const withUpdatedQueue = (state: State, qFunc: (queue: ModelMergeQueue) => void, adds = {}) => {
    const queue = getQueue(state);
    qFunc(queue); // queue is now changed
    const serializedQueue = queue.serialize();
    const result = Object.assign({}, state, adds, { serializedQueue });
    return result;
}

export default function writeBackQueueReducer(state: State = initialState, action: any) {
    switch (action.type) {
        case types.WRITEBACK_QUEUE_ADD_CHANGE_TO_QUEUE_CHANGE: {
            const {change, model} = action.payload;
            return withUpdatedQueue(state, queue => {
                queue.addChange(change, model);
            })
        }

        case types.WRITEBACK_QUEUE_CLAIM_MODEL_FOR_WRITEBACK_CHANGE: {
            const {model, claimIdentifier} = action.payload;
            return withUpdatedQueue(state, queue => {
                queue.scheduleChange(model, claimIdentifier);
            })
        }

        case types.WRITEBACK_QUEUE_WRITEBACK_INIT: {
            return Object.assign({}, state, { writeBackState: WRITEBACK_INIT });
        }

        case types.WRITEBACK_QUEUE_WRITEBACK_STARTED: {
            return Object.assign({}, state, { writeBackState: WRITEBACK_RUNNING });
        }

        case types.API_TIMEREGISTRATION_CREATE_SUCCESS: {
            const claimIdentifier = getTransactionId(action);
            if(claimIdentifier){
                return withUpdatedQueue(state, queue => {
                    queue.notifyCreateId(claimIdentifier, getEntityId(getUpsertTimeRegistration(action)) as string);
                });
            }
            return state;
        }

        case types.WRITEBACK_QUEUE_WRITEBACK_COMPLETE: {
            const { success, claimIdentifier, storeModel, newMergedModel } = action.payload;
            return withUpdatedQueue(state, queue => {
                if(success){
                    queue.clearScheduledChange(claimIdentifier, storeModel, newMergedModel);
                }
                else {
                    queue.rollbackScheduledChange(claimIdentifier);
                }
            }, { writeBackState: WRITEBACK_INACTIVE });
        }

        case types.WRITEBACK_QUEUE_ROLLBACK_ALL_QUEUED_CHANGES:
        {
            return withUpdatedQueue(state,
                queue => {
                    queue.reset();
                });
        }

        case types.WRITEBACK_QUEUE_ROLLBACK_ALL_QUEUED_INVALID_CHANGES:
        {
            return withUpdatedQueue(state,
                queue => {
                    queue.resetInvalid();
                });
        }

        default:
            return state;
    }
}

import {createSelector} from "reselect";
import * as apihelper from "../apihelper";
import * as storehelper from '../storehelper';
import * as AbsencePeriodTypesName from '../../constants/AbsencePeriodTypeNames';
import { ResourceObjects } from "../../lib/models";

export const allAbsencePeriodTypes = (state: any) => state.api.absencePeriodType.allAbsencePeriodType as ResourceObjects;

export const filterById = (absencePeriodTypes: ResourceObjects, absencePeriodTypeId: string) =>
    storehelper.findById(absencePeriodTypes, absencePeriodTypeId);

export const filterByName = (absencePeriodTypes: ResourceObjects, absencePeriodTypeName: string) =>
absencePeriodTypes.find(abt => apihelper.getAttr(abt, 'name') === absencePeriodTypeName);

export const absencePeriodTypeVacation = createSelector(
    [allAbsencePeriodTypes],
    (absencePeriodTypes) => filterByName(absencePeriodTypes, AbsencePeriodTypesName.VACATION.name)
)

export const absencePeriodTypeParentalLeave = createSelector(
    [allAbsencePeriodTypes],
    (absencePeriodTypes) => filterByName(absencePeriodTypes, AbsencePeriodTypesName.PARENTALLEAVE.name)
)

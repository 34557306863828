import {put, select, takeLatest} from 'redux-saga/effects';
import * as types from '../../../constants/ActionTypes';
import * as absencePeriodCommentActions from '../../../actions/api/absencePeriodcomments';
import * as absencePeriodSelectors from '../../../selectors/reducers/absencePeriod';

function* doLoadComments(action) {
    let absencePeriodId = action.payload.id;
    let absencePeriod = yield select(absencePeriodSelectors.absencePeriodById, absencePeriodId);
    yield put(absencePeriodCommentActions.getAllAbsencePeriodComments(absencePeriod));
}

export default function* loadComments() {
    yield takeLatest(types.APP_PAGE_ABSENCEPERIODS_LOAD_COMMENTS, doLoadComments);
}

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Stack } from '@fluentui/react';

const bigCenteredNumberStyle = {
    root: {
        textAlign: 'center',
        fontWeight: 'bold'
    }
};
const smallCenteredFieldDescription = {
    root: {
        textAlign: 'center',
        color: 'gray'
    }
};
const themedSmallStackTokens = {
    childrenGap: 's1',
    padding: 's1',
};

export default class KpiStack extends Component {
    static propTypes = {
        kpis: PropTypes.arrayOf(PropTypes.shape({
            title: PropTypes.string,
            value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
        }))
    };

    static defaultProps = {
        kpis: []
    };

    render(){
        return (
            <Stack horizontal horizontalAlign="space-evenly">
                {
                    this.props.kpis.map((kpi, idx) => 
                        <Stack key={`${kpi.title}-${idx}`} tokens={themedSmallStackTokens}>
                            <Stack.Item styles={bigCenteredNumberStyle}>
                            {kpi.value}
                            </Stack.Item>
                            <Stack.Item styles={smallCenteredFieldDescription} title={kpi.description}>
                            {kpi.title}
                            </Stack.Item>
                        </Stack>
                    )
                }
            </Stack>
        );
    }
}
import React from 'react'; 
import { ActivityItem, Icon, IIconProps } from '@fluentui/react';
import * as icons from '../../constants/Icons';
import { Notification, NotificationLevel, NotificationType } from '../../reducers/app/notification';

type NotificationActivityItemProps = {
    notification: Notification,
    onClick?: React.MouseEventHandler<HTMLElement>
};

const typeToIconProps = (type: NotificationType): IIconProps => {
    return ({
        [NotificationType.ApiCallPost]: { iconName: icons.ICON_NAME_UPLOAD, title: "Data is being created" },
        [NotificationType.ApiCallGet]: {iconName: icons.ICON_NAME_DOWNLOAD, title: "Fetching data"},
        [NotificationType.ApiCallPatch]: {iconName: icons.ICON_NAME_UPLOAD, title: "Changes are being uploaded"},
        [NotificationType.ApiCallDelete]: {iconName: icons.ICON_NAME_DELETE, title: "Data is being deleted"}
    })[type];
}

export const NotificationActivityItem = ({notification, onClick}: NotificationActivityItemProps) => {
    const iconProps = typeToIconProps(notification.type);
    const iconClass = ({
        [NotificationLevel.Info]: '',
        [NotificationLevel.Error]: "icon-color-red",
        [NotificationLevel.Warning]: 'icon-color-yellow'
    })[notification.level];
    iconProps.className = iconClass || undefined;
    
    const message = notification.content.message;
    const hasSimpleMessage = typeof message === 'string';
    const activityDescription = hasSimpleMessage ? message : message.title;
    const comment = hasSimpleMessage ? '' : message.description;

    return (
        <ActivityItem 
            onClick={onClick}
            activityDescription={activityDescription}
            comments={comment}
            activityIcon={<Icon {...iconProps}/>}
            timeStamp={notification.timestamp}
        />
    )
}

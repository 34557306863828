import React, { Component } from "react";
import { Label } from "@fluentui/react/lib/Label";
import DatePicker from "./DatePicker";
import PropTypes from "prop-types";
import moment from "moment";

export default class WeekPicker extends Component {
  constructor(props) {
    super(props);
    this._onSelectDate = this._onSelectDate.bind(this);
    this._formatDate = this._formatDate.bind(this);
    this._onLeftClick = this._onLeftClick.bind(this);
    this._onRightClick = this._onRightClick.bind(this);
  }

  _onSelectDate(date) {
    if (this.props.onSelectWeek) {
      this.props.onSelectWeek(moment(date).startOf("isoWeek").toDate());
    }
  }

  _formatDate(date) {
    let d = moment(date);
    let startWeek = d.startOf("isoWeek").format("dddd, MMMM Do");
    let endWeek = d.endOf("isoWeek").format("dddd, MMMM Do");
    return (
      "Week " +
      d.isoWeek() +
      ", " +
      d.year() +
      ". From " +
      startWeek +
      " to " +
      endWeek
    );
  }

  _onLeftClick() {
    if (this.props.onSelectWeek && this.props.value) {
      this.props.onSelectWeek(
        moment(this.props.value).startOf("isoWeek").subtract(1, "week").toDate()
      );
    }
  }

  _onRightClick() {
    if (this.props.onSelectWeek && this.props.value) {
      this.props.onSelectWeek(
        moment(this.props.value).startOf("isoWeek").add(1, "week").toDate()
      );
    }
  }

  render() {
    var { label, ...other } = this.props;

    return (
      <div className="novatime-weekpicker">
        {label ? <Label>{label}</Label> : undefined}
        <DatePicker
          {...other}
          showWeekNumbers={true}
          onSelectDate={this._onSelectDate}
          showMonthPickerAsOverlay={true}
          formatDate={this._formatDate}
          onRightClick={this._onRightClick}
          onLeftClick={this._onLeftClick}
        />
      </div>
    );
  }
}

WeekPicker.propTypes = {
  onSelectWeek: PropTypes.func,
};

import * as types from '../../constants/ActionTypes';

export function createContractRole(contractRole) {
    return {
        type: types.API_CONTRACTROLE_CREATE_INIT,
        payload: {model: contractRole}
    };
}

export function deleteContractRole(contractRole) {
    return {
        type: types.API_CONTRACTROLE_DELETE_INIT,
        payload: {model: contractRole}
    };
}

export function getAllContractRoles(contract) {
    return {
        type: types.API_CONTRACTROLE_GET_ALL_INIT,
        payload: {
            contract
        }
    };
}

export function updateContractRole(contractRole, modelChanges) {
    return {
        type: types.API_CONTRACTROLE_UPDATE_INIT,
        payload: {
            model: contractRole,
            changes: modelChanges
        }
    };
}

import * as types from "../../constants/ActionTypes";
import { ResourceObject } from "../../lib/models";

export function getAllAbsencePeriods(
  earliestStart: Date | null = null,
  resourceId: string | null = null,
  latestStart: Date | null = null,
  absencePeriodStatusId: string | null = null
) {
  return {
    type: types.API_ABSENCEPERIOD_GET_ALL_INIT,
    payload: {
      earliestStart:
        earliestStart instanceof Date
          ? earliestStart.toISOString()
          : earliestStart,
      latestStart:
        latestStart instanceof Date ? latestStart.toISOString() : latestStart,
      resourceId,
      absencePeriodStatusId,
    },
  };
}

export function createAbsencePeriod(dto: ResourceObject) {
  return {
    type: types.API_ABSENCEPERIOD_CREATE_INIT,
    payload: { model: dto },
  };
}

export function updateAbsencePeriod(
  dto: ResourceObject,
  modelChanges: ResourceObject
) {
  return {
    type: types.API_ABSENCEPERIOD_UPDATE_INIT,
    payload: {
      model: dto,
      changes: modelChanges,
    },
  };
}

export function deleteAbsencePeriod(dto: ResourceObject) {
  return {
    type: types.API_ABSENCEPERIOD_DELETE_INIT,
    payload: { model: dto },
  };
}

import * as types from "../../constants/ActionTypes";

export type GetAllWorkLocationsAction = {
  type: string;
  payload: {
    parameters: Record<string, never>;
  };
};

export const getAllWorkLocations = (): GetAllWorkLocationsAction => ({
  type: types.API_WORKLOCATON_GET_ALL_INIT,
  payload: { parameters: {} },
});


import { put } from "redux-saga/effects"
import * as types from '../../../constants/ActionTypes';
import { takeLatestAfterBootstrap } from "../../helpers";
import * as projectsAndRolesActions from "../../../actions/pages/projectsAndRoles"

function* doFetchClientsOfCurrentAccount(){  
        yield put( projectsAndRolesActions.selectProject(null) );
        yield put( projectsAndRolesActions.selectClient(null) ); 
        yield put({ type: types.APP_PAGE_PROJECTS_AND_ROLES_LOAD_CLIENTS} );
}

export default function* fetchClientOfCurrentAccount(){
    yield takeLatestAfterBootstrap(types.APP_SET_SELECTED_ACCOUNT,doFetchClientsOfCurrentAccount)
}

import * as SagaHelpers from '../helpers';
import * as SagaEffects from 'redux-saga/effects';
import * as types from '../../constants/ActionTypes';
import PermissionConnector from '../../connectors/Permission';

const createPermission = SagaHelpers.createApiHandler(
    types.API_PERMISSION_CREATE_INIT, 
    SagaHelpers.generateCreateApiCall(
        PermissionConnector,
        function* (connector: PermissionConnector, action: any): any {
            return yield SagaEffects.call([connector, connector.createPermission], {
                data: action.payload.model
            });
        }
    )
)

const deletePermission = SagaHelpers.createApiHandler(
    types.API_PERMISSION_DELETE_INIT, 
    SagaHelpers.generateDeleteApiCall(
        PermissionConnector,
        function* (connector: PermissionConnector, action: any): any {
            return yield SagaEffects.call([connector, connector.deletePermission], action.payload.model.id);
        }
    )
)

const getAllPermissions = SagaHelpers.createApiHandler(
    types.API_PERMISSION_GET_ALL_INIT, 
    SagaHelpers.generateGetAllApiCall(
        PermissionConnector,
        function* (connector: PermissionConnector, action: any): any {
            const accountId = action.payload.account ? action.payload.account.id : null;
            return yield SagaEffects.call([connector, connector.getPermissionsByAccountId], accountId);
        }
    )
)

export default function* permissionSaga() {
    yield SagaEffects.all([
        SagaEffects.fork(getAllPermissions),
        SagaEffects.fork(createPermission),
        SagaEffects.fork(deletePermission),
    ]);
}

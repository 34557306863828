import {all, fork} from 'redux-saga/effects';
import projectsAndRolesOpened from './pageOpened';
import projectsAndRolesLoadClients  from './loadClients';
import projectsAndRolesClientSelected from './clientSelected';
import projectsAndRolesProjectSelected from './projectSelected';
import fetchClientsOnAccountSelect from './fetchClients'
import projectDeleted from './projectDeleted';

export default function* pageProjectAndRolesRootSaga() {
    yield all([
        fork(projectsAndRolesOpened),
        fork(projectsAndRolesLoadClients),
        fork(projectsAndRolesClientSelected),
        fork(projectsAndRolesProjectSelected),
        fork(fetchClientsOnAccountSelect),
        fork(projectDeleted)
    ]);
}

/* tslint:disable:no-unused-variable */
import React, { Component } from 'react'; 
import "../lib/setOperations";
import DetailsListBasic from "./DetailsListBasic";
import { sortItemsAscending } from '../selectors/storehelper';


export default class SorterList extends Component {
    constructor(props) {
        super(props);
        this._onColumnClick = this._onColumnClick.bind(this);
        this.state = {
            sortedColumnIndex: undefined,
            sortedDescending: undefined,
        }
    }

    _onColumnClick = (ev, column) => {
        const {columnSorters, columns} = this.props;
        if (columnSorters[column.fieldName] === undefined) {
            return;
        }

        const index = columns.indexOf(columns.find(c => c.fieldName === column.fieldName));

        this.setState({
            sortedDescending: column.isSorted ? !column.isSortedDescending : false,
            sortedColumnIndex: index,
        });
    };

    // Finds the indicies in items for the deepest levels in the group object. These indicies divides items
    // into blocks, which can be sorted individually without destroying the group structure (this is done
    // in _sortInBlocks).
    _getBlocksFromGroups = (groups, lst=[0]) => {
        groups.forEach(group => {
            if (group.children.length == 0) {
                lst.push(lst.at(-1) + group.count);
            }
            else {
                this._getBlocksFromGroups(group.children, lst)
            }
        })
        return lst;
    };

    _sortInBlocks = (items, sortInfo, groups, sortedDescending) => {
        let blockDividers = this._getBlocksFromGroups(groups, [0]);
        let newItems = [];

        for (let i=1; i<blockDividers.length; i++) {
            let sorted = sortItemsAscending(items.slice(blockDividers[i-1], blockDividers[i]), sortInfo)
            sortedDescending ?  newItems.push(sorted.reverse()) : newItems.push(sorted);
        }
        return newItems.flat();
    };

    static getDerivedStateFromProps(props,state){
        let {initialColumnSorterIndex} = props;
        const {sortedColumnIndex} = state;
         
        if (sortedColumnIndex == undefined){
            return {sortedColumnIndex:initialColumnSorterIndex};
        }
        return null;
    }

    render() {
        const {items, columns, columnSorters, groups, ...other} = this.props;
        const {sortedColumnIndex, sortedDescending} = this.state;

        let newCols = columns.map(c => Object.assign({isSorted: false, onColumnClick: this._onColumnClick}, c))
        let newItems = items.slice();

        if (sortedColumnIndex > -1) {
            // Adjust column decoration
            newCols[sortedColumnIndex].isSorted = true;
            newCols[sortedColumnIndex].isSortedDescending = sortedDescending;
            const sortedCol = columns[sortedColumnIndex].fieldName;
            // Adjust list items
            if (groups) {
                newItems = this._sortInBlocks(newItems, columnSorters[sortedCol], groups, sortedDescending);
            } else {
                let sorted = sortItemsAscending(newItems, columnSorters[sortedCol]);
                newItems = sortedDescending ? sorted.reverse() : sorted;
            }
        }
        return (
            <DetailsListBasic
                {...other}
                items={newItems}
                columns={newCols}
                columnSorters={columnSorters}
                groups = {groups}
            />
        );
    }
}

import {all, fork} from 'redux-saga/effects';
import timeRegistrationPageOpened from './pageOpened';
import timeRegistrationResourceSelected from './resourceSelected';
import fetchWeekRegistrations from './fetchWeekRegistrations';
import weekSelected from './weekSelected';
import timeRegistrationDeleteRegistration from './deleteRegistration';
import clearSelectionDelay from './clearSelection';
import lockTimePeriod from './lockPeriod';
import checkLockedLastWeek from './checkLockedLastWeek'
import makeChangeToCurrentEdit from './makeChangeToCurrentEdit';

export default function* pageRootSaga() {
    yield all([
        fork(timeRegistrationPageOpened),
        fork(timeRegistrationResourceSelected),
        fork(fetchWeekRegistrations),
        fork(weekSelected),
        fork(timeRegistrationDeleteRegistration),
        fork(clearSelectionDelay),
        fork(lockTimePeriod),
        fork(checkLockedLastWeek),
        fork(makeChangeToCurrentEdit)
    ]);
}

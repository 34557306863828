import * as types from '../../constants/ActionTypes';

export function createAbsenceRegistrationProject(absenceRegistrationProject) {
    return {
        type: types.API_ABSENCEREGISTRATIONPROJECT_CREATE_INIT,
        payload: {model: absenceRegistrationProject}
    };
}

export function deleteAbsenceRegistrationProject(absenceRegistrationProject) {
    return {
        type: types.API_ABSENCEREGISTRATIONPROJECT_DELETE_INIT,
        payload: {model: absenceRegistrationProject}
    };
}

export function getAllAbsenceRegistrationProjects(includes) {
    return {
        type: types.API_ABSENCEREGISTRATIONPROJECT_GET_ALL_INIT,
        payload: {
            includes: includes
        }
    };
}



export function updateAbsenceRegistrationProject(absenceRegisrationProject, modelChanges) {
    return {
        type: types.API_ABSENCEREGISTRATIONPROJECT_UPDATE_INIT,
        payload: {
            model: absenceRegisrationProject,
            changes: modelChanges
        }
    };
}

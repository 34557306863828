import React, { Component } from "react";
import { connect } from "react-redux";
import { Dispatch, bindActionCreators } from "redux";
import { Redirect, Route } from "react-router-dom";
import { bootstrap } from "../actions/app";
import Home from "../containers/pages/Home";
import Login from "../containers/auth/Login";
import Logout from "../containers/auth/Logout";
import TimeRegistration from "../containers/pages/TimeRegistration";
import ResourceManagement from "../containers/pages/ResourceManagement";
import Header from "../containers/common/Header";
import Footer from "../containers/common/Footer";
import ClientsAndContracts from "../containers/pages/ClientsAndContracts";
import ProjectsAndRoles from "../containers/pages/ProjectsAndRoles";
import UsersAndResources from "../containers/pages/UsersAndResources";
import AbsencePeriods from "../containers/pages/AbsencePeriods";
import TimeRegistrationExport from "../containers/pages/TimeRegistrationExport";
import { initializeIcons } from "@fluentui/react/lib/Icons";
import UrlContext, { UrlConfig } from "../contexts/UrlContext";
import CurrentUserContext, {
  CurrentUserConfig,
} from "../contexts/CurrentUserContext";
import * as url_consts from "../constants/Urls";
import { isCheckingAuth, isLoggedIn } from "../selectors/reducers/auth";
import { urls } from "../selectors/reducers/root";
import { isAppReady } from "../selectors/reducers/root";
import { currentUser } from "../selectors/reducers/user";
import * as permissionSelectors from "../selectors/reducers/permission";
import EntityEditor from "./EntityEditor";
import { UserPermissionSet } from "../lib/PermissionSet";
import { ResourceObject } from "../lib/models";

require("../stylesheets/app.scss");

initializeIcons(undefined, { disableWarnings: true });

type NovatimeStateProps = {
  currentUserPermissionSet: UserPermissionSet;
  isAppReady: boolean;
  isLoggedIn: boolean;
  isCheckingAuth: boolean;
  currentUser: ResourceObject | null;
  urls: UrlConfig;
};

type NovatimeOwnProps = Record<string, never>;

type NovatimeDispatchProps = {
  actions: {
    bootstrap: () => void;
  };
};

type NovatimeProps = NovatimeStateProps &
  NovatimeOwnProps &
  NovatimeDispatchProps;

class Novatime extends Component<NovatimeProps> {
  componentDidMount() {
    if (this.props.actions.bootstrap) {
      this.props.actions.bootstrap();
    }
  }

  render() {
    const {
      currentUserPermissionSet,
      isAppReady,
      isLoggedIn,
      isCheckingAuth,
      currentUser,
    } = this.props;
    const currentPath = window.location.pathname;
    const currentUserContextObj: CurrentUserConfig = {
      isLoggedIn,
      user: currentUser,
      currentUserPermissionSet,
    };

    const doRedirectToHome =
      ![
        url_consts.HOME_RELATIVE,
        url_consts.LOGIN_RELATIVE,
        url_consts.LOGOUT_RELATIVE,
      ].includes(currentPath) &&
      !isLoggedIn &&
      !isCheckingAuth &&
      isAppReady;
    const doRedirectToTimeReg =
      url_consts.HOME_RELATIVE === currentPath && isLoggedIn && isAppReady;
    return (
      <UrlContext.Provider value={this.props.urls}>
        <CurrentUserContext.Provider value={currentUserContextObj}>
          <div className="novatime-app">
            <div className="novatime-app-all-but-footer">
              <Header />
              <EntityEditor />

              <div className="main-content">
                {/* Go home if not logged in */}
                {doRedirectToHome ? (
                  <Redirect to={url_consts.HOME_RELATIVE} />
                ) : undefined}
                {/* Go to time registrations if logged and in / */}
                {doRedirectToTimeReg ? (
                  <Redirect to={url_consts.TIMEREGISTRATION_RELATIVE} />
                ) : undefined}
                <Route exact path={url_consts.HOME_RELATIVE} component={Home} />
                <Route path={url_consts.LOGIN_RELATIVE} component={Login} />
                <Route path={url_consts.SWITCH_USER}>
                  <Login selectAccount={true} />
                </Route>
                <Route path={url_consts.LOGOUT_RELATIVE} component={Logout} />
                <Route
                  path={url_consts.TIMEREGISTRATION_RELATIVE}
                  component={TimeRegistration}
                />
                <Route
                  path={url_consts.RESOURCEMANAGEMENT_RELATIVE}
                  component={ResourceManagement}
                />
                <Route
                  path={url_consts.CONTRACTSANDCLIENTS_RELATIVE}
                  component={ClientsAndContracts}
                />
                <Route
                  path={url_consts.PROJECTSANDROLES_RELATIVE}
                  component={ProjectsAndRoles}
                />
                <Route
                  path={url_consts.USERSANDRESOURCES_RELATIVE}
                  component={UsersAndResources}
                />
                <Route
                  path={url_consts.ABSENCEPERIODS_RELATIVE}
                  component={AbsencePeriods}
                />
                <Route
                  path={url_consts.TIMEREGEXPORT_RELATIVE}
                  component={TimeRegistrationExport}
                />
              </div>
            </div>
            <Footer />
          </div>
        </CurrentUserContext.Provider>
      </UrlContext.Provider>
    );
  }
}

function mapStateToProps(state: any): NovatimeStateProps {
  return {
    isLoggedIn: isLoggedIn(state),
    isCheckingAuth: isCheckingAuth(state),
    isAppReady: isAppReady(state),
    urls: urls(state),
    currentUser: currentUser(state),
    currentUserPermissionSet:
      permissionSelectors.currentUserPermissionSet(state),
  };
}

function mapDispatch(dispatch: Dispatch): NovatimeDispatchProps {
  const actions = { bootstrap };
  return { actions: bindActionCreators(actions, dispatch) };
}

export default connect<
  NovatimeStateProps,
  NovatimeDispatchProps,
  NovatimeOwnProps
>(
  mapStateToProps,
  mapDispatch
)(Novatime);

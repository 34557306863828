import * as React from "react";
import PropTypes from "prop-types";

//Fabric
import { assign } from "@fluentui/react/lib/Utilities";
import { CommandBar } from "@fluentui/react/lib/CommandBar";

export default class NavBar extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
  }

  render() {
    const {
      items,
      overflowItems,
      farItems,
      isSearchBoxVisible,
      areIconsVisible,
      areItemsEnabled,
      areNamesVisible,
    } = this.props;
    let { filteredItems, filteredOverflowItems, filteredFarItems } = [];

    if (items) {
      filteredItems = items.map((item: any) =>
        assign({}, item, {
          iconOnly: !areNamesVisible,
          icon: areIconsVisible ? item.icon : "",
          disabled: !areItemsEnabled,
        })
      );
    }

    if (overflowItems) {
      filteredOverflowItems = overflowItems.map((item: any) =>
        assign({}, item, {
          iconOnly: !areNamesVisible,
          icon: areIconsVisible ? item.icon : "",
          disabled: !areItemsEnabled,
        })
      );
    }

    if (farItems) {
      filteredFarItems = farItems.map((item: any) =>
        assign({}, item, {
          iconOnly: !areNamesVisible,
          icon: areIconsVisible ? item.icon : "",
          disabled: !areItemsEnabled,
        })
      );
    }

    return (
      <CommandBar
        isSearchBoxVisible={isSearchBoxVisible}
        searchPlaceholderText="Search..."
        elipisisAriaLabel="More options"
        items={filteredItems}
        overflowItems={filteredOverflowItems}
        farItems={filteredFarItems}
        className="novatime-header-navbar"
      />
    );
  }
}

NavBar.propTypes = {
  items: PropTypes.array,
  isSearchBoxVisible: PropTypes.bool,
  areNamesVisible: PropTypes.bool,
  areIconsVisible: PropTypes.bool,
  areItemsEnabled: PropTypes.bool,
  overflowItems: PropTypes.array,
  farItems: PropTypes.array,
};

import {put, putResolve, select, takeEvery} from 'redux-saga/effects';
import * as types from '../../constants/ActionTypes';
import {queue} from '../../selectors/reducers/writeBackQueue'

function* doAddChange(action) {

    let q = yield select(queue);
    let ch = q.findInQueue(action.payload.model);
    let original = ch ? ch.original : action.payload.model;
    let mergedBefore = ch ? ch.merged : action.payload.model;

    yield putResolve({
        type: types.WRITEBACK_QUEUE_ADD_CHANGE_TO_QUEUE_CHANGE, 
        payload: action.payload
    });
    q = yield select(queue);
    let ch2 = q.findInQueueByOriginalModel(ch ? ch.original : action.payload.model);
    yield put({
        type: types.WRITEBACK_QUEUE_ADD_CHANGE_TO_QUEUE_DONE,
        payload: {
            original,
            mergedBefore,
            mergedAfter: ch2.merged,
            change: action.payload.change,
            foundOriginalInQueue: !!ch
        }
    })
}

export default function* addChange() {
    yield takeEvery(types.WRITEBACK_QUEUE_ADD_CHANGE_TO_QUEUE_INIT, doAddChange);
}

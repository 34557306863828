import * as EntityTypes from "../../constants/EntityTypes";
import { ResourceObject } from "../../lib/models";
import * as apihelper from "../../selectors/apihelper";

const filterByOperationType = (requests: any, operationTypes: string[]) =>
  requests.filter((r: any) =>
    operationTypes.includes(r.initAction.type.split("_").slice(2, -1).join("_"))
  );

export const filterByModel = (requests: any, model: ResourceObject) =>
  filterByModelTypeAndId(
    requests,
    apihelper.getEntityType(model),
    apihelper.getEntityId(model) as string
  );

export const filterByModelTypeAndId = (
  requests: any,
  modelType: string,
  modelId: string
) =>
  requests.filter((d: any) => {
    const entity = d.initAction.payload.model;
    return (
      apihelper.entityHasType(entity, modelType) &&
      apihelper.entityHasId(entity, modelId)
    );
  });

export const liveRequests = (state: any) => state.app.requests.liveRequests;

export const liveDeleteRequests = (state: any) =>
  filterByOperationType(liveRequests(state), ["DELETE"]);

const liveRequestsByEntityType = (state: any, entityType: string) =>
  liveRequests(state).filter(
    (r: any) => r.initAction.type.split("_")[1] === entityType.toUpperCase()
  );

export const liveTimeRegistrationWriteRequests = (state: any) =>
  filterByOperationType(
    liveRequestsByEntityType(state, EntityTypes.TIMEREGISTRATION),
    ["UPDATE", "CREATE", "DELETE"]
  );

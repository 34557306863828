import * as billableTypes from '../constants/BillabilityTypes'
import { getAttr, getEntityId } from '../selectors/apihelper';


export default class BillableSet {
    constructor(billableTypeEntities) {
        this.billableTypeEntities = billableTypeEntities
    }

    static isEntityBillable(entity) {
        return billableTypes.ALL_BILLABLE.includes(getAttr(entity, 'name'))
    }

    static isEntityBillablityTypeExternal(entity) {
        return billableTypes.EXTERNAL === getAttr(entity, 'name')
    }

    static isEntityBillableTypeHourlyRate(entity) {
        return billableTypes.HOURLY_RATE === getAttr(entity, 'name')
    }

    static isEntityBillableTypeBroker(entity) {
        return billableTypes.BROKER === getAttr(entity, 'name')
    }

    static isEntityNotBillable(entity) {
        return billableTypes.NOT_BILLABLE === getAttr(entity, 'name')
    }

    getIdForNotBillable() {
        return getEntityId(this.billableTypeEntities.find(billableType => BillableSet.isEntityBillable(billableType)))
    }

    getIdForHourlyRate() {
        return getEntityId(this.billableTypeEntities.find(billableType => BillableSet.isEntityBillableTypeHourlyRate(billableType)))
    }

    getIdForExternal() {
        return getEntityId(this.billableTypeEntities.find(billableType => BillableSet.isEntityBillablityTypeExternal(billableType)))
    }

    getIdForBroker() {
        return getEntityId(this.billableTypeEntities.find(billableType => BillableSet.isEntityBillableTypeBroker(billableType)))
    }

    getBillableTypeFromId(id) {
        return this.billableTypeEntities.find(billableType => getEntityId(billableType) === id)
    }

    getBillableTypeNameFromId(id) {
        return id ? getAttr(this.getBillableTypeFromId(id), 'name') : ''
    }

    getAllBillableTypeEntities() {
        return this.billableTypeEntities
    }
}
import {put} from 'redux-saga/effects';
import * as types from '../../../constants/ActionTypes';
import {takeLatestAfterBootstrap} from '../../helpers';
import * as pageActions from '../../../actions/pages/absencePeriods';

function* doAbsencePeriodSetYear() {
    yield put(pageActions.loadForCurrentFilter());
}

export default function* absencePeriodSetYear() {
    yield takeLatestAfterBootstrap(types.APP_PAGE_ABSENCEPERIODS_SET_YEAR, doAbsencePeriodSetYear);
}

import React, { Component } from "react";
import PropTypes from "prop-types";
import { DefaultButton } from "@fluentui/react/lib/Button";
import ProjectBrowser from "../projectbrowser/ProjectBrowser";
import { Panel } from "@fluentui/react/lib/Panel";

export default class ProjectBrowserModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isVisible: false,
      isMobile: false,
    };
    this._onDismiss = this._onDismiss.bind(this);
    this._toggleVisible = this._toggleVisible.bind(this);
    this._onSelectProjects = this._onSelectProjects.bind(this);
    this._handleMobile = this._handleMobile.bind(this);
  }

  _onDismiss() {
    this.setState({
      isVisible: false,
    });
  }

  _toggleVisible() {
    this.setState({
      isVisible: !this.state.isVisible,
    });
    this._handleMobile();
  }

  _onSelectProjects(projects) {
    this.props.onItemSelected(projects);
    this._onDismiss();
  }

  _handleMobile() {
    this.setState({
      isMobile: window.innerWidth < 480,
    });
  }

  render() {
    let {
      projects,
      clients,
      hideButtonDisabled,
      contracts,
      selectedProjects,
      hourSet,
      selectedResource,
    } = this.props;
    let { isVisible } = this.state;

    return (
      <div>
        <div ref={(menuButton) => (this._menuButtonElement = menuButton)}>
          <DefaultButton onClick={this._toggleVisible} text="Add more" />
        </div>

        <Panel
          isOpen={isVisible}
          onDismiss={this._onDismiss}
          isBlocking={true}
          containerClassName="novatime-project-browser-panel"
          isLightDismiss
          hasCloseButton={false}
        >
          <ProjectBrowser
            contracts={contracts}
            projects={projects}
            clients={clients}
            onSelectProjects={this._onSelectProjects}
            onDismiss={this._onDismiss}
            selectedProjects={selectedProjects}
            hourSet={hourSet}
            selectedResource={selectedResource}
          />
        </Panel>
      </div>
    );
  }
}

ProjectBrowserModal.propTypes = {
  projects: PropTypes.array,
  clients: PropTypes.array,
  onItemSelected: PropTypes.func,
};

import BaseConnector from './Base';

export default class ContractConnector extends BaseConnector {
    getContracts(clientId?: string | null, includes?: string) {
        const params = [];
        if(clientId !== null){
            params.push('filter=client.id eq ' + clientId);
        }
        if(includes){
            params.push('includes=' + includes);
        }
        const queryParam = params.length > 0 ? `?${params.join('&')}` : '';
        return this.authFetch(this.baseApiUrl + 'Contracts/' + queryParam);
    }

    createContract(contractDto: object) {
        return this.authPost(this.baseApiUrl + 'Contracts/', contractDto)
    }

    updateContract(contractId: string, contractDto: object){
        return this.authPatch(this.baseApiUrl + 'Contracts/' + contractId, contractDto);
    }

    deleteContract(id: string){
        return this.authDelete(this.baseApiUrl + 'Contracts/' + id);
    }
}

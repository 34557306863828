import BaseConnector from './Base';

export default class ResourceConnector extends BaseConnector {

    getResourcesByAccountId(accountId: string) {
        return this.authFetch(this.baseApiUrl + 'Resources?filter=account.id eq ' + accountId);
    }

    createResource(resourceDto: object) {
        return this.authPost(this.baseApiUrl + 'Resources/', resourceDto);
    }

    updateResource(resourceId: string, resourceDto: object){
        return this.authPatch(this.baseApiUrl + 'Resources/' + resourceId + '?includes=user', resourceDto);
    }

    deleteResource(id: string){
        return this.authDelete(this.baseApiUrl + 'Resources/' + id);
    }
}

import {put, takeLatest} from 'redux-saga/effects';
import * as types from '../../../constants/ActionTypes';

function* doWeekSelected(action) {
    yield put({ type: types.APP_PAGE_TIMEREGISTRATION_FETCH_WEEK_REGISTRATIONS });
}

export default function* weekSelected() {
    yield takeLatest(types.APP_PAGE_TIMEREGISTRATION_WEEK_SELECTED, doWeekSelected);
}

import React, { Component } from 'react';
import { Dispatch, bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {goHome} from '../../actions/nav';
import {logout} from '../../actions/auth';
import * as authSelectors from '../../selectors/app/auth';

type LogoutStateProps = {
    isLoggedIn: boolean,
}
type LogoutOwnProps = Record<string, never>;
type LogoutDispatchProps = {
    actions: {
        goHome: (skipHistory: boolean) => void,
        logout: () => void
    }
}

type LogoutProps = LogoutStateProps & LogoutOwnProps & LogoutDispatchProps;

class Logout extends Component<LogoutProps> {
    componentDidUpdate = () => this._doLogout()

    componentDidMount = () => this._doLogout()

    _doLogout = () => {
        const { actions, isLoggedIn } = this.props;

        if (isLoggedIn){
            actions.logout();
        }
        else {
            actions.goHome(true);
        }
    }

    render = () => <></>
}

function mapStateToProps(state: any) {
    return {
        isLoggedIn: authSelectors.isLoggedIn(state)
    };
}

function mapDispatch(dispatch: Dispatch) {
    const actions = { goHome, logout };
    return { actions: bindActionCreators(actions, dispatch) };
}

export default connect<LogoutStateProps, LogoutDispatchProps, LogoutOwnProps>(mapStateToProps, mapDispatch)(Logout);

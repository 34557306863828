import React, { Component } from "react";
import PropTypes from "prop-types";
import { HoverCard, ExpandingCardMode } from "@fluentui/react/lib/HoverCard";
import * as InternalPropTypes from "../../constants/PropTypes";
import "../../lib/setOperations";

export default class TimeRegistrationHoverCard extends Component {
  static propTypes = {
    onHover: PropTypes.func,
    onLongHover: PropTypes.func,
    expandedCardHeight: PropTypes.number,
    compactCardHeight: PropTypes.number,
    resources: InternalPropTypes.resourceEntities,
  };

  constructor(props) {
    super(props);

    this._onHover = this._onHover.bind(this);
    this._onLongHover = this._onLongHover.bind(this);
  }

  _onHover(renderData) {
    return this.props.onHover ? this.props.onHover(renderData) : null;
  }

  _onLongHover(renderData) {
    return this.props.onLongHover ? this.props.onLongHover(renderData) : null;
  }

  render() {
    let { renderData, expandedCardHeight, compactCardHeight } = this.props;
    const expandingCardProps = {
      onRenderCompactCard: this._onHover,
      onRenderExpandedCard: this._onLongHover,
      renderData,
      compactCardHeight,
      expandedCardHeight:
        (typeof expandedCardHeight === "number" && expandedCardHeight) ||
        (this.props.onLongHover && 200) ||
        0,
      mode: this.props.onLongHover
        ? ExpandingCardMode.expanded
        : ExpandingCardMode.compact,
      styles: {
        root: {
          width: "600px",
        },
        compactCard: {
          overflowY: "auto",
        },
      },
    };

    return (
      <HoverCard
        expandingCardProps={expandingCardProps}
        cardOpenDelay={500}
        expandedCardOpenDelay={1000}
      >
        {this.props.children}
      </HoverCard>
    );
  }
}

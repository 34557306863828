import React, { Component } from "react";
import PropTypes from "prop-types";
import { TextField } from "@fluentui/react/lib/TextField";
import { ActivityItem } from "@fluentui/react/lib/ActivityItem";
import { Icon } from "@fluentui/react/lib/Icon";
import {
  relRefersToEntity,
  getAttr,
  getEntityId,
} from "../../selectors/apihelper";
import { createEmptyModelAttributes } from "../../lib/emptyModelCreation";
import moment from "moment";
import * as EntityTypes from "../../constants/EntityTypes";
import SingleBaseForm from "./SingleBaseForm";
import Column from "../grid/Column";
import Row from "../grid/Row";
import * as InternalPropTypes from "../../constants/PropTypes";
import Grid from "../grid/Grid";

export default class AbsencePeriodCommentForm extends SingleBaseForm {
  constructor(props) {
    super(props);
    let { model } = props;
    this.state = {};
    this.state.fields = SingleBaseForm._initializeFields(
      {
        comment: {
          getVal: () => getAttr(this.props.model, "comment"),
          setVal: () =>
            createEmptyModelAttributes(EntityTypes.ABSENCEPERIODCOMMENT, {
              comment: this.state.fields.comment.value,
            }),
          isRequired: true,
        },
      },
      model
    );

    this._onCommentChange = this._onCommentChange.bind(this);
    this.renderComments = this.renderComments.bind(this);
  }

  static propTypes = {
    model: PropTypes.object,
    onSave: PropTypes.func,
    onDismiss: PropTypes.func,
    comments: InternalPropTypes.absencePeriodCommentEntities,
    usersForComments: InternalPropTypes.userEntities,
  };

  _onCommentChange(e, value) {
    this._updateFieldState("comment", value);
  }

  renderComments() {
    let { model, comments, usersForComments } = this.props;

    let activityList = comments.map((comment) => {
      let time = moment(getAttr(comment, "addedDate")).format(
        "MMMM Do YYYY, HH:mm:ss"
      );
      let commentText = getAttr(comment, "comment");
      let user = usersForComments.find((u) =>
        relRefersToEntity(comment, "sentByUser", u)
      );

      return (
        <ActivityItem
          activityDescription={[
            <span key={"activity-desc" + getEntityId(comment)}>
              {getAttr(user, "name")}
            </span>,
          ]}
          activityIcon={<Icon iconName="Message" />}
          comments={[
            <span key={"activity-comment" + getEntityId(comment)}>
              {commentText}
            </span>,
          ]}
          timeStamp={time}
          key={"activity-item" + getEntityId(comment)}
        />
      );
    });

    return <div>{activityList}</div>;
  }

  render() {
    let { fields } = this.state;
    let { comments, model } = this.props;

    return (
      <div className="novatime-absencePeriod-comment-form">
        <h2>Absence Period Comments</h2>
        <br />
        {comments.length > 0 ? this.renderComments() : null}
        <br />
        <Grid>
          <Row>
            <Column sm={12} md={12} lg={12}>
              <TextField
                label="Comment"
                onChange={this._onCommentChange}
                required={this.state.fields.comment.isRequired}
                multiline
                rows={4}
                value={this.state.fields.comment.value}
                errorMessage={this.state.fields.comment.validationMessage}
              />
            </Column>
          </Row>
        </Grid>
        <br />

        {this._renderButtons()}
        <br />
        {this._renderTimestampsAndId(model)}
      </div>
    );
  }
}

import * as types from '../../constants/ActionTypes';
import * as apihelper from '../../selectors/apihelper';

export function pageOpened() {
    return {
        type: types.APP_PAGE_RESOURCEMANAGEMENT_OPENED
    };
}

export function selectCell(cellData) {
    return {
        type: types.APP_PAGE_RESOURCEMANAGEMENT_CELL_SELECTED,
        payload: {
            resourceId: apihelper.getEntityId(cellData.resource),
            date: cellData.date.toISOString(),
            timeRegistrationIds: cellData.timeRegistrations.map(apihelper.getEntityId)
        }
    };
}

export function selectTimeRegistration(timeRegistration) {
    return {
        type: types.APP_PAGE_RESOURCEMANAGEMENT_TR_SELECTED,
        payload: timeRegistration
    };
}

export function clearSelectedCell() {
    return {
        type: types.APP_PAGE_RESOURCEMANAGEMENT_CLEAR_SELECTED_CELL
    }
}

export function selectProjectId(projectId){
    return {
        type: types.APP_PAGE_RESOURCEMANAGEMENT_UPDATE_FILTER_INIT,
        payload: {
            projectId
        }
    }
}

export function selectMonth(firstDayOfMonth){
    return {
        type: types.APP_PAGE_RESOURCEMANAGEMENT_UPDATE_FILTER_INIT,
        payload: {
            startTime: firstDayOfMonth instanceof Date ? firstDayOfMonth.toISOString() : firstDayOfMonth
        }
    }
}

export function selectClientId(clientId){
    return {
        type: types.APP_PAGE_RESOURCEMANAGEMENT_UPDATE_FILTER_INIT,
        payload: {
            clientId,
            projectId: null
        }
    }
}

export function selectAllClients(){
    return {
        type: types.APP_PAGE_RESOURCEMANAGEMENT_UPDATE_FILTER_INIT,
        payload: {
            clientId: null,
            projectId: null
        }
    }
}

export function selectAllProjects(){
    return {
        type: types.APP_PAGE_RESOURCEMANAGEMENT_UPDATE_FILTER_INIT,
        payload: {
            projectId: null
        }
    }
}

export function selectResourceTypeIds(resourceTypeIds, showInactiveResources){
    return {
        type: types.APP_PAGE_RESOURCEMANAGEMENT_UPDATE_FILTER_INIT,
        payload: {
            resourceTypeIds,
            showInactiveResources
        }
    }
}

export function updateViewDesc(viewDesc) {
    return {
        type: types.APP_PAGE_RESOURCEMANAGEMENT_UPDATE_VIEW_DESC,
        payload: viewDesc,
    }
}

export function lockPeriods(resourcesToClose) {
    return {
        type: types.APP_PAGE_RESOURCEMANAGEMENT_LOCK_PERIODS,
        payload: resourcesToClose
    }
}

export function openPeriods(resourcesToOpen) {
    return {
        type: types.APP_PAGE_RESOURCEMANAGEMENT_OPEN_PERIODS,
        payload: resourcesToOpen
    }
}

export function toggleProcessingDialog() {
    return {
        type: types.APP_PAGE_RESOURCEMANAGEMENT_TOGGLE_PROCESSING_DIALOG,
    }
}
import {put, select, takeLatest} from 'redux-saga/effects';
import * as types from '../../../constants/ActionTypes';
import {getAllProjectResources} from '../../../actions/api/projectResource';
import {getAllContractRoles} from '../../../actions/api/contractRole';
import * as reducerContractSelectors from '../../../selectors/reducers/contract';
import * as sagahelpers from '../../helpers';

function* doProjectSelected(action) {
    if(action.payload !== null){
        yield sagahelpers.callAndAwait(getAllProjectResources({project: action.payload}));
        let contract = yield select(reducerContractSelectors.contractByProject, action.payload);
        yield put(getAllContractRoles(contract));
    }
}

export default function* projectSelected() {
    yield takeLatest(types.APP_PAGE_PROJECTS_AND_ROLES_PROJECT_SELECTED, doProjectSelected);
}
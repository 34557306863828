import {combineReducers} from "redux";
import notification from './notification';
import settings from './settings';
import requests from './requests';
import editor from './entityEditor';
import auth from './auth';

export default combineReducers({
    notification,
    settings,
    requests,
    editor,
    auth
});

import {contractByContractRole, contractByProject, contractsByProjects} from './contract';
import * as apihelper from '../apihelper';
import { currentUserAccount } from './account';
import { createSelector } from 'reselect';
import * as storehelper from '../storehelper';
import { ResourceObjects, ResourceObject } from '../../lib/models';

export const allClients = (state: any) => state.api.client.allClients as ResourceObjects;

export const filterByAccounts = (clients: ResourceObjects, accounts: ResourceObjects | ResourceObject) =>
    storehelper.filterByReferenceToRelatedEntities(clients, accounts, 'account');

export const allClientsByAccount = (state: any, account: ResourceObject) => filterByAccounts(allClients(state), account);

export const filterByAccountIds = (clients: ResourceObjects, accountIds: string[]) => 
    storehelper.filterByReferencedIds(clients, 'account', accountIds);

export const filterByContracts = (
    clients: ResourceObjects, 
    contracts: ResourceObjects | ResourceObject
): ResourceObjects =>
    storehelper.filterByRelatedEntity(clients, contracts, 'client');

export const allClientsByAccountIds = (state: any, accountIds: string[]) => filterByAccountIds(allClients(state), accountIds);

export const clientById = (state: any, clientId: string) => allClients(state).find(c => apihelper.entityHasId(c, clientId));

export const clientByProject = (state: any, project: ResourceObject) => {
    const contract = contractByProject(state, project);
    const clientId = contract && apihelper.getRelId(contract, 'client') as string;
    return allClients(state).find(c => apihelper.entityHasId(c, clientId));
};

export const allClientsByProjects = (state: any, projects: ResourceObjects) => {
    const clientIdsFromContracts = contractsByProjects(state, projects).map(client => apihelper.getRelId(client, 'client'));
    return allClients(state).filter(c => clientIdsFromContracts.includes(apihelper.getEntityId(c) as string));
};

export const clientByContract = (state: any, contract: ResourceObject) => 
    allClients(state).find(cl => apihelper.entityHasId(cl, apihelper.getRelId(contract, 'client') as string));

export const clientByContractRole = (
    state: any,
    contractRole: ResourceObject
) => clientByContract(state, contractByContractRole(state, contractRole) as ResourceObject);

export const allClientsByCurrentUserAccount = createSelector(
    [allClients, currentUserAccount],
    (clients, account) => clients.filter(c => apihelper.relRefersToEntity(c, 'account', account))
)

const lexicalSortByName = storehelper.sortByLocaleCompare((entity: ResourceObject) =>
    (apihelper.getAttr(entity, 'name') as string).toLowerCase());

export const allClientsByCurrentUserAccountSortedByName = createSelector(
    [allClientsByCurrentUserAccount],
    clients => clients.sort(lexicalSortByName)
);

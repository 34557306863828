import React, { Component } from "react";
import PropTypes from "prop-types";
import { AnimatePresence, motion } from "framer-motion";

const motionVariants = {
  enter: { opacity: 1, transition: { duration: 300 } },
  exit: { opacity: 0, transition: { duration: 300 } },
};

// runs in two modes. If props are given, these take precedense. Otherwise, state runs expansion
export default class Expandable extends Component {
  static propTypes = {
    expanded: PropTypes.bool,
    initialExpanded: PropTypes.bool,
    onToggle: PropTypes.func,
    expandText: PropTypes.string,
    collapseText: PropTypes.string,
  };

  static defaultProps = {
    expandText: "expand",
    collapseText: "collapse",
    initialExpanded: false,
  };

  constructor(props) {
    super(props);
    this.state = {
      expanded: this.props.initialExpanded,
    };
    this.state.expanded = this.isExpanded();

    this.onToggle = this.onToggle.bind(this);
  }

  isExpanded() {
    return typeof this.props.expanded == "boolean"
      ? this.props.expanded
      : this.state.expanded;
  }

  onToggle() {
    if (this.props.onToggle) {
      this.props.onToggle(this.props.expanded);
    } else {
      this.setState({ expanded: !this.state.expanded });
    }
  }

  render() {
    let { expandText, collapseText } = this.props;
    let isExpanded = this.isExpanded();
    return (
      <div
        className={"expandable-component " + (!isExpanded ? "collapsed" : "")}
      >
        <p>
          <button
            onClick={this.onToggle}
            className="expandable-component-show-button"
          >
            {isExpanded ? collapseText : expandText}
          </button>
        </p>
        <AnimatePresence>
          {isExpanded ? (
            <motion.div
              key="expandable"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1, transition: { duration: 0.3 } }}
              exit={{ opacity: 0 }}
              className="expandable-component-children"
            >
              {this.props.children}
            </motion.div>
          ) : null}
        </AnimatePresence>
      </div>
    );
  }
}

import * as SagaHelpers from '../helpers';
import * as SagaEffects from 'redux-saga/effects';
import * as types from '../../constants/ActionTypes';
import LockedTimePeriodConnector from "../../connectors/LockedTimePeriod";

const createLockedTimePeriod = SagaHelpers.createApiHandler(
    types.API_LOCKEDTIMEPERIOD_CREATE_INIT, 
    SagaHelpers.generateCreateApiCall(
        LockedTimePeriodConnector,
        function* (connector: LockedTimePeriodConnector, action: any): any {
            return yield SagaEffects.call([connector, connector.createLockedTimePeriod], {
                data: action.payload.model
            });
        }
    )
)

const deleteLockedTimePeriod = SagaHelpers.createApiHandler(
    types.API_LOCKEDTIMEPERIOD_DELETE_INIT, 
    SagaHelpers.generateDeleteApiCall(
        LockedTimePeriodConnector,
        function* (connector: LockedTimePeriodConnector, action: any): any {
            return yield SagaEffects.call([connector, connector.deleteLockedTimePeriod], action.payload.model.id);
        }
    )
)

const getAllLockedTimePeriods = SagaHelpers.createApiHandler(
    types.API_LOCKEDTIMEPERIOD_GET_ALL_INIT, 
    SagaHelpers.generateGetAllApiCall(
        LockedTimePeriodConnector,
        function* (connector: LockedTimePeriodConnector, action: any): any {
            const {resource, startDate, endDate} = action.payload.parameters;
            const resourceId = resource ? resource.id : null;
            return yield SagaEffects.call(
                [connector, connector.getAll],
                {resourceId, startDate, endDate},
                action.payload.includes);
        }
    )
)

export default function* lockedTimePeriodSaga() {
    yield SagaEffects.all([
        SagaEffects.fork(getAllLockedTimePeriods),
        SagaEffects.fork(createLockedTimePeriod),
        SagaEffects.fork(deleteLockedTimePeriod),
    ]);
}

import * as types from '../../constants/ActionTypes';
import {updateDtoList} from '../helpers';
import {getAllBillabilityTypes} from '../../selectors/actions/billabilityTypes';

const initialState = {
    storeRevision: 0,
    allBillabilityTypes: []
};

export default function contractRoleReducer(state = initialState, action) {
    switch (action.type) {
    case types.API_BILLABILITYTYPE_GET_ALL_SUCCESS: {
        return updateDtoList(state, 'allBillabilityTypes', getAllBillabilityTypes(action));
    }

    default:
        return state;
    }
}
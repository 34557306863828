import * as types from '../../constants/ActionTypes';

const initialState = {
    selectedUserId: null,
	selectedResourceId: null,
	selectedPermissionUserId: null,
    toggleInactives: false,
};

export default function auth(state = initialState, action) {
    switch (action.type) {

    case types.APP_PAGE_USERSANDRESOURCES_USER_SELECTED: {
        return Object.assign({}, state, {
            selectedUserId: action.payload ? action.payload.id : null,
        });
    }

    case types.APP_PAGE_USERSANDRESOURCES_RESOURCE_SELECTED: {
        return Object.assign({}, state, {
            selectedResourceId: action.payload ? action.payload.id : null,
        });
	}

	case types.APP_PAGE_USERSANDRESOURCES_PERMISSION_SELECTED: { 
		return Object.assign({}, state, {
			selectedPermissionUserId: action.payload ? action.payload.user.id : null,
            selectedPermissionAccountId: action.payload ? action.payload.account.id : null,
            selectedPermissionClientId: action.payload ? action.payload.client.id : null,
            selectedPermissionProjectId: action.payload ? action.payload.project.id : null,
		});
	}
    case types.APP_PAGE_USERSANDRESOURCES_TOGGLE_INACTIVES: {
        return Object.assign({}, state, {
            toggleInactives: !state.toggleInactives
        });
    }

    default:
        return state;
    }
}
